let state = {
    loaderModal_Shown: false,
    reasonWhy_loaderModal_Shown: undefined,
}

const actions = {
    change_loaderModal_storeAction({ commit }, value) {
        commit('change_loaderModal_storeMutation', value)
    },
}

const mutations = {
    change_loaderModal_storeMutation(state, payload) {
        state.loaderModal_Shown = payload.loaderModal_Shown;
        state.reasonWhy_loaderModal_Shown = payload.reasonWhy_loaderModal_Shown;
    },
}

export const container = {
    namespaced: true,
    state,
    actions,
    mutations
}