import axios from 'axios';
import router from "@/router";

let state = {
    ZArec_Typ_select: null,
    ZArec_TypUI_select: null,
    ZArec_MNTyp_select: null,
    ZArec_LS_select: null,
    ZArec_LSUI_select: null,
    ZArec_MNLS_select: null,
    ZArec_BR_select: null,
    ZArec_BRUI_select: null,
    ZArec_MNBR_select: null,
    ZArec_BRLT_select: null,
    ZArec_BRLTUI_select: null,
    ZArec_MNBRLT_select: null,
    ZArec_ZApad_select: null,
    ZArec_ZApadUI_select: null,
    ZArec_MNZApad_select: null,
    ZArec_BAL_select: null,
    ZArec_BALUI_select: null,
    ZArec_MNBAL_select: null,
    RLD_Typ_select: null,
    RLD_TypUI_select: null,
    RLD_MNTyp_select: null,
    RLD_FEF_select: null,
    RLD_FEFUI_select: null,
    RLD_MNFEF_select: null,
    ZArec_Typ_list: [],
    ZArec_LS_list: [],
    ZArec_BR_list: [],
    ZArec_BRLT_list: [],
    ZArec_ZApad_list: [],
    ZArec_BAL_list: [],
    RLD_Typ_list: [],
    RLD_FEF_list: [],
    FI_fuer_suche_verwenden: true,

}
const actions = {
    async get_ZArec_Typ_storeAction({ commit, rootState }) {

        //console.log('get_FI_Type_storeAction')
        const data_FU = { ID: rootState.FI.FI_MNFI_selected, FIberuecksichtigen: rootState.ZArec.FI_fuer_suche_verwenden };
        await axios({

            method: 'post',
            url: '/api/ZArec/get_ZArec_Typ',
            data: {
                data_FU: data_FU,
            },


        })
            .then((response) => {
                commit('get_ZArec_Typ_storeMutation', response.data)

                if (state.ZArec_Typ_select != null) {
                    var firstIndex_whichMeetConditions_Typ = state.ZArec_Typ_list.findIndex(i => (i.text.text == state.ZArec_Typ_select));
                    if (firstIndex_whichMeetConditions_Typ > -1) {
                        commit('change_ZArec_Typ_checkboxSelection_storeMutation', state.ZArec_Typ_select)
                    }
                }

            })
            .catch(() => { })
    },
    async get_ZArec_LS_storeAction({ commit, rootState }) {

        //console.log('get_FI_Type_storeAction')
        const data_FU = { ID: rootState.FI.FI_MNFI_selected, FIberuecksichtigen: rootState.ZArec.FI_fuer_suche_verwenden };
        await axios({

            method: 'post',
            url: '/api/ZArec/get_ZArec_LS',
            data: {
                data_FU: data_FU,
            },


        })
            .then((response) => {
                commit('get_ZArec_LS_storeMutation', response.data)

                //if (state.ZArec_LS_select != null) {
                //    var firstIndex_whichMeetConditions_LS = state.ZArec_LS_list.findIndex(i => (i.text.text == state.ZArec_LS_select));
                //    if (firstIndex_whichMeetConditions_LS > -1) {
                //        commit('change_ZArec_LS_checkboxSelection_storeMutation', state.ZArec_LS_select)
                //    }
                //}

            })
            .catch(() => { })
    },
    async get_ZArec_BAL_storeAction({ commit, rootState }) {

        //console.log('get_FI_Type_storeAction')
        const data_FU = { ID: rootState.FI.FI_MNFI_selected, FIberuecksichtigen: rootState.ZArec.FI_fuer_suche_verwenden };
        await axios({

            method: 'post',
            url: '/api/ZArec/get_ZArec_BAL',
            data: {
                data_FU: data_FU,
            },


        })
            .then((response) => {

                commit('get_ZArec_BAL_storeMutation', response.data)

                //if (state.ZArec_BAL_select != null) {
                //    var firstIndex_whichMeetConditions_BAL = state.ZArec_BAL_list.findIndex(i => (i.text.text == state.ZArec_BAL_select));
                //    if (firstIndex_whichMeetConditions_BAL > -1) {
                //        commit('change_ZArec_BAL_checkboxSelection_storeMutation', state.ZArec_BAL_select)
                //    }
                //}

            })
            .catch(() => { })
    },
    async get_ZArec_BR_storeAction({ commit }) {

        //console.log('get_FI_Type_storeAction')
        const data_FU = { ZArec_BR: state.ZArec_BR_select };
        await axios({

            method: 'post',
            url: '/api/ZArec/get_ZArec_BR',
            data: {
                data_FU: data_FU,
            },


        })
            .then((response) => {

                commit('get_ZArec_BR_storeMutation', response.data)

                //if (state.ZArec_BR_select != null) {
                //    var firstIndex_whichMeetConditions_BR = state.ZArec_BR_list.findIndex(i => (i.text.text == state.ZArec_BR_select));
                //    if (firstIndex_whichMeetConditions_BR > -1) {
                //        commit('change_ZArec_BR_checkboxSelection_storeMutation', state.ZArec_BR_select)
                //    }
                //}

            })
            .catch(() => { })
    },
    async get_ZArec_MNBR_storeAction({ commit }) {

        //console.log('get_FI_Type_storeAction')
        const data_FU = { ZArec_BR: state.ZArec_BR_select };
        await axios({

            method: 'post',
            url: '/api/ZArec/get_ZArec_MNBR',
            data: {
                data_FU: data_FU,
            },


        })
            .then((response) => {

                commit('change_ZArec_MNBR_selection_storeMutation', response.data)



            })
            .catch(() => { })
    },
    async get_ZArec_BRLT_storeAction({ commit }) {

        //console.log('get_FI_Type_storeAction')
        const data_FU = { ZArec_BR: state.ZArec_BR_select };
        await axios({

            method: 'post',
            url: '/api/ZArec/get_ZArec_BRLT',
            data: {
                data_FU: data_FU,
            },


        })
            .then((response) => {

                commit('get_ZArec_BRLT_storeMutation', response.data)

                //if (state.ZArec_BRLT_select != null) {
                //    var firstIndex_whichMeetConditions_BRLT = state.ZArec_BRLT_list.findIndex(i => (i.text.text == state.ZArec_BRLT_select));
                //    if (firstIndex_whichMeetConditions_BRLT > -1) {
                //        commit('change_ZArec_BRLT_checkboxSelection_storeMutation', state.ZArec_BRLT_select)
                //    }
                //}


            })
            .catch(() => { })
    },
    async get_ZArec_ZApad_storeAction({ commit }) {

        //console.log('get_FI_Type_storeAction')
        const data_FU = { ZArec_BR: state.ZArec_BR_select };
        await axios({

            method: 'post',
            url: '/api/ZArec/get_ZArec_ZApad',
            data: {
                data_FU: data_FU,
            },


        })
            .then((response) => {

                commit('get_ZArec_ZApad_storeMutation', response.data)

                //if (state.ZArec_ZApad_select != null) {
                //    var firstIndex_whichMeetConditions_ZApad = state.ZArec_ZApad_list.findIndex(i => (i.text.text == state.ZArec_ZApad_select));
                //    if (firstIndex_whichMeetConditions_ZApad > -1) {
                //        commit('change_ZArec_ZApad_checkboxSelection_storeMutation', state.ZArec_ZApad_select)
                //    }
                //}

            })
            .catch(() => { })
    },
    async get_RLD_Typ_storeAction({ commit }) {
        await axios({
            method: 'post',
            url: '/api/ZArec/get_RLD_Typ',
            
        })
            .then((response) => {
                debugger
                commit('get_RLD_Typ_storeMutation', response.data)
                debugger

            })
            .catch(() => { })
    },
    async get_RLD_FEF_storeAction({ commit }) {
        const data_FU = { RLD: state.RLD_Typ_select };

        await axios({
            method: 'post',
            url: '/api/ZArec/get_RLD_FEF',
            data: {
                data_FU: data_FU,
            },
        })
            .then((response) => {
                commit('get_RLD_FEF_storeMutation', response.data)


            })
            .catch(() => { })
    },

    async insert_data({ rootState }) {

        const data_Insert_ZArec = {
            ZArec_Typ: state.ZArec_Typ_select,
            MN_ZArec_Typ: state.ZArec_MNTyp_select,
            ZArec_LS: state.ZArec_LS_select,
            MN_ZArec_LS: state.ZArec_MNLS_select,
            ZArec_BR: state.ZArec_BR_select,
            MN_ZArec_BR: state.ZArec_MNBR_select,
            ZArec_BRLT: state.ZArec_BRLT_select,
            MN_ZArec_BRLT: state.ZArec_MNBRLT_select,
            ZArec_ZApad: state.ZArec_ZApad_select,
            MN_ZArec_ZApad: state.ZArec_MNZApad_select,
            ZArec_BAL: state.ZArec_BAL_select, 
            MN_ZArec_BAL: state.ZArec_MNBAL_select,
            RLD_Typ: state.RLD_Typ_select,
            MN_RLD_Typ: state.RLD_MNTyp_select,
            RLD_FEF: state.RLD_FEF_select,
            MN_RLD_FEF: state.RLD_MNFEF_select,
        }

        await axios({
            method: 'post',
            url: '/api/ZArec/insert_data',
            data: {
                data_Insert_ZArec: data_Insert_ZArec,
                zLM: rootState.manualHome.ZLM,
            },
        })
            .then((response) => {
                this.commit('manualHome/change_ZLM_storeMutation', response.data)


            })
            .catch(() => { })
    },

    change_ZArec_Typ_selection_storeAction({ commit, dispatch }, value) {
        /*console.log('change_FI_Type_selection_storeAction', value);*/

        if (value.selected == true) {
            commit('change_ZArec_Typ_checkboxSelection_storeMutation', null);
        }
        else {
            commit('change_ZArec_Typ_checkboxSelection_storeMutation', value.text.text);
        }

        const firstIndex_whichMeetConditions = state.ZArec_Typ_list.findIndex(i => (i.selected == true));

        if (firstIndex_whichMeetConditions > -1) {
            commit('change_ZArec_Typ_selection_storeMutation', state.ZArec_Typ_list[firstIndex_whichMeetConditions].text.text);
            commit('change_ZArec_TypUI_selection_storeMutation', state.ZArec_Typ_list[firstIndex_whichMeetConditions].text.textUI);
            commit('change_ZArec_MNTyp_selection_storeMutation', state.ZArec_Typ_list[firstIndex_whichMeetConditions].id);
        }
        else {
            commit('change_ZArec_Typ_selection_storeMutation', null)
            commit('change_ZArec_TypUI_selection_storeMutation', null)
            commit('change_ZArec_MNTyp_selection_storeMutation', null)
        }


        //commit('change_ZArec_Typ_selection_storeMutation', value);
        if (state.ZArec_Typ_select == "ZArec4C 013") {

            commit('change_ZArec_BR_selection_storeMutation', null)
            commit('change_ZArec_BRUI_selection_storeMutation', null)
            commit('change_ZArec_MNBR_selection_storeMutation', null)

            commit('change_ZArec_BRLT_selection_storeMutation', null)
            commit('change_ZArec_BRLTUI_selection_storeMutation', null)
            commit('change_ZArec_MNBRLT_selection_storeMutation', null)
        } 


        
       
        commit('change_ZArec_LS_selection_storeMutation', null)
        commit('change_ZArec_LSUI_selection_storeMutation', null)
        commit('change_ZArec_MNLS_selection_storeMutation', null)
        
        commit('change_ZArec_BR_selection_storeMutation', null)
        commit('change_ZArec_BRUI_selection_storeMutation', null)
        commit('change_ZArec_MNBR_selection_storeMutation', null)
        
        commit('change_ZArec_BRLT_selection_storeMutation', null)
        commit('change_ZArec_BRLTUI_selection_storeMutation', null)
        commit('change_ZArec_MNBRLT_selection_storeMutation', null)

        commit('change_ZArec_ZApad_selection_storeMutation', null)
        commit('change_ZArec_ZApadUI_selection_storeMutation', null)
        commit('change_ZArec_MNZApad_selection_storeMutation', null)
        
        commit('change_ZArec_BAL_selection_storeMutation', null)
        commit('change_ZArec_BALUI_selection_storeMutation', null)
        commit('change_ZArec_MNBAL_selection_storeMutation', null)


        dispatch('get_ZArec_LS_storeAction');
        dispatch('get_ZArec_BAL_storeAction');
        dispatch('get_ZArec_BR_storeAction')
        dispatch('get_ZArec_BRLT_storeAction');
        dispatch('get_ZArec_ZApad_storeAction');
    },

    change_ZArec_LS_selection_storeAction({ commit }, value) {
        if (value.selected == true) {
            commit('change_ZArec_LS_checkboxSelection_storeMutation', null);
        }
        else {
            commit('change_ZArec_LS_checkboxSelection_storeMutation', value.text.text);
        }

        const firstIndex_whichMeetConditions = state.ZArec_LS_list.findIndex(i => (i.selected == true));

        if (firstIndex_whichMeetConditions > -1) {
            commit('change_ZArec_LS_selection_storeMutation', state.ZArec_LS_list[firstIndex_whichMeetConditions].text.text);
            commit('change_ZArec_LSUI_selection_storeMutation', state.ZArec_LS_list[firstIndex_whichMeetConditions].text.textUI);
            commit('change_ZArec_MNLS_selection_storeMutation', state.ZArec_LS_list[firstIndex_whichMeetConditions].id);
        }
        else {
            commit('change_ZArec_LS_selection_storeMutation', null)
            commit('change_ZArec_LSUI_selection_storeMutation', null)
            commit('change_ZArec_MNLS_selection_storeMutation', null)
        }

    },



    change_ZArec_BR_selection_storeAction({ commit, dispatch }, value) {
        //if (state.ZArec_BR_select == "BR14-A") {
        //    state.ZArec_BRLT_select = null;
        //    state.ZArec_BRLTUI_select = null;
        //    state.ZArec_MNBRLT_select = null;
        //}
                
        if (value.selected == true) {
            commit('change_ZArec_BR_checkboxSelection_storeMutation', null);
        }
        else {
            commit('change_ZArec_BR_checkboxSelection_storeMutation', value.text.text);
        }

        const firstIndex_whichMeetConditions = state.ZArec_BR_list.findIndex(i => (i.selected == true));

        if (firstIndex_whichMeetConditions > -1) {
            commit('change_ZArec_BR_selection_storeMutation', state.ZArec_BR_list[firstIndex_whichMeetConditions].text.text);
            commit('change_ZArec_BRUI_selection_storeMutation', state.ZArec_BR_list[firstIndex_whichMeetConditions].text.textUI);
            dispatch('get_ZArec_MNBR_storeAction');
            commit('change_ZArec_MNBR_selection_storeMutation', value);
        }
        else {
            commit('change_ZArec_BR_selection_storeMutation', null)
            commit('change_ZArec_BRUI_selection_storeMutation', null)
            commit('change_ZArec_MNBR_selection_storeMutation', null)

        }
        
        //commit('change_ZArec_BR_selection_storeMutation', value)
        if (state.ZArec_BR_select == "BR14-A") {

            
            commit('change_ZArec_BRLT_selection_storeMutation', null)
            commit('change_ZArec_BRLTUI_selection_storeMutation', null)
            commit('change_ZArec_MNBRLT_selection_storeMutation', null)
        }    

        dispatch('get_ZArec_BRLT_storeAction')
        
        
    },
    change_ZArec_BRLT_selection_storeAction({ commit }, value) {
        

        if (value.selected == true) {
            
            commit('change_ZArec_BRLT_checkboxSelection_storeMutation', null);
        }
        else {
            commit('change_ZArec_BRLT_checkboxSelection_storeMutation', value.text.text);
        }

        const firstIndex_whichMeetConditions = state.ZArec_BRLT_list.findIndex(i => (i.selected == true));

        if (firstIndex_whichMeetConditions > -1) {
            commit('change_ZArec_BRLT_selection_storeMutation', state.ZArec_BRLT_list[firstIndex_whichMeetConditions].text.text);
            commit('change_ZArec_BRLTUI_selection_storeMutation', state.ZArec_BRLT_list[firstIndex_whichMeetConditions].text.textUI);
            commit('change_ZArec_MNBRLT_selection_storeMutation', state.ZArec_BRLT_list[firstIndex_whichMeetConditions].id);
        }
        else {
            commit('change_ZArec_BRLT_selection_storeMutation', null)
            commit('change_ZArec_BRLTUI_selection_storeMutation', null)
            commit('change_ZArec_MNBRLT_selection_storeMutation', null)
        }

        
    },

    change_ZArec_ZApad_selection_storeAction({ commit }, value) {


        if (value.selected == true) {
            commit('change_ZArec_ZApad_checkboxSelection_storeMutation', null);
        }
        else {
            commit('change_ZArec_ZApad_checkboxSelection_storeMutation', value.text.text);
        }

        const firstIndex_whichMeetConditions = state.ZArec_ZApad_list.findIndex(i => (i.selected == true));

        if (firstIndex_whichMeetConditions > -1) {
            commit('change_ZArec_ZApad_selection_storeMutation', state.ZArec_ZApad_list[firstIndex_whichMeetConditions].text.text);
            commit('change_ZArec_ZApadUI_selection_storeMutation', state.ZArec_ZApad_list[firstIndex_whichMeetConditions].text.textUI);
            commit('change_ZArec_MNZApad_selection_storeMutation', state.ZArec_ZApad_list[firstIndex_whichMeetConditions].id);
        }
        else {
            commit('change_ZArec_ZApad_selection_storeMutation', null)
            commit('change_ZArec_ZApadUI_selection_storeMutation', null)
            commit('change_ZArec_MNZApad_selection_storeMutation', null)
        }


    },

    change_ZArec_BAL_selection_storeAction({ commit }, value) {


        if (value.selected == true) {
            commit('change_ZArec_BAL_checkboxSelection_storeMutation', null);
        }
        else {
            commit('change_ZArec_BAL_checkboxSelection_storeMutation', value.text.text);
        }

        const firstIndex_whichMeetConditions = state.ZArec_BAL_list.findIndex(i => (i.selected == true));

        if (firstIndex_whichMeetConditions > -1) {
            commit('change_ZArec_BAL_selection_storeMutation', state.ZArec_BAL_list[firstIndex_whichMeetConditions].text.text);
            commit('change_ZArec_BALUI_selection_storeMutation', state.ZArec_BAL_list[firstIndex_whichMeetConditions].text.textUI);
            commit('change_ZArec_MNBAL_selection_storeMutation', state.ZArec_BAL_list[firstIndex_whichMeetConditions].id);
        }
        else {
            commit('change_ZArec_BAL_selection_storeMutation', null);
            commit('change_ZArec_BALUI_selection_storeMutation', null);
            commit('change_ZArec_MNBAL_selection_storeMutation', null);
        }


    },

    change_RLD_Typ_selection_storeAction({ commit, dispatch }, value) {

        if (value.selected == true) {
            commit('change_RLD_Typ_checkboxSelection_storeMutation', null);
        }
        else {
            commit('change_RLD_Typ_checkboxSelection_storeMutation', value.text.text);
        }
        
        const firstIndex_whichMeetConditions = state.RLD_Typ_list.findIndex(i => (i.selected == true));

        if (firstIndex_whichMeetConditions > -1) {
            commit('change_RLD_Typ_selection_storeMutation', state.RLD_Typ_list[firstIndex_whichMeetConditions].text.text);
            commit('change_RLD_TypUI_selection_storeMutation', state.RLD_Typ_list[firstIndex_whichMeetConditions].text.textUI);
            commit('change_RLD_MNTyp_selection_storeMutation', state.RLD_Typ_list[firstIndex_whichMeetConditions].id);
        }
        else {
            commit('change_RLD_Typ_selection_storeMutation', null);
            commit('change_RLD_TypUI_selection_storeMutation', null);
            commit('change_RLD_MNTyp_selection_storeMutation', null);
        }
        commit('change_RLD_FEF_selection_storeMutation', null)
        commit('change_RLD_FEFUI_selection_storeMutation', null)
        commit('change_RLD_MNFEF_selection_storeMutation', null)

        dispatch('get_RLD_FEF_storeAction')

    },

    //change_RLD_FEF_selection_storeAction({ commit }, value) {

    //    if (value.selected == true) {
    //        commit('change_RLD_FEF_checkboxSelection_storeMutation', null);
    //    }
    //    else {
    //        commit('change_RLD_FEF_checkboxSelection_storeMutation', value.text.text);
    //    }

    //    const firstIndex_whichMeetConditions = state.RLD_FEF_list.findIndex(i => (i.selected == true));

    //    if (firstIndex_whichMeetConditions > -1) {
    //        commit('change_RLD_FEF_selection_storeMutation', state.RLD_FEF_list[firstIndex_whichMeetConditions].text.text);
    //    }
    //    else {
    //        commit('change_RLD_FEF_selection_storeMutation', null);
    //    }

    //}

    change_RLD_FEF_selection_storeAction({ commit }, value) {

        if (value.selected == true) {
            commit('change_RLD_FEF_checkboxSelection_storeMutation', null);
        }
        else {
            commit('change_RLD_FEF_checkboxSelection_storeMutation', value.text.text);
        }

        const firstIndex_whichMeetConditions = state.RLD_FEF_list.findIndex(i => (i.selected == true));

        if (firstIndex_whichMeetConditions > -1) {
            commit('change_RLD_FEF_selection_storeMutation', state.RLD_FEF_list[firstIndex_whichMeetConditions].text.text);
            commit('change_RLD_FEFUI_selection_storeMutation', state.RLD_FEF_list[firstIndex_whichMeetConditions].text.textUI);
            commit('change_RLD_MNFEF_selection_storeMutation', state.RLD_FEF_list[firstIndex_whichMeetConditions].id);
        }
        else {
            commit('change_RLD_FEF_selection_storeMutation', null);
            commit('change_RLD_FEFUI_selection_storeMutation', null);
            commit('change_RLD_MNFEF_selection_storeMutation', null);
        }

        

    },
    change_FI_fuer_suche_verwenden_selection_storeAction({ commit, dispatch }, value) {

        commit('change_FI_fuer_suche_verwenden_selection_storeMutation', value)
        dispatch('get_ZArec_Typ_storeAction')
    },

    change_inputs_ZArec_toDefault_storeAction({ commit }) {

        commit('change_ZArec_toDefault_selection_storeMutation');
    },

    }
   



const mutations = {
    get_ZArec_Typ_storeMutation(state, payload) {
        state.ZArec_Typ_list = payload;
    },
   change_ZArec_Typ_selection_storeMutation(state, payload) {
       state.ZArec_Typ_select = payload;
    },
    change_ZArec_TypUI_selection_storeMutation(state, payload) {
        state.ZArec_TypUI_select = payload;
    },
    change_ZArec_MNTyp_selection_storeMutation(state, payload) {
        state.ZArec_MNTyp_select = payload;
    },
    get_ZArec_LS_storeMutation(state, payload) {
        state.ZArec_LS_list = payload;
    },
    change_ZArec_LS_selection_storeMutation(state, payload) {
        state.ZArec_LS_select = payload;
    },
    change_ZArec_LSUI_selection_storeMutation(state, payload) {
        state.ZArec_LSUI_select = payload;
    },
    change_ZArec_MNLS_selection_storeMutation(state, payload) {
        state.ZArec_MNLS_select = payload;
    },
    get_ZArec_BR_storeMutation(state, payload) {
        state.ZArec_BR_list = payload;
    },
    change_ZArec_BR_selection_storeMutation(state, payload) {
        state.ZArec_BR_select = payload;
    },
    change_ZArec_BRUI_selection_storeMutation(state, payload) {
        state.ZArec_BRUI_select = payload;
    },
    change_ZArec_MNBR_selection_storeMutation(state, payload) {
        state.ZArec_MNBR_select = payload;
    },
    get_ZArec_BRLT_storeMutation(state, payload) {
        state.ZArec_BRLT_list = payload;
    },
    change_ZArec_BRLT_selection_storeMutation(state, payload) {
        state.ZArec_BRLT_select = payload;
    },
    change_ZArec_BRLTUI_selection_storeMutation(state, payload) {
        state.ZArec_BRLTUI_select = payload;
    },
    change_ZArec_MNBRLT_selection_storeMutation(state, payload) {
        state.ZArec_MNBRLT_select = payload;
    },
    get_ZArec_ZApad_storeMutation(state, payload) {
        state.ZArec_ZApad_list = payload;
    },
    get_RLD_Typ_storeMutation(state, payload) {
        state.RLD_Typ_list = payload;
    },
    change_RLD_Typ_selection_storeMutation(state, payload) {
        state.RLD_Typ_select = payload;
    },
    change_RLD_TypUI_selection_storeMutation(state, payload) {
        state.RLD_TypUI_select = payload;
    },
    change_RLD_MNTyp_selection_storeMutation(state, payload) {
        state.RLD_MNTyp_select = payload;
    },
    get_RLD_FEF_storeMutation(state, payload) {
        state.RLD_FEF_list = payload;
    },
    change_RLD_FEF_selection_storeMutation(state, payload) {
        state.RLD_FEF_select = payload;
    },
    change_RLD_FEFUI_selection_storeMutation(state, payload) {
        state.RLD_FEFUI_select = payload;
    },
    change_RLD_MNFEF_selection_storeMutation(state, payload) {
        state.RLD_MNFEF_select = payload;
    },
    change_ZArec_ZApad_selection_storeMutation(state, payload) {
        state.ZArec_ZApad_select = payload;
    },
    change_ZArec_ZApadUI_selection_storeMutation(state, payload) {
        state.ZArec_ZApadUI_select = payload;
    },
    change_ZArec_MNZApad_selection_storeMutation(state, payload) {
        state.ZArec_MNZApad_select = payload;
    },
    get_ZArec_BAL_storeMutation(state, payload) {
        state.ZArec_BAL_list = payload;
    },
    change_ZArec_BAL_selection_storeMutation(state, payload) {
        state.ZArec_BAL_select = payload;
    },
    change_ZArec_BALUI_selection_storeMutation(state, payload) {
        state.ZArec_BALUI_select = payload;
    },
    change_ZArec_MNBAL_selection_storeMutation(state, payload) {
        state.ZArec_MNBAL_select = payload;
    },
    change_FI_fuer_suche_verwenden_selection_storeMutation(state, payload) {
        state.FI_fuer_suche_verwenden = payload
    },

    //change_EVAC_toDefault_selection_storeMutation(state) {
    //    state.EVAC_Typ_select = undefined;
    //    state.EVAC_Batt_select = undefined;
    //    state.EVAC_Bal_select = undefined;
    //    state.EVAC_Cable_select = undefined;
    //    state.EVAC_Control_Cable_select = undefined;
    //},
    //change_FI_fuer_suche_verwenden_selection_storeMutation(state, payload) {
    //    state.FI_fuer_suche_verwenden = payload
    //},
    change_ZArec_Typ_checkboxSelection_storeMutation(state, payload) {

        for (let i = 0; i < state.ZArec_Typ_list.length; i++) {
            state.ZArec_Typ_list[i].selected = false;

            if (state.ZArec_Typ_list[i].text.text == payload) {
                state.ZArec_Typ_list[i].selected = true;
            }
        }
    },
    change_ZArec_LS_checkboxSelection_storeMutation(state, payload) {
        for (let i = 0; i < state.ZArec_LS_list.length; i++) {
            state.ZArec_LS_list[i].selected = false;

            if (state.ZArec_LS_list[i].text.text == payload) {
                state.ZArec_LS_list[i].selected = true;
            }
        }
    },
    change_ZArec_BR_checkboxSelection_storeMutation(state, payload) {
        for (let i = 0; i < state.ZArec_BR_list.length; i++) {
            state.ZArec_BR_list[i].selected = false;

            if (state.ZArec_BR_list[i].text.text == payload) {
                state.ZArec_BR_list[i].selected = true;
            }
        }
    },
    change_ZArec_BRLT_checkboxSelection_storeMutation(state, payload) {
        
        for (let i = 0; i < state.ZArec_BRLT_list.length; i++) {
            state.ZArec_BRLT_list[i].selected = false;
            
            if (state.ZArec_BRLT_list[i].text.text == payload) {
                state.ZArec_BRLT_list[i].selected = true;
            }
        }
    },
    change_ZArec_ZApad_checkboxSelection_storeMutation(state, payload) {
        for (let i = 0; i < state.ZArec_ZApad_list.length; i++) {
            state.ZArec_ZApad_list[i].selected = false;

            if (state.ZArec_ZApad_list[i].text.text == payload) {
                state.ZArec_ZApad_list[i].selected = true;
            }
        }
    },
    change_ZArec_BAL_checkboxSelection_storeMutation(state, payload) {
        for (let i = 0; i < state.ZArec_BAL_list.length; i++) {
            state.ZArec_BAL_list[i].selected = false;

            if (state.ZArec_BAL_list[i].text.text == payload) {
                state.ZArec_BAL_list[i].selected = true;
            }
        }
    },
    change_RLD_Typ_checkboxSelection_storeMutation(state, payload) {
        for (let i = 0; i < state.RLD_Typ_list.length; i++) {
            state.RLD_Typ_list[i].selected = false;

            if (state.RLD_Typ_list[i].text.text == payload) {
                state.RLD_Typ_list[i].selected = true;
            }
        }
    },
    change_RLD_FEF_checkboxSelection_storeMutation(state, payload) {
        for (let i = 0; i < state.RLD_FEF_list.length; i++) {
            state.RLD_FEF_list[i].selected = false;

            if (state.RLD_FEF_list[i].text.text == payload) {
                state.RLD_FEF_list[i].selected = true;
            }
        }
    },

    change_ZArec_toDefault_selection_storeMutation(state, payload) {
        state.ZArec_Typ_select = null;
        state.ZArec_TypUI_select = null;
        state.ZArec_MNTyp_select = null;
        state.ZArec_LS_select = null;
        state.ZArec_LSUI_select = null;
        state.ZArec_MNLS_select = null;
        state.ZArec_BR_select = null;
        state.ZArec_BRUI_select = null;
        state.ZArec_MNBR_select = null;
        state.ZArec_BRLT_select = null;
        state.ZArec_BRLTUI_select = null;
        state.ZArec_MNBRLT_select = null;
        state.ZArec_ZApad_select = null;
        state.ZArec_ZApadUI_select = null;
        state.ZArec_MNZApad_select = null;
        state.ZArec_BAL_select = null;
        state.ZArec_BALUI_select = null;
        state.ZArec_MNBAL_select = null;
        state.RLD_Typ_select = null;
        state.RLD_TypUI_select = null;
        state.RLD_MNTyp_select = null;
        state.RLD_FEF_select = null;
        state.RLD_FEFUI_select = null;
        state.RLD_MNFEF_select = null;

        //for (let i = 0; i < state.ZArec_Typ_list.length; i++) {
        //    state.ZArec_Typ_list[i].selected = false;
        //}
        //for (let i = 0; i < state.ZArec_LS_list.length; i++) {
        //    state.ZArec_LS_list[i].selected = false;
            
        //}
        //for (let i = 0; i < state.ZArec_BR_list.length; i++) {
        //    state.ZArec_BR_list[i].selected = false;
           
        //}
        //for (let i = 0; i < state.ZArec_BRLT_list.length; i++) {
        //    state.ZArec_BRLT_list[i].selected = false;
        //}
        //for (let i = 0; i < state.ZArec_ZApad_list.length; i++) {
        //    state.ZArec_ZApad_list[i].selected = false;
        //}
        //for (let i = 0; i < state.ZArec_BAL_list.length; i++) {
        //    state.ZArec_BAL_list[i].selected = false;
        //}  
        //for (let i = 0; i < state.RLD_Typ_list.length; i++) {
        //    state.RLD_Typ_list[i].selected = false;
        //}  
        //for (let i = 0; i < state.RLD_FEF_list.length; i++) {
        //    state.RLD_FEF_list[i].selected = false;
        //}                

        state.ZArec_Typ_list = [];
        state.ZArec_LS_list = [];
        state.ZArec_BRLT_list = [];
        state.ZArec_ZApad_list = [];
        state.ZArec_BAL_list = [];
        state.RLD_Typ_list = [];
        state.RLD_FEF_list = [];
    },
    
}
export const ZArec = {
    namespaced: true,
    components: {
        axios,
    },
    state,
    actions,
    mutations
}