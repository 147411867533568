import axios from 'axios';


let state = {
    userPreference_Modal_Shown: false,

    loader_forLoadingResults_at_userPreferences_Modal_Shown: false,
    cancellationInformationsAboutHttpRequests_get_userPreference_ValueListsAndSelections_Informations: new AbortController(),
    cancellationInformationsAboutHttpRequests_get_userPreference_PermissibleValueRanges_Informations: new AbortController(),
    cancellationInformationsAboutHttpRequests_get_userPreference_Informations: new AbortController(),

    userPreferences_Loaded: false,

    userPreferences_ValueListsAndSelections: {},
    userPreferences_PermissibleValueRanges: {},
    userPreferences_unintendedCarMovementCheck_PermissibleValueRanges: {},

    userPreferences: {},
    preferences_at_databaseRecord_users_haveToBeUpdated: false,

    unintendedCarMovementCheck_DeadTime: undefined,
    unintendedCarMovementCheck_DeadTime_ValidationResult: {},
    unintendedCarMovementCheck_DoorZone: undefined,
    unintendedCarMovementCheck_DoorZone_ValidationResult: {},

    energyEfficiencyRating_Standby_PowerConsumptionOfCarLightAndElevatorControlSystem: undefined,
    energyEfficiencyRating_Standby_PowerConsumptionOfCarLightAndElevatorControlSystem_ValidationResult: {},
    energyEfficiencyRating_ElectricityCosts: undefined,
    energyEfficiencyRating_ElectricityCosts_ValidationResult: {},

    //generalConfig
    erp_username: '',
}

const actions = {
    async change_userPreference_Modal_Shown_storeAction({ commit, dispatch }, value) {
        if (value == true) {
            commit('change_userPreference_Modal_Shown_storeMutation', value)
            commit('change_loader_forLoadingResults_at_userPreferences_Modal_Shown_storeMutation', true)
            await dispatch('get_userPreference_ValueListsAndSelections_Informations_storeAction');
            await dispatch('get_userPreference_PermissibleValueRanges_Informations_storeAction');
            await dispatch('get_userPreference_unintendedCarMovementCheck_PermissibleValueRanges_Informations_storeAction');
            await dispatch('get_userPreference_Informations_storeAction');
            commit('change_loader_forLoadingResults_at_userPreferences_Modal_Shown_storeMutation', false)
        }
        else if (value == false) {
            commit('abort_httpRequests_get_userPreference_ValueListsAndSelections_Informations_storeMutation');
            commit('abort_httpRequests_get_userPreference_PermissibleValueRanges_Informations_storeMutation');
            commit('abort_httpRequests_get_userPreference_Informations_storeMutation');
            commit('change_loader_forLoadingResults_at_userPreferences_Modal_Shown_storeMutation', false)

            if (state.preferences_at_databaseRecord_users_haveToBeUpdated == true) {
                await dispatch('update_databaseRecord_at_users_bySetting_Preferences_storeAction');
            }
            commit('change_userPreference_Modal_Shown_storeMutation', value)
        }
    },

    async get_userPreference_ValueListsAndSelections_Informations_storeAction({ commit }) {
        commit('abort_httpRequests_get_userPreference_ValueListsAndSelections_Informations_storeMutation');
        if (state.cancellationInformationsAboutHttpRequests_get_userPreference_ValueListsAndSelections_Informations.signal.aborted == true) {
            commit('change_cancellationInformationsAboutHttpRequests_get_userPreference_ValueListsAndSelections_Informations_toNewAbortController_storeMutation')
        }

        await axios({
            method: 'post',
            url: '/api/users/get_userPreference_ValueListsAndSelections_Informations',
            signal: state.cancellationInformationsAboutHttpRequests_get_userPreference_ValueListsAndSelections_Informations.signal,
        })
            .then((response) => {
                this.dispatch('statusHandling/change_dataAndStatus_storeAction', response.data)

                if ((response.data.status.statusCode.startsWith('2')) || (response.data.status.statusCode.startsWith('74')) || (response.data.status.statusCode.startsWith('75'))) {
                    commit('change_userPreferences_ValueListsAndSelections_storeMutation', response.data.data)
                }
            })
    },
    async get_userPreference_PermissibleValueRanges_Informations_storeAction({ commit }) {
        commit('abort_httpRequests_get_userPreference_PermissibleValueRanges_Informations_storeMutation');
        if (state.cancellationInformationsAboutHttpRequests_get_userPreference_PermissibleValueRanges_Informations.signal.aborted == true) {
            commit('change_cancellationInformationsAboutHttpRequests_get_userPreference_PermissibleValueRanges_Informations_toNewAbortController_storeMutation')
        }

        await axios({
            method: 'post',
            url: '/api/controlTechnologies/get_energyEfficiencyRating_PermissibleValueRanges_Informations',
            signal: state.cancellationInformationsAboutHttpRequests_get_userPreference_PermissibleValueRanges_Informations.signal,
        })
            .then((response) => {
                this.dispatch('statusHandling/change_dataAndStatus_storeAction', response.data);

                if ((response.data.status.statusCode.substring(0, 1) == '2') || (response.data.status.statusCode.substring(0, 2) == '74') || (response.data.status.statusCode.substring(0, 2) == '75')) {
                    commit('change_userPreferences_PermissibleValueRanges_storeMutation', response.data.data.energyEfficiencyRating_PermissibleValueRanges)
                }
            })
            .catch(() => { })
    },
    async get_userPreference_unintendedCarMovementCheck_PermissibleValueRanges_Informations_storeAction({ commit }) {
        commit('abort_httpRequests_get_userPreference_PermissibleValueRanges_Informations_storeMutation');
        if (state.cancellationInformationsAboutHttpRequests_get_userPreference_PermissibleValueRanges_Informations.signal.aborted == true) {
            commit('change_cancellationInformationsAboutHttpRequests_get_userPreference_PermissibleValueRanges_Informations_toNewAbortController_storeMutation')
        }

        await axios({
            method: 'post',
            url: '/api/users/get_userPreference_unintendedCarMovementCheck_Inputs_PermissibleValueRanges_Informations',
            signal: state.cancellationInformationsAboutHttpRequests_get_userPreference_PermissibleValueRanges_Informations.signal,
        })
            .then((response) => {
                this.dispatch('statusHandling/change_dataAndStatus_storeAction', response.data);

                if ((response.data.status.statusCode.substring(0, 1) == '2') || (response.data.status.statusCode.substring(0, 2) == '74') || (response.data.status.statusCode.substring(0, 2) == '75')) {
                    commit('change_userPreferences_unintendenCarMovementCheck_PermissibleValueRanges_storeMutation', response.data.data.unintendedCarMovementCheck_Inputs_ValueListAndSelectionsAndPermissibleValueRanges.unintendedCarMovementCheck_PermissibleValueRanges)
                }
            })
            .catch(() => { })
    },
    async get_userPreference_Informations_storeAction({ commit }) {
        commit('change_userPreferences_Loaded_storeMutation', false)

        commit('abort_httpRequests_get_userPreference_Informations_storeMutation');
        if (state.cancellationInformationsAboutHttpRequests_get_userPreference_Informations.signal.aborted == true) {
            commit('change_cancellationInformationsAboutHttpRequests_get_userPreference_Informations_toNewAbortController_storeMutation')
        }

        await axios({
            method: 'post',
            url: '/api/users/get_userPreference_Informations',
            signal: state.cancellationInformationsAboutHttpRequests_get_userPreference_Informations.signal,

        })
            .then((response) => {
                this.dispatch('statusHandling/change_dataAndStatus_storeAction', response.data);

                if ((response.data.status.statusCode.substring(0, 1) == '2') || (response.data.status.statusCode.substring(0, 2) == '74') || (response.data.status.statusCode.substring(0, 2) == '75')) {
                    commit('change_userPreferences_storeMutation', response.data.data.userPreferences)

                    if (response.data.data.userPreferences.hasOwnProperty('unintendedCarMovementCheck_DoorZone') == true) {
                        commit('change_unintendedCarMovementCheck_DoorZone_storeMutation', { value: response.data.data.userPreferences.unintendedCarMovementCheck_DoorZone, invalid: false })
                    }
                    else {
                        commit('change_unintendedCarMovementCheck_DoorZone_storeMutation', { value: null, invalid: false })
                    }
                    if (response.data.data.userPreferences.hasOwnProperty('unintendedCarMovementCheck_DeadTime') == true) {
                        commit('change_unintendedCarMovementCheck_DeadTime_storeMutation', { value: response.data.data.userPreferences.unintendedCarMovementCheck_DeadTime, invalid: false })
                    }
                    else {
                        commit('change_unintendedCarMovementCheck_DeadTime_storeMutation', { value: null, invalid: false })
                    }

                    if (response.data.data.userPreferences.hasOwnProperty('energyEfficiencyRating_Standby_PowerConsumptionOfCarLightAndElevatorControlSystem') == true) {
                        commit('change_energyEfficiencyRating_Standby_PowerConsumptionOfCarLightAndElevatorControlSystem_storeMutation', { value: response.data.data.userPreferences.energyEfficiencyRating_Standby_PowerConsumptionOfCarLightAndElevatorControlSystem, invalid: false })
                    }
                    else {
                        commit('change_energyEfficiencyRating_Standby_PowerConsumptionOfCarLightAndElevatorControlSystem_storeMutation', { value: null, invalid: false })
                    }

                    if (response.data.data.userPreferences.hasOwnProperty('energyEfficiencyRating_ElectricityCosts') == true) {
                        commit('change_energyEfficiencyRating_ElectricityCosts_storeMutation', { value: response.data.data.userPreferences.energyEfficiencyRating_ElectricityCosts, invalid: false })
                    }
                    else {
                        commit('change_energyEfficiencyRating_ElectricityCosts_storeMutation', { value: null, invalid: false })
                    }
                }
            })
            .catch(() => { })
            .then(() => {
                commit('change_userPreferences_Loaded_storeMutation', true)
            })
    },
    /////////////////////////////////////
    async update_databaseRecord_at_users_bySetting_Preferences_storeAction({ commit }) {
        this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: true, reasonWhy_loaderModal_Shown: null });

        const data = {
            userPreferences: state.userPreferences,
        };


        await axios({
            method: 'post',
            url: '/api/users/update_databaseRecord_at_users_bySetting_Preferences',
            data: {
                data: data,
            }
        })
            .then((response) => {
                this.dispatch('statusHandling/change_dataAndStatus_storeAction', response.data);

                if (response.data.status.statusCode.startsWith('2')) {
                    commit('change_preferences_at_databaseRecord_users_haveToBeUpdated_storeMutation', false)
                }
            })
            .catch(() => { })
            .then(() => {
                this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
            })
    },


    // driveTechnologies
    change_elevatorMotor_Machine_Series_at_userPreferences_storeAction({ commit }, value) {
        var userPreferences_elevatorMotor_Machine_Series = [];

        if (state.userPreferences.hasOwnProperty('elevatorMotor_Machine_Series') == true) {
            userPreferences_elevatorMotor_Machine_Series = userPreferences_elevatorMotor_Machine_Series.concat(state.userPreferences.elevatorMotor_Machine_Series)

            const index = userPreferences_elevatorMotor_Machine_Series.findIndex(i => i == value);
            if (index > -1) {
                userPreferences_elevatorMotor_Machine_Series.splice(index, 1);
            }
            else {
                userPreferences_elevatorMotor_Machine_Series.push(value);
            }
        }
        else {
            userPreferences_elevatorMotor_Machine_Series.push(value);
        }

        if (userPreferences_elevatorMotor_Machine_Series.length > 0) {
            commit('change_elevatorMotor_Machine_Series_at_userPreferences_storeMutation', userPreferences_elevatorMotor_Machine_Series)
        }
        else {
            commit('change_elevatorMotor_Machine_Series_at_userPreferences_storeMutation', null)
        }

        commit('change_preferences_at_databaseRecord_users_haveToBeUpdated_storeMutation', true)
    },
    change_use_PT100_for_elevatorMotor_TemperatureMonitoring_at_userPreferences_storeAction({ commit }, value) {
        commit('change_use_PT100_for_elevatorMotor_TemperatureMonitoring_at_userPreferences_storeMutation', value)
        commit('change_preferences_at_databaseRecord_users_haveToBeUpdated_storeMutation', true)
    },
    change_useOf_elevatorMotor_ForcedVentilation_at_userPreferences_storeAction({ commit }, value) {
        commit('change_useOf_elevatorMotor_ForcedVentilation_at_userPreferences_storeMutation', value)
        commit('change_preferences_at_databaseRecord_users_haveToBeUpdated_storeMutation', true)
    },
    change_elevatorMotor_Brake_NominalVoltage_at_userPreferences_storeAction({ commit }, value) {
        commit('change_elevatorMotor_Brake_NominalVoltage_at_userPreferences_storeMutation', value)
        commit('change_preferences_at_databaseRecord_users_haveToBeUpdated_storeMutation', true)
    },
    change_elevatorMotor_Brake_MechanicalRelease_at_userPreferences_storeAction({ commit }, value) {
        commit('change_elevatorMotor_Brake_MechanicalRelease_at_userPreferences_storeMutation', value)
        commit('change_preferences_at_databaseRecord_users_haveToBeUpdated_storeMutation', true)
    },
    change_elevatorMotor_Brake_ReleaseMonitoring_at_userPreferences_storeAction({ commit }, value) {
        commit('change_elevatorMotor_Brake_ReleaseMonitoring_at_userPreferences_storeMutation', value)
        commit('change_preferences_at_databaseRecord_users_haveToBeUpdated_storeMutation', true)
    },
    change_elevatorMotor_Encoder_at_userPreferences_storeAction({ commit }, value) {
        commit('change_elevatorMotor_Encoder_at_userPreferences_storeMutation', value)
        commit('change_preferences_at_databaseRecord_users_haveToBeUpdated_storeMutation', true)
    },

    // controlTechnologies
    change_inverter_Series_at_userPreferences_storeAction({ commit }, value) {
        var userPreferences_inverter_Series = [];

        if (state.userPreferences.hasOwnProperty('inverter_Series') == true) {
            userPreferences_inverter_Series = userPreferences_inverter_Series.concat(state.userPreferences.inverter_Series)

            const index = userPreferences_inverter_Series.findIndex(i => i == value);
            if (index > -1) {
                userPreferences_inverter_Series.splice(index, 1);
            }
            else {
                userPreferences_inverter_Series.push(value);
            }
        }
        else {
            userPreferences_inverter_Series.push(value);
        }

        if (userPreferences_inverter_Series.length > 0) {
            commit('change_inverter_Series_at_userPreferences_storeMutation', userPreferences_inverter_Series)
        }
        else {
            commit('change_inverter_Series_at_userPreferences_storeMutation', null)
        }

        commit('change_preferences_at_databaseRecord_users_haveToBeUpdated_storeMutation', true)
    },
    change_unintendedCarMovementCheck_CalculatedWithShortCircuitMotorBraking_at_userPreferences_storeAction({ commit }, value) {
        commit('change_unintendedCarMovementCheck_CalculatedWithShortCircuitMotorBraking_at_userPreferences_storeMutation', value)
        commit('change_preferences_at_databaseRecord_users_haveToBeUpdated_storeMutation', true)
    },
    change_unintendedCarMovementCheck_SpeedLimit_at_userPreferences_storeAction({ commit }, value) {
        commit('change_unintendedCarMovementCheck_SpeedLimit_at_userPreferences_storeMutation', value)
        commit('change_preferences_at_databaseRecord_users_haveToBeUpdated_storeMutation', true)
    },
    change_unintendedCarMovementCheck_DoorZone_at_userPreferences_storeAction({ commit }, value) {
        const valueAndValueValidationCriteria = this._vm.$transmit_valueAndValueValidationCriteria;
        valueAndValueValidationCriteria.value = value;
        valueAndValueValidationCriteria.dataType = this._vm.$dataTypes.integer;
        valueAndValueValidationCriteria.permissibleNumberOfDecimalPlaces = null;  

        valueAndValueValidationCriteria.permissibleValueRange = state.userPreferences_unintendedCarMovementCheck_PermissibleValueRanges.doorZone;
        const change_unintendedCarMovementCheck_DoorZone_ValidationResult = this._vm.$checkIf_value_isValid(valueAndValueValidationCriteria);

        commit('change_unintendedCarMovementCheck_DoorZone_storeMutation', change_unintendedCarMovementCheck_DoorZone_ValidationResult)

        if (change_unintendedCarMovementCheck_DoorZone_ValidationResult.invalid == false) {
            commit('change_unintendedCarMovementCheck_DoorZone_at_userPreferences_storeMutation', change_unintendedCarMovementCheck_DoorZone_ValidationResult.value)
        }
        else {
            commit('change_unintendedCarMovementCheck_DoorZone_at_userPreferences_storeMutation', null)
        }
        commit('change_preferences_at_databaseRecord_users_haveToBeUpdated_storeMutation', true)
    },
    change_unintendedCarMovementCheck_DeadTime_at_userPreferences_storeAction({ commit }, value) {
        const valueAndValueValidationCriteria = this._vm.$transmit_valueAndValueValidationCriteria;
        valueAndValueValidationCriteria.value = value;
        valueAndValueValidationCriteria.dataType = this._vm.$dataTypes.integer;
        valueAndValueValidationCriteria.permissibleNumberOfDecimalPlaces = null;

        valueAndValueValidationCriteria.permissibleValueRange = state.userPreferences_unintendedCarMovementCheck_PermissibleValueRanges.deadTime;
        const change_unintendedCarMovementCheck_DeadTime_ValidationResult = this._vm.$checkIf_value_isValid(valueAndValueValidationCriteria);

        commit('change_unintendedCarMovementCheck_DeadTime_storeMutation', change_unintendedCarMovementCheck_DeadTime_ValidationResult)

        if (change_unintendedCarMovementCheck_DeadTime_ValidationResult.invalid == false) {
            commit('change_unintendedCarMovementCheck_DeadTime_at_userPreferences_storeMutation', change_unintendedCarMovementCheck_DeadTime_ValidationResult.value)
        }
        else {
            commit('change_unintendedCarMovementCheck_DeadTime_at_userPreferences_storeMutation', null)
        }
        commit('change_preferences_at_databaseRecord_users_haveToBeUpdated_storeMutation', true)
    },
    change_productTypeForConversionOfRegenerativeEnergy_at_userPreferences_storeAction({ commit }, value) {
        commit('change_productTypeForConversionOfRegenerativeEnergy_at_userPreferences_storeMutation', value)
        commit('change_preferences_at_databaseRecord_users_haveToBeUpdated_storeMutation', true)
    },
    change_energyEfficiencyRating_Standby_PowerConsumptionOfCarLightAndElevatorControlSystem_at_userPreferences_storeAction({ commit }, value) {
        const valueAndValueValidationCriteria = this._vm.$transmit_valueAndValueValidationCriteria;
        valueAndValueValidationCriteria.value = value;
        valueAndValueValidationCriteria.dataType = this._vm.$dataTypes.decimal;
        valueAndValueValidationCriteria.permissibleNumberOfDecimalPlaces = 2;
        valueAndValueValidationCriteria.permissibleValueRange = state.userPreferences_PermissibleValueRanges.standbyPowerConsumptionOfCarLightAndElevatorControlSystem;
        const energyEfficiencyRating_Standby_PowerConsumptionOfCarLightAndElevatorControlSystem_ValidationResult = this._vm.$checkIf_value_isValid(valueAndValueValidationCriteria);

        commit('change_energyEfficiencyRating_Standby_PowerConsumptionOfCarLightAndElevatorControlSystem_storeMutation', energyEfficiencyRating_Standby_PowerConsumptionOfCarLightAndElevatorControlSystem_ValidationResult)

        if (energyEfficiencyRating_Standby_PowerConsumptionOfCarLightAndElevatorControlSystem_ValidationResult.invalid == false) {
            commit('change_energyEfficiencyRating_Standby_PowerConsumptionOfCarLightAndElevatorControlSystem_at_userPreferences_storeMutation', energyEfficiencyRating_Standby_PowerConsumptionOfCarLightAndElevatorControlSystem_ValidationResult.value)
        }
        else {
            commit('change_energyEfficiencyRating_Standby_PowerConsumptionOfCarLightAndElevatorControlSystem_at_userPreferences_storeMutation', 0)
        }

        commit('change_preferences_at_databaseRecord_users_haveToBeUpdated_storeMutation', true)
    },
    change_energyEfficiencyRating_ElectricityCosts_at_userPreferences_storeAction({ commit }, value) {
        const valueAndValueValidationCriteria = this._vm.$transmit_valueAndValueValidationCriteria;
        valueAndValueValidationCriteria.value = value;
        valueAndValueValidationCriteria.dataType = this._vm.$dataTypes.decimal;
        valueAndValueValidationCriteria.permissibleNumberOfDecimalPlaces = 2;
        valueAndValueValidationCriteria.permissibleValueRange = state.userPreferences_PermissibleValueRanges.electricityCostsPerKilowattHour;
        const energyEfficiencyRating_ElectricityCosts_ValidationResult = this._vm.$checkIf_value_isValid(valueAndValueValidationCriteria);

        commit('change_energyEfficiencyRating_ElectricityCosts_storeMutation', energyEfficiencyRating_ElectricityCosts_ValidationResult)

        if (energyEfficiencyRating_ElectricityCosts_ValidationResult.invalid == false) {
            commit('change_energyEfficiencyRating_ElectricityCosts_at_userPreferences_storeMutation', energyEfficiencyRating_ElectricityCosts_ValidationResult.value)
        }
        else {
            commit('change_energyEfficiencyRating_ElectricityCosts_at_userPreferences_storeMutation', 0)
        }

        commit('change_preferences_at_databaseRecord_users_haveToBeUpdated_storeMutation', true)
    },
    change_erpUsername_at_userPreferences_storeAction({ commit }, value) {
        commit('change_erpUsername_stateMutation', value);
        commit('change_preferences_at_databaseRecord_users_haveToBeUpdated_storeMutation', true)
    },
    change_lowestUserLevel_at_userPreferences_storeAction({ commit }, value) {
        commit('change_lowestUserLevel_stateMutation', value)
        commit('change_preferences_at_databaseRecord_users_haveToBeUpdated_storeMutation', true)
    },

    set_decimalValue({ dispatch }, value) {
        const valueDecimalCriteria = this._vm.$localDecimal;
        valueDecimalCriteria.value = value.value;
        if (value.country == 'de') {
            valueDecimalCriteria.country = 'de-DE';
        }
        else {
            valueDecimalCriteria.country = 'en-US';
        }

        const decimalValue = this._vm.$set_decimalValueToLocaleString(valueDecimalCriteria);
        if (value.name == 'speedLimit') {
            dispatch('change_unintendedCarMovementCheck_SpeedLimit_at_userPreferences_storeAction', decimalValue);
        }
    }


}

const mutations = {
    change_userPreference_Modal_Shown_storeMutation(state, payload) {
        state.userPreference_Modal_Shown = payload;
    },
    change_preferences_at_databaseRecord_users_haveToBeUpdated_storeMutation(state, payload) {
        state.preferences_at_databaseRecord_users_haveToBeUpdated = payload;
    },

    change_userPreferences_Loaded_storeMutation(state, payload) {
        state.userPreferences_Loaded = payload;
    },

    change_loader_forLoadingResults_at_userPreferences_Modal_Shown_storeMutation(state, payload) {
        state.loader_forLoadingResults_at_userPreferences_Modal_Shown = payload;
    },
    abort_httpRequests_get_userPreference_ValueListsAndSelections_Informations_storeMutation(state) {
        state.cancellationInformationsAboutHttpRequests_get_userPreference_ValueListsAndSelections_Informations.abort();
    },
    change_cancellationInformationsAboutHttpRequests_get_userPreference_ValueListsAndSelections_Informations_toNewAbortController_storeMutation(state) {
        state.cancellationInformationsAboutHttpRequests_get_userPreference_ValueListsAndSelections_Informations = new AbortController();
    },
    abort_httpRequests_get_userPreference_PermissibleValueRanges_Informations_storeMutation(state) {
        state.cancellationInformationsAboutHttpRequests_get_userPreference_PermissibleValueRanges_Informations.abort();
    },
    change_cancellationInformationsAboutHttpRequests_get_userPreference_PermissibleValueRanges_Informations_toNewAbortController_storeMutation(state) {
        state.cancellationInformationsAboutHttpRequests_get_userPreference_PermissibleValueRanges_Informations = new AbortController();
    },
    abort_httpRequests_get_userPreference_Informations_storeMutation(state) {
        state.cancellationInformationsAboutHttpRequests_get_userPreference_Informations.abort();
    },
    change_cancellationInformationsAboutHttpRequests_get_userPreference_Informations_toNewAbortController_storeMutation(state) {
        state.cancellationInformationsAboutHttpRequests_get_userPreference_Informations = new AbortController();
    },

    change_userPreferences_ValueListsAndSelections_storeMutation(state, payload) {
        state.userPreferences_ValueListsAndSelections = payload
    },
    change_userPreferences_PermissibleValueRanges_storeMutation(state, payload) {
        state.userPreferences_PermissibleValueRanges = payload;
    },
    change_userPreferences_unintendenCarMovementCheck_PermissibleValueRanges_storeMutation(state, payload) {
        state.userPreferences_unintendedCarMovementCheck_PermissibleValueRanges = payload;
    },
    change_userPreferences_storeMutation(state, payload) {
        state.userPreferences = payload;
    },


    // driveTechnologies
    change_elevatorMotor_Machine_Series_at_userPreferences_storeMutation(state, payload) {
        state.userPreferences = { ...state.userPreferences, elevatorMotor_Machine_Series: payload };
    },
    change_use_PT100_for_elevatorMotor_TemperatureMonitoring_at_userPreferences_storeMutation(state, payload) {
        state.userPreferences = { ...state.userPreferences, use_PT100_For_ElevatorMotor_TemperatureMonitoring: payload };
    },
    change_useOf_elevatorMotor_ForcedVentilation_at_userPreferences_storeMutation(state, payload) {
        state.userPreferences = { ...state.userPreferences, useOf_ElevatorMotor_ForcedVentilation: payload };
    },
    change_elevatorMotor_Brake_NominalVoltage_at_userPreferences_storeMutation(state, payload) {
        state.userPreferences = { ...state.userPreferences, elevatorMotor_Brake_NominalVoltage: payload };
    },
    change_elevatorMotor_Brake_MechanicalRelease_at_userPreferences_storeMutation(state, payload) {
        state.userPreferences = { ...state.userPreferences, elevatorMotor_Brake_MechanicalRelease: payload };
    },
    change_elevatorMotor_Brake_ReleaseMonitoring_at_userPreferences_storeMutation(state, payload) {
        state.userPreferences = { ...state.userPreferences, elevatorMotor_Brake_ReleaseMonitoring: payload };
    },
    change_elevatorMotor_Encoder_at_userPreferences_storeMutation(state, payload) {
        state.userPreferences = { ...state.userPreferences, elevatorMotor_Encoder: payload };
    },

    // controlTechnologies
    change_inverter_Series_at_userPreferences_storeMutation(state, payload) {
        state.userPreferences = { ...state.userPreferences, inverter_Series: payload };
    },
    change_unintendedCarMovementCheck_CalculatedWithShortCircuitMotorBraking_at_userPreferences_storeMutation(state, payload) {
        state.userPreferences = { ...state.userPreferences, unintendedCarMovementCheck_CalculatedWithShortCircuitMotorBraking: payload };
    },
    change_unintendedCarMovementCheck_SpeedLimit_at_userPreferences_storeMutation(state, payload) {
        state.userPreferences = { ...state.userPreferences, unintendedCarMovementCheck_SpeedLimit: payload };
    },
    change_unintendedCarMovementCheck_DoorZone_at_userPreferences_storeMutation(state, payload) {
        state.userPreferences = { ...state.userPreferences, unintendedCarMovementCheck_DoorZone: payload };
    },
    change_unintendedCarMovementCheck_DeadTime_at_userPreferences_storeMutation(state, payload) {
        state.userPreferences = { ...state.userPreferences, unintendedCarMovementCheck_DeadTime: payload };
    },
    change_unintendedCarMovementCheck_DoorZone_storeMutation(state, payload) {
        state.unintendedCarMovementCheck_DoorZone = payload.value;
        state.unintendedCarMovementCheck_DoorZone_ValidationResult = payload;
    },
    change_unintendedCarMovementCheck_DeadTime_storeMutation(state, payload) {
        state.unintendedCarMovementCheck_DeadTime = payload.value;
        state.unintendedCarMovementCheck_DeadTime_ValidationResult = payload;
    },
    change_productTypeForConversionOfRegenerativeEnergy_at_userPreferences_storeMutation(state, payload) {
        state.userPreferences = { ...state.userPreferences, productTypeForConversionOfRegenerativeEnergy: payload };
    },
    change_energyEfficiencyRating_Standby_PowerConsumptionOfCarLightAndElevatorControlSystem_at_userPreferences_storeMutation(state, payload) {
        state.userPreferences = { ...state.userPreferences, energyEfficiencyRating_Standby_PowerConsumptionOfCarLightAndElevatorControlSystem: payload };
    },
    change_energyEfficiencyRating_ElectricityCosts_at_userPreferences_storeMutation(state, payload) {
        state.userPreferences = { ...state.userPreferences, energyEfficiencyRating_ElectricityCosts: payload };
    },
    change_energyEfficiencyRating_Standby_PowerConsumptionOfCarLightAndElevatorControlSystem_storeMutation(state, payload) {
        state.energyEfficiencyRating_Standby_PowerConsumptionOfCarLightAndElevatorControlSystem = payload.value;
        state.energyEfficiencyRating_Standby_PowerConsumptionOfCarLightAndElevatorControlSystem_ValidationResult = payload;
    },
    change_energyEfficiencyRating_ElectricityCosts_storeMutation(state, payload) {
        state.energyEfficiencyRating_ElectricityCosts = payload.value;
        state.energyEfficiencyRating_ElectricityCosts_ValidationResult = payload;
    },

    //general
    change_erpUsername_stateMutation(state, payload) {
        state.erp_username= payload;
        state.userPreferences = { ...state.userPreferences, erp_username: payload };
    },
    change_lowestUserLevel_stateMutation(state, payload) {
        state.userPreferences = { ...state.userPreferences, lowestUserLevel: payload };
    },
}

export const user = {
    namespaced: true,
    components: {
        axios
    },
    state,
    actions,
    mutations
}