export default {
    install: (app, options) => {
        app.prototype.$checkIf_value_isValid = (value) => {
            var returnValue = value.value
            var invalid = false;
            var invalidityReason = undefined;

            if ((value.value == null) || (value.value == 'null')) {
                value.value = '';
                returnValue = '';
            }

            if ((value.value instanceof String) == false) {
                value.value = value.value.toString();
            }

            if (value.value.length > 0) {
                if ((value.dataType == app.prototype.$dataTypes.integer) || (value.dataType == app.prototype.$dataTypes.decimal)) {
                    let couldBeANumber = true;

                    if ((value.permissibleValueRange.maximum.value == null) || (value.permissibleValueRange.maximum.operator == null)) {
                        value.permissibleValueRange.maximum.value = 2147483647;
                        value.permissibleValueRange.maximum.operator = app.prototype.$operators.lessThanOrEqualTo;
                    }

                    if (value.value.split('.').length == 2) {
                        if (value.value.split('.')[1].length == 0) {
                            couldBeANumber = false;
                        }
                    }

                    if ((Number.isNaN(Number(value.value)) == false) && couldBeANumber) {

                        var decimalPlaces = 0;
                        if ((value.value).indexOf(',') > 0) {
                            decimalPlaces = String(value.value).length - 1 - String(value.value).indexOf(',');
                        }
                        else if ((value.value).indexOf('.') > 0) {
                            decimalPlaces = String(value.value).length - 1 - String(value.value).indexOf('.');
                        }

                        returnValue = Number(value.value).toFixed(decimalPlaces)
                        if ((value.permissibleValueRange.minimum.value != null) && (value.permissibleValueRange.maximum.value != null)) {
                            if ((value.permissibleValueRange.minimum.operator == app.prototype.$operators.greaterThanOrEqualTo) && (value.permissibleValueRange.maximum.operator == app.prototype.$operators.lessThanOrEqualTo)) {
                                if ((value.value < value.permissibleValueRange.minimum.value) || (value.value > value.permissibleValueRange.maximum.value)) {
                                    invalid = true
                                    invalidityReason = app.prototype.$invalidityReasons.outOfPermissibleValueRange
                                }
                                else {
                                    const response = app.prototype.$checkIf_value_correspondsToDataType({ 'value': value.value, 'dataType': value.dataType, 'permissibleNumberOfDecimalPlaces': value.permissibleNumberOfDecimalPlaces })
                                    invalid = response.invalid
                                    invalidityReason = response.invalidityReason
                                }
                            }
                            else if ((value.permissibleValueRange.minimum.operator == app.prototype.$operators.greaterThanOrEqualTo) && (value.permissibleValueRange.maximum.operator == app.prototype.$operators.lessThan)) {
                                if ((value.value < value.permissibleValueRange.minimum.value) || (value.value >= value.permissibleValueRange.maximum.value)) {
                                    invalid = true
                                    invalidityReason = app.prototype.$invalidityReasons.outOfPermissibleValueRange
                                }
                                else {
                                    const response = app.prototype.$checkIf_value_correspondsToDataType({ 'value': value.value, 'dataType': value.dataType, 'permissibleNumberOfDecimalPlaces': value.permissibleNumberOfDecimalPlaces })
                                    invalid = response.invalid
                                    invalidityReason = response.invalidityReason
                                }
                            }
                            else if ((value.permissibleValueRange.minimum.operator == app.prototype.$operators.greaterThan) && (value.permissibleValueRange.maximum.operator == app.prototype.$operators.lessThanOrEqualTo)) {
                                if ((value.value <= value.permissibleValueRange.minimum.value) || (value.value > value.permissibleValueRange.maximum.value)) {
                                    invalid = true
                                    invalidityReason = app.prototype.$invalidityReasons.outOfPermissibleValueRange
                                }
                                else {
                                    const response = app.prototype.$checkIf_value_correspondsToDataType({ 'value': value.value, 'dataType': value.dataType, 'permissibleNumberOfDecimalPlaces': value.permissibleNumberOfDecimalPlaces })
                                    invalid = response.invalid
                                    invalidityReason = response.invalidityReason
                                }
                            }
                            else if ((value.permissibleValueRange.minimum.operator == app.prototype.$operators.greaterThan) && (value.permissibleValueRange.maximum.operator == app.prototype.$operators.lessThan)) {
                                if ((value.value <= value.permissibleValueRange.minimum.value) || (value.value >= value.permissibleValueRange.maximum.value)) {
                                    invalid = true
                                    invalidityReason = app.prototype.$invalidityReasons.outOfPermissibleValueRange
                                }
                                else {
                                    const response = app.prototype.$checkIf_value_correspondsToDataType({ 'value': value.value, 'dataType': value.dataType, 'permissibleNumberOfDecimalPlaces': value.permissibleNumberOfDecimalPlaces })
                                    invalid = response.invalid
                                    invalidityReason = response.invalidityReason
                                }
                            }
                            else {
                                // error
                            }
                        }
                        else if ((value.permissibleValueRange.minimum.value != null) && (value.permissibleValueRange.maximum.value == null)) {
                            if (value.permissibleValueRange.minimum.operator == app.prototype.$operators.greaterThanOrEqualTo) {
                                if (value.value < value.permissibleValueRange.minimum.value) {
                                    invalid = true
                                    invalidityReason = app.prototype.$invalidityReasons.outOfPermissibleValueRange
                                }
                                else {
                                    const response = app.prototype.$checkIf_value_correspondsToDataType({ 'value': value.value, 'dataType': value.dataType, 'permissibleNumberOfDecimalPlaces': value.permissibleNumberOfDecimalPlaces })
                                    invalid = response.invalid
                                    invalidityReason = response.invalidityReason
                                }
                            }
                            else if (value.permissibleValueRange.minimum.operator == app.prototype.$operators.greaterThan) {
                                if (value.value <= value.permissibleValueRange.minimum.value) {
                                    invalid = true
                                    invalidityReason = app.prototype.$invalidityReasons.outOfPermissibleValueRange
                                }
                                else {
                                    const response = app.prototype.$checkIf_value_correspondsToDataType({ 'value': value.value, 'dataType': value.dataType, 'permissibleNumberOfDecimalPlaces': value.permissibleNumberOfDecimalPlaces })
                                    invalid = response.invalid
                                    invalidityReason = response.invalidityReason
                                }
                            }
                            else {
                                // error
                            }
                        }
                        else if ((value.permissibleValueRange.minimum.value == null) && (value.permissibleValueRange.maximum.value != null)) {
                            if (value.permissibleValueRange.maximum.operator == app.prototype.$operators.lessThanOrEqualTo) {
                                if (value.value > value.permissibleValueRange.maximum.value) {
                                    invalid = true
                                    invalidityReason = app.prototype.$invalidityReasons.outOfPermissibleValueRange
                                }
                                else {
                                    const response = app.prototype.$checkIf_value_correspondsToDataType({ 'value': value.value, 'dataType': value.dataType, 'permissibleNumberOfDecimalPlaces': value.permissibleNumberOfDecimalPlaces })
                                    invalid = response.invalid
                                    invalidityReason = response.invalidityReason
                                }
                            }
                            else if (value.permissibleValueRange.maximum.operator == app.prototype.$operators.lessThan) {
                                if (value.value >= value.permissibleValueRange.maximum.value) {
                                    invalid = true
                                    invalidityReason = app.prototype.$invalidityReasons.outOfPermissibleValueRange
                                }
                                else {
                                    const response = app.prototype.$checkIf_value_correspondsToDataType({ 'value': value.value, 'dataType': value.dataType, 'permissibleNumberOfDecimalPlaces': value.permissibleNumberOfDecimalPlaces })
                                    invalid = response.invalid
                                    invalidityReason = response.invalidityReason
                                }
                            }
                            else {
                                // error
                            }
                        }
                        else {
                            // error
                        }
                    }
                    else {
                        invalid = true;
                        invalidityReason = app.prototype.$invalidityReasons.noNumber;
                    }
                }
                else {
                    if (value.value.length <= 1073741823) {
                        if (value.value.includes('"') || value.value.includes(',') || value.value.includes(';') || value.value.includes('<') || value.value.includes('>') || value.value.match(/\\$/)) {
                            invalid = true;
                            invalidityReason = app.prototype.$invalidityReasons.containsUnwantedCharacters;
                        }
                    }
                    else {
                        invalid = true;
                        invalidityReason = app.prototype.$invalidityReasons.characterLengthExceeded;
                    }
                }
            }
            else {
                invalid = true;
                invalidityReason = app.prototype.$invalidityReasons.notSet;
            }

            if (returnValue === '') {
                returnValue = null;
            }

            return { value: returnValue, invalid: invalid, invalidityReason: invalidityReason, permissibleNumberOfDecimalPlaces: value.permissibleNumberOfDecimalPlaces, permissibleValueRange: value.permissibleValueRange }
        }
        app.prototype.$checkIf_value_correspondsToDataType = (value) => {
            var invalid = false;
            var invalidityReason = null;

            if (value.dataType == app.prototype.$dataTypes.integer) {
                if (value.value.split('.').length > 1) {
                    invalid = true
                    invalidityReason = app.prototype.$invalidityReasons.noInteger
                }
            }
            else if (value.dataType == app.prototype.$dataTypes.decimal) {
                if (value.value.split('.').length > 1) {
                    if (value.value.split('.')[1].length > value.permissibleNumberOfDecimalPlaces) {
                        invalid = true
                        invalidityReason = app.prototype.$invalidityReasons.permissibleNumberOfDecimalPlacesExceeded
                    }
                }
            }

            return { 'invalid': invalid, 'invalidityReason': invalidityReason }
        }

        app.prototype.$set_decimalValueToLocaleString = (value) => {
            if (value.value != [] && value.value != null && isNaN(value.value) == true) {

                if (value.value.toString().slice(-1) != ",") {

                    var decimalPlaces = 0;
                    if ((value.value).indexOf(',') > 0) {
                        decimalPlaces = String(value.value).length - 1 - String(value.value).indexOf(',');
                    }
                    else if ((value.value).indexOf('.') > 0) {
                        decimalPlaces = String(value.value).length - 1 - String(value.value).indexOf('.');
                    }

                    const number = parseFloat(value.value.replace(',', '.')).toFixed(decimalPlaces);
                    if (isNaN(number) == false && number != [] && number != null) {
                        return number;
                    }
                    else {
                        return value.value;
                    }
                }
                else {
                    return value.value;
                }
            }
            else {
                return value.value;
            }
        }

        app.prototype.$get_decimalValueToLocaleString = (value) => {
            if (value.value != [] && value.value != null) {
                if (value.country == 'de') {
                    return String(value.value).replace(".",",")
                }
                else {
                    return String(value.value).replace(",", ".")
                }
            }
            else {
                return value.value;
            }
        }

        app.prototype.$transmit_valueAndValueValidationCriteria =
        {
            'value': null,
            'dataType': null,
            'permissibleNumberOfDecimalPlaces': null,
            'permissibleValueRange': {
                'minimum': {
                    'value': null,
                    'operator': null,
                },
                'maximum': {
                    'value': null,
                    'operator': null,
                }
            }
        }

        app.prototype.$localDecimal =
        {
            'country': null,
            'value': null
        }

        app.prototype.$dataTypes = {
            integer: 'integer',
            decimal: 'decimal',
            string: 'string',
        }
        app.prototype.$operators = {
            greaterThanOrEqualTo: 'greaterThanOrEqualTo',
            greaterThan: 'greaterThan',
            lessThanOrEqualTo: 'lessThanOrEqualTo',
            lessThan: 'lessThan',
        }
        app.prototype.$invalidityReasons = {
            notSet: 'notSet',
            noNumber: 'noNumber',
            noInteger: 'noInteger',
            containsUnwantedCharacters: 'containsUnwantedCharacters',
            characterLengthExceeded: 'characterLengthExceeded',
            outOfPermissibleValueRange: 'outOfPermissibleValueRange',
            permissibleNumberOfDecimalPlacesExceeded: 'permissibleNumberOfDecimalPlacesExceeded',
            minimumValueGreaterThanMaximumValue: 'minimumValueGreaterThanMaximumValue',
        }
    }
}

