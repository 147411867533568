import axios from 'axios';
import router from "@/router";

let state = {
    elevatorMotor_Machine_Manual: {},

    // elevatorMotor_Brake
    elevatorMotor_Brake_Manual_Data: {},
    elevatorMotor_Brake_Selections_Shown: true,

    // elevatorMotor_Brake_MechanicalRelease
    elevatorMotor_Brake_MechanicalRelease_ChangedByUser: false,
    // elevatorMotor_Brake_ReleaseMonitoring
    elevatorMotor_Brake_ReleaseMonitoring_ChangedByUser: false,


    // elevatorMotor_Encoder
    elevatorMotor_Encoder_Data: {},
    elevatorMotor_Encoder_Selection_Shown: true,
}

const actions = {
    change_driveTechnologies_toDefault_storeAction({ commit, dispatch }) {
        commit('change_elevatorMotor_Machine_Manual_storeMutation', {});
        dispatch('change_elevatorMotor_Brake_toDefault_storeAction')
        dispatch('change_elevatorMotor_Encoder_toDefault_storeAction')
    },


    async check_toDos_at_driveTechnologiesManual_storeAction({ rootState, commit, dispatch }) {
        this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: true, reasonWhy_loaderModal_Shown: 'data_BeingLoaded' });

        const configurationStepsManualId = rootState.inputsManual.configurationStepsManualId;
        await axios({
            method: 'post',
            url: '/api/driveTechnologiesManual/get_elevatorMotor_Machine_Manual_Informations/' + configurationStepsManualId,
        })
            .then((response) => {
                this.dispatch('statusHandling/change_dataAndStatus_storeAction', response.data);
                if ((response.data.status.statusCode.substring(0, 1) == '2') || (response.data.status.statusCode.substring(0, 2) == '74') || (response.data.status.statusCode.substring(0, 2) == '75')) {
                    commit('change_elevatorMotor_Machine_Manual_storeMutation', response.data.data);
                    dispatch('get_elevatorMotor_Brake_Manual_Informations_storeAction');
                    dispatch('get_elevatorMotor_Encoder_Informations_storeAction');
                }
            })
            .catch(() => { })
            .then(() => {
                this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
            })
    },

    change_elevatorMotor_Brake_toDefault_storeAction({ commit }) {
        commit('change_elevatorMotor_Brake_Manual_Data_storeMutation', {});
        commit('change_elevatorMotor_Brake_Selections_Shown_storeMutation', true);
        commit('change_loader_forLoadingData_at_elevatorMotor_Brake_Shown_storeMutation', false);
        commit('change_elevatorMotor_Brake_MechanicalRelease_ChangedByUser_storeMutation', false);
        commit('change_elevatorMotor_Brake_ReleaseMonitoring_ChangedByUser_storeMutation', false);

    },

    async get_elevatorMotor_Brake_Manual_Informations_storeAction({ rootState, dispatch }) {
        this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: true, reasonWhy_loaderModal_Shown: 'data_BeingLoaded' });

        const configurationStepsManualId = rootState.inputsManual.configurationStepsManualId;
        await axios({
            method: 'post',
            url: '/api/driveTechnologiesManual/get_elevatorMotor_Brake_Manual_Informations/' + configurationStepsManualId,
        })
            .then((response) => {
                this.dispatch('statusHandling/change_dataAndStatus_storeAction', response.data);

                if ((response.data.status.statusCode.substring(0, 1) == '2') || (response.data.status.statusCode.substring(0, 2) == '74') || (response.data.status.statusCode.substring(0, 2) == '75')) {
                    dispatch('toDos_afterResponseOf_get_elevatorMotor_Brake_Manual_Informations_storeAction', response.data.data)
                    if (state.elevatorMotor_Brake_Manual_Data != null && state.elevatorMotor_Brake_Manual_Data.hasOwnProperty('elevatorMotor_Brake_Selection')) {
                        const firstIndex_whichMeetConditions = state.elevatorMotor_Brake_Manual_Data.elevatorMotor_Brake_Selection.findIndex(i => i.selected == true);
                        if (firstIndex_whichMeetConditions > -1) {
                            dispatch('change_Selected_atFirstElementOf_elevatorMotor_Brake_Selection_toTrue_storeAction', firstIndex_whichMeetConditions);
                        }
                        else {
                            dispatch('change_Selected_atFirstElementOf_elevatorMotor_Brake_Selection_toTrue_storeAction', 0);
                        }
                    }
                    else {
                        dispatch('change_Selected_atFirstElementOf_elevatorMotor_Brake_Selection_toTrue_storeAction', 0);
                    }
                }

            })
            .catch(() => { })
            .then(() => {
                this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
            })
    },
    toDos_afterResponseOf_get_elevatorMotor_Brake_Manual_Informations_storeAction({ dispatch }, value) {
        dispatch('change_elevatorMotor_Brake_Manual_Data_storeAction', value.elevatorMotor_Brake_Data);
    },
    change_elevatorMotor_Brake_Manual_Data_storeAction({ commit }, value) {
        commit('change_elevatorMotor_Brake_Manual_Data_storeMutation', value);
    },
    change_elevatorMotor_Brake_Selections_Shown_storeAction({ commit }, value) {
        commit('change_elevatorMotor_Brake_Selections_Shown_storeMutation', value)
    },
    async change_Selected_atFirstElementOf_elevatorMotor_Brake_Selection_toTrue_storeAction({ commit, dispatch }, value) {
        dispatch('change_Selected_at_elevatorMotor_Brake_Selection_storeAction', value);
        dispatch('change_Selected_at_elevatorMotor_Brake_Voltage_Selection_basedOnChangesTo_Selected_at_elevatorMotor_Brake_Selection_storeAction');
        dispatch('change_Selected_at_elevatorMotor_Brake_MechanicalRelease_Selection_basedOnChangesTo_Selected_at_elevatorMotor_Brake_Selection_storeAction');
        dispatch('change_Selected_at_elevatorMotor_Brake_ReleaseMonitoring_Selection_basedOnChangesTo_Selected_at_elevatorMotor_Brake_Selection_storeAction');
        dispatch('change_Disabled_at_elevatorMotor_Brake_MechanicalRelease_Selection_basedOnChangesTo_Selected_at_elevatorMotor_Brake_Selection_storeAction');
        dispatch('change_Disabled_at_elevatorMotor_Brake_ReleaseMonitoring_Selection_basedOnChangesTo_Selected_at_elevatorMotor_Brake_Selection_storeAction');
    },
    change_Selected_at_elevatorMotor_Brake_Selection_storeAction({ commit }, value) {
        commit('change_Selected_at_elevatorMotor_Brake_Selection_storeMutation', value);

        const firstIndexWhere_Selected_at_elevatorMotor_Brake_Selection_isTrue = state.elevatorMotor_Brake_Manual_Data.elevatorMotor_Brake_Selection.findIndex(i => i.selected == true);
        if (firstIndexWhere_Selected_at_elevatorMotor_Brake_Selection_isTrue > -1 && state.elevatorMotor_Brake_Manual_Data != null && state.elevatorMotor_Brake_Manual_Data.hasOwnProperty('elevatorMotor_Brake_Connection_Selection') && state.elevatorMotor_Brake_Manual_Data.elevatorMotor_Brake_Connection_Selection != null) {
            const firstIndexWhere_Selected_at_elevatorMotor_BrakeConnection_Selection_isTrue = state.elevatorMotor_Brake_Manual_Data.elevatorMotor_Brake_Connection_Selection.findIndex(i => i.selected == true);
            if (firstIndexWhere_Selected_at_elevatorMotor_BrakeConnection_Selection_isTrue > -1) {
                const brakeConnection = {
                    index: firstIndexWhere_Selected_at_elevatorMotor_Brake_Selection_isTrue,
                    brakeConnectionBlockNumber: state.elevatorMotor_Brake_Manual_Data.elevatorMotor_Brake_Connection_Selection[firstIndexWhere_Selected_at_elevatorMotor_BrakeConnection_Selection_isTrue].blockNumber,
                    brakeConnectionTypeDesignation: state.elevatorMotor_Brake_Manual_Data.elevatorMotor_Brake_Connection_Selection[firstIndexWhere_Selected_at_elevatorMotor_BrakeConnection_Selection_isTrue].text,
                }
                commit('change_Selected_at_elevatorMotor_Brake_Selection_BrakeConnection_storeMutation', brakeConnection);
            }
        }
        if (firstIndexWhere_Selected_at_elevatorMotor_Brake_Selection_isTrue > -1 && state.elevatorMotor_Brake_Manual_Data != null && state.elevatorMotor_Brake_Manual_Data.hasOwnProperty('elevatorMotor_Brake_Bowdenwire_Selection') && state.elevatorMotor_Brake_Manual_Data.elevatorMotor_Brake_Bowdenwire_Selection != null) {
            const firstIndexWhere_Selected_at_elevatorMotor_Bowdenwire_Selection_isTrue = state.elevatorMotor_Brake_Manual_Data.elevatorMotor_Brake_Bowdenwire_Selection.findIndex(i => i.selected == true);
            if (firstIndexWhere_Selected_at_elevatorMotor_Bowdenwire_Selection_isTrue > -1) {
                const bowdenwire = {
                    index: firstIndexWhere_Selected_at_elevatorMotor_Brake_Selection_isTrue,
                    bowdenwireBlockNumber: state.elevatorMotor_Brake_Manual_Data.elevatorMotor_Brake_Bowdenwire_Selection[firstIndexWhere_Selected_at_elevatorMotor_Bowdenwire_Selection_isTrue].blockNumber,
                    bowdenwireDeliver: state.elevatorMotor_Brake_Manual_Data.elevatorMotor_Brake_Bowdenwire_Selection[firstIndexWhere_Selected_at_elevatorMotor_Bowdenwire_Selection_isTrue].deliver.code,
                    bowdenwireLength: state.elevatorMotor_Brake_Manual_Data.elevatorMotor_Brake_Bowdenwire_Selection[firstIndexWhere_Selected_at_elevatorMotor_Bowdenwire_Selection_isTrue].length,
                }
                commit('change_Selected_at_elevatorMotor_Brake_Selection_Bowdenwire_storeMutation', bowdenwire);
            }
        }
    },
    change_Selected_at_elevatorMotor_Brake_Selection_basedOnChangesTo_elevatorMotor_Brake_Voltage_storeAction({ commit, dispatch }, value) {
        var firstIndexWhere_Selected_at_elevatorMotor_Brake_Selection_hasToBeChangedToTrue = undefined;
        const firstIndexWhere_Selected_at_elevatorMotor_Brake_Selection_isTrue = state.elevatorMotor_Brake_Manual_Data.elevatorMotor_Brake_Selection.findIndex(i => i.selected == true);

        if ((state.elevatorMotor_Brake_MechanicalRelease_ChangedByUser == false) && (state.elevatorMotor_Brake_ReleaseMonitoring_ChangedByUser == false)) {
            firstIndexWhere_Selected_at_elevatorMotor_Brake_Selection_hasToBeChangedToTrue = state.elevatorMotor_Brake_Manual_Data.elevatorMotor_Brake_Selection.findIndex(i => i.elevatorMotor_Brake.nominalVoltage == value);
        }
        else if ((state.elevatorMotor_Brake_MechanicalRelease_ChangedByUser == true) && (state.elevatorMotor_Brake_ReleaseMonitoring_ChangedByUser == false)) {
            firstIndexWhere_Selected_at_elevatorMotor_Brake_Selection_hasToBeChangedToTrue = state.elevatorMotor_Brake_Manual_Data.elevatorMotor_Brake_Selection.findIndex(i => ((i.elevatorMotor_Brake.nominalVoltage == value) && (i.elevatorMotor_Brake.mechanicalRelease == state.elevatorMotor_Brake_Manual_Data.elevatorMotor_Brake_Selection[firstIndexWhere_Selected_at_elevatorMotor_Brake_Selection_isTrue].elevatorMotor_Brake.mechanicalRelease)))
            if (firstIndexWhere_Selected_at_elevatorMotor_Brake_Selection_hasToBeChangedToTrue == -1) {
                firstIndexWhere_Selected_at_elevatorMotor_Brake_Selection_hasToBeChangedToTrue = state.elevatorMotor_Brake_Manual_Data.elevatorMotor_Brake_Selection.findIndex(i => i.elevatorMotor_Brake.nominalVoltage == value);
            }
        }
        else if ((state.elevatorMotor_Brake_MechanicalRelease_ChangedByUser == false) && (state.elevatorMotor_Brake_ReleaseMonitoring_ChangedByUser == true)) {
            firstIndexWhere_Selected_at_elevatorMotor_Brake_Selection_hasToBeChangedToTrue = state.elevatorMotor_Brake_Manual_Data.elevatorMotor_Brake_Selection.findIndex(i => ((i.elevatorMotor_Brake.nominalVoltage == value) && (i.elevatorMotor_Brake.releaseMonitoring == state.elevatorMotor_Brake_Manual_Data.elevatorMotor_Brake_Selection[firstIndexWhere_Selected_at_elevatorMotor_Brake_Selection_isTrue].elevatorMotor_Brake.releaseMonitoring)))
            if (firstIndexWhere_Selected_at_elevatorMotor_Brake_Selection_hasToBeChangedToTrue == -1) {
                firstIndexWhere_Selected_at_elevatorMotor_Brake_Selection_hasToBeChangedToTrue = state.elevatorMotor_Brake_Manual_Data.elevatorMotor_Brake_Selection.findIndex(i => i.elevatorMotor_Brake.nominalVoltage == value);
            }
        }
        else if ((state.elevatorMotor_Brake_MechanicalRelease_ChangedByUser == true) && (state.elevatorMotor_Brake_ReleaseMonitoring_ChangedByUser == true)) {
            firstIndexWhere_Selected_at_elevatorMotor_Brake_Selection_hasToBeChangedToTrue = state.elevatorMotor_Brake_Manual_Data.elevatorMotor_Brake_Selection.findIndex(i => ((i.elevatorMotor_Brake.nominalVoltage == value) && (i.elevatorMotor_Brake.mechanicalRelease == state.elevatorMotor_Brake_Manual_Data.elevatorMotor_Brake_Selection[firstIndexWhere_Selected_at_elevatorMotor_Brake_Selection_isTrue].elevatorMotor_Brake.mechanicalRelease) && (i.elevatorMotor_Brake.releaseMonitoring == state.elevatorMotor_Brake_Manual_Data.elevatorMotor_Brake_Selection[firstIndexWhere_Selected_at_elevatorMotor_Brake_Selection_isTrue].elevatorMotor_Brake.releaseMonitoring)))
            if (firstIndexWhere_Selected_at_elevatorMotor_Brake_Selection_hasToBeChangedToTrue == -1) {
                firstIndexWhere_Selected_at_elevatorMotor_Brake_Selection_hasToBeChangedToTrue = state.elevatorMotor_Brake_Manual_Data.elevatorMotor_Brake_Selection.findIndex(i => i.elevatorMotor_Brake.nominalVoltage == value);
            }
        }

        if (firstIndexWhere_Selected_at_elevatorMotor_Brake_Selection_hasToBeChangedToTrue > -1) {
            dispatch('checkIf_changesAt_elevatorMotor_Brake_changes_configurationFlow_storeAction', firstIndexWhere_Selected_at_elevatorMotor_Brake_Selection_hasToBeChangedToTrue);
        }
    },
    change_Selected_at_elevatorMotor_Brake_Selection_basedOnChangesTo_elevatorMotor_Brake_MechanicalRelease_storeAction({ commit, dispatch }, value) {
        var firstIndexWhere_Selected_at_elevatorMotor_Brake_Selection_hasToBeChangedToTrue = undefined;
        const firstIndexWhere_Selected_at_elevatorMotor_Brake_Selection_isTrue = state.elevatorMotor_Brake_Manual_Data.elevatorMotor_Brake_Selection.findIndex(i => i.selected == true);

        firstIndexWhere_Selected_at_elevatorMotor_Brake_Selection_hasToBeChangedToTrue = state.elevatorMotor_Brake_Manual_Data.elevatorMotor_Brake_Selection.findIndex(i => ((i.elevatorMotor_Brake.nominalVoltage == state.elevatorMotor_Brake_Manual_Data.elevatorMotor_Brake_Selection[firstIndexWhere_Selected_at_elevatorMotor_Brake_Selection_isTrue].elevatorMotor_Brake.nominalVoltage) && (i.elevatorMotor_Brake.mechanicalRelease == value) && (i.elevatorMotor_Brake.releaseMonitoring == state.elevatorMotor_Brake_Manual_Data.elevatorMotor_Brake_Selection[firstIndexWhere_Selected_at_elevatorMotor_Brake_Selection_isTrue].elevatorMotor_Brake.releaseMonitoring)));
        if (firstIndexWhere_Selected_at_elevatorMotor_Brake_Selection_hasToBeChangedToTrue == -1) {
            firstIndexWhere_Selected_at_elevatorMotor_Brake_Selection_hasToBeChangedToTrue = state.elevatorMotor_Brake_Manual_Data.elevatorMotor_Brake_Selection.findIndex(i => ((i.elevatorMotor_Brake.nominalVoltage == state.elevatorMotor_Brake_Manual_Data.elevatorMotor_Brake_Selection[firstIndexWhere_Selected_at_elevatorMotor_Brake_Selection_isTrue].elevatorMotor_Brake.nominalVoltage) && (i.elevatorMotor_Brake.mechanicalRelease == value)));
        }

        if (firstIndexWhere_Selected_at_elevatorMotor_Brake_Selection_hasToBeChangedToTrue > -1) {
            commit('change_elevatorMotor_Brake_MechanicalRelease_ChangedByUser_storeMutation', true);
            dispatch('checkIf_changesAt_elevatorMotor_Brake_changes_configurationFlow_storeAction', firstIndexWhere_Selected_at_elevatorMotor_Brake_Selection_hasToBeChangedToTrue);
        }
    },
    change_Selected_at_elevatorMotor_Brake_Selection_basedOnChangesTo_elevatorMotor_Brake_ReleaseMonitoring_storeAction({ commit, dispatch }, value) {
        var firstIndexWhere_Selected_at_elevatorMotor_Brake_Selection_hasToBeChangedToTrue = undefined;
        const firstIndexWhere_Selected_at_elevatorMotor_Brake_Selection_isTrue = state.elevatorMotor_Brake_Manual_Data.elevatorMotor_Brake_Selection.findIndex(i => i.selected == true);

        firstIndexWhere_Selected_at_elevatorMotor_Brake_Selection_hasToBeChangedToTrue = state.elevatorMotor_Brake_Manual_Data.elevatorMotor_Brake_Selection.findIndex(i => ((i.elevatorMotor_Brake.nominalVoltage == state.elevatorMotor_Brake_Manual_Data.elevatorMotor_Brake_Selection[firstIndexWhere_Selected_at_elevatorMotor_Brake_Selection_isTrue].elevatorMotor_Brake.nominalVoltage) && (i.elevatorMotor_Brake.mechanicalRelease == state.elevatorMotor_Brake_Manual_Data.elevatorMotor_Brake_Selection[firstIndexWhere_Selected_at_elevatorMotor_Brake_Selection_isTrue].elevatorMotor_Brake.mechanicalRelease) && (i.elevatorMotor_Brake.releaseMonitoring == value)));

        if (firstIndexWhere_Selected_at_elevatorMotor_Brake_Selection_hasToBeChangedToTrue > -1) {
            commit('change_elevatorMotor_Brake_ReleaseMonitoring_ChangedByUser_storeMutation', true);
            dispatch('checkIf_changesAt_elevatorMotor_Brake_changes_configurationFlow_storeAction', firstIndexWhere_Selected_at_elevatorMotor_Brake_Selection_hasToBeChangedToTrue);
        }
    },
    change_Selected_at_elevatorMotor_Brake_Selection_basedOnChangesTo_elevatorMotor_Brake_Connection_storeAction({ commit, dispatch }, value) {
        const firstIndexWhere_Selected_at_elevatorMotor_Brake_Selection_isTrue = state.elevatorMotor_Brake_Manual_Data.elevatorMotor_Brake_Selection.findIndex(i => i.selected == true);

        commit('change_Selected_BlockNumber_at_elevatorMotor_Brake_Connection_Selection_storeMutation', value)

        if (firstIndexWhere_Selected_at_elevatorMotor_Brake_Selection_isTrue > -1) {
            const brakeConnection = {
                index: firstIndexWhere_Selected_at_elevatorMotor_Brake_Selection_isTrue,
                brakeConnectionBlockNumber: value.blockNumber,
                brakeConnectionTypeDesignation: value.text,
            }
            commit('change_Selected_at_elevatorMotor_Brake_Selection_BrakeConnection_storeMutation', brakeConnection);
            dispatch('checkIf_changesAt_elevatorMotor_Brake_changes_configurationFlow_storeAction', firstIndexWhere_Selected_at_elevatorMotor_Brake_Selection_isTrue);
        }
    },
    async checkIf_changesAt_elevatorMotor_Brake_changes_configurationFlow_storeAction({ rootState, dispatch }, value) {
        dispatch('change_Selected_at_elevatorMotor_Brake_Selection_storeAction', value)
        dispatch('change_Selected_at_elevatorMotor_Brake_Voltage_Selection_basedOnChangesTo_Selected_at_elevatorMotor_Brake_Selection_storeAction');
        dispatch('change_Selected_at_elevatorMotor_Brake_MechanicalRelease_Selection_basedOnChangesTo_Selected_at_elevatorMotor_Brake_Selection_storeAction');
        dispatch('change_Selected_at_elevatorMotor_Brake_ReleaseMonitoring_Selection_basedOnChangesTo_Selected_at_elevatorMotor_Brake_Selection_storeAction');
        dispatch('change_Disabled_at_elevatorMotor_Brake_MechanicalRelease_Selection_basedOnChangesTo_Selected_at_elevatorMotor_Brake_Selection_storeAction');
        dispatch('change_Disabled_at_elevatorMotor_Brake_ReleaseMonitoring_Selection_basedOnChangesTo_Selected_at_elevatorMotor_Brake_Selection_storeAction');
    },

    // elevatorMotor_Brake_Voltage
    change_Selected_at_elevatorMotor_Brake_Voltage_Selection_basedOnChangesTo_Selected_at_elevatorMotor_Brake_Selection_storeAction({ dispatch }) {
        const firstIndexWhere_Selected_at_elevatorMotor_Brake_Selection_isTrue = state.elevatorMotor_Brake_Manual_Data.elevatorMotor_Brake_Selection.findIndex(i => i.selected == true);

        if (firstIndexWhere_Selected_at_elevatorMotor_Brake_Selection_isTrue > -1) {
            const firstIndexWhere_Selected_at_elevatorMotor_Brake_Voltage_Selection_mustBeChangedToTrue = state.elevatorMotor_Brake_Manual_Data.elevatorMotor_Brake_Voltage_Selection.findIndex(i => i.nominalVoltage == state.elevatorMotor_Brake_Manual_Data.elevatorMotor_Brake_Selection[firstIndexWhere_Selected_at_elevatorMotor_Brake_Selection_isTrue].elevatorMotor_Brake.nominalVoltage);
            if (firstIndexWhere_Selected_at_elevatorMotor_Brake_Voltage_Selection_mustBeChangedToTrue > -1) {
                dispatch('change_Selected_atElementOf_elevatorMotor_Brake_Voltage_Selection_storeAction', firstIndexWhere_Selected_at_elevatorMotor_Brake_Voltage_Selection_mustBeChangedToTrue);
            }
            else {
                // error
            }
        }
        else {
            // error
        }
    },
    change_Selected_atElementOf_elevatorMotor_Brake_Voltage_Selection_storeAction({ commit }, value) {
        commit('change_Selected_at_elevatorMotor_Brake_Voltage_Selection_storeMutation', value);
    },

    // elevatorMotor_Brake_MechanicalRelease
    change_Selected_at_elevatorMotor_Brake_MechanicalRelease_Selection_basedOnChangesTo_Selected_at_elevatorMotor_Brake_Selection_storeAction({ dispatch }) {
        const firstIndexWhere_Selected_at_elevatorMotor_Brake_Selection_isTrue = state.elevatorMotor_Brake_Manual_Data.elevatorMotor_Brake_Selection.findIndex(i => i.selected == true);

        if (firstIndexWhere_Selected_at_elevatorMotor_Brake_Selection_isTrue > -1) {
            const firstIndexWhere_Selected_at_elevatorMotor_Brake_MechanicalRelease_Selection_mustBeChangedToTrue = state.elevatorMotor_Brake_Manual_Data.elevatorMotor_Brake_MechanicalRelease_Selection.findIndex(i => i.text == state.elevatorMotor_Brake_Manual_Data.elevatorMotor_Brake_Selection[firstIndexWhere_Selected_at_elevatorMotor_Brake_Selection_isTrue].elevatorMotor_Brake.mechanicalRelease);
            if (firstIndexWhere_Selected_at_elevatorMotor_Brake_MechanicalRelease_Selection_mustBeChangedToTrue > -1) {
                dispatch('change_Selected_atElementOf_elevatorMotor_Brake_MechanicalRelease_Selection_storeAction', firstIndexWhere_Selected_at_elevatorMotor_Brake_MechanicalRelease_Selection_mustBeChangedToTrue);
            }
            else {
                // error
            }
        }
        else {
            // error
        }
    },
    change_Selected_atElementOf_elevatorMotor_Brake_MechanicalRelease_Selection_storeAction({ commit, dispatch }, value) {
        commit('change_Selected_at_elevatorMotor_Brake_MechanicalRelease_Selection_storeMutation', value);

        if (state.elevatorMotor_Brake_Manual_Data.hasOwnProperty('elevatorMotor_Brake_DeliverBowdenwire_Selection') && state.elevatorMotor_Brake_Manual_Data.elevatorMotor_Brake_DeliverBowdenwire_Selection != null && state.elevatorMotor_Brake_Manual_Data.elevatorMotor_Brake_DeliverBowdenwire_Selection.length > 0) {
            const firstIndex_whichMeetConditions = state.elevatorMotor_Brake_Manual_Data.elevatorMotor_Brake_MechanicalRelease_Selection.findIndex(i => i.selected == true);
            if (firstIndex_whichMeetConditions > -1) {
                var firstIndex_WichtMeetConditions_Bowdenwire = state.elevatorMotor_Brake_Manual_Data.elevatorMotor_Brake_DeliverBowdenwire_Selection.findIndex(i => i.selected == true);
                if (firstIndex_WichtMeetConditions_Bowdenwire == -1 || state.elevatorMotor_Brake_Manual_Data.elevatorMotor_Brake_DeliverBowdenwire_Selection[firstIndex_WichtMeetConditions_Bowdenwire].mechanicalRelease != state.elevatorMotor_Brake_Manual_Data.elevatorMotor_Brake_MechanicalRelease_Selection[firstIndex_whichMeetConditions].text) {
                    firstIndex_WichtMeetConditions_Bowdenwire = state.elevatorMotor_Brake_Manual_Data.elevatorMotor_Brake_DeliverBowdenwire_Selection.findIndex(i => i.mechanicalRelease == state.elevatorMotor_Brake_Manual_Data.elevatorMotor_Brake_MechanicalRelease_Selection[firstIndex_whichMeetConditions].text);
                    dispatch('change_Selected_at_elevatorMotor_Brake_DeliverBowdenwire_Selection_storeAction', firstIndex_WichtMeetConditions_Bowdenwire);
                }
            }
        }


    },
    change_Disabled_at_elevatorMotor_Brake_MechanicalRelease_Selection_basedOnChangesTo_Selected_at_elevatorMotor_Brake_Selection_storeAction({ commit }) {
        var helpArray_elevatorMotor_Brake_MechanicalRelease_Selection = [];

        const firstIndexWhere_Selected_at_elevatorMotor_Brake_Selection_isTrue = state.elevatorMotor_Brake_Manual_Data.elevatorMotor_Brake_Selection.findIndex(i => i.selected == true);
        if (firstIndexWhere_Selected_at_elevatorMotor_Brake_Selection_isTrue > -1) {
            state.elevatorMotor_Brake_Manual_Data.elevatorMotor_Brake_Selection.forEach(i => {
                if (i.elevatorMotor_Brake.nominalVoltage == state.elevatorMotor_Brake_Manual_Data.elevatorMotor_Brake_Selection[firstIndexWhere_Selected_at_elevatorMotor_Brake_Selection_isTrue].elevatorMotor_Brake.nominalVoltage) {
                    var helpArray_containsCurrentItemOf_elevatorMotor_Brake_MechanicalRelease_Selection = false;
                    helpArray_elevatorMotor_Brake_MechanicalRelease_Selection.forEach(ii => {
                        if (i.elevatorMotor_Brake.mechanicalRelease == ii.text) {
                            helpArray_containsCurrentItemOf_elevatorMotor_Brake_MechanicalRelease_Selection = true;
                        }
                    })
                    if (!helpArray_containsCurrentItemOf_elevatorMotor_Brake_MechanicalRelease_Selection) {
                        helpArray_elevatorMotor_Brake_MechanicalRelease_Selection.push(i.elevatorMotor_Brake.mechanicalRelease)
                    }
                }
            })
        }
        else {
            // error
        }

        if (helpArray_elevatorMotor_Brake_MechanicalRelease_Selection.length > 0) {
            commit('change_Disabled_at_elevatorMotor_Brake_MechanicalRelease_Selection_storeMutation', helpArray_elevatorMotor_Brake_MechanicalRelease_Selection)
        }
    },


    // elevatorMotor_Brake_ReleaseMonitoring
    change_Selected_at_elevatorMotor_Brake_ReleaseMonitoring_Selection_basedOnChangesTo_Selected_at_elevatorMotor_Brake_Selection_storeAction({ dispatch }) {
        const firstIndexWhere_Selected_at_elevatorMotor_Brake_Selection_isTrue = state.elevatorMotor_Brake_Manual_Data.elevatorMotor_Brake_Selection.findIndex(i => i.selected == true);

        if (firstIndexWhere_Selected_at_elevatorMotor_Brake_Selection_isTrue > -1) {
            const firstIndexWhere_Selected_at_elevatorMotor_Brake_ReleaseMonitoring_Selection_mustBeChangedToTrue = state.elevatorMotor_Brake_Manual_Data.elevatorMotor_Brake_ReleaseMonitoring_Selection.findIndex(i => i.text == state.elevatorMotor_Brake_Manual_Data.elevatorMotor_Brake_Selection[firstIndexWhere_Selected_at_elevatorMotor_Brake_Selection_isTrue].elevatorMotor_Brake.releaseMonitoring);
            if (firstIndexWhere_Selected_at_elevatorMotor_Brake_ReleaseMonitoring_Selection_mustBeChangedToTrue > -1) {
                dispatch('change_Selected_atElementOf_elevatorMotor_Brake_ReleaseMonitoring_Selection_storeAction', firstIndexWhere_Selected_at_elevatorMotor_Brake_ReleaseMonitoring_Selection_mustBeChangedToTrue);
            }
            else {
                // error
            }
        }
        else {
            // error
        }
    },
    change_Selected_atElementOf_elevatorMotor_Brake_ReleaseMonitoring_Selection_storeAction({ commit }, value) {
        commit('change_Selected_at_elevatorMotor_Brake_ReleaseMonitoring_Selection_storeMutation', value);
    },
    change_Disabled_at_elevatorMotor_Brake_ReleaseMonitoring_Selection_basedOnChangesTo_Selected_at_elevatorMotor_Brake_Selection_storeAction({ commit }) {
        var helpArray_elevatorMotor_Brake_ReleaseMonitoring_Selection = [];

        const firstIndexWhere_Selected_at_elevatorMotor_Brake_Selection_isTrue = state.elevatorMotor_Brake_Manual_Data.elevatorMotor_Brake_Selection.findIndex(i => i.selected == true);
        if (firstIndexWhere_Selected_at_elevatorMotor_Brake_Selection_isTrue > -1) {
            state.elevatorMotor_Brake_Manual_Data.elevatorMotor_Brake_Selection.forEach(i => {
                var helpArray_containsCurrentItemOf_elevatorMotor_Brake_ReleaseMonitoring_Selection = false;
                if ((i.elevatorMotor_Brake.nominalVoltage == state.elevatorMotor_Brake_Manual_Data.elevatorMotor_Brake_Selection[firstIndexWhere_Selected_at_elevatorMotor_Brake_Selection_isTrue].elevatorMotor_Brake.nominalVoltage) && (i.elevatorMotor_Brake.withMechanicalRelease) && (i.elevatorMotor_Brake.mechanicalRelease == state.elevatorMotor_Brake_Manual_Data.elevatorMotor_Brake_Selection[firstIndexWhere_Selected_at_elevatorMotor_Brake_Selection_isTrue].elevatorMotor_Brake.mechanicalRelease)) {
                    helpArray_elevatorMotor_Brake_ReleaseMonitoring_Selection.forEach(ii => {
                        if (ii.text == i.elevatorMotor_Brake.releaseMonitoring) {
                            helpArray_containsCurrentItemOf_elevatorMotor_Brake_ReleaseMonitoring_Selection = true;
                        }
                    })
                    if (!helpArray_containsCurrentItemOf_elevatorMotor_Brake_ReleaseMonitoring_Selection) {
                        helpArray_elevatorMotor_Brake_ReleaseMonitoring_Selection.push(i.elevatorMotor_Brake.releaseMonitoring)
                    }
                }
                else if ((i.elevatorMotor_Brake.nominalVoltage == state.elevatorMotor_Brake_Manual_Data.elevatorMotor_Brake_Selection[firstIndexWhere_Selected_at_elevatorMotor_Brake_Selection_isTrue].elevatorMotor_Brake.nominalVoltage) && (!i.elevatorMotor_Brake.withMechanicalRelease) && (state.elevatorMotor_Brake_Manual_Data.elevatorMotor_Brake_Selection[firstIndexWhere_Selected_at_elevatorMotor_Brake_Selection_isTrue].elevatorMotor_Brake.mechanicalRelease == 'without')) {
                    helpArray_elevatorMotor_Brake_ReleaseMonitoring_Selection.forEach(ii => {
                        if (ii.text == i.elevatorMotor_Brake.releaseMonitoring) {
                            helpArray_containsCurrentItemOf_elevatorMotor_Brake_ReleaseMonitoring_Selection = true;
                        }
                    })
                    if (!helpArray_containsCurrentItemOf_elevatorMotor_Brake_ReleaseMonitoring_Selection) {
                        helpArray_elevatorMotor_Brake_ReleaseMonitoring_Selection.push(i.elevatorMotor_Brake.releaseMonitoring)
                    }
                }
            })
        }
        else {
            // error
        }

        if (helpArray_elevatorMotor_Brake_ReleaseMonitoring_Selection.length > 0) {
            commit('change_Disabled_at_elevatorMotor_Brake_ReleaseMonitoring_Selection_storeMutation', helpArray_elevatorMotor_Brake_ReleaseMonitoring_Selection)
        }
    },

    // elevatorMotor_Brake_Connection
    change_Selected_atElementOf_elevatorMotor_Brake_Connection_Selection_storeAction({ commit }, value) {
        commit('change_Selected_at_elevatorMotor_Brake_Connection_Selection_storeMutation', value);
    },

    // elevatorMotor_Brake_Bowdenwire
    change_Selected_at_elevatorMotor_Brake_Selection_basedOnChangesTo_elevatorMotor_Brake_DeliverBowdenwire_storeAction({ commit, dispatch }, value) {

        var firstIndex_whichMeetConditions = state.elevatorMotor_Brake_Manual_Data.elevatorMotor_Brake_DeliverBowdenwire_Selection.findIndex(i => i.selected == true);
        if (value != null && (firstIndex_whichMeetConditions == -1 || value.deliver.text != state.elevatorMotor_Brake_Manual_Data.elevatorMotor_Brake_DeliverBowdenwire_Selection[firstIndex_whichMeetConditions].deliver.text)) {
            var firstIndex_whichMeetConditions_Bowdewire = state.elevatorMotor_Brake_Manual_Data.elevatorMotor_Brake_DeliverBowdenwire_Selection.findIndex(i => i.deliver.text == value.deliver.text && i.mechanicalRelease == value.mechanicalRelease);
            dispatch('change_Selected_at_elevatorMotor_Brake_DeliverBowdenwire_Selection_storeAction', firstIndex_whichMeetConditions_Bowdewire)

            const firstIndexWhere_Selected_at_elevatorMotor_Brake_Selection_isTrue = state.elevatorMotor_Brake_Manual_Data.elevatorMotor_Brake_Selection.findIndex(i => i.selected == true);
            if (firstIndexWhere_Selected_at_elevatorMotor_Brake_Selection_isTrue > -1) {
                dispatch('checkIf_changesAt_elevatorMotor_Brake_changes_configurationFlow_storeAction', firstIndexWhere_Selected_at_elevatorMotor_Brake_Selection_isTrue);
            }
        }
    },
    change_Selected_at_elevatorMotor_Brake_DeliverBowdenwire_Selection_storeAction({ commit, dispatch }, value) {
        commit('change_Selected_at_elevatorMotor_Brake_DeliverBowdenwire_Selection_storeMutation', value);

        const firstIndex_whichMeetConditions = state.elevatorMotor_Brake_Manual_Data.elevatorMotor_Brake_DeliverBowdenwire_Selection.findIndex(i => i.selected == true);
        if (firstIndex_whichMeetConditions > -1) {
            const deliverBowdenwire_Selection = state.elevatorMotor_Brake_Manual_Data.elevatorMotor_Brake_DeliverBowdenwire_Selection[firstIndex_whichMeetConditions]
            var firstIndex_WichtMeetConditions_Bowdenwire = state.elevatorMotor_Brake_Manual_Data.elevatorMotor_Brake_Bowdenwire_Selection.findIndex(i => i.selected == true);
            if (firstIndex_WichtMeetConditions_Bowdenwire == -1 ||
                (state.elevatorMotor_Brake_Manual_Data.elevatorMotor_Brake_Bowdenwire_Selection[firstIndex_WichtMeetConditions_Bowdenwire].mechanicalRelease != deliverBowdenwire_Selection.mechanicalRelease ||
                    state.elevatorMotor_Brake_Manual_Data.elevatorMotor_Brake_Bowdenwire_Selection[firstIndex_WichtMeetConditions_Bowdenwire].deliver.text != deliverBowdenwire_Selection.deliver.text)) {
                firstIndex_WichtMeetConditions_Bowdenwire = state.elevatorMotor_Brake_Manual_Data.elevatorMotor_Brake_Bowdenwire_Selection.findIndex(i => i.mechanicalRelease == deliverBowdenwire_Selection.mechanicalRelease && i.deliver.text == deliverBowdenwire_Selection.deliver.text);
                dispatch('change_Selected_at_elevatorMotor_Brake_Bowdenwire_Selection_storeAction', firstIndex_WichtMeetConditions_Bowdenwire);
            }
        }
        else {
            dispatch('change_Selected_at_elevatorMotor_Brake_Bowdenwire_Selection_storeAction', -1);
        }
    },
    change_Selected_at_elevatorMotor_Brake_Bowdenwire_Selection_storeAction({ commit, dispatch }, value) {
        commit('change_Selected_at_elevatorMotor_Brake_Bowdenwire_Selection_storeMutation', value);
        const firstIndexWhere_Selected_at_elevatorMotor_Brake_Selection_isTrue = state.elevatorMotor_Brake_Manual_Data.elevatorMotor_Brake_Selection.findIndex(i => i.selected == true);
        if (firstIndexWhere_Selected_at_elevatorMotor_Brake_Selection_isTrue > -1 && value > -1) {
            const bowdenwire = {
                index: firstIndexWhere_Selected_at_elevatorMotor_Brake_Selection_isTrue,
                bowdenwireBlockNumber: state.elevatorMotor_Brake_Manual_Data.elevatorMotor_Brake_Bowdenwire_Selection[value].blockNumber,
                bowdenwireDeliver: state.elevatorMotor_Brake_Manual_Data.elevatorMotor_Brake_Bowdenwire_Selection[value].deliver.code,
                bowdenwireLength: state.elevatorMotor_Brake_Manual_Data.elevatorMotor_Brake_Bowdenwire_Selection[value].length,
            }
            commit('change_Selected_at_elevatorMotor_Brake_Selection_Bowdenwire_storeMutation', bowdenwire);
        }
    },
    change_Selected_at_elevatorMotor_Brake_Selection_basedOnChangesTo_elevatorMotor_Brake_Bowdenwire_storeAction({ commit, dispatch }, value) {
        var firstIndex_whichMeetConditions = state.elevatorMotor_Brake_Manual_Data.elevatorMotor_Brake_Bowdenwire_Selection.findIndex(i => i.selected == true);
        if (value != null && (firstIndex_whichMeetConditions == -1 ||
            value.deliver.text != state.elevatorMotor_Brake_Manual_Data.elevatorMotor_Brake_Bowdenwire_Selection[firstIndex_whichMeetConditions].deliver.text ||
            value.mechanicalRelease != state.elevatorMotor_Brake_Manual_Data.elevatorMotor_Brake_Bowdenwire_Selection[firstIndex_whichMeetConditions].mechanicalRelease ||
            value.length != state.elevatorMotor_Brake_Manual_Data.elevatorMotor_Brake_Bowdenwire_Selection[firstIndex_whichMeetConditions].length)) {
            var firstIndex_WichtMeetConditions_Bowdenwire = state.elevatorMotor_Brake_Manual_Data.elevatorMotor_Brake_Bowdenwire_Selection.findIndex(i => i.length == value.length && i.deliver.text == value.deliver.text && i.mechanicalRelease == value.mechanicalRelease);
            dispatch('change_Selected_at_elevatorMotor_Brake_Bowdenwire_Selection_storeAction', firstIndex_WichtMeetConditions_Bowdenwire);

            const firstIndexWhere_Selected_at_elevatorMotor_Brake_Selection_isTrue = state.elevatorMotor_Brake_Manual_Data.elevatorMotor_Brake_Selection.findIndex(i => i.selected == true);
            if (firstIndexWhere_Selected_at_elevatorMotor_Brake_Selection_isTrue > -1) {
                dispatch('checkIf_changesAt_elevatorMotor_Brake_changes_configurationFlow_storeAction', firstIndexWhere_Selected_at_elevatorMotor_Brake_Selection_isTrue);
            }
        }
    },
    change_Selected_atElementOf_elevatorMotor_Brake_Bowdenwire_Selection_storeAction({ commit, dispatch }, value) {
        if (value > -1) {
            var firstIndex_whichMeetConditions = state.elevatorMotor_Brake_Manual_Data.elevatorMotor_Brake_DeliverBowdenwire_Selection.findIndex(i => i.deliver.text == state.elevatorMotor_Brake_Manual_Data.elevatorMotor_Brake_Bowdenwire_Selection[value].deliver.text && i.mechanicalRelease == state.elevatorMotor_Brake_Manual_Data.elevatorMotor_Brake_Bowdenwire_Selection[value].mechanicalRelease);
            if (firstIndex_whichMeetConditions > -1) {
                commit('change_Selected_at_elevatorMotor_Brake_DeliverBowdenwire_Selection_storeMutation', firstIndex_whichMeetConditions);
            }
            dispatch('change_Selected_at_elevatorMotor_Brake_Bowdenwire_Selection_storeAction', value);
        }
    },

    // elevatorMotor_Encoder
    change_elevatorMotor_Encoder_toDefault_storeAction({ commit }) {
        commit('change_elevatorMotor_Encoder_Data_storeMutation', {});
        commit('change_elevatorMotor_Encoder_Selection_Shown_storeMutation', true)
    },

    async get_elevatorMotor_Encoder_Informations_storeAction({ dispatch, rootState }) {
        this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: true, reasonWhy_loaderModal_Shown: 'data_BeingLoaded' });

        const configurationStepsManualId = rootState.inputsManual.configurationStepsManualId;

        await axios({
            method: 'post',
            url: '/api/driveTechnologiesManual/get_elevatorMotor_Encoder_Informations/' + configurationStepsManualId,
        })
            .then((response) => {
                this.dispatch('statusHandling/change_dataAndStatus_storeAction', response.data);

                if ((response.data.status.statusCode.substring(0, 1) == '2') || (response.data.status.statusCode.substring(0, 2) == '74') || (response.data.status.statusCode.substring(0, 2) == '75')) {
                    dispatch('toDos_afterResponseOf_get_elevatorMotor_Encoder_Informations_storeAction', response.data.data);
                    if (state.elevatorMotor_Encoder_Data != null && state.elevatorMotor_Encoder_Data.hasOwnProperty('elevatorMotor_Encoder_Selection')) {
                        const firstIndex_whichMeetConditions = state.elevatorMotor_Encoder_Data.elevatorMotor_Encoder_Selection.findIndex(i => i.selected == true);
                        if (firstIndex_whichMeetConditions > -1) {
                            dispatch('change_Selected_at_elevatorMotor_Encoder_Selection_storeAction', firstIndex_whichMeetConditions);
                        }
                        else {
                            dispatch('change_Selected_atFirstElementOf_elevatorMotor_Encoder_Selection_toTrue_storeAction');
                        }
                    }
                    else {
                        dispatch('change_Selected_atFirstElementOf_elevatorMotor_Encoder_Selection_toTrue_storeAction');
                    }
                }
            })
            .catch(() => { })
            .then(() => {
                this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
            })
    },
    toDos_afterResponseOf_get_elevatorMotor_Encoder_Informations_storeAction({ rootState, dispatch }, value) {
        dispatch('change_elevatorMotor_Encoder_Data_storeAction', value.elevatorMotor_Encoder_Data);
    },

    change_elevatorMotor_Encoder_Data_storeAction({ commit }, value) {
        commit('change_elevatorMotor_Encoder_Data_storeMutation', value);
    },
    change_elevatorMotor_Encoder_Selection_Shown_storeAction({ commit }, value) {
        commit('change_elevatorMotor_Encoder_Selection_Shown_storeMutation', value)
    },
    async change_Selected_atFirstElementOf_elevatorMotor_Encoder_Selection_toTrue_storeAction({ dispatch }) {
        dispatch('change_Selected_at_elevatorMotor_Encoder_Selection_storeAction', 0);
    },
    async checkIf_changesAt_elevatorMotor_Encoder_changes_configurationFlow_storeAction({ rootState, commit, dispatch }, value) {
        dispatch('change_Selected_at_elevatorMotor_Encoder_Selection_storeAction', value)
    },
    change_Selected_at_elevatorMotor_Encoder_Selection_storeAction({ commit }, value) {
        commit('change_Selected_at_elevatorMotor_Encoder_Selection_storeMutation', value);
    },



    async insertOrUpdate_driveTechnologiesManual_storeAction({ rootState, dispatch }) {
        this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: true, reasonWhy_loaderModal_Shown: 'data_BeingLoaded' });

        this.dispatch('cables/change_cablesSelection_toDefault_storeAction');
        this.dispatch('packagingMotorManual/change_packagingMotor_toDefault_storeAction');
        const configurationStepsManualId = rootState.inputsManual.configurationStepsManualId;

        const firstIndexWhere_Selected_brake = state.elevatorMotor_Brake_Manual_Data.elevatorMotor_Brake_Selection.findIndex(i => i.selected == true);
        const firstIndexWhere_Selected_encoder = state.elevatorMotor_Encoder_Data.elevatorMotor_Encoder_Selection.findIndex(i => i.selected == true);
        const driveTechnologiesManual = {
            mN_BREM: state.elevatorMotor_Brake_Manual_Data.elevatorMotor_Brake_Selection[firstIndexWhere_Selected_brake].elevatorMotor_Brake.blockNumber,
            brakeNominalVoltage: state.elevatorMotor_Brake_Manual_Data.elevatorMotor_Brake_Selection[firstIndexWhere_Selected_brake].elevatorMotor_Brake.nominalVoltage,
            //bCSPAN: state.elevatorMotor_Brake_Manual_Data.elevatorMotor_Brake_Selection[firstIndexWhere_Selected_brake].elevatorMotor_Brake.bCSPAN,
            bCSPAE: state.elevatorMotor_Brake_Manual_Data.elevatorMotor_Brake_Selection[firstIndexWhere_Selected_brake].elevatorMotor_Brake.switchOnVoltage,
            bHL: state.elevatorMotor_Brake_Manual_Data.elevatorMotor_Brake_Selection[firstIndexWhere_Selected_brake].elevatorMotor_Brake.mechanicalRelease,
            bKSICH: state.elevatorMotor_Brake_Manual_Data.elevatorMotor_Brake_Selection[firstIndexWhere_Selected_brake].elevatorMotor_Brake.releaseMonitoring,
            tNZBB: state.elevatorMotor_Brake_Manual_Data.elevatorMotor_Brake_Selection[firstIndexWhere_Selected_brake].elevatorMotor_Brake.brakeConnectionBlockNumber,
            sZBANS: state.elevatorMotor_Brake_Manual_Data.elevatorMotor_Brake_Selection[firstIndexWhere_Selected_brake].elevatorMotor_Brake.brakeConnectionTypeDesignation,
            lSPEZB: state.elevatorMotor_Brake_Manual_Data.elevatorMotor_Brake_Selection[firstIndexWhere_Selected_brake].elevatorMotor_Brake.bowdenwireDeliver,
            bowdenwireBlockNumber: state.elevatorMotor_Brake_Manual_Data.elevatorMotor_Brake_Selection[firstIndexWhere_Selected_brake].elevatorMotor_Brake.bowdenwireBlockNumber,
            sPEZBL: state.elevatorMotor_Brake_Manual_Data.elevatorMotor_Brake_Selection[firstIndexWhere_Selected_brake].elevatorMotor_Brake.bowdenwireLength,
            gTYP: state.elevatorMotor_Encoder_Data.elevatorMotor_Encoder_Selection[firstIndexWhere_Selected_encoder].elevatorMotor_Encoder.typeDesignation,
        }

        const data = {
            configurationStepsManualId: configurationStepsManualId,
            driveTechnologiesManual: driveTechnologiesManual,
        }
        await axios({
            method: 'post',
            url: '/api/configurations/insertOrUpdate_driveTechnologiesManual',
            data: {
                data: data,
            }
        })
            .then((response) => {
                this.dispatch('statusHandling/change_dataAndStatus_storeAction', response.data);

                if (response.data.status.statusCode.substring(0, 1) == '2') {
                    if (response.data.data.changedDataset == true) {
                        this.dispatch('cables/change_cablesManual_toDefault_storeAction');
                        this.dispatch('suppliesMotorManual/change_suppliesMotorManual_toDefault_storeAction');
                        this.dispatch('packagingMotorManual/change_packagingMotor_toDefault_storeAction');

                        var navigationManual = Object.assign({}, rootState.configurations.navigationManual)
                        navigationManual.driveTechnologiesManual = 'passed';
                        navigationManual.cablesManual = null;
                        navigationManual.suppliesMotorManual = null;
                        navigationManual.packagingMotorManual = 'disabled';
                        this.commit('configurations/change_navigationManual_storeMutation', navigationManual);
                    }
                    router.push({ params: { slug: 'cables' } });
                }
                else {
                    var navigationManual = Object.assign({}, rootState.configurations.navigationManual)
                    navigationManual.driveTechnologiesManual = null;
                    navigationManual.cablesManual = null;
                    navigationManual.suppliesMotorManual = 'disabled';
                    navigationManual.packagingMotorManual = 'disabled';
                    this.commit('configurations/change_navigationManual_storeMutation', navigationManual);
                }

            })
            .catch(() => { })
            .then(() => {
                this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
            })
    },
    async delete_configuration_driveTechnologiesManual_storeAction({ rootState, commit, dispatch }) {
        this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: true, reasonWhy_loaderModal_Shown: 'data_BeingLoaded' });

        const configurationStepsManualId = rootState.inputsManual.configurationStepsManualId;

        const data = {
            configurationStepsManualId: configurationStepsManualId,
        }

        await axios({
            method: 'post',
            url: '/api/configurations/delete_configuration_driveTechnologiesManual',
            data: {
                data: data,
            }
        })
            .then((response) => {
                this.dispatch('statusHandling/change_dataAndStatus_storeAction', response.data);
                if (response.data.status.statusCode.substring(0, 1) == '2') {
                    this.dispatch('cables/change_cablesManual_toDefault_storeAction');
                    this.dispatch('suppliesMotorManual/change_suppliesMotorManual_toDefault_storeAction');
                    this.dispatch('packagingMotorManual/change_packagingMotor_toDefault_storeAction');

                    var navigationManual = Object.assign({}, rootState.configurations.navigationManual)
                    navigationManual.driveTechnologiesManual = null;
                    navigationManual.cablesManual = null;
                    navigationManual.suppliesMotorManual = 'disabled';
                    navigationManual.packagingMotorManual = 'disabled';
                    this.commit('configurations/change_navigationManual_storeMutation', navigationManual);
                }

            })
            .catch(() => { })
            .then(() => {
                this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
            })

    },

    checkIf_changesAt_configurationFlow_storeAction({ rootState, dispatch }) {
        if (rootState.configurations.navigationManual.driveTechnologiesManual == "passed") {
            dispatch('delete_configuration_driveTechnologiesManual_storeAction');
        }
    },

    checkIf_driveTechnologiesManual_areValid_storeAction({ rootState, dispatch }) {
        var numberOf_inputs_whichAreInvalid = 0;

        const firstIndexWhere_Selected_brake = state.elevatorMotor_Brake_Manual_Data.elevatorMotor_Brake_Selection.findIndex(i => i.selected == true);
        const firstIndexWhere_Selected_encoder = state.elevatorMotor_Encoder_Data.elevatorMotor_Encoder_Selection.findIndex(i => i.selected == true);

        if (firstIndexWhere_Selected_brake == -1) {
            numberOf_inputs_whichAreInvalid = numberOf_inputs_whichAreInvalid + 1;
        }
        else {
            if (state.elevatorMotor_Brake_Manual_Data.elevatorMotor_Brake_Selection[firstIndexWhere_Selected_brake].elevatorMotor_Brake.brakeConnectionBlockNumber == null) {
                numberOf_inputs_whichAreInvalid = numberOf_inputs_whichAreInvalid + 1;
            }
            if (state.elevatorMotor_Brake_Manual_Data.elevatorMotor_Brake_Selection[firstIndexWhere_Selected_brake].elevatorMotor_Brake.bowdenwireDeliver != null && state.elevatorMotor_Brake_Manual_Data.elevatorMotor_Brake_Selection[firstIndexWhere_Selected_brake].elevatorMotor_Brake.bowdenwireDeliver != 0 && state.elevatorMotor_Brake_Manual_Data.elevatorMotor_Brake_Selection[firstIndexWhere_Selected_brake].elevatorMotor_Brake.bowdenwireBlockNumber == null) {
                numberOf_inputs_whichAreInvalid = numberOf_inputs_whichAreInvalid + 1;
            }
        }

        if (firstIndexWhere_Selected_encoder == -1) {
            numberOf_inputs_whichAreInvalid = numberOf_inputs_whichAreInvalid + 1;
        }

        if (numberOf_inputs_whichAreInvalid > 0) {
            this.dispatch('statusHandling/change_dataAndStatus_storeAction', { status: { statusCode: '7200001', statusInContextOf: 'checkIf_inputs_areValid_storeAction', statusInformations: [{ textKey: 1446 }, { textKey: 1449 }], statusModalClosingOptions: [{ displayedDesignation: { textKey: 383 }, closingOption: 'close' }] } })
        }
        else if (numberOf_inputs_whichAreInvalid == 0) {
            dispatch('insertOrUpdate_driveTechnologiesManual_storeAction');
        }
    },
}

const mutations = {
    change_elevatorMotor_Machine_Manual_storeMutation(state, payload) {
        state.elevatorMotor_Machine_Manual = payload;
    },

    // elevatorMotor_Brake
    change_elevatorMotor_Brake_Manual_Data_storeMutation(state, payload) {
        state.elevatorMotor_Brake_Manual_Data = Object.assign({}, payload);
    },
    change_Selected_at_elevatorMotor_Brake_Selection_storeMutation(state, payload) {
        for (let i = 0; i < state.elevatorMotor_Brake_Manual_Data.elevatorMotor_Brake_Selection.length; i++) {
            state.elevatorMotor_Brake_Manual_Data.elevatorMotor_Brake_Selection[i].selected = false;
            if (i == payload) {
                state.elevatorMotor_Brake_Manual_Data.elevatorMotor_Brake_Selection[i].selected = true;
            }
        }
    },
    change_Selected_at_elevatorMotor_Brake_Selection_BrakeConnection_storeMutation(state, payload) {
        state.elevatorMotor_Brake_Manual_Data.elevatorMotor_Brake_Selection[payload.index].elevatorMotor_Brake.brakeConnectionTypeDesignation = payload.brakeConnectionTypeDesignation;
        state.elevatorMotor_Brake_Manual_Data.elevatorMotor_Brake_Selection[payload.index].elevatorMotor_Brake.brakeConnectionBlockNumber = payload.brakeConnectionBlockNumber;
    },
    change_Selected_at_elevatorMotor_Brake_Selection_Bowdenwire_storeMutation(state, payload) {
        state.elevatorMotor_Brake_Manual_Data.elevatorMotor_Brake_Selection[payload.index].elevatorMotor_Brake.bowdenwireDeliver = payload.bowdenwireDeliver;
        state.elevatorMotor_Brake_Manual_Data.elevatorMotor_Brake_Selection[payload.index].elevatorMotor_Brake.bowdenwireBlockNumber = payload.bowdenwireBlockNumber;
        state.elevatorMotor_Brake_Manual_Data.elevatorMotor_Brake_Selection[payload.index].elevatorMotor_Brake.bowdenwireLength = payload.bowdenwireLength;
    },
    change_elevatorMotor_Brake_Selections_Shown_storeMutation(state, payload) {
        state.elevatorMotor_Brake_Selections_Shown = payload;
    },

    change_loader_forLoadingData_at_elevatorMotor_Brake_Shown_storeMutation(state, payload) {
        state.loader_forLoadingData_at_elevatorMotor_Brake_Shown = payload;
    },
    abort_httpRequests_get_elevatorMotor_Brake_Informations_storeMutation(state) {
        state.cancellationInformationsAboutHttpRequests_get_elevatorMotor_Brake_Informations.abort();
    },
    change_cancellationInformationsAboutHttpRequests_get_elevatorMotor_Brake_Informations_toNewAbortController_storeMutation(state) {
        state.cancellationInformationsAboutHttpRequests_get_elevatorMotor_Brake_Informations = new AbortController();
    },

    // elevatorMotor_Brake_Voltage
    change_Selected_at_elevatorMotor_Brake_Voltage_Selection_storeMutation(state, payload) {
        for (let i = 0; i < state.elevatorMotor_Brake_Manual_Data.elevatorMotor_Brake_Voltage_Selection.length; i++) {
            state.elevatorMotor_Brake_Manual_Data.elevatorMotor_Brake_Voltage_Selection[i].selected = false;
            if (i == payload) {
                state.elevatorMotor_Brake_Manual_Data.elevatorMotor_Brake_Voltage_Selection[i].selected = true;
            }
        }
    },

    // elevatorMotor_Brake_MechanicalRelease
    change_Selected_at_elevatorMotor_Brake_MechanicalRelease_Selection_storeMutation(state, payload) {
        for (let i = 0; i < state.elevatorMotor_Brake_Manual_Data.elevatorMotor_Brake_MechanicalRelease_Selection.length; i++) {
            state.elevatorMotor_Brake_Manual_Data.elevatorMotor_Brake_MechanicalRelease_Selection[i].selected = false;
            if (i == payload) {
                state.elevatorMotor_Brake_Manual_Data.elevatorMotor_Brake_MechanicalRelease_Selection[i].selected = true;
            }
        }
    },
    change_Disabled_at_elevatorMotor_Brake_MechanicalRelease_Selection_storeMutation(state, payload) {
        state.elevatorMotor_Brake_Manual_Data.elevatorMotor_Brake_MechanicalRelease_Selection.forEach(i => {
            const item_whichMustNotBeDisabled = payload.some(ii => ii == i.text);
            i.disabled = true;
            if (item_whichMustNotBeDisabled) {
                i.disabled = false;
            }
        })
    },
    change_elevatorMotor_Brake_MechanicalRelease_ChangedByUser_storeMutation(state, payload) {
        state.elevatorMotor_Brake_MechanicalRelease_ChangedByUser = payload;
    },

    // elevatorMotor_Brake_ReleaseMonitoring
    change_Selected_at_elevatorMotor_Brake_ReleaseMonitoring_Selection_storeMutation(state, payload) {
        for (let i = 0; i < state.elevatorMotor_Brake_Manual_Data.elevatorMotor_Brake_ReleaseMonitoring_Selection.length; i++) {
            state.elevatorMotor_Brake_Manual_Data.elevatorMotor_Brake_ReleaseMonitoring_Selection[i].selected = false;
            if (i == payload) {
                state.elevatorMotor_Brake_Manual_Data.elevatorMotor_Brake_ReleaseMonitoring_Selection[i].selected = true;
            }
        }
    },
    change_Disabled_at_elevatorMotor_Brake_ReleaseMonitoring_Selection_storeMutation(state, payload) {
        state.elevatorMotor_Brake_Manual_Data.elevatorMotor_Brake_ReleaseMonitoring_Selection.forEach(i => {
            const item_whichMustNotBeDisabled = payload.some(ii => ii == i.text);
            i.disabled = true;
            if (item_whichMustNotBeDisabled) {
                i.disabled = false;
            }
        })
    },
    change_elevatorMotor_Brake_ReleaseMonitoring_ChangedByUser_storeMutation(state, payload) {
        state.elevatorMotor_Brake_ReleaseMonitoring_ChangedByUser = payload;
    },

    // elevatorMotor_Brake_Connection
    change_Selected_at_elevatorMotor_Brake_Connection_Selection_storeMutation(state, payload) {
        for (let i = 0; i < state.elevatorMotor_Brake_Manual_Data.elevatorMotor_Brake_Connection_Selection.length; i++) {
            state.elevatorMotor_Brake_Manual_Data.elevatorMotor_Brake_Connection_Selection[i].selected = false;
            if (i == payload) {
                state.elevatorMotor_Brake_Manual_Data.elevatorMotor_Brake_Connection_Selection[i].selected = true;
            }
        }
    },
    change_Selected_BlockNumber_at_elevatorMotor_Brake_Connection_Selection_storeMutation(state, payload) {
        for (let i = 0; i < state.elevatorMotor_Brake_Manual_Data.elevatorMotor_Brake_Connection_Selection.length; i++) {
            state.elevatorMotor_Brake_Manual_Data.elevatorMotor_Brake_Connection_Selection[i].selected = false;
            if (state.elevatorMotor_Brake_Manual_Data.elevatorMotor_Brake_Connection_Selection[i].blockNumber == payload.blockNumber) {
                state.elevatorMotor_Brake_Manual_Data.elevatorMotor_Brake_Connection_Selection[i].selected = true;
            }
        }
    },

    // elevatorMotor_Brake_Bowdenwire
    change_Selected_at_elevatorMotor_Brake_DeliverBowdenwire_Selection_storeMutation(state, payload) {
        for (let i = 0; i < state.elevatorMotor_Brake_Manual_Data.elevatorMotor_Brake_DeliverBowdenwire_Selection.length; i++) {
            state.elevatorMotor_Brake_Manual_Data.elevatorMotor_Brake_DeliverBowdenwire_Selection[i].selected = false;
            if (i == payload) {
                state.elevatorMotor_Brake_Manual_Data.elevatorMotor_Brake_DeliverBowdenwire_Selection[i].selected = true;
            }
        }
    },
    change_Selected_at_elevatorMotor_Brake_Bowdenwire_Selection_storeMutation(state, payload) {
        for (let i = 0; i < state.elevatorMotor_Brake_Manual_Data.elevatorMotor_Brake_Bowdenwire_Selection.length; i++) {
            state.elevatorMotor_Brake_Manual_Data.elevatorMotor_Brake_Bowdenwire_Selection[i].selected = false;
            if (i == payload) {
                state.elevatorMotor_Brake_Manual_Data.elevatorMotor_Brake_Bowdenwire_Selection[i].selected = true;
            }
        }
    },


    // elevatorMotor_Encoder
    change_elevatorMotor_Encoder_Data_storeMutation(state, payload) {
        state.elevatorMotor_Encoder_Data = Object.assign({}, payload);
    },
    change_elevatorMotor_Encoder_Selection_Shown_storeMutation(state, payload) {
        state.elevatorMotor_Encoder_Selection_Shown = payload;
    },
    change_Selected_at_elevatorMotor_Encoder_Selection_storeMutation(state, payload) {
        for (let i = 0; i < state.elevatorMotor_Encoder_Data.elevatorMotor_Encoder_Selection.length; i++) {
            state.elevatorMotor_Encoder_Data.elevatorMotor_Encoder_Selection[i].selected = false;
            if (i == payload) {
                state.elevatorMotor_Encoder_Data.elevatorMotor_Encoder_Selection[i].selected = true;
            }
        }
    },

}

export const driveTechnologiesManual = {
    namespaced: true,
    components: {
        axios
    },
    state,
    actions,
    mutations
}