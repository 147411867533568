import axios from 'axios';
import router from "@/router";

let state = {

    // pulley
    pulley_Data: {},

    // frame deliver options
    frameOptionsAvailable: {},
    frameDeliverOptions_Data: {},
    frameOperatingInstruction_Selection: [],
    frameMotorConsole_Selection: [],
    frameInformation: {},

}

const actions = {

    // frame
    change_frames_toDefault_storeAction({ commit, dispatch }) {
        commit('change_pulley_Data_storeMutation', {})
    },

    // pulley Sheave
    change_pulley_toDefault_storeAction({ commit }) {
        commit('change_pulley_Data_storeMutation', {});
    },

    async get_pulley_Informations_storeAction({ dispatch, rootState }) {
        const data = {
            configurationOverviewsId: rootState.configurations.configurationOverviewsId,
            configurationSteps: rootState.configurations.configurationSteps,
        }

        await axios({
            method: 'post',
            url: '/api/frames/get_pulley_Informations',
            data: {
                data: data,
            }
        })
            .then((response) => {
                this.dispatch('statusHandling/change_dataAndStatus_storeAction', response.data);

                if ((response.data.status.statusCode.substring(0, 1) == '2') || (response.data.status.statusCode.substring(0, 2) == '74') || (response.data.status.statusCode.substring(0, 2) == '75')) {
                    dispatch('toDos_afterResponseOf_get_pulley_Informations_storeAction', response.data.data)
                    if (state.frameDeliverOptions_Data.selectedPulleys == true) {
                        dispatch('change_selectedPulleys_UpdateDatabase2_storeAction', null);
                    }
                }
            })
            .catch(() => { })
    },
    toDos_afterResponseOf_get_pulley_Informations_storeAction({ dispatch }, value) {
        dispatch('change_pulley_Data_storeAction', value.pulley_Data);

        value.configurationSteps.pulley.loaded = true;
        this.dispatch('configurations/change_configurationSteps_storeAction', value.configurationSteps);
    },

    change_pulley_Data_storeAction({ commit }, value) {
        commit('change_pulley_Data_storeMutation', value)
    },

    async checkIf_changesAt_pulley_changes_configurationFlow_storeAction({ commit, dispatch }, value) {
        const firstIndex_whichMeetCondition = state.pulley_Data.pulley_Selection.findIndex(i => i.pulley.blockNumber == value.pulley.blockNumber);
        if (firstIndex_whichMeetCondition > -1) {
            //const firstIndexWhere_Selected_atElementOf_pulley_Selection_isTrue = state.pulley_Data.pulley_Selection.findIndex(i => i.selected == true);
            //if (firstIndexWhere_Selected_atElementOf_pulley_Selection_isTrue == firstIndex_whichMeetCondition) {
            //    commit('change_Selected_atEveryElementOf_pulley_Selection_toFalse_storeMutation')
            //}
            //else {
            dispatch('change_Selected_atElementOf_pulley_Selection_storeAction', firstIndex_whichMeetCondition)
            //}

            if (state.frameDeliverOptions_Data.selectedPulleys == true) {
                await this.dispatch('configurations/selectAndInsert_or_updateAndOrDelete_databaseRecords_storeAction', { changesAt: this._vm.$configurationSteps.pulley, pulley: value.pulley });
                this.dispatch('configurations/check_toDos_at_frames_storeAction')
            }
        }
        else {
            // error
        }
    },
    change_Selected_atElementOf_pulley_Selection_storeAction({ commit }, value) {
        commit('change_Selected_at_pulley_Selection_storeMutation', value);
    },

    // frame deliver options
    change_frameDeliverOptions_toDefault_storeAction({ commit, dispatch }) {
        commit('change_frameOptionsAvailable_storeMutation', {});
        commit('change_frameDeliverOptions_Data_storeMutation', {});
        commit('change_frameOperatingInstruction_Selection_storeMutation', []);
        commit('change_frameMotorConsole_Selection_storeMutation', []);
        commit('change_frameInformation_storeMutation', {});
    },
    async get_frameDeliverOptions_Informations_storeAction({ dispatch, rootState }) {
        const data = {
            configurationOverviewsId: rootState.configurations.configurationOverviewsId,
            configurationSteps: rootState.configurations.configurationSteps,
        }

        await axios({
            method: 'post',
            url: '/api/frames/get_frameDeliverOptions_Informations',
            data: {
                data: data,
            }
        })
            .then((response) => {
                this.dispatch('statusHandling/change_dataAndStatus_storeAction', response.data);

                if ((response.data.status.statusCode.substring(0, 1) == '2') || (response.data.status.statusCode.substring(0, 2) == '74') || (response.data.status.statusCode.substring(0, 2) == '75')) {
                    dispatch('toDos_afterResponseOf_get_frameDeliverOptions_Informations_storeAction', response.data.data)
                }
            })
            .catch(() => { })
    },

    toDos_afterResponseOf_get_frameDeliverOptions_Informations_storeAction({ dispatch }, value) {
        dispatch('change_selectedPulleys_UpdateDatabase_storeAction', value.frameDeliverOption.selectedPulleys);
        dispatch('change_frameDeliverOptions_Data_storeAction', value.frameDeliverOption);
        dispatch('change_frameOperatingInstruction_Selection_storeAction', value.operatingInstructionLanguage_Selection);
        dispatch('change_frameMotorConsole_Selection_storeAction', value.motorConsole_Selection);
        dispatch('change_frameOptionsAvailable_storeAction', value.frameOptionsAvailable)
        dispatch('change_frameInformation_storeAction', value.frameInformation)
        //const firstIndexWhere_Selected_atElementOf_motorConsole_Selection_isTrue = state.motorConsole_Selection.findIndex(i => i.selected == true);
        //if (firstIndexWhere_Selected_atElementOf_motorConsole_Selection_isTrue == firstIndex_whichMeetCondition) {
        //    commit('change_Selected_atEveryElementOf_motorConsole_Selection_toFalse_storeMutation')
        //}
        //else {
        //    dispatch('change_Selected_atElementOf_motorConsole_Selection_storeAction', firstIndex_whichMeetCondition)
        //}
        value.configurationSteps.frameDeliverOptions.loaded = true;
        this.dispatch('configurations/change_configurationSteps_storeAction', value.configurationSteps);

    },

    change_frameDeliverOptions_Data_storeAction({ commit, dispatch }, value) {
        commit('change_frameDeliverOptions_Data_storeMutation', value);
        if (state.frameDeliverOptions_Data.hasOwnProperty('operatingInstructionLanguage') && Array.isArray(state.frameOperatingInstruction_Selection) && state.frameOperatingInstruction_Selection.length > 0) {
            const firstIndex_whichMeetCondition = state.frameOperatingInstruction_Selection.findIndex(i => i.text == state.frameDeliverOptions_Data.operatingInstructionLanguage);
            if (firstIndex_whichMeetCondition > -1) {
                const firstIndexWhere_Selected_atElementOf_operatingInstruction_Selection_isTrue = state.frameOperatingInstruction_Selection.findIndex(i => i.selected == true);
                if (firstIndexWhere_Selected_atElementOf_operatingInstruction_Selection_isTrue == firstIndex_whichMeetCondition) {
                    //commit('change_Selected_atEveryElementOf_operatingInstruction_Selection_toFalse_storeMutation')
                }
                else {
                    dispatch('change_Selected_atElementOf_operatingInstruction_Selection_storeAction', firstIndex_whichMeetCondition)
                }
            }
        }

        if (state.frameDeliverOptions_Data.hasOwnProperty('motorConsole') && Array.isArray(state.frameMotorConsole_Selection) && state.frameMotorConsole_Selection.length > 0) {
            const firstIndex_whichMeetCondition_MotorConsole = state.frameMotorConsole_Selection.findIndex(i => i.text == state.frameDeliverOptions_Data.motorConsole);
            if (firstIndex_whichMeetCondition_MotorConsole > -1) {
                const firstIndexWhere_Selected_atElementOf_frameMotorConsole_Selection_isTrue = state.frameMotorConsole_Selection.findIndex(i => i.selected == true);
                if (firstIndexWhere_Selected_atElementOf_frameMotorConsole_Selection_isTrue == firstIndex_whichMeetCondition_MotorConsole) {
                    //commit('change_Selected_atEveryElementOf_motorConsole_Selection_toFalse_storeMutation')
                }
                else {
                    dispatch('change_Selected_atElementOf_motorConsole_Selection_storeAction', firstIndex_whichMeetCondition_MotorConsole)
                }
            }
        }



    },

    change_frameOperatingInstruction_Selection_storeAction({ commit }, value) {
        commit('change_frameOperatingInstruction_Selection_storeMutation', value);
    },
    change_frameMotorConsole_Selection_storeAction({ commit }, value) {
        commit('change_frameMotorConsole_Selection_storeMutation', value);
    },
    change_frameOptionsAvailable_storeAction({ commit }, value) {
        commit('change_frameOptionsAvailable_storeMutation', value);
    },
    change_frameInformation_storeAction({ commit }, value) {
        commit('change_frameInformation_storeMutation', value);
    },
    toDos_afterResponseOf_get_frameOperatingInstruction_storeAction({ dispatch, commit }, value) {
        if (value == true) {
            const firstIndex_whichMeetCondition = state.frameOperatingInstruction_Selection.findIndex(i => i.selected == true);
            if (firstIndex_whichMeetCondition > -1) {
                const firstIndexWhere_Selected_atElementOf_operatingInstruction_Selection_isTrue = state.frameOperatingInstruction_Selection.findIndex(i => i.selected == true);
                dispatch('change_Selected_atElementOf_operatingInstruction_Selection_storeAction', firstIndexWhere_Selected_atElementOf_operatingInstruction_Selection_isTrue)
            }
            else {
                dispatch('change_Selected_atElementOf_operatingInstruction_Selection_storeAction', 0)
            }
        }
        else {
            commit('change_Selected_atEveryElementOf_operatingInstruction_Selection_toFalse_storeMutation')
        }
        dispatch('change_selectedFrameOption_storeAction');
    },


    change_selectedFrameOption_storeAction({ dispatch }) {
        dispatch('checkIf_changesAt_frameDeliverOptions_changes_configurationFlow_storeAction');
        this.dispatch('configurations/check_toDos_at_frames_storeAction')
    },

    change_selectedAssembled_storeAction({ commit, dispatch }, value) {
        commit('change_selectedAssembled_storeMutation', value);
        dispatch('change_selectedFrameOption_storeAction');
    },
    change_selectedEmergencyStopSwitch_storeAction({ commit, dispatch }, value) {
        commit('change_selectedEmergencyStopSwitch_storeMutation', value);
        dispatch('change_selectedFrameOption_storeAction');
    },
    change_selectedIsolation_storeAction({ commit, dispatch }, value) {
        commit('change_selectedIsolation_storeMutation', value);
        dispatch('change_selectedFrameOption_storeAction');
    },
    change_selectedOperatingInstructions_storeAction({ commit, dispatch }, value) {
        commit('change_selectedOperatingInstructions_storeMutation', value);
        dispatch('toDos_afterResponseOf_get_frameOperatingInstruction_storeAction', value);
        dispatch('change_selectedFrameOption_storeAction');
    },
    async change_selectedPulleys_storeAction({ commit, dispatch }, value) {
        dispatch('change_selectedPulleys_UpdateDatabase_storeAction', value);
        commit('change_selectedPulleys_storeMutation', value);
        await dispatch('change_selectedFrameOption_storeAction');
    },
    async change_selectedPulleys_UpdateDatabase_storeAction({ rootState }, value) {
        if (value != state.frameDeliverOptions_Data.selectedPulleys) {
            if (state.pulley_Data != null && state.pulley_Data.hasOwnProperty('pulley_Selection') && Array.isArray(state.pulley_Data.pulley_Selection)) {
                if (state.pulley_Data != null) {
                    const firstIndex_whichMeetCondition = state.pulley_Data.pulley_Selection.findIndex(i => i.selected == true);
                    if (firstIndex_whichMeetCondition > -1) {
                        await this.dispatch('configurations/selectAndInsert_or_updateAndOrDelete_databaseRecords_storeAction', { changesAt: this._vm.$configurationSteps.pulley, pulley: state.pulley_Data.pulley_Selection[firstIndex_whichMeetCondition].pulley });
                        this.dispatch('configurations/check_toDos_at_frames_storeAction')
                    }

                }
            }
        }
    },
    async change_selectedPulleys_UpdateDatabase2_storeAction({ rootState }, value) {
        if (value != state.frameDeliverOptions_Data.selectedPulleys) {
            if (state.pulley_Data != null && state.pulley_Data.hasOwnProperty('pulley_Selection') && Array.isArray(state.pulley_Data.pulley_Selection)) {
                if (state.pulley_Data != null) {
                    const firstIndex_whichMeetCondition = state.pulley_Data.pulley_Selection.findIndex(i => i.selected == true);
                    if (firstIndex_whichMeetCondition > -1) {
                        if (rootState.configurations.configuration_Modifiable == true) {
                            await this.dispatch('configurations/selectAndInsert_or_updateAndOrDelete_databaseRecords_storeAction', { changesAt: this._vm.$configurationSteps.pulley, pulley: state.pulley_Data.pulley_Selection[firstIndex_whichMeetCondition].pulley });
                        }
                        this.dispatch('configurations/check_toDos_at_frames_storeAction')
                    }

                }
            }
        }
    },
    change_selectedRetrofit_storeAction({ commit, dispatch }, value) {
        commit('change_selectedRetrofit_storeMutation', value);
        dispatch('change_selectedFrameOption_storeAction');
    },
    change_selectedRopeGuard_storeAction({ commit, dispatch }, value) {
        commit('change_selectedRopeGuard_storeMutation', value);
        dispatch('change_selectedFrameOption_storeAction');
    },
    async checkIf_changesAt_frameDeliverOptions_changes_configurationFlow_storeAction({ rootState, dispatch }) {
        await this.dispatch('configurations/selectAndInsert_or_updateAndOrDelete_databaseRecords_storeAction', { changesAt: this._vm.$configurationSteps.frameDeliverOptions, frameDeliverOptions: state.frameDeliverOptions_Data });
    },

    async insert_databaseRecord_at_frameDeliverOptions_storeAction({ dispatch, rootState }) {
        const data = {
            configurationOverviewsId: rootState.configurations.configurationOverviewsId,
            configurationSteps: rootState.configurations.configurationSteps,
            frameDeliverOptions: state.frameDeliverOptions_Data,
        }

        await axios({
            method: 'post',
            url: '/api/frames/insert_databaseRecord_at_frameDeliverOptions',
            data: {
                data: data,
            }
        })
            .then((response) => {
                dispatch('statusHandling/change_dataAndStatus_storeAction', response.data);

                if (response.data.status.statusCode.startsWith('2')) {
                    dispatch('configurations/change_configurationSteps_storeAction', response.data.data.configurationSteps);
                }
            })
            .catch(() => { })
    },

    async checkIf_changesAt_operatingInstructionLanguage_changes_configurationFlow_storeAction({ commit, dispatch }, value) {
        const firstIndex_whichMeetCondition = state.frameOperatingInstruction_Selection.findIndex(i => i.text == value.text);
        if (firstIndex_whichMeetCondition > -1) {
            const firstIndexWhere_Selected_atElementOf_operatingInstruction_Selection_isTrue = state.frameOperatingInstruction_Selection.findIndex(i => i.selected == true);
            if (firstIndexWhere_Selected_atElementOf_operatingInstruction_Selection_isTrue == firstIndex_whichMeetCondition) {
                //commit('change_Selected_atEveryElementOf_operatingInstruction_Selection_toFalse_storeMutation')
            }
            else {
                dispatch('change_Selected_atElementOf_operatingInstruction_Selection_storeAction', firstIndex_whichMeetCondition)
            }
            //await this.dispatch('configurations/selectAndInsert_or_updateAndOrDelete_databaseRecords_storeAction', { changesAt: this._vm.$configurationSteps.pulley, pulley: value.pulley });
            this.dispatch('configurations/check_toDos_at_frames_storeAction')
        }
        else {
            // error
        }
        dispatch('change_selectedFrameOption_storeAction');
    },

    change_Selected_atElementOf_operatingInstruction_Selection_storeAction({ commit }, value) {
        commit('change_Selected_atElementOf_operatingInstruction_Selection_storeMutation', value);
    },

    async checkIf_changesAt_motorConsole_changes_configurationFlow_storeAction({ commit, dispatch }, value) {
        const firstIndex_whichMeetCondition = state.frameMotorConsole_Selection.findIndex(i => i.text == value.text);
        if (firstIndex_whichMeetCondition > -1) {
            const firstIndexWhere_Selected_atElementOf_frameMotorConsole_Selection_isTrue = state.frameMotorConsole_Selection.findIndex(i => i.selected == true);
            if (firstIndexWhere_Selected_atElementOf_frameMotorConsole_Selection_isTrue == firstIndex_whichMeetCondition) {
                //commit('change_Selected_atEveryElementOf_motorConsole_Selection_toFalse_storeMutation')
            }
            else {
                dispatch('change_Selected_atElementOf_motorConsole_Selection_storeAction', firstIndex_whichMeetCondition)
            }
            //await this.dispatch('configurations/selectAndInsert_or_updateAndOrDelete_databaseRecords_storeAction', { changesAt: this._vm.$configurationSteps.pulley, pulley: value.pulley });
            this.dispatch('configurations/check_toDos_at_frames_storeAction')
        }
        else {
            // error
        }
        dispatch('change_selectedFrameOption_storeAction');
    },

    change_Selected_atElementOf_motorConsole_Selection_storeAction({ commit }, value) {
        commit('change_Selected_atElementOf_motorConsole_Selection_storeMutation', value);
    },

}



const mutations = {
    // pulleys
    change_pulley_Data_storeMutation(state, payload) {
        state.pulley_Data = Object.assign({}, payload);
    },
    change_Selected_at_pulley_Selection_storeMutation(state, payload) {
        for (let i = 0; i < state.pulley_Data.pulley_Selection.length; i++) {
            state.pulley_Data.pulley_Selection[i].selected = false;
            if (i == payload) {
                state.pulley_Data.pulley_Selection[i].selected = true;
            }
        }
    },
    change_Selected_atEveryElementOf_pulley_Selection_toFalse_storeMutation(state) {
        if (Array.isArray(state.pulley_Data.pulley_Selection)) {
            state.pulley_Data.pulley_Selection.forEach(i => {
                i.selected = false;
            })
        }
    },
    change_frameDeliverOptions_Data_storeMutation(state, payload) {
        state.frameDeliverOptions_Data = payload;
    },
    change_frameOperatingInstruction_Selection_storeMutation(state, payload) {
        state.frameOperatingInstruction_Selection = payload;
    },
    change_frameMotorConsole_Selection_storeMutation(state, payload) {
        state.frameMotorConsole_Selection = payload;
    },
    change_frameOptionsAvailable_storeMutation(state, payload) {
        state.frameOptionsAvailable = payload;
    },
    change_selectedAssembled_storeMutation(state, payload) {
        state.frameDeliverOptions_Data.selectedAssembled = payload;
    },
    change_selectedEmergencyStopSwitch_storeMutation(state, payload) {
        state.frameDeliverOptions_Data.selectedEmergencyStopSwitch = payload;
    },
    change_selectedIsolation_storeMutation(state, payload) {
        state.frameDeliverOptions_Data.selectedIsolation = payload;
    },
    change_selectedOperatingInstructions_storeMutation(state, payload) {
        state.frameDeliverOptions_Data.selectedOperatingInstructions = payload;
    },
    change_selectedPulleys_storeMutation(state, payload) {
        state.frameDeliverOptions_Data.selectedPulleys = payload;
    },
    change_selectedRetrofit_storeMutation(state, payload) {
        state.frameDeliverOptions_Data.selectedRetrofit = payload;
    },
    change_selectedRopeGuard_storeMutation(state, payload) {
        state.frameDeliverOptions_Data.selectedRopeGuard = payload;
    },
    change_frameOperatingInstructionLanguage(state, payload) {
        state.frameDeliverOptions_Data.operatingInstructionLanguage = payload;
    },
    change_Selected_atElementOf_operatingInstruction_Selection_storeMutation(state, payload) {
        for (let i = 0; i < state.frameOperatingInstruction_Selection.length; i++) {
            state.frameOperatingInstruction_Selection[i].selected = false;
            if (i == payload) {
                state.frameOperatingInstruction_Selection[i].selected = true;
                state.frameDeliverOptions_Data.operatingInstructionLanguage = state.frameOperatingInstruction_Selection[i].text;
            }
        }
    },
    change_Selected_atEveryElementOf_operatingInstruction_Selection_toFalse_storeMutation(state) {
        if (Array.isArray(state.frameOperatingInstruction_Selection)) {
            state.frameOperatingInstruction_Selection.forEach(i => {
                i.selected = false;
            })
        }
        state.frameDeliverOptions_Data.operatingInstructionLanguage = null;
    },

    change_Selected_atElementOf_motorConsole_Selection_storeMutation(state, payload) {
        for (let i = 0; i < state.frameMotorConsole_Selection.length; i++) {
            state.frameMotorConsole_Selection[i].selected = false;
            if (i == payload) {
                state.frameMotorConsole_Selection[i].selected = true;
                state.frameDeliverOptions_Data.motorConsole = state.frameMotorConsole_Selection[i].text;
            }
        }
    },
    change_frameInformation_storeMutation(state, payload) {
        state.frameInformation = payload;
    },
}

export const frames = {
    namespaced: true,
    components: {
        axios
    },
    state,
    actions,
    mutations
}