import axios from 'axios';

let state = {
    // inverter
    inverter_Data: {},

    inverter_Selection_Shown: false,
    inverter_Selection_lastIndex: 5,
    filtered_inverter_Selection: [],
    inverter_Selection_Filtered: false,

    // inverter filter
    inverter_Filter_Shown: true,
    //inverter_Filter_ListItem: 'filter',

    inverter_FilterCriterion_inverter_Series_Selection: [],
    inverter_FilterCriterion_inverter_Series_Selection_MountingControlCabinet: [],
    arrayOf_active_inverter_FilterCriteria: [],

    // unintendedCarMovement 
    inverter_forWhich_unintendedCarMovement_isToBeMade: {},

    unintendedCarMovementCheck_Modal_Shown: false,
    unintendedCarMovementCheck_Input_Disabled: true,
    loader_forLoadingAllContent_at_unintendedCarMovementCheck_Modal_Shown: false,
    loader_forLoadingResults_at_unintendedCarMovementCheck_Modal_Shown: false,
    cancellationInformationsAboutHttpRequests_get_unintendedCarMovementCheck_Informations: new AbortController(),

    unintendedCarMovementCheck_Inputs_ValueListAndSelectionsAndPermissibleValueRanges: {},
    unintendedCarMovementCheck_Inputs_ValueListAndSelectionsAndPermissibleValueRanges_Loaded: false,

    doorZone: { value: undefined, invalid: undefined },
    deadTime: { value: undefined, invalid: undefined },
    accelerationLimitedTo2Point5MetersPerSquareSecond: undefined,
    speedLimit: { value: undefined, invalid: undefined },
    lowestShaftDoorHeight: { value: undefined, invalid: undefined },
    carDoorHeight: { value: undefined, invalid: undefined },
    carDoorApronLength: { value: undefined, invalid: undefined },
    unintendedCarMovementCheck_Inputs_Invalid: undefined,
    informationPermissibleDistance: undefined,

    carWeightInWorstLoadCase: {},
    travelDirectionInWorstLoadCase: {},

    unintendedCarMovementCheck_PresentationOfResults: 'overview',

    calculatedWithShortCircuitMotorBraking: false,
    unintendedCarMovementCheck: {},


    // brakeResistor / powerRecuperationUnit
    productTypeForConversionOfRegenerativeEnergy: 'brake resistor',

    // brakeResistor
    brakeResistor_Data: {},
    full_brakeResistor_Selection_Shown: false,

    // powerRecuperationUnit 
    powerRecuperationUnit_Data: {},
    full_powerRecuperationUnit_Selection_Shown: false,

    // brakeControl
    brakeControl_Data: {},


    // energyEfficiencyRating
    loader_forLoadingResults_at_energyEfficiencyRating_Shown: false,
    cancellationInformationsAboutHttpRequests_get_energyEfficiencyRating_Informations: new AbortController(),

    energyEfficiencyRating_PermissibleValueRanges: {},

    standby_PowerConsumptionOfCarLightAndElevatorControlSystem: { value: undefined, invalid: undefined },
    travel_PowerConsumptionOfCarLightAndElevatorControlSystem: { value: undefined, invalid: undefined },
    travel_LoadFactor_Calculated: undefined,
    travel_LoadFactor: { value: undefined, invalid: undefined },
    electricityCosts: { value: undefined, invalid: undefined },
    energyEfficiencyRating_Inputs_Invalid: undefined,

    energyEfficiencyRating: {},

    printInDocument: false,

    // comparisonOfPossibilitiesToConvertRegenerativeEnergy
    comparisonOfPossibilitiesToConvertRegenerativeEnergy_Modal_Shown: false,
    loader_forLoadingAllContent_at_comparisonOfPossibilitiesToConvertRegenerativeEnergy_Modal_Shown: false,
    loader_forLoadingResults_at_comparisonOfPossibilitiesToConvertRegenerativeEnergy_Modal_Shown: false,
    cancellationInformationsAboutHttpRequests_get_comparisonOfPossibilitiesToConvertRegenerativeEnergy_Informations: new AbortController(),

    comparisonOfPossibilitiesToConvertRegenerativeEnergy: {},

}

const actions = {
    //Test Dimmler 04.05
    async get_FI_Type_storeAction({ commit }) {

        //console.log('get_FI_Type_storeAction')
        await axios({
            method: 'post',
            url: '/api/FI/get_FI_Type',

        })
            .then((response) => {
                commit('get_FI_Type_storeMutation', response)


            })
            .catch(() => { })
    },

    set_decimalValue({ dispatch }, value) {
        const valueDecimalCriteria = this._vm.$localDecimal;
        valueDecimalCriteria.value = value.value;
        if (value.country == 'de') {
            valueDecimalCriteria.country = 'de-DE';
        }
        else {
            valueDecimalCriteria.country = 'en-US';
        }

        const decimalValue = this._vm.$set_decimalValueToLocaleString(valueDecimalCriteria);

        if (value.name == 'speedLimit') {
            dispatch('change_speedLimit_storeAction', decimalValue);
        }
        else if (value.name == 'standby_PowerConsumptionOfCarLightAndElevatorControlSystem') {
            dispatch('change_standby_PowerConsumptionOfCarLightAndElevatorControlSystem_storeAction', decimalValue);
        }
        else if (value.name == 'travel_PowerConsumptionOfCarLightAndElevatorControlSystem') {
            dispatch('change_travel_PowerConsumptionOfCarLightAndElevatorControlSystem_storeAction', decimalValue);
        }
        else if (value.name == 'electricityCosts') {
            dispatch('change_electricityCosts_storeAction', decimalValue);
        }
    },

    change_controlTechnologies_toDefault_storeAction({ dispatch }) {
        dispatch('change_inverter_toDefault_storeAction')
        dispatch('change_unintendedCarMovementCheck_toDefault_storeAction')
        dispatch('change_comparisonOfPossibilitiesToConvertRegenerativeEnergy_toDefault_storeAction')
        dispatch('change_brakeResistor_toDefault_storeAction')
        dispatch('change_powerRecuperationUnit_toDefault_storeAction')
        dispatch('change_brakeControl_toDefault_storeAction')
        dispatch('change_energyEfficiencyRating_toDefault_storeAction')
    },

    // inverter
    change_inverter_toDefault_storeAction({ commit }) {
        commit('change_inverter_Data_storeMutation', {});

        commit('change_inverter_Selection_Shown_storeMutation', false);
        commit('change_inverter_Selection_lastIndex_storeMutation', 5);
        commit('change_filtered_inverter_Selection_storeMutation', []);
        commit('change_inverter_Selection_Filtered_storeMutation', false);

        commit('change_inverter_Filter_Shown_storeMutation', true);
        //commit('change_inverter_Filter_ListItem_storeMutation', 'filter');

        commit('change_inverter_FilterCriterion_inverter_Series_Selection_storeMutation', []);
        commit('change_inverter_FilterCriterion_inverter_Series_Selection_MountingControlCabinet_storeMutation', [])
        commit('remove_allElementsAt_arrayOf_active_inverter_FilterCriteria_storeMutation');
    },

    change_inverter_Selection_Shown_storeAction({ commit }, value) {
        commit('change_inverter_Selection_Shown_storeMutation', value)
    },

    async get_inverter_Informations_storeAction({ dispatch, rootState }) {
        const data = {
            configurationOverviewsId: rootState.configurations.configurationOverviewsId,
            configurationSteps: rootState.configurations.configurationSteps,
        }

        await axios({
            method: 'post',
            url: '/api/controlTechnologies/get_inverter_Informations',
            data: {
                data: data,
            }
        })
            .then((response) => {
                this.dispatch('statusHandling/change_dataAndStatus_storeAction', response.data);

                if ((response.data.status.statusCode.substring(0, 1) == '2') || (response.data.status.statusCode.substring(0, 2) == '74') || (response.data.status.statusCode.substring(0, 2) == '75')) {
                    dispatch('toDos_afterResponseOf_get_inverter_Informations_storeAction', response.data.data);
                }
            })
            .catch(() => { })
    },
    toDos_afterResponseOf_get_inverter_Informations_storeAction({ rootState, commit, dispatch }, value) {
        dispatch('change_inverter_Data_storeAction', value.inverter_Data);
        dispatch('checkIf_userPreferences_for_inverter_FilterCriteria_Set_storeAction');
        commit('change_inverter_Selection_Shown_storeMutation', true)

        var configurationSteps = Object.assign({}, rootState.configurations.configurationSteps);
        var configurationSteps_inverter = Object.assign({}, rootState.configurations.configurationSteps.inverter);
        configurationSteps_inverter.loaded = true;
        configurationSteps.inverter = configurationSteps_inverter;
        this.dispatch('configurations/change_configurationSteps_storeAction', configurationSteps)
    },

    change_inverter_Data_storeAction({ commit }, value) {
        commit('change_inverter_Data_storeMutation', value);
        commit('change_inverter_FilterCriterion_inverter_Series_Selection_storeMutation', value.inverter_Filter_Data.inverter_Series_Selection.mountingWall)
        commit('change_inverter_FilterCriterion_inverter_Series_Selection_MountingControlCabinet_storeMutation', value.inverter_Filter_Data.inverter_Series_Selection.mountingControlCabinet)
    },

    async checkIf_userPreferences_for_inverter_FilterCriteria_Set_storeAction({ rootState, commit, dispatch }) {
        if (rootState.user.userPreferences_Loaded == false) {
            await this.dispatch('user/get_userPreference_Informations_storeAction')
        }

        if ((rootState.user.userPreferences.hasOwnProperty('inverter_Series') == true) && (rootState.user.userPreferences.inverter_Series.length > 0)) {
            rootState.user.userPreferences.inverter_Series.forEach(i => {
                state.inverter_Data.inverter_Filter_Data.inverter_Series_Selection.mountingControlCabinet.forEach(ii => {
                    if (ii.series == i) {
                        commit('change_Selected_atElementOf_inverter_FilterCriterion_inverter_Series_Selection_storeMutation', i)
                        commit('change_Selected_atElementOf_inverter_FilterCriterion_inverter_Series_Selection_MountingControlCabinet_storeMutation', i)
                        commit('add_elementAt_arrayOf_active_inverter_FilterCriteria_storeMutation', { filterBy: 'inverter_Series', data: i })
                    }
                })
            })
        }

        if (state.arrayOf_active_inverter_FilterCriteria.length > 0) {
            dispatch('filter_inverter_Selection_storeAction');
        }
    },

    async checkIf_changesAt_inverter_changes_configurationFlow_storeAction({ rootState, dispatch }, value) {
        const indexOf_firstElementOf_inverter_Selection_where_Selected_isTrue = state.inverter_Data.inverter_Selection.findIndex(i => i.selected == true);
        const firstIndex_whichMeetCondition = state.inverter_Data.inverter_Selection.findIndex(i => i.inverter.blockNumber == value.inverter.blockNumber);

        if (firstIndex_whichMeetCondition > -1) {
            dispatch('change_Selected_atElementOf_inverter_Selection_storeAction', firstIndex_whichMeetCondition);
            dispatch('change_inverter_Selection_Shown_storeAction', false);

            if (rootState.configurations.configurationSteps.inverter.storedInDatabase == true) {
                if (indexOf_firstElementOf_inverter_Selection_where_Selected_isTrue != firstIndex_whichMeetCondition) {
                    await this.dispatch('configurations/selectAndInsert_or_updateAndOrDelete_databaseRecords_storeAction', { changesAt: this._vm.$configurationSteps.inverter, elevatorMotor_Machine: state.inverter_Data.inverter_Selection[firstIndex_whichMeetCondition].elevatorMotor_Machine, inverter: state.inverter_Data.inverter_Selection[firstIndex_whichMeetCondition].inverter, unintendedCarMovementCheck: state.inverter_Data.inverter_Selection[firstIndex_whichMeetCondition].unintendedCarMovementCheck });
                }
            }
            else {
                await dispatch('insert_databaseRecord_at_inverters_storeAction', value)
                if (state.inverter_Data.inverter_Selection[firstIndex_whichMeetCondition].unintendedCarMovementCheck_Executed == true) {
                    await dispatch('insert_databaseRecord_at_unintendedCarMovementChecks_storeAction', state.inverter_Data.inverter_Selection[firstIndex_whichMeetCondition].unintendedCarMovementCheck)
                }
            }

            this.dispatch('configurations/check_toDos_at_controlTechnologies_storeAction')
        }
        else {
            // error
        }
    },
    change_Selected_atElementOf_inverter_Selection_storeAction({ commit, dispatch }, value) {
        dispatch('change_Selected_at_thirdPartyInverter_storeAction', false)
        commit('change_Selected_at_inverter_Selection_storeMutation', value)
    },
    change_Selected_atEveryElementOf_inverter_Selection_toFalse_storeAction({ commit }) {
        commit('change_Selected_atEveryElementOf_inverter_Selection_toFalse_storeMutation')
    },
    async change_Selected_atElementOf_inverter_Selection_basedOn_databaseRecord_storeAction({ rootState, dispatch }) {
        if (((rootState.configurations.loaded_configuration.hasOwnProperty(this._vm.$configurationSteps.inverter) == true) && (rootState.configurations.loaded_configuration.inverter != null) && (rootState.configurations.loaded_configuration.hasOwnProperty(this._vm.$configurationSteps.elevatorMotor_Machine) == true) && (rootState.configurations.loaded_configuration.elevatorMotor_Machine != null)) == false) {
            await this.dispatch('configurations/select_databaseRecords_storeAction', { selectDatabaseRecordsFor: 'controlTechnologies' });
        }

        if ((rootState.configurations.loaded_configuration.hasOwnProperty(this._vm.$configurationSteps.inverter) == true) && (rootState.configurations.loaded_configuration.inverter != null) && (rootState.configurations.loaded_configuration.hasOwnProperty(this._vm.$configurationSteps.elevatorMotor_Machine) == true) && (rootState.configurations.loaded_configuration.elevatorMotor_Machine != null)) {
            dispatch('change_inverter_Selection_Shown_storeAction', false);

            if (rootState.configurations.loaded_configuration.inverter.series == 'other inverter') {
                dispatch('change_Selected_at_thirdPartyInverter_storeAction', true);

                if (rootState.configurations.configurationSteps.unintendedCarMovementCheck.storedInDatabase == true) {
                    if (((rootState.configurations.loaded_configuration.hasOwnProperty(this._vm.$configurationSteps.unintendedCarMovementCheck) == true) && (rootState.configurations.loaded_configuration.unintendedCarMovementCheck != null)) == false) {
                        await this.dispatch('configurations/select_databaseRecords_storeAction', { selectDatabaseRecordsFor: 'controlTechnologies' });
                    }

                    if ((rootState.configurations.loaded_configuration.hasOwnProperty(this._vm.$configurationSteps.unintendedCarMovementCheck) == true) && (rootState.configurations.loaded_configuration.unintendedCarMovementCheck != null)) {
                        dispatch('add_unintendedCarMovementCheck_Object_at_thirdPartyInverter_storeAction', rootState.configurations.loaded_configuration.unintendedCarMovementCheck);
                    }
                }
            }
            else {
                const firstIndex_whichMeetCondition = state.inverter_Data.inverter_Selection.findIndex(i => i.inverter.blockNumber == rootState.configurations.loaded_configuration.inverter.blockNumber);
                if (firstIndex_whichMeetCondition > -1) {
                    dispatch('change_Selected_atElementOf_inverter_Selection_storeAction', firstIndex_whichMeetCondition);

                    if (rootState.configurations.configurationSteps.unintendedCarMovementCheck.storedInDatabase == true) {
                        if (((rootState.configurations.loaded_configuration.hasOwnProperty(this._vm.$configurationSteps.unintendedCarMovementCheck) == true) && (rootState.configurations.loaded_configuration.unintendedCarMovementCheck != null)) == false) {
                            await this.dispatch('configurations/select_databaseRecords_storeAction', { selectDatabaseRecordsFor: 'controlTechnologies' });
                        }

                        if ((rootState.configurations.loaded_configuration.hasOwnProperty(this._vm.$configurationSteps.unintendedCarMovementCheck) == true) && (rootState.configurations.loaded_configuration.unintendedCarMovementCheck != null)) {
                            dispatch('add_unintendedCarMovementCheck_Object_atElementOf_inverter_Selection_storeAction', { inverter_index: firstIndex_whichMeetCondition, unintendedCarMovementCheck: rootState.configurations.loaded_configuration.unintendedCarMovementCheck })
                        }
                    }
                }
                else {
                    // error
                }
            }
        }
    },

    async checkIf_changesAt_thirdPartyInverter_changes_configurationFlow_storeAction({ rootState, dispatch }) {
        dispatch('change_Selected_atEveryElementOf_inverter_Selection_toFalse_storeAction')
        dispatch('change_Selected_at_thirdPartyInverter_storeAction', true);
        dispatch('change_inverter_Selection_Shown_storeAction', false);

        if (rootState.configurations.configurationSteps.inverter.storedInDatabase == true) {
            this.dispatch('configurations/selectAndInsert_or_updateAndOrDelete_databaseRecords_storeAction', { changesAt: this._vm.$configurationSteps.inverter, elevatorMotor_Machine: state.inverter_Data.thirdPartyInverter.elevatorMotor_Machine, inverter: state.inverter_Data.thirdPartyInverter.inverter });
        }
        else {
            await dispatch('insert_databaseRecord_at_inverters_storeAction', state.inverter_Data.thirdPartyInverter)
            if (state.inverter_Data.thirdPartyInverter.unintendedCarMovementCheck_Executed == true) {
                await dispatch('insertOrUpdate_DatabaseRecord_at_unintendedCarMovementCheck_storeAction', state.inverter_Data.thirdPartyInverter.unintendedCarMovementCheck)
            }
        }
    },
    change_Selected_at_thirdPartyInverter_storeAction({ commit, dispatch }, value) {
        if (value == true) {
            dispatch('change_Selected_atEveryElementOf_inverter_Selection_toFalse_storeAction');
        }

        commit('change_Selected_at_thirdPartyInverter_storeMutation', value)
    },

    change_inverter_Selection_lastIndex_storeAction({ commit }, value) {
        commit('change_inverter_Selection_lastIndex_storeMutation', value)
    },

    async change_PowerConsumption_at_inverter_Selection_storeAction({ commit }, value) {
        if (state.inverter_Data.inverter_Selection.some(i => i.selected == true)) {
            commit('change_PowerConsumption_at_inverter_Selection_storeMutation', value)
        }
    },

    async insert_databaseRecord_at_inverters_storeAction({ rootState }, value) {
        var data = value;
        value.configurationOverviewsId = rootState.configurations.configurationOverviewsId;
        value.configurationSteps = rootState.configurations.configurationSteps;

        await axios({
            method: 'post',
            url: '/api/controlTechnologies/insert_databaseRecord_at_inverters',
            data: {
                data: data,
            }
        })
            .then((response) => {
                this.dispatch('statusHandling/change_dataAndStatus_storeAction', response.data);

                if (response.data.status.statusCode.substring(0, 1) == '2') {
                    this.dispatch('configurations/change_configurationSteps_storeAction', response.data.data.configurationSteps);
                }
            })
            .catch(() => { })
    },


    // inverter filter
    change_inverter_Filter_Shown_storeAction({ commit }, value) {
        commit('change_inverter_Filter_Shown_storeMutation', value)
    },
    //change_inverter_Filter_ListItem_storeAction({ commit }, value) {
    //    commit('change_inverter_Filter_ListItem_storeMutation', value)
    //},

    change_Selected_atElementOf_inverter_FilterCriterion_inverter_Series_Selection_storeAction({ commit, dispatch }, value) {
        commit('change_Selected_atElementOf_inverter_FilterCriterion_inverter_Series_Selection_storeMutation', value);
        commit('change_Selected_atElementOf_inverter_FilterCriterion_inverter_Series_Selection_MountingControlCabinet_storeMutation', value)
        dispatch('addOrRemove_inverter_Series_at_arrayof_active_inverter_FilterCriteria_storeAction', { filterBy: 'inverter_Series', data: value })
    },

    addOrRemove_inverter_Series_at_arrayof_active_inverter_FilterCriteria_storeAction({ commit, dispatch }, value) {
        const index = state.arrayOf_active_inverter_FilterCriteria.findIndex(i => (i.filterBy == value.filterBy) && (i.data == value.data))

        if (index > -1) {
            commit('remove_elementAt_arrayOf_active_inverter_FilterCriteria_storeMutation', index);
        }
        else {
            commit('add_elementAt_arrayOf_active_inverter_FilterCriteria_storeMutation', value);
        }

        if (state.arrayOf_active_inverter_FilterCriteria.length >= 1) {
            dispatch('filter_inverter_Selection_storeAction');
        }
        else if (state.arrayOf_active_inverter_FilterCriteria.length == 0) {
            commit('change_inverter_Selection_Filtered_storeMutation', false);
        }
    },
    remove_allElementsAt_arrayof_active_inverter_FilterCriteria_storeAction({ commit, dispatch }) {
        commit('remove_allElementsAt_arrayOf_active_inverter_FilterCriteria_storeMutation');
        commit('change_Selected_atEveryElementOf_inverter_FilterCriterion_inverter_Series_Selection_toFalse_storeMutation');
        commit('change_Selected_atEveryElementOf_inverter_FilterCriterion_inverter_Series_Selection_toFalse_MountingControlCabinet_storeMutation');

        dispatch('filter_inverter_Selection_storeAction');
    },

    filter_inverter_Selection_storeAction({ commit }) {
        commit('change_inverter_Selection_lastIndex_storeMutation', 5);
        commit('change_filtered_inverter_Selection_storeMutation', []);

        var duplicated_inverter_Selection = state.inverter_Data.inverter_Selection;
        var duplicated_arrayOf_active_inverter_FilterCriteria = [];
        duplicated_arrayOf_active_inverter_FilterCriteria = duplicated_arrayOf_active_inverter_FilterCriteria.concat(state.arrayOf_active_inverter_FilterCriteria);

        var duplicated_inverter_Selection_filteredBy_inverter_Series = [];

        for (let indexOf_duplicated_arrayOf_active_inverter_FilterCriteria = 0; indexOf_duplicated_arrayOf_active_inverter_FilterCriteria < duplicated_arrayOf_active_inverter_FilterCriteria.length; indexOf_duplicated_arrayOf_active_inverter_FilterCriteria++) {
            for (let indexOf_duplicated_inverter_Selection = 0; indexOf_duplicated_inverter_Selection < duplicated_inverter_Selection.length; indexOf_duplicated_inverter_Selection++) {
                if (duplicated_inverter_Selection[indexOf_duplicated_inverter_Selection].inverter.series == duplicated_arrayOf_active_inverter_FilterCriteria[indexOf_duplicated_arrayOf_active_inverter_FilterCriteria].data) {
                    duplicated_inverter_Selection_filteredBy_inverter_Series.push(duplicated_inverter_Selection[indexOf_duplicated_inverter_Selection]);
                }
            }
        }

        const filtered_inverter_Selection = duplicated_inverter_Selection.filter(x => duplicated_inverter_Selection_filteredBy_inverter_Series.includes(x));
        commit('change_filtered_inverter_Selection_storeMutation', filtered_inverter_Selection)

        if (state.arrayOf_active_inverter_FilterCriteria.length > 0) {
            commit('change_inverter_Selection_Filtered_storeMutation', true);
        }
        else if (state.arrayOf_active_inverter_FilterCriteria.length == 0) {
            commit('change_inverter_Selection_Filtered_storeMutation', false);
        }
    },


    // unintendedCarMovement
    change_unintendedCarMovementCheck_toDefault_storeAction({ commit }) {
        commit('change_inverter_forWhich_unintendedCarMovement_isToBeMade_storeMutation', {});

        commit('abort_httpRequests_get_unintendedCarMovementCheck_Informations_storeMutation');
        commit('change_loader_forLoadingAllContent_at_unintendedCarMovementCheck_Modal_Shown_storeMutation', false);
        commit('change_loader_forLoadingResults_at_unintendedCarMovementCheck_Modal_Shown_storeMutation', false);

        commit('change_doorZone_storeMutation', { value: null, invalid: null });
        commit('change_deadTime_storeMutation', { value: null, invalid: null });
        commit('change_accelerationLimitedTo2Point5MetersPerSquareSecond_storeMutation', null);
        commit('change_speedLimit_storeMutation', { value: null, invalid: null });
        commit('change_unintendedCarMovementCheck_Inputs_Invalid_storeMutation', null);

        commit('change_calculatedWithShortCircuitMotorBraking_storeMutation', false);

        commit('change_carWeightInWorstLoadCase_storeMutation', {});
        commit('change_travelDirectionInWorstLoadCase_storeMutation', {});
        commit('change_unintendedCarMovementCheck_storeMutation', {});

        commit('change_unintendedCarMovementCheck_Inputs_ValueListAndSelectionsAndPermissibleValueRanges_storeMutation', {});
        commit('change_unintendedCarMovementCheck_Inputs_ValueListAndSelectionsAndPermissibleValueRanges_Loaded_storeMutation', false);
    },

    async change_unintendedCarMovementCheck_Modal_Shown_storeAction({ commit, dispatch }, value) {
        if (value == true) {
            dispatch('change_change_unintendedCarMovementCheck_Input_Disabled_storeAction')
        }
        commit('change_unintendedCarMovementCheck_Modal_Shown_storeMutation', value);

        if (value == false) {
            dispatch('checkIf_changesAt_unintendedCarMovementCheck_changes_configurationFlow_storeAction')
        }
    },

    async change_change_unintendedCarMovementCheck_Input_Disabled_storeAction({ commit }) {
        await axios({
            method: 'post',
            url: '/api/controlTechnologies/get_unintendedCarMovementCheck_Input_Disabled',
        })

            .then((response) => {
                commit('change_unintendedCarMovementCheck_Input_Disabled_storeMutation', response.data)
            })
    },

    async checkIf_changesAt_unintendedCarMovementCheck_changes_configurationFlow_storeAction({ dispatch }) {
        if (state.inverter_forWhich_unintendedCarMovement_isToBeMade.unintendedCarMovementCheck_Executed == true) {
            if (state.unintendedCarMovementCheck_Inputs_Invalid == false) {
                var inputs_Changed = false;
                if ((state.doorZone.value != state.inverter_forWhich_unintendedCarMovement_isToBeMade.unintendedCarMovementCheck.doorZone)
                    || (state.deadTime.value != state.inverter_forWhich_unintendedCarMovement_isToBeMade.unintendedCarMovementCheck.deadTime)
                    || (state.accelerationLimitedTo2Point5MetersPerSquareSecond != state.inverter_forWhich_unintendedCarMovement_isToBeMade.unintendedCarMovementCheck.accelerationLimitedTo2Point5MetersPerSquareSecond)
                    || (state.speedLimit.value != state.inverter_forWhich_unintendedCarMovement_isToBeMade.unintendedCarMovementCheck.speedLimit)
                    || (state.lowestShaftDoorHeight.value != state.inverter_forWhich_unintendedCarMovement_isToBeMade.unintendedCarMovementCheck.lowestShaftDoorHeight)
                    || (state.carDoorHeight.value != state.inverter_forWhich_unintendedCarMovement_isToBeMade.unintendedCarMovementCheck.carDoorHeight)
                    || (state.carDoorApronLength.value != state.inverter_forWhich_unintendedCarMovement_isToBeMade.unintendedCarMovementCheck.carDoorApronLength)
                    || (state.calculatedWithShortCircuitMotorBraking != state.inverter_forWhich_unintendedCarMovement_isToBeMade.unintendedCarMovementCheck.calculatedWithShortCircuitMotorBraking)
                ) {
                    inputs_Changed = true;
                }

                if (inputs_Changed == true) {
                    if (state.inverter_forWhich_unintendedCarMovement_isToBeMade.inverter.series == 'other inverter') {
                        dispatch('change_unintendedCarMovementCheck_Object_at_thirdPartyInverter_storeAction');
                    }
                    else {
                        dispatch('change_unintendedCarMovementCheck_Object_at_inverter_Selection_storeAction');
                    }

                    if (state.inverter_forWhich_unintendedCarMovement_isToBeMade.selected == true) {
                        await this.dispatch('configurations/selectAndInsert_or_updateAndOrDelete_databaseRecords_storeAction', { changesAt: this._vm.$configurationSteps.unintendedCarMovementCheck, unintendedCarMovementCheck: state.unintendedCarMovementCheck });
                        this.dispatch('configurations/check_toDos_at_controlTechnologies_storeAction')
                    }
                }
            }
            else {
                if (state.inverter_forWhich_unintendedCarMovement_isToBeMade.inverter.series == 'other inverter') {
                    dispatch('delete_unintendedCarMovementCheck_Object_at_thirdPartyInverter_storeAction')
                }
                else {
                    dispatch('delete_unintendedCarMovementCheck_Object_at_inverter_Selection_storeAction')
                }

                if (state.inverter_forWhich_unintendedCarMovement_isToBeMade.selected == true) {
                    await this.dispatch('configurations/selectAndInsert_or_updateAndOrDelete_databaseRecords_storeAction', { changesAt: this._vm.$configurationSteps.unintendedCarMovementCheck });
                    this.dispatch('configurations/check_toDos_at_controlTechnologies_storeAction')
                }
            }
        }
        else {
            if (state.unintendedCarMovementCheck_Inputs_Invalid == false) {
                if (state.inverter_forWhich_unintendedCarMovement_isToBeMade.inverter.series == 'other inverter') {
                    dispatch('add_unintendedCarMovementCheck_Object_at_thirdPartyInverter_storeAction', state.unintendedCarMovementCheck);
                }
                else {
                    dispatch('add_unintendedCarMovementCheck_Object_at_inverter_Selection_storeAction');
                }

                if (state.inverter_forWhich_unintendedCarMovement_isToBeMade.selected == true) {
                    await this.dispatch('configurations/selectAndInsert_or_updateAndOrDelete_databaseRecords_storeAction', { changesAt: this._vm.$configurationSteps.unintendedCarMovementCheck, unintendedCarMovementCheck: state.unintendedCarMovementCheck });
                    this.dispatch('configurations/check_toDos_at_controlTechnologies_storeAction')
                }
            }
        }

        dispatch('change_unintendedCarMovementCheck_toDefault_storeAction');
    },

    async checkIf_unintendedCarMovementCheck_Inputs_ValueListAndSelectionsAndPermissibleValueRanges_Loaded_storeAction({ rootState, commit, dispatch }, inverter) {
        commit('change_inverter_forWhich_unintendedCarMovement_isToBeMade_storeMutation', inverter);

        if (state.tractionCheck_Inputs_ValueListsAndSelectionsAndPermissibleValueRanges_Loaded == true) {
            dispatch('get_unintendedCarMovementCheck_Informations_for_inverter_storeAction')
        }
        else {
            await dispatch('get_unintendedCarMovementCheck_Inputs_ValueListAndSelectionsAndPermissibleValueRanges_Informations_storeAction');
            if ((rootState.statusHandling.status.statusInContextOf == 'get_unintendedCarMovementCheck_Inputs_ValueListAndSelectionsAndPermissibleValueRanges_Informations') && ((rootState.statusHandling.status.statusCode.substring(0, 1) == '2') || (rootState.statusHandling.status.statusCode.substring(0, 2) == '74') || (rootState.statusHandling.status.statusCode.substring(0, 2) == '75'))) {
                dispatch('get_unintendedCarMovementCheck_Informations_for_inverter_storeAction')
            }
        }
    },
    async checkIf_unintendedCarMovementCheck_Inputs_ValueListAndSelectionsAndPermissibleValueRanges_Loaded2_storeAction({ rootState, commit, dispatch }, inverter) {
        commit('change_inverter_forWhich_unintendedCarMovement_isToBeMade_storeMutation', inverter);

        if (state.tractionCheck_Inputs_ValueListsAndSelectionsAndPermissibleValueRanges_Loaded == true) {
            dispatch('get_unintendedCarMovementCheck_Data_fromItemOf_inverter_Selection_storeAction', inverter)
        }
        else {
            await dispatch('get_unintendedCarMovementCheck_Inputs_ValueListAndSelectionsAndPermissibleValueRanges_Informations_storeAction');

            if ((rootState.statusHandling.status.statusInContextOf == 'get_unintendedCarMovementCheck_Inputs_ValueListAndSelectionsAndPermissibleValueRanges_Informations') && ((rootState.statusHandling.status.statusCode.substring(0, 1) == '2') || (rootState.statusHandling.status.statusCode.substring(0, 2) == '74') || (rootState.statusHandling.status.statusCode.substring(0, 2) == '75'))) {
                dispatch('get_unintendedCarMovementCheck_Data_fromItemOf_inverter_Selection_storeAction', inverter)
            }
        }
    },
    async checkIf_unintendedCarMovementCheck_Inputs_ValueListAndSelectionsAndPermissibleValueRanges_Loaded3_storeAction({ rootState, commit, dispatch }) {
        commit('change_inverter_forWhich_unintendedCarMovement_isToBeMade_storeMutation', state.inverter_Data.thirdPartyInverter)

        if (state.tractionCheck_Inputs_ValueListsAndSelectionsAndPermissibleValueRanges_Loaded == true) {
            dispatch('get_unintendedCarMovementCheck_Informations_for_thirdPartyInverter_storeAction')
        }
        else {
            await dispatch('get_unintendedCarMovementCheck_Inputs_ValueListAndSelectionsAndPermissibleValueRanges_Informations_storeAction');

            if ((rootState.statusHandling.status.statusInContextOf == 'get_unintendedCarMovementCheck_Inputs_ValueListAndSelectionsAndPermissibleValueRanges_Informations') && ((rootState.statusHandling.status.statusCode.substring(0, 1) == '2') || (rootState.statusHandling.status.statusCode.substring(0, 2) == '74') || (rootState.statusHandling.status.statusCode.substring(0, 2) == '75'))) {
                dispatch('get_unintendedCarMovementCheck_Informations_for_thirdPartyInverter_storeAction')
            }
        }
    },
    async checkIf_unintendedCarMovementCheck_Inputs_ValueListAndSelectionsAndPermissibleValueRanges_Loaded4_storeAction({ rootState, commit, dispatch }) {
        commit('change_inverter_forWhich_unintendedCarMovement_isToBeMade_storeMutation', state.inverter_Data.thirdPartyInverter)

        if (state.tractionCheck_Inputs_ValueListsAndSelectionsAndPermissibleValueRanges_Loaded == true) {
            dispatch('get_unintendedCarMovementCheck_Data_fromItemOf_thirdPartyInverter_storeAction')
        }
        else {
            await dispatch('get_unintendedCarMovementCheck_Inputs_ValueListAndSelectionsAndPermissibleValueRanges_Informations_storeAction');

            if ((rootState.statusHandling.status.statusInContextOf == 'get_unintendedCarMovementCheck_Inputs_ValueListAndSelectionsAndPermissibleValueRanges_Informations') && ((rootState.statusHandling.status.statusCode.substring(0, 1) == '2') || (rootState.statusHandling.status.statusCode.substring(0, 2) == '74') || (rootState.statusHandling.status.statusCode.substring(0, 2) == '75'))) {
                dispatch('get_unintendedCarMovementCheck_Data_fromItemOf_thirdPartyInverter_storeAction')
            }
        }
    },

    async get_unintendedCarMovementCheck_Informations_for_inverter_storeAction({ rootState, commit, dispatch }) {
        if (rootState.user.userPreferences_Loaded == false) {
            await this.dispatch('user/get_userPreference_Informations_storeAction')
        }
        dispatch('get_unintendedCarMovementCheck_Informations_storeAction', false);
    },
    async get_unintendedCarMovementCheck_Data_fromItemOf_inverter_Selection_storeAction({ commit, dispatch }, inverter) {
        commit('change_calculatedWithShortCircuitMotorBraking_storeMutation', inverter.unintendedCarMovementCheck.calculatedWithShortCircuitMotorBraking);
        commit('change_doorZone_storeMutation', { value: inverter.unintendedCarMovementCheck.doorZone, invalid: false });
        commit('change_deadTime_storeMutation', { value: inverter.unintendedCarMovementCheck.deadTime, invalid: false });
        commit('change_accelerationLimitedTo2Point5MetersPerSquareSecond_storeMutation', inverter.unintendedCarMovementCheck.accelerationLimitedTo2Point5MetersPerSquareSecond);
        commit('change_speedLimit_storeMutation', { value: inverter.unintendedCarMovementCheck.speedLimit, invalid: false });
        commit('change_lowestShaftDoorHeight_storeMutation', { value: inverter.unintendedCarMovementCheck.lowestShaftDoorHeight, invalid: false });
        commit('change_carDoorHeight_storeMutation', { value: inverter.unintendedCarMovementCheck.carDoorHeight, invalid: false });
        commit('change_carDoorApronLength_storeMutation', { value: inverter.unintendedCarMovementCheck.carDoorApronLength, invalid: false });
        commit('change_unintendedCarMovementCheck_Inputs_Invalid_storeMutation', false)
        commit('change_unintendedCarMovementCheck_storeMutation', inverter.unintendedCarMovementCheck);

        dispatch('get_unintendedCarMovementCheck_Informations_storeAction', true);
    },
    async get_unintendedCarMovementCheck_Informations_for_thirdPartyInverter_storeAction({ rootState, commit, dispatch }) {
        dispatch('get_unintendedCarMovementCheck_Informations_storeAction', false);
    },
    async get_unintendedCarMovementCheck_Data_fromItemOf_thirdPartyInverter_storeAction({ commit, dispatch }) {
        commit('change_calculatedWithShortCircuitMotorBraking_storeMutation', state.inverter_Data.thirdPartyInverter.unintendedCarMovementCheck.calculatedWithShortCircuitMotorBraking);
        commit('change_doorZone_storeMutation', { value: state.inverter_Data.thirdPartyInverter.unintendedCarMovementCheck.doorZone, invalid: false });
        commit('change_deadTime_storeMutation', { value: state.inverter_Data.thirdPartyInverter.unintendedCarMovementCheck.deadTime, invalid: false });
        commit('change_accelerationLimitedTo2Point5MetersPerSquareSecond_storeMutation', state.inverter_Data.thirdPartyInverter.unintendedCarMovementCheck.accelerationLimitedTo2Point5MetersPerSquareSecond);
        commit('change_speedLimit_storeMutation', { value: state.inverter_Data.thirdPartyInverter.unintendedCarMovementCheck.speedLimit, invalid: false });
        commit('change_lowestShaftDoorHeight_storeMutation', { value: state.inverter_Data.thirdPartyInverter.unintendedCarMovementCheck.lowestShaftDoorHeight, invalid: false });
        commit('change_carDoorHeight_storeMutation', { value: state.inverter_Data.thirdPartyInverter.unintendedCarMovementCheck.carDoorHeight, invalid: false });
        commit('change_carDoorApronLength_storeMutation', { value: state.inverter_Data.thirdPartyInverter.unintendedCarMovementCheck.carDoorApronLength, invalid: false });
        commit('change_unintendedCarMovementCheck_Inputs_Invalid_storeMutation', false)
        commit('change_unintendedCarMovementCheck_storeMutation', state.inverter_Data.thirdPartyInverter.unintendedCarMovementCheck);

        dispatch('get_unintendedCarMovementCheck_Informations_storeAction', true);
    },

    async get_unintendedCarMovementCheck_Inputs_ValueListAndSelectionsAndPermissibleValueRanges_Informations_storeAction({ rootState, commit, dispatch }) {
        const data = { configurationOverviewsId: rootState.configurations.configurationOverviewsId };

        await axios({
            method: 'post',
            url: '/api/controlTechnologies/get_unintendedCarMovementCheck_Inputs_ValueListAndSelectionsAndPermissibleValueRanges_Informations',
            data: {
                data: data,
            }
        })

            .then((response) => {
                this.dispatch('statusHandling/change_dataAndStatus_storeAction', response.data);

                if ((response.data.status.statusCode.substring(0, 1) == '2') || (response.data.status.statusCode.substring(0, 2) == '74') || (response.data.status.statusCode.substring(0, 2) == '75')) {
                    dispatch('toDos_afterResponseOf_get_unintendedCarMovementCheck_Inputs_ValueListAndSelectionsAndPermissibleValueRanges_Informations_storeAction', response.data.data)
                }
                else {
                    commit('change_unintendedCarMovementCheck_Inputs_ValueListAndSelectionsAndPermissibleValueRanges_storeMutation', {});
                    commit('change_unintendedCarMovementCheck_Inputs_ValueListAndSelectionsAndPermissibleValueRanges_Loaded_storeMutation', false)
                }
            })
    },

    toDos_afterResponseOf_get_unintendedCarMovementCheck_Inputs_ValueListAndSelectionsAndPermissibleValueRanges_Informations_storeAction({ commit }, value) {
        commit('change_unintendedCarMovementCheck_Inputs_ValueListAndSelectionsAndPermissibleValueRanges_storeMutation', value.unintendedCarMovementCheck_Inputs_ValueListAndSelectionsAndPermissibleValueRanges);
        commit('change_unintendedCarMovementCheck_Inputs_ValueListAndSelectionsAndPermissibleValueRanges_Loaded_storeMutation', true)
    },

    change_doorZone_storeAction({ commit, dispatch }, value) {
        const valueAndValueValidationCriteria = this._vm.$transmit_valueAndValueValidationCriteria;
        valueAndValueValidationCriteria.value = value;
        valueAndValueValidationCriteria.dataType = this._vm.$dataTypes.integer;
        valueAndValueValidationCriteria.permissibleNumberOfDecimalPlaces = null;
        valueAndValueValidationCriteria.permissibleValueRange.minimum.value = state.unintendedCarMovementCheck_Inputs_ValueListAndSelectionsAndPermissibleValueRanges.unintendedCarMovementCheck_PermissibleValueRanges.doorZone.minimum.value //0;
        valueAndValueValidationCriteria.permissibleValueRange.minimum.operator = state.unintendedCarMovementCheck_Inputs_ValueListAndSelectionsAndPermissibleValueRanges.unintendedCarMovementCheck_PermissibleValueRanges.doorZone.minimum.operator //this.$operators.greaterThan;
        valueAndValueValidationCriteria.permissibleValueRange.maximum.value = state.unintendedCarMovementCheck_Inputs_ValueListAndSelectionsAndPermissibleValueRanges.unintendedCarMovementCheck_PermissibleValueRanges.doorZone.maximum.value;
        valueAndValueValidationCriteria.permissibleValueRange.maximum.operator = state.unintendedCarMovementCheck_Inputs_ValueListAndSelectionsAndPermissibleValueRanges.unintendedCarMovementCheck_PermissibleValueRanges.doorZone.maximum.operator;
        const doorZone_ValidationResult = this._vm.$checkIf_value_isValid(valueAndValueValidationCriteria);

        commit('change_doorZone_storeMutation', doorZone_ValidationResult)
        dispatch('checkIf_unintendedCarMovementCheck_Inputs_areValid_storeAction');
    },
    change_deadTime_storeAction({ commit, dispatch }, value) {
        const valueAndValueValidationCriteria = this._vm.$transmit_valueAndValueValidationCriteria;
        valueAndValueValidationCriteria.value = value;
        valueAndValueValidationCriteria.dataType = this._vm.$dataTypes.integer;
        valueAndValueValidationCriteria.permissibleNumberOfDecimalPlaces = null;
        valueAndValueValidationCriteria.permissibleValueRange.minimum.value = state.unintendedCarMovementCheck_Inputs_ValueListAndSelectionsAndPermissibleValueRanges.unintendedCarMovementCheck_PermissibleValueRanges.deadTime.minimum.value; //0;
        valueAndValueValidationCriteria.permissibleValueRange.minimum.operator = state.unintendedCarMovementCheck_Inputs_ValueListAndSelectionsAndPermissibleValueRanges.unintendedCarMovementCheck_PermissibleValueRanges.deadTime.minimum.operator; //this.$operators.greaterThan;
        valueAndValueValidationCriteria.permissibleValueRange.maximum.value = state.unintendedCarMovementCheck_Inputs_ValueListAndSelectionsAndPermissibleValueRanges.unintendedCarMovementCheck_PermissibleValueRanges.deadTime.maximum.value;
        valueAndValueValidationCriteria.permissibleValueRange.maximum.operator = state.unintendedCarMovementCheck_Inputs_ValueListAndSelectionsAndPermissibleValueRanges.unintendedCarMovementCheck_PermissibleValueRanges.deadTime.maximum.operator;
        const deadTime_ValidationResult = this._vm.$checkIf_value_isValid(valueAndValueValidationCriteria);

        commit('change_deadTime_storeMutation', deadTime_ValidationResult)
        dispatch('checkIf_unintendedCarMovementCheck_Inputs_areValid_storeAction');
    },
    change_accelerationLimitedTo2Point5MetersPerSquareSecond_storeAction({ commit, dispatch }, value) {
        commit('change_accelerationLimitedTo2Point5MetersPerSquareSecond_storeMutation', value)
        dispatch('checkIf_unintendedCarMovementCheck_Inputs_areValid_storeAction');
    },
    change_calculatedWithShortCircuitMotorBraking_storeAction({ commit, dispatch }, value) {
        commit('change_calculatedWithShortCircuitMotorBraking_storeMutation', value)
        dispatch('checkIf_unintendedCarMovementCheck_Inputs_areValid_storeAction');
    },
    change_calculatedWithShortCircuitMotorBraking2_storeAction({ commit, dispatch }, value) {
        commit('change_calculatedWithShortCircuitMotorBraking_storeMutation', value)
        dispatch('controlTechnologies/get_inverter_Informations_storeAction');
    },
    change_speedLimit_storeAction({ commit, dispatch }, value) {
        const valueAndValueValidationCriteria = this._vm.$transmit_valueAndValueValidationCriteria;
        valueAndValueValidationCriteria.value = value;
        valueAndValueValidationCriteria.dataType = this._vm.$dataTypes.decimal;
        valueAndValueValidationCriteria.permissibleNumberOfDecimalPlaces = 3;
        valueAndValueValidationCriteria.permissibleValueRange.minimum.value = state.unintendedCarMovementCheck_Inputs_ValueListAndSelectionsAndPermissibleValueRanges.unintendedCarMovementCheck_PermissibleValueRanges.speedLimit.minimum.value; //0;
        valueAndValueValidationCriteria.permissibleValueRange.minimum.operator = state.unintendedCarMovementCheck_Inputs_ValueListAndSelectionsAndPermissibleValueRanges.unintendedCarMovementCheck_PermissibleValueRanges.speedLimit.minimum.operator;//this.$operators.greaterThan;
        valueAndValueValidationCriteria.permissibleValueRange.maximum.value = state.unintendedCarMovementCheck_Inputs_ValueListAndSelectionsAndPermissibleValueRanges.unintendedCarMovementCheck_PermissibleValueRanges.speedLimit.maximum.value;
        valueAndValueValidationCriteria.permissibleValueRange.maximum.operator = state.unintendedCarMovementCheck_Inputs_ValueListAndSelectionsAndPermissibleValueRanges.unintendedCarMovementCheck_PermissibleValueRanges.speedLimit.maximum.operator;
        const speedLimit_ValidationResult = this._vm.$checkIf_value_isValid(valueAndValueValidationCriteria);

        commit('change_speedLimit_storeMutation', speedLimit_ValidationResult)
        dispatch('checkIf_unintendedCarMovementCheck_Inputs_areValid_storeAction');
    },
    change_lowestShaftDoorHeight_storeAction({ commit, dispatch }, value) {
        const valueAndValueValidationCriteria = this._vm.$transmit_valueAndValueValidationCriteria;
        valueAndValueValidationCriteria.value = value;
        valueAndValueValidationCriteria.dataType = this._vm.$dataTypes.integer;
        valueAndValueValidationCriteria.permissibleNumberOfDecimalPlaces = null;
        valueAndValueValidationCriteria.permissibleValueRange.minimum.value = state.unintendedCarMovementCheck_Inputs_ValueListAndSelectionsAndPermissibleValueRanges.unintendedCarMovementCheck_PermissibleValueRanges.lowestShaftDoorHeight.minimum.value; //0;
        valueAndValueValidationCriteria.permissibleValueRange.minimum.operator = state.unintendedCarMovementCheck_Inputs_ValueListAndSelectionsAndPermissibleValueRanges.unintendedCarMovementCheck_PermissibleValueRanges.lowestShaftDoorHeight.minimum.operator; //this.$operators.greaterThan;
        valueAndValueValidationCriteria.permissibleValueRange.maximum.value = state.unintendedCarMovementCheck_Inputs_ValueListAndSelectionsAndPermissibleValueRanges.unintendedCarMovementCheck_PermissibleValueRanges.lowestShaftDoorHeight.maximum.value;
        valueAndValueValidationCriteria.permissibleValueRange.maximum.operator = state.unintendedCarMovementCheck_Inputs_ValueListAndSelectionsAndPermissibleValueRanges.unintendedCarMovementCheck_PermissibleValueRanges.lowestShaftDoorHeight.maximum.operator;
        const lowestShaftDoorHeight_ValidationResult = this._vm.$checkIf_value_isValid(valueAndValueValidationCriteria);

        commit('change_lowestShaftDoorHeight_storeMutation', lowestShaftDoorHeight_ValidationResult)
        dispatch('checkIf_unintendedCarMovementCheck_Inputs_areValid_storeAction');
    },
    change_carDoorHeight_storeAction({ commit, dispatch }, value) {
        const valueAndValueValidationCriteria = this._vm.$transmit_valueAndValueValidationCriteria;
        valueAndValueValidationCriteria.value = value;
        valueAndValueValidationCriteria.dataType = this._vm.$dataTypes.integer;
        valueAndValueValidationCriteria.permissibleNumberOfDecimalPlaces = null;
        valueAndValueValidationCriteria.permissibleValueRange.minimum.value = state.unintendedCarMovementCheck_Inputs_ValueListAndSelectionsAndPermissibleValueRanges.unintendedCarMovementCheck_PermissibleValueRanges.carDoorHeight.minimum.value; //0;
        valueAndValueValidationCriteria.permissibleValueRange.minimum.operator = state.unintendedCarMovementCheck_Inputs_ValueListAndSelectionsAndPermissibleValueRanges.unintendedCarMovementCheck_PermissibleValueRanges.carDoorHeight.minimum.operator; //this.$operators.greaterThan;
        valueAndValueValidationCriteria.permissibleValueRange.maximum.value = state.unintendedCarMovementCheck_Inputs_ValueListAndSelectionsAndPermissibleValueRanges.unintendedCarMovementCheck_PermissibleValueRanges.carDoorHeight.maximum.value;
        valueAndValueValidationCriteria.permissibleValueRange.maximum.operator = state.unintendedCarMovementCheck_Inputs_ValueListAndSelectionsAndPermissibleValueRanges.unintendedCarMovementCheck_PermissibleValueRanges.carDoorHeight.maximum.operator;
        const carDoorHeight_ValidationResult = this._vm.$checkIf_value_isValid(valueAndValueValidationCriteria);

        commit('change_carDoorHeight_storeMutation', carDoorHeight_ValidationResult)
        dispatch('checkIf_unintendedCarMovementCheck_Inputs_areValid_storeAction');
    },
    change_carDoorApronLength_storeAction({ commit, dispatch }, value) {
        const valueAndValueValidationCriteria = this._vm.$transmit_valueAndValueValidationCriteria;
        valueAndValueValidationCriteria.value = value;
        valueAndValueValidationCriteria.dataType = this._vm.$dataTypes.integer;
        valueAndValueValidationCriteria.permissibleNumberOfDecimalPlaces = null;
        valueAndValueValidationCriteria.permissibleValueRange.minimum.value = state.unintendedCarMovementCheck_Inputs_ValueListAndSelectionsAndPermissibleValueRanges.unintendedCarMovementCheck_PermissibleValueRanges.carDoorApronLength.minimum.value; //0;
        valueAndValueValidationCriteria.permissibleValueRange.minimum.operator = state.unintendedCarMovementCheck_Inputs_ValueListAndSelectionsAndPermissibleValueRanges.unintendedCarMovementCheck_PermissibleValueRanges.carDoorApronLength.minimum.operator; //this.$operators.greaterThan;
        valueAndValueValidationCriteria.permissibleValueRange.maximum.value = state.unintendedCarMovementCheck_Inputs_ValueListAndSelectionsAndPermissibleValueRanges.unintendedCarMovementCheck_PermissibleValueRanges.carDoorApronLength.maximum.value;
        valueAndValueValidationCriteria.permissibleValueRange.maximum.operator = state.unintendedCarMovementCheck_Inputs_ValueListAndSelectionsAndPermissibleValueRanges.unintendedCarMovementCheck_PermissibleValueRanges.carDoorApronLength.maximum.operator;
        const carDoorApronLength_ValidationResult = this._vm.$checkIf_value_isValid(valueAndValueValidationCriteria);

        commit('change_carDoorApronLength_storeMutation', carDoorApronLength_ValidationResult)
        dispatch('checkIf_unintendedCarMovementCheck_Inputs_areValid_storeAction');
    },
    checkIf_unintendedCarMovementCheck_Inputs_areValid_storeAction({ commit, dispatch }) {
        if ((state.doorZone.invalid == false) && (state.deadTime.invalid == false) && (state.lowestShaftDoorHeight.invalid == false) && (state.carDoorHeight.invalid == false) && (state.carDoorApronLength.invalid == false) && (state.speedLimit.invalid == false)) {
            commit('change_unintendedCarMovementCheck_Inputs_Invalid_storeMutation', false);
            dispatch('get_unintendedCarMovementCheck_Informations_storeAction', true)
        }
        else {
            commit('change_unintendedCarMovementCheck_Inputs_Invalid_storeMutation', true);

            commit('abort_httpRequests_get_unintendedCarMovementCheck_Informations_storeMutation');

            commit('change_carWeightInWorstLoadCase_storeMutation', {});
            commit('change_travelDirectionInWorstLoadCase_storeMutation', {});
            commit('change_unintendedCarMovementCheck_storeMutation', {});
        }
    },

    get_unintendedCarMovementCheck_Informations_storeAction({ rootState, commit, dispatch }, inputs_Transmitted) {
        commit('change_unintendedCarMovementCheck_Modal_Shown_storeMutation', true);
        dispatch('change_change_unintendedCarMovementCheck_Input_Disabled_storeAction')

        var unintendedCarMovementCheck = { calculatedWithShortCircuitMotorBraking: false };

        var inputs_UseresPreferences_DetectionDistance = false;
        var inputs_UseresPreferences_DeadTime = false;
        var inputs_UseresPreferences_SpeedDetector = false;
        var inputs_UseresPreferences_CalculatedWithShortCircuitMotorBraking = false;

        if ((rootState.user.userPreferences.hasOwnProperty('unintendedCarMovementCheck_CalculatedWithShortCircuitMotorBraking') == true) && (rootState.user.userPreferences.unintendedCarMovementCheck_CalculatedWithShortCircuitMotorBraking != null)) {
            unintendedCarMovementCheck.calculatedWithShortCircuitMotorBraking = rootState.user.userPreferences.unintendedCarMovementCheck_CalculatedWithShortCircuitMotorBraking;
            inputs_UseresPreferences_CalculatedWithShortCircuitMotorBraking = true;
        }
        if ((rootState.user.userPreferences.hasOwnProperty('unintendedCarMovementCheck_DoorZone') == true) && (rootState.user.userPreferences.unintendedCarMovementCheck_DoorZone != null)) {
            unintendedCarMovementCheck.doorZone = rootState.user.userPreferences.unintendedCarMovementCheck_DoorZone;
            inputs_UseresPreferences_DetectionDistance = true;
        }
        if ((rootState.user.userPreferences.hasOwnProperty('unintendedCarMovementCheck_DeadTime') == true) && (rootState.user.userPreferences.unintendedCarMovementCheck_DeadTime != null)) {
            unintendedCarMovementCheck.deadTime = rootState.user.userPreferences.unintendedCarMovementCheck_DeadTime;
            inputs_UseresPreferences_DeadTime = true;
        }
        if ((rootState.user.userPreferences.hasOwnProperty('unintendedCarMovementCheck_SpeedLimit') == true) && (rootState.user.userPreferences.unintendedCarMovementCheck_SpeedLimit != null)) {
            unintendedCarMovementCheck.speedLimit = rootState.user.userPreferences.unintendedCarMovementCheck_SpeedLimit;
            inputs_UseresPreferences_SpeedDetector = true;
        }
        if (inputs_Transmitted == true) {
            commit('change_loader_forLoadingResults_at_unintendedCarMovementCheck_Modal_Shown_storeMutation', true);

            unintendedCarMovementCheck.doorZone = state.doorZone.value;
            unintendedCarMovementCheck.deadTime = state.deadTime.value;
            unintendedCarMovementCheck.accelerationLimitedTo2Point5MetersPerSquareSecond = state.accelerationLimitedTo2Point5MetersPerSquareSecond;
            unintendedCarMovementCheck.speedLimit = state.speedLimit.value;
            unintendedCarMovementCheck.lowestShaftDoorHeight = state.lowestShaftDoorHeight.value;
            unintendedCarMovementCheck.carDoorHeight = state.carDoorHeight.value;
            unintendedCarMovementCheck.carDoorApronLength = state.carDoorApronLength.value;
            unintendedCarMovementCheck.calculatedWithShortCircuitMotorBraking = state.calculatedWithShortCircuitMotorBraking;
        }
        else if (inputs_Transmitted == false) {
            commit('change_loader_forLoadingAllContent_at_unintendedCarMovementCheck_Modal_Shown_storeMutation', true);
        }

        const data = {
            configurationOverviewsId: rootState.configurations.configurationOverviewsId,
            inverter: state.inverter_forWhich_unintendedCarMovement_isToBeMade.inverter,
            unintendedCarMovementCheck: unintendedCarMovementCheck,
            inputs_Transmitted: inputs_Transmitted,
            inputs_UseresPreferences_DetectionDistance: inputs_UseresPreferences_DetectionDistance,
            inputs_UseresPreferences_DeadTime: inputs_UseresPreferences_DeadTime,
            inputs_UseresPreferences_SpeedDetector: inputs_UseresPreferences_SpeedDetector,
            inputs_UseresPreferences_CalculatedWithShortCircuitMotorBraking: inputs_UseresPreferences_CalculatedWithShortCircuitMotorBraking,
        };

        commit('abort_httpRequests_get_unintendedCarMovementCheck_Informations_storeMutation');
        if (state.cancellationInformationsAboutHttpRequests_get_unintendedCarMovementCheck_Informations.signal.aborted == true) {
            commit('change_cancellationInformationsAboutHttpRequests_get_unintendedCarMovementCheck_Informations_toNewAbortController_storeMutation');
        }

        axios({
            method: 'post',
            url: '/api/controlTechnologies/get_unintendedCarMovementCheck_Informations',
            signal: state.cancellationInformationsAboutHttpRequests_get_unintendedCarMovementCheck_Informations.signal,
            data: {
                data: data,
            }
        })
            .then((response) => {
                this.dispatch('statusHandling/change_dataAndStatus_storeAction', response.data);

                if ((response.data.status.statusCode.substring(0, 1) == '2') || (response.data.status.statusCode.substring(0, 2) == '74') || (response.data.status.statusCode.substring(0, 2) == '75')) {
                    dispatch('toDos_afterResponseOf_get_unintendedCarMovementCheck_Informations_storeAction', response.data.data)
                }

                commit('change_loader_forLoadingResults_at_unintendedCarMovementCheck_Modal_Shown_storeMutation', false);
            })
            .catch(() => { })
            .then(() => {
                commit('change_loader_forLoadingAllContent_at_unintendedCarMovementCheck_Modal_Shown_storeMutation', false);
            })
    },


    async check_unintendedCarMovementCheck_Valid_storeAction({ rootState, commit, dispatch }, close_UCM_ModalShow_Continue) {
        var unintendedCarMovementCheck = { calculatedWithShortCircuitMotorBraking: false };
        unintendedCarMovementCheck.doorZone = state.doorZone.value;
        unintendedCarMovementCheck.deadTime = state.deadTime.value;
        unintendedCarMovementCheck.accelerationLimitedTo2Point5MetersPerSquareSecond = state.accelerationLimitedTo2Point5MetersPerSquareSecond;
        unintendedCarMovementCheck.speedLimit = state.speedLimit.value;
        unintendedCarMovementCheck.lowestShaftDoorHeight = state.lowestShaftDoorHeight.value;
        unintendedCarMovementCheck.carDoorHeight = state.carDoorHeight.value;
        unintendedCarMovementCheck.carDoorApronLength = state.carDoorApronLength.value;
        unintendedCarMovementCheck.calculatedWithShortCircuitMotorBraking = state.calculatedWithShortCircuitMotorBraking;

        const data = {
            configurationOverviewsId: rootState.configurations.configurationOverviewsId,
            inverter: state.inverter_forWhich_unintendedCarMovement_isToBeMade.inverter,
            unintendedCarMovementCheck: unintendedCarMovementCheck,
            close_UCM_ModalShow_Continue: close_UCM_ModalShow_Continue,
        };

        await axios({
            method: 'post',
            url: '/api/controlTechnologies/check_unintendedCarMovementCheck_Valid',
            data: {
                data: data,
            }
        })
            .then((response) => {
                this.dispatch('statusHandling/change_dataAndStatus_storeAction', response.data);
                if ((response.data.status.statusCode.substring(0, 1) == '2') || (response.data.status.statusCode.substring(0, 2) == '74') || (response.data.status.statusCode.substring(0, 2) == '75')) {
                    dispatch('toDos_afterResponseOf_check_unintendedCarMovementCheck_Valid_storeAction', response.data.data)
                }
            })
            .catch(() => { })
            .then(() => {
            })
    },

    toDos_afterResponseOf_get_unintendedCarMovementCheck_Informations_storeAction({ commit }, value) {
        commit('change_carWeightInWorstLoadCase_storeMutation', value.carWeightInWorstLoadCase);
        commit('change_travelDirectionInWorstLoadCase_storeMutation', value.travelDirectionInWorstLoadCase);
        commit('change_unintendedCarMovementCheck_storeMutation', value.unintendedCarMovementCheck);
        commit('change_informationPermissibleDistance_storeMutation', value.informationPermissibleDistance);

        if (value.inputs_Transmitted == false) {
            commit('change_doorZone_storeMutation', { value: value.unintendedCarMovementCheck.doorZone, invalid: false });
            commit('change_deadTime_storeMutation', { value: value.unintendedCarMovementCheck.deadTime, invalid: false });
            commit('change_accelerationLimitedTo2Point5MetersPerSquareSecond_storeMutation', value.unintendedCarMovementCheck.accelerationLimitedTo2Point5MetersPerSquareSecond);
            commit('change_speedLimit_storeMutation', { value: value.unintendedCarMovementCheck.speedLimit, invalid: false });
            commit('change_lowestShaftDoorHeight_storeMutation', { value: value.unintendedCarMovementCheck.lowestShaftDoorHeight, invalid: false });
            commit('change_carDoorHeight_storeMutation', { value: value.unintendedCarMovementCheck.carDoorHeight, invalid: false });
            commit('change_carDoorApronLength_storeMutation', { value: value.unintendedCarMovementCheck.carDoorApronLength, invalid: false });
            commit('change_unintendedCarMovementCheck_Inputs_Invalid_storeMutation', false)
            commit('change_calculatedWithShortCircuitMotorBraking_storeMutation', value.unintendedCarMovementCheck.calculatedWithShortCircuitMotorBraking);
        }
    },

    toDos_afterResponseOf_check_unintendedCarMovementCheck_Valid_storeAction({ commit, dispatch }) {
        const firstIndex_whichMeetConditions = state.inverter_Data.inverter_Selection.findIndex(i => (i.inverter.blockNumber == state.inverter_forWhich_unintendedCarMovement_isToBeMade.inverter.blockNumber))
        dispatch('change_unintendedCarMovementCheck_Modal_Shown_storeAction', false);
        if (firstIndex_whichMeetConditions > -1) {
            const inverter = state.inverter_Data.inverter_Selection[firstIndex_whichMeetConditions];
            dispatch('checkIf_changesAt_inverter_changes_configurationFlow_storeAction', inverter);
        }
    },

    change_unintendedCarMovementCheck_PresentationOfResults_storeAction({ commit }, value) {
        commit('change_unintendedCarMovementCheck_PresentationOfResults_storeMutation', value)
    },

    add_unintendedCarMovementCheck_Object_at_inverter_Selection_storeAction({ dispatch }) {
        const firstIndex_whichMeetCondition = state.inverter_Data.inverter_Selection.findIndex(i => i.inverter.blockNumber == state.inverter_forWhich_unintendedCarMovement_isToBeMade.inverter.blockNumber);
        if (firstIndex_whichMeetCondition > -1) {
            dispatch('add_unintendedCarMovementCheck_Object_atElementOf_inverter_Selection_storeAction', { inverter_index: firstIndex_whichMeetCondition, unintendedCarMovementCheck: state.unintendedCarMovementCheck })
        }
        else {
            // error
        }
    },
    add_unintendedCarMovementCheck_Object_atElementOf_inverter_Selection_storeAction({ commit }, value) {
        commit('add_unintendedCarMovementCheck_Object_at_inverter_Selection_storeMutation', value)
    },
    change_unintendedCarMovementCheck_Object_at_inverter_Selection_storeAction({ commit }) {
        const firstIndex_whichMeetCondition = state.inverter_Data.inverter_Selection.findIndex(i => i.inverter.blockNumber == state.inverter_forWhich_unintendedCarMovement_isToBeMade.inverter.blockNumber);
        if (firstIndex_whichMeetCondition > -1) {
            commit('change_unintendedCarMovementCheck_Object_at_inverter_Selection_storeMutation', { inverter_index: firstIndex_whichMeetCondition, unintendedCarMovementCheck: state.unintendedCarMovementCheck })
        }
        else {
            // error
        }
    },
    delete_unintendedCarMovementCheck_Object_at_inverter_Selection_storeAction({ commit }) {
        const firstIndex_whichMeetCondition = state.inverter_Data.inverter_Selection.findIndex(i => i.inverter.blockNumber == state.inverter_forWhich_unintendedCarMovement_isToBeMade.inverter.blockNumber);
        if (firstIndex_whichMeetCondition > -1) {
            commit('delete_unintendedCarMovementCheck_Object_at_inverter_Selection_storeMutation', firstIndex_whichMeetCondition)
        }
        else {
            // error
        }
    },

    add_unintendedCarMovementCheck_Object_at_thirdPartyInverter_storeAction({ commit }, value) {
        commit('add_unintendedCarMovementCheck_Object_at_thirdPartyInverter_storeMutation', value)
    },
    change_unintendedCarMovementCheck_Object_at_thirdPartyInverter_storeAction({ commit }) {
        commit('change_unintendedCarMovementCheck_Object_at_thirdPartyInverter_storeMutation', state.unintendedCarMovementCheck)
    },
    delete_unintendedCarMovementCheck_Object_at_thirdPartyInverter_storeAction({ commit }) {
        commit('delete_unintendedCarMovementCheck_Object_at_thirdPartyInverter_storeMutation')
    },

    async insert_databaseRecord_at_unintendedCarMovementChecks_storeAction({ rootState }, value) {
        const data = {
            configurationOverviewsId: rootState.configurations.configurationOverviewsId,
            configurationSteps: rootState.configurations.configurationSteps,
            unintendedCarMovementCheck: value,
        }

        await axios({
            method: 'post',
            url: '/api/controlTechnologies/insert_databaseRecord_at_unintendedCarMovementChecks',
            data: {
                data: data,
            }
        })
            .then((response) => {
                this.dispatch('statusHandling/change_dataAndStatus_storeAction', response.data);

                if (response.data.status.statusCode.substring(0, 1) == '2') {
                    this.dispatch('configurations/change_configurationSteps_storeAction', response.data.data.configurationSteps);
                }
            })
            .catch(() => { })
    },

    async openUcmFile_storeAction({ dispatch }, value) {

        await axios({
            method: 'post',
            url: '/api/controlTechnologies/openUcmFile/' + value,
        })
            .then((response) => {
                //this.dispatch('statusHandling/change_dataAndStatus_storeAction', response.data);
                if (response.data != null || response.data != undefined || response.data != '') {
                    //this.dispatch('configurations/change_configurationSteps_storeAction', response.data.data.configurationSteps);

                    var newWindow = window.open("", "_blank");
                    newWindow.document.write(response.data);
                    newWindow.document.title = "ZAlift";
                }
            })
            .catch(() => { })
    },

    // comparisonOfPossibilitiesToConvertRegenerativeEnergy
    change_comparisonOfPossibilitiesToConvertRegenerativeEnergy_toDefault_storeAction({ commit }) {
        commit('abort_httpRequests_get_comparisonOfPossibilitiesToConvertRegenerativeEnergy_Informations_storeMutation');
        commit('change_loader_forLoadingAllContent_at_comparisonOfPossibilitiesToConvertRegenerativeEnergy_Modal_Shown_storeMutation', false);
        commit('change_loader_forLoadingResults_at_comparisonOfPossibilitiesToConvertRegenerativeEnergy_Modal_Shown_storeMutation', false);

        commit('change_comparisonOfPossibilitiesToConvertRegenerativeEnergy_storeMutation', {});
    },

    async hide_comparisonOfPossibilitiesToConvertRegenerativeEnergy_Modal_storeAction({ rootState, commit, dispatch }, value) {
        if (state.energyEfficiencyRating_Inputs_Invalid == false) {
            var inputs_Changed = false;
            if ((state.standby_PowerConsumptionOfCarLightAndElevatorControlSystem.value != state.energyEfficiencyRating.standbyPowerConsumptionOfCarLightAndElevatorControlSystem)
                || (state.travel_PowerConsumptionOfCarLightAndElevatorControlSystem.value != state.energyEfficiencyRating.travelPowerConsumptionOfCarLightAndElevatorControlSystem)
                || (state.travel_LoadFactor_Calculated != state.energyEfficiencyRating.travelLoadFactorCalculated)
                || ((state.travel_LoadFactor_Calculated == false) && (state.travel_LoadFactor.value != state.energyEfficiencyRating.travelLoadFactor))
                || (state.electricityCosts.value != state.energyEfficiencyRating.electricityCostsPerKilowattHour)
            ) {
                inputs_Changed = true;
            }

            const indexOf_firstElementOf_powerRecuperationUnit_Selection_where_Selected_AtComparisonOfPossibilitiesToConvertRegenerativeEnergy_isTrue = state.powerRecuperationUnit_Data.powerRecuperationUnit_Selection.findIndex(i => i.selected_AtComparisonOfPossibilitiesToConvertRegenerativeEnergy == true);
            const indexOf_firstElementOf_powerRecuperationUnit_Selection_where_Selected_isTrue = state.powerRecuperationUnit_Data.powerRecuperationUnit_Selection.findIndex(i => i.selected == true);

            if (value == 'brake resistor') {
                if (rootState.configurations.configurationSteps.brakeResistor.storedInDatabase == true) {
                    if (inputs_Changed == true) {
                        dispatch('change_energyEfficiencyRating_storeAction', { elevatorMotor_Machine: state.comparisonOfPossibilitiesToConvertRegenerativeEnergy.elevatorMotor_Machine, inverter: state.comparisonOfPossibilitiesToConvertRegenerativeEnergy.inverter, energyEfficiencyRating: state.comparisonOfPossibilitiesToConvertRegenerativeEnergy.energyEfficiencyRating_BrakeResistor })
                        dispatch('insertOrUpdate_databaseRecord_at_energyEfficiencyRatings_storeAction', { elevatorMotor_Machine: state.comparisonOfPossibilitiesToConvertRegenerativeEnergy.elevatorMotor_Machine, inverter: state.comparisonOfPossibilitiesToConvertRegenerativeEnergy.inverter, energyEfficiencyRating: state.comparisonOfPossibilitiesToConvertRegenerativeEnergy.energyEfficiencyRating_BrakeResistor })
                    }
                }
                else {
                    dispatch('change_productTypeForConversionOfRegenerativeEnergy_storeAction', 'brake resistor')
                    dispatch('change_energyEfficiencyRating_storeAction', { elevatorMotor_Machine: state.comparisonOfPossibilitiesToConvertRegenerativeEnergy.elevatorMotor_Machine, inverter: state.comparisonOfPossibilitiesToConvertRegenerativeEnergy.inverter, energyEfficiencyRating: state.comparisonOfPossibilitiesToConvertRegenerativeEnergy.energyEfficiencyRating_BrakeResistor })

                    commit('change_Selected_atEveryElementOf_powerRecuperationUnit_Selection_toFalse_storeMutation');
                    commit('change_Selected_at_brakeResistor_Selection_storeMutation', 0);

                    await this.dispatch('configurations/selectAndInsert_or_updateAndOrDelete_databaseRecords_storeAction', { changesAt: this._vm.$configurationSteps.brakeResistor, brakeResistor: state.brakeResistor_Data.brakeResistor_Selection[0].brakeResistor, elevatorMotor_Machine: state.comparisonOfPossibilitiesToConvertRegenerativeEnergy.elevatorMotor_Machine, inverter: state.comparisonOfPossibilitiesToConvertRegenerativeEnergy.inverter, energyEfficiencyRating: state.comparisonOfPossibilitiesToConvertRegenerativeEnergy.energyEfficiencyRating_BrakeResistor });
                    this.dispatch('configurations/check_toDos_at_controlTechnologies_storeAction')
                }
            }
            else if (value == 'power recuperation unit') {
                var powerRecuperationUnit_SetOrChanged = false;
                if (rootState.configurations.configurationSteps.powerRecuperationUnit.storedInDatabase == true) {
                    if (indexOf_firstElementOf_powerRecuperationUnit_Selection_where_Selected_isTrue == indexOf_firstElementOf_powerRecuperationUnit_Selection_where_Selected_AtComparisonOfPossibilitiesToConvertRegenerativeEnergy_isTrue) {
                        if (inputs_Changed == true) {
                            dispatch('change_energyEfficiencyRating_storeAction', { elevatorMotor_Machine: state.comparisonOfPossibilitiesToConvertRegenerativeEnergy.elevatorMotor_Machine, inverter: state.comparisonOfPossibilitiesToConvertRegenerativeEnergy.inverter, energyEfficiencyRating: state.comparisonOfPossibilitiesToConvertRegenerativeEnergy.energyEfficiencyRating_PowerRecuperationUnit })
                            dispatch('insertOrUpdate_databaseRecord_at_energyEfficiencyRatings_storeAction', { elevatorMotor_Machine: state.comparisonOfPossibilitiesToConvertRegenerativeEnergy.elevatorMotor_Machine, inverter: state.comparisonOfPossibilitiesToConvertRegenerativeEnergy.inverter, energyEfficiencyRating: state.comparisonOfPossibilitiesToConvertRegenerativeEnergy.energyEfficiencyRating_PowerRecuperationUnit })
                        }
                    }
                    else {
                        powerRecuperationUnit_SetOrChanged = true;
                    }
                }
                else {
                    powerRecuperationUnit_SetOrChanged = true;
                    dispatch('change_productTypeForConversionOfRegenerativeEnergy_storeAction', 'power recuperation unit')
                }


                if (powerRecuperationUnit_SetOrChanged == true) {
                    dispatch('change_energyEfficiencyRating_storeAction', { elevatorMotor_Machine: state.comparisonOfPossibilitiesToConvertRegenerativeEnergy.elevatorMotor_Machine, inverter: state.comparisonOfPossibilitiesToConvertRegenerativeEnergy.inverter, energyEfficiencyRating: state.comparisonOfPossibilitiesToConvertRegenerativeEnergy.energyEfficiencyRating_PowerRecuperationUnit })

                    commit('change_Selected_atEveryElementOf_brakeResistor_Selection_toFalse_storeMutation');
                    commit('change_Selected_at_powerRecuperationUnit_Selection_storeMutation', indexOf_firstElementOf_powerRecuperationUnit_Selection_where_Selected_AtComparisonOfPossibilitiesToConvertRegenerativeEnergy_isTrue);

                    //if (state.powerRecuperationUnit_Data.powerRecuperationUnit_Selection[indexOf_firstElementOf_powerRecuperationUnit_Selection_where_Selected_AtComparisonOfPossibilitiesToConvertRegenerativeEnergy_isTrue].powerRecuperationUnit.typeDesignation.includes('ZArec')) {
                    //    commit('change_full_powerRecuperationUnit_Selection_Shown_storeMutation', false)
                    //}
                    //else {
                    //    commit('change_full_powerRecuperationUnit_Selection_Shown_storeMutation', true)
                    //}

                    await this.dispatch('configurations/selectAndInsert_or_updateAndOrDelete_databaseRecords_storeAction', { changesAt: this._vm.$configurationSteps.powerRecuperationUnit, powerRecuperationUnit: state.powerRecuperationUnit_Data.powerRecuperationUnit_Selection[indexOf_firstElementOf_powerRecuperationUnit_Selection_where_Selected_AtComparisonOfPossibilitiesToConvertRegenerativeEnergy_isTrue].powerRecuperationUnit, elevatorMotor_Machine: state.comparisonOfPossibilitiesToConvertRegenerativeEnergy.elevatorMotor_Machine, inverter: state.comparisonOfPossibilitiesToConvertRegenerativeEnergy.inverter, energyEfficiencyRating: state.comparisonOfPossibilitiesToConvertRegenerativeEnergy.energyEfficiencyRating_PowerRecuperationUnit });
                    this.dispatch('configurations/check_toDos_at_controlTechnologies_storeAction')
                }
            }
            else if (value == 'close') {
                if (inputs_Changed == true) {
                    if (rootState.configurations.configurationSteps.brakeResistor.storedInDatabase == true) {
                        dispatch('change_energyEfficiencyRating_storeAction', { elevatorMotor_Machine: state.comparisonOfPossibilitiesToConvertRegenerativeEnergy.elevatorMotor_Machine, inverter: state.comparisonOfPossibilitiesToConvertRegenerativeEnergy.inverter, energyEfficiencyRating: state.comparisonOfPossibilitiesToConvertRegenerativeEnergy.energyEfficiencyRating_BrakeResistor })
                        dispatch('insertOrUpdate_databaseRecord_at_energyEfficiencyRatings_storeAction', { elevatorMotor_Machine: state.comparisonOfPossibilitiesToConvertRegenerativeEnergy.elevatorMotor_Machine, inverter: state.comparisonOfPossibilitiesToConvertRegenerativeEnergy.inverter, energyEfficiencyRating: state.comparisonOfPossibilitiesToConvertRegenerativeEnergy.energyEfficiencyRating_BrakeResistor })
                    }

                    if (rootState.configurations.configurationSteps.powerRecuperationUnit.storedInDatabase == true) {
                        if (indexOf_firstElementOf_powerRecuperationUnit_Selection_where_Selected_isTrue != indexOf_firstElementOf_powerRecuperationUnit_Selection_where_Selected_AtComparisonOfPossibilitiesToConvertRegenerativeEnergy_isTrue) {
                            dispatch('get_energyEfficiencyRating_Informations_storeAction', { standbyPowerConsumptionOfCarLightAndElevatorControlSystem_Transmitted: true, electricityCostsPerKilowattHour_Transmitted: true, all_Inputs_Transmitted: true })
                        }
                        else {
                            dispatch('change_energyEfficiencyRating_storeAction', { elevatorMotor_Machine: state.comparisonOfPossibilitiesToConvertRegenerativeEnergy.elevatorMotor_Machine, inverter: state.comparisonOfPossibilitiesToConvertRegenerativeEnergy.inverter, energyEfficiencyRating: state.comparisonOfPossibilitiesToConvertRegenerativeEnergy.energyEfficiencyRating_PowerRecuperationUnit })
                            dispatch('insertOrUpdate_databaseRecord_at_energyEfficiencyRatings_storeAction', { elevatorMotor_Machine: state.comparisonOfPossibilitiesToConvertRegenerativeEnergy.elevatorMotor_Machine, inverter: state.comparisonOfPossibilitiesToConvertRegenerativeEnergy.inverter, energyEfficiencyRating: state.comparisonOfPossibilitiesToConvertRegenerativeEnergy.energyEfficiencyRating_PowerRecuperationUnit })
                        }
                    }
                }
            }
        }

        dispatch('change_comparisonOfPossibilitiesToConvertRegenerativeEnergy_Modal_Shown_storeAction', false)
    },
    change_comparisonOfPossibilitiesToConvertRegenerativeEnergy_Modal_Shown_storeAction({ commit, dispatch }, value) {
        commit('change_comparisonOfPossibilitiesToConvertRegenerativeEnergy_Modal_Shown_storeMutation', value)
        if (value == true) {
            dispatch('get_comparisonOfPossibilitiesToConvertRegenerativeEnergy_Informations_storeAction')
        }
        else if (value == false) {
            dispatch('change_comparisonOfPossibilitiesToConvertRegenerativeEnergy_toDefault_storeAction');
        }
    },

    get_comparisonOfPossibilitiesToConvertRegenerativeEnergy_Informations_storeAction({ rootState, commit, dispatch }) {
        const selected_comparisonOfPossibilitiesToConvertRegenerativeEnergy_powerRecuperationUnit = state.powerRecuperationUnit_Data.powerRecuperationUnit_Selection.find(i => i.selected_AtComparisonOfPossibilitiesToConvertRegenerativeEnergy == true);

        const energyEfficiencyRating = {
            standbyPowerConsumptionOfCarLightAndElevatorControlSystem: state.standby_PowerConsumptionOfCarLightAndElevatorControlSystem.value,
            travelPowerConsumptionOfCarLightAndElevatorControlSystem: state.travel_PowerConsumptionOfCarLightAndElevatorControlSystem.value,
            travelLoadFactorCalculated: state.travel_LoadFactor_Calculated,
            travelLoadFactor: state.travel_LoadFactor.value,
            electricityCostsPerKilowattHour: state.electricityCosts.value,
        }

        const data = {
            configurationOverviewsId: rootState.configurations.configurationOverviewsId,
            energyEfficiencyRating: energyEfficiencyRating,
            powerRecuperationUnit: { typeDesignation: undefined },
            powerRecuperationUnit_TypeDesignation_Transmitted: false,
            printInDocument: state.printInDocument,
        }

        if (selected_comparisonOfPossibilitiesToConvertRegenerativeEnergy_powerRecuperationUnit != null) {
            data.powerRecuperationUnit.typeDesignation = selected_comparisonOfPossibilitiesToConvertRegenerativeEnergy_powerRecuperationUnit.powerRecuperationUnit.typeDesignation;
            data.powerRecuperationUnit_TypeDesignation_Transmitted = true;
            commit('change_loader_forLoadingResults_at_comparisonOfPossibilitiesToConvertRegenerativeEnergy_Modal_Shown_storeMutation', true)
        }
        else {
            commit('change_loader_forLoadingAllContent_at_comparisonOfPossibilitiesToConvertRegenerativeEnergy_Modal_Shown_storeMutation', true)
        }

        commit('abort_httpRequests_get_comparisonOfPossibilitiesToConvertRegenerativeEnergy_Informations_storeMutation');
        if (state.cancellationInformationsAboutHttpRequests_get_comparisonOfPossibilitiesToConvertRegenerativeEnergy_Informations.signal.aborted == true) {
            commit('change_cancellationInformationsAboutHttpRequests_get_comparisonOfPossibilitiesToConvertRegenerativeEnergy_Informations_toNewAbortController_storeMutation')
        }

        axios({
            method: 'post',
            url: '/api/controlTechnologies/get_comparisonOfPossibilitiesToConvertRegenerativeEnergy_Informations',
            signal: state.cancellationInformationsAboutHttpRequests_get_comparisonOfPossibilitiesToConvertRegenerativeEnergy_Informations.signal,
            data: {
                data: data,
            }
        })
            .then((response) => {
                this.dispatch('statusHandling/change_dataAndStatus_storeAction', response.data);

                if ((response.data.status.statusCode.substring(0, 1) == '2') || (response.data.status.statusCode.substring(0, 2) == '74') || (response.data.status.statusCode.substring(0, 2) == '75')) {
                    dispatch('toDos_afterResponseOf_get_comparisonOfPossibilitiesToConvertRegenerativeEnergy_Informations_storeAction', response.data.data)
                }
                commit('change_loader_forLoadingResults_at_comparisonOfPossibilitiesToConvertRegenerativeEnergy_Modal_Shown_storeMutation', false)
            })
            .catch(() => { })
            .then(() => {
                commit('change_loader_forLoadingAllContent_at_comparisonOfPossibilitiesToConvertRegenerativeEnergy_Modal_Shown_storeMutation', false)
            })
    },
    toDos_afterResponseOf_get_comparisonOfPossibilitiesToConvertRegenerativeEnergy_Informations_storeAction({ commit }, value) {
        if (value.powerRecuperationUnit_TypeDesignation_Transmitted == false) {
            const firstIndex_whichMeetCondition = state.powerRecuperationUnit_Data.powerRecuperationUnit_Selection.findIndex(i => i.powerRecuperationUnit.typeDesignation == value.powerRecuperationUnit.typeDesignation);
            if (firstIndex_whichMeetCondition > -1) {
                commit('change_Selected_AtComparisonOfPossibilitiesToConvertRegenerativeEnergy_at_powerRecuperationUnit_Selection_storeMutation', firstIndex_whichMeetCondition)
            }
            else {
                // error
            }
        }
        commit('change_comparisonOfPossibilitiesToConvertRegenerativeEnergy_storeMutation', value)
    },


    // productTypeForConversionOfRegenerativeEnergy
    async checkIf_userPreference_for_productTypeForConversionOfRegenerativeEnergy_Set_storeAction({ rootState, dispatch }) {
        if (rootState.user.userPreferences_Loaded == false) {
            await this.dispatch('user/get_userPreference_Informations_storeAction')
        }


        if ((rootState.user.userPreferences.hasOwnProperty('productTypeForConversionOfRegenerativeEnergy') == true) && (rootState.user.userPreferences.productTypeForConversionOfRegenerativeEnergy != null) && ((rootState.user.userPreferences.productTypeForConversionOfRegenerativeEnergy == 'brake resistor') || (rootState.user.userPreferences.productTypeForConversionOfRegenerativeEnergy == 'power recuperation unit'))) {
            dispatch('change_productTypeForConversionOfRegenerativeEnergy_storeAction', rootState.user.userPreferences.productTypeForConversionOfRegenerativeEnergy)
        }
        if (state.productTypeForConversionOfRegenerativeEnergy == 'brake resistor') {
            dispatch('change_Selected_atElementOf_brakeResistor_Selection_storeAction', 0);
            await dispatch('insert_databaseRecord_at_brakeResistors_storeAction', state.brakeResistor_Data.brakeResistor_Selection[0].brakeResistor);
        }
        else if (state.productTypeForConversionOfRegenerativeEnergy == 'power recuperation unit') {
            dispatch('change_Selected_atElementOf_powerRecuperationUnit_Selection_storeAction', 0);
            dispatch('change_Selected_AtComparisonOfPossibilitiesToConvertRegenerativeEnergy_at_powerRecuperationUnit_Selection_storeAction', 0);
            await dispatch('insert_databaseRecord_at_powerRecuperationUnits_storeAction', state.powerRecuperationUnit_Data.powerRecuperationUnit_Selection[0].powerRecuperationUnit);
        }
        else {
            dispatch('change_productTypeForConversionOfRegenerativeEnergy_storeAction', 'brake resistor')
            dispatch('change_Selected_atElementOf_brakeResistor_Selection_storeAction', 0);
            await dispatch('insert_databaseRecord_at_brakeResistors_storeAction', state.brakeResistor_Data.brakeResistor_Selection[0].brakeResistor);
        }
    },
    change_productTypeForConversionOfRegenerativeEnergy_storeAction({ commit }, value) {
        commit('change_productTypeForConversionOfRegenerativeEnergy_storeMutation', value)
    },


    // brakeResistor
    change_brakeResistor_toDefault_storeAction({ commit }) {
        commit('change_brakeResistor_Data_storeMutation', {});
        commit('change_full_brakeResistor_Selection_Shown_storeMutation', false);
        commit('change_productTypeForConversionOfRegenerativeEnergy_storeMutation', 'brake resistor');
    },

    async get_brakeResistor_Informations_storeAction({ dispatch, rootState }) {
        const data = {
            configurationOverviewsId: rootState.configurations.configurationOverviewsId,
            configurationSteps: rootState.configurations.configurationSteps,
        }

        await axios({
            method: 'post',
            url: '/api/controlTechnologies/get_brakeResistor_Informations',
            data: {
                data: data,
            }
        })
            .then((response) => {
                this.dispatch('statusHandling/change_dataAndStatus_storeAction', response.data);

                if ((response.data.status.statusCode.substring(0, 1) == '2') || (response.data.status.statusCode.substring(0, 2) == '74') || (response.data.status.statusCode.substring(0, 2) == '75')) {
                    dispatch('toDos_afterResponseOf_get_brakeResistor_Informations_storeAction', response.data.data)
                }
            })
            .catch(() => { })
    },
    toDos_afterResponseOf_get_brakeResistor_Informations_storeAction({ dispatch }, value) {
        dispatch('change_brakeResistor_Data_storeAction', value.brakeResistor_Data);

        value.configurationSteps.brakeResistor.loaded = true;
        this.dispatch('configurations/change_configurationSteps_storeAction', value.configurationSteps);
    },

    change_full_brakeResistor_Selection_Shown_storeAction({ commit }, value) {
        commit('change_full_brakeResistor_Selection_Shown_storeMutation', value)
    },
    change_brakeResistor_Data_storeAction({ commit }, value) {
        commit('change_brakeResistor_Data_storeMutation', value)
    },

    async checkIf_changesAt_brakeResistor_changes_configurationFlow_storeAction({ rootState, commit, dispatch }, value) {
        commit('change_Selected_atEveryElementOf_powerRecuperationUnit_Selection_toFalse_storeMutation');
        dispatch('change_Selected_atElementOf_brakeResistor_Selection_storeAction', value);

        if ((rootState.configurations.configurationSteps.brakeResistor.storedInDatabase == true) || (rootState.configurations.configurationSteps.powerRecuperationUnit.storedInDatabase == true)) {
            await this.dispatch('configurations/selectAndInsert_or_updateAndOrDelete_databaseRecords_storeAction', { changesAt: this._vm.$configurationSteps.brakeResistor, brakeResistor: state.brakeResistor_Data.brakeResistor_Selection[value].brakeResistor });
        }
        else {
            await dispatch('insert_databaseRecord_at_brakeResistors_storeAction', state.brakeResistor_Data.brakeResistor_Selection[value].brakeResistor);
        }

        this.dispatch('configurations/check_toDos_at_controlTechnologies_storeAction')
    },
    change_Selected_atElementOf_brakeResistor_Selection_storeAction({ commit }, value) {
        commit('change_Selected_at_brakeResistor_Selection_storeMutation', value);
    },
    async insert_databaseRecord_at_brakeResistors_storeAction({ rootState }, value) {
        const data = {
            configurationOverviewsId: rootState.configurations.configurationOverviewsId,
            configurationSteps: rootState.configurations.configurationSteps,
            brakeResistor: value,
        }

        await axios({
            method: 'post',
            url: '/api/controlTechnologies/insert_databaseRecord_at_brakeResistors',
            data: {
                data: data,
            }
        })
            .then((response) => {
                this.dispatch('statusHandling/change_dataAndStatus_storeAction', response.data);

                if (response.data.status.statusCode.substring(0, 1) == '2') {
                    this.dispatch('configurations/change_configurationSteps_storeAction', response.data.data.configurationSteps);
                }
            })
            .catch(() => { })
    },


    // powerRecuperationUnit
    change_powerRecuperationUnit_toDefault_storeAction({ commit }) {
        commit('change_powerRecuperationUnit_Data_storeMutation', {});
        commit('change_full_powerRecuperationUnit_Selection_Shown_storeMutation', false);
        commit('change_productTypeForConversionOfRegenerativeEnergy_storeMutation', 'brake resistor');
    },

    async get_powerRecuperationUnit_Informations_storeAction({ dispatch, rootState }) {
        const data = {
            configurationOverviewsId: rootState.configurations.configurationOverviewsId,
            configurationSteps: rootState.configurations.configurationSteps,
        }

        await axios({
            method: 'post',
            url: '/api/controlTechnologies/get_powerRecuperationUnit_Informations',
            data: {
                data: data,
            }
        })
            .then((response) => {
                this.dispatch('statusHandling/change_dataAndStatus_storeAction', response.data);

                if ((response.data.status.statusCode.substring(0, 1) == '2') || (response.data.status.statusCode.substring(0, 2) == '74') || (response.data.status.statusCode.substring(0, 2) == '75')) {
                    dispatch('toDos_afterResponseOf_get_powerRecuperationUnit_Informations_storeAction', response.data.data);
                }
            })
            .catch(() => { })
    },
    toDos_afterResponseOf_get_powerRecuperationUnit_Informations_storeAction({ commit, dispatch }, value) {
        dispatch('change_powerRecuperationUnit_Data_storeAction', value.powerRecuperationUnit_Data);

        //if (value.configurationSteps.powerRecuperationUnit.available == true) {
        //    if (((value.powerRecuperationUnit_Data.powerRecuperationUnitsOfTheSeriesZArec_Available == true) && (value.powerRecuperationUnit_Data.thirdPartyPowerRecuperationUnits_Available == false)) || ((value.powerRecuperationUnit_Data.powerRecuperationUnitsOfTheSeriesZArec_Available == false) && (value.powerRecuperationUnit_Data.thirdPartyPowerRecuperationUnits_Available == true))) {
        //        commit('change_full_powerRecuperationUnit_Selection_Shown_storeMutation', true);
        //    }
        //    else {
        //        commit('change_full_powerRecuperationUnit_Selection_Shown_storeMutation', false);
        //    }
        //}

        value.configurationSteps.powerRecuperationUnit.loaded = true;
        this.dispatch('configurations/change_configurationSteps_storeAction', value.configurationSteps);
    },

    change_powerRecuperationUnit_Data_storeAction({ commit }, value) {
        commit('change_powerRecuperationUnit_Data_storeMutation', value);
    },
    change_full_powerRecuperationUnit_Selection_Shown_storeAction({ commit }, value) {
        commit('change_full_powerRecuperationUnit_Selection_Shown_storeMutation', value)
    },

    async checkIf_changesAt_powerRecuperationUnit_changes_configurationFlow_storeAction({ rootState, commit, dispatch }, value) {
        commit('change_Selected_atEveryElementOf_brakeResistor_Selection_toFalse_storeMutation');
        dispatch('change_Selected_atElementOf_powerRecuperationUnit_Selection_storeAction', value)

        if ((rootState.configurations.configurationSteps.brakeResistor.storedInDatabase == true) || (rootState.configurations.configurationSteps.powerRecuperationUnit.storedInDatabase == true)) {
            await this.dispatch('configurations/selectAndInsert_or_updateAndOrDelete_databaseRecords_storeAction', { changesAt: this._vm.$configurationSteps.powerRecuperationUnit, powerRecuperationUnit: state.powerRecuperationUnit_Data.powerRecuperationUnit_Selection[value].powerRecuperationUnit });
        }
        else {
            await dispatch('insert_databaseRecord_at_powerRecuperationUnits_storeAction', state.powerRecuperationUnit_Data.powerRecuperationUnit_Selection[value].powerRecuperationUnit);
        }

        this.dispatch('configurations/check_toDos_at_controlTechnologies_storeAction')

    },
    change_Selected_atElementOf_powerRecuperationUnit_Selection_storeAction({ commit }, value) {
        commit('change_Selected_at_powerRecuperationUnit_Selection_storeMutation', value);
    },
    change_Selected_AtComparisonOfPossibilitiesToConvertRegenerativeEnergy_at_powerRecuperationUnit_Selection_storeAction({ commit }, value) {
        commit('change_Selected_AtComparisonOfPossibilitiesToConvertRegenerativeEnergy_at_powerRecuperationUnit_Selection_storeMutation', value)
    },

    async insert_databaseRecord_at_powerRecuperationUnits_storeAction({ rootState }, value) {
        const data = {
            configurationOverviewsId: rootState.configurations.configurationOverviewsId,
            configurationSteps: rootState.configurations.configurationSteps,
            powerRecuperationUnit: value,
        }

        await axios({
            method: 'post',
            url: '/api/controlTechnologies/insert_databaseRecord_at_powerRecuperationUnits',
            data: {
                data: data,
            }
        })
            .then((response) => {
                this.dispatch('statusHandling/change_dataAndStatus_storeAction', response.data);

                if (response.data.status.statusCode.substring(0, 1) == '2') {
                    this.dispatch('configurations/change_configurationSteps_storeAction', response.data.data.configurationSteps);
                }
            })
            .catch(() => { })
    },


    // brakeControl
    change_brakeControl_toDefault_storeAction({ commit }) {
        commit('change_brakeControl_Data_storeMutation', {});
    },

    async get_brakeControl_Informations_storeAction({ dispatch, rootState }) {
        const data = {
            configurationOverviewsId: rootState.configurations.configurationOverviewsId,
            configurationSteps: rootState.configurations.configurationSteps,
        }


        await axios({
            method: 'post',
            url: '/api/controlTechnologies/get_brakeControl_Informations',
            data: {
                data: data,
            }
        })
            .then((response) => {
                this.dispatch('statusHandling/change_dataAndStatus_storeAction', response.data);

                if ((response.data.status.statusCode.substring(0, 1) == '2') || (response.data.status.statusCode.substring(0, 2) == '74') || (response.data.status.statusCode.substring(0, 2) == '75')) {
                    dispatch('toDos_afterResponseOf_get_brakeControl_Informations_storeAction', response.data.data)
                }
            })
            .catch(() => { })
    },
    toDos_afterResponseOf_get_brakeControl_Informations_storeAction({ dispatch }, value) {
        dispatch('change_brakeControl_Data_storeAction', value.brakeControl_Data);

        value.configurationSteps.brakeControl.loaded = true;

        this.dispatch('configurations/change_configurationSteps_storeAction', value.configurationSteps);
    },

    change_brakeControl_Data_storeAction({ commit }, value) {
        commit('change_brakeControl_Data_storeMutation', value)
    },

    async checkIf_changesAt_brakeControl_changes_configurationFlow_storeAction({ commit, dispatch }, value) {
        const firstIndex_whichMeetCondition = state.brakeControl_Data.brakeControl_Selection.findIndex(i => i.brakeControl.blockNumber == value.brakeControl.blockNumber);
        if (firstIndex_whichMeetCondition > -1) {
            const firstIndexWhere_Selected_atElementOf_brakeControl_Selection_isTrue = state.brakeControl_Data.brakeControl_Selection.findIndex(i => i.selected == true);

            if (firstIndexWhere_Selected_atElementOf_brakeControl_Selection_isTrue == firstIndex_whichMeetCondition) {
                commit('change_Selected_atEveryElementOf_brakeControl_Selection_toFalse_storeMutation')
            }
            else {
                dispatch('change_Selected_atElementOf_brakeControl_Selection_storeAction', firstIndex_whichMeetCondition)
            }

            await this.dispatch('configurations/selectAndInsert_or_updateAndOrDelete_databaseRecords_storeAction', { changesAt: this._vm.$configurationSteps.brakeControl, brakeControl: value.brakeControl });
            this.dispatch('configurations/check_toDos_at_controlTechnologies_storeAction')
        }
        else {
            // error
        }
    },
    change_Selected_atElementOf_brakeControl_Selection_storeAction({ commit }, value) {
        commit('change_Selected_at_brakeControl_Selection_storeMutation', value)
    },


    // energyEfficiencyRating
    change_energyEfficiencyRating_toDefault_storeAction({ commit, dispatch }) {
        commit('change_loader_forLoadingResults_at_energyEfficiencyRating_Shown_storeMutation', false);

        commit('change_energyEfficiencyRating_PermissibleValueRanges_storeMutation', {});

        commit('change_standby_PowerConsumptionOfCarLightAndElevatorControlSystem_storeMutation', { value: null, invalid: null });
        commit('change_travel_PowerConsumptionOfCarLightAndElevatorControlSystem_storeMutation', { value: null, invalid: null });
        commit('change_travel_LoadFactor_Calculated_storeMutation', null);
        commit('change_travel_LoadFactor_storeMutation', { value: null, invalid: null });
        commit('change_electricityCosts_storeMutation', { value: null, invalid: null });
        commit('change_energyEfficiencyRating_Inputs_Invalid_storeMutation', null);

        dispatch('change_energyEfficiencyRating_storeAction', { elevatorMotor_Machine: { powerConsumption: null, loadPower: null }, inverter: { powerConsumption: null }, energyEfficiencyRating: {} });
    },
    set_energyEfficiencyRating_storeAction({ commit, dispatch }, value) {
        commit('change_standby_PowerConsumptionOfCarLightAndElevatorControlSystem_storeMutation', { value: value.energyEfficiencyRating.standbyPowerConsumptionOfCarLightAndElevatorControlSystem, invalid: false });
        commit('change_travel_PowerConsumptionOfCarLightAndElevatorControlSystem_storeMutation', { value: value.energyEfficiencyRating.travelPowerConsumptionOfCarLightAndElevatorControlSystem, invalid: false });
        commit('change_travel_LoadFactor_Calculated_storeMutation', value.energyEfficiencyRating.travelLoadFactorCalculated);
        commit('change_travel_LoadFactor_storeMutation', { value: value.energyEfficiencyRating.travelLoadFactor, invalid: false });
        commit('change_electricityCosts_storeMutation', { value: value.energyEfficiencyRating.electricityCostsPerKilowattHour, invalid: false });
        commit('change_energyEfficiencyRating_Inputs_Invalid_storeMutation', false);

        dispatch('change_energyEfficiencyRating_storeAction', { elevatorMotor_Machine: value.elevatorMotor_Machine, inverter: value.inverter, energyEfficiencyRating: value.energyEfficiencyRating });
    },

    async get_energyEfficiencyRating_PermissibleValueRanges_Informations_storeAction({ dispatch }) {
        await axios({
            method: 'post',
            url: '/api/controlTechnologies/get_energyEfficiencyRating_PermissibleValueRanges_Informations',
        })
            .then((response) => {
                dispatch('toDos_afterResponseOf_get_energyEfficiencyRating_PermissibleValueRanges_Informations_storeAction', response.data.data)
            })
    },
    toDos_afterResponseOf_get_energyEfficiencyRating_PermissibleValueRanges_Informations_storeAction({ commit }, value) {
        commit('change_energyEfficiencyRating_PermissibleValueRanges_storeMutation', value.energyEfficiencyRating_PermissibleValueRanges);
    },

    change_standby_PowerConsumptionOfCarLightAndElevatorControlSystem_storeAction({ commit, dispatch }, value) {
        const valueAndValueValidationCriteria = this._vm.$transmit_valueAndValueValidationCriteria;
        valueAndValueValidationCriteria.value = value;
        valueAndValueValidationCriteria.dataType = this._vm.$dataTypes.decimal;
        valueAndValueValidationCriteria.permissibleNumberOfDecimalPlaces = 2;
        valueAndValueValidationCriteria.permissibleValueRange = state.energyEfficiencyRating_PermissibleValueRanges.standbyPowerConsumptionOfCarLightAndElevatorControlSystem;
        const standby_PowerConsumptionOfCarLightAndElevatorControlSystem_ValidationResult = this._vm.$checkIf_value_isValid(valueAndValueValidationCriteria);

        commit('change_standby_PowerConsumptionOfCarLightAndElevatorControlSystem_storeMutation', standby_PowerConsumptionOfCarLightAndElevatorControlSystem_ValidationResult)
        dispatch('checkIf_energyEfficiencyRating_Inputs_areValid_storeAction')
    },
    change_travel_PowerConsumptionOfCarLightAndElevatorControlSystem_storeAction({ commit, dispatch }, value) {
        const valueAndValueValidationCriteria = this._vm.$transmit_valueAndValueValidationCriteria;
        valueAndValueValidationCriteria.value = value;
        valueAndValueValidationCriteria.dataType = this._vm.$dataTypes.decimal;
        valueAndValueValidationCriteria.permissibleNumberOfDecimalPlaces = 2;
        valueAndValueValidationCriteria.permissibleValueRange = state.energyEfficiencyRating_PermissibleValueRanges.travelPowerConsumptionOfCarLightAndElevatorControlSystem;
        const travel_PowerConsumptionOfCarLightAndElevatorControlSystem_ValidationResult = this._vm.$checkIf_value_isValid(valueAndValueValidationCriteria);

        commit('change_travel_PowerConsumptionOfCarLightAndElevatorControlSystem_storeMutation', travel_PowerConsumptionOfCarLightAndElevatorControlSystem_ValidationResult)
        dispatch('checkIf_energyEfficiencyRating_Inputs_areValid_storeAction')
    },
    change_travel_LoadFactor_Calculated_storeAction({ commit, dispatch }, value) {
        commit('change_travel_LoadFactor_Calculated_storeMutation', value)
        dispatch('checkIf_energyEfficiencyRating_Inputs_areValid_storeAction')
    },
    change_travel_LoadFactor_storeAction({ commit, dispatch }, value) {
        const valueAndValueValidationCriteria = this._vm.$transmit_valueAndValueValidationCriteria;
        valueAndValueValidationCriteria.value = value;
        valueAndValueValidationCriteria.dataType = this._vm.$dataTypes.integer;
        valueAndValueValidationCriteria.permissibleValueRange = state.energyEfficiencyRating_PermissibleValueRanges.travelLoadFactor;
        valueAndValueValidationCriteria.permissibleNumberOfDecimalPlaces = null;
        const travel_LoadFactor_ValidationResult = this._vm.$checkIf_value_isValid(valueAndValueValidationCriteria);

        commit('change_travel_LoadFactor_storeMutation', travel_LoadFactor_ValidationResult)
        dispatch('checkIf_energyEfficiencyRating_Inputs_areValid_storeAction')
    },
    change_electricityCosts_storeAction({ commit, dispatch }, value) {
        const valueAndValueValidationCriteria = this._vm.$transmit_valueAndValueValidationCriteria;
        valueAndValueValidationCriteria.value = value;
        valueAndValueValidationCriteria.dataType = this._vm.$dataTypes.decimal;
        valueAndValueValidationCriteria.permissibleNumberOfDecimalPlaces = 2;
        valueAndValueValidationCriteria.permissibleValueRange = state.energyEfficiencyRating_PermissibleValueRanges.electricityCostsPerKilowattHour;
        const electricityCosts_ValidationResult = this._vm.$checkIf_value_isValid(valueAndValueValidationCriteria);

        commit('change_electricityCosts_storeMutation', electricityCosts_ValidationResult)
        dispatch('checkIf_energyEfficiencyRating_Inputs_areValid_storeAction')
    },
    checkIf_energyEfficiencyRating_Inputs_areValid_storeAction({ commit, dispatch }) {
        if ((state.standby_PowerConsumptionOfCarLightAndElevatorControlSystem.invalid == false) && (state.travel_PowerConsumptionOfCarLightAndElevatorControlSystem.invalid == false) && ((state.travel_LoadFactor_Calculated == true) || ((state.travel_LoadFactor_Calculated == false) && (state.travel_LoadFactor.invalid == false))) && (state.electricityCosts.invalid == false)) {
            commit('change_energyEfficiencyRating_Inputs_Invalid_storeMutation', false)
            if (state.comparisonOfPossibilitiesToConvertRegenerativeEnergy_Modal_Shown == true) {
                dispatch('get_comparisonOfPossibilitiesToConvertRegenerativeEnergy_Informations_storeAction');
            }
            else {
                dispatch('get_energyEfficiencyRating_Informations_storeAction', { standbyPowerConsumptionOfCarLightAndElevatorControlSystem_Transmitted: true, electricityCostsPerKilowattHour_Transmitted: true, all_Inputs_Transmitted: true });
            }
        }
        else {
            commit('change_energyEfficiencyRating_Inputs_Invalid_storeMutation', true);

            commit('abort_httpRequests_get_comparisonOfPossibilitiesToConvertRegenerativeEnergy_Informations_storeMutation');
            commit('abort_httpRequests_get_energyEfficiencyRating_Informations_storeMutation');

            dispatch('change_energyEfficiencyRating_storeAction', { elevatorMotor_Machine: { powerConsumption: null, loadPower: null }, inverter: { powerConsumption: null }, energyEfficiencyRating: {} });

            dispatch('delete_databaseRecord_at_energyEfficiencyRatings_storeAction');
        }
    },

    async checkIf_userPreferences_for_energyEfficiencyRating_Set_storeAction({ rootState, commit, dispatch }) {
        var standbyPowerConsumptionOfCarLightAndElevatorControlSystem_Transmitted = false;
        var electricityCostsPerKilowattHour_Transmitted = false;

        if (rootState.user.userPreferences_Loaded == false) {
            await this.dispatch('user/get_userPreference_Informations_storeAction')
        }

        if (((rootState.user.userPreferences.hasOwnProperty('energyEfficiencyRating_Standby_PowerConsumptionOfCarLightAndElevatorControlSystem') == false) || (rootState.user.userPreferences.energyEfficiencyRating_Standby_PowerConsumptionOfCarLightAndElevatorControlSystem == 0)) && ((rootState.user.userPreferences.hasOwnProperty('energyEfficiencyRating_ElectricityCosts') == true) && (rootState.user.userPreferences.energyEfficiencyRating_ElectricityCosts > 0))) {
            commit('change_electricityCosts_storeMutation', { value: rootState.user.userPreferences.energyEfficiencyRating_ElectricityCosts, invalid: false });

            electricityCostsPerKilowattHour_Transmitted = true;
        }
        else if (((rootState.user.userPreferences.hasOwnProperty('energyEfficiencyRating_Standby_PowerConsumptionOfCarLightAndElevatorControlSystem') == true) && (rootState.user.userPreferences.energyEfficiencyRating_Standby_PowerConsumptionOfCarLightAndElevatorControlSystem > 0)) && ((rootState.user.userPreferences.hasOwnProperty('energyEfficiencyRating_ElectricityCosts') == false) || (rootState.user.userPreferences.energyEfficiencyRating_ElectricityCosts == 0))) {
            commit('change_standby_PowerConsumptionOfCarLightAndElevatorControlSystem_storeMutation', { value: rootState.user.userPreferences.energyEfficiencyRating_Standby_PowerConsumptionOfCarLightAndElevatorControlSystem, invalid: false });

            standbyPowerConsumptionOfCarLightAndElevatorControlSystem_Transmitted = true;
        }
        else if (((rootState.user.userPreferences.hasOwnProperty('energyEfficiencyRating_Standby_PowerConsumptionOfCarLightAndElevatorControlSystem') == true) && (rootState.user.userPreferences.energyEfficiencyRating_Standby_PowerConsumptionOfCarLightAndElevatorControlSystem > 0)) && ((rootState.user.userPreferences.hasOwnProperty('energyEfficiencyRating_ElectricityCosts') == true) && (rootState.user.userPreferences.energyEfficiencyRating_ElectricityCosts > 0))) {
            commit('change_standby_PowerConsumptionOfCarLightAndElevatorControlSystem_storeMutation', { value: rootState.user.userPreferences.energyEfficiencyRating_Standby_PowerConsumptionOfCarLightAndElevatorControlSystem, invalid: false });
            commit('change_electricityCosts_storeMutation', { value: rootState.user.userPreferences.energyEfficiencyRating_ElectricityCosts, invalid: false });

            standbyPowerConsumptionOfCarLightAndElevatorControlSystem_Transmitted = true;
            electricityCostsPerKilowattHour_Transmitted = true;
        }

        await dispatch('get_energyEfficiencyRating_Informations_storeAction', { standbyPowerConsumptionOfCarLightAndElevatorControlSystem_Transmitted: standbyPowerConsumptionOfCarLightAndElevatorControlSystem_Transmitted, electricityCostsPerKilowattHour_Transmitted: electricityCostsPerKilowattHour_Transmitted, all_Inputs_Transmitted: false });
    },

    async get_energyEfficiencyRating_Informations_storeAction({ rootState, commit, dispatch }, value) {
        var powerRecuperationUnit = { typeDesignation: undefined };

        if ((state.powerRecuperationUnit_Data.hasOwnProperty('powerRecuperationUnit_Selection')) && (Array.isArray(state.powerRecuperationUnit_Data.powerRecuperationUnit_Selection))) {
            const firstIndexWhere_Selected_at_powerRecuperationUnit_Selection_isTrue = state.powerRecuperationUnit_Data.powerRecuperationUnit_Selection.findIndex(i => i.selected == true);
            if (firstIndexWhere_Selected_at_powerRecuperationUnit_Selection_isTrue > -1) {
                powerRecuperationUnit.typeDesignation = state.powerRecuperationUnit_Data.powerRecuperationUnit_Selection[firstIndexWhere_Selected_at_powerRecuperationUnit_Selection_isTrue].powerRecuperationUnit.typeDesignation;
            }
            else {
                // error
            }
        }

        var energyEfficiencyRating = {}
        if (value.all_Inputs_Transmitted == false) {
            if (value.standbyPowerConsumptionOfCarLightAndElevatorControlSystem_Transmitted == true) {
                energyEfficiencyRating.standbyPowerConsumptionOfCarLightAndElevatorControlSystem = state.standby_PowerConsumptionOfCarLightAndElevatorControlSystem.value;
            }
            if (value.electricityCostsPerKilowattHour_Transmitted == true) {
                energyEfficiencyRating.electricityCostsPerKilowattHour = state.electricityCosts.value;
            }
        }
        else if (value.all_Inputs_Transmitted == true) {
            energyEfficiencyRating.standbyPowerConsumptionOfCarLightAndElevatorControlSystem = state.standby_PowerConsumptionOfCarLightAndElevatorControlSystem.value;
            energyEfficiencyRating.travelPowerConsumptionOfCarLightAndElevatorControlSystem = state.travel_PowerConsumptionOfCarLightAndElevatorControlSystem.value;
            energyEfficiencyRating.travelLoadFactorCalculated = state.travel_LoadFactor_Calculated;
            energyEfficiencyRating.travelLoadFactor = state.travel_LoadFactor.value;
            energyEfficiencyRating.electricityCostsPerKilowattHour = state.electricityCosts.value;
        }

        const data = {
            configurationOverviewsId: rootState.configurations.configurationOverviewsId,
            energyEfficiencyRating_NullableInputs: energyEfficiencyRating,
            productTypeForConversionOfRegenerativeEnergy: state.productTypeForConversionOfRegenerativeEnergy,
            powerRecuperationUnit: powerRecuperationUnit,
            standbyPowerConsumptionOfCarLightAndElevatorControlSystem_Transmitted: value.standbyPowerConsumptionOfCarLightAndElevatorControlSystem_Transmitted,
            electricityCostsPerKilowattHour_Transmitted: value.electricityCostsPerKilowattHour_Transmitted,
            printInDocument: state.printInDocument,
            all_Inputs_Transmitted: value.all_Inputs_Transmitted,
        }

        if (rootState.configurations.configurationSteps.energyEfficiencyRating.loaded == true) {
            commit('change_loader_forLoadingResults_at_energyEfficiencyRating_Shown_storeMutation', true)
        }

        commit('abort_httpRequests_get_energyEfficiencyRating_Informations_storeMutation');
        if (state.cancellationInformationsAboutHttpRequests_get_energyEfficiencyRating_Informations.signal.aborted == true) {
            commit('change_cancellationInformationsAboutHttpRequests_get_energyEfficiencyRating_Informations_toNewAbortController_storeMutation')
        }

        await axios({
            method: 'post',
            url: '/api/controlTechnologies/get_energyEfficiencyRating_Informations',
            signal: state.cancellationInformationsAboutHttpRequests_get_energyEfficiencyRating_Informations.signal,
            data: {
                data: data,
            }
        })
            .then(async (response) => {
                this.dispatch('statusHandling/change_dataAndStatus_storeAction', response.data);

                if ((response.data.status.statusCode.substring(0, 1) == '2') || (response.data.status.statusCode.substring(0, 2) == '74') || (response.data.status.statusCode.substring(0, 2) == '75')) {
                    await dispatch('toDos_afterResponseOf_get_energyEfficiencyRating_Informations_storeAction', response.data.data);
                }
                commit('change_loader_forLoadingResults_at_energyEfficiencyRating_Shown_storeMutation', false)
            })
            .catch(() => { })
    },
    async toDos_afterResponseOf_get_energyEfficiencyRating_Informations_storeAction({ rootState, commit, dispatch }, value) {
        if (value.all_Inputs_Transmitted == false) {
            if (value.standbyPowerConsumptionOfCarLightAndElevatorControlSystem_Transmitted == false) {
                commit('change_standby_PowerConsumptionOfCarLightAndElevatorControlSystem_storeMutation', { value: value.energyEfficiencyRating.standbyPowerConsumptionOfCarLightAndElevatorControlSystem, invalid: false })
            }

            if (value.electricityCostsPerKilowattHour_Transmitted == false) {
                commit('change_electricityCosts_storeMutation', { value: value.energyEfficiencyRating.electricityCostsPerKilowattHour, invalid: false })
            }

            commit('change_travel_PowerConsumptionOfCarLightAndElevatorControlSystem_storeMutation', { value: value.energyEfficiencyRating.travelPowerConsumptionOfCarLightAndElevatorControlSystem, invalid: false })
            commit('change_travel_LoadFactor_Calculated_storeMutation', value.energyEfficiencyRating.travelLoadFactorCalculated)
            commit('change_travel_LoadFactor_storeMutation', { value: value.energyEfficiencyRating.travelLoadFactor, invalid: false })
            commit('change_energyEfficiencyRating_Inputs_Invalid_storeMutation', false);
        }

        if (value.energyEfficiencyRating.travelLoadFactorCalculated == true) {
            commit('change_travel_LoadFactor_storeMutation', { value: value.energyEfficiencyRating.travelLoadFactor, invalid: false })
        }

        dispatch('change_energyEfficiencyRating_storeAction', value);

        var configurationSteps = Object.assign({}, rootState.configurations.configurationSteps);
        configurationSteps.energyEfficiencyRating.loaded = true;
        this.dispatch('configurations/change_configurationSteps_storeAction', configurationSteps);

        await dispatch('insertOrUpdate_databaseRecord_at_energyEfficiencyRatings_storeAction', value)
    },

    change_energyEfficiencyRating_storeAction({ commit }, value) {
        commit('change_energyEfficiencyRating_storeMutation', value);
    },

    async insertOrUpdate_databaseRecord_at_energyEfficiencyRatings_storeAction({ rootState }, value) {
        const data = {
            configurationOverviewsId: rootState.configurations.configurationOverviewsId,
            configurationSteps: rootState.configurations.configurationSteps,
            elevatorMotor_Machine: value.elevatorMotor_Machine,
            inverter: value.inverter,
            energyEfficiencyRating: value.energyEfficiencyRating,
        }

        await axios({
            method: 'post',
            url: '/api/controlTechnologies/insertOrUpdate_databaseRecord_at_energyEfficiencyRatings',
            data: {
                data: data,
            }
        })
            .then(async (response) => {
                this.dispatch('statusHandling/change_dataAndStatus_storeAction', response.data);

                if (response.data.status.statusCode.startsWith('2')) {
                    this.dispatch('configurations/change_configurationSteps_storeAction', response.data.data.configurationSteps);

                    if (rootState.configurations.configuration_Modifiable == false) {
                        await this.dispatch('summary/update_databaseRecord_at_configurationOverviews_bySetting_PKstring_storeAction');
                        await this.dispatch('summary/update_databaseRecord_at_configurationOverviews_bySetting_OutputContentOfHtmlFile_storeAction');
                        await this.dispatch('summary/update_databaseRecord_at_configurationOverviews_bySetting_OutputContentOfTextFile_storeAction');
                    }
                }
            })
            .catch(() => { })
    },
    async delete_databaseRecord_at_energyEfficiencyRatings_storeAction({ rootState }) {
        if (rootState.configurationSteps.energyEfficiencyRating.storedInDatabase == true) {
            const data = {
                configurationOverviewsId: rootState.configurations.configurationOverviewsId,
                configurationSteps: rootState.configurations.configurationSteps
            }

            await axios({
                method: 'post',
                url: '/api/controlTechnologies/delete_databaseRecord_at_energyEfficiencyRatings',
                data: {
                    data: data,
                }
            })
                .then(async (response) => {
                    this.dispatch('statusHandling/change_dataAndStatus_storeAction', response.data);

                    if (response.data.status.statusCode.startsWith('2')) {
                        this.dispatch('configurations/change_configurationSteps_storeAction', response.data.data.configurationSteps);

                        if (rootState.configurations.configuration_Modifiable == false) {
                            await this.dispatch('summary/update_databaseRecord_at_configurationOverviews_bySetting_PKstring_storeAction');
                            await this.dispatch('summary/update_databaseRecord_at_configurationOverviews_bySetting_OutputContentOfHtmlFile_storeAction');
                            await this.dispatch('summary/update_databaseRecord_at_configurationOverviews_bySetting_OutputContentOfTextFile_storeAction');
                        }
                    }
                })
                .catch(() => { })
        }
    },
    async changed_energyEfficiencyRating_PrintInDocument_storeAction({ commit }, value) {
        commit('change_printInDocument_storeMutation', value);
        const data = {
            printInDocument: state.printInDocument,
            comparisonOfPossibilitiesToConvertRegenerativeEnergy: state.comparisonOfPossibilitiesToConvertRegenerativeEnergy
        }

        await axios({
            method: 'post',
            url: '/api/controlTechnologies/changed_energyEfficiencyRating_PrintInDocument',
            data: {
                data: data,
            }
        })
            .then(async (response) => {
                if (response.data.status.statusCode.startsWith('2')) {
                    commit('change_comparisonOfPossibilitiesToConvertRegenerativeEnergy_storeMutation', response.data.data)
                }
            })
            .catch(() => { })

    },
}

const mutations = {

    ////TestDimmler
    //get_FI_Type_storeMutation(state, payload) {
    //    state.FI_Type_List = payload;
    //},

    // inverter
    change_inverter_Selection_Shown_storeMutation(state, payload) {
        state.inverter_Selection_Shown = payload;
    },

    change_inverter_Data_storeMutation(state, payload) {
        state.inverter_Data = payload;
    },

    change_Selected_at_inverter_Selection_storeMutation(state, payload) {
        for (let i = 0; i < state.inverter_Data.inverter_Selection.length; i++) {
            state.inverter_Data.inverter_Selection[i].selected = false;

            if (i == payload) {
                state.inverter_Data.inverter_Selection[i].selected = true;
            }
        }
    },
    change_Selected_atEveryElementOf_inverter_Selection_toFalse_storeMutation(state) {
        state.inverter_Data.inverter_Selection.forEach(i => {
            i.selected = false;
        })
    },
    change_Selected_at_thirdPartyInverter_storeMutation(state, payload) {
        state.inverter_Data.thirdPartyInverter.selected = payload;
    },

    change_inverter_Selection_lastIndex_storeMutation(state, payload) {
        state.inverter_Selection_lastIndex = payload;
    },
    change_inverter_Selection_Filtered_storeMutation(state, payload) {
        state.inverter_Selection_Filtered = payload;
    },
    change_filtered_inverter_Selection_storeMutation(state, payload) {
        state.filtered_inverter_Selection = payload;
    },

    change_inverter_forWhich_unintendedCarMovement_isToBeMade_storeMutation(state, payload) {
        state.inverter_forWhich_unintendedCarMovement_isToBeMade = Object.assign({}, payload);
    },

    // inverter filter
    change_inverter_Filter_Shown_storeMutation(state, payload) {
        state.inverter_Filter_Shown = payload;
    },
    //change_inverter_Filter_ListItem_storeMutation(state, payload) {
    //    state.inverter_Filter_ListItem = payload;
    //},

    change_inverter_FilterCriterion_inverter_Series_Selection_storeMutation(state, payload) {
        state.inverter_FilterCriterion_inverter_Series_Selection = payload;
    },

    change_inverter_FilterCriterion_inverter_Series_Selection_MountingControlCabinet_storeMutation(state, payload) {
        state.inverter_FilterCriterion_inverter_Series_Selection_MountingControlCabinet = payload;
    },

    change_Selected_atElementOf_inverter_FilterCriterion_inverter_Series_Selection_storeMutation(state, payload) {
        state.inverter_FilterCriterion_inverter_Series_Selection.forEach(i => {
            if (i.series == payload) {
                if (i.selected == true) {
                    i.selected = false;
                }
                else {
                    i.selected = true;
                }
            }
        });
    },


    change_Selected_atEveryElementOf_inverter_FilterCriterion_inverter_Series_Selection_toFalse_storeMutation(state) {
        state.inverter_FilterCriterion_inverter_Series_Selection.forEach(i => {
            i.selected = false;
        });
    },

    change_Selected_atElementOf_inverter_FilterCriterion_inverter_Series_Selection_MountingControlCabinet_storeMutation(state, payload) {
        state.inverter_FilterCriterion_inverter_Series_Selection_MountingControlCabinet.forEach(i => {
            if (i.series == payload) {
                if (i.selected == true) {
                    i.selected = false;
                }
                else {
                    i.selected = true;
                }
            }
        });
    },


    change_Selected_atEveryElementOf_inverter_FilterCriterion_inverter_Series_Selection_toFalse_MountingControlCabinet_storeMutation(state) {
        state.inverter_FilterCriterion_inverter_Series_Selection_MountingControlCabinet.forEach(i => {
            i.selected = false;
        });
    },

    add_elementAt_arrayOf_active_inverter_FilterCriteria_storeMutation(state, payload) {
        state.arrayOf_active_inverter_FilterCriteria.push(payload);
    },
    remove_elementAt_arrayOf_active_inverter_FilterCriteria_storeMutation(state, payload) {
        state.arrayOf_active_inverter_FilterCriteria.splice(payload, 1);
    },
    remove_allElementsAt_arrayOf_active_inverter_FilterCriteria_storeMutation(state) {
        state.arrayOf_active_inverter_FilterCriteria = [];
    },


    // unintendedCarMovement
    change_unintendedCarMovementCheck_Modal_Shown_storeMutation(state, payload) {
        state.unintendedCarMovementCheck_Modal_Shown = payload;
    },
    change_unintendedCarMovementCheck_Input_Disabled_storeMutation(state, payload) {
        state.unintendedCarMovementCheck_Input_Disabled = payload;
    },
    change_loader_forLoadingAllContent_at_unintendedCarMovementCheck_Modal_Shown_storeMutation(state, payload) {
        state.loader_forLoadingAllContent_at_unintendedCarMovementCheck_Modal_Shown = payload;
    },
    change_loader_forLoadingResults_at_unintendedCarMovementCheck_Modal_Shown_storeMutation(state, payload) {
        state.loader_forLoadingResults_at_unintendedCarMovementCheck_Modal_Shown = payload;
    },
    abort_httpRequests_get_unintendedCarMovementCheck_Informations_storeMutation(state) {
        state.cancellationInformationsAboutHttpRequests_get_unintendedCarMovementCheck_Informations.abort();
    },
    change_cancellationInformationsAboutHttpRequests_get_unintendedCarMovementCheck_Informations_toNewAbortController_storeMutation(state) {
        state.cancellationInformationsAboutHttpRequests_get_unintendedCarMovementCheck_Informations = new AbortController();
    },

    change_unintendedCarMovementCheck_Inputs_ValueListAndSelectionsAndPermissibleValueRanges_storeMutation(state, payload) {
        state.unintendedCarMovementCheck_Inputs_ValueListAndSelectionsAndPermissibleValueRanges = Object.assign({}, payload);
    },
    change_unintendedCarMovementCheck_Inputs_ValueListAndSelectionsAndPermissibleValueRanges_Loaded_storeMutation(state, payload) {
        state.unintendedCarMovementCheck_Inputs_ValueListAndSelectionsAndPermissibleValueRanges_Loaded = payload;
    },

    change_doorZone_storeMutation(state, payload) {
        state.doorZone = payload;
    },
    change_deadTime_storeMutation(state, payload) {
        state.deadTime = payload;
    },
    change_accelerationLimitedTo2Point5MetersPerSquareSecond_storeMutation(state, payload) {
        state.accelerationLimitedTo2Point5MetersPerSquareSecond = payload;
    },
    change_speedLimit_storeMutation(state, payload) {
        state.speedLimit = payload;
    },
    change_lowestShaftDoorHeight_storeMutation(state, payload) {
        state.lowestShaftDoorHeight = payload;
    },
    change_carDoorHeight_storeMutation(state, payload) {
        state.carDoorHeight = payload;
    },
    change_carDoorApronLength_storeMutation(state, payload) {
        state.carDoorApronLength = payload;
    },
    change_informationPermissibleDistance_storeMutation(state, payload) {
        state.informationPermissibleDistance = payload;
    },
    change_unintendedCarMovementCheck_Inputs_Invalid_storeMutation(state, payload) {
        state.unintendedCarMovementCheck_Inputs_Invalid = payload;
    },

    change_carWeightInWorstLoadCase_storeMutation(state, payload) {
        state.carWeightInWorstLoadCase = Object.assign({}, payload);
    },
    change_travelDirectionInWorstLoadCase_storeMutation(state, payload) {
        state.travelDirectionInWorstLoadCase = Object.assign({}, payload);
    },

    change_unintendedCarMovementCheck_PresentationOfResults_storeMutation(state, payload) {
        state.unintendedCarMovementCheck_PresentationOfResults = payload;
    },
    change_calculatedWithShortCircuitMotorBraking_storeMutation(state, payload) {
        state.calculatedWithShortCircuitMotorBraking = payload;
    },

    change_unintendedCarMovementCheck_storeMutation(state, payload) {
        state.unintendedCarMovementCheck = Object.assign({}, payload);
    },

    add_unintendedCarMovementCheck_Object_at_inverter_Selection_storeMutation(state, payload) {
        const unintendedCarMovementCheck = payload.unintendedCarMovementCheck;
        const helpObject = { unintendedCarMovementCheck };
        state.inverter_Data.inverter_Selection[payload.inverter_index] = Object.assign(state.inverter_Data.inverter_Selection[payload.inverter_index], helpObject);
        state.inverter_Data.inverter_Selection[payload.inverter_index].unintendedCarMovementCheck_Executed = true;
    },
    change_unintendedCarMovementCheck_Object_at_inverter_Selection_storeMutation(state, payload) {
        state.inverter_Data.inverter_Selection[payload.inverter_index].unintendedCarMovementCheck = payload.unintendedCarMovementCheck;
    },
    delete_unintendedCarMovementCheck_Object_at_inverter_Selection_storeMutation(state, payload) {
        state.inverter_Data.inverter_Selection[payload].unintendedCarMovementCheck_Executed = false;
        delete state.inverter_Data.inverter_Selection[payload].unintendedCarMovementCheck
    },

    add_unintendedCarMovementCheck_Object_at_thirdPartyInverter_storeMutation(state, payload) {
        const unintendedCarMovementCheck = payload;
        const helpObject = { unintendedCarMovementCheck };
        state.inverter_Data.thirdPartyInverter = Object.assign(state.inverter_Data.thirdPartyInverter, helpObject);
        state.inverter_Data.thirdPartyInverter.unintendedCarMovementCheck_Executed = true;
    },
    change_unintendedCarMovementCheck_Object_at_thirdPartyInverter_storeMutation(state, payload) {
        state.inverter_Data.thirdPartyInverter.unintendedCarMovementCheck = payload;
    },
    delete_unintendedCarMovementCheck_Object_at_thirdPartyInverter_storeMutation(state) {
        state.inverter_Data.thirdPartyInverter.unintendedCarMovementCheck_Executed = false;
        delete state.inverter_Data.thirdPartyInverter.unintendedCarMovementCheck
    },


    // comparisonOfPossibilitiesToConvertRegenerativeEnergy
    change_comparisonOfPossibilitiesToConvertRegenerativeEnergy_Modal_Shown_storeMutation(state, payload) {
        state.comparisonOfPossibilitiesToConvertRegenerativeEnergy_Modal_Shown = payload;
    },
    change_loader_forLoadingAllContent_at_comparisonOfPossibilitiesToConvertRegenerativeEnergy_Modal_Shown_storeMutation(state, payload) {
        state.loader_forLoadingAllContent_at_comparisonOfPossibilitiesToConvertRegenerativeEnergy_Modal_Shown = payload;
    },
    change_loader_forLoadingResults_at_comparisonOfPossibilitiesToConvertRegenerativeEnergy_Modal_Shown_storeMutation(state, payload) {
        state.loader_forLoadingResults_at_comparisonOfPossibilitiesToConvertRegenerativeEnergy_Modal_Shown = payload;
    },
    abort_httpRequests_get_comparisonOfPossibilitiesToConvertRegenerativeEnergy_Informations_storeMutation(state) {
        state.cancellationInformationsAboutHttpRequests_get_comparisonOfPossibilitiesToConvertRegenerativeEnergy_Informations.abort();
    },
    change_cancellationInformationsAboutHttpRequests_get_comparisonOfPossibilitiesToConvertRegenerativeEnergy_Informations_toNewAbortController_storeMutation(state) {
        state.cancellationInformationsAboutHttpRequests_get_comparisonOfPossibilitiesToConvertRegenerativeEnergy_Informations = new AbortController();
    },

    change_comparisonOfPossibilitiesToConvertRegenerativeEnergy_storeMutation(state, payload) {
        state.comparisonOfPossibilitiesToConvertRegenerativeEnergy = Object.assign({}, payload);
    },
    change_comparisonOfPossibilitiesToConvertRegenerativeEnergy_powerRecuperationUnit_Selection_storeMutation(state, payload) {
        state.comparisonOfPossibilitiesToConvertRegenerativeEnergy_powerRecuperationUnit_Selection = payload;
    },
    change_Selected_at_comparisonOfPossibilitiesToConvertRegenerativeEnergy_powerRecuperationUnit_Selection_storeMutation(state, payload) {
        for (let i = 0; i < state.comparisonOfPossibilitiesToConvertRegenerativeEnergy_powerRecuperationUnit_Selection.length; i++) {
            state.comparisonOfPossibilitiesToConvertRegenerativeEnergy_powerRecuperationUnit_Selection[i].selected = false;
            if (i == payload) {
                state.comparisonOfPossibilitiesToConvertRegenerativeEnergy_powerRecuperationUnit_Selection[i].selected = true;
            }
        }
    },


    // productTypeForConversionOfRegenerativeEnergy
    change_productTypeForConversionOfRegenerativeEnergy_storeMutation(state, payload) {
        state.productTypeForConversionOfRegenerativeEnergy = payload;
    },


    // brakeResistor
    change_full_brakeResistor_Selection_Shown_storeMutation(state, payload) {
        state.full_brakeResistor_Selection_Shown = payload;
    },
    change_brakeResistor_Data_storeMutation(state, payload) {
        state.brakeResistor_Data = Object.assign({}, payload);
    },
    change_Selected_at_brakeResistor_Selection_storeMutation(state, payload) {
        for (let i = 0; i < state.brakeResistor_Data.brakeResistor_Selection.length; i++) {
            state.brakeResistor_Data.brakeResistor_Selection[i].selected = false;
            if (i == payload) {
                state.brakeResistor_Data.brakeResistor_Selection[i].selected = true;
            }
        }
    },
    change_Selected_atEveryElementOf_brakeResistor_Selection_toFalse_storeMutation(state) {
        if ((state.brakeResistor_Data.hasOwnProperty('brakeResistor_Selection')) && (Array.isArray(state.brakeResistor_Data.brakeResistor_Selection))) {
            state.brakeResistor_Data.brakeResistor_Selection.forEach(i => {
                i.selected = false;
            })
        }
    },


    // powerRecuperationUnit
    change_powerRecuperationUnit_Data_storeMutation(state, payload) {
        state.powerRecuperationUnit_Data = Object.assign({}, payload);
    },
    change_full_powerRecuperationUnit_Selection_Shown_storeMutation(state, payload) {
        state.full_powerRecuperationUnit_Selection_Shown = payload;
    },
    change_Selected_at_powerRecuperationUnit_Selection_storeMutation(state, payload) {
        for (let i = 0; i < state.powerRecuperationUnit_Data.powerRecuperationUnit_Selection.length; i++) {
            state.powerRecuperationUnit_Data.powerRecuperationUnit_Selection[i].selected = false;
            state.powerRecuperationUnit_Data.powerRecuperationUnit_Selection[i].selected_AtComparisonOfPossibilitiesToConvertRegenerativeEnergy = false;
            if (i == payload) {
                state.powerRecuperationUnit_Data.powerRecuperationUnit_Selection[i].selected = true;
                state.powerRecuperationUnit_Data.powerRecuperationUnit_Selection[i].selected_AtComparisonOfPossibilitiesToConvertRegenerativeEnergy = true;

            }
        }
    },
    change_Selected_atEveryElementOf_powerRecuperationUnit_Selection_toFalse_storeMutation(state) {
        if ((state.powerRecuperationUnit_Data.hasOwnProperty('powerRecuperationUnit_Selection')) && (Array.isArray(state.powerRecuperationUnit_Data.powerRecuperationUnit_Selection))) {
            state.powerRecuperationUnit_Data.powerRecuperationUnit_Selection.forEach(i => {
                i.selected = false;
                i.selected_AtComparisonOfPossibilitiesToConvertRegenerativeEnergy = false;
            })
        }
    },
    change_Selected_AtComparisonOfPossibilitiesToConvertRegenerativeEnergy_at_powerRecuperationUnit_Selection_storeMutation(state, payload) {
        for (let i = 0; i < state.powerRecuperationUnit_Data.powerRecuperationUnit_Selection.length; i++) {
            state.powerRecuperationUnit_Data.powerRecuperationUnit_Selection[i].selected_AtComparisonOfPossibilitiesToConvertRegenerativeEnergy = false;
            if (i == payload) {
                state.powerRecuperationUnit_Data.powerRecuperationUnit_Selection[i].selected_AtComparisonOfPossibilitiesToConvertRegenerativeEnergy = true;
            }
        }
    },


    // brakeControl
    change_brakeControl_Data_storeMutation(state, payload) {
        state.brakeControl_Data = Object.assign({}, payload);
    },
    change_Selected_at_brakeControl_Selection_storeMutation(state, payload) {
        for (let i = 0; i < state.brakeControl_Data.brakeControl_Selection.length; i++) {
            state.brakeControl_Data.brakeControl_Selection[i].selected = false;
            if (i == payload) {
                state.brakeControl_Data.brakeControl_Selection[i].selected = true;
            }
        }
    },
    change_Selected_atEveryElementOf_brakeControl_Selection_toFalse_storeMutation(state) {
        if (Array.isArray(state.brakeControl_Data.brakeControl_Selection)) {
            state.brakeControl_Data.brakeControl_Selection.forEach(i => {
                i.selected = false;
            })
        }
    },


    // energyEfficiencyRating
    change_loader_forLoadingResults_at_energyEfficiencyRating_Shown_storeMutation(state, payload) {
        state.loader_forLoadingResults_at_energyEfficiencyRating_Shown = payload;
    },
    abort_httpRequests_get_energyEfficiencyRating_Informations_storeMutation(state) {
        state.cancellationInformationsAboutHttpRequests_get_energyEfficiencyRating_Informations.abort();
    },
    change_cancellationInformationsAboutHttpRequests_get_energyEfficiencyRating_Informations_toNewAbortController_storeMutation(state) {
        state.cancellationInformationsAboutHttpRequests_get_energyEfficiencyRating_Informations = new AbortController();
    },

    change_energyEfficiencyRating_PermissibleValueRanges_storeMutation(state, payload) {
        state.energyEfficiencyRating_PermissibleValueRanges = Object.assign({}, payload);
    },

    change_standby_PowerConsumptionOfCarLightAndElevatorControlSystem_storeMutation(state, payload) {
        state.standby_PowerConsumptionOfCarLightAndElevatorControlSystem = payload;
    },
    change_travel_PowerConsumptionOfCarLightAndElevatorControlSystem_storeMutation(state, payload) {
        state.travel_PowerConsumptionOfCarLightAndElevatorControlSystem = payload;
    },
    change_travel_LoadFactor_Calculated_storeMutation(state, payload) {
        state.travel_LoadFactor_Calculated = payload;
    },
    change_travel_LoadFactor_storeMutation(state, payload) {
        state.travel_LoadFactor = payload;
    },
    change_electricityCosts_storeMutation(state, payload) {
        state.electricityCosts = payload;
    },
    change_energyEfficiencyRating_Inputs_Invalid_storeMutation(state, payload) {
        state.energyEfficiencyRating_Inputs_Invalid = payload;
    },

    change_energyEfficiencyRating_storeMutation(state, payload) {
        state.energyEfficiencyRating = Object.assign({}, payload);
    },
    change_printInDocument_storeMutation(state, payload) {
        state.printInDocument = payload;
    },
}

export const controlTechnologies = {
    namespaced: true,
    components: {
        axios
    },
    state,
    actions,
    mutations
}