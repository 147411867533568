import axios from 'axios';

let state = {
    data: {},
    status: {},
    statusModal_Shown: false,
    valueDivergenceModal_Shown: false,

    accessToken_Expired: false,
}

const actions = {
    change_dataAndStatus_storeAction({ commit, dispatch }, value) {
        //console.log('status', value)

        commit('change_dataAndStatus_storeMutation', value);
        commit('change_statusModal_Shown_storeMutation', false);
        if (value.hasOwnProperty('status')) {
            if ((value.status.statusCode.length == 3) ||
                (value.status.statusCode.startsWith('70')) ||
                (value.status.statusCode.startsWith('71')) ||
                (value.status.statusCode.startsWith('72')) ||
                (value.status.statusCode.startsWith('73')) ||
                (value.status.statusCode.startsWith('76')) ||
                (value.status.statusCode.startsWith('77'))) {
                if ((value.status.statusCode == '401') ||
                    ((value.status.statusInContextOf != 'select_Note_from_databaseRecord_configurationOverviews') &&
                        (value.status.statusInContextOf != 'select_databaseRecord_from_configurationOverviews') &&
                        (value.status.statusInContextOf != 'get_calculated_suspensionMeans_Weight_Informations') &&
                        (value.status.statusInContextOf != 'get_calculated_suspensionMeans_SafetyFactor_Informations') &&
                        (value.status.statusInContextOf != 'check_configuration_byCalculationDll') &&
                        (value.status.statusInContextOf != 'insertOrUpdate_databaseRecord_at_productConfigurator') &&
                        (value.status.statusInContextOf != 'update_databaseRecord_at_configurationOverviews_bySetting_PKstring') &&
                        (value.status.statusInContextOf != 'update_databaseRecord_at_configurationOverviews_bySetting_OutputContentOfHtmlFile') &&
                        (value.status.statusInContextOf != 'update_databaseRecord_at_configurationOverviews_bySetting_OutputContentOfTextFile') &&
                        (value.status.statusInContextOf != 'update_databaseRecord_at_configurationOverviews_bySetting_Configuration_Modifiable'))) {
                    //console.log('status', value)

                    this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
                    dispatch('change_statusModal_Shown_storeAction', true);
                }
            }
            else if (value.status.statusCode.startsWith('2')) {
                if ((value.status.statusInContextOf == 'check_configuration_fromDatabase') ||
                    (value.status.statusInContextOf == 'check_configuration_fromFile')) {
                    this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
                    dispatch('change_statusModal_Shown_storeAction', true);
                }
            }
        }
    },

    change_statusHandling_toDefault_storeAction({ commit }) {
        commit('change_dataAndStatus_storeMutation', { data: {}, status: {} });
        commit('change_statusModal_Shown_storeMutation', false);
        commit('change_valueDivergenceModal_Shown_storeMutation', false)
    },
    change_statusModal_Shown_storeAction({ commit }, value) {
        commit('change_statusModal_Shown_storeMutation', value)
    },
    change_valueDivergenceModal_Shown_storeAction({ commit }, value) {
        commit('change_valueDivergenceModal_Shown_storeMutation', value)
    },
}

const mutations = {
    change_dataAndStatus_storeMutation(state, payload) {
        if (payload.hasOwnProperty('data')) {
            state.data = payload.data;
        }
        else {
            state.data = {};
        }

        if (payload.hasOwnProperty('status')) {
            state.status = payload.status;
        }
        else {
            state.status = {};
        }
    },

    change_statusModal_Shown_storeMutation(state, payload) {
        state.statusModal_Shown = payload;
    },
    change_valueDivergenceModal_Shown_storeMutation(state, payload) {
        state.valueDivergenceModal_Shown = payload;
    },
}

export const statusHandling = {
    namespaced: true,
    components: {
        axios
    },
    state,
    actions,
    mutations
}