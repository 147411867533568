import userManager from '../authentication/authentication.js';
import { store } from '@/store';

const user = JSON.parse(sessionStorage.getItem('user'));
const state = user ? { status: { loggedIn: true }, userManager } : { status: { loggedIn: false }, userManager };

const actions = {
    async authenticate(obj, returnPath) {
        let tempUser = null;

        await store.dispatch('account/getUser').then(response => tempUser = response);
        
        if (tempUser!=null && tempUser.profile.emails != null && tempUser.profile.emails.length>0) {
            tempUser.profile["id"] = tempUser.profile.emails[0];
        }

        if (!!tempUser) {
            state.status = { loggedIn: true };
            state.user = tempUser;
        } else {
            await store.dispatch('account/signIn', returnPath);
        }
    },

    async getUser() {
        try {
            let user = await userManager.getUser();
            return user;
        }
        catch {

        }
    },

    signIn(obj, returnPath) {
        returnPath ? userManager.signinRedirect({ state: returnPath }) : userManager.signinRedirect();
    },
    logout() {
        userManager.signoutRedirect().then(() => {
            userManager.clearStaleState();
            state.status = { loggedIn: false };
            state.user = null;
        });
    }
};



export const account = {
    namespaced: true,
    state,
    actions,
};
