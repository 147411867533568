import axios from 'axios';
import router from '../router';

let state = {

    // SCH-MOD Modul
    schmod_locationProductionSelection: [],
    schmod_ProdApSelection: [],
    schmod_PSGSizeSelection: [],
    schmod_motorIDSelection: [],
    schmod_motorCOSNameSelection: [],
    schmod_motorVoltageSelection: [],
    schmod_inverterTypeSelection: [],
    schmod_cableLengthSelection: [],
    schmod_cableLengthBrakeSelection: [],
    schmod_handWheelSelection: [],
    schmod_handReleaseSelection: [],
    schmod_grooveSelection: [],
    schmod_numberOfGroovesSelection: [],
    schmod_certificationSelection: [],


    schmod_locationProduction: undefined,
    schmod_ProAp: undefined,
    schmod_PSGSize: undefined,
    schmod_motorID: undefined,
    schmod_motorCOSName: undefined,
    schmod_motorVoltage: undefined,
    schmod_inverterType: undefined,
    schmod_cableLength: undefined,
    schmod_cableLengthBrake: undefined,
    schmod_handWheel: undefined,
    schmod_handRelease: undefined,
    schmod_grooveDistance: { value: undefined, invalid: undefined },
    schmod_groove: undefined,
    schmod_numberOfGrooves: undefined,
    schmod_certification: undefined,
    schModDetails: undefined,

    schmod_Valid: false,

    schmodMotordetails: undefined,
    showSCHMOD: false,
}

const actions = {

    // SCH-MOD Modul
    async set_endPSG_storeAction({ commit, dispatch }) {
        await this.dispatch('inputsManual/delete_configuration_storeAction');
        await this.commit('inputsManual/change_customerName_storeMutation', null);
        await this.commit('inputsManual/change_forcedVentilationSelection_storeMutation', []);
        await this.dispatch('inputsManual/set_inputManual_toDefault_storeAction');
        await dispatch('set_inputManual_SCHMOD_toDefault_storeAction');
        await commit('set_showSCHMOD_storeMutation', false);
        router.push({ params: { slug: 'inputsManual' } });
    },
    set_schmod_inputsManual_storeAction({ commit, dispatch }, value) {
        dispatch('check_SCHMOD_storeAction', value);
        //dispatch('get_SCHMOD_DefaultAndValuelists_storeAction');
    },

    async check_SCHMOD_Menu_storeAction({ rootState, commit, dispatch }, value) {
        const data = {
            configurationStepsManualId: value,
        }

        await axios({
            method: 'post',
            url: '/api/inputsManual/check_SCHMOD',
            data: {
                data: data,
            }
        })
            .then((response) => {
                if (response.data == true) {
                    router.push({ params: { slug: 'psgMenu' } });
                }
            })
            .catch(() => {
            })
            .then(() => {
            })
    },

    async check_SCHMOD_storeAction({ rootState, commit, dispatch }, value) {
        const data = {
            configurationStepsManualId: value,
        }

        await axios({
            method: 'post',
            url: '/api/inputsManual/check_SCHMOD',
            data: {
                data: data,
            }
        })
            .then((response) => {
                if (response.data == true) {
                    commit('set_showSCHMOD_storeMutation', false);
                    this.dispatch('inputsManual/set_inputManual_Motor_toDefault_storeAction');
                    this.dispatch('inputsManual/set_inputManual_TractionSheave_toDefault_storeAction');
                    this.dispatch('inputsManual/set_inputManual_RopeGuard_toDefault_storeAction');
                    dispatch('set_inputManual_SCHMOD_toDefault_storeAction');
                    this.dispatch('inputsManual/get_SCHMOD_customerNameSelection_storeAction')
                }
                else {
                    commit('set_showSCHMOD_storeMutation', true);
                    dispatch('get_SCHMOD_DefaultAndValuelists_storeAction');
                }
            })
            .catch(() => {
            })
            .then(() => {
            })
    },

    async get_SCHMOD_DefaultAndValuelists_storeAction({ commit, dispatch, rootState }) {
        this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: true, reasonWhy_loaderModal_Shown: 'data_BeingLoaded' });

        await axios({
            method: 'post',
            url: '/api/inputsManual/get_SCHMOD_DefaultAndValuelists',
        })
            .then((response) => {
                commit('change_SCHMOD_locationProductionSelection_storeMutation', response.data.data.locationProductionSelection);
                if (state.schmod_locationProductionSelection.length > 0) {
                    var firstIndex_whichMeetConditions = state.schmod_locationProductionSelection.findIndex(i => (i.text.text == state.schmod_locationProduction));
                    if (firstIndex_whichMeetConditions > -1) {
                        dispatch('change_SCHMOD_locationProduction_storeAction', state.schmod_locationProductionSelection[firstIndex_whichMeetConditions]);
                    }
                    else {
                        firstIndex_whichMeetConditions = state.schmod_locationProductionSelection.findIndex(i => (i.selected == true));
                        if (firstIndex_whichMeetConditions > -1) {
                            dispatch('change_SCHMOD_locationProduction_storeAction', state.schmod_locationProductionSelection[firstIndex_whichMeetConditions]);
                        }
                        else {
                            //this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
                        }

                    }
                }
                commit('change_SCHMOD_certificationSelection_storeMutation', response.data.data.certificationSelection);
                if (state.schmod_certificationSelection.length > 0) {
                    var firstIndex_whichMeetConditions = state.schmod_certificationSelection.findIndex(i => (i.text.text == state.schmod_certification));
                    if (firstIndex_whichMeetConditions > -1) {
                        dispatch('change_SCHMOD_certification_storeAction', state.schmod_certificationSelection[firstIndex_whichMeetConditions]);
                    }
                    else {
                        firstIndex_whichMeetConditions = state.schmod_certificationSelection.findIndex(i => (i.selected == true));
                        if (firstIndex_whichMeetConditions > -1) {
                            dispatch('change_SCHMOD_certification_storeAction', state.schmod_certificationSelection[firstIndex_whichMeetConditions]);
                        }
                        else {
                            //this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
                        }

                    }
                }
                commit('change_SCHMOD_ProdApSelection_storeMutation', response.data.data.prodApSelection);
                if (state.schmod_ProdApSelection.length > 0) {
                    var firstIndex_whichMeetConditions = state.schmod_ProdApSelection.findIndex(i => (i.text.text == state.prodAP));
                    if (firstIndex_whichMeetConditions > -1) {
                        dispatch('change_SCHMOD_ProAp_storeAction', state.schmod_ProdApSelection[firstIndex_whichMeetConditions]);
                    }
                    else {
                        firstIndex_whichMeetConditions = state.schmod_ProdApSelection.findIndex(i => (i.selected == true));
                        if (firstIndex_whichMeetConditions > -1) {
                            dispatch('change_SCHMOD_ProAp_storeAction', state.schmod_ProdApSelection[firstIndex_whichMeetConditions]);
                        }
                        else {
                            //this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
                        }

                    }
                }
                commit('change_SCHMOD_PSGSizeSelection_storeMutation', response.data.data.psgSizeSelection);
                if (state.schmod_PSGSizeSelection.length > 0) {
                    var firstIndex_whichMeetConditions = state.schmod_PSGSizeSelection.findIndex(i => (i.text.text == state.schmod_PSGSize));
                    if (firstIndex_whichMeetConditions > -1) {
                        dispatch('change_SCHMOD_PSGSize_storeAction', state.schmod_PSGSizeSelection[firstIndex_whichMeetConditions]);
                    }
                    else {
                        firstIndex_whichMeetConditions = state.schmod_PSGSizeSelection.findIndex(i => (i.selected == true));
                        if (firstIndex_whichMeetConditions > -1) {
                            dispatch('change_SCHMOD_PSGSize_storeAction', state.schmod_PSGSizeSelection[firstIndex_whichMeetConditions]);
                        }
                        else {
                            //this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
                        }

                    }
                }
                commit('change_SCHMOD_motorIDSelection_storeMutation', response.data.data.motorIDSelection);
                if (state.schmod_motorIDSelection.length > 0) {
                    var firstIndex_whichMeetConditions = state.schmod_motorIDSelection.findIndex(i => (i.text.text == state.schmod_motorID));
                    if (firstIndex_whichMeetConditions > -1) {
                        dispatch('change_SCHMOD_motorID_storeAction', state.schmod_motorIDSelection[firstIndex_whichMeetConditions]);
                    }
                    else {
                        firstIndex_whichMeetConditions = state.schmod_motorIDSelection.findIndex(i => (i.selected == true));
                        if (firstIndex_whichMeetConditions > -1) {
                            dispatch('change_SCHMOD_motorID_storeAction', state.schmod_motorIDSelection[firstIndex_whichMeetConditions]);
                        }
                        else {
                            //this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
                        }

                    }
                }
                commit('change_SCHMOD_motorCOSNameSelection_storeMutation', response.data.data.motorCOSNameSelection);
                if (state.schmod_motorCOSNameSelection.length > 0) {
                    var firstIndex_whichMeetConditions = state.schmod_motorCOSNameSelection.findIndex(i => (i.text.text == state.schmod_motorCOSName));
                    if (firstIndex_whichMeetConditions > -1) {
                        dispatch('change_SCHMOD_motorCosName_storeAction', state.schmod_motorCOSNameSelection[firstIndex_whichMeetConditions]);
                    }
                    else {
                        firstIndex_whichMeetConditions = state.schmod_motorCOSNameSelection.findIndex(i => (i.selected == true));
                        if (firstIndex_whichMeetConditions > -1) {
                            dispatch('change_SCHMOD_motorCosName_storeAction', state.schmod_motorCOSNameSelection[firstIndex_whichMeetConditions]);
                        }
                        else {
                            //this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
                        }

                    }
                }
                commit('change_SCHMOD_motorVoltageSelection_storeMutation', response.data.data.motorVoltageSelection);
                if (state.schmod_motorVoltageSelection.length > 0) {
                    var firstIndex_whichMeetConditions = state.schmod_motorVoltageSelection.findIndex(i => (i.value == state.schmod_motorVoltage));
                    if (firstIndex_whichMeetConditions > -1) {
                        dispatch('change_SCHMOD_motorVoltage_storeAction', state.schmod_motorVoltageSelection[firstIndex_whichMeetConditions]);
                    }
                    else {
                        firstIndex_whichMeetConditions = state.schmod_motorVoltageSelection.findIndex(i => (i.selected == true));
                        if (firstIndex_whichMeetConditions > -1) {
                            dispatch('change_SCHMOD_motorVoltage_storeAction', state.schmod_motorVoltageSelection[firstIndex_whichMeetConditions]);
                        }
                        else {
                            //this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
                        }

                    }
                }
                commit('change_SCHMOD_inverterTypeSelection_storeMutation', response.data.data.inverterTypeSelection);
                if (state.schmod_inverterTypeSelection.length > 0) {
                    var firstIndex_whichMeetConditions = state.schmod_inverterTypeSelection.findIndex(i => (i.text.text == state.schmod_inverterType));
                    if (firstIndex_whichMeetConditions > -1) {
                        dispatch('change_SCHMOD_inverterType_storeAction', state.schmod_inverterTypeSelection[firstIndex_whichMeetConditions]);
                    }
                    else {
                        firstIndex_whichMeetConditions = state.schmod_inverterTypeSelection.findIndex(i => (i.selected == true));
                        if (firstIndex_whichMeetConditions > -1) {
                            dispatch('change_SCHMOD_inverterType_storeAction', state.schmod_inverterTypeSelection[firstIndex_whichMeetConditions]);
                        }
                        else {
                            //this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
                        }

                    }
                }
                commit('change_SCHMOD_cableLengthSelection_storeMutation', response.data.data.cableLengthSelection);
                if (state.schmod_cableLengthSelection.length > 0) {
                    var firstIndex_whichMeetConditions = state.schmod_cableLengthSelection.findIndex(i => (i.text.text == state.schmod_cableLength));
                    if (firstIndex_whichMeetConditions > -1) {
                        dispatch('change_SCHMOD_cableLength_storeAction', state.schmod_cableLengthSelection[firstIndex_whichMeetConditions]);
                    }
                    else {
                        firstIndex_whichMeetConditions = state.schmod_cableLengthSelection.findIndex(i => (i.selected == true));
                        if (firstIndex_whichMeetConditions > -1) {
                            dispatch('change_SCHMOD_cableLength_storeAction', state.schmod_cableLengthSelection[firstIndex_whichMeetConditions]);
                        }
                        else {
                            //this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
                        }

                    }
                }
                commit('change_SCHMOD_cableLengthBrakeSelection_storeMutation', response.data.data.cableLengthBrakeSelection);
                if (state.schmod_cableLengthBrakeSelection.length > 0) {
                    var firstIndex_whichMeetConditions = state.schmod_cableLengthBrakeSelection.findIndex(i => (i.text.text == state.schmod_cableLengthBrake));
                    if (firstIndex_whichMeetConditions > -1) {
                        dispatch('change_SCHMOD_cableLengthBrake_storeAction', state.schmod_cableLengthBrakeSelection[firstIndex_whichMeetConditions]);
                    }
                    else {
                        firstIndex_whichMeetConditions = state.schmod_cableLengthBrakeSelection.findIndex(i => (i.selected == true));
                        if (firstIndex_whichMeetConditions > -1) {
                            dispatch('change_SCHMOD_cableLengthBrake_storeAction', state.schmod_cableLengthBrakeSelection[firstIndex_whichMeetConditions]);
                        }
                        else {
                            //this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
                        }

                    }
                }
                commit('change_SCHMOD_handWheelSelection_storeMutation', response.data.data.handWheelSelection);
                if (state.schmod_handWheelSelection.length > 0) {
                    var firstIndex_whichMeetConditions = state.schmod_handWheelSelection.findIndex(i => (i.text.text == state.schmod_handWheel));
                    if (firstIndex_whichMeetConditions > -1) {
                        dispatch('change_SCHMOD_handWheel_storeAction', state.schmod_handWheelSelection[firstIndex_whichMeetConditions]);
                    }
                    else {
                        firstIndex_whichMeetConditions = state.schmod_handWheelSelection.findIndex(i => (i.selected == true));
                        if (firstIndex_whichMeetConditions > -1) {
                            dispatch('change_SCHMOD_handWheel_storeAction', state.schmod_handWheelSelection[firstIndex_whichMeetConditions]);
                        }
                        else {
                            //this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
                        }

                    }
                }
                commit('change_SCHMOD_handReleaseSelection_storeMutation', response.data.data.handReleaseSelection);
                if (state.schmod_handReleaseSelection.length > 0) {
                    var firstIndex_whichMeetConditions = state.schmod_handReleaseSelection.findIndex(i => (i.text.text == state.schmod_handRelease));
                    if (firstIndex_whichMeetConditions > -1) {
                        dispatch('change_SCHMOD_handRelease_storeAction', state.schmod_handReleaseSelection[firstIndex_whichMeetConditions]);
                    }
                    else {
                        firstIndex_whichMeetConditions = state.schmod_handReleaseSelection.findIndex(i => (i.selected == true));
                        if (firstIndex_whichMeetConditions > -1) {
                            dispatch('change_SCHMOD_handRelease_storeAction', state.schmod_handReleaseSelection[firstIndex_whichMeetConditions]);
                        }
                        else {
                            //this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
                        }

                    }
                }
                commit('change_SCHMOD_grooveSelection_storeMutation', response.data.data.grooveSelection);
                const firstIndex_whichMeetConditions_groove = state.schmod_grooveSelection.findIndex(i => (i.selected == true));
                if (firstIndex_whichMeetConditions_groove > -1) {
                    dispatch('change_SCHMOD_grooves_storeAction', state.schmod_grooveSelection[firstIndex_whichMeetConditions_groove]);
                }
                else {
                    //this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
                }
                commit('change_SCHMOD_numberOfGroovesSelection_storeMutation', response.data.data.numberOfGroovesSelection);
                const firstIndex_whichMeetConditions_numberOfGrooves = state.schmod_numberOfGroovesSelection.findIndex(i => (i.selected == true));
                if (firstIndex_whichMeetConditions_numberOfGrooves > -1) {
                    dispatch('change_SCHMOD_numberOfGrooves_storeAction', state.schmod_numberOfGroovesSelection[firstIndex_whichMeetConditions_numberOfGrooves]);
                }
                else {
                    //this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
                }

                commit('change_SchModDetails_storeMuation', response.data.data.schModDetails);

            })
            .catch(() => {
                this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
            })
            .then(() => {
                if (state.schmod_locationProductionSelection == null || state.schmod_locationProductionSelection.length == 0) {
                    //this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
                }
                this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });

            })
    },

    async get_SCHMOD_MotorVoltageSelection_storeAction({ commit, dispatch }) {
        this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: true, reasonWhy_loaderModal_Shown: 'data_BeingLoaded' });

        const data = {
            locationProduction: state.schmod_locationProduction,
            prodAp: state.schmod_ProAp,
        }

        await axios({
            method: 'post',
            url: '/api/inputsManual/get_SCHMOD_MotorVoltageSelection',
            data: {
                data: data,
            }
        })
            .then((response) => {
                commit('change_SCHMOD_motorVoltageSelection_storeMutation', response.data);
                if (state.schmod_motorVoltageSelection.length > 0) {
                    var firstIndex_whichMeetConditions = state.schmod_motorVoltageSelection.findIndex(i => (i.value == state.schmod_motorVoltage));
                    if (firstIndex_whichMeetConditions > -1) {
                        dispatch('change_SCHMOD_motorVoltage_storeAction', state.schmod_motorVoltageSelection[firstIndex_whichMeetConditions]);
                    }
                    else {
                        firstIndex_whichMeetConditions = state.schmod_motorVoltageSelection.findIndex(i => (i.selected == true));
                        if (firstIndex_whichMeetConditions > -1) {
                            dispatch('change_SCHMOD_motorVoltage_storeAction', state.schmod_motorVoltageSelection[firstIndex_whichMeetConditions]);
                        }
                        else {
                            this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
                        }

                    }
                }
            })
            .catch(() => {
                this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
            })
            .then(() => {
                if (state.schmod_inverterTypeSelection == null || state.schmod_inverterTypeSelection.length == 0) {
                    this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
                }
                this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });

            })
    },
    async get_SCHMOD_PSGSizeSelection_storeAction({ commit, dispatch }) {
        this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: true, reasonWhy_loaderModal_Shown: 'data_BeingLoaded' });

        const data = {
            prodAp: state.schmod_ProAp,
            voltage: state.schmod_motorVoltage,
        }

        await axios({
            method: 'post',
            url: '/api/inputsManual/get_SCHMOD_PSGSizeSelection',
            data: {
                data: data,
            }
        })
            .then((response) => {
                commit('change_SCHMOD_PSGSizeSelection_storeMutation', response.data);
                if (state.schmod_PSGSizeSelection.length > 0) {
                    var firstIndex_whichMeetConditions = state.schmod_PSGSizeSelection.findIndex(i => (i.text.text == state.schmod_PSGSize));
                    if (firstIndex_whichMeetConditions > -1) {
                        dispatch('change_SCHMOD_PSGSize_storeAction', state.schmod_PSGSizeSelection[firstIndex_whichMeetConditions]);
                    }
                    else {
                        firstIndex_whichMeetConditions = state.schmod_PSGSizeSelection.findIndex(i => (i.selected == true));
                        if (firstIndex_whichMeetConditions > -1) {
                            dispatch('change_SCHMOD_PSGSize_storeAction', state.schmod_PSGSizeSelection[firstIndex_whichMeetConditions]);
                        }
                        else {
                            this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
                        }

                    }
                }
            })
            .catch(() => {
                this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
            })
            .then(() => {
                if (state.schmod_PSGSizeSelection == null || state.schmod_PSGSizeSelection.length == 0) {
                    this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
                }
                this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });

            })
    },
    async get_SCHMOD_MotorIDSelection_storeAction({ commit, dispatch }) {
        if (state.schmod_ProAp != null && state.schmod_motorVoltage != null) {
            this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: true, reasonWhy_loaderModal_Shown: 'data_BeingLoaded' });

            const data = {
                prodAp: state.schmod_ProAp,
                voltage: state.schmod_motorVoltage,
                pSG_Size: state.schmod_PSGSize,
            }

            await axios({
                method: 'post',
                url: '/api/inputsManual/get_SCHMOD_MotorIDSelection',
                data: {
                    data: data,
                }
            })
                .then((response) => {
                    commit('change_SCHMOD_motorIDSelection_storeMutation', response.data);
                    if (state.schmod_motorIDSelection.length > 0) {
                        var firstIndex_whichMeetConditions = state.schmod_motorIDSelection.findIndex(i => (i.text.text == state.schmod_motorID));
                        if (firstIndex_whichMeetConditions > -1) {
                            dispatch('change_SCHMOD_motorID_storeAction', state.schmod_motorIDSelection[firstIndex_whichMeetConditions]);
                        }
                        else {
                            firstIndex_whichMeetConditions = state.schmod_motorIDSelection.findIndex(i => (i.selected == true));
                            if (firstIndex_whichMeetConditions > -1) {
                                dispatch('change_SCHMOD_motorID_storeAction', state.schmod_motorIDSelection[firstIndex_whichMeetConditions]);
                            }
                            else {
                                this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
                            }

                        }
                    }
                })
                .catch(() => {
                    this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
                })
                .then(() => {
                    if (state.schmod_motorIDSelection == null || state.schmod_motorIDSelection.length == 0) {
                        this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
                    }
                    this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });

                })
        }
    },
    async get_SCHMOD_MotorCOSNameSelection_storeAction({ commit, dispatch }) {
        if (state.schmod_ProAp != null && state.schmod_motorVoltage != null) {
            this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: true, reasonWhy_loaderModal_Shown: 'data_BeingLoaded' });

            const data = {
                prodAp: state.schmod_ProAp,
                voltage: state.schmod_motorVoltage,
                pSG_Size: state.schmod_PSGSize,
                cosnam: state.schmod_motorID,
            }

            await axios({
                method: 'post',
                url: '/api/inputsManual/get_SCHMOD_MotorCOSNameSelection',
                data: {
                    data: data,
                }
            })
                .then((response) => {
                    commit('change_SCHMOD_motorCOSNameSelection_storeMutation', response.data);
                    if (state.schmod_motorCOSNameSelection.length > 0) {
                        var firstIndex_whichMeetConditions = state.schmod_motorCOSNameSelection.findIndex(i => (i.text.text == state.schmod_motorCOSName));
                        if (firstIndex_whichMeetConditions > -1) {
                            dispatch('change_SCHMOD_motorCosName_storeAction', state.schmod_motorCOSNameSelection[firstIndex_whichMeetConditions]);
                        }
                        else {
                            firstIndex_whichMeetConditions = state.schmod_motorCOSNameSelection.findIndex(i => (i.selected == true));
                            if (firstIndex_whichMeetConditions > -1) {
                                dispatch('change_SCHMOD_motorCosName_storeAction', state.schmod_motorCOSNameSelection[firstIndex_whichMeetConditions]);
                            }
                            else {
                                this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
                            }

                        }
                    }
                })
                .catch(() => {
                    this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
                })
                .then(() => {
                    if (state.schmod_motorCOSNameSelection == null || state.schmod_motorCOSNameSelection.length == 0) {
                        this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
                    }
                    this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });

                })
        }
    },
    async get_SCHMOD_InverterTypeSelection_storeAction({ commit, dispatch }) {
        this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: true, reasonWhy_loaderModal_Shown: 'data_BeingLoaded' });

        const data = {
            locationProduction: state.schmod_locationProduction,
            prodAp: state.schmod_ProAp,
        }

        await axios({
            method: 'post',
            url: '/api/inputsManual/get_SCHMOD_InverterTypeSelection',
            data: {
                data: data,
            }
        })
            .then((response) => {
                commit('change_SCHMOD_inverterTypeSelection_storeMutation', response.data);
                if (state.schmod_inverterTypeSelection.length > 0) {
                    var firstIndex_whichMeetConditions = state.schmod_inverterTypeSelection.findIndex(i => (i.text.text == state.schmod_inverterType));
                    if (firstIndex_whichMeetConditions > -1) {
                        dispatch('change_SCHMOD_inverterType_storeAction', state.schmod_inverterTypeSelection[firstIndex_whichMeetConditions]);
                    }
                    else {
                        firstIndex_whichMeetConditions = state.schmod_inverterTypeSelection.findIndex(i => (i.selected == true));
                        if (firstIndex_whichMeetConditions > -1) {
                            dispatch('change_SCHMOD_inverterType_storeAction', state.schmod_inverterTypeSelection[firstIndex_whichMeetConditions]);
                        }
                        else {
                            this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
                        }

                    }
                }
            })
            .catch(() => {
                this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
            })
            .then(() => {
                if (state.schmod_inverterTypeSelection == null || state.schmod_inverterTypeSelection.length == 0) {
                    this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
                }
                this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });

            })
    },
    async get_SCHMOD_CableLengthBrakeSelection_storeAction({ commit, dispatch }) {
        this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: true, reasonWhy_loaderModal_Shown: 'data_BeingLoaded' });

        const data = {
            prodAp: state.schmod_ProAp,
        }

        await axios({
            method: 'post',
            url: '/api/inputsManual/get_SCHMOD_CableLengthBrakeSelection',
            data: {
                data: data,
            }
        })
            .then((response) => {
                commit('change_SCHMOD_cableLengthBrakeSelection_storeMutation', response.data);
                if (state.schmod_cableLengthBrakeSelection.length > 0) {
                    var firstIndex_whichMeetConditions = state.schmod_cableLengthBrakeSelection.findIndex(i => (i.text.text == state.schmod_cableLengthBrake));
                    if (firstIndex_whichMeetConditions > -1) {
                        dispatch('change_SCHMOD_cableLengthBrake_storeAction', state.schmod_cableLengthBrakeSelection[firstIndex_whichMeetConditions]);
                    }
                    else {
                        firstIndex_whichMeetConditions = state.schmod_cableLengthBrakeSelection.findIndex(i => (i.selected == true));
                        if (firstIndex_whichMeetConditions > -1) {
                            dispatch('change_SCHMOD_cableLengthBrake_storeAction', state.schmod_cableLengthBrakeSelection[firstIndex_whichMeetConditions]);
                        }
                        else {
                            this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
                        }

                    }
                }
            })
            .catch(() => {
                this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
            })
            .then(() => {
                if (state.schmod_cableLengthBrakeSelection == null || state.schmod_cableLengthBrakeSelection.length == 0) {
                    this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
                }
                this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });

            })
    },
    async get_SCHMOD_GrooveSelection_storeAction({ commit, dispatch }) {
        this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: true, reasonWhy_loaderModal_Shown: 'data_BeingLoaded' });

        const data = {
            prodAp: state.schmod_ProAp,
        }

        await axios({
            method: 'post',
            url: '/api/inputsManual/get_SCHMOD_GrooveSelection',
            data: {
                data: data,
            }
        })
            .then((response) => {
                commit('change_SCHMOD_grooveSelection_storeMutation', response.data);
                const firstIndex_whichMeetConditions = state.schmod_grooveSelection.findIndex(i => (i.selected == true));
                if (firstIndex_whichMeetConditions > -1) {
                    dispatch('change_SCHMOD_grooves_storeAction', state.schmod_grooveSelection[firstIndex_whichMeetConditions]);
                }
                else {
                    this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
                }

            })
            .catch(() => {
                this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
            })
            .then(() => {
                if (state.schmod_grooveSelection == null || state.schmod_grooveSelection.length == 0) {
                    this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
                }
                this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });

            })
    },

    change_SCHMOD_locationProduction_storeAction({ commit, dispatch }, value) {
        commit('change_SCHMOD_locationProduction_storeMutation', value.text.text);
        //dispatch('get_SCHMOD_ProdApSelection_storeAction');
        dispatch('checkIf_SCHMOD_inputsManual_areValid_storeAction');
    },
    change_SCHMOD_ProAp_storeAction({ commit, dispatch }, value) {
        commit('change_SCHMOD_ProAp_storeMutation', value.text.text);
        const firstIndex_whichMeetConditions_prodAp = state.schmod_ProdApSelection.findIndex(i => (i.selected == true));
        if (firstIndex_whichMeetConditions_prodAp > -1) {
            dispatch('get_SCHMOD_MotorVoltageSelection_storeAction');
            dispatch('get_SCHMOD_InverterTypeSelection_storeAction');
            dispatch('get_SCHMOD_CableLengthBrakeSelection_storeAction');
            dispatch('get_SCHMOD_GrooveSelection_storeAction');
        }
        commit('change_SCHMOD_PSGSize_storeMutation', null);
        commit('change_SCHMOD_motorID_storeMutation', null);
        commit('change_SCHMOD_motorCosName_storeMutation', null);

        dispatch('checkIf_SCHMOD_inputsManual_areValid_storeAction');
    },
    async change_SCHMOD_PSGSize_storeAction({ commit, dispatch }, value) {
        if (state.schmod_ProAp != null && state.schmod_motorVoltage != null && value != null && value.hasOwnProperty('text') && value.text.text != null) {
            var filter_ProApAndUsNomAndPSGSize = state.schModDetails.filter(i => (i.prodap == state.schmod_ProAp && i.usNom == state.schmod_motorVoltage && i.psgglo == value.text.text));
            if (filter_ProApAndUsNomAndPSGSize != null && filter_ProApAndUsNomAndPSGSize.length > 0) {
                commit('change_SCHMOD_PSGSize_storeMutation', value.text.text);
                await dispatch('get_SCHMOD_MotorIDSelection_storeAction')
                if (state.schmod_motorID != null) {
                    filter_ProApAndUsNomAndPSGSize = filter_ProApAndUsNomAndPSGSize.filter(i => (i.cosnam == state.schmod_motorID));
                    if (filter_ProApAndUsNomAndPSGSize != null && filter_ProApAndUsNomAndPSGSize.length > 0) {
                    }
                    else {
                        commit('change_SCHMOD_motorID_storeMutation', null);
                    }
                    await dispatch('get_SCHMOD_MotorCOSNameSelection_storeAction')
                }
                else {
                    await dispatch('get_SCHMOD_MotorCOSNameSelection_storeAction')
                }
                if (state.schmod_motorCOSName != null) {
                    filter_ProApAndUsNomAndPSGSize = filter_ProApAndUsNomAndPSGSize.filter(i => (i.psgnam == state.schmod_motorCOSName));
                    if (filter_ProApAndUsNomAndPSGSize != null && filter_ProApAndUsNomAndPSGSize.length > 0) {
                    }
                    else {
                        commit('change_SCHMOD_motorCosName_storeMutation', null);
                    }
                }
            }
            else {
                commit('change_SCHMOD_PSGSize_storeMutation', null);
                await dispatch('get_SCHMOD_MotorIDSelection_storeAction')
                await dispatch('get_SCHMOD_MotorCOSNameSelection_storeAction')
            }
        }
        else {
            commit('change_SCHMOD_PSGSize_storeMutation', null);

            await dispatch('get_SCHMOD_MotorIDSelection_storeAction')
            await dispatch('get_SCHMOD_MotorCOSNameSelection_storeAction')
        }
        dispatch('checkIf_SCHMOD_inputsManual_areValid_storeAction');
    },
    async change_SCHMOD_motorID_storeAction({ commit, dispatch }, value) {
        if (state.schmod_ProAp != null && state.schmod_motorVoltage != null && value != null && value.hasOwnProperty('text') && value.text.text != null) {
            var filter_ProApAndUsNom = state.schModDetails.filter(i => (i.prodap == state.schmod_ProAp && i.usNom == state.schmod_motorVoltage && i.cosnam == value.text.text));
            if (filter_ProApAndUsNom != null && filter_ProApAndUsNom.length > 0) {
                commit('change_SCHMOD_motorID_storeMutation', value.text.text);
                await dispatch('get_SCHMOD_MotorCOSNameSelection_storeAction')
                const filter_PSGSize = structuredClone(filter_ProApAndUsNom)
                if (state.schmod_motorCOSName != null) {
                    filter_ProApAndUsNom = filter_ProApAndUsNom.filter(i => (i.psgnam == state.schmod_motorCOSName));
                    if (filter_ProApAndUsNom != null && filter_ProApAndUsNom.length > 0) {
                    }
                    else {
                        commit('change_SCHMOD_motorCosName_storeMutation', null);
                    }
                }
                if (state.schmod_PSGSize != null) {
                    filter_ProApAndUsNom = filter_ProApAndUsNom.filter(i => (i.psgglo == state.schmod_PSGSize));
                    if (filter_ProApAndUsNom != null && filter_ProApAndUsNom.length > 0) {
                    }
                    else {
                        if (filter_ProApAndUsNom != null && filter_ProApAndUsNom.length > 0) {
                            commit('change_SCHMOD_PSGSize_storeMutation', filter_ProApAndUsNom[0].psgglo);
                        }
                        else {
                            if (filter_PSGSize != null && filter_PSGSize.length > 0) {
                                commit('change_SCHMOD_PSGSize_storeMutation', filter_PSGSize[0].psgglo);
                            }
                            else {
                                commit('change_SCHMOD_PSGSize_storeMutation', null);
                            }
                        }
                    }
                }
                else {
                    if (filter_ProApAndUsNom != null && filter_ProApAndUsNom.length > 0) {
                        commit('change_SCHMOD_PSGSize_storeMutation', filter_ProApAndUsNom[0].psgglo);
                    }
                    else {
                        if (filter_PSGSize != null && filter_PSGSize.length > 0) {
                            commit('change_SCHMOD_PSGSize_storeMutation', filter_PSGSize[0].psgglo);
                        }
                    }
                }
            }
            else {
                commit('change_SCHMOD_motorID_storeMutation', null);
                await dispatch('get_SCHMOD_MotorCOSNameSelection_storeAction')
            }
        }
        else {
            commit('change_SCHMOD_motorID_storeMutation', null);
            await dispatch('get_SCHMOD_MotorCOSNameSelection_storeAction')
        }

        dispatch('checkIf_SCHMOD_inputsManual_areValid_storeAction');
    },
    change_SCHMOD_motorCosName_storeAction({ commit, dispatch }, value) {
        if (state.schmod_ProAp != null && state.schmod_motorVoltage != null && value != null && value.hasOwnProperty('text') && value.text.text != null) {
            var filter_ProApAndUsNom = state.schModDetails.filter(i => (i.prodap == state.schmod_ProAp && i.usNom == state.schmod_motorVoltage && i.psgnam == value.text.text));
            if (filter_ProApAndUsNom != null && filter_ProApAndUsNom.length > 0) {
                commit('change_SCHMOD_motorCosName_storeMutation', value.text.text);
                const filter_PSGSize = structuredClone(filter_ProApAndUsNom)
                if (state.schmod_motorID != null) {
                    filter_ProApAndUsNom = filter_ProApAndUsNom.filter(i => (i.cosnam == state.schmod_motorID));
                    if (filter_ProApAndUsNom != null && filter_ProApAndUsNom.length > 0) {
                    }
                    else {
                        if (filter_PSGSize != null && filter_PSGSize.length > 0) {
                            commit('change_SCHMOD_motorID_storeMutation', filter_PSGSize[0].cosnam);
                            filter_ProApAndUsNom = filter_ProApAndUsNom.filter(i => (i.cosnam == filter_PSGSize[0].cosnam));
                        }
                        else {
                            commit('change_SCHMOD_motorID_storeMutation', null);
                        }
                    }
                }
                else {
                    if (filter_ProApAndUsNom != null && filter_ProApAndUsNom.length > 0) {
                        commit('change_SCHMOD_motorID_storeMutation', filter_ProApAndUsNom[0].cosnam);
                        filter_ProApAndUsNom = filter_ProApAndUsNom.filter(i => (i.cosnam == filter_ProApAndUsNom[0].cosnam));
                    }
                    else {
                        commit('change_SCHMOD_motorID_storeMutation', null);
                    }
                }
                if (state.schmod_PSGSize != null) {
                    filter_ProApAndUsNom = filter_ProApAndUsNom.filter(i => (i.psgglo == state.schmod_PSGSize));
                    if (filter_ProApAndUsNom != null && filter_ProApAndUsNom.length > 0) {
                    }
                    else {
                        if (filter_ProApAndUsNom != null && filter_ProApAndUsNom.length > 0) {
                            commit('change_SCHMOD_PSGSize_storeMutation', filter_ProApAndUsNom[0].psgglo);
                        }
                        else {
                            if (filter_PSGSize != null && filter_PSGSize.length > 0) {
                                commit('change_SCHMOD_PSGSize_storeMutation', filter_PSGSize[0].psgglo);
                            }
                            else {
                                commit('change_SCHMOD_PSGSize_storeMutation', null);
                            }
                        }
                    }
                }
                else {
                    if (filter_ProApAndUsNom != null && filter_ProApAndUsNom.length > 0) {
                        commit('change_SCHMOD_PSGSize_storeMutation', filter_ProApAndUsNom[0].psgglo);
                    }
                    else {
                        if (filter_PSGSize != null && filter_PSGSize.length > 0) {
                            commit('change_SCHMOD_PSGSize_storeMutation', filter_PSGSize[0].psgglo);
                        }
                    }
                }
            }
            else {
                commit('change_SCHMOD_motorCosName_storeMutation', null);
            }
        }
        else {
            commit('change_SCHMOD_motorCosName_storeMutation', null);
        }
        dispatch('checkIf_SCHMOD_inputsManual_areValid_storeAction');
    },
    change_SCHMOD_motorVoltage_storeAction({ commit, dispatch }, value) {
        commit('change_SCHMOD_motorVoltage_storeMutation', value.value);
        //const firstIndex_whichMeetConditions_prodAp = state.schmod_ProdApSelection.findIndex(i => (i.selected == true));
        //const firstIndex_whichMeetConditions_voltage = state.schmod_motorVoltageSelection.findIndex(i => (i.selected == true));
        //if (firstIndex_whichMeetConditions_prodAp > -1 && firstIndex_whichMeetConditions_voltage > -1) {
        //    dispatch('get_SCHMOD_PSGSizeSelection_storeAction')
        //}
        commit('change_SCHMOD_PSGSize_storeMutation', null);
        commit('change_SCHMOD_motorID_storeMutation', null);
        commit('change_SCHMOD_motorCosName_storeMutation', null);
        dispatch('get_SCHMOD_PSGSizeSelection_storeAction');

        dispatch('checkIf_SCHMOD_inputsManual_areValid_storeAction');
    },
    change_SCHMOD_inverterType_storeAction({ commit, dispatch }, value) {
        commit('change_SCHMOD_inverterType_storeMutation', value.text.text);
        dispatch('checkIf_SCHMOD_inputsManual_areValid_storeAction');
    },
    change_SCHMOD_cableLength_storeAction({ commit, dispatch }, value) {
        commit('change_SCHMOD_cableLength_storeMutation', value.text.text);
        dispatch('checkIf_SCHMOD_inputsManual_areValid_storeAction');
    },
    change_SCHMOD_cableLengthBrake_storeAction({ commit, dispatch }, value) {
        commit('change_SCHMOD_cableLengthBrake_storeMutation', value.text.text);
        dispatch('checkIf_SCHMOD_inputsManual_areValid_storeAction');
    },
    change_SCHMOD_handWheel_storeAction({ commit, dispatch }, value) {
        commit('change_SCHMOD_handWheel_storeMutation', value.text.text);
        dispatch('checkIf_SCHMOD_inputsManual_areValid_storeAction');
    },
    change_SCHMOD_handRelease_storeAction({ commit, dispatch }, value) {
        commit('change_SCHMOD_handRelease_storeMutation', value.text.text);
        dispatch('checkIf_SCHMOD_inputsManual_areValid_storeAction');
    },
    change_SCHMOD_grooveDistance_storeAction({ commit, dispatch }, value) {
        if (value != null && value.trim() != '') {
            const valueAndValueValidationCriteria = this._vm.$transmit_valueAndValueValidationCriteria;
            valueAndValueValidationCriteria.value = value;
            valueAndValueValidationCriteria.dataType = this._vm.$dataTypes.integer;
            valueAndValueValidationCriteria.permissibleNumberOfDecimalPlaces = null;
            valueAndValueValidationCriteria.permissibleValueRange.minimum.value = 20;
            valueAndValueValidationCriteria.permissibleValueRange.minimum.operator = this._vm.$operators.greaterThan;
            valueAndValueValidationCriteria.permissibleValueRange.minimum.value = 300;
            valueAndValueValidationCriteria.permissibleValueRange.minimum.operator = this._vm.$operators.lessThanOrEqualTo;
            const schmod_grooveDistance_ValidationResult = this._vm.$checkIf_value_isValid(valueAndValueValidationCriteria);

            commit('change_SCHMOD_grooveDistance_storeMutation', schmod_grooveDistance_ValidationResult);
        }
        else {
            commit('change_SCHMOD_grooveDistance_storeMutation', { value: null, invalid: null });
        }
        dispatch('checkIf_SCHMOD_inputsManual_areValid_storeAction');
    },
    change_SCHMOD_grooves_storeAction({ commit, dispatch }, value) {
        commit('change_SCHMOD_groove_storeMutation', value.text.text);
        dispatch('checkIf_SCHMOD_inputsManual_areValid_storeAction');
    },
    change_SCHMOD_numberOfGrooves_storeAction({ commit, dispatch }, value) {
        commit('change_SCHMOD_numberOfGrooves_storeMutation', value.text.text);
        dispatch('checkIf_SCHMOD_inputsManual_areValid_storeAction');
    },
    change_SCHMOD_certification_storeAction({ commit, dispatch }, value) {
        commit('change_SCHMOD_certification_storeMutation', value.text.text);
        dispatch('checkIf_SCHMOD_inputsManual_areValid_storeAction');
    },

    async insertOrUpdate_schmod_storeAction({ rootState, commit, dispatch }) {
        this.dispatch('cables/change_cablesSelection_toDefault_storeAction');
        this.dispatch('packagingMotorManual/change_packagingMotor_toDefault_storeAction');
        this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: true, reasonWhy_loaderModal_Shown: 'data_BeingLoaded' });

        var schmodGrooveDistance = null;
        if (state.schmod_grooveDistance != null && state.schmod_grooveDistance.invalid == false) {
            schmodGrooveDistance = state.schmod_grooveDistance.value;
        }

        var schmodMIb1 = null;
        if (state.operatingCurrentSplus != null && state.operatingCurrentSplus.invalid == false) {
            schmodMIb1 = state.operatingCurrentSplus.value;
        }

        const pSG_Variante = {
            produktionsort: state.schmod_locationProduction,
            zertifizierung: state.schmod_certification,
            prodAP: state.schmod_ProAp,
            voltage: state.schmod_motorVoltage,
            pSG_GlobalID_BG: state.schmod_PSGSize,
            motorID: state.schmod_motorID,
            pSGNAM: state.schmod_motorCOSName,
            pSG_Reglerart: state.schmod_inverterType,
            pSG_Kabellaenge: state.schmod_cableLength,
            pSG_KabellaengeBremse: state.schmod_cableLengthBrake,
            pSG_Handradanbau: state.schmod_handWheel,
            pSG_Handlueftung: state.schmod_handRelease,
            rillenabstand: schmodGrooveDistance,
            rillenform: state.schmod_groove,
            pSG_No_of_rope_Grooves: state.schmod_numberOfGrooves,
            mIb1: schmodMIb1,
        }

        this.dispatch('inputsManual/set_inputManual_Motor_toDefault_storeAction');
        this.dispatch('inputsManual/set_inputManual_TractionSheave_toDefault_storeAction');
        this.dispatch('inputsManual/set_inputManual_RopeGuard_toDefault_storeAction');

        const configurationStepsManualId = state.configurationStepsManualId;

        const data = {
            pSG_Variante: pSG_Variante,
            configurationStepsManualId: configurationStepsManualId,
        }

        await axios({
            method: 'post',
            url: '/api/inputsManual/insertOrUpdate_schmod',
            data: {
                data: data,
            }
        })
            .then((response) => {
                this.dispatch('statusHandling/change_dataAndStatus_storeAction', response.data);
                if (response.data.status.statusCode.substring(0, 1) == '2') {
                    //this.commit('configurations/change_configurationOverviewsManualId_storeMutation', response.data.data.configurationOverviewsManualId)
                    this.dispatch('inputsManual/change_configurationStepsManualId_storeAction', response.data.data.configurationStepsManualId);

                    //dispatch('set_inputsManual_storeAction');
                    dispatch('set_inputManual_SCHMOD_toDefault_storeAction');

                    this.dispatch('inputsManual/get_SCHMOD_customerNameSelection_storeAction')

                }
                else {
                    this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
                }

            })
            .catch(() => {
                this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
            })
            .then(() => {
            })
    },


    async get_SCHMOD_Motordetails_storeAction({ rootState, commit, dispatch }) {
        const configurationStepsManualId = rootState.inputsManual.configurationStepsManualId;
        if (configurationStepsManualId != null) {
            const data = {
                kid: rootState.inputsManual.customerName,
                temperatureMonitoring: rootState.inputsManual.temperatureMonitoring,
                mLS_Law: rootState.inputsManual.certification,
                preference: rootState.inputsManual.preference,
                sM250_TS280: rootState.inputsManual.sM250_TS280,
                configurationStepsManualId: configurationStepsManualId,
            }

            await axios({
                method: 'post',
                url: '/api/inputsManual/get_SCHMOD_Motordetails',
                data: {
                    data: data,
                }
            })
                .then((response) => {
                    this.dispatch('statusHandling/change_dataAndStatus_storeAction', response.data);
                    if (response.data.status.statusCode.substring(0, 1) == '2') {
                        if (response.data.data.motordetails.motorTypeSelection != null) {
                            this.commit('inputsManual/change_motorTypeSelection_storeMutation', response.data.data.motordetails.motorTypeSelection);
                            const firstIndex_whichMeetConditions = rootState.inputsManual.motorTypeSelection.findIndex(i => (i.selected == true));
                            if (firstIndex_whichMeetConditions > -1) {
                                this.dispatch('inputsManual/change_motorType_storeAction', rootState.inputsManual.motorTypeSelection[firstIndex_whichMeetConditions]);
                            }
                        } else {
                            this.commit('inputsManual/change_motorTypeSelection_storeMutation', []);
                        }
                        if (response.data.data.motordetails.motorSizeSelection != null) {
                            this.commit('inputsManual/change_motorSizeSelection_storeMutation', response.data.data.motordetails.motorSizeSelection);
                            const firstIndex_whichMeetConditions = rootState.inputsManual.motorSizeSelection.findIndex(i => (i.selected == true));
                            if (firstIndex_whichMeetConditions > -1) {
                                this.dispatch('inputsManual/change_motorSize_storeAction', rootState.inputsManual.motorSizeSelection[firstIndex_whichMeetConditions]);
                            }
                        } else {
                            this.commit('inputsManual/change_motorSizeSelection_storeMutation', []);
                        }
                        if (response.data.data.motordetails.motorPackageSelection != null) {
                            this.commit('inputsManual/change_motorPackageSelection_storeMutation', response.data.data.motordetails.motorPackageSelection);
                            const firstIndex_whichMeetConditions = rootState.inputsManual.motorPackageSelection.findIndex(i => (i.selected == true));
                            if (firstIndex_whichMeetConditions > -1) {
                                this.dispatch('inputsManual/change_motorPackage_storeAction', rootState.inputsManual.motorPackageSelection[firstIndex_whichMeetConditions]);
                            }
                        } else {
                            this.commit('inputsManual/change_motorPackageSelection_storeMutation', []);
                        }
                        if (response.data.data.motordetails.motorZusatzSelection != null) {
                            this.commit('inputsManual/change_motorZusatzSelection_storeMutation', response.data.data.motordetails.motorZusatzSelection);
                            const firstIndex_whichMeetConditions = rootState.inputsManual.motorZusatzSelection.findIndex(i => (i.selected == true));
                            if (firstIndex_whichMeetConditions > -1) {
                                this.dispatch('inputsManual/change_motorZusatz_storeAction', rootState.inputsManual.motorZusatzSelection[firstIndex_whichMeetConditions]);
                            }
                        } else {
                            this.commit('inputsManual/change_motorZusatzSelection_storeMutation', []);
                        }
                        if (response.data.data.motordetails.motorVoltageSelection != null) {
                            this.commit('inputsManual/change_motorVoltageSelection_storeMutation', response.data.data.motordetails.motorVoltageSelection);
                            const firstIndex_whichMeetConditions = rootState.inputsManual.motorVoltageSelection.findIndex(i => (i.selected == true));
                            if (firstIndex_whichMeetConditions > -1) {
                                this.dispatch('inputsManual/change_motorVoltage_storeAction', rootState.inputsManual.motorVoltageSelection[firstIndex_whichMeetConditions]);
                            }
                        } else {
                            this.commit('inputsManual/change_motorVoltageSelection_storeMutation', []);
                        }
                        if (response.data.data.motordetails.motorRatedSpeedSelection != null) {
                            this.commit('inputsManual/change_motorRatedSpeedSelection_storeMutation', response.data.data.motordetails.motorRatedSpeedSelection);
                            const firstIndex_whichMeetConditions = rootState.inputsManual.motorRatedSpeedSelection.findIndex(i => (i.selected == true));
                            if (firstIndex_whichMeetConditions > -1) {
                                this.dispatch('inputsManual/change_motorRatedSpeed_storeAction', rootState.inputsManual.motorRatedSpeedSelection[firstIndex_whichMeetConditions]);
                            }
                        } else {
                            this.commit('inputsManual/change_motorRatedSpeedSelection_storeMutation', []);
                        }

                        //this.dispatch('inputsManual/get_upgradeSelection_storeAction');
                    }
                    else {
                        this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
                    }
                })
                .catch(() => {
                    this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
                })
                .then(() => {
                    this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
                })
        }
        else {
            this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
        }
    },

    set_inputManual_SCHMOD_toDefault_storeAction({ commit }) {
        //commit('change_SCHMOD_onok_storeMutation', null);
        //commit('change_SCHMOD_inok_storeMutation', null);
        commit('change_SCHMOD_locationProductionSelection_storeMutation', []);
        commit('change_SCHMOD_ProdApSelection_storeMutation', []);
        commit('change_SCHMOD_PSGSizeSelection_storeMutation', []);
        commit('change_SCHMOD_motorIDSelection_storeMutation', []);
        commit('change_SCHMOD_motorCOSNameSelection_storeMutation', []);
        commit('change_SCHMOD_motorVoltageSelection_storeMutation', []);
        commit('change_SCHMOD_inverterTypeSelection_storeMutation', []);
        commit('change_SCHMOD_cableLengthSelection_storeMutation', []);
        commit('change_SCHMOD_cableLengthBrakeSelection_storeMutation', []);
        commit('change_SCHMOD_handWheelSelection_storeMutation', []);
        commit('change_SCHMOD_handReleaseSelection_storeMutation', []);
        commit('change_SCHMOD_grooveSelection_storeMutation', []);
        commit('change_SCHMOD_numberOfGroovesSelection_storeMutation', []);
        commit('change_SCHMOD_locationProduction_storeMutation', null);
        commit('change_SCHMOD_ProAp_storeMutation', null);
        commit('change_SCHMOD_PSGSize_storeMutation', null);
        commit('change_SCHMOD_motorID_storeMutation', null);
        commit('change_SCHMOD_motorCosName_storeMutation', null);
        commit('change_SCHMOD_motorVoltage_storeMutation', null);
        commit('change_SCHMOD_inverterType_storeMutation', null);
        commit('change_SCHMOD_cableLength_storeMutation', null);
        commit('change_SCHMOD_cableLengthBrake_storeMutation', null);
        commit('change_SCHMOD_handWheel_storeMutation', null);
        commit('change_SCHMOD_handRelease_storeMutation', null);
        commit('change_SCHMOD_grooveDistance_storeMutation', { value: null, invalid: null });
        commit('change_SCHMOD_groove_storeMutation', null);
        commit('change_SCHMOD_numberOfGrooves_storeMutation', null);

    },

    checkIf_changesAt_configurationFlow_storeAction({ rootState, dispatch }) {
        if (rootState.configurations.navigationManual.inputsManual == "passed") {
            //this.dispatch('inputsManual/delete_configuration_storeAction');
        }
    },

    async get_SCHMOD_tractionSheavedetails_storeAction({ rootState, commit, dispatch }) {
        const configurationStepsManualId = rootState.inputsManual.configurationStepsManualId;
        var upgrade = null;
        const firstIndex_whichMeetConditions_upgrade = rootState.inputsManual.upgradeSelection.findIndex(i => (i.selected == true));
        if (firstIndex_whichMeetConditions_upgrade > -1) {
            upgrade = rootState.inputsManual.upgradeSelection[firstIndex_whichMeetConditions_upgrade].text;
        }

        const data = {
            motorType: rootState.inputsManual.motorType,
            motorSize: rootState.inputsManual.motorSize,
            motorPackage: rootState.inputsManual.motorPackage,
            motorZusatz: rootState.inputsManual.motorZusatz,
            motorVoltage: rootState.inputsManual.motorVoltage,
            motorRatedSpeed: rootState.inputsManual.motorRatedSpeed,
            temperatureMonitoring: rootState.inputsManual.temperatureMonitoring,
            upgrade: upgrade,
            tractionSheaveDiameter: rootState.inputsManual.tractionsheave_DiameterAndWidth.diameter,
            tractionSheaveWidth: rootState.inputsManual.tractionsheave_DiameterAndWidth.width,
            motorLink: rootState.inputsManual.tractionsheave_DiameterAndWidth.motorLink,
            kid: rootState.inputsManual.customerName,
            lTHD: rootState.inputsManual.machiningForHandWheel,
            configurationStepsManualId: configurationStepsManualId,
        }

        await axios({
            method: 'post',
            url: '/api/inputsManual/get_SCHMOD_tractionSheavedetails',
            data: {
                data: data,
            }
        })
            .then((response) => {
                this.dispatch('statusHandling/change_dataAndStatus_storeAction', response.data);
                if (response.data.status.statusCode.substring(0, 1) == '2') {
                    if (response.data.data.tractionSheavedetails.tractionSheaveProfilesSelection != null) {
                        this.commit('inputsManual/change_sheaveProfileSelection_storeMutation', response.data.data.tractionSheavedetails.tractionSheaveProfilesSelection);
                        const firstIndex_whichMeetConditions = rootState.inputsManual.sheaveProfileSelection.findIndex(i => (i.selected == true));
                        if (firstIndex_whichMeetConditions > -1) {
                            this.dispatch('inputsManual/change_sheaveProfile_storeAction', rootState.inputsManual.sheaveProfileSelection[firstIndex_whichMeetConditions]);
                        }
                    } else {
                        this.commit('inputsManual/change_sheaveProfileSelection_storeMutation', []);
                    }
                    if (response.data.data.tractionSheavedetails.tractionSheaveUndercutAngleSelection != null) {
                        this.commit('inputsManual/change_undercutAngleSelection_storeMutation', response.data.data.tractionSheavedetails.tractionSheaveUndercutAngleSelection);
                        const firstIndex_whichMeetConditions = rootState.inputsManual.undercutAngleSelection.findIndex(i => (i.selected == true));
                        if (firstIndex_whichMeetConditions > -1) {
                            this.dispatch('inputsManual/change_undercutAngle_storeAction', rootState.inputsManual.undercutAngleSelection[firstIndex_whichMeetConditions]);
                        }
                    } else {
                        this.commit('inputsManual/change_undercutAngleSelection_storeMutation', []);
                    }
                    if (response.data.data.tractionSheavedetails.tractionSheaveVgrooveAngleSelection != null) {
                        this.commit('inputsManual/change_vgrooveAngleSelection_storeMutation', response.data.data.tractionSheavedetails.tractionSheaveVgrooveAngleSelection);
                        const firstIndex_whichMeetConditions = rootState.inputsManual.vgrooveAngleSelection.findIndex(i => (i.selected == true));
                        if (firstIndex_whichMeetConditions > -1) {
                            this.dispatch('inputsManual/change_vgrooveAngle_storeAction', rootState.inputsManual.vgrooveAngleSelection[firstIndex_whichMeetConditions]);
                        }
                    } else {
                        this.commit('inputsManual/change_vgrooveAngleSelection_storeMutation', []);
                    }
                    if (response.data.data.tractionSheavedetails.tractionSheaveRopeDiameterSelection != null) {
                        this.commit('inputsManual/change_ropeDiameterSelection_storeMutation', response.data.data.tractionSheavedetails.tractionSheaveRopeDiameterSelection);
                        const firstIndex_whichMeetConditions = rootState.inputsManual.ropeDiameterSelection.findIndex(i => (i.selected == true));
                        if (firstIndex_whichMeetConditions > -1) {
                            this.dispatch('inputsManual/change_ropeDiameter_storeAction', rootState.inputsManual.ropeDiameterSelection[firstIndex_whichMeetConditions]);
                        }
                    } else {
                        this.commit('inputsManual/change_ropeDiameterSelection_storeMutation', []);
                    }
                    if (response.data.data.tractionSheavedetails.tractionSheaveGrooveDistanceSelection != null) {
                        this.commit('inputsManual/change_grooveDistanceSelection_storeMutation', response.data.data.tractionSheavedetails.tractionSheaveGrooveDistanceSelection);
                        const firstIndex_whichMeetConditions = rootState.inputsManual.grooveDistanceSelection.findIndex(i => (i.selected == true));
                        if (firstIndex_whichMeetConditions > -1) {
                            this.dispatch('inputsManual/change_grooveDistance_storeAction', rootState.inputsManual.grooveDistanceSelection[firstIndex_whichMeetConditions]);
                        }
                    } else {
                        this.commit('inputsManual/change_grooveDistanceSelection_storeMutation', []);
                    }
                    if (response.data.data.tractionSheavedetails.tractionSheaveNumberOfRopesSelection != null) {
                        this.commit('inputsManual/change_numberOfGroovesSelection_storeMutation', response.data.data.tractionSheavedetails.tractionSheaveNumberOfRopesSelection);
                        const firstIndex_whichMeetConditions = rootState.inputsManual.numberOfGroovesSelection.findIndex(i => (i.selected == true));
                        if (firstIndex_whichMeetConditions > -1) {
                            this.dispatch('inputsManual/change_numberOfGrooves_storeAction', rootState.inputsManual.numberOfGroovesSelection[firstIndex_whichMeetConditions]);
                        }
                    } else {
                        this.commit('inputsManual/change_numberOfGroovesSelection_storeMutation', []);
                    }

                    //dispatch('get_upgradeSelection_storeAction');
                }
                else {
                }
            })
            .catch(() => {
            })
            .then(() => {
            })

    },


    checkIf_SCHMOD_inputsManual_areValid_storeAction({ rootState, dispatch, commit }) {
        var numberOf_inputs_whichAreInvalid = 0;
        if (state.schmod_locationProduction == null) {
            numberOf_inputs_whichAreInvalid = numberOf_inputs_whichAreInvalid + 1;
        }
        if (state.schmod_certification == null) {
            numberOf_inputs_whichAreInvalid = numberOf_inputs_whichAreInvalid + 1;
        }
        if (state.schmod_ProAp == null) {
            numberOf_inputs_whichAreInvalid = numberOf_inputs_whichAreInvalid + 1;
        }
        if (state.schmod_motorVoltage == null) {
            numberOf_inputs_whichAreInvalid = numberOf_inputs_whichAreInvalid + 1;
        }
        if (state.schmod_PSGSize == null) {
            numberOf_inputs_whichAreInvalid = numberOf_inputs_whichAreInvalid + 1;
        }
        if (state.schmod_motorID == null) {
            numberOf_inputs_whichAreInvalid = numberOf_inputs_whichAreInvalid + 1;
        }
        if (state.schmod_motorCOSName == null) {
            numberOf_inputs_whichAreInvalid = numberOf_inputs_whichAreInvalid + 1;
        }
        if (state.schmod_inverterType == null) {
            numberOf_inputs_whichAreInvalid = numberOf_inputs_whichAreInvalid + 1;
        }
        if (state.schmod_cableLength == null) {
            numberOf_inputs_whichAreInvalid = numberOf_inputs_whichAreInvalid + 1;
        }
        if (state.schmod_cableLengthBrake == null) {
            numberOf_inputs_whichAreInvalid = numberOf_inputs_whichAreInvalid + 1;
        }
        if (state.schmod_handWheel == null) {
            numberOf_inputs_whichAreInvalid = numberOf_inputs_whichAreInvalid + 1;
        }
        if (state.schmod_handRelease == null) {
            numberOf_inputs_whichAreInvalid = numberOf_inputs_whichAreInvalid + 1;
        }
        if (state.schmod_groove == null) {
            numberOf_inputs_whichAreInvalid = numberOf_inputs_whichAreInvalid + 1;
        }
        if (state.schmod_numberOfGrooves == null) {
            numberOf_inputs_whichAreInvalid = numberOf_inputs_whichAreInvalid + 1;
        }

        if (numberOf_inputs_whichAreInvalid > 0) {
            commit('change_schmod_Valid_storeMuation', false);
        }
        else {
            commit('change_schmod_Valid_storeMuation', true);
        }
    },
}

const mutations = {
    // SCH-MOD Modul
    change_SCHMOD_locationProductionSelection_storeMutation(state, payload) {
        //if (payload == null || payload.length == 0) {
        //    this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
        //}

        state.schmod_locationProductionSelection = payload;
    },
    change_SCHMOD_ProdApSelection_storeMutation(state, payload) {
        //if (payload == null || payload.length == 0) {
        //    this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
        //}

        state.schmod_ProdApSelection = payload;
    },
    change_SCHMOD_PSGSizeSelection_storeMutation(state, payload) {
        //if (payload == null || payload.length == 0) {
        //    this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
        //}

        state.schmod_PSGSizeSelection = payload;
    },
    change_SCHMOD_motorIDSelection_storeMutation(state, payload) {
        //if (payload == null || payload.length == 0) {
        //    this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
        //}

        state.schmod_motorIDSelection = payload;
    },
    change_SCHMOD_motorCOSNameSelection_storeMutation(state, payload) {
        //if (payload == null || payload.length == 0) {
        //    this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
        //}

        state.schmod_motorCOSNameSelection = payload;
    },
    change_SCHMOD_motorVoltageSelection_storeMutation(state, payload) {
        //if (payload == null || payload.length == 0) {
        //    this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
        //}

        state.schmod_motorVoltageSelection = payload;
    },
    change_SCHMOD_inverterTypeSelection_storeMutation(state, payload) {
        //if (payload == null || payload.length == 0) {
        //    this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
        //}

        state.schmod_inverterTypeSelection = payload;
    },
    change_SCHMOD_cableLengthSelection_storeMutation(state, payload) {
        //if (payload == null || payload.length == 0) {
        //    this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
        //}

        state.schmod_cableLengthSelection = payload;
    },
    change_SCHMOD_cableLengthBrakeSelection_storeMutation(state, payload) {
        //if (payload == null || payload.length == 0) {
        //    this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
        //}

        state.schmod_cableLengthBrakeSelection = payload;
    },
    change_SCHMOD_handWheelSelection_storeMutation(state, payload) {
        //if (payload == null || payload.length == 0) {
        //    this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
        //}

        state.schmod_handWheelSelection = payload;
    },
    change_SCHMOD_handReleaseSelection_storeMutation(state, payload) {
        //if (payload == null || payload.length == 0) {
        //    this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
        //}

        state.schmod_handReleaseSelection = payload;
    },
    change_SCHMOD_grooveSelection_storeMutation(state, payload) {
        //if (payload == null || payload.length == 0) {
        //    this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
        //}

        state.schmod_grooveSelection = payload;
    },
    change_SCHMOD_numberOfGroovesSelection_storeMutation(state, payload) {
        //if (payload == null || payload.length == 0) {
        //    this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
        //}

        state.schmod_numberOfGroovesSelection = payload;
    },
    change_SCHMOD_locationProduction_storeMutation(state, payload) {
        state.schmod_locationProduction = null;
        for (let i = 0; i < state.schmod_locationProductionSelection.length; i++) {
            state.schmod_locationProductionSelection[i].selected = false;

            if (state.schmod_locationProductionSelection[i].text.text == payload) {
                state.schmod_locationProduction = payload;
                state.schmod_locationProductionSelection[i].selected = true;
            }
        }
    },
    change_SCHMOD_ProAp_storeMutation(state, payload) {
        state.schmod_ProAp = null;
        for (let i = 0; i < state.schmod_ProdApSelection.length; i++) {
            state.schmod_ProdApSelection[i].selected = false;

            if (state.schmod_ProdApSelection[i].text.text == payload) {
                state.schmod_ProAp = payload;
                state.schmod_ProdApSelection[i].selected = true;
            }
        }
    },
    change_SCHMOD_PSGSize_storeMutation(state, payload) {
        state.schmod_PSGSize = null;
        for (let i = 0; i < state.schmod_PSGSizeSelection.length; i++) {
            state.schmod_PSGSizeSelection[i].selected = false;
            if (state.schmod_PSGSizeSelection[i].text.text == payload) {
                state.schmod_PSGSize = payload;
                state.schmod_PSGSizeSelection[i].selected = true;
            }
        }
    },
    change_SCHMOD_motorID_storeMutation(state, payload) {
        state.schmod_motorID = null;
        for (let i = 0; i < state.schmod_motorIDSelection.length; i++) {
            state.schmod_motorIDSelection[i].selected = false;

            if (state.schmod_motorIDSelection[i].text.text == payload) {
                state.schmod_motorID = payload;
                state.schmod_motorIDSelection[i].selected = true;
            }
        }
    },
    change_SCHMOD_motorCosName_storeMutation(state, payload) {
        state.schmod_motorCOSName = null;
        for (let i = 0; i < state.schmod_motorCOSNameSelection.length; i++) {
            state.schmod_motorCOSNameSelection[i].selected = false;

            if (state.schmod_motorCOSNameSelection[i].text.text == payload) {
                state.schmod_motorCOSName = payload;
                state.schmod_motorCOSNameSelection[i].selected = true;
            }
        }
    },
    change_SCHMOD_motorVoltage_storeMutation(state, payload) {
        state.schmod_motorVoltage = null;
        for (let i = 0; i < state.schmod_motorVoltageSelection.length; i++) {
            state.schmod_motorVoltageSelection[i].selected = false;

            if (state.schmod_motorVoltageSelection[i].value == payload) {
                state.schmod_motorVoltage = payload;
                state.schmod_motorVoltageSelection[i].selected = true;
            }
        }
    },
    change_SCHMOD_inverterType_storeMutation(state, payload) {
        state.schmod_inverterType = null;
        for (let i = 0; i < state.schmod_inverterTypeSelection.length; i++) {
            state.schmod_inverterTypeSelection[i].selected = false;

            if (state.schmod_inverterTypeSelection[i].text.text == payload) {
                state.schmod_inverterType = payload;
                state.schmod_inverterTypeSelection[i].selected = true;
            }
        }
    },
    change_SCHMOD_cableLength_storeMutation(state, payload) {
        state.schmod_cableLength = null;
        for (let i = 0; i < state.schmod_cableLengthSelection.length; i++) {
            state.schmod_cableLengthSelection[i].selected = false;

            if (state.schmod_cableLengthSelection[i].text.text == payload) {
                state.schmod_cableLength = payload;
                state.schmod_cableLengthSelection[i].selected = true;
            }
        }
    },
    change_SCHMOD_cableLengthBrake_storeMutation(state, payload) {
        state.schmod_cableLengthBrake = null;
        for (let i = 0; i < state.schmod_cableLengthBrakeSelection.length; i++) {
            state.schmod_cableLengthBrakeSelection[i].selected = false;

            if (state.schmod_cableLengthBrakeSelection[i].text.text == payload) {
                state.schmod_cableLengthBrake = payload;
                state.schmod_cableLengthBrakeSelection[i].selected = true;
            }
        }
    },
    change_SCHMOD_handWheel_storeMutation(state, payload) {
        state.schmod_handWheel = null;
        for (let i = 0; i < state.schmod_handWheelSelection.length; i++) {
            state.schmod_handWheelSelection[i].selected = false;

            if (state.schmod_handWheelSelection[i].text.text == payload) {
                state.schmod_handWheel = payload;
                state.schmod_handWheelSelection[i].selected = true;
            }
        }
    },
    change_SCHMOD_handRelease_storeMutation(state, payload) {
        state.schmod_handRelease = null;
        for (let i = 0; i < state.schmod_handReleaseSelection.length; i++) {
            state.schmod_handReleaseSelection[i].selected = false;

            if (state.schmod_handReleaseSelection[i].text.text == payload) {
                state.schmod_handRelease = payload;
                state.schmod_handReleaseSelection[i].selected = true;
            }
        }
    },
    change_SCHMOD_grooveDistance_storeMutation(state, payload) {
        state.schmod_grooveDistance = payload;
    },
    change_SCHMOD_groove_storeMutation(state, payload) {
        state.schmod_groove = null;
        for (let i = 0; i < state.schmod_grooveSelection.length; i++) {
            state.schmod_grooveSelection[i].selected = false;

            if (state.schmod_grooveSelection[i].text.text == payload) {
                state.schmod_groove = payload;
                state.schmod_grooveSelection[i].selected = true;
            }
        }
    },
    change_SCHMOD_numberOfGrooves_storeMutation(state, payload) {
        state.schmod_numberOfGrooves = null;
        for (let i = 0; i < state.schmod_numberOfGroovesSelection.length; i++) {
            state.schmod_numberOfGroovesSelection[i].selected = false;

            if (state.schmod_numberOfGroovesSelection[i].text.text == payload) {
                state.schmod_numberOfGrooves = payload;
                state.schmod_numberOfGroovesSelection[i].selected = true;
            }
        }
    },
    change_SCHMOD_certificationSelection_storeMutation(state, payload) {
        state.schmod_certificationSelection = payload;
    },
    change_SCHMOD_certification_storeMutation(state, payload) {
        for (let i = 0; i < state.schmod_certificationSelection.length; i++) {
            state.schmod_certificationSelection[i].selected = false;

            if (state.schmod_certificationSelection[i].text.text == payload) {
                state.schmod_certification = payload;
                state.schmod_certificationSelection[i].selected = true;
            }
        }
    },
    change_schmod_Valid_storeMuation(state, payload) {
        state.schmod_Valid = payload;
    },
    change_SchModDetails_storeMuation(state, payload) {
        state.schModDetails = payload;
    },
    change_SCHMOD_Motordetails_storeMutation(state, payload) {
        state.schmodMotordetails = payload;
    },
    set_showSCHMOD_storeMutation(state, payload) {
        state.showSCHMOD = payload;
    },
}

export const psgMenu = {
    namespaced: true,
    components: {
        axios,
    },
    state,
    actions,
    mutations
}