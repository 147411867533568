import axios from 'axios';
import router from "@/router";

let state = {
    elevatorMotor_Machine_Filter_Shown: true,

    // elevatorMotor_Machine
    elevatorMotor_Machine_Data: {},

    elevatorMotor_Machine_Selection_Shown: false,
    elevatorMotor_Machine_Selection_lastIndex: 5,
    filtered_elevatorMotor_Machine_Selection: [],
    elevatorMotor_Machine_Selection_Filtered: false,

    // elevatorMotor_Machine filter
    elevatorMotor_Machine_FilterCriterion_elevatorMotor_Machine_Series_Selection: [],
    elevatorMotor_Machine_FilterCriterion_tractionSheaveDiameter_Selection: [],
    elevatorMotor_Machine_FilterCriterion_lowestNominalRotationSpeedPerMachineTractionSheaveCombination: true,
    elevatorMotor_Machine_FilterCriterion_elevatorMotor_Machine_MechanicalUtilization_Maximum: { value: undefined, invalid: undefined },
    elevatorMotor_Machine_FilterCriterion_elevatorMotor_Machine_ElectricalUtilization_Maximum: { value: undefined, invalid: undefined },
    elevatorMotor_Machine_FilterCriterion_elevatorMotor_TractionSheave_Diameter_Minimum: { value: undefined, invalid: undefined },
    elevatorMotor_Machine_FilterCriterion_elevatorMotor_TractionSheave_Diameter_Maximum: { value: undefined, invalid: undefined },
    elevatorMotor_Machine_FilterCriterion_elevatorMotor_TemperatureMonitoringByPT100: false,
    elevatorMotor_Machine_FilterCriterion_elevatorMotor_ForcedVentilation: false,
    elevatorMotor_Machine_FilterCriterion_elevatorMotor_Brake_MechanicalRelease: false,
    elevatorMotor_Machine_FilterCriterion_elevatorMotor_SafetyBrake: false,
    elevatorMotor_Machine_FilterCriterion_elevatorMotor_AutomationProject: false,
    elevatorMotor_Machine_FilterCriterion_elevatorMotor_ZAframe: false,

    arrayOf_active_elevatorMotor_Machine_FilterCriteria: [],


    elevatorMotor_Machine_forWhich_wrappingAngleCalculation_and_tractionCheck_isMade: {},


    // wrappingAngleCalculation
    wrappingAngleCalculation_Modal_Shown: false,
    loader_forLoadingAllContent_at_wrappingAngleCalculation_Modal_Shown: false,
    loader_forLoadingResults_at_wrappingAngleCalculation_Modal_Shown: false,
    cancellationInformationsAboutHttpRequests_get_wrappingAngleCalculation_Informations: new AbortController(),
    cancellationInformationsAboutHttpRequests_check_wrappingAngleCalculation_Data_byCalculationDll: new AbortController(),

    verticalDistanceOfTheAxlesFromTractionSheaveAndDeflectionPulley: { value: undefined, invalid: undefined },
    horizontalDistanceOfTheRopeHoles: { value: undefined, invalid: undefined },
    deflectionPulleyDiameter: { value: undefined, invalid: undefined },
    wrappingAngle_Calculated: undefined,
    calculated_wrappingAngle: undefined,
    entered_wrappingAngle: { value: undefined, invalid: undefined },
    wrappingAngleCalculation_Inputs_Invalid: undefined,

    wrappingAngle_Property_of_suspensionMeans_Object_atItemOf_elevatorMotor_Machine_Selection_EnteredNotCalculated: undefined,


    // tractionCheck
    tractionCheck_Modal_Shown: false,
    loader_forLoadingAllContent_at_tractionCheck_Modal_Shown: false,
    loader_forLoadingResults_at_tractionCheck_Modal_Shown: false,
    cancellationInformationsAboutHttpRequests_get_tractionCheck_Informations: new AbortController(),

    tractionCheck_Inputs_ValueListsAndSelectionsAndPermissibleValueRanges: {},
    tractionCheck_Inputs_ValueListsAndSelectionsAndPermissibleValueRanges_Loaded: false,

    ropePulleyDiameter: { value: undefined, invalid: undefined },
    numberOfRopePulleysWithUniformBending: { value: undefined, invalid: undefined },
    numberOfRopePulleysWithReverseBending: { value: undefined, invalid: undefined },
    sheaveHardened: undefined,
    sheaveProfile: undefined,
    sheaveProfileAndSheaveHardenedTextKey: undefined,
    undercutAngle_Disabled: undefined,
    undercutAngle: undefined,
    undercutWidth_Disabled: true,
    undercutWidth_Input: false,
    vgrooveAngle_Disabled: undefined,
    vgrooveAngle: undefined,
    minimumValues_Disabled: undefined,
    calculatedWithMinimumValues: undefined,
    shortenedBufferStroke_Disabled: undefined,
    calculatedWithShortenedBufferStroke: undefined,
    tractionCheck_Inputs_Invalid: undefined,

    minimumCarWeight: undefined,
    maximumCarWeight: undefined,

    tractionCheck: {},

    // elevatorMotor_Machine_TractionSheaveSide
    elevatorMotor_Machine_TractionSheaveSide_Data: {},

    // elevatorMotor_Machine_InstallationPosition
    elevatorMotor_Machine_InstallationPosition_Data: {},

    // elevatorMotor_TemperatureMonitoring
    elevatorMotor_TemperatureMonitoring_Data: {},
    elevatorMotor_TemperatureMonitoring_Selection_Shown: true,

    // elevatorMotor_ForcedVentilation
    elevatorMotor_ForcedVentilation: {},
    elevatorMotor_ForcedVentilation_Options_Shown: true,
    permissibleDutyCycleWithoutForcedVentilation: undefined,
    permissibleDutyCycleWithForcedVentilation: undefined,

    // elevatorMotor_Brake
    elevatorMotor_Brake_Data: {},
    elevatorMotor_Brake_Selections_Shown: true,

    // elevatorMotor_Brake_MechanicalRelease
    elevatorMotor_Brake_MechanicalRelease_ChangedByUser: false,
    // elevatorMotor_Brake_ReleaseMonitoring
    elevatorMotor_Brake_ReleaseMonitoring_ChangedByUser: false,


    // elevatorMotor_Encoder
    elevatorMotor_Encoder_Data: {},
    elevatorMotor_Encoder_Selection_Shown: true,

    // userPreferences
    //userPreferences_affected_elevatorMotor_Machine_FilterCriteria: false,
    userPreference_affected_selected_elevatorMotor_TemperatureMonitoring: false,
    userPreference_affected_elevatorMotor_ForcedVentilation_Selected: false,
    userPreference_affected_selected_elevatorMotor_Brake_NominalVoltage: false,
    userPreference_affected_selected_elevatorMotor_Brake_MechanicalRelease: false,
    userPreference_affected_selected_elevatorMotor_Brake_ReleaseMonitoring: false,
    userPreferences_affected_selected_elevatorMotor_Brake: false,
    userPreference_affected_selected_elevatorMotor_Encoder: false,

    // frame
    frame_Data: {},
    horizontalDistance_Minimum: null,
    horizontalDistance_Maximum: null,
    wrappingAngleFrameCalculation_Modal_Shown: false,

    frameInformation: null,
}

const actions = {

    set_decimalValue({ dispatch }, value) {
        const valueDecimalCriteria = this._vm.$localDecimal;
        valueDecimalCriteria.value = value.value;
        if (value.country == 'de') {
            valueDecimalCriteria.country = 'de-DE';
        }
        else {
            valueDecimalCriteria.country = 'en-US';
        }

        const decimalValue = this._vm.$set_decimalValueToLocaleString(valueDecimalCriteria);

        if (value.name == 'elevatorMotor_Machine_FilterCriterion_elevatorMotor_Machine_ElectricalUtilization_Maximum') {
            dispatch('change_elevatorMotor_Machine_FilterCriterion_elevatorMotor_Machine_ElectricalUtilization_Maximum_storeAction', decimalValue);
        }
        else if (value.name == 'elevatorMotor_Machine_FilterCriterion_elevatorMotor_Machine_MechanicalUtilization_Maximum') {
            dispatch('change_elevatorMotor_Machine_FilterCriterion_elevatorMotor_Machine_MechanicalUtilization_Maximum_storeAction', decimalValue);
        }
        else if (value.name == 'verticalDistanceOfTheAxlesFromTractionSheaveAndDeflectionPulley') {
            dispatch('change_verticalDistanceOfTheAxlesFromTractionSheaveAndDeflectionPulley_storeAction', decimalValue);
        }
        else if (value.name == 'horizontalDistanceOfTheRopeHoles') {
            if (state.wrappingAngleFrameCalculation_Modal_Shown == true) {
                dispatch('change_horizontalDistanceOfTheRopeHoles2_storeAction', decimalValue);
            }
            else {
                dispatch('change_horizontalDistanceOfTheRopeHoles_storeAction', decimalValue);
            }
        }
        else if (value.name == 'entered_wrappingAngle') {
            dispatch('change_entered_wrappingAngle_storeAction', decimalValue);
        }
    },


    change_driveTechnologies_toDefault_storeAction({ dispatch }) {
        dispatch('change_elevatorMotor_Machine_toDefault_storeAction')
        dispatch('change_wrappingAngleCalculation_toDefault_storeAction')
        dispatch('change_tractionCheck_toDefault_storeAction')
        dispatch('change_elevatorMotor_Machine_TractionSheaveSide_toDefault_storeAction')
        dispatch('change_elevatorMotor_Machine_InstallationPosition_toDefault_storeAction')
        dispatch('change_elevatorMotor_TemperatureMonitoring_toDefault_storeAction')
        dispatch('change_elevatorMotor_ForcedVentilation_toDefault_storeAction')
        dispatch('change_elevatorMotor_Brake_toDefault_storeAction')
        dispatch('change_elevatorMotor_Encoder_toDefault_storeAction')
        dispatch('change_elevatorMotor_Frame_toDefault_storeAction')
    },

    // elevatorMotor_Machine
    change_elevatorMotor_Machine_toDefault_storeAction({ commit }) {
        commit('change_elevatorMotor_Machine_Data_storeMutation', {});

        commit('change_elevatorMotor_Machine_Selection_Shown_storeMutation', false);
        commit('change_elevatorMotor_Machine_Selection_lastIndex_storeMutation', 5);
        commit('change_filtered_elevatorMotor_Machine_Selection_storeMutation', []);
        commit('change_elevatorMotor_Machine_Selection_Filtered_storeMutation', false);

        commit('change_elevatorMotor_Machine_FilterCriterion_elevatorMotor_Machine_Series_Selection_storeMutation', []);
        commit('change_elevatorMotor_Machine_FilterCriterion_tractionSheaveDiameter_storeMutation', []);
        commit('change_elevatorMotor_Machine_FilterCriterion_lowestNominalRotationSpeedPerMachineTractionSheaveCombination_storeMutation', true);
        commit('change_elevatorMotor_Machine_FilterCriterion_elevatorMotor_Machine_MechanicalUtilization_Maximum_storeMutation', { value: null, invalid: null });
        commit('change_elevatorMotor_Machine_FilterCriterion_elevatorMotor_Machine_ElectricalUtilization_Maximum_storeMutation', { value: null, invalid: null });
        commit('change_elevatorMotor_Machine_FilterCriterion_elevatorMotor_TractionSheave_Diameter_Minimum_storeMutation', { value: null, invalid: null });
        commit('change_elevatorMotor_Machine_FilterCriterion_elevatorMotor_TractionSheave_Diameter_Maximum_storeMutation', { value: null, invalid: null });
        commit('change_elevatorMotor_Machine_FilterCriterion_elevatorMotor_TemperatureMonitoringByPT100_storeMutation', false);
        commit('change_elevatorMotor_Machine_FilterCriterion_elevatorMotor_ForcedVentilation_storeMutation', false);
        commit('change_elevatorMotor_Machine_FilterCriterion_elevatorMotor_Brake_MechanicalRelease_storeMutation', false);
        commit('change_elevatorMotor_Machine_FilterCriterion_elevatorMotor_SafetyBrake_storeMutation', false);
        commit('change_elevatorMotor_Machine_FilterCriterion_elevatorMotor_AutomationProject_storeMutation', false);
        commit('change_elevatorMotor_Machine_FilterCriterion_elevatorMotor_ZAframe_storeMutation', false);

        commit('remove_allElementsAt_arrayOf_active_elevatorMotor_Machine_FilterCriteria_storeMutation');
    },

    change_elevatorMotor_Machine_Selection_Shown_storeAction({ commit }, value) {
        commit('change_elevatorMotor_Machine_Selection_Shown_storeMutation', value)
    },

    async get_elevatorMotor_Machine_Informations_storeAction({ rootState, dispatch }) {
        const data = {
            configurationOverviewsId: rootState.configurations.configurationOverviewsId,
            configurationSteps: rootState.configurations.configurationSteps,
        };

        await axios({
            method: 'post',
            url: '/api/driveTechnologies/get_elevatorMotor_Machine_Informations',
            data: {
                data: data,
            }
        })
            .then((response) => {
                this.dispatch('statusHandling/change_dataAndStatus_storeAction', response.data);

                if ((response.data.status.statusCode.substring(0, 1) == '2') || (response.data.status.statusCode.substring(0, 2) == '74') || (response.data.status.statusCode.substring(0, 2) == '75')) {
                    dispatch('toDos_afterResponseOf_get_elevatorMotor_Machine_Informations_storeAction', response.data.data);
                    if (response.data.data.elevatorMotor_Machine_Data.elevatorMotor_Machine_Filter_Data.elevatorMotor_ZAframe == true) {
                        dispatch('change_elevatorMotor_Machine_FilterCriterion_elevatorMotor_ZAframe_storeAction', true);
                    }
                }
            })
            .catch(() => { })
    },
    toDos_afterResponseOf_get_elevatorMotor_Machine_Informations_storeAction({ rootState, dispatch }, value) {
        dispatch('change_elevatorMotor_Machine_Data_storeAction', value.elevatorMotor_Machine_Data);
        dispatch('change_elevatorMotor_Machine_Selection_Shown_storeAction', true);
        dispatch('checkIf_userPreferences_for_elevatorMotor_Machine_FilterCriteria_Set_storeAction');
        var configurationSteps = Object.assign({}, rootState.configurations.configurationSteps);
        var configurationSteps_elevatorMotor_Machine = Object.assign({}, rootState.configurations.configurationSteps.elevatorMotor_Machine);
        configurationSteps_elevatorMotor_Machine.loaded = true;
        configurationSteps.elevatorMotor_Machine = configurationSteps_elevatorMotor_Machine
        this.dispatch('configurations/change_configurationSteps_storeAction', configurationSteps)
    },

    change_elevatorMotor_Machine_Data_storeAction({ commit }, value) {
        commit('change_elevatorMotor_Machine_Data_storeMutation', value);

        if ((value != null) && (value.elevatorMotor_Machine_Filter_Data != null)) {
            commit('change_elevatorMotor_Machine_FilterCriterion_elevatorMotor_Machine_Series_Selection_storeMutation', value.elevatorMotor_Machine_Filter_Data.elevatorMotor_Machine_Series_Selection)
            commit('change_elevatorMotor_Machine_FilterCriterion_tractionSheaveDiameter_storeMutation', value.elevatorMotor_Machine_Filter_Data.tractionSheaveDiameter_Selection)
            commit('change_elevatorMotor_Machine_FilterCriterion_elevatorMotor_Machine_MechanicalUtilization_Maximum_storeMutation', { value: value.elevatorMotor_Machine_Filter_Data.elevatorMotor_Machine_MechanicalUtilization_Maximum, invalid: false });
            commit('change_elevatorMotor_Machine_FilterCriterion_elevatorMotor_Machine_ElectricalUtilization_Maximum_storeMutation', { value: value.elevatorMotor_Machine_Filter_Data.elevatorMotor_Machine_ElectricalUtilization_Maximum, invalid: false });
            //commit('change_elevatorMotor_Machine_FilterCriterion_elevatorMotor_TractionSheave_Diameter_Minimum_storeMutation', { value: value.elevatorMotor_Machine_Filter_Data.elevatorMotor_TractionSheave_Diameter_Minimum, invalid: false });
            //commit('change_elevatorMotor_Machine_FilterCriterion_elevatorMotor_TractionSheave_Diameter_Maximum_storeMutation', { value: value.elevatorMotor_Machine_Filter_Data.elevatorMotor_TractionSheave_Diameter_Maximum, invalid: false });
            commit('change_elevatorMotor_Machine_FilterCriterion_elevatorMotor_TractionSheave_Diameter_Minimum_storeMutation', { value: null, invalid: null });
            commit('change_elevatorMotor_Machine_FilterCriterion_elevatorMotor_TractionSheave_Diameter_Maximum_storeMutation', { value: null, invalid: null });
        }
        else {
            commit('change_elevatorMotor_Machine_FilterCriterion_elevatorMotor_Machine_Series_Selection_storeMutation', [])
            commit('change_elevatorMotor_Machine_FilterCriterion_tractionSheaveDiameter_storeMutation', [])
            commit('change_elevatorMotor_Machine_FilterCriterion_elevatorMotor_Machine_MechanicalUtilization_Maximum_storeMutation', { value: null, invalid: null });
            commit('change_elevatorMotor_Machine_FilterCriterion_elevatorMotor_Machine_ElectricalUtilization_Maximum_storeMutation', { value: null, invalid: null });
            commit('change_elevatorMotor_Machine_FilterCriterion_elevatorMotor_TractionSheave_Diameter_Minimum_storeMutation', { value: null, invalid: null });
            commit('change_elevatorMotor_Machine_FilterCriterion_elevatorMotor_TractionSheave_Diameter_Maximum_storeMutation', { value: null, invalid: null });
        }
    },
    change_elevatorMotor_Machine_Selection_lastIndex_storeAction({ commit }, value) {
        commit('change_elevatorMotor_Machine_Selection_lastIndex_storeMutation', value)
    },

    async checkIf_userPreferences_for_elevatorMotor_Machine_FilterCriteria_Set_storeAction({ rootState, commit, dispatch }) {
        if (rootState.user.userPreferences_Loaded == false) {
            await this.dispatch('user/get_userPreference_Informations_storeAction')
        }
        if ((rootState.user.userPreferences.hasOwnProperty('elevatorMotor_Machine_Series') == true) && rootState.user.userPreferences.elevatorMotor_Machine_Series != null && (rootState.user.userPreferences.elevatorMotor_Machine_Series.length > 0)) {
            rootState.user.userPreferences.elevatorMotor_Machine_Series.forEach(i => {
                state.elevatorMotor_Machine_Data.elevatorMotor_Machine_Filter_Data.elevatorMotor_Machine_Series_Selection.forEach(ii => {
                    if (ii.text == i) {
                        commit('change_Selected_atElementOf_elevatorMotor_Machine_FilterCriterion_elevatorMotor_Machine_Series_Selection_storeMutation', i)
                        commit('add_elementAt_arrayOf_active_elevatorMotor_Machine_FilterCriteria_storeMutation', { filterBy: 'elevatorMotor_Machine_Series', data: i })
                    }
                })
            })
        }
        if ((rootState.user.userPreferences.hasOwnProperty('use_PT100_For_ElevatorMotor_TemperatureMonitoring') == true) && (rootState.user.userPreferences.use_PT100_For_ElevatorMotor_TemperatureMonitoring == true)) {
            if (state.elevatorMotor_Machine_Data.elevatorMotor_Machine_Filter_Data.elevatorMotor_TemperatureMonitoringByPT100_Available == true) {
                commit('change_elevatorMotor_Machine_FilterCriterion_elevatorMotor_TemperatureMonitoringByPT100_storeMutation', true)
                commit('add_elementAt_arrayOf_active_elevatorMotor_Machine_FilterCriteria_storeMutation', { filterBy: 'elevatorMotor_TemperatureMonitoringByPT100' })
            }
        }
        if ((rootState.user.userPreferences.hasOwnProperty('useOf_ElevatorMotor_ForcedVentilation') == true) && (rootState.user.userPreferences.useOf_ElevatorMotor_ForcedVentilation == true)) {
            if (state.elevatorMotor_Machine_Data.elevatorMotor_Machine_Filter_Data.elevatorMotor_ForcedVentilation_AvailableAndOrNecessary == true) {
                commit('change_elevatorMotor_Machine_FilterCriterion_elevatorMotor_ForcedVentilation_storeMutation', true)
                commit('add_elementAt_arrayOf_active_elevatorMotor_Machine_FilterCriteria_storeMutation', { filterBy: 'elevatorMotor_ForcedVentilation' })
            }
        }
        if ((rootState.user.userPreferences.hasOwnProperty('elevatorMotor_Brake_MechanicalRelease') == true) && (rootState.user.userPreferences.elevatorMotor_Brake_MechanicalRelease != null) && (rootState.user.userPreferences.elevatorMotor_Brake_MechanicalRelease != 'without')) {
            if (state.elevatorMotor_Machine_Data.elevatorMotor_Machine_Filter_Data.elevatorMotor_Brake_MechanicalRelease_Available == true) {
                commit('change_elevatorMotor_Machine_FilterCriterion_elevatorMotor_Brake_MechanicalRelease_storeMutation', true)
                commit('add_elementAt_arrayOf_active_elevatorMotor_Machine_FilterCriteria_storeMutation', { filterBy: 'elevatorMotor_Brake_MechanicalRelease' })
            }
        }

        if (state.arrayOf_active_elevatorMotor_Machine_FilterCriteria.length > 0) {
            //commit('change_userPreferences_affected_elevatorMotor_Machine_FilterCriteria_storeMutation', true)
            dispatch('filter_elevatorMotor_Machine_Selection_storeAction');
        }
    },

    async change_elevatorMotor_Machine_forWhich_wrappingAngleCalculation_and_tractionCheck_isMade_storeAction({ commit, dispatch }, value) {
        const elevatorMotor_Machine_forWhich_wrappingAngleCalculation_and_tractionCheck_isMade = Object.assign({}, value)
        await commit('change_elevatorMotor_Machine_forWhich_wrappingAngleCalculation_and_tractionCheck_isMade_storeMutation', elevatorMotor_Machine_forWhich_wrappingAngleCalculation_and_tractionCheck_isMade)
    },

    checkIf_tractionCheck_Passed_storeAction({ dispatch }, value) {
        dispatch('change_elevatorMotor_Machine_forWhich_wrappingAngleCalculation_and_tractionCheck_isMade_storeAction', value);
        if (state.elevatorMotor_Machine_forWhich_wrappingAngleCalculation_and_tractionCheck_isMade.tractionCheck_Passed == true) {
            dispatch('checkIf_changesAt_elevatorMotor_Machine_changes_configurationFlow_storeAction')
        }
        else {
            if (state.elevatorMotor_Machine_forWhich_wrappingAngleCalculation_and_tractionCheck_isMade.tractionCheck_Executed == true) {
                var status = {
                    statusCode: '7200000',
                    statusInContextOf: 'checkIf_tractionCheck_Passed_storeAction',
                    statusInformations: [{ textKey: 3054, textValues: null }],
                    valueDivergences: null,
                }

                status.statusModalClosingOptions = [{ displayedDesignation: { textKey: 3098 }, closingOption: this._vm.$closingOptions.continue }, { displayedDesignation: { textKey: 3090 }, closingOption: this._vm.$closingOptions.close }]
                this.dispatch('statusHandling/change_dataAndStatus_storeAction', { data: null, status: status });
            }
            else {
                dispatch('checkIf_tractionCheck_Inputs_ValueListsAndSelectionsAndPermissibleValueRanges_Loaded_storeAction');
            }
        }
    },
    async checkIf_changesAt_elevatorMotor_Machine_changes_configurationFlow_storeAction({ rootState, dispatch }) {
        const indexOf_firstElementOf_elevatorMotor_Machine_Selection_where_Selected_isTrue = state.elevatorMotor_Machine_Data.elevatorMotor_Machine_Selection.findIndex(i => i.selected == true);
        const firstIndex_whichMeetConditions = state.elevatorMotor_Machine_Data.elevatorMotor_Machine_Selection.findIndex(i => ((i.elevatorMotor_Machine.blockNumberMachine == state.elevatorMotor_Machine_forWhich_wrappingAngleCalculation_and_tractionCheck_isMade.elevatorMotor_Machine.blockNumberMachine) && (i.elevatorMotor_Machine.blockNumberGear == state.elevatorMotor_Machine_forWhich_wrappingAngleCalculation_and_tractionCheck_isMade.elevatorMotor_Machine.blockNumberGear) && (i.elevatorMotor_TractionSheave.blockNumber == state.elevatorMotor_Machine_forWhich_wrappingAngleCalculation_and_tractionCheck_isMade.elevatorMotor_TractionSheave.blockNumber)));
        if (firstIndex_whichMeetConditions > -1) {
            dispatch('change_Selected_atElementOf_elevatorMotor_Machine_Selection_storeAction', firstIndex_whichMeetConditions)
            dispatch('change_elevatorMotor_Machine_Selection_Shown_storeAction', false)

            if (rootState.configurations.configurationSteps.elevatorMotor_Machine.storedInDatabase == true) {
                if (indexOf_firstElementOf_elevatorMotor_Machine_Selection_where_Selected_isTrue != firstIndex_whichMeetConditions) {
                    var frameSelection = null;
                    if (state.elevatorMotor_Machine_forWhich_wrappingAngleCalculation_and_tractionCheck_isMade.frame != null) {
                        frameSelection = state.elevatorMotor_Machine_forWhich_wrappingAngleCalculation_and_tractionCheck_isMade.frame.frame;
                    }
                    await this.dispatch('configurations/selectAndInsert_or_updateAndOrDelete_databaseRecords_storeAction', { changesAt: this._vm.$configurationSteps.elevatorMotor_Machine, elevatorSystem: state.elevatorMotor_Machine_forWhich_wrappingAngleCalculation_and_tractionCheck_isMade.elevatorSystem, suspensionMeans: state.elevatorMotor_Machine_forWhich_wrappingAngleCalculation_and_tractionCheck_isMade.suspensionMeans, elevatorMotor_TractionSheave: state.elevatorMotor_Machine_forWhich_wrappingAngleCalculation_and_tractionCheck_isMade.elevatorMotor_TractionSheave, elevatorMotor_Machine: state.elevatorMotor_Machine_forWhich_wrappingAngleCalculation_and_tractionCheck_isMade.elevatorMotor_Machine, tractionCheck: state.elevatorMotor_Machine_forWhich_wrappingAngleCalculation_and_tractionCheck_isMade.tractionCheck, frame: frameSelection });
                }
                else {
                    if (rootState.configurations.configurationSteps.tractionChecks.storedInDatabase == false) {
                        await dispatch('insert_databaseRecords_at_tractionCheck_update_databaseRecords_at_suspensionMeans_and_elevatorMotor_TractionSheave_storeAction', state.elevatorMotor_Machine_forWhich_wrappingAngleCalculation_and_tractionCheck_isMade);
                    }
                }
            }
            else {
                await dispatch('insert_databaseRecords_at_elevatorMotor_Machine_and_tractionCheck_update_databaseRecords_at_suspensionMeans_and_elevatorMotor_TractionSheave_storeAction', state.elevatorMotor_Machine_forWhich_wrappingAngleCalculation_and_tractionCheck_isMade);
            }

            this.dispatch('configurations/check_toDos_at_driveTechnologies_storeAction')
        }
        else {
            // error
        }
    },
    change_Selected_atElementOf_elevatorMotor_Machine_Selection_storeAction({ commit }, value) {
        commit('change_Selected_at_elevatorMotor_Machine_Selection_storeMutation', value)
    },
    change_Selected_atEveryElementOf_elevatorMotor_Machine_Selection_toFalse_storeAction({ commit }) {
        commit('change_Selected_atEveryElementOf_elevatorMotor_Machine_Selection_toFalse_storeMutation')
    },

    async insert_databaseRecords_at_elevatorMotor_Machine_and_tractionCheck_update_databaseRecords_at_suspensionMeans_and_elevatorMotor_TractionSheave_storeAction({ rootState }, value) {

        var frameSelected = null;
        if ((state.elevatorMotor_Machine_Data.hasOwnProperty('elevatorMotor_Machine_Selection')) && (Array.isArray(state.elevatorMotor_Machine_Data.elevatorMotor_Machine_Selection))) {
            const firstIndexWhere_Selected_atElementOf_frame_Selection_isTrue = state.elevatorMotor_Machine_Data.elevatorMotor_Machine_Selection.findIndex(i => i.selected == true);
            if (firstIndexWhere_Selected_atElementOf_frame_Selection_isTrue > -1 && state.elevatorMotor_Machine_Data.elevatorMotor_Machine_Selection[firstIndexWhere_Selected_atElementOf_frame_Selection_isTrue].frame != null && state.elevatorMotor_Machine_Data.elevatorMotor_Machine_Selection[firstIndexWhere_Selected_atElementOf_frame_Selection_isTrue].frame.frame != null) {
                frameSelected = state.elevatorMotor_Machine_Data.elevatorMotor_Machine_Selection[firstIndexWhere_Selected_atElementOf_frame_Selection_isTrue].frame.frame;
            }
        }

        const data = {
            configurationOverviewsId: rootState.configurations.configurationOverviewsId,
            configurationSteps: rootState.configurations.configurationSteps,
            elevatorSystem: value.elevatorSystem,
            suspensionMeans: value.suspensionMeans,
            elevatorMotor_Machine: value.elevatorMotor_Machine,
            elevatorMotor_TractionSheave: value.elevatorMotor_TractionSheave,
            tractionCheck: value.tractionCheck,
            frame: frameSelected,
        }

        await axios({
            method: 'post',
            url: '/api/driveTechnologies/insert_databaseRecords_at_elevatorMotor_Machine_and_tractionCheck_update_databaseRecords_at_suspensionMeans_and_elevatorMotor_TractionSheave',
            data: {
                data: data,
            }
        })
            .then((response) => {
                this.dispatch('statusHandling/change_dataAndStatus_storeAction', response.data);

                if (response.data.status.statusCode.startsWith('2')) {
                    this.dispatch('configurations/change_configurationSteps_storeAction', response.data.data.configurationSteps);
                    this.commit('inputs/change_grooveDistance_storeMutation', { value: value.elevatorMotor_TractionSheave.grooveDistance, invalid: false });
                }
            })
            .catch(() => { })
    },

    async insert_databaseRecords_at_tractionCheck_update_databaseRecords_at_suspensionMeans_and_elevatorMotor_TractionSheave_storeAction({ rootState }, value) {
        var frame = null;
        if (value.frame != null) {
            frame = value.frame.frame;
        }
        const data = {
            configurationOverviewsId: rootState.configurations.configurationOverviewsId,
            configurationSteps: rootState.configurations.configurationSteps,
            elevatorSystem: value.elevatorSystem,
            suspensionMeans: value.suspensionMeans,
            elevatorMotor_Machine: value.elevatorMotor_Machine,
            elevatorMotor_TractionSheave: value.elevatorMotor_TractionSheave,
            tractionCheck: value.tractionCheck,
            frame: frame,
        }

        await axios({
            method: 'post',
            url: '/api/driveTechnologies/insert_databaseRecords_at_tractionCheck_update_databaseRecords_at_suspensionMeans_and_elevatorMotor_TractionSheave',
            data: {
                data: data,
            }
        })
            .then((response) => {
                this.dispatch('statusHandling/change_dataAndStatus_storeAction', response.data);

                if (response.data.status.statusCode.startsWith('2')) {
                    this.dispatch('configurations/change_configurationSteps_storeAction', response.data.data.configurationSteps);
                }
            })
            .catch(() => { })
    },



    // elevatorMotor_Machine filter
    change_elevatorMotor_Machine_Filter_Shown_storeAction({ commit }, value) {
        commit('change_elevatorMotor_Machine_Filter_Shown_storeMutation', value)
    },

    change_Selected_atElementOf_elevatorMotor_Machine_FilterCriterion_elevatorMotor_Machine_Series_Selection_storeAction({ commit, dispatch }, value) {
        commit('change_Selected_atElementOf_elevatorMotor_Machine_FilterCriterion_elevatorMotor_Machine_Series_Selection_storeMutation', value);
        dispatch('addOrRemove_elevatorMotor_Machine_Series_at_arrayof_active_elevatorMotor_Machine_FilterCriteria_storeAction', { filterBy: 'elevatorMotor_Machine_Series', data: value })
    },
    change_elevatorMotor_Machine_FilterCriterion_lowestNominalRotationSpeedPerMachineTractionSheaveCombination_storeAction({ commit, dispatch }, value) {
        commit('change_elevatorMotor_Machine_FilterCriterion_lowestNominalRotationSpeedPerMachineTractionSheaveCombination_storeMutation', value)
        dispatch('addOrRemove_elementsAt_arrayof_active_elevatorMotor_Machine_FilterCriteria_storeAction', { filterBy: 'lowestNominalRotationSpeedPerMachineTractionSheaveCombination' });
    },
    change_elevatorMotor_Machine_FilterCriterion_elevatorMotor_Machine_ElectricalUtilization_Maximum_storeAction({ commit, dispatch }, value) {
        const valueAndValueValidationCriteria = this._vm.$transmit_valueAndValueValidationCriteria;
        valueAndValueValidationCriteria.value = value;
        valueAndValueValidationCriteria.dataType = this._vm.$dataTypes.decimal;
        valueAndValueValidationCriteria.permissibleNumberOfDecimalPlaces = 1;
        valueAndValueValidationCriteria.permissibleValueRange.minimum.value = state.elevatorMotor_Machine_Data.elevatorMotor_Machine_Filter_Data.elevatorMotor_Machine_ElectricalUtilization_LowestPossibleMaximum;
        valueAndValueValidationCriteria.permissibleValueRange.minimum.operator = this._vm.$operators.greaterThanOrEqualTo;
        valueAndValueValidationCriteria.permissibleValueRange.maximum.value = state.elevatorMotor_Machine_Data.elevatorMotor_Machine_Filter_Data.elevatorMotor_Machine_ElectricalUtilization_Maximum;
        valueAndValueValidationCriteria.permissibleValueRange.maximum.operator = this._vm.$operators.lessThanOrEqualTo;
        const elevatorMotor_Machine_FilterCriterion_elevatorMotor_Machine_ElectricalUtilization_Maximum_ValidationResult = this._vm.$checkIf_value_isValid(valueAndValueValidationCriteria);

        commit('change_elevatorMotor_Machine_FilterCriterion_elevatorMotor_Machine_ElectricalUtilization_Maximum_storeMutation', elevatorMotor_Machine_FilterCriterion_elevatorMotor_Machine_ElectricalUtilization_Maximum_ValidationResult)

        if ((elevatorMotor_Machine_FilterCriterion_elevatorMotor_Machine_ElectricalUtilization_Maximum_ValidationResult.invalid == false) && (elevatorMotor_Machine_FilterCriterion_elevatorMotor_Machine_ElectricalUtilization_Maximum_ValidationResult.value < state.elevatorMotor_Machine_Data.elevatorMotor_Machine_Filter_Data.elevatorMotor_Machine_ElectricalUtilization_Maximum)) {
            dispatch('addOrChange_elevatorMotor_Machine_ElectricalUtilization_Maximum_and_MechanicalUtilization_Maximum_at_arrayof_active_elevatorMotor_Machine_FilterCriteria_storeAction', { filterBy: 'elevatorMotor_Machine_ElectricalUtilization_Maximum', data: elevatorMotor_Machine_FilterCriterion_elevatorMotor_Machine_ElectricalUtilization_Maximum_ValidationResult.value });
        }
        else {
            const index = state.arrayOf_active_elevatorMotor_Machine_FilterCriteria.findIndex(i => i.filterBy == 'elevatorMotor_Machine_ElectricalUtilization_Maximum');
            if (index > -1) {
                commit('remove_elementAt_arrayOf_active_elevatorMotor_Machine_FilterCriteria_storeMutation', index)
                dispatch('filter_elevatorMotor_Machine_Selection_storeAction');
            }
        }
    },
    change_elevatorMotor_Machine_FilterCriterion_elevatorMotor_Machine_MechanicalUtilization_Maximum_storeAction({ commit, dispatch }, value) {
        const valueAndValueValidationCriteria = this._vm.$transmit_valueAndValueValidationCriteria;
        valueAndValueValidationCriteria.value = value;
        valueAndValueValidationCriteria.dataType = this._vm.$dataTypes.decimal;
        valueAndValueValidationCriteria.permissibleNumberOfDecimalPlaces = 1;
        valueAndValueValidationCriteria.permissibleValueRange.minimum.value = state.elevatorMotor_Machine_Data.elevatorMotor_Machine_Filter_Data.elevatorMotor_Machine_MechanicalUtilization_LowestPossibleMaximum;
        valueAndValueValidationCriteria.permissibleValueRange.minimum.operator = this._vm.$operators.greaterThanOrEqualTo;
        valueAndValueValidationCriteria.permissibleValueRange.maximum.value = state.elevatorMotor_Machine_Data.elevatorMotor_Machine_Filter_Data.elevatorMotor_Machine_MechanicalUtilization_Maximum;
        valueAndValueValidationCriteria.permissibleValueRange.maximum.operator = this._vm.$operators.lessThanOrEqualTo;
        const elevatorMotor_Machine_FilterCriterion_elevatorMotor_Machine_MechanicalUtilization_Maximum_ValidationResult = this._vm.$checkIf_value_isValid(valueAndValueValidationCriteria);

        commit('change_elevatorMotor_Machine_FilterCriterion_elevatorMotor_Machine_MechanicalUtilization_Maximum_storeMutation', elevatorMotor_Machine_FilterCriterion_elevatorMotor_Machine_MechanicalUtilization_Maximum_ValidationResult)

        if ((elevatorMotor_Machine_FilterCriterion_elevatorMotor_Machine_MechanicalUtilization_Maximum_ValidationResult.invalid == false) && (elevatorMotor_Machine_FilterCriterion_elevatorMotor_Machine_MechanicalUtilization_Maximum_ValidationResult.value < state.elevatorMotor_Machine_Data.elevatorMotor_Machine_Filter_Data.elevatorMotor_Machine_MechanicalUtilization_Maximum)) {
            dispatch('addOrChange_elevatorMotor_Machine_ElectricalUtilization_Maximum_and_MechanicalUtilization_Maximum_at_arrayof_active_elevatorMotor_Machine_FilterCriteria_storeAction', { filterBy: 'elevatorMotor_Machine_MechanicalUtilization_Maximum', data: elevatorMotor_Machine_FilterCriterion_elevatorMotor_Machine_MechanicalUtilization_Maximum_ValidationResult.value });
        }
        else {
            const index = state.arrayOf_active_elevatorMotor_Machine_FilterCriteria.findIndex(i => i.filterBy == 'elevatorMotor_Machine_MechanicalUtilization_Maximum');
            if (index > -1) {
                commit('remove_elementAt_arrayOf_active_elevatorMotor_Machine_FilterCriteria_storeMutation', index)
                dispatch('filter_elevatorMotor_Machine_Selection_storeAction');
            }
        }
    },
    change_elevatorMotor_Machine_FilterCriterion_elevatorMotor_TractionSheave_Diameter_Minimum_storeAction({ commit, dispatch }, value) {
        var valueAndValueValidationCriteria = this._vm.$transmit_valueAndValueValidationCriteria;
        valueAndValueValidationCriteria.value = value;
        valueAndValueValidationCriteria.dataType = this._vm.$dataTypes.integer;
        valueAndValueValidationCriteria.permissibleValueRange.minimum.value = state.elevatorMotor_Machine_Data.elevatorMotor_Machine_Filter_Data.elevatorMotor_TractionSheave_Diameter_Minimum;
        valueAndValueValidationCriteria.permissibleValueRange.minimum.operator = this._vm.$operators.greaterThanOrEqualTo;
        valueAndValueValidationCriteria.permissibleValueRange.maximum.value = state.elevatorMotor_Machine_Data.elevatorMotor_Machine_Filter_Data.elevatorMotor_TractionSheave_Diameter_Maximum;
        valueAndValueValidationCriteria.permissibleValueRange.maximum.operator = this._vm.$operators.lessThanOrEqualTo;
        valueAndValueValidationCriteria.permissibleNumberOfDecimalPlaces = null;
        const elevatorMotor_Machine_FilterCriterion_elevatorMotor_TractionSheave_Diameter_Minimum_ValidationResult = this._vm.$checkIf_value_isValid(valueAndValueValidationCriteria);

        valueAndValueValidationCriteria = this._vm.$transmit_valueAndValueValidationCriteria;
        valueAndValueValidationCriteria.value = state.elevatorMotor_Machine_FilterCriterion_elevatorMotor_TractionSheave_Diameter_Maximum.value;
        valueAndValueValidationCriteria.dataType = this._vm.$dataTypes.integer;
        valueAndValueValidationCriteria.permissibleValueRange.minimum.value = state.elevatorMotor_Machine_Data.elevatorMotor_Machine_Filter_Data.elevatorMotor_TractionSheave_Diameter_Minimum;
        valueAndValueValidationCriteria.permissibleValueRange.minimum.operator = this._vm.$operators.greaterThanOrEqualTo;
        valueAndValueValidationCriteria.permissibleValueRange.maximum.value = state.elevatorMotor_Machine_Data.elevatorMotor_Machine_Filter_Data.elevatorMotor_TractionSheave_Diameter_Maximum;
        valueAndValueValidationCriteria.permissibleValueRange.maximum.operator = this._vm.$operators.lessThanOrEqualTo;
        valueAndValueValidationCriteria.permissibleNumberOfDecimalPlaces = null;
        const elevatorMotor_Machine_FilterCriterion_elevatorMotor_TractionSheave_Diameter_Maximum_ValidationResult = this._vm.$checkIf_value_isValid(valueAndValueValidationCriteria);


        if ((elevatorMotor_Machine_FilterCriterion_elevatorMotor_TractionSheave_Diameter_Minimum_ValidationResult.value != null) && (elevatorMotor_Machine_FilterCriterion_elevatorMotor_TractionSheave_Diameter_Maximum_ValidationResult.value != null) && (elevatorMotor_Machine_FilterCriterion_elevatorMotor_TractionSheave_Diameter_Minimum_ValidationResult.value > elevatorMotor_Machine_FilterCriterion_elevatorMotor_TractionSheave_Diameter_Maximum_ValidationResult.value)) {
            elevatorMotor_Machine_FilterCriterion_elevatorMotor_TractionSheave_Diameter_Minimum_ValidationResult.invalid = true
            elevatorMotor_Machine_FilterCriterion_elevatorMotor_TractionSheave_Diameter_Minimum_ValidationResult.invalidityReason = this._vm.$invalidityReasons.minimumValueGreaterThanMaximumValue

            elevatorMotor_Machine_FilterCriterion_elevatorMotor_TractionSheave_Diameter_Maximum_ValidationResult.invalid = true
            elevatorMotor_Machine_FilterCriterion_elevatorMotor_TractionSheave_Diameter_Maximum_ValidationResult.invalidityReason = this._vm.$invalidityReasons.minimumValueGreaterThanMaximumValue
        }

        commit('change_elevatorMotor_Machine_FilterCriterion_elevatorMotor_TractionSheave_Diameter_Minimum_storeMutation', elevatorMotor_Machine_FilterCriterion_elevatorMotor_TractionSheave_Diameter_Minimum_ValidationResult)
        commit('change_elevatorMotor_Machine_FilterCriterion_elevatorMotor_TractionSheave_Diameter_Maximum_storeMutation', elevatorMotor_Machine_FilterCriterion_elevatorMotor_TractionSheave_Diameter_Maximum_ValidationResult)

        if ((elevatorMotor_Machine_FilterCriterion_elevatorMotor_TractionSheave_Diameter_Minimum_ValidationResult.invalid == false) && (elevatorMotor_Machine_FilterCriterion_elevatorMotor_TractionSheave_Diameter_Maximum_ValidationResult.invalid == false) && (((elevatorMotor_Machine_FilterCriterion_elevatorMotor_TractionSheave_Diameter_Minimum_ValidationResult.value > state.elevatorMotor_Machine_Data.elevatorMotor_Machine_Filter_Data.elevatorMotor_TractionSheave_Diameter_Minimum) && (elevatorMotor_Machine_FilterCriterion_elevatorMotor_TractionSheave_Diameter_Maximum_ValidationResult.value <= state.elevatorMotor_Machine_Data.elevatorMotor_Machine_Filter_Data.elevatorMotor_TractionSheave_Diameter_Maximum)) || ((elevatorMotor_Machine_FilterCriterion_elevatorMotor_TractionSheave_Diameter_Minimum_ValidationResult.value >= state.elevatorMotor_Machine_Data.elevatorMotor_Machine_Filter_Data.elevatorMotor_TractionSheave_Diameter_Minimum) && (elevatorMotor_Machine_FilterCriterion_elevatorMotor_TractionSheave_Diameter_Maximum_ValidationResult.value < state.elevatorMotor_Machine_Data.elevatorMotor_Machine_Filter_Data.elevatorMotor_TractionSheave_Diameter_Maximum)))) {
            dispatch('addOrChange_elevatorMotor_Machine_TractionSheave_Diameter_at_arrayof_active_elevatorMotor_Machine_FilterCriteria_storeAction', { filterBy: 'elevatorMotor_TractionSheave_Diameter', data: { minimum: elevatorMotor_Machine_FilterCriterion_elevatorMotor_TractionSheave_Diameter_Minimum_ValidationResult.value, maximum: elevatorMotor_Machine_FilterCriterion_elevatorMotor_TractionSheave_Diameter_Maximum_ValidationResult.value } });
        }
        else {
            const index = state.arrayOf_active_elevatorMotor_Machine_FilterCriteria.findIndex(i => i.filterBy == 'elevatorMotor_TractionSheave_Diameter');
            if (index > -1) {
                commit('remove_elementAt_arrayOf_active_elevatorMotor_Machine_FilterCriteria_storeMutation', index)
                dispatch('filter_elevatorMotor_Machine_Selection_storeAction');
            }
        }
    },
    change_elevatorMotor_Machine_FilterCriterion_elevatorMotor_TractionSheave_Diameter_Maximum_storeAction({ commit, dispatch }, value) {
        var valueAndValueValidationCriteria = this._vm.$transmit_valueAndValueValidationCriteria;
        valueAndValueValidationCriteria.value = state.elevatorMotor_Machine_FilterCriterion_elevatorMotor_TractionSheave_Diameter_Minimum.value;
        valueAndValueValidationCriteria.dataType = this._vm.$dataTypes.integer;
        valueAndValueValidationCriteria.permissibleNumberOfDecimalPlaces = null;
        valueAndValueValidationCriteria.permissibleValueRange.minimum.value = state.elevatorMotor_Machine_Data.elevatorMotor_Machine_Filter_Data.elevatorMotor_TractionSheave_Diameter_Minimum;
        valueAndValueValidationCriteria.permissibleValueRange.minimum.operator = this._vm.$operators.greaterThanOrEqualTo;
        valueAndValueValidationCriteria.permissibleValueRange.maximum.value = state.elevatorMotor_Machine_Data.elevatorMotor_Machine_Filter_Data.elevatorMotor_TractionSheave_Diameter_Maximum;
        valueAndValueValidationCriteria.permissibleValueRange.maximum.operator = this._vm.$operators.lessThanOrEqualTo;
        const elevatorMotor_Machine_FilterCriterion_elevatorMotor_TractionSheave_Diameter_Minimum_ValidationResult = this._vm.$checkIf_value_isValid(valueAndValueValidationCriteria);

        valueAndValueValidationCriteria = this._vm.$transmit_valueAndValueValidationCriteria;
        valueAndValueValidationCriteria.value = value;
        valueAndValueValidationCriteria.dataType = this._vm.$dataTypes.integer;
        valueAndValueValidationCriteria.permissibleNumberOfDecimalPlaces = null;
        valueAndValueValidationCriteria.permissibleValueRange.minimum.value = state.elevatorMotor_Machine_Data.elevatorMotor_Machine_Filter_Data.elevatorMotor_TractionSheave_Diameter_Minimum;
        valueAndValueValidationCriteria.permissibleValueRange.minimum.operator = this._vm.$operators.greaterThanOrEqualTo;
        valueAndValueValidationCriteria.permissibleValueRange.maximum.value = state.elevatorMotor_Machine_Data.elevatorMotor_Machine_Filter_Data.elevatorMotor_TractionSheave_Diameter_Maximum;
        valueAndValueValidationCriteria.permissibleValueRange.maximum.operator = this._vm.$operators.lessThanOrEqualTo;
        const elevatorMotor_Machine_FilterCriterion_elevatorMotor_TractionSheave_Diameter_Maximum_ValidationResult = this._vm.$checkIf_value_isValid(valueAndValueValidationCriteria);


        if ((elevatorMotor_Machine_FilterCriterion_elevatorMotor_TractionSheave_Diameter_Minimum_ValidationResult.value != null) && (elevatorMotor_Machine_FilterCriterion_elevatorMotor_TractionSheave_Diameter_Maximum_ValidationResult.value != null) && (elevatorMotor_Machine_FilterCriterion_elevatorMotor_TractionSheave_Diameter_Minimum_ValidationResult.value > elevatorMotor_Machine_FilterCriterion_elevatorMotor_TractionSheave_Diameter_Maximum_ValidationResult.value)) {
            elevatorMotor_Machine_FilterCriterion_elevatorMotor_TractionSheave_Diameter_Minimum_ValidationResult.invalid = true
            elevatorMotor_Machine_FilterCriterion_elevatorMotor_TractionSheave_Diameter_Minimum_ValidationResult.invalidityReason = this._vm.$invalidityReasons.minimumValueGreaterThanMaximumValue

            elevatorMotor_Machine_FilterCriterion_elevatorMotor_TractionSheave_Diameter_Maximum_ValidationResult.invalid = true
            elevatorMotor_Machine_FilterCriterion_elevatorMotor_TractionSheave_Diameter_Maximum_ValidationResult.invalidityReason = this._vm.$invalidityReasons.minimumValueGreaterThanMaximumValue
        }

        commit('change_elevatorMotor_Machine_FilterCriterion_elevatorMotor_TractionSheave_Diameter_Minimum_storeMutation', elevatorMotor_Machine_FilterCriterion_elevatorMotor_TractionSheave_Diameter_Minimum_ValidationResult)
        commit('change_elevatorMotor_Machine_FilterCriterion_elevatorMotor_TractionSheave_Diameter_Maximum_storeMutation', elevatorMotor_Machine_FilterCriterion_elevatorMotor_TractionSheave_Diameter_Maximum_ValidationResult)

        if ((elevatorMotor_Machine_FilterCriterion_elevatorMotor_TractionSheave_Diameter_Minimum_ValidationResult.invalid == false) && (elevatorMotor_Machine_FilterCriterion_elevatorMotor_TractionSheave_Diameter_Maximum_ValidationResult.invalid == false) && (((elevatorMotor_Machine_FilterCriterion_elevatorMotor_TractionSheave_Diameter_Minimum_ValidationResult.value > state.elevatorMotor_Machine_Data.elevatorMotor_Machine_Filter_Data.elevatorMotor_TractionSheave_Diameter_Minimum) && (elevatorMotor_Machine_FilterCriterion_elevatorMotor_TractionSheave_Diameter_Maximum_ValidationResult.value <= state.elevatorMotor_Machine_Data.elevatorMotor_Machine_Filter_Data.elevatorMotor_TractionSheave_Diameter_Maximum)) || ((elevatorMotor_Machine_FilterCriterion_elevatorMotor_TractionSheave_Diameter_Minimum_ValidationResult.value >= state.elevatorMotor_Machine_Data.elevatorMotor_Machine_Filter_Data.elevatorMotor_TractionSheave_Diameter_Minimum) && (elevatorMotor_Machine_FilterCriterion_elevatorMotor_TractionSheave_Diameter_Maximum_ValidationResult.value < state.elevatorMotor_Machine_Data.elevatorMotor_Machine_Filter_Data.elevatorMotor_TractionSheave_Diameter_Maximum)))) {
            dispatch('addOrChange_elevatorMotor_Machine_TractionSheave_Diameter_at_arrayof_active_elevatorMotor_Machine_FilterCriteria_storeAction', { filterBy: 'elevatorMotor_TractionSheave_Diameter', data: { minimum: elevatorMotor_Machine_FilterCriterion_elevatorMotor_TractionSheave_Diameter_Minimum_ValidationResult.value, maximum: elevatorMotor_Machine_FilterCriterion_elevatorMotor_TractionSheave_Diameter_Maximum_ValidationResult.value } });
        }
        else {
            const index = state.arrayOf_active_elevatorMotor_Machine_FilterCriteria.findIndex(i => i.filterBy == 'elevatorMotor_TractionSheave_Diameter');
            if (index > -1) {
                commit('remove_elementAt_arrayOf_active_elevatorMotor_Machine_FilterCriteria_storeMutation', index)
                dispatch('filter_elevatorMotor_Machine_Selection_storeAction');
            }
        }
    },
    change_elevatorMotor_Machine_FilterCriterion_elevatorMotor_TemperatureMonitoringByPT100_storeAction({ commit, dispatch }, value) {
        commit('change_elevatorMotor_Machine_FilterCriterion_elevatorMotor_TemperatureMonitoringByPT100_storeMutation', value)
        dispatch('addOrRemove_elementsAt_arrayof_active_elevatorMotor_Machine_FilterCriteria_storeAction', { filterBy: 'elevatorMotor_TemperatureMonitoringByPT100' })
    },
    change_elevatorMotor_Machine_FilterCriterion_elevatorMotor_ForcedVentilation_storeAction({ commit, dispatch }, value) {
        commit('change_elevatorMotor_Machine_FilterCriterion_elevatorMotor_ForcedVentilation_storeMutation', value)
        dispatch('addOrRemove_elementsAt_arrayof_active_elevatorMotor_Machine_FilterCriteria_storeAction', { filterBy: 'elevatorMotor_ForcedVentilation' })
    },
    change_elevatorMotor_Machine_FilterCriterion_elevatorMotor_Brake_MechanicalRelease_storeAction({ commit, dispatch }, value) {
        commit('change_elevatorMotor_Machine_FilterCriterion_elevatorMotor_Brake_MechanicalRelease_storeMutation', value)
        dispatch('addOrRemove_elementsAt_arrayof_active_elevatorMotor_Machine_FilterCriteria_storeAction', { filterBy: 'elevatorMotor_Brake_MechanicalRelease' })
    },
    change_elevatorMotor_Machine_FilterCriterion_elevatorMotor_SafetyBrake_storeAction({ commit, dispatch }, value) {
        commit('change_elevatorMotor_Machine_FilterCriterion_elevatorMotor_SafetyBrake_storeMutation', value)
        dispatch('addOrRemove_elementsAt_arrayof_active_elevatorMotor_Machine_FilterCriteria_storeAction', { filterBy: 'elevatorMotor_SafetyBrake' })
    },
    change_elevatorMotor_Machine_FilterCriterion_elevatorMotor_AutomationProject_storeAction({ commit, dispatch }, value) {
        commit('change_elevatorMotor_Machine_FilterCriterion_elevatorMotor_AutomationProject_storeMutation', value)
        dispatch('addOrRemove_elementsAt_arrayof_active_elevatorMotor_Machine_FilterCriteria_storeAction', { filterBy: 'elevatorMotor_AutomationProject' })
    },
    change_elevatorMotor_Machine_FilterCriterion_elevatorMotor_ZAframe_storeAction({ commit, dispatch }, value) {
        commit('change_elevatorMotor_Machine_FilterCriterion_elevatorMotor_ZAframe_storeMutation', value)
        dispatch('addOrRemove_elementsAt_arrayof_active_elevatorMotor_Machine_FilterCriteria_storeAction', { filterBy: 'elevatorMotor_ZAframe' })
    },

    addOrRemove_elevatorMotor_Machine_Series_at_arrayof_active_elevatorMotor_Machine_FilterCriteria_storeAction({ commit, dispatch }, value) {
        const index = state.arrayOf_active_elevatorMotor_Machine_FilterCriteria.findIndex(i => (i.filterBy == value.filterBy) && (i.data == value.data))
        if (index > -1) {
            commit('remove_elementAt_arrayOf_active_elevatorMotor_Machine_FilterCriteria_storeMutation', index);
        }
        else {
            commit('add_elementAt_arrayOf_active_elevatorMotor_Machine_FilterCriteria_storeMutation', value);
        }

        if (state.arrayOf_active_elevatorMotor_Machine_FilterCriteria.length >= 1) {
            dispatch('filter_elevatorMotor_Machine_Selection_storeAction');
        }
        else if (state.arrayOf_active_elevatorMotor_Machine_FilterCriteria.length == 0) {
            commit('change_elevatorMotor_Machine_Selection_Filtered_storeMutation', false);
        }
    },
    addOrChange_elevatorMotor_Machine_ElectricalUtilization_Maximum_and_MechanicalUtilization_Maximum_at_arrayof_active_elevatorMotor_Machine_FilterCriteria_storeAction({ commit, dispatch }, value) {
        const index = state.arrayOf_active_elevatorMotor_Machine_FilterCriteria.findIndex(i => i.filterBy == value.filterBy)

        if (index > -1) {
            commit('change_elementAt_arrayOf_active_elevatorMotor_Machine_FilterCriteria_storeMutation', { index: index, data: value.data })
        }
        else {
            commit('add_elementAt_arrayOf_active_elevatorMotor_Machine_FilterCriteria_storeMutation', value);
        }

        dispatch('filter_elevatorMotor_Machine_Selection_storeAction');
    },
    remove_elevatorMotor_Machine_ElectricalUtilization_Maximum_and_MechanicalUtilization_Maximum_at_arrayof_active_elevatorMotor_Machine_FilterCriteria_storeAction({ commit, dispatch }, value) {
        if (value.filterBy == 'elevatorMotor_Machine_ElectricalUtilization_Maximum') {
            commit('change_elevatorMotor_Machine_FilterCriterion_elevatorMotor_Machine_ElectricalUtilization_Maximum_storeMutation', { value: state.elevatorMotor_Machine_Data.elevatorMotor_Machine_Filter_Data.elevatorMotor_Machine_ElectricalUtilization_Maximum, invalid: false });
        }
        else if (value.filterBy == 'elevatorMotor_Machine_MechanicalUtilization_Maximum') {
            commit('change_elevatorMotor_Machine_FilterCriterion_elevatorMotor_Machine_MechanicalUtilization_Maximum_storeMutation', { value: state.elevatorMotor_Machine_Data.elevatorMotor_Machine_Filter_Data.elevatorMotor_Machine_MechanicalUtilization_Maximum, invalid: false });
        }

        const index = state.arrayOf_active_elevatorMotor_Machine_FilterCriteria.findIndex(i => i.filterBy == value.filterBy);
        commit('remove_elementAt_arrayOf_active_elevatorMotor_Machine_FilterCriteria_storeMutation', index);
        dispatch('filter_elevatorMotor_Machine_Selection_storeAction');
    },
    addOrChange_elevatorMotor_Machine_TractionSheave_Diameter_at_arrayof_active_elevatorMotor_Machine_FilterCriteria_storeAction({ commit, dispatch }, value) {
        const index = state.arrayOf_active_elevatorMotor_Machine_FilterCriteria.findIndex(i => i.filterBy == value.filterBy)
        if (index > -1) {
            commit('change_elementAt_arrayOf_active_elevatorMotor_Machine_FilterCriteria_storeMutation', { index: index, data: value.data })
        }
        else {
            commit('add_elementAt_arrayOf_active_elevatorMotor_Machine_FilterCriteria_storeMutation', value);
        }
        dispatch('filter_elevatorMotor_Machine_Selection_storeAction');
    },
    remove_elevatorMotor_Machine_TractionSheave_Diameter_at_arrayof_active_elevatorMotor_Machine_FilterCriteria_storeAction({ commit, dispatch }) {
        commit('change_elevatorMotor_Machine_FilterCriterion_elevatorMotor_TractionSheave_Diameter_Minimum_storeMutation', { value: null, invalid: null });
        commit('change_elevatorMotor_Machine_FilterCriterion_elevatorMotor_TractionSheave_Diameter_Maximum_storeMutation', { value: null, invalid: null });

        const index = state.arrayOf_active_elevatorMotor_Machine_FilterCriteria.findIndex(i => i.filterBy == 'elevatorMotor_TractionSheave_Diameter');
        commit('remove_elementAt_arrayOf_active_elevatorMotor_Machine_FilterCriteria_storeMutation', index);
        dispatch('filter_elevatorMotor_Machine_Selection_storeAction');
    },
    addOrRemove_elementsAt_arrayof_active_elevatorMotor_Machine_FilterCriteria_storeAction({ commit, dispatch }, value) {
        const index = state.arrayOf_active_elevatorMotor_Machine_FilterCriteria.findIndex(i => i.filterBy == value.filterBy)
        if (index > -1) {
            if (value.filterBy == 'lowestNominalRotationSpeedPerMachineTractionSheaveCombination') {
                commit('change_elevatorMotor_Machine_FilterCriterion_lowestNominalRotationSpeedPerMachineTractionSheaveCombination_storeMutation', false);
            }
            else if (value.filterBy == 'elevatorMotor_TemperatureMonitoringByPT100') {
                commit('change_elevatorMotor_Machine_FilterCriterion_elevatorMotor_TemperatureMonitoringByPT100_storeMutation', false);
            }
            else if (value.filterBy == 'elevatorMotor_ForcedVentilation') {
                commit('change_elevatorMotor_Machine_FilterCriterion_elevatorMotor_ForcedVentilation_storeMutation', false);
            }
            else if (value.filterBy == 'elevatorMotor_Brake_MechanicalRelease') {
                commit('change_elevatorMotor_Machine_FilterCriterion_elevatorMotor_Brake_MechanicalRelease_storeMutation', false);
            }
            else if (value.filterBy == 'elevatorMotor_SafetyBrake') {
                commit('change_elevatorMotor_Machine_FilterCriterion_elevatorMotor_SafetyBrake_storeMutation', false);
            }
            else if (value.filterBy == 'elevatorMotor_AutomationProject') {
                commit('change_elevatorMotor_Machine_FilterCriterion_elevatorMotor_AutomationProject_storeMutation', false);
            }
            else if (value.filterBy == 'elevatorMotor_ZAframe') {
                commit('change_elevatorMotor_Machine_FilterCriterion_elevatorMotor_ZAframe_storeMutation', false);
            }

            commit('remove_elementAt_arrayOf_active_elevatorMotor_Machine_FilterCriteria_storeMutation', index);
        }
        else {
            commit('add_elementAt_arrayOf_active_elevatorMotor_Machine_FilterCriteria_storeMutation', value);
        }


        if (state.arrayOf_active_elevatorMotor_Machine_FilterCriteria.length >= 1) {
            dispatch('filter_elevatorMotor_Machine_Selection_storeAction');
        }
        else if (state.arrayOf_active_elevatorMotor_Machine_FilterCriteria.length == 0) {
            commit('change_elevatorMotor_Machine_Selection_Filtered_storeMutation', false);
        }
    },
    remove_allElementsAt_arrayof_active_elevatorMotor_Machine_FilterCriteria_storeAction({ commit, dispatch }) {
        commit('remove_allElementsAt_arrayOf_active_elevatorMotor_Machine_FilterCriteria_storeMutation');
        commit('change_Selected_atEveryElementOf_elevatorMotor_Machine_FilterCriterion_elevatorMotor_Machine_Series_Selection_toFalse_storeMutation');
        commit('change_elevatorMotor_Machine_FilterCriterion_lowestNominalRotationSpeedPerMachineTractionSheaveCombination_storeMutation', false);
        commit('change_elevatorMotor_Machine_FilterCriterion_elevatorMotor_Machine_ElectricalUtilization_Maximum_storeMutation', { value: state.elevatorMotor_Machine_Data.elevatorMotor_Machine_Filter_Data.elevatorMotor_Machine_ElectricalUtilization_Maximum, invalid: false });
        commit('change_elevatorMotor_Machine_FilterCriterion_elevatorMotor_Machine_MechanicalUtilization_Maximum_storeMutation', { value: state.elevatorMotor_Machine_Data.elevatorMotor_Machine_Filter_Data.elevatorMotor_Machine_MechanicalUtilization_Maximum, invalid: false });
        commit('change_elevatorMotor_Machine_FilterCriterion_elevatorMotor_TractionSheave_Diameter_Minimum_storeMutation', { value: null, invalid: false });
        commit('change_elevatorMotor_Machine_FilterCriterion_elevatorMotor_TractionSheave_Diameter_Maximum_storeMutation', { value: null, invalid: false });
        commit('change_elevatorMotor_Machine_FilterCriterion_elevatorMotor_TemperatureMonitoringByPT100_storeMutation', false);
        commit('change_elevatorMotor_Machine_FilterCriterion_elevatorMotor_ForcedVentilation_storeMutation', false);
        commit('change_elevatorMotor_Machine_FilterCriterion_elevatorMotor_Brake_MechanicalRelease_storeMutation', false);
        commit('change_elevatorMotor_Machine_FilterCriterion_elevatorMotor_SafetyBrake_storeMutation', false);
        commit('change_elevatorMotor_Machine_FilterCriterion_elevatorMotor_AutomationProject_storeMutation', false);
        commit('change_elevatorMotor_Machine_FilterCriterion_elevatorMotor_ZAframe_storeMutation', false);

        dispatch('filter_elevatorMotor_Machine_Selection_storeAction');
    },

    filter_elevatorMotor_Machine_Selection_storeAction({ commit }) {
        commit('change_elevatorMotor_Machine_Selection_lastIndex_storeMutation', 5);
        commit('change_filtered_elevatorMotor_Machine_Selection_storeMutation', []);

        var duplicated_elevatorMotor_Machine_Selection = state.elevatorMotor_Machine_Data.elevatorMotor_Machine_Selection;
        var duplicated_arrayOf_active_elevatorMotor_Machine_FilterCriteria = [];
        duplicated_arrayOf_active_elevatorMotor_Machine_FilterCriteria = duplicated_arrayOf_active_elevatorMotor_Machine_FilterCriteria.concat(state.arrayOf_active_elevatorMotor_Machine_FilterCriteria);

        var filtered_elevatorMotor_Machine_Selection_step1 = []
        var filtered_elevatorMotor_Machine_Selection_step2 = []
        var filtered_elevatorMotor_Machine_Selection_step3 = []
        var duplicated_elevatorMotor_Machine_Selection_filteredBy_elevatorMotor_Machine_Series = []
        var duplicated_elevatorMotor_Machine_Selection_filteredBy_lowestNominalRotationSpeedPerMachineTractionSheaveCombination = []
        var arrayWithDeletableIndexesOf_duplicated_arrayOf_active_elevatorMotor_Machine_FilterCriteria = []

        // create 3 helpArrays from elevatorMotor_Machine_Selection filtered by machine_GearlessSeries, machine_WithGear, machine_LowestNominalRotationSpeedPerMachineTractionSheaveCombination
        for (let indexOf_duplicated_arrayOf_active_elevatorMotor_Machine_FilterCriteria = 0; indexOf_duplicated_arrayOf_active_elevatorMotor_Machine_FilterCriteria < duplicated_arrayOf_active_elevatorMotor_Machine_FilterCriteria.length; indexOf_duplicated_arrayOf_active_elevatorMotor_Machine_FilterCriteria++) {
            if (duplicated_arrayOf_active_elevatorMotor_Machine_FilterCriteria[indexOf_duplicated_arrayOf_active_elevatorMotor_Machine_FilterCriteria].filterBy == 'elevatorMotor_Machine_Series') {
                for (let indexOf_duplicated_elevatorMotor_Machine_Selection = 0; indexOf_duplicated_elevatorMotor_Machine_Selection < duplicated_elevatorMotor_Machine_Selection.length; indexOf_duplicated_elevatorMotor_Machine_Selection++) {
                    if ((duplicated_elevatorMotor_Machine_Selection[indexOf_duplicated_elevatorMotor_Machine_Selection].elevatorMotor_Machine.series == duplicated_arrayOf_active_elevatorMotor_Machine_FilterCriteria[indexOf_duplicated_arrayOf_active_elevatorMotor_Machine_FilterCriteria].data) || ((duplicated_elevatorMotor_Machine_Selection[indexOf_duplicated_elevatorMotor_Machine_Selection].elevatorMotor_Machine.design == 'gear') && (duplicated_arrayOf_active_elevatorMotor_Machine_FilterCriteria[indexOf_duplicated_arrayOf_active_elevatorMotor_Machine_FilterCriteria].data == 'Geared machines'))) {
                        duplicated_elevatorMotor_Machine_Selection_filteredBy_elevatorMotor_Machine_Series.push(duplicated_elevatorMotor_Machine_Selection[indexOf_duplicated_elevatorMotor_Machine_Selection]);
                    }
                }
                arrayWithDeletableIndexesOf_duplicated_arrayOf_active_elevatorMotor_Machine_FilterCriteria.push(indexOf_duplicated_arrayOf_active_elevatorMotor_Machine_FilterCriteria);
            }
            else if (duplicated_arrayOf_active_elevatorMotor_Machine_FilterCriteria[indexOf_duplicated_arrayOf_active_elevatorMotor_Machine_FilterCriteria].filterBy == 'lowestNominalRotationSpeedPerMachineTractionSheaveCombination') {
                duplicated_elevatorMotor_Machine_Selection_filteredBy_lowestNominalRotationSpeedPerMachineTractionSheaveCombination = duplicated_elevatorMotor_Machine_Selection.filter(i => {
                    if ((i.lowestNominalRotationSpeedPerMachineTractionSheaveCombination == true)) {
                        return true;
                    }
                    return false;
                });
                arrayWithDeletableIndexesOf_duplicated_arrayOf_active_elevatorMotor_Machine_FilterCriteria.push(indexOf_duplicated_arrayOf_active_elevatorMotor_Machine_FilterCriteria);
            }
        }

        // remove filterCriteria(machine_GearlessSeries, machine_WithGear, lowestNominalRotationSpeedPerMachineTractionSheaveCombination) from duplicated_arrayOf_active_elevatorMotor_Machine_FilterCriteria
        arrayWithDeletableIndexesOf_duplicated_arrayOf_active_elevatorMotor_Machine_FilterCriteria.sort();
        arrayWithDeletableIndexesOf_duplicated_arrayOf_active_elevatorMotor_Machine_FilterCriteria.reverse();
        for (let indexOf_arrayWithDeletableIndexesOf_duplicated_arrayOf_active_elevatorMotor_Machine_FilterCriteria = 0; indexOf_arrayWithDeletableIndexesOf_duplicated_arrayOf_active_elevatorMotor_Machine_FilterCriteria < arrayWithDeletableIndexesOf_duplicated_arrayOf_active_elevatorMotor_Machine_FilterCriteria.length; indexOf_arrayWithDeletableIndexesOf_duplicated_arrayOf_active_elevatorMotor_Machine_FilterCriteria++) {
            duplicated_arrayOf_active_elevatorMotor_Machine_FilterCriteria.splice(arrayWithDeletableIndexesOf_duplicated_arrayOf_active_elevatorMotor_Machine_FilterCriteria[indexOf_arrayWithDeletableIndexesOf_duplicated_arrayOf_active_elevatorMotor_Machine_FilterCriteria], 1)
        }

        // create one helpArray with all selected machine series
        if (duplicated_elevatorMotor_Machine_Selection_filteredBy_elevatorMotor_Machine_Series.length == 0) {
            filtered_elevatorMotor_Machine_Selection_step1 = filtered_elevatorMotor_Machine_Selection_step1.concat(duplicated_elevatorMotor_Machine_Selection)
        }
        else if (duplicated_elevatorMotor_Machine_Selection_filteredBy_elevatorMotor_Machine_Series.length > 0) {
            filtered_elevatorMotor_Machine_Selection_step1 = filtered_elevatorMotor_Machine_Selection_step1.concat(duplicated_elevatorMotor_Machine_Selection_filteredBy_elevatorMotor_Machine_Series)
        }


        // filter by lowestNominalRotationSpeedPerMachineTractionSheaveCombination
        if (duplicated_elevatorMotor_Machine_Selection_filteredBy_lowestNominalRotationSpeedPerMachineTractionSheaveCombination.length > 0) {
            for (let indexOf_duplicated_elevatorMotor_Machine_Selection_filteredBy_lowestNominalRotationSpeedPerMachineTractionSheaveCombination = 0; indexOf_duplicated_elevatorMotor_Machine_Selection_filteredBy_lowestNominalRotationSpeedPerMachineTractionSheaveCombination < duplicated_elevatorMotor_Machine_Selection_filteredBy_lowestNominalRotationSpeedPerMachineTractionSheaveCombination.length; indexOf_duplicated_elevatorMotor_Machine_Selection_filteredBy_lowestNominalRotationSpeedPerMachineTractionSheaveCombination++) {
                const index = filtered_elevatorMotor_Machine_Selection_step1.findIndex(x => ((x.elevatorMotor_Machine.blockNumberMachine == duplicated_elevatorMotor_Machine_Selection_filteredBy_lowestNominalRotationSpeedPerMachineTractionSheaveCombination[indexOf_duplicated_elevatorMotor_Machine_Selection_filteredBy_lowestNominalRotationSpeedPerMachineTractionSheaveCombination].elevatorMotor_Machine.blockNumberMachine) && (x.elevatorMotor_Machine.blockNumberGear == duplicated_elevatorMotor_Machine_Selection_filteredBy_lowestNominalRotationSpeedPerMachineTractionSheaveCombination[indexOf_duplicated_elevatorMotor_Machine_Selection_filteredBy_lowestNominalRotationSpeedPerMachineTractionSheaveCombination].elevatorMotor_Machine.blockNumberGear) && (x.elevatorMotor_TractionSheave.blockNumber == duplicated_elevatorMotor_Machine_Selection_filteredBy_lowestNominalRotationSpeedPerMachineTractionSheaveCombination[indexOf_duplicated_elevatorMotor_Machine_Selection_filteredBy_lowestNominalRotationSpeedPerMachineTractionSheaveCombination].elevatorMotor_TractionSheave.blockNumber)));
                if (index > -1) {
                    filtered_elevatorMotor_Machine_Selection_step2.push(filtered_elevatorMotor_Machine_Selection_step1[index])
                }
            }
        }

        if (filtered_elevatorMotor_Machine_Selection_step2.length == 0) {
            filtered_elevatorMotor_Machine_Selection_step2 = filtered_elevatorMotor_Machine_Selection_step2.concat(filtered_elevatorMotor_Machine_Selection_step1);
        }
        if (duplicated_arrayOf_active_elevatorMotor_Machine_FilterCriteria.length > 0) {
            for (let indexOf_duplicated_arrayOf_active_elevatorMotor_Machine_FilterCriteria = 0; indexOf_duplicated_arrayOf_active_elevatorMotor_Machine_FilterCriteria < duplicated_arrayOf_active_elevatorMotor_Machine_FilterCriteria.length; indexOf_duplicated_arrayOf_active_elevatorMotor_Machine_FilterCriteria++) {
                var filtered_elevatorMotor_Machine_Selection_step31 = []
                filtered_elevatorMotor_Machine_Selection_step31 = filtered_elevatorMotor_Machine_Selection_step2.filter(i => {
                    if (duplicated_arrayOf_active_elevatorMotor_Machine_FilterCriteria[indexOf_duplicated_arrayOf_active_elevatorMotor_Machine_FilterCriteria].filterBy == 'elevatorMotor_Machine_ElectricalUtilization_Maximum') {
                        if (i.elevatorMotor_Machine.electricalUtilization <= duplicated_arrayOf_active_elevatorMotor_Machine_FilterCriteria[indexOf_duplicated_arrayOf_active_elevatorMotor_Machine_FilterCriteria].data) {
                            return true;
                        }
                    }
                    if (duplicated_arrayOf_active_elevatorMotor_Machine_FilterCriteria[indexOf_duplicated_arrayOf_active_elevatorMotor_Machine_FilterCriteria].filterBy == 'elevatorMotor_Machine_MechanicalUtilization_Maximum') {
                        if (i.elevatorMotor_Machine.mechanicalUtilization <= duplicated_arrayOf_active_elevatorMotor_Machine_FilterCriteria[indexOf_duplicated_arrayOf_active_elevatorMotor_Machine_FilterCriteria].data) {
                            return true;
                        }
                    }
                    if (duplicated_arrayOf_active_elevatorMotor_Machine_FilterCriteria[indexOf_duplicated_arrayOf_active_elevatorMotor_Machine_FilterCriteria].filterBy == 'elevatorMotor_TractionSheave_Diameter') {
                        if ((i.elevatorMotor_TractionSheave.diameter >= duplicated_arrayOf_active_elevatorMotor_Machine_FilterCriteria[indexOf_duplicated_arrayOf_active_elevatorMotor_Machine_FilterCriteria].data.minimum) && (i.elevatorMotor_TractionSheave.diameter <= duplicated_arrayOf_active_elevatorMotor_Machine_FilterCriteria[indexOf_duplicated_arrayOf_active_elevatorMotor_Machine_FilterCriteria].data.maximum)) {
                            return true;
                        }
                    }
                    if ((i.brake_MechanicalRelease_Available == true) && (duplicated_arrayOf_active_elevatorMotor_Machine_FilterCriteria[indexOf_duplicated_arrayOf_active_elevatorMotor_Machine_FilterCriteria].filterBy == 'elevatorMotor_Brake_MechanicalRelease')) {
                        return true;
                    }
                    if ((i.temperatureMonitoringByPT100_Available == true) && (duplicated_arrayOf_active_elevatorMotor_Machine_FilterCriteria[indexOf_duplicated_arrayOf_active_elevatorMotor_Machine_FilterCriteria].filterBy == 'elevatorMotor_TemperatureMonitoringByPT100')) {
                        return true;
                    }
                    if (((i.forcedVentilation_Available == true) || (i.forcedVentilation_Necessary == true)) && (duplicated_arrayOf_active_elevatorMotor_Machine_FilterCriteria[indexOf_duplicated_arrayOf_active_elevatorMotor_Machine_FilterCriteria].filterBy == 'elevatorMotor_ForcedVentilation')) {
                        return true;
                    }
                    if ((i.safetyBrake_Available == true) && (duplicated_arrayOf_active_elevatorMotor_Machine_FilterCriteria[indexOf_duplicated_arrayOf_active_elevatorMotor_Machine_FilterCriteria].filterBy == 'elevatorMotor_SafetyBrake')) {
                        return true;
                    }
                    if ((i.automationProject == true) && (duplicated_arrayOf_active_elevatorMotor_Machine_FilterCriteria[indexOf_duplicated_arrayOf_active_elevatorMotor_Machine_FilterCriteria].filterBy == 'elevatorMotor_AutomationProject')) {
                        return true;
                    }
                    if ((i.frame_Available == true) && (duplicated_arrayOf_active_elevatorMotor_Machine_FilterCriteria[indexOf_duplicated_arrayOf_active_elevatorMotor_Machine_FilterCriteria].filterBy == 'elevatorMotor_ZAframe')) {
                        return true;
                    }
                    return false;
                });
                if (indexOf_duplicated_arrayOf_active_elevatorMotor_Machine_FilterCriteria > 0) {
                    filtered_elevatorMotor_Machine_Selection_step3 = filtered_elevatorMotor_Machine_Selection_step3.filter(x => filtered_elevatorMotor_Machine_Selection_step31.includes(x));
                }
                else if (indexOf_duplicated_arrayOf_active_elevatorMotor_Machine_FilterCriteria == 0) {
                    filtered_elevatorMotor_Machine_Selection_step3 = filtered_elevatorMotor_Machine_Selection_step3.concat(filtered_elevatorMotor_Machine_Selection_step31);
                }
            }
        }
        else if (duplicated_arrayOf_active_elevatorMotor_Machine_FilterCriteria.length == 0) {
            filtered_elevatorMotor_Machine_Selection_step3 = filtered_elevatorMotor_Machine_Selection_step3.concat(filtered_elevatorMotor_Machine_Selection_step2)
        }

        const filtered_elevatorMotor_Machine_Selection = duplicated_elevatorMotor_Machine_Selection.filter(x => filtered_elevatorMotor_Machine_Selection_step3.includes(x));
        commit('change_filtered_elevatorMotor_Machine_Selection_storeMutation', filtered_elevatorMotor_Machine_Selection)

        if (state.arrayOf_active_elevatorMotor_Machine_FilterCriteria.length > 0) {
            commit('change_elevatorMotor_Machine_Selection_Filtered_storeMutation', true);
        }
        else if (state.arrayOf_active_elevatorMotor_Machine_FilterCriteria.length == 0) {
            commit('change_elevatorMotor_Machine_Selection_Filtered_storeMutation', false);
        }
    },


    // wrappingAngleCalculation & tractionCheck
    async checkIf_tractionCheck_Inputs_ValueListsAndSelectionsAndPermissibleValueRanges_Loaded_storeAction({ rootState, dispatch }) {
        if (state.tractionCheck_Inputs_ValueListsAndSelectionsAndPermissibleValueRanges_Loaded == true) {
            dispatch('checkIf_wrappingAngle_hasToBeCalculated_before_tractionCheck_storeAction')
        }
        else {
            await dispatch('get_tractionCheck_Inputs_ValueListsAndSelectionsAndPermissibleValueRanges_Informations_storeAction');

            if ((rootState.statusHandling.status.statusInContextOf == 'get_tractionCheck_Inputs_ValueListsAndSelectionsAndPermissibleValueRanges_Informations') && ((rootState.statusHandling.status.statusCode.substring(0, 1) == '2') || (rootState.statusHandling.status.statusCode.substring(0, 2) == '74') || (rootState.statusHandling.status.statusCode.substring(0, 2) == '75'))) {
                dispatch('checkIf_wrappingAngle_hasToBeCalculated_before_tractionCheck_storeAction')
            }
        }
    },
    async checkIf_tractionCheck_Inputs_ValueListsAndSelectionsAndPermissibleValueRanges_Loaded2_storeAction({ rootState, dispatch }) {
        if (state.tractionCheck_Inputs_ValueListsAndSelectionsAndPermissibleValueRanges_Loaded == true) {
            dispatch('checkIf_wrappingAngle_hasToBeCalculated_before_tractionCheck_with_Data_fromItemOf_elevatorMotor_Machine_Selection_storeAction')
        }
        else {
            await dispatch('get_tractionCheck_Inputs_ValueListsAndSelectionsAndPermissibleValueRanges_Informations_storeAction');
            if ((rootState.statusHandling.status.statusInContextOf == 'get_tractionCheck_Inputs_ValueListsAndSelectionsAndPermissibleValueRanges_Informations') && ((rootState.statusHandling.status.statusCode.substring(0, 1) == '2') || (rootState.statusHandling.status.statusCode.substring(0, 2) == '74') || (rootState.statusHandling.status.statusCode.substring(0, 2) == '75'))) {
                dispatch('checkIf_wrappingAngle_hasToBeCalculated_before_tractionCheck_with_Data_fromItemOf_elevatorMotor_Machine_Selection_storeAction')
            }
        }
    },

    checkIf_wrappingAngle_hasToBeCalculated_before_tractionCheck_storeAction({ rootState, commit, dispatch }) {
        commit('change_tractionCheck_storeMutation', {});
        commit('change_calculated_wrappingAngle_storeMutation', null);
        commit('change_wrappingAngle_Calculated_storeMutation', rootState.inputs.wrappingAngle_Calculated);

        if (rootState.inputs.wrappingAngle_Calculated == true) {
            dispatch('get_wrappingAngleCalculation_Informations_storeAction', false);
        }
        else {
            dispatch('get_tractionCheck_Informations_storeAction', false);
        }
    },
    checkIf_wrappingAngle_hasToBeCalculated_before_tractionCheck_with_Data_fromItemOf_elevatorMotor_Machine_Selection_storeAction({ rootState, commit }) {
        if (rootState.inputs.wrappingAngle_Calculated == true) {
            if (state.elevatorMotor_Machine_forWhich_wrappingAngleCalculation_and_tractionCheck_isMade.suspensionMeans.wrappingAngleCalculated == true) {
                commit('change_wrappingAngle_Calculated_storeMutation', true);
                commit('change_verticalDistanceOfTheAxlesFromTractionSheaveAndDeflectionPulley_storeMutation', { value: state.elevatorMotor_Machine_forWhich_wrappingAngleCalculation_and_tractionCheck_isMade.suspensionMeans.verticalDistanceOfTheAxlesFromTractionSheaveAndDeflectionPulley, invalid: false });
                commit('change_horizontalDistanceOfTheRopeHoles_storeMutation', { value: state.elevatorMotor_Machine_forWhich_wrappingAngleCalculation_and_tractionCheck_isMade.suspensionMeans.horizontalDistanceOfTheRopeHoles, invalid: false });
                commit('change_deflectionPulleyDiameter_storeMutation', { value: state.elevatorMotor_Machine_forWhich_wrappingAngleCalculation_and_tractionCheck_isMade.suspensionMeans.deflectionPulleyDiameter, invalid: false });
                commit('change_wrappingAngleCalculation_Inputs_Invalid_storeMutation', false)
                commit('change_calculated_wrappingAngle_storeMutation', state.elevatorMotor_Machine_forWhich_wrappingAngleCalculation_and_tractionCheck_isMade.suspensionMeans.wrappingAngle);
                commit('change_entered_wrappingAngle_storeMutation', { value: null, invalid: null });
                commit('change_wrappingAngleCalculation_Inputs_Invalid_storeMutation', false)
                commit('change_wrappingAngleCalculation_Modal_Shown_storeMutation', true);
            }
            else if (state.elevatorMotor_Machine_forWhich_wrappingAngleCalculation_and_tractionCheck_isMade.suspensionMeans.wrappingAngleCalculated == false) {
                commit('change_wrappingAngle_Calculated_storeMutation', false);
                commit('change_verticalDistanceOfTheAxlesFromTractionSheaveAndDeflectionPulley_storeMutation', { value: null, invalid: null });
                commit('change_horizontalDistanceOfTheRopeHoles_storeMutation', { value: null, invalid: null });
                commit('change_deflectionPulleyDiameter_storeMutation', { value: null, invalid: null });
                commit('change_wrappingAngleCalculation_Inputs_Invalid_storeMutation', null);
                commit('change_wrappingAngle_Property_of_suspensionMeans_Object_atItemOf_elevatorMotor_Machine_Selection_EnteredNotCalculated_storeMutation', true);
                commit('change_calculated_wrappingAngle_storeMutation', null);
                commit('change_entered_wrappingAngle_storeMutation', { value: state.elevatorMotor_Machine_forWhich_wrappingAngleCalculation_and_tractionCheck_isMade.suspensionMeans.wrappingAngle, invalid: false });
                commit('change_wrappingAngleCalculation_Modal_Shown_storeMutation', true);
            }
        }
        else if (rootState.inputs.wrappingAngle_Calculated == false) {
            commit('change_ropePulleyDiameter_storeMutation', { value: state.elevatorMotor_Machine_forWhich_wrappingAngleCalculation_and_tractionCheck_isMade.suspensionMeans.ropePulleyDiameter, invalid: false });
            commit('change_numberOfRopePulleysWithUniformBending_storeMutation', { value: state.elevatorMotor_Machine_forWhich_wrappingAngleCalculation_and_tractionCheck_isMade.suspensionMeans.numberOfRopePulleysWithUniformBending, invalid: false });
            commit('change_numberOfRopePulleysWithReverseBending_storeMutation', { value: state.elevatorMotor_Machine_forWhich_wrappingAngleCalculation_and_tractionCheck_isMade.suspensionMeans.numberOfRopePulleysWithReverseBending, invalid: false });
            commit('change_sheaveHardened_storeMutation', state.elevatorMotor_Machine_forWhich_wrappingAngleCalculation_and_tractionCheck_isMade.elevatorMotor_TractionSheave.sheaveHardened);
            commit('change_sheaveProfile_storeMutation', state.elevatorMotor_Machine_forWhich_wrappingAngleCalculation_and_tractionCheck_isMade.elevatorMotor_TractionSheave.sheaveProfile);
            commit('change_undercutAngle_Disabled_storeMutation', state.elevatorMotor_Machine_forWhich_wrappingAngleCalculation_and_tractionCheck_isMade.elevatorMotor_TractionSheave.undercutAngleDisabled);
            commit('change_undercutAngle_storeMutation', state.elevatorMotor_Machine_forWhich_wrappingAngleCalculation_and_tractionCheck_isMade.elevatorMotor_TractionSheave.undercutAngle);
            commit('change_vgrooveAngle_Disabled_storeMutation', state.elevatorMotor_Machine_forWhich_wrappingAngleCalculation_and_tractionCheck_isMade.elevatorMotor_TractionSheave.vgrooveAngleDisabled);
            commit('change_vgrooveAngle_storeMutation', state.elevatorMotor_Machine_forWhich_wrappingAngleCalculation_and_tractionCheck_isMade.elevatorMotor_TractionSheave.vgrooveAngle);
            commit('change_minimumValues_Disabled_storeMutation', state.elevatorMotor_Machine_forWhich_wrappingAngleCalculation_and_tractionCheck_isMade.tractionCheck.minimumValuesDisabled);
            commit('change_calculatedWithMinimumValues_storeMutation', state.elevatorMotor_Machine_forWhich_wrappingAngleCalculation_and_tractionCheck_isMade.tractionCheck.calculatedWithMinimumValues);
            commit('change_shortenedBufferStroke_Disabled_storeMutation', state.elevatorMotor_Machine_forWhich_wrappingAngleCalculation_and_tractionCheck_isMade.tractionCheck.shortenedBufferStrokeDisabled);
            commit('change_calculatedWithShortenedBufferStroke_storeMutation', state.elevatorMotor_Machine_forWhich_wrappingAngleCalculation_and_tractionCheck_isMade.tractionCheck.calculatedWithShortenedBufferStroke);
            commit('change_tractionCheck_Inputs_Invalid_storeMutation', false)
            commit('change_minimumCarWeight_storeMutation', state.elevatorMotor_Machine_forWhich_wrappingAngleCalculation_and_tractionCheck_isMade.elevatorSystem.minimumCarWeight);
            commit('change_maximumCarWeight_storeMutation', state.elevatorMotor_Machine_forWhich_wrappingAngleCalculation_and_tractionCheck_isMade.elevatorSystem.maximumCarWeight);
            commit('change_tractionCheck_storeMutation', state.elevatorMotor_Machine_forWhich_wrappingAngleCalculation_and_tractionCheck_isMade)
            commit('change_tractionCheck_Modal_Shown_storeMutation', true)
        }
    },

    add_wrappingAngleCalculation_and_tractionCheck_Data_at_itemOf_elevatorMotor_Machine_Selection_storeAction({ dispatch }, value) {
        const firstIndex_whichMeetConditions = state.elevatorMotor_Machine_Data.elevatorMotor_Machine_Selection.findIndex(i => (i.elevatorMotor_Machine.blockNumberMachine == state.elevatorMotor_Machine_forWhich_wrappingAngleCalculation_and_tractionCheck_isMade.elevatorMotor_Machine.blockNumberMachine) && (i.elevatorMotor_Machine.blockNumberGear == state.elevatorMotor_Machine_forWhich_wrappingAngleCalculation_and_tractionCheck_isMade.elevatorMotor_Machine.blockNumberGear) && (i.elevatorMotor_TractionSheave.blockNumber == state.elevatorMotor_Machine_forWhich_wrappingAngleCalculation_and_tractionCheck_isMade.elevatorMotor_TractionSheave.blockNumber))
        if (firstIndex_whichMeetConditions > -1) {
            dispatch('add_elevatorSystem_Object_atElementOf_elevatorMotor_Machine_Selection_storeAction', { index: firstIndex_whichMeetConditions, elevatorSystem: value.elevatorSystem })
            dispatch('add_suspensionMeans_Object_atElementOf_elevatorMotor_Machine_Selection_storeAction', { index: firstIndex_whichMeetConditions, suspensionMeans: value.suspensionMeans })
            dispatch('change_tractionCheck_Data_at_elevatorMotor_TractionSheave_Object_atElementOf_elevatorMotor_Machine_Selection_storeAction', { index: firstIndex_whichMeetConditions, elevatorMotor_TractionSheave: value.elevatorMotor_TractionSheave })
            dispatch('add_tractionCheck_Object_atElementOf_elevatorMotor_Machine_Selection_storeAction', { index: firstIndex_whichMeetConditions, tractionCheck: value.tractionCheck })
        }
        else {
            // error
        }
    },
    add_wrappingAngleCalculation_and_frame_Data_at_itemOf_elevatorMotor_Machine_Selection_storeAction({ dispatch }, value) {
        const firstIndex_whichMeetConditions = state.elevatorMotor_Machine_Data.elevatorMotor_Machine_Selection.findIndex(i => (i.elevatorMotor_Machine.blockNumberMachine == state.elevatorMotor_Machine_forWhich_wrappingAngleCalculation_and_tractionCheck_isMade.elevatorMotor_Machine.blockNumberMachine) && (i.elevatorMotor_Machine.blockNumberGear == state.elevatorMotor_Machine_forWhich_wrappingAngleCalculation_and_tractionCheck_isMade.elevatorMotor_Machine.blockNumberGear) && (i.elevatorMotor_TractionSheave.blockNumber == state.elevatorMotor_Machine_forWhich_wrappingAngleCalculation_and_tractionCheck_isMade.elevatorMotor_TractionSheave.blockNumber))
        if (firstIndex_whichMeetConditions > -1) {
            dispatch('add_frame_Object_atElementOf_elevatorMotor_Machine_Selection_storeAction', { index: firstIndex_whichMeetConditions, frameSelection: value.frame_Selection })
        }
        else {
            // error
        }
    },
    change_wrappingAngleCalculation_and_tractionCheck_Data_at_itemOf_elevatorMotor_Machine_Selection_storeAction({ commit }, value) {
        const firstIndex_whichMeetConditions = state.elevatorMotor_Machine_Data.elevatorMotor_Machine_Selection.findIndex(i => (i.elevatorMotor_Machine.blockNumberMachine == state.elevatorMotor_Machine_forWhich_wrappingAngleCalculation_and_tractionCheck_isMade.elevatorMotor_Machine.blockNumberMachine) && (i.elevatorMotor_Machine.blockNumberGear == state.elevatorMotor_Machine_forWhich_wrappingAngleCalculation_and_tractionCheck_isMade.elevatorMotor_Machine.blockNumberGear) && (i.elevatorMotor_TractionSheave.blockNumber == state.elevatorMotor_Machine_forWhich_wrappingAngleCalculation_and_tractionCheck_isMade.elevatorMotor_TractionSheave.blockNumber))
        if (firstIndex_whichMeetConditions > -1) {
            commit('change_elevatorSystem_Object_at_ElementOf_elevatorMotor_Machine_Selection_storeMutation', { index: firstIndex_whichMeetConditions, elevatorSystem: value.elevatorSystem })
            commit('change_suspensionMeans_Object_at_ElementOf_elevatorMotor_Machine_Selection_storeMutation', { index: firstIndex_whichMeetConditions, suspensionMeans: value.suspensionMeans })
            commit('change_tractionCheck_Data_at_elevatorMotor_TractionSheave_Object_at_ElementOf_elevatorMotor_Machine_Selection_storeMutation', { index: firstIndex_whichMeetConditions, elevatorMotor_TractionSheave: value.elevatorMotor_TractionSheave })
            commit('change_tractionCheck_Object_at_ElementOf_elevatorMotor_Machine_Selection_storeMutation', { index: firstIndex_whichMeetConditions, tractionCheck: value.tractionCheck })
        }
        else {
            // error
        }
    },
    delete_wrappingAngleCalculation_and_tractionCheck_Data_at_itemOf_elevatorMotor_Machine_Selection_storeAction({ commit }) {
        const firstIndex_whichMeetConditions = state.elevatorMotor_Machine_Data.elevatorMotor_Machine_Selection.findIndex(i => (i.elevatorMotor_Machine.blockNumberMachine == state.elevatorMotor_Machine_forWhich_wrappingAngleCalculation_and_tractionCheck_isMade.elevatorMotor_Machine.blockNumberMachine) && (i.elevatorMotor_Machine.blockNumberGear == state.elevatorMotor_Machine_forWhich_wrappingAngleCalculation_and_tractionCheck_isMade.elevatorMotor_Machine.blockNumberGear) && (i.elevatorMotor_TractionSheave.blockNumber == state.elevatorMotor_Machine_forWhich_wrappingAngleCalculation_and_tractionCheck_isMade.elevatorMotor_TractionSheave.blockNumber))
        if (firstIndex_whichMeetConditions > -1) {
            commit('delete_elevatorSystem_Object_at_ElementOf_elevatorMotor_Machine_Selection_storeMutation', firstIndex_whichMeetConditions)
            commit('delete_suspensionMeans_Object_at_ElementOf_elevatorMotor_Machine_Selection_storeMutation', firstIndex_whichMeetConditions)
            commit('change_tractionCheck_Data_at_elevatorMotor_TractionSheave_Object_at_ElementOf_elevatorMotor_Machine_Selection_toNull_storeMutation', firstIndex_whichMeetConditions)
            commit('delete_tractionCheck_Object_at_ElementOf_elevatorMotor_Machine_Selection_storeMutation', firstIndex_whichMeetConditions)
            commit('change_frame_Data_at_ElementOf_elevatorMotor_Machine_Selection_toNull_storeMutation', firstIndex_whichMeetConditions)
            //commit('delete_frame_Data_at_ElementOf_elevatorMotor_Machine_Selection_storeMutation', firstIndex_whichMeetConditions)
        }
        else {
            // error
        }
    },

    add_elevatorSystem_Object_atElementOf_elevatorMotor_Machine_Selection_storeAction({ commit }, value) {
        commit('add_elevatorSystem_Object_at_ElementOf_elevatorMotor_Machine_Selection_storeMutation', value)
    },
    add_suspensionMeans_Object_atElementOf_elevatorMotor_Machine_Selection_storeAction({ commit }, value) {
        commit('add_suspensionMeans_Object_at_ElementOf_elevatorMotor_Machine_Selection_storeMutation', value)
    },
    change_tractionCheck_Data_at_elevatorMotor_TractionSheave_Object_atElementOf_elevatorMotor_Machine_Selection_storeAction({ commit }, value) {
        commit('change_tractionCheck_Data_at_elevatorMotor_TractionSheave_Object_at_ElementOf_elevatorMotor_Machine_Selection_storeMutation', value)
    },
    change_frame_Data_atElementOf_elevatorMotor_Machine_Selection_storeAction({ commit }, value) {
        commit('change_frame_Data_at_ElementOf_elevatorMotor_Machine_Selection_storeMutation', value)
    },
    add_tractionCheck_Object_atElementOf_elevatorMotor_Machine_Selection_storeAction({ commit }, value) {
        commit('add_tractionCheck_Object_at_ElementOf_elevatorMotor_Machine_Selection_storeMutation', value)
    },
    add_frame_Object_atElementOf_elevatorMotor_Machine_Selection_storeAction({ commit }, value) {
        commit('add_frame_Object_at_ElementOf_elevatorMotor_Machine_Selection_storeMutation', value)
    },


    // wrappingAngleCalculation
    change_wrappingAngleCalculation_toDefault_storeAction({ commit }) {
        commit('change_wrappingAngleCalculation_Modal_Shown_storeMutation', false)

        commit('abort_httpRequests_get_wrappingAngleCalculation_Informations_storeMutation');
        commit('abort_httpRequests_check_wrappingAngleCalculation_Data_byCalculationDll_storeMutation');

        commit('change_elevatorMotor_Machine_forWhich_wrappingAngleCalculation_and_tractionCheck_isMade_storeMutation', {});

        commit('change_loader_forLoadingAllContent_at_wrappingAngleCalculation_Modal_Shown_storeMutation', false);
        commit('change_loader_forLoadingResults_at_wrappingAngleCalculation_Modal_Shown_storeMutation', false);

        commit('change_verticalDistanceOfTheAxlesFromTractionSheaveAndDeflectionPulley_storeMutation', { value: undefined, invalid: undefined });
        commit('change_horizontalDistanceOfTheRopeHoles_storeMutation', { value: undefined, invalid: undefined });
        commit('change_deflectionPulleyDiameter_storeMutation', { value: undefined, invalid: undefined });
        commit('change_wrappingAngle_Calculated_storeMutation', undefined);
        commit('change_calculated_wrappingAngle_storeMutation', undefined);
        commit('change_entered_wrappingAngle_storeMutation', { value: undefined, invalid: undefined });
        commit('change_wrappingAngleCalculation_Inputs_Invalid_storeMutation', undefined);

        commit('change_wrappingAngle_Property_of_suspensionMeans_Object_atItemOf_elevatorMotor_Machine_Selection_EnteredNotCalculated_storeMutation', undefined);
    },

    get_wrappingAngleCalculation_Object_fromItemOf_elevatorMotor_Machine_Selection_storeAction({ commit }, selected_elevatorMotor_Machine) {
        commit('change_wrappingAngle_Calculated_storeMutation', selected_elevatorMotor_Machine.suspensionMeans.wrappingAngleCalculated)

        commit('change_verticalDistanceOfTheAxlesFromTractionSheaveAndDeflectionPulley_storeMutation', { value: selected_elevatorMotor_Machine.suspensionMeans.verticalDistanceOfTheAxlesFromTractionSheaveAndDeflectionPulley, invalid: false });
        commit('change_horizontalDistanceOfTheRopeHoles_storeMutation', { value: selected_elevatorMotor_Machine.suspensionMeans.horizontalDistanceOfTheRopeHoles, invalid: false });
        commit('change_deflectionPulleyDiameter_storeMutation', { value: selected_elevatorMotor_Machine.suspensionMeans.deflectionPulleyDiameter, invalid: false });
        commit('change_wrappingAngleCalculation_Inputs_Invalid_storeMutation', false);

        if (selected_elevatorMotor_Machine.suspensionMeans.wrappingAngleCalculated == true) {
            commit('change_calculated_wrappingAngle_storeMutation', selected_elevatorMotor_Machine.suspensionMeans.wrappingAngle);
            commit('change_entered_wrappingAngle_storeMutation', { value: null, invalid: false });
        }
        else if (selected_elevatorMotor_Machine.suspensionMeans.wrappingAngleCalculated == false) {
            commit('change_calculated_wrappingAngle_storeMutation', null);
            commit('change_entered_wrappingAngle_storeMutation', { value: selected_elevatorMotor_Machine.suspensionMeans.wrappingAngle, invalid: false });
        }
    },

    backTo_wrappingAngle_Modal_storeAction({ commit }) {
        commit('change_tractionCheck_Modal_Shown_storeMutation', false);
        commit('change_wrappingAngleCalculation_Modal_Shown_storeMutation', true);
    },
    change_wrappingAngleCalculation_Modal_Shown_storeAction({ commit, dispatch }, value) {
        if (value == true) {
            commit('change_wrappingAngleCalculation_Modal_Shown_storeMutation', value)
        }
        else if (value == false) {
            if (state.elevatorMotor_Machine_Selection_Shown == true) {
                dispatch('checkIf_changesAt_wrappingAngleCalculation_changes_configurationFlow_storeAction')
            }
            else {
                dispatch('change_wrappingAngleCalculation_toDefault_storeAction')
            }
        }
    },

    checkIf_changesAt_wrappingAngleCalculation_changes_configurationFlow_storeAction({ rootState, dispatch }) {
        if (state.elevatorMotor_Machine_forWhich_wrappingAngleCalculation_and_tractionCheck_isMade.tractionCheck_Executed == true) {
            var inputs_Changed = false;

            if (state.elevatorMotor_Machine_forWhich_wrappingAngleCalculation_and_tractionCheck_isMade.suspensionMeans.wrappingAngleCalculated == true) {
                if ((state.verticalDistanceOfTheAxlesFromTractionSheaveAndDeflectionPulley.value != state.elevatorMotor_Machine_forWhich_wrappingAngleCalculation_and_tractionCheck_isMade.suspensionMeans.verticalDistanceOfTheAxlesFromTractionSheaveAndDeflectionPulley)
                    || (state.horizontalDistanceOfTheRopeHoles.value != state.elevatorMotor_Machine_forWhich_wrappingAngleCalculation_and_tractionCheck_isMade.suspensionMeans.horizontalDistanceOfTheRopeHoles)
                    || (state.deflectionPulleyDiameter.value != state.elevatorMotor_Machine_forWhich_wrappingAngleCalculation_and_tractionCheck_isMade.suspensionMeans.deflectionPulleyDiameter)
                ) {
                    inputs_Changed = true;
                }
            }
            else if (state.elevatorMotor_Machine_forWhich_wrappingAngleCalculation_and_tractionCheck_isMade.suspensionMeans.wrappingAngleCalculated == false) {
                if (state.entered_wrappingAngle.value != state.elevatorMotor_Machine_forWhich_wrappingAngleCalculation_and_tractionCheck_isMade.suspensionMeans.wrappingAngle) {
                    inputs_Changed = true;
                }
            }


            if (inputs_Changed == true) {
                if (state.wrappingAngleCalculation_Inputs_Invalid == true) {
                    dispatch('delete_wrappingAngleCalculation_and_tractionCheck_Data_at_itemOf_elevatorMotor_Machine_Selection_storeAction')
                    dispatch('change_Selected_atEveryElementOf_elevatorMotor_Machine_Selection_toFalse_storeAction')
                }

                if (rootState.configurations.configurationSteps.elevatorMotor_Machine.storedInDatabase == true) {
                    this.dispatch('configurations/selectAndInsert_or_updateAndOrDelete_databaseRecords_storeAction', { changesAt: this._vm.$configurationSteps.wrappingAngleCalculaion });

                }
            }
        }

        dispatch('change_wrappingAngleCalculation_toDefault_storeAction')
    },

    change_wrappingAngle_Calculated_storeAction({ commit, dispatch }, value) {
        commit('change_wrappingAngle_Calculated_storeMutation', value);
        if ((state.wrappingAngle_Calculated == true) && (state.wrappingAngle_Property_of_suspensionMeans_Object_atItemOf_elevatorMotor_Machine_Selection_EnteredNotCalculated == true)) {
            commit('change_wrappingAngle_Property_of_suspensionMeans_Object_atItemOf_elevatorMotor_Machine_Selection_EnteredNotCalculated_storeMutation', false);
            dispatch('get_wrappingAngleCalculation_Informations_storeAction', false);
        }
    },
    change_elevatorMotor_Frame_toDefault_storeAction({commit }) {
        commit('change_frame_Data_storeMutation', []);
        commit('change_frame_horizontalDistance_Minimum_storeMutation', null);
        commit('change_frame_horizontalDistance_Maximum_storeMutation', null);
        commit('change_wrappingAngleFrameCalculation_Modal_Shown_storeMutation', false);
        commit('change_wrappingAngleCalculation_Modal_Shown_storeMutation', false);
        commit('change_frameInformation_storeMutation', null);
    },
    change_verticalDistanceOfTheAxlesFromTractionSheaveAndDeflectionPulley_storeAction({ commit, dispatch }, value) {
        const valueAndValueValidationCriteria = this._vm.$transmit_valueAndValueValidationCriteria;
        valueAndValueValidationCriteria.value = value;
        valueAndValueValidationCriteria.dataType = this._vm.$dataTypes.decimal;
        valueAndValueValidationCriteria.permissibleNumberOfDecimalPlaces = 2;
        valueAndValueValidationCriteria.permissibleValueRange.minimum.value = 0;
        valueAndValueValidationCriteria.permissibleValueRange.minimum.operator = this._vm.$operators.greaterThanOrEqualTo;
        valueAndValueValidationCriteria.permissibleValueRange.maximum.value = null;
        valueAndValueValidationCriteria.permissibleValueRange.maximum.operator = null;
        const verticalDistanceOfTheAxlesFromTractionSheaveAndDeflectionPulley_ValidationResult = this._vm.$checkIf_value_isValid(valueAndValueValidationCriteria);

        commit('change_verticalDistanceOfTheAxlesFromTractionSheaveAndDeflectionPulley_storeMutation', verticalDistanceOfTheAxlesFromTractionSheaveAndDeflectionPulley_ValidationResult);
        dispatch('checkIf_wrappingAngleCalculation_Inputs_areValid_storeAction')
    },
    change_horizontalDistanceOfTheRopeHoles_storeAction({ commit, dispatch }, value) {
        const valueAndValueValidationCriteria = this._vm.$transmit_valueAndValueValidationCriteria;
        valueAndValueValidationCriteria.value = value;
        valueAndValueValidationCriteria.dataType = this._vm.$dataTypes.decimal;
        valueAndValueValidationCriteria.permissibleNumberOfDecimalPlaces = 2;
        valueAndValueValidationCriteria.permissibleValueRange.minimum.value = 0;
        valueAndValueValidationCriteria.permissibleValueRange.minimum.operator = this._vm.$operators.greaterThanOrEqualTo;
        valueAndValueValidationCriteria.permissibleValueRange.maximum.value = null;
        valueAndValueValidationCriteria.permissibleValueRange.maximum.operator = null;
        const horizontalDistanceOfTheRopeHoles_ValidationResult = this._vm.$checkIf_value_isValid(valueAndValueValidationCriteria);

        commit('change_horizontalDistanceOfTheRopeHoles_storeMutation', horizontalDistanceOfTheRopeHoles_ValidationResult);
        dispatch('checkIf_wrappingAngleCalculation_Inputs_areValid_storeAction')
    },
    change_horizontalDistanceOfTheRopeHoles2_storeAction({ commit, dispatch }, value) {
        const valueAndValueValidationCriteria = this._vm.$transmit_valueAndValueValidationCriteria;
        valueAndValueValidationCriteria.value = value;
        valueAndValueValidationCriteria.dataType = this._vm.$dataTypes.decimal;
        valueAndValueValidationCriteria.permissibleNumberOfDecimalPlaces = 2;
        valueAndValueValidationCriteria.permissibleValueRange.minimum.value = state.horizontalDistance_Minimum;
        valueAndValueValidationCriteria.permissibleValueRange.minimum.operator = this._vm.$operators.greaterThanOrEqualTo;
        valueAndValueValidationCriteria.permissibleValueRange.maximum.value = state.horizontalDistance_Maximum;
        valueAndValueValidationCriteria.permissibleValueRange.maximum.operator = this._vm.$operators.lessThanOrEqualTo;
        const horizontalDistanceOfTheRopeHoles_ValidationResult = this._vm.$checkIf_value_isValid(valueAndValueValidationCriteria);

        commit('change_horizontalDistanceOfTheRopeHoles_storeMutation', horizontalDistanceOfTheRopeHoles_ValidationResult);
        dispatch('checkIf_wrappingAngleCalculation_Inputs_areValid_storeAction')
    },
    change_deflectionPulleyDiameter_storeAction({ commit, dispatch }, value) {
        const valueAndValueValidationCriteria = this._vm.$transmit_valueAndValueValidationCriteria;
        valueAndValueValidationCriteria.value = value;
        valueAndValueValidationCriteria.dataType = this._vm.$dataTypes.integer;
        valueAndValueValidationCriteria.permissibleNumberOfDecimalPlaces = null;
        valueAndValueValidationCriteria.permissibleValueRange.minimum.value = 0;
        valueAndValueValidationCriteria.permissibleValueRange.minimum.operator = this._vm.$operators.greaterThanOrEqualTo;
        valueAndValueValidationCriteria.permissibleValueRange.maximum.value = null;
        valueAndValueValidationCriteria.permissibleValueRange.maximum.operator = null;
        const deflectionPulleyDiameter_ValidationResult = this._vm.$checkIf_value_isValid(valueAndValueValidationCriteria);

        commit('change_deflectionPulleyDiameter_storeMutation', deflectionPulleyDiameter_ValidationResult);
        dispatch('checkIf_wrappingAngleCalculation_Inputs_areValid_storeAction')
    },
    checkIf_wrappingAngleCalculation_Inputs_areValid_storeAction({ commit, dispatch }) {
        if ((state.verticalDistanceOfTheAxlesFromTractionSheaveAndDeflectionPulley.invalid == false) && (state.horizontalDistanceOfTheRopeHoles.invalid == false) && (state.deflectionPulleyDiameter.invalid == false)) {
            commit('change_wrappingAngleCalculation_Inputs_Invalid_storeMutation', false);
            dispatch('get_wrappingAngleCalculation_Informations_storeAction', true);
        }
        else {
            commit('change_wrappingAngleCalculation_Inputs_Invalid_storeMutation', true);
            commit('abort_httpRequests_get_wrappingAngleCalculation_Informations_storeMutation');
        }
    },
    change_entered_wrappingAngle_storeAction({ commit }, value) {
        const valueAndValueValidationCriteria = this._vm.$transmit_valueAndValueValidationCriteria;
        valueAndValueValidationCriteria.value = value;
        valueAndValueValidationCriteria.dataType = this._vm.$dataTypes.decimal;
        valueAndValueValidationCriteria.permissibleNumberOfDecimalPlaces = 2;
        valueAndValueValidationCriteria.permissibleValueRange.minimum.value = 0;
        valueAndValueValidationCriteria.permissibleValueRange.minimum.operator = this._vm.$operators.greaterThan;
        valueAndValueValidationCriteria.permissibleValueRange.maximum.value = null;
        valueAndValueValidationCriteria.permissibleValueRange.maximum.operator = null;
        const entered_wrappingAngle_ValidationResult = this._vm.$checkIf_value_isValid(valueAndValueValidationCriteria);

        commit('change_entered_wrappingAngle_storeMutation', entered_wrappingAngle_ValidationResult);
    },

    async get_wrappingAngleCalculation_Informations_storeAction({ rootState, commit, dispatch }, inputs_Transmitted) {
        commit('change_wrappingAngleCalculation_Modal_Shown_storeMutation', true);
        var suspensionMeans = {};

        if (inputs_Transmitted == true) {
            commit('change_loader_forLoadingResults_at_wrappingAngleCalculation_Modal_Shown_storeMutation', true);

            suspensionMeans.verticalDistanceOfTheAxlesFromTractionSheaveAndDeflectionPulley = state.verticalDistanceOfTheAxlesFromTractionSheaveAndDeflectionPulley.value;
            suspensionMeans.horizontalDistanceOfTheRopeHoles = state.horizontalDistanceOfTheRopeHoles.value;
            suspensionMeans.deflectionPulleyDiameter = state.deflectionPulleyDiameter.value;
        }
        else {
            commit('change_loader_forLoadingAllContent_at_wrappingAngleCalculation_Modal_Shown_storeMutation', true);
        }

        var frames = null;
        if (state.frame_Data.hasOwnProperty('frame_Selection') && state.frame_Data.frame_Selection.length > 0) {
            const firstIndex_frameSelected = state.frame_Data.frame_Selection.findIndex(i => (i.selected == true))

            if (firstIndex_frameSelected > -1) {
                frames = state.frame_Data.frame_Selection[firstIndex_frameSelected].frame;
            }

        }
        const data = {
            configurationOverviewsId: rootState.configurations.configurationOverviewsId,
            suspensionMeans: suspensionMeans,
            elevatorMotor_TractionSheave: state.elevatorMotor_Machine_forWhich_wrappingAngleCalculation_and_tractionCheck_isMade.elevatorMotor_TractionSheave,
            elevatorMotor_Machine: state.elevatorMotor_Machine_forWhich_wrappingAngleCalculation_and_tractionCheck_isMade.elevatorMotor_Machine,
            wrappingAngleFrameCalculationModal_Shown: state.wrappingAngleFrameCalculation_Modal_Shown,
            inputs_Transmitted: inputs_Transmitted,
            frame: frames,
        }

        commit('abort_httpRequests_get_wrappingAngleCalculation_Informations_storeMutation');
        if (state.cancellationInformationsAboutHttpRequests_get_wrappingAngleCalculation_Informations.signal.aborted == true) {
            commit('change_cancellationInformationsAboutHttpRequests_get_wrappingAngleCalculation_Informations_toNewAbortController_storeMutation')
        }

        await axios({
            method: 'post',
            url: '/api/driveTechnologies/get_wrappingAngleCalculation_Informations',
            signal: state.cancellationInformationsAboutHttpRequests_get_wrappingAngleCalculation_Informations.signal,
            data: {
                data: data,
            }
        })
            .then((response) => {
                this.dispatch('statusHandling/change_dataAndStatus_storeAction', response.data)
                if ((response.data.status.statusCode.substring(0, 1) == '2') || (response.data.status.statusCode.substring(0, 2) == '74') || (response.data.status.statusCode.substring(0, 2) == '75')) {
                    dispatch('toDos_afterResponseOf_get_wrappingAngleCalculation_Informations_storeAction', response.data.data)
                }

                commit('change_loader_forLoadingResults_at_wrappingAngleCalculation_Modal_Shown_storeMutation', false);
            })
            .catch(() => { })
            .then(() => {
                commit('change_loader_forLoadingAllContent_at_wrappingAngleCalculation_Modal_Shown_storeMutation', false);

            })
    },
    toDos_afterResponseOf_get_wrappingAngleCalculation_Informations_storeAction({ commit, dispatch }, value) {
        commit('change_calculated_wrappingAngle_storeMutation', value.calculated_WrappingAngle);
        if (value.inputs_Transmitted == false) {
            commit('change_deflectionPulleyDiameter_storeMutation', { value: value.deflectionPulleyDiameter, invalid: false });
            dispatch('change_wrappingAngleFrameCalculation_Modal_Shown_storeAction', value.wrappingAngleFrameCalculation_Modal_Shown)
            dispatch('get_frame_Informations_storeAction', state.elevatorMotor_Machine_forWhich_wrappingAngleCalculation_and_tractionCheck_isMade);
        }
    },

    checkIf_wrappingAngleCalculation_Data_areValid_storeAction({ dispatch }) {
        if (state.wrappingAngle_Calculated == true) {
            if (state.verticalDistanceOfTheAxlesFromTractionSheaveAndDeflectionPulley.invalid == null) {
                dispatch('change_verticalDistanceOfTheAxlesFromTractionSheaveAndDeflectionPulley_storeAction', state.verticalDistanceOfTheAxlesFromTractionSheaveAndDeflectionPulley.value);
            }
            if (state.horizontalDistanceOfTheRopeHoles.invalid == null) {
                dispatch('change_horizontalDistanceOfTheRopeHoles_storeAction', state.horizontalDistanceOfTheRopeHoles.value);
            }
            if (state.deflectionPulleyDiameter.invalid == null) {
                dispatch('change_deflectionPulleyDiameter_storeAction', state.deflectionPulleyDiameter.value);
            }

            if ((state.verticalDistanceOfTheAxlesFromTractionSheaveAndDeflectionPulley.invalid == false) && (state.horizontalDistanceOfTheRopeHoles.invalid == false) && (state.deflectionPulleyDiameter.invalid == false) && (state.calculated_wrappingAngle != null)) {
                dispatch('check_wrappingAngleCalculation_Data_byCalculationDll_storeAction');
            }
        }
        else if (state.wrappingAngle_Calculated == false) {
            if (state.entered_wrappingAngle.invalid == null) {
                dispatch('change_entered_wrappingAngle_storeAction', state.entered_wrappingAngle.value);
            }

            if (state.entered_wrappingAngle.invalid == false) {
                dispatch('check_wrappingAngleCalculation_Data_byCalculationDll_storeAction');
            }
        }
    },

    check_wrappingAngleCalculation_Data_byCalculationDll_storeAction({ rootState, commit, dispatch }) {
        var suspensionMeans = {
            verticalDistanceOfTheAxlesFromTractionSheaveAndDeflectionPulley: state.verticalDistanceOfTheAxlesFromTractionSheaveAndDeflectionPulley.value,
            horizontalDistanceOfTheRopeHoles: state.horizontalDistanceOfTheRopeHoles.value,
            deflectionPulleyDiameter: state.deflectionPulleyDiameter.value,
            wrappingAngleCalculated: state.wrappingAngle_Calculated,
        };

        if (state.wrappingAngle_Calculated == true) {
            suspensionMeans.wrappingAngle = state.calculated_wrappingAngle;
        }
        else if (state.wrappingAngle_Calculated == false) {
            suspensionMeans.wrappingAngle = state.entered_wrappingAngle.value;
        }

        var frames = null;
        if (state.frame_Data.hasOwnProperty('frame_Selection') && state.frame_Data.frame_Selection.length > 0) {
            const firstIndex_frameSelected = state.frame_Data.frame_Selection.findIndex(i => (i.selected == true))

            if (firstIndex_frameSelected > -1) {
                frames = state.frame_Data.frame_Selection[firstIndex_frameSelected].frame;
            }

        }

        const data = {
            configurationOverviewsId: rootState.configurations.configurationOverviewsId,
            elevatorMotor_TractionSheave: state.elevatorMotor_Machine_forWhich_wrappingAngleCalculation_and_tractionCheck_isMade.elevatorMotor_TractionSheave,
            elevatorMotor_Machine: state.elevatorMotor_Machine_forWhich_wrappingAngleCalculation_and_tractionCheck_isMade.elevatorMotor_Machine,
            suspensionMeans: suspensionMeans,
            frame: frames,
        }

        commit('abort_httpRequests_check_wrappingAngleCalculation_Data_byCalculationDll_storeMutation');
        if (state.cancellationInformationsAboutHttpRequests_check_wrappingAngleCalculation_Data_byCalculationDll.signal.aborted == true) {
            commit('change_cancellationInformationsAboutHttpRequests_check_wrappingAngleCalculation_Data_byCalculationDll_toNewAbortController_storeMutation')
        }

        axios({
            method: 'post',
            url: '/api/driveTechnologies/check_wrappingAngleCalculation_Data_byCalculationDll',
            signal: state.cancellationInformationsAboutHttpRequests_check_wrappingAngleCalculation_Data_byCalculationDll.signal,
            data: {
                data: data,
            }
        })
            .then((response) => {
                this.dispatch('statusHandling/change_dataAndStatus_storeAction', response.data)

                if ((response.data.status.statusCode.substring(0, 1) == '2') || (response.data.status.statusCode.substring(0, 2) == '74') || (response.data.status.statusCode.substring(0, 2) == '75')) {
                    if (response.data.data.axleLoad != null) {
                        const firstIndex_whichMeetConditions = state.elevatorMotor_Machine_Data.elevatorMotor_Machine_Selection.findIndex(i => (i.elevatorMotor_Machine.blockNumberMachine == state.elevatorMotor_Machine_forWhich_wrappingAngleCalculation_and_tractionCheck_isMade.elevatorMotor_Machine.blockNumberMachine) && (i.elevatorMotor_Machine.blockNumberGear == state.elevatorMotor_Machine_forWhich_wrappingAngleCalculation_and_tractionCheck_isMade.elevatorMotor_Machine.blockNumberGear) && (i.elevatorMotor_TractionSheave.blockNumber == state.elevatorMotor_Machine_forWhich_wrappingAngleCalculation_and_tractionCheck_isMade.elevatorMotor_TractionSheave.blockNumber))
                        if (firstIndex_whichMeetConditions > -1) {
                            commit('change_axleLoad_at_ElementOf_elevatorMotor_Machine_Selection_storeMutation', { index: firstIndex_whichMeetConditions, axleLoad: response.data.data.axleLoad })
                        }
                    }

                    dispatch('toDos_afterResponseOf_check_wrappingAngleCalculation_Data_byCalculationDll_storeAction')
                }
            })
            .then(() => {
                commit('change_wrappingAngleCalculation_Modal_Shown_storeMutation', false);
            })
    },
    toDos_afterResponseOf_check_wrappingAngleCalculation_Data_byCalculationDll_storeAction({ commit, dispatch }) {
        if (state.elevatorMotor_Machine_forWhich_wrappingAngleCalculation_and_tractionCheck_isMade.tractionCheck_Executed == true) {

            commit('change_ropePulleyDiameter_storeMutation', { value: state.elevatorMotor_Machine_forWhich_wrappingAngleCalculation_and_tractionCheck_isMade.suspensionMeans.ropePulleyDiameter, invalid: false });
            commit('change_numberOfRopePulleysWithUniformBending_storeMutation', { value: state.elevatorMotor_Machine_forWhich_wrappingAngleCalculation_and_tractionCheck_isMade.suspensionMeans.numberOfRopePulleysWithUniformBending, invalid: false });
            commit('change_numberOfRopePulleysWithReverseBending_storeMutation', { value: state.elevatorMotor_Machine_forWhich_wrappingAngleCalculation_and_tractionCheck_isMade.suspensionMeans.numberOfRopePulleysWithReverseBending, invalid: false });
            commit('change_sheaveHardened_storeMutation', state.elevatorMotor_Machine_forWhich_wrappingAngleCalculation_and_tractionCheck_isMade.elevatorMotor_TractionSheave.sheaveHardened);
            commit('change_sheaveProfile_storeMutation', state.elevatorMotor_Machine_forWhich_wrappingAngleCalculation_and_tractionCheck_isMade.elevatorMotor_TractionSheave.sheaveProfile);
            commit('change_undercutAngle_Disabled_storeMutation', state.elevatorMotor_Machine_forWhich_wrappingAngleCalculation_and_tractionCheck_isMade.elevatorMotor_TractionSheave.undercutAngleDisabled);
            commit('change_undercutAngle_storeMutation', state.elevatorMotor_Machine_forWhich_wrappingAngleCalculation_and_tractionCheck_isMade.elevatorMotor_TractionSheave.undercutAngle);
            commit('change_vgrooveAngle_Disabled_storeMutation', state.elevatorMotor_Machine_forWhich_wrappingAngleCalculation_and_tractionCheck_isMade.elevatorMotor_TractionSheave.vgrooveAngleDisabled);
            commit('change_vgrooveAngle_storeMutation', state.elevatorMotor_Machine_forWhich_wrappingAngleCalculation_and_tractionCheck_isMade.elevatorMotor_TractionSheave.vgrooveAngle);
            commit('change_minimumValues_Disabled_storeMutation', state.elevatorMotor_Machine_forWhich_wrappingAngleCalculation_and_tractionCheck_isMade.tractionCheck.minimumValuesDisabled);
            commit('change_calculatedWithMinimumValues_storeMutation', state.elevatorMotor_Machine_forWhich_wrappingAngleCalculation_and_tractionCheck_isMade.tractionCheck.calculatedWithMinimumValues);
            commit('change_shortenedBufferStroke_Disabled_storeMutation', state.elevatorMotor_Machine_forWhich_wrappingAngleCalculation_and_tractionCheck_isMade.tractionCheck.shortenedBufferStrokeDisabled);
            commit('change_calculatedWithShortenedBufferStroke_storeMutation', state.elevatorMotor_Machine_forWhich_wrappingAngleCalculation_and_tractionCheck_isMade.tractionCheck.calculatedWithShortenedBufferStroke);
            commit('change_minimumCarWeight_storeMutation', state.elevatorMotor_Machine_forWhich_wrappingAngleCalculation_and_tractionCheck_isMade.elevatorSystem.minimumCarWeight);
            commit('change_maximumCarWeight_storeMutation', state.elevatorMotor_Machine_forWhich_wrappingAngleCalculation_and_tractionCheck_isMade.elevatorSystem.maximumCarWeight);
            commit('change_tractionCheck_Inputs_Invalid_storeMutation', false)

            dispatch('get_tractionCheck_Informations_storeAction', true);
        }
        else {
            dispatch('get_tractionCheck_Informations_storeAction', false);
        }
    },


    // tractionCheck
    change_tractionCheck_toDefault_storeAction({ commit }) {
        commit('change_tractionCheck_Modal_Shown_storeMutation', false)

        commit('abort_httpRequests_get_tractionCheck_Informations_storeMutation');

        commit('change_elevatorMotor_Machine_forWhich_wrappingAngleCalculation_and_tractionCheck_isMade_storeMutation', {});

        commit('change_verticalDistanceOfTheAxlesFromTractionSheaveAndDeflectionPulley_storeMutation', { value: undefined, invalid: undefined });
        commit('change_horizontalDistanceOfTheRopeHoles_storeMutation', { value: undefined, invalid: undefined });
        commit('change_deflectionPulleyDiameter_storeMutation', { value: undefined, invalid: undefined });
        commit('change_wrappingAngle_Calculated_storeMutation', undefined);
        commit('change_calculated_wrappingAngle_storeMutation', undefined);
        commit('change_entered_wrappingAngle_storeMutation', { value: undefined, invalid: undefined });
        commit('change_wrappingAngleCalculation_Inputs_Invalid_storeMutation', undefined);

        commit('change_wrappingAngle_Property_of_suspensionMeans_Object_atItemOf_elevatorMotor_Machine_Selection_EnteredNotCalculated_storeMutation', undefined);

        commit('change_loader_forLoadingAllContent_at_tractionCheck_Modal_Shown_storeMutation', false);
        commit('change_loader_forLoadingResults_at_tractionCheck_Modal_Shown_storeMutation', false);

        commit('change_tractionCheck_Inputs_ValueListsAndSelectionsAndPermissibleValueRanges_storeMutation', {});
        commit('change_tractionCheck_Inputs_ValueListsAndSelectionsAndPermissibleValueRanges_Loaded_storeMutation', false)

        commit('change_ropePulleyDiameter_storeMutation', { value: undefined, invalid: undefined });
        commit('change_numberOfRopePulleysWithUniformBending_storeMutation', { value: undefined, invalid: undefined });
        commit('change_numberOfRopePulleysWithReverseBending_storeMutation', { value: undefined, invalid: undefined });
        commit('change_sheaveHardened_storeMutation', undefined);
        commit('change_sheaveProfile_storeMutation', undefined);
        commit('change_sheaveProfileAndSheaveHardenedTextKey_storeMutation', undefined);
        commit('change_undercutAngle_Disabled_storeMutation', undefined);
        commit('change_undercutAngle_storeMutation', undefined);
        commit('change_vgrooveAngle_Disabled_storeMutation', undefined);
        commit('change_vgrooveAngle_storeMutation', undefined);
        commit('change_minimumValues_Disabled_storeMutation', undefined);
        commit('change_calculatedWithMinimumValues_storeMutation', undefined);
        commit('change_shortenedBufferStroke_Disabled_storeMutation', undefined);
        commit('change_calculatedWithShortenedBufferStroke_storeMutation', undefined);
        commit('change_tractionCheck_Inputs_Invalid_storeMutation', undefined);

        commit('change_minimumCarWeight_storeMutation', undefined);
        commit('change_maximumCarWeight_storeMutation', undefined);

        commit('change_tractionCheck_storeMutation', {});
    },

    get_tractionCheck_Data_fromItemOf_elevatorMotor_Machine_Selection_storeAction({ commit }, selected_elevatorMotor_Machine) {
        commit('change_ropePulleyDiameter_storeMutation', { value: selected_elevatorMotor_Machine.suspensionMeans.ropePulleyDiameter, invalid: false });
        commit('change_numberOfRopePulleysWithUniformBending_storeMutation', { value: selected_elevatorMotor_Machine.suspensionMeans.numberOfRopePulleysWithUniformBending, invalid: false });
        commit('change_numberOfRopePulleysWithReverseBending_storeMutation', { value: selected_elevatorMotor_Machine.suspensionMeans.numberOfRopePulleysWithReverseBending, invalid: false });
        commit('change_tractionCheck_Inputs_Invalid_storeMutation', false);

        commit('change_sheaveHardened_storeMutation', selected_elevatorMotor_Machine.elevatorMotor_TractionSheave.sheaveHardened);
        commit('change_sheaveProfile_storeMutation', selected_elevatorMotor_Machine.elevatorMotor_TractionSheave.sheaveProfile);
        commit('change_undercutAngle_Disabled_storeMutation', selected_elevatorMotor_Machine.elevatorMotor_TractionSheave.undercutAngleDisabled);
        commit('change_undercutAngle_storeMutation', selected_elevatorMotor_Machine.elevatorMotor_TractionSheave.undercutAngle);
        commit('change_vgrooveAngle_Disabled_storeMutation', selected_elevatorMotor_Machine.elevatorMotor_TractionSheave.vgrooveAngleDisabled);
        commit('change_vgrooveAngle_storeMutation', selected_elevatorMotor_Machine.elevatorMotor_TractionSheave.vgrooveAngle);
        commit('change_minimumValues_Disabled_storeMutation', selected_elevatorMotor_Machine.tractionCheck.minimumValuesDisabled);
        commit('change_calculatedWithMinimumValues_storeMutation', selected_elevatorMotor_Machine.tractionCheck.calculatedWithMinimumValues);
        commit('change_shortenedBufferStroke_Disabled_storeMutation', selected_elevatorMotor_Machine.tractionCheck.shortenedBufferStrokeDisabled);
        commit('change_calculatedWithShortenedBufferStroke_storeMutation', selected_elevatorMotor_Machine.tractionCheck.calculatedWithShortenedBufferStroke);

        commit('change_minimumCarWeight_storeMutation', selected_elevatorMotor_Machine.elevatorSystem.minimumCarWeight);
        commit('change_maximumCarWeight_storeMutation', selected_elevatorMotor_Machine.elevatorSystem.maximumCarWeight);

        commit('change_tractionCheck_storeMutation', selected_elevatorMotor_Machine);
    },

    show_tractionCheck_Modal_storeAction({ commit, dispatch }) {
        if (state.elevatorMotor_Machine_forWhich_wrappingAngleCalculation_and_tractionCheck_isMade.tractionCheck_Executed == true) {
            var inputs_Changed = false;

            if (state.elevatorMotor_Machine_forWhich_wrappingAngleCalculation_and_tractionCheck_isMade.suspensionMeans.wrappingAngleCalculated == true) {
                if ((state.verticalDistanceOfTheAxlesFromTractionSheaveAndDeflectionPulley.value != state.elevatorMotor_Machine_forWhich_wrappingAngleCalculation_and_tractionCheck_isMade.suspensionMeans.verticalDistanceOfTheAxlesFromTractionSheaveAndDeflectionPulley)
                    || (state.horizontalDistanceOfTheRopeHoles.value != state.elevatorMotor_Machine_forWhich_wrappingAngleCalculation_and_tractionCheck_isMade.suspensionMeans.horizontalDistanceOfTheRopeHoles)
                    || (state.deflectionPulleyDiameter.value != state.elevatorMotor_Machine_forWhich_wrappingAngleCalculation_and_tractionCheck_isMade.suspensionMeans.deflectionPulleyDiameter)
                ) {
                    inputs_Changed = true;

                    var suspensionMeans = Object.assign({}, state.elevatorMotor_Machine_forWhich_wrappingAngleCalculation_and_tractionCheck_isMade.suspensionMeans);
                    suspensionMeans.ropePulleyDiameter = state.deflectionPulleyDiameter.value
                    var elevatorMotor_Machine_forWhich_wrappingAngleCalculation_and_tractionCheck_isMade = Object.assign({}, state.elevatorMotor_Machine_forWhich_wrappingAngleCalculation_and_tractionCheck_isMade);
                    elevatorMotor_Machine_forWhich_wrappingAngleCalculation_and_tractionCheck_isMade.suspensionMeans = suspensionMeans
                    commit('change_elevatorMotor_Machine_forWhich_wrappingAngleCalculation_and_tractionCheck_isMade_storeMutation', elevatorMotor_Machine_forWhich_wrappingAngleCalculation_and_tractionCheck_isMade)
                    commit('change_ropePulleyDiameter_storeMutation', { value: state.deflectionPulleyDiameter.value, invalid: false });

                }
            }
            else if (state.elevatorMotor_Machine_forWhich_wrappingAngleCalculation_and_tractionCheck_isMade.suspensionMeans.wrappingAngleCalculated == false) {
                if (state.entered_wrappingAngle.value != state.elevatorMotor_Machine_forWhich_wrappingAngleCalculation_and_tractionCheck_isMade.suspensionMeans.wrappingAngle) {
                    inputs_Changed = true;
                }
            }

            if (inputs_Changed == false) {
                commit('change_ropePulleyDiameter_storeMutation', { value: state.elevatorMotor_Machine_forWhich_wrappingAngleCalculation_and_tractionCheck_isMade.suspensionMeans.ropePulleyDiameter, invalid: false });
                commit('change_numberOfRopePulleysWithUniformBending_storeMutation', { value: state.elevatorMotor_Machine_forWhich_wrappingAngleCalculation_and_tractionCheck_isMade.suspensionMeans.numberOfRopePulleysWithUniformBending, invalid: false });
                commit('change_numberOfRopePulleysWithReverseBending_storeMutation', { value: state.elevatorMotor_Machine_forWhich_wrappingAngleCalculation_and_tractionCheck_isMade.suspensionMeans.numberOfRopePulleysWithReverseBending, invalid: false });
                commit('change_sheaveHardened_storeMutation', state.elevatorMotor_Machine_forWhich_wrappingAngleCalculation_and_tractionCheck_isMade.elevatorMotor_TractionSheave.sheaveHardened);
                commit('change_sheaveProfile_storeMutation', state.elevatorMotor_Machine_forWhich_wrappingAngleCalculation_and_tractionCheck_isMade.elevatorMotor_TractionSheave.sheaveProfile);
                commit('change_undercutAngle_Disabled_storeMutation', state.elevatorMotor_Machine_forWhich_wrappingAngleCalculation_and_tractionCheck_isMade.elevatorMotor_TractionSheave.undercutAngleDisabled);
                commit('change_undercutAngle_storeMutation', state.elevatorMotor_Machine_forWhich_wrappingAngleCalculation_and_tractionCheck_isMade.elevatorMotor_TractionSheave.undercutAngle);
                commit('change_vgrooveAngle_Disabled_storeMutation', state.elevatorMotor_Machine_forWhich_wrappingAngleCalculation_and_tractionCheck_isMade.elevatorMotor_TractionSheave.vgrooveAngleDisabled);
                commit('change_vgrooveAngle_storeMutation', state.elevatorMotor_Machine_forWhich_wrappingAngleCalculation_and_tractionCheck_isMade.elevatorMotor_TractionSheave.vgrooveAngle);
                commit('change_minimumValues_Disabled_storeMutation', state.elevatorMotor_Machine_forWhich_wrappingAngleCalculation_and_tractionCheck_isMade.tractionCheck.minimumValuesDisabled);
                commit('change_calculatedWithMinimumValues_storeMutation', state.elevatorMotor_Machine_forWhich_wrappingAngleCalculation_and_tractionCheck_isMade.tractionCheck.calculatedWithMinimumValues);
                commit('change_shortenedBufferStroke_Disabled_storeMutation', state.elevatorMotor_Machine_forWhich_wrappingAngleCalculation_and_tractionCheck_isMade.tractionCheck.shortenedBufferStrokeDisabled);
                commit('change_calculatedWithShortenedBufferStroke_storeMutation', state.elevatorMotor_Machine_forWhich_wrappingAngleCalculation_and_tractionCheck_isMade.tractionCheck.calculatedWithShortenedBufferStroke);
                commit('change_tractionCheck_Inputs_Invalid_storeMutation', false)
                commit('change_tractionCheck_storeMutation', state.elevatorMotor_Machine_forWhich_wrappingAngleCalculation_and_tractionCheck_isMade)
                commit('change_minimumCarWeight_storeMutation', state.elevatorMotor_Machine_forWhich_wrappingAngleCalculation_and_tractionCheck_isMade.elevatorSystem.minimumCarWeight);
                commit('change_maximumCarWeight_storeMutation', state.elevatorMotor_Machine_forWhich_wrappingAngleCalculation_and_tractionCheck_isMade.elevatorSystem.maximumCarWeight);
                commit('change_wrappingAngleCalculation_Modal_Shown_storeMutation', false)
                commit('change_tractionCheck_Modal_Shown_storeMutation', true)
            }
            else if (inputs_Changed == true) {
                dispatch('checkIf_wrappingAngleCalculation_Data_areValid_storeAction')
            }
        }
        else {
            dispatch('checkIf_wrappingAngleCalculation_Data_areValid_storeAction')
        }

    },
    async change_tractionCheck_Modal_Shown_storeAction({ commit, dispatch }, value) {
        if (value == true) {
            commit('change_tractionCheck_Modal_Shown_storeMutation', value)
        }
        else if (value == false) {
            if (state.elevatorMotor_Machine_Selection_Shown == true) {
                await dispatch('checkIf_changesAt_tractionCheck_changes_configurationFlow_storeAction')
            }
            else {
                dispatch('change_tractionCheck_toDefault_storeAction')
            }
        }
    },
    async change_tractionCheck_Modal_Continue_storeAction({ dispatch }, value) {
        await dispatch('change_tractionCheck_Modal_Shown_storeAction', value)
    },
    async checkIf_changesAt_tractionCheck_changes_configurationFlow_storeAction({ rootState, dispatch, commit }) {
        if (state.tractionCheck_Inputs_Invalid == false) {
            var tractionCheck = Object.assign({}, state.tractionCheck);

            if (rootState.inputs.wrappingAngle_Calculated == true) {
                if (state.wrappingAngle_Calculated == true) {
                    tractionCheck.suspensionMeans.wrappingAngleCalculated = true;
                    tractionCheck.suspensionMeans.verticalDistanceOfTheAxlesFromTractionSheaveAndDeflectionPulley = state.verticalDistanceOfTheAxlesFromTractionSheaveAndDeflectionPulley.value;
                    tractionCheck.suspensionMeans.horizontalDistanceOfTheRopeHoles = state.horizontalDistanceOfTheRopeHoles.value;
                    tractionCheck.suspensionMeans.deflectionPulleyDiameter = state.deflectionPulleyDiameter.value;
                    tractionCheck.suspensionMeans.wrappingAngle = state.calculated_wrappingAngle;
                    tractionCheck.frameSelection = null;
                    tractionCheck.wrappingAngleFrameCalculated = state.wrappingAngleFrameCalculation_Modal_Shown;
                }
                else if (state.wrappingAngle_Calculated == false) {
                    tractionCheck.suspensionMeans.wrappingAngleCalculated = false;
                    tractionCheck.suspensionMeans.verticalDistanceOfTheAxlesFromTractionSheaveAndDeflectionPulley = null;
                    tractionCheck.suspensionMeans.horizontalDistanceOfTheRopeHoles = null;
                    tractionCheck.suspensionMeans.deflectionPulleyDiameter = null;
                    tractionCheck.suspensionMeans.wrappingAngle = state.entered_wrappingAngle.value;
                    tractionCheck.frameSelection = null;
                    tractionCheck.wrappingAngleFrameCalculated = false;
                }
            }
            else if (rootState.inputs.wrappingAngle_Calculated == false) {
                tractionCheck.suspensionMeans.wrappingAngleCalculated = false;
                tractionCheck.suspensionMeans.wrappingAngle = rootState.inputs.wrappingAngle.value;
            }
        }


        if (state.elevatorMotor_Machine_forWhich_wrappingAngleCalculation_and_tractionCheck_isMade.tractionCheck_Executed == true) {

            var inputs_Changed = false;

            if ((state.ropePulleyDiameter.value != state.elevatorMotor_Machine_forWhich_wrappingAngleCalculation_and_tractionCheck_isMade.suspensionMeans.ropePulleyDiameter)
                || (state.numberOfRopePulleysWithUniformBending.value != state.elevatorMotor_Machine_forWhich_wrappingAngleCalculation_and_tractionCheck_isMade.suspensionMeans.numberOfRopePulleysWithUniformBending)
                || (state.numberOfRopePulleysWithReverseBending.value != state.elevatorMotor_Machine_forWhich_wrappingAngleCalculation_and_tractionCheck_isMade.suspensionMeans.numberOfRopePulleysWithReverseBending)
                || (state.sheaveHardened != state.elevatorMotor_Machine_forWhich_wrappingAngleCalculation_and_tractionCheck_isMade.elevatorMotor_TractionSheave.sheaveHardened)
                || (state.sheaveProfile != state.elevatorMotor_Machine_forWhich_wrappingAngleCalculation_and_tractionCheck_isMade.elevatorMotor_TractionSheave.sheaveProfile)
                || (state.undercutAngle_Disabled != state.elevatorMotor_Machine_forWhich_wrappingAngleCalculation_and_tractionCheck_isMade.elevatorMotor_TractionSheave.undercutAngleDisabled)
                || ((state.undercutAngle_Disabled == false) && (state.elevatorMotor_Machine_forWhich_wrappingAngleCalculation_and_tractionCheck_isMade.elevatorMotor_TractionSheave.undercutAngleDisabled == false) && (state.undercutAngle != state.elevatorMotor_Machine_forWhich_wrappingAngleCalculation_and_tractionCheck_isMade.elevatorMotor_TractionSheave.undercutAngle))
                || (state.vgrooveAngle_Disabled != state.elevatorMotor_Machine_forWhich_wrappingAngleCalculation_and_tractionCheck_isMade.elevatorMotor_TractionSheave.vgrooveAngleDisabled)
                || ((state.vgrooveAngle_Disabled == false) && (state.elevatorMotor_Machine_forWhich_wrappingAngleCalculation_and_tractionCheck_isMade.elevatorMotor_TractionSheave.vgrooveAngleDisabled == false) && (state.vgrooveAngle != state.elevatorMotor_Machine_forWhich_wrappingAngleCalculation_and_tractionCheck_isMade.elevatorMotor_TractionSheave.vgrooveAngle))
                || (state.minimumValues_Disabled != state.elevatorMotor_Machine_forWhich_wrappingAngleCalculation_and_tractionCheck_isMade.tractionCheck.minimumValuesDisabled)
                || ((state.minimumValues_Disabled == false) && (state.elevatorMotor_Machine_forWhich_wrappingAngleCalculation_and_tractionCheck_isMade.tractionCheck.minimumValuesDisabled == false) && (state.calculatedWithMinimumValues != state.elevatorMotor_Machine_forWhich_wrappingAngleCalculation_and_tractionCheck_isMade.tractionCheck.calculatedWithMinimumValues))
                || (state.shortenedBufferStroke_Disabled != state.elevatorMotor_Machine_forWhich_wrappingAngleCalculation_and_tractionCheck_isMade.tractionCheck.shortenedBufferStrokeDisabled)
                || ((state.shortenedBufferStroke_Disabled == false) && (state.elevatorMotor_Machine_forWhich_wrappingAngleCalculation_and_tractionCheck_isMade.tractionCheck.shortenedBufferStrokeDisabled == false) && (state.calculatedWithShortenedBufferStroke != state.elevatorMotor_Machine_forWhich_wrappingAngleCalculation_and_tractionCheck_isMade.tractionCheck.calculatedWithShortenedBufferStroke))
            ) {
                inputs_Changed = true;
            }

            if (rootState.inputs.wrappingAngle_Calculated == true
                && state.verticalDistanceOfTheAxlesFromTractionSheaveAndDeflectionPulley.value != state.elevatorMotor_Machine_forWhich_wrappingAngleCalculation_and_tractionCheck_isMade.suspensionMeans.verticalDistanceOfTheAxlesFromTractionSheaveAndDeflectionPulley
                || state.horizontalDistanceOfTheRopeHoles.value != state.elevatorMotor_Machine_forWhich_wrappingAngleCalculation_and_tractionCheck_isMade.suspensionMeans.horizontalDistanceOfTheRopeHoles
                || state.deflectionPulleyDiameter.value != state.elevatorMotor_Machine_forWhich_wrappingAngleCalculation_and_tractionCheck_isMade.suspensionMeans.deflectionPulleyDiameter
                || (state.wrappingAngleFrameCalculation_Modal_Shown == false && state.elevatorMotor_Machine_forWhich_wrappingAngleCalculation_and_tractionCheck_isMade.hasOwnProperty('frame') == true)
                || (state.wrappingAngleFrameCalculation_Modal_Shown == true && state.elevatorMotor_Machine_forWhich_wrappingAngleCalculation_and_tractionCheck_isMade.hasOwnProperty('frame') == false)) {
                inputs_Changed = true;
            }
            else if (rootState.inputs.wrappingAngle_Calculated == true && state.frame_Data.hasOwnProperty('frame_Selection') == true) {
                const firstIndex_frameSelected = state.frame_Data.frame_Selection.findIndex(i => (i.selected == true))

                if (firstIndex_frameSelected > -1 && state.elevatorMotor_Machine_forWhich_wrappingAngleCalculation_and_tractionCheck_isMade.hasOwnProperty('frame') == true && state.frame_Data.frame_Selection[firstIndex_frameSelected].frame.blockNumber != state.elevatorMotor_Machine_forWhich_wrappingAngleCalculation_and_tractionCheck_isMade.frame.frame.blockNumber) {
                    inputs_Changed = true;
                }
            }

            if (inputs_Changed == true) {
                if (state.tractionCheck_Inputs_Invalid == false) {
                    dispatch('change_wrappingAngleCalculation_and_tractionCheck_Data_at_itemOf_elevatorMotor_Machine_Selection_storeAction', tractionCheck);
                    if (tractionCheck.tractionCheck.tractionCheckPassedInTotal != true) {
                        dispatch('change_Selected_atEveryElementOf_elevatorMotor_Machine_Selection_toFalse_storeAction')
                    }

                    if (state.frame_Data != null && state.frame_Data.hasOwnProperty('frame_Selection') == true && state.frame_Data.frame_Selection != null) {
                        const firstIndex_whichMeetConditions = state.elevatorMotor_Machine_Data.elevatorMotor_Machine_Selection.findIndex(i => (i.elevatorMotor_Machine.blockNumberMachine == state.elevatorMotor_Machine_forWhich_wrappingAngleCalculation_and_tractionCheck_isMade.elevatorMotor_Machine.blockNumberMachine) && (i.elevatorMotor_Machine.blockNumberGear == state.elevatorMotor_Machine_forWhich_wrappingAngleCalculation_and_tractionCheck_isMade.elevatorMotor_Machine.blockNumberGear) && (i.elevatorMotor_TractionSheave.blockNumber == state.elevatorMotor_Machine_forWhich_wrappingAngleCalculation_and_tractionCheck_isMade.elevatorMotor_TractionSheave.blockNumber))
                        if (firstIndex_whichMeetConditions > -1) {
                            const firstIndex_whichMeetConditions2 = state.frame_Data.frame_Selection.findIndex(i => (i.selected == true))
                            if (firstIndex_whichMeetConditions2 > -1) {
                                commit('change_frame_Data_at_ElementOf_elevatorMotor_Machine_Selection_storeMutation', { index: firstIndex_whichMeetConditions, frame: state.frame_Data.frame_Selection[firstIndex_whichMeetConditions2] })
                            }
                        }
                    }
                }
                else {
                    dispatch('delete_wrappingAngleCalculation_and_tractionCheck_Data_at_itemOf_elevatorMotor_Machine_Selection_storeAction')
                    dispatch('change_Selected_atEveryElementOf_elevatorMotor_Machine_Selection_toFalse_storeAction')
                }

                if (rootState.configurations.configurationSteps.elevatorMotor_Machine.storedInDatabase == true) {
                    await this.dispatch('configurations/selectAndInsert_or_updateAndOrDelete_databaseRecords_storeAction', { changesAt: this._vm.$configurationSteps.tractionCheck });
                }
            }
        }
        else {
            if (state.tractionCheck_Inputs_Invalid == false) {
                dispatch('add_wrappingAngleCalculation_and_tractionCheck_Data_at_itemOf_elevatorMotor_Machine_Selection_storeAction', tractionCheck);
                const firstIndex_whichMeetConditions3 = state.elevatorMotor_Machine_Data.elevatorMotor_Machine_Selection.findIndex(i => (i.elevatorMotor_Machine.blockNumberMachine == state.elevatorMotor_Machine_forWhich_wrappingAngleCalculation_and_tractionCheck_isMade.elevatorMotor_Machine.blockNumberMachine) && (i.elevatorMotor_Machine.blockNumberGear == state.elevatorMotor_Machine_forWhich_wrappingAngleCalculation_and_tractionCheck_isMade.elevatorMotor_Machine.blockNumberGear) && (i.elevatorMotor_TractionSheave.blockNumber == state.elevatorMotor_Machine_forWhich_wrappingAngleCalculation_and_tractionCheck_isMade.elevatorMotor_TractionSheave.blockNumber))
                if (firstIndex_whichMeetConditions3 > -1 && (state.frame_Data.hasOwnProperty('frame_Selection')) && (Array.isArray(state.frame_Data.frame_Selection))) {
                    const firstIndex_whichMeetConditions4 = state.frame_Data.frame_Selection.findIndex(i => (i.selected == true))
                    if (firstIndex_whichMeetConditions4 > -1) {
                        commit('change_frame_Data_at_ElementOf_elevatorMotor_Machine_Selection_storeMutation', { index: firstIndex_whichMeetConditions3, frame: state.frame_Data.frame_Selection[firstIndex_whichMeetConditions4] })
                    }
                }

            }
        }

        if (rootState.inputs.wrappingAngle_Calculated == true) {
            dispatch('change_wrappingAngleCalculation_toDefault_storeAction')
        }

        dispatch('change_tractionCheck_toDefault_storeAction')
    },

    async get_tractionCheck_Inputs_ValueListsAndSelectionsAndPermissibleValueRanges_Informations_storeAction({ rootState, commit, dispatch, }) {
        const data = {
            configurationOverviewsId: rootState.configurations.configurationOverviewsId,
            elevatorMotor_TractionSheave: state.elevatorMotor_Machine_forWhich_wrappingAngleCalculation_and_tractionCheck_isMade.elevatorMotor_TractionSheave,
            elevatorMotor_Machine: state.elevatorMotor_Machine_forWhich_wrappingAngleCalculation_and_tractionCheck_isMade.elevatorMotor_Machine,
        }

        await axios({
            method: 'post',
            url: '/api/driveTechnologies/get_tractionCheck_Inputs_ValueListsAndSelectionsAndPermissibleValueRanges_Informations',
            data: {
                data: data,
            }
        })
            .then((response) => {
                this.dispatch('statusHandling/change_dataAndStatus_storeAction', response.data);

                if ((response.data.status.statusCode.substring(0, 1) == '2') || (response.data.status.statusCode.substring(0, 2) == '74') || (response.data.status.statusCode.substring(0, 2) == '75')) {
                    dispatch('toDos_afterResponseOf_get_tractionCheck_Inputs_ValueListsAndSelectionsAndPermissibleValueRanges_Informations_storeAction', response.data.data)
                }
                else {
                    commit('change_tractionCheck_Inputs_ValueListsAndSelectionsAndPermissibleValueRanges_storeMutation', {});
                    commit('change_tractionCheck_Inputs_ValueListsAndSelectionsAndPermissibleValueRanges_Loaded_storeMutation', false)
                }
            })
            .catch(() => { })
    },
    toDos_afterResponseOf_get_tractionCheck_Inputs_ValueListsAndSelectionsAndPermissibleValueRanges_Informations_storeAction({ commit }, value) {
        commit('change_tractionCheck_Inputs_ValueListsAndSelectionsAndPermissibleValueRanges_storeMutation', value.tractionCheck_Inputs_ValueListsAndSelectionsAndPermissibleValueRanges);
        commit('change_tractionCheck_Inputs_ValueListsAndSelectionsAndPermissibleValueRanges_Loaded_storeMutation', true)
        commit('change_tractionCheck_UndercutWidthDisabled_storeMutation', value.tractionCheck_Inputs_ValueListsAndSelectionsAndPermissibleValueRanges.undercutWidth_Disabled);
        commit('change_tractionCheck_UndercutWidthInput_storeMutation', value.tractionCheck_Inputs_ValueListsAndSelectionsAndPermissibleValueRanges.undercutWidth_Input);
    },

    change_ropePulleyDiameter_storeAction({ commit, dispatch }, value) {
        const valueAndValueValidationCriteria = this._vm.$transmit_valueAndValueValidationCriteria;
        valueAndValueValidationCriteria.value = value;
        valueAndValueValidationCriteria.dataType = this._vm.$dataTypes.integer;
        valueAndValueValidationCriteria.permissibleNumberOfDecimalPlaces = null;
        valueAndValueValidationCriteria.permissibleValueRange = state.tractionCheck_Inputs_ValueListsAndSelectionsAndPermissibleValueRanges.tractionCheck_PermissibleValueRanges.ropePulleyDiameter;
        const ropePulleyDiameter_ValidationResult = this._vm.$checkIf_value_isValid(valueAndValueValidationCriteria);

        commit('change_ropePulleyDiameter_storeMutation', ropePulleyDiameter_ValidationResult);
        dispatch('checkIf_tractionCheck_Inputs_areValid_storeAction')
    },
    change_numberOfRopePulleysWithUniformBending_storeAction({ commit, dispatch }, value) {
        const valueAndValueValidationCriteria = this._vm.$transmit_valueAndValueValidationCriteria;
        valueAndValueValidationCriteria.value = value;
        valueAndValueValidationCriteria.dataType = this._vm.$dataTypes.integer;
        valueAndValueValidationCriteria.permissibleNumberOfDecimalPlaces = null;
        valueAndValueValidationCriteria.permissibleValueRange = state.tractionCheck_Inputs_ValueListsAndSelectionsAndPermissibleValueRanges.tractionCheck_PermissibleValueRanges.numberOfRopePulleysWithUniformBending;
        const numberOfRopePulleysWithUniformBending_ValidationResult = this._vm.$checkIf_value_isValid(valueAndValueValidationCriteria);

        commit('change_numberOfRopePulleysWithUniformBending_storeMutation', numberOfRopePulleysWithUniformBending_ValidationResult);
        dispatch('checkIf_tractionCheck_Inputs_areValid_storeAction')
    },
    change_numberOfRopePulleysWithReverseBending_storeAction({ commit, dispatch }, value) {
        const valueAndValueValidationCriteria = this._vm.$transmit_valueAndValueValidationCriteria;
        valueAndValueValidationCriteria.value = value;
        valueAndValueValidationCriteria.dataType = this._vm.$dataTypes.integer;
        valueAndValueValidationCriteria.permissibleNumberOfDecimalPlaces = null;
        valueAndValueValidationCriteria.permissibleValueRange = state.tractionCheck_Inputs_ValueListsAndSelectionsAndPermissibleValueRanges.tractionCheck_PermissibleValueRanges.numberOfRopePulleysWithReverseBending;
        const numberOfRopePulleysWithReverseBending_ValidationResult = this._vm.$checkIf_value_isValid(valueAndValueValidationCriteria);

        commit('change_numberOfRopePulleysWithReverseBending_storeMutation', numberOfRopePulleysWithReverseBending_ValidationResult);
        dispatch('checkIf_tractionCheck_Inputs_areValid_storeAction')
    },
    change_sheaveProfileAndSheaveHardened_storeAction({ commit, dispatch }, value) {
        commit('change_sheaveProfileAndSheaveHardenedTextKey_storeMutation', value)

        const sheaveProfileAndSheaveHardened = state.tractionCheck_Inputs_ValueListsAndSelectionsAndPermissibleValueRanges.sheaveProfileAndSheaveHardened_Selection.find(i => i.textKey == value);

        if (sheaveProfileAndSheaveHardened != null) {
            commit('change_sheaveProfile_storeMutation', sheaveProfileAndSheaveHardened.sheaveProfile);
            commit('change_sheaveHardened_storeMutation', sheaveProfileAndSheaveHardened.sheaveHardened);
            dispatch('checkIf_tractionCheck_Inputs_areValid_storeAction')
        }
    },
    change_undercutAngle_Disabled_storeAction({ commit, dispatch }, value) {
        commit('change_undercutAngle_Disabled_storeMutation', value);
        dispatch('checkIf_tractionCheck_Inputs_areValid_storeAction')
    },
    change_undercutAngle_storeAction({ commit, dispatch }, value) {
        commit('change_undercutAngle_storeMutation', value);
        dispatch('checkIf_tractionCheck_Inputs_areValid_storeAction')
    },
    change_vgrooveAngle_Disabled_storeAction({ commit, dispatch }, value) {
        commit('change_vgrooveAngle_Disabled_storeMutation', value);
        dispatch('checkIf_tractionCheck_Inputs_areValid_storeAction')
    },
    change_vgrooveAngle_storeAction({ commit, dispatch }, value) {
        commit('change_vgrooveAngle_storeMutation', value);
        dispatch('checkIf_tractionCheck_Inputs_areValid_storeAction')
    },
    change_minimumValues_Disabled_storeAction({ commit, dispatch }, value) {
        commit('change_minimumValues_Disabled_storeMutation', value);
        dispatch('checkIf_tractionCheck_Inputs_areValid_storeAction')
    },
    change_calculatedWithMinimumValues_storeAction({ commit, dispatch }, value) {
        commit('change_calculatedWithMinimumValues_storeMutation', value);
        dispatch('checkIf_tractionCheck_Inputs_areValid_storeAction')
    },
    change_shortenedBufferStroke_Disabled_storeAction({ commit, dispatch }, value) {
        commit('change_shortenedBufferStroke_Disabled_storeMutation', value);
        dispatch('checkIf_tractionCheck_Inputs_areValid_storeAction')
    },
    change_calculatedWithShortenedBufferStroke_storeAction({ commit, dispatch }, value) {
        commit('change_calculatedWithShortenedBufferStroke_storeMutation', value);
        dispatch('checkIf_tractionCheck_Inputs_areValid_storeAction')
    },
    checkIf_tractionCheck_Inputs_areValid_storeAction({ commit, dispatch }) {
        if ((state.ropePulleyDiameter.invalid == false) && (state.numberOfRopePulleysWithUniformBending.invalid == false) && (state.numberOfRopePulleysWithReverseBending.invalid == false)) {
            commit('change_tractionCheck_Inputs_Invalid_storeMutation', false);
            dispatch('get_tractionCheck_Informations_storeAction', true);
        }
        else {
            commit('change_tractionCheck_Inputs_Invalid_storeMutation', true);
            commit('abort_httpRequests_get_tractionCheck_Informations_storeMutation');
            commit('change_tractionCheck_storeMutation', {});
        }
    },

    get_tractionCheck_Informations_storeAction({ rootState, commit, dispatch }, inputs_Transmitted) {
        commit('change_wrappingAngleCalculation_Modal_Shown_storeMutation', false);
        commit('change_tractionCheck_Modal_Shown_storeMutation', true);

        var suspensionMeans = {};
        var elevatorMotor_TractionSheave = Object.assign({}, state.elevatorMotor_Machine_forWhich_wrappingAngleCalculation_and_tractionCheck_isMade.elevatorMotor_TractionSheave);
        var tractionCheck = {};


        if (inputs_Transmitted == true) {
            commit('change_loader_forLoadingResults_at_tractionCheck_Modal_Shown_storeMutation', true);

            suspensionMeans.ropePulleyDiameter = state.ropePulleyDiameter.value;
            suspensionMeans.numberOfRopePulleysWithUniformBending = state.numberOfRopePulleysWithUniformBending.value;
            suspensionMeans.numberOfRopePulleysWithReverseBending = state.numberOfRopePulleysWithReverseBending.value;

            elevatorMotor_TractionSheave.sheaveHardened = state.sheaveHardened;
            elevatorMotor_TractionSheave.sheaveProfile = state.sheaveProfile;
            elevatorMotor_TractionSheave.undercutAngleDisabled = state.undercutAngle_Disabled;
            elevatorMotor_TractionSheave.undercutAngle = state.undercutAngle;
            elevatorMotor_TractionSheave.vgrooveAngleDisabled = state.vgrooveAngle_Disabled;
            elevatorMotor_TractionSheave.vgrooveAngle = state.vgrooveAngle;

            tractionCheck.minimumValuesDisabled = state.minimumValues_Disabled;
            tractionCheck.calculatedWithMinimumValues = state.calculatedWithMinimumValues;
            tractionCheck.shortenedBufferStrokeDisabled = state.shortenedBufferStroke_Disabled;
            tractionCheck.calculatedWithShortenedBufferStroke = state.calculatedWithShortenedBufferStroke;
        }
        else {
            commit('change_loader_forLoadingAllContent_at_tractionCheck_Modal_Shown_storeMutation', true);
        }


        if (rootState.inputs.wrappingAngle_Calculated == true) {
            if (state.wrappingAngle_Calculated == true) {
                suspensionMeans.wrappingAngleCalculated = true;
                suspensionMeans.wrappingAngle = state.calculated_wrappingAngle;
                if (state.deflectionPulleyDiameter != null && state.deflectionPulleyDiameter.value != null) {
                    suspensionMeans.deflectionPulleyDiameter = state.deflectionPulleyDiameter.value
                }
            }
            else if (state.wrappingAngle_Calculated == false) {
                suspensionMeans.wrappingAngleCalculated = false;
                suspensionMeans.wrappingAngle = state.entered_wrappingAngle.value;
            }
        }
        else if (rootState.inputs.wrappingAngle_Calculated == false) {
            suspensionMeans.wrappingAngleCalculated = false;
            suspensionMeans.wrappingAngle = rootState.inputs.wrappingAngle.value;
        }

        var frame = null;
        if (state.frame_Data != null && state.frame_Data.hasOwnProperty('frame_Selection') == true && state.frame_Data.frame_Selection != null && Array.isArray(state.frame_Data.frame_Selection) == true) {
            const firstIndex_whichMeetConditions = state.frame_Data.frame_Selection.findIndex(i => i.selected == true);
            if (firstIndex_whichMeetConditions > -1) {
                frame = state.frame_Data.frame_Selection[firstIndex_whichMeetConditions].frame;
            }
        }
        const data = {
            configurationOverviewsId: rootState.configurations.configurationOverviewsId,
            suspensionMeans: suspensionMeans,
            elevatorMotor_TractionSheave: elevatorMotor_TractionSheave,
            elevatorMotor_Machine: state.elevatorMotor_Machine_forWhich_wrappingAngleCalculation_and_tractionCheck_isMade.elevatorMotor_Machine,
            tractionCheck: tractionCheck,
            inputs_Transmitted: inputs_Transmitted,
            frame: frame,
        }


        commit('abort_httpRequests_get_tractionCheck_Informations_storeMutation');
        if (state.cancellationInformationsAboutHttpRequests_get_tractionCheck_Informations.signal.aborted == true) {
            commit('change_cancellationInformationsAboutHttpRequests_get_tractionCheck_Informations_toNewAbortController_storeMutation')
        }

        axios({
            method: 'post',
            url: '/api/driveTechnologies/get_tractionCheck_Informations',
            signal: state.cancellationInformationsAboutHttpRequests_get_tractionCheck_Informations.signal,
            data: {
                data: data,
            }
        })
            .then((response) => {
                this.dispatch('statusHandling/change_dataAndStatus_storeAction', response.data)

                if ((response.data.status.statusCode.substring(0, 1) == '2') || (response.data.status.statusCode.substring(0, 2) == '74') || (response.data.status.statusCode.substring(0, 2) == '75') || (response.data.status.statusCode.substring(0, 2) == '70')) {
                    dispatch('toDos_afterResponseOf_get_tractionCheck_Informations_storeAction', response.data.data)
                }
                commit('change_loader_forLoadingResults_at_tractionCheck_Modal_Shown_storeMutation', false);
            })
            .catch(() => { })
            .then(() => {
                commit('change_loader_forLoadingAllContent_at_tractionCheck_Modal_Shown_storeMutation', false);
            })
    },
    toDos_afterResponseOf_get_tractionCheck_Informations_storeAction({ commit }, value) {
        commit('change_undercutAngle_Disabled_storeMutation', value.elevatorMotor_TractionSheave.undercutAngleDisabled);
        commit('change_undercutAngle_storeMutation', value.elevatorMotor_TractionSheave.undercutAngle);
        commit('change_vgrooveAngle_Disabled_storeMutation', value.elevatorMotor_TractionSheave.vgrooveAngleDisabled);
        commit('change_vgrooveAngle_storeMutation', value.elevatorMotor_TractionSheave.vgrooveAngle);

        commit('change_minimumCarWeight_storeMutation', value.elevatorSystem.minimumCarWeight);
        commit('change_maximumCarWeight_storeMutation', value.elevatorSystem.maximumCarWeight);

        commit('change_tractionCheck_storeMutation', value);

        if (value.inputs_Transmitted == false) {
            commit('change_ropePulleyDiameter_storeMutation', { value: value.suspensionMeans.ropePulleyDiameter, invalid: false });
            commit('change_numberOfRopePulleysWithUniformBending_storeMutation', { value: value.suspensionMeans.numberOfRopePulleysWithUniformBending, invalid: false });
            commit('change_numberOfRopePulleysWithReverseBending_storeMutation', { value: value.suspensionMeans.numberOfRopePulleysWithReverseBending, invalid: false });
            commit('change_sheaveHardened_storeMutation', value.elevatorMotor_TractionSheave.sheaveHardened);
            commit('change_sheaveProfile_storeMutation', value.elevatorMotor_TractionSheave.sheaveProfile);
            commit('change_minimumValues_Disabled_storeMutation', value.tractionCheck.minimumValuesDisabled);
            commit('change_calculatedWithMinimumValues_storeMutation', value.tractionCheck.calculatedWithMinimumValues);
            commit('change_shortenedBufferStroke_Disabled_storeMutation', value.tractionCheck.shortenedBufferStrokeDisabled);
            commit('change_calculatedWithShortenedBufferStroke_storeMutation', value.tractionCheck.calculatedWithShortenedBufferStroke);
            commit('change_tractionCheck_Inputs_Invalid_storeMutation', false)
        }
    },


    // elevatorMotor_Machine_TractionSheaveSide
    change_elevatorMotor_Machine_TractionSheaveSide_toDefault_storeAction({ commit }) {
        commit('change_elevatorMotor_Machine_TractionSheaveSide_Data_storeMutation', {});
    },

    async get_elevatorMotor_Machine_TractionSheaveSide_Informations_storeAction({ dispatch, rootState }) {
        const data = {
            configurationOverviewsId: rootState.configurations.configurationOverviewsId,
            configurationSteps: rootState.configurations.configurationSteps,
        };

        await axios({
            method: 'post',
            url: '/api/driveTechnologies/get_elevatorMotor_Machine_TractionSheaveSide_Informations',
            data: {
                data: data,
            }
        })
            .then(response => {
                this.dispatch('statusHandling/change_dataAndStatus_storeAction', response.data);

                if ((response.data.status.statusCode.substring(0, 1) == '2') || (response.data.status.statusCode.substring(0, 2) == '74') || (response.data.status.statusCode.substring(0, 2) == '75')) {
                    dispatch('toDos_afterResponseOf_get_elevatorMotor_Machine_TractionSheaveSide_Informations_storeAction', response.data.data)
                }
            })
            .catch(() => { })
    },
    toDos_afterResponseOf_get_elevatorMotor_Machine_TractionSheaveSide_Informations_storeAction({ dispatch }, value) {
        dispatch('change_elevatorMotor_Machine_TractionSheaveSide_Data_storeAction', value.elevatorMotor_Machine_TractionSheaveSide_Data);

        value.configurationSteps.elevatorMotor_Machine_TractionSheaveSide.loaded = true;
        this.dispatch('configurations/change_configurationSteps_storeAction', value.configurationSteps);
    },

    change_elevatorMotor_Machine_TractionSheaveSide_Data_storeAction({ commit }, value) {
        commit('change_elevatorMotor_Machine_TractionSheaveSide_Data_storeMutation', value);
    },

    async checkIf_changesAt_elevatorMotor_Machine_TractionSheaveSide_changes_configurationFlow_storeAction({ rootState, dispatch }, value) {
        dispatch('change_Selected_at_elevatorMotor_Machine_TractionSheaveSide_Selection_storeAction', value);

        const firstIndexWhere_Selected_atElementOf_elevatorMotor_Machine_Selection_isTrue = state.elevatorMotor_Machine_Data.elevatorMotor_Machine_Selection.findIndex(i => i.selected == true)
        if (firstIndexWhere_Selected_atElementOf_elevatorMotor_Machine_Selection_isTrue > -1) {
            const elevatorMotor_Machine = Object.assign({}, state.elevatorMotor_Machine_Data.elevatorMotor_Machine_Selection[firstIndexWhere_Selected_atElementOf_elevatorMotor_Machine_Selection_isTrue].elevatorMotor_Machine)
            elevatorMotor_Machine.tractionSheaveSide = state.elevatorMotor_Machine_TractionSheaveSide_Data.elevatorMotor_Machine_TractionSheaveSide_Selection[value].elevatorMotor_Machine_TractionSheaveSide.text;

            if (rootState.configurations.configurationSteps.elevatorMotor_Machine_TractionSheaveSide.storedInDatabase == true) {
                await this.dispatch('configurations/selectAndInsert_or_updateAndOrDelete_databaseRecords_storeAction', { changesAt: this._vm.$configurationSteps.elevatorMotor_Machine_TractionSheaveSide, elevatorMotor_Machine: elevatorMotor_Machine });
                this.dispatch('configurations/check_toDos_at_driveTechnologies_storeAction')
            }
            else {
                dispatch('update_databaseRecord_at_elevatorMotor_Machines_bySetting_TractionSheaveSide_storeAction', state.elevatorMotor_Machine_TractionSheaveSide_Data.elevatorMotor_Machine_TractionSheaveSide_Selection[value].elevatorMotor_Machine_TractionSheaveSide.text)
            }
        }
        else {
            // error
        }
    },
    change_Selected_at_elevatorMotor_Machine_TractionSheaveSide_Selection_storeAction({ commit }, value) {
        commit('change_Selected_at_elevatorMotor_Machine_TractionSheaveSide_Selection_storeMutation', value);
    },

    async update_databaseRecord_at_elevatorMotor_Machines_bySetting_TractionSheaveSide_storeAction({ rootState, }, value) {
        const elevatorMotor_Machine = { tractionSheaveSide: value };

        const data = {
            configurationOverviewsId: rootState.configurations.configurationOverviewsId,
            configurationSteps: rootState.configurations.configurationSteps,
            elevatorMotor_Machine: elevatorMotor_Machine,
        }

        await axios({
            method: 'post',
            url: '/api/driveTechnologies/update_databaseRecord_at_elevatorMotor_Machines_bySetting_TractionSheaveSide',
            data: {
                data: data,
            }
        })
            .then((response) => {
                this.dispatch('statusHandling/change_dataAndStatus_storeAction', response.data);

                if (response.data.status.statusCode.startsWith('2')) {
                    this.dispatch('configurations/change_configurationSteps_storeAction', response.data.data.configurationSteps);
                }
            })
            .catch(() => { })
    },


    // elevatorMotor_Machine_InstallationPosition
    change_elevatorMotor_Machine_InstallationPosition_toDefault_storeAction({ commit }) {
        commit('change_elevatorMotor_Machine_InstallationPosition_Data_storeMutation', {});
    },

    async get_elevatorMotor_Machine_InstallationPosition_Informations_storeAction({ dispatch, rootState }) {
        const data = {
            configurationOverviewsId: rootState.configurations.configurationOverviewsId,
            configurationSteps: rootState.configurations.configurationSteps,
        };

        await axios({
            method: 'post',
            url: '/api/driveTechnologies/get_elevatorMotor_Machine_InstallationPosition_Informations',
            data: {
                data: data,
            }
        })
            .then(response => {
                this.dispatch('statusHandling/change_dataAndStatus_storeAction', response.data);

                if ((response.data.status.statusCode.substring(0, 1) == '2') || (response.data.status.statusCode.substring(0, 2) == '74') || (response.data.status.statusCode.substring(0, 2) == '75')) {
                    dispatch('toDos_afterResponseOf_get_elevatorMotor_Machine_InstallationPosition_Informations_storeAction', response.data.data)
                }
            })
    },
    toDos_afterResponseOf_get_elevatorMotor_Machine_InstallationPosition_Informations_storeAction({ rootState, dispatch }, value) {
        dispatch('change_elevatorMotor_Machine_InstallationPosition_Data_storeAction', value.elevatorMotor_Machine_InstallationPosition_Data);

        var configurationSteps = Object.assign({}, rootState.configurations.configurationSteps);
        var configurationSteps_elevatorMotor_Machine_InstallationPosition = Object.assign({}, rootState.configurations.configurationSteps.elevatorMotor_Machine_InstallationPosition);
        configurationSteps_elevatorMotor_Machine_InstallationPosition.loaded = true;
        configurationSteps.elevatorMotor_Machine_InstallationPosition = configurationSteps_elevatorMotor_Machine_InstallationPosition;
        this.dispatch('configurations/change_configurationSteps_storeAction', configurationSteps)
    },

    change_elevatorMotor_Machine_InstallationPosition_Data_storeAction({ commit }, value) {
        commit('change_elevatorMotor_Machine_InstallationPosition_Data_storeMutation', value);
    },

    async checkIf_changesAt_elevatorMotor_Machine_InstallationPosition_changes_configurationFlow_storeAction({ rootState, dispatch }, value) {
        dispatch('change_Selected_at_elevatorMotor_Machine_InstallationPosition_Selection_storeAction', value)

        const firstIndexWhere_Selected_atElementOf_elevatorMotor_Machine_Selection_isTrue = state.elevatorMotor_Machine_Data.elevatorMotor_Machine_Selection.findIndex(i => i.selected == true)
        if (firstIndexWhere_Selected_atElementOf_elevatorMotor_Machine_Selection_isTrue > -1) {
            const elevatorMotor_Machine = Object.assign({}, state.elevatorMotor_Machine_Data.elevatorMotor_Machine_Selection[firstIndexWhere_Selected_atElementOf_elevatorMotor_Machine_Selection_isTrue].elevatorMotor_Machine)
            elevatorMotor_Machine.installationPosition = state.elevatorMotor_Machine_InstallationPosition_Data.elevatorMotor_Machine_InstallationPosition_Selection[value].elevatorMotor_Machine_InstallationPosition.text;

            if (rootState.configurations.configurationSteps.elevatorMotor_InstallationPosition.storedInDatabase == true) {
                await this.dispatch('configurations/selectAndInsert_or_updateAndOrDelete_databaseRecords_storeAction', { changesAt: this._vm.$configurationSteps.elevatorMotor_Machine_InstallationPosition, elevatorMotor_Machine: elevatorMotor_Machine })
                this.dispatch('configurations/check_toDos_at_driveTechnologies_storeAction')
            }
            else {
                dispatch('update_databaseRecord_at_elevatorMotor_Machines_bySetting_InstallationPosition_storeAction', state.elevatorMotor_Machine_InstallationPosition_Data.elevatorMotor_Machine_InstallationPosition_Selection[value].elevatorMotor_Machine_InstallationPosition.text)
            }
        }
        else {
            // error
        }
    },
    change_Selected_at_elevatorMotor_Machine_InstallationPosition_Selection_storeAction({ commit }, value) {
        commit('change_Selected_at_elevatorMotor_Machine_InstallationPosition_Selection_storeMutation', value);
    },

    async update_databaseRecord_at_elevatorMotor_Machines_bySetting_InstallationPosition_storeAction({ rootState, }, value) {
        const elevatorMotor_Machine = { installationPosition: value };

        const data = {
            configurationOverviewsId: rootState.configurations.configurationOverviewsId,
            configurationSteps: rootState.configurations.configurationSteps,
            elevatorMotor_Machine: elevatorMotor_Machine,
        }

        await axios({
            method: 'post',
            url: '/api/driveTechnologies/update_databaseRecord_at_elevatorMotor_Machines_bySetting_InstallationPosition',
            data: {
                data: data,
            }
        })
            .then((response) => {
                this.dispatch('statusHandling/change_dataAndStatus_storeAction', response.data);

                if (response.data.status.statusCode.startsWith('2')) {
                    this.dispatch('configurations/change_configurationSteps_storeAction', response.data.data.configurationSteps);
                }
            })
            .catch(() => { })
    },


    // elevatorMotor_TemperatureMonitoring
    change_elevatorMotor_TemperatureMonitoring_toDefault_storeAction({ commit }) {
        commit('change_elevatorMotor_TemperatureMonitoring_Data_storeMutation', {});
        commit('change_elevatorMotor_TemperatureMonitoring_Selection_Shown_storeMutation', true)
    },

    async get_elevatorMotor_TemperatureMonitoring_Informations_storeAction({ dispatch, rootState }) {
        const data = {
            configurationOverviewsId: rootState.configurations.configurationOverviewsId,
            configurationSteps: rootState.configurations.configurationSteps,
        };

        await axios({
            method: 'post',
            url: '/api/driveTechnologies/get_elevatorMotor_TemperatureMonitoring_Informations',
            data: {
                data: data,
            }
        })
            .then(response => {
                this.dispatch('statusHandling/change_dataAndStatus_storeAction', response.data)

                if ((response.data.status.statusCode.substring(0, 1) == '2') || (response.data.status.statusCode.substring(0, 2) == '74') || (response.data.status.statusCode.substring(0, 2) == '75')) {
                    dispatch('toDos_afterResponseOf_get_elevatorMotor_TemperatureMonitoring_Informations_storeAction', response.data.data);
                }
            })
            .catch(() => { })
    },
    toDos_afterResponseOf_get_elevatorMotor_TemperatureMonitoring_Informations_storeAction({ rootState, dispatch }, value) {
        dispatch('change_elevatorMotor_TemperatureMonitoring_Data_storeAction', value.elevatorMotor_TemperatureMonitoring_Data);

        var configurationSteps = Object.assign({}, rootState.configurations.configurationSteps);
        var configurationSteps_elevatorMotor_TemperatureMonitoring = Object.assign({}, rootState.configurations.configurationSteps.elevatorMotor_TemperatureMonitoring);
        configurationSteps_elevatorMotor_TemperatureMonitoring.loaded = true;
        configurationSteps.elevatorMotor_TemperatureMonitoring = configurationSteps_elevatorMotor_TemperatureMonitoring;
        this.dispatch('configurations/change_configurationSteps_storeAction', configurationSteps)
    },

    change_elevatorMotor_TemperatureMonitoring_Data_storeAction({ commit }, value) {
        commit('change_elevatorMotor_TemperatureMonitoring_Data_storeMutation', value);
    },
    change_elevatorMotor_TemperatureMonitoring_Selection_Shown_storeAction({ commit }, value) {
        commit('change_elevatorMotor_TemperatureMonitoring_Selection_Shown_storeMutation', value)
    },

    async checkIf_elevatorMotor_Machine_FilterCriterion_elevatorMotor_TemperatureMonitoringByPT100_AvailableAndTrue_storeAction({ rootState, commit, dispatch }) {
        if ((state.elevatorMotor_Machine_Data.elevatorMotor_Machine_Filter_Data.elevatorMotor_TemperatureMonitoringByPT100_Available == true) && (state.elevatorMotor_Machine_FilterCriterion_elevatorMotor_TemperatureMonitoringByPT100 == true)) {
            const firstIndex_whichMeetCondition = state.elevatorMotor_TemperatureMonitoring_Data.elevatorMotor_TemperatureMonitoring_Selection.findIndex(i => i.elevatorMotor_TemperatureMonitoring.typeDesignation == 'PT100');
            if (firstIndex_whichMeetCondition > -1) {
                dispatch('change_Selected_at_elevatorMotor_TemperatureMonitoring_Selection_storeAction', firstIndex_whichMeetCondition)
                await dispatch('insert_databaseRecord_at_elevatorMotor_TemperatureMonitorings_storeAction', state.elevatorMotor_TemperatureMonitoring_Data.elevatorMotor_TemperatureMonitoring_Selection[firstIndex_whichMeetCondition].elevatorMotor_TemperatureMonitoring)

                if ((rootState.user.userPreferences.hasOwnProperty('use_PT100_For_ElevatorMotor_TemperatureMonitoring') == true) && (rootState.user.userPreferences.use_PT100_For_ElevatorMotor_TemperatureMonitoring == true)) {
                    commit('change_userPreference_affected_selected_elevatorMotor_TemperatureMonitoring_storeMutation', true)
                    commit('change_elevatorMotor_TemperatureMonitoring_Selection_Shown_storeMutation', false)
                }
            }
            else {
                dispatch('change_Selected_at_elevatorMotor_TemperatureMonitoring_Selection_storeAction', 0)
                await dispatch('insert_databaseRecord_at_elevatorMotor_TemperatureMonitorings_storeAction', state.elevatorMotor_TemperatureMonitoring_Data.elevatorMotor_TemperatureMonitoring_Selection[0].elevatorMotor_TemperatureMonitoring)
            }
        }
        else {
            dispatch('change_Selected_at_elevatorMotor_TemperatureMonitoring_Selection_storeAction', 0);
            await dispatch('insert_databaseRecord_at_elevatorMotor_TemperatureMonitorings_storeAction', state.elevatorMotor_TemperatureMonitoring_Data.elevatorMotor_TemperatureMonitoring_Selection[0].elevatorMotor_TemperatureMonitoring)
        }
    },

    async checkIf_changesAt_elevatorMotor_TemperatureMonitoring_changes_configurationFlow_storeAction({ rootState, commit, dispatch }, value) {
        commit('change_userPreference_affected_selected_elevatorMotor_TemperatureMonitoring_storeMutation', false)
        dispatch('change_Selected_at_elevatorMotor_TemperatureMonitoring_Selection_storeAction', value)

        if (rootState.configurations.configurationSteps.elevatorMotor_TemperatureMonitoring.storedInDatabase == true) {
            await this.dispatch('configurations/selectAndInsert_or_updateAndOrDelete_databaseRecords_storeAction', { changesAt: this._vm.$configurationSteps.elevatorMotor_TemperatureMonitoring, elevatorMotor_TemperatureMonitoring: state.elevatorMotor_TemperatureMonitoring_Data.elevatorMotor_TemperatureMonitoring_Selection[value].elevatorMotor_TemperatureMonitoring });
            this.dispatch('configurations/check_toDos_at_driveTechnologies_storeAction')
        }
        else {
            dispatch('insert_databaseRecord_at_elevatorMotor_TemperatureMonitorings_storeAction', state.elevatorMotor_TemperatureMonitoring_Data.elevatorMotor_TemperatureMonitoring_Selection[value].elevatorMotor_TemperatureMonitoring)
        }
    },
    change_Selected_at_elevatorMotor_TemperatureMonitoring_Selection_storeAction({ commit }, value) {
        commit('change_Selected_at_elevatorMotor_TemperatureMonitoring_Selection_storeMutation', value);
    },

    async insert_databaseRecord_at_elevatorMotor_TemperatureMonitorings_storeAction({ rootState }, value) {
        const data = {
            configurationOverviewsId: rootState.configurations.configurationOverviewsId,
            configurationSteps: rootState.configurations.configurationSteps,
            elevatorMotor_TemperatureMonitoring: value,
        }

        await axios({
            method: 'post',
            url: '/api/driveTechnologies/insert_databaseRecord_at_elevatorMotor_TemperatureMonitorings',
            data: {
                data: data,
            }
        })
            .then((response) => {
                this.dispatch('statusHandling/change_dataAndStatus_storeAction', response.data);

                if (response.data.status.statusCode.startsWith('2')) {
                    this.dispatch('configurations/change_configurationSteps_storeAction', response.data.data.configurationSteps);
                }
            })
            .catch(() => { })
    },


    // elevatorMotor_ForcedVentilation
    change_elevatorMotor_ForcedVentilation_toDefault_storeAction({ commit }) {
        commit('change_elevatorMotor_ForcedVentilation_storeMutation', {});
        commit('change_elevatorMotor_ForcedVentilation_Options_Shown_storeMutation', true)
    },

    async get_elevatorMotor_ForcedVentilation_Informations_storeAction({ dispatch, rootState }) {
        const data = {
            configurationOverviewsId: rootState.configurations.configurationOverviewsId,
            configurationSteps: rootState.configurations.configurationSteps,
        };

        await axios({
            method: 'post',
            url: '/api/driveTechnologies/get_elevatorMotor_ForcedVentilation_Informations',
            data: {
                data: data,
            }
        })
            .then((response) => {
                this.dispatch('statusHandling/change_dataAndStatus_storeAction', response.data);

                if ((response.data.status.statusCode.substring(0, 1) == '2') || (response.data.status.statusCode.substring(0, 2) == '74') || (response.data.status.statusCode.substring(0, 2) == '75')) {
                    dispatch('toDos_afterResponseOf_get_elevatorMotor_ForcedVentilation_Informations_storeAction', response.data.data)
                }
            })
            .catch(() => { })
    },
    toDos_afterResponseOf_get_elevatorMotor_ForcedVentilation_Informations_storeAction({ commit, dispatch }, value) {
        dispatch('change_elevatorMotor_ForcedVentilation_storeAction', value.elevatorMotor_ForcedVentilation);
        commit('change_elevatorMotor_PermissibleDutyCyleWithoutForcedVentilation_storeMutation', value.permissibleDutyCycleWithoutForcedVentilation);
        commit('change_elevatorMotor_PermissibleDutyCyleWithForcedVentilation_storeMutation', value.permissibleDutyCycleWithForcedVentilation);

        value.configurationSteps.elevatorMotor_ForcedVentilation.loaded = true;
        this.dispatch('configurations/change_configurationSteps_storeAction', value.configurationSteps);
    },

    change_elevatorMotor_ForcedVentilation_storeAction({ commit }, value) {
        commit('change_elevatorMotor_ForcedVentilation_storeMutation', value);
    },
    change_elevatorMotor_ForcedVentilation_Options_Shown_storeAction({ commit }, value) {
        commit('change_elevatorMotor_ForcedVentilation_Options_Shown_storeMutation', value)
    },

    async checkIf_elevatorMotor_Machine_FilterCriterion_elevatorMotor_ForcedVentilation_AvailableAndTrue_storeAction({ rootState, commit, dispatch }) {
        if ((state.elevatorMotor_Machine_Data.elevatorMotor_Machine_Filter_Data.elevatorMotor_ForcedVentilation_AvailableAndOrNecessary == true) && (state.elevatorMotor_Machine_FilterCriterion_elevatorMotor_ForcedVentilation == true)) {
            dispatch('change_Selected_at_elevatorMotor_ForcedVentilation_storeAction', true)
            await dispatch('insert_databaseRecord_at_elevatorMotor_ForcedVentilations_storeAction', state.elevatorMotor_ForcedVentilation);

            if ((rootState.user.userPreferences.hasOwnProperty('useOf_ElevatorMotor_ForcedVentilation') == true) && (rootState.user.userPreferences.useOf_ElevatorMotor_ForcedVentilation == true)) {
                commit('change_userPreference_affected_elevatorMotor_ForcedVentilation_Selected_storeMutation', true)
                commit('change_elevatorMotor_ForcedVentilation_Options_Shown_storeMutation', false)
            }
        }
        else {
            dispatch('change_Selected_at_elevatorMotor_ForcedVentilation_storeAction', false)
            await dispatch('insert_databaseRecord_at_elevatorMotor_ForcedVentilations_storeAction', state.elevatorMotor_ForcedVentilation);
        }
    },

    async checkIf_changesAt_elevatorMotor_ForcedVentilation_changes_configurationFlow_storeAction({ rootState, commit, dispatch }, value) {
        dispatch('change_Selected_at_elevatorMotor_ForcedVentilation_storeAction', value)

        const firstIndexWhere_Selected_atElementOf_elevatorMotor_Machine_Selection_isTrue = state.elevatorMotor_Machine_Data.elevatorMotor_Machine_Selection.findIndex(i => i.selected == true)
        if (firstIndexWhere_Selected_atElementOf_elevatorMotor_Machine_Selection_isTrue > -1) {
            if (value == true && state.permissibleDutyCycleWithForcedVentilation != null) {
                commit('change_permissibleDutyCycle_at_ElementOf_elevatorMotor_Machine_Selection_storeMutation', { index: firstIndexWhere_Selected_atElementOf_elevatorMotor_Machine_Selection_isTrue, permissibleDutyCycle: state.permissibleDutyCycleWithForcedVentilation })
            }
            else if (value == false && state.permissibleDutyCycleWithoutForcedVentilation != null) {
                commit('change_permissibleDutyCycle_at_ElementOf_elevatorMotor_Machine_Selection_storeMutation', { index: firstIndexWhere_Selected_atElementOf_elevatorMotor_Machine_Selection_isTrue, permissibleDutyCycle: state.permissibleDutyCycleWithoutForcedVentilation })
            }

            const elevatorMotor_Machine = Object.assign({}, state.elevatorMotor_Machine_Data.elevatorMotor_Machine_Selection[firstIndexWhere_Selected_atElementOf_elevatorMotor_Machine_Selection_isTrue].elevatorMotor_Machine)
            if (rootState.configurations.configurationSteps.elevatorMotor_ForcedVentilation.storedInDatabase == true) {
                await this.dispatch('configurations/selectAndInsert_or_updateAndOrDelete_databaseRecords_storeAction', { changesAt: this._vm.$configurationSteps.elevatorMotor_ForcedVentilation, elevatorMotor_ForcedVentilation: state.elevatorMotor_ForcedVentilation, elevatorMotor_Machine: elevatorMotor_Machine });
                this.dispatch('configurations/check_toDos_at_driveTechnologies_storeAction')
            }
            else {
                dispatch('insert_databaseRecord_at_elevatorMotor_ForcedVentilations_storeAction', state.elevatorMotor_ForcedVentilation);
            }

        }
        else {
            if (rootState.configurations.configurationSteps.elevatorMotor_ForcedVentilation.storedInDatabase == true) {
                await this.dispatch('configurations/selectAndInsert_or_updateAndOrDelete_databaseRecords_storeAction', { changesAt: this._vm.$configurationSteps.elevatorMotor_ForcedVentilation, elevatorMotor_ForcedVentilation: state.elevatorMotor_ForcedVentilation });
                this.dispatch('configurations/check_toDos_at_driveTechnologies_storeAction')
            }
            else {
                dispatch('insert_databaseRecord_at_elevatorMotor_ForcedVentilations_storeAction', state.elevatorMotor_ForcedVentilation);
            }
        }
    },
    change_Selected_at_elevatorMotor_ForcedVentilation_storeAction({ commit }, value) {
        commit('change_Selected_at_elevatorMotor_ForcedVentilation_storeMutation', value);
    },

    async insert_databaseRecord_at_elevatorMotor_ForcedVentilations_storeAction({ rootState }, value) {

        var elevatorMotorMachine;
        const firstIndexWhere_Selected_atElementOf_elevatorMotor_Machine_Selection_isTrue = state.elevatorMotor_Machine_Data.elevatorMotor_Machine_Selection.findIndex(i => i.selected == true)
        if (firstIndexWhere_Selected_atElementOf_elevatorMotor_Machine_Selection_isTrue > -1) {
            elevatorMotorMachine = Object.assign({}, state.elevatorMotor_Machine_Data.elevatorMotor_Machine_Selection[firstIndexWhere_Selected_atElementOf_elevatorMotor_Machine_Selection_isTrue].elevatorMotor_Machine)
        }

        const data = {
            configurationOverviewsId: rootState.configurations.configurationOverviewsId,
            configurationSteps: rootState.configurations.configurationSteps,
            elevatorMotor_ForcedVentilation: value,
            elevatorMotor_Machine: elevatorMotorMachine,
        }

        await axios({
            method: 'post',
            url: '/api/driveTechnologies/insert_databaseRecord_at_elevatorMotor_ForcedVentilations',
            data: {
                data: data,
            }
        })
            .then((response) => {
                this.dispatch('statusHandling/change_dataAndStatus_storeAction', response.data);

                if (response.data.status.statusCode.startsWith('2')) {
                    this.dispatch('configurations/change_configurationSteps_storeAction', response.data.data.configurationSteps);
                }
            })
            .catch(() => { })
    },

    change_elevatorMotor_Brake_toDefault_storeAction({ commit }) {
        commit('change_elevatorMotor_Brake_Data_storeMutation', {});
        commit('change_elevatorMotor_Brake_Selections_Shown_storeMutation', true);
        commit('change_loader_forLoadingData_at_elevatorMotor_Brake_Shown_storeMutation', false);
        commit('change_elevatorMotor_Brake_MechanicalRelease_ChangedByUser_storeMutation', false);
        commit('change_elevatorMotor_Brake_ReleaseMonitoring_ChangedByUser_storeMutation', false);

    },

    async get_elevatorMotor_Brake_Informations_storeAction({ rootState, dispatch }) {
        const data = {
            configurationOverviewsId: rootState.configurations.configurationOverviewsId,
            configurationSteps: rootState.configurations.configurationSteps,
        }

        await axios({
            method: 'post',
            url: '/api/driveTechnologies/get_elevatorMotor_Brake_Informations',
            data: {
                data: data,
            }
        })
            .then((response) => {
                this.dispatch('statusHandling/change_dataAndStatus_storeAction', response.data);

                if ((response.data.status.statusCode.substring(0, 1) == '2') || (response.data.status.statusCode.substring(0, 2) == '74') || (response.data.status.statusCode.substring(0, 2) == '75')) {
                    dispatch('toDos_afterResponseOf_get_elevatorMotor_Brake_Informations_storeAction', response.data.data)
                }

            })
            .catch(() => { })
    },
    toDos_afterResponseOf_get_elevatorMotor_Brake_Informations_storeAction({ dispatch }, value) {
        dispatch('change_elevatorMotor_Brake_Data_storeAction', value.elevatorMotor_Brake_Data);
        value.configurationSteps.elevatorMotor_Brake.loaded = true;
        this.dispatch('configurations/change_configurationSteps_storeAction', value.configurationSteps);
    },

    change_elevatorMotor_Brake_Data_storeAction({ commit }, value) {
        commit('change_elevatorMotor_Brake_Data_storeMutation', value);
    },
    change_elevatorMotor_Brake_Selections_Shown_storeAction({ commit }, value) {
        commit('change_elevatorMotor_Brake_Selections_Shown_storeMutation', value)
    },

    async checkIf_elevatorMotor_Machine_FilterCriterion_elevatorMotor_Brake_MechanicalRelease_AvailableAndTrue_storeAction({ rootState, dispatch }) {
        if (rootState.user.userPreferences_Loaded == false) {
            await this.dispatch('user/get_userPreference_Informations_storeAction')
        }

        if (state.elevatorMotor_Machine_Data.elevatorMotor_Machine_Filter_Data.elevatorMotor_Brake_MechanicalRelease_Available == true) {
            if (state.elevatorMotor_Machine_FilterCriterion_elevatorMotor_Brake_MechanicalRelease == true) {
                if ((rootState.user.userPreferences.hasOwnProperty('elevatorMotor_Brake_MechanicalRelease') == true) && ((rootState.user.userPreferences.elevatorMotor_Brake_MechanicalRelease == 'hand release') || (rootState.user.userPreferences.elevatorMotor_Brake_MechanicalRelease == 'bowdenwire hand release'))) {
                    await dispatch('checkIf_userPreferences_elevatorMotor_Brake_NominalVoltage_and_elevatorMotor_Brake_ReleaseMonitoring_Set_while_userPreference_elevatorMotor_Brake_MechanicalRelease_Set_storeAction')
                }
                else {
                    var firstIndex_whichMeetCondition = state.elevatorMotor_Brake_Data.elevatorMotor_Brake_Selection.findIndex(i => i.elevatorMotor_Brake.mechanicalRelease == 'hand release');
                    if (firstIndex_whichMeetCondition > -1) {
                        dispatch('change_Selected_at_elevatorMotor_Brake_Selection_storeAction', firstIndex_whichMeetCondition);
                        dispatch('change_Selected_at_elevatorMotor_Brake_Voltage_Selection_basedOnChangesTo_Selected_at_elevatorMotor_Brake_Selection_storeAction');
                        dispatch('change_Selected_at_elevatorMotor_Brake_MechanicalRelease_Selection_basedOnChangesTo_Selected_at_elevatorMotor_Brake_Selection_storeAction');
                        dispatch('change_Selected_at_elevatorMotor_Brake_ReleaseMonitoring_Selection_basedOnChangesTo_Selected_at_elevatorMotor_Brake_Selection_storeAction');
                        dispatch('change_Disabled_at_elevatorMotor_Brake_MechanicalRelease_Selection_basedOnChangesTo_Selected_at_elevatorMotor_Brake_Selection_storeAction');
                        dispatch('change_Disabled_at_elevatorMotor_Brake_ReleaseMonitoring_Selection_basedOnChangesTo_Selected_at_elevatorMotor_Brake_Selection_storeAction');
                        await dispatch('insert_databaseRecord_at_elevatorMotor_Brakes_storeAction', state.elevatorMotor_Brake_Data.elevatorMotor_Brake_Selection[firstIndex_whichMeetCondition].elevatorMotor_Brake)
                    }
                    else {
                        firstIndex_whichMeetCondition = state.elevatorMotor_Brake_Data.elevatorMotor_Brake_Selection.findIndex(i => i.elevatorMotor_Brake.mechanicalRelease == 'bowdenwire hand release')
                        if (firstIndex_whichMeetCondition > -1) {
                            dispatch('change_Selected_at_elevatorMotor_Brake_Selection_storeAction', firstIndex_whichMeetCondition);
                            dispatch('change_Selected_at_elevatorMotor_Brake_Voltage_Selection_basedOnChangesTo_Selected_at_elevatorMotor_Brake_Selection_storeAction');
                            dispatch('change_Selected_at_elevatorMotor_Brake_MechanicalRelease_Selection_basedOnChangesTo_Selected_at_elevatorMotor_Brake_Selection_storeAction');
                            dispatch('change_Selected_at_elevatorMotor_Brake_ReleaseMonitoring_Selection_basedOnChangesTo_Selected_at_elevatorMotor_Brake_Selection_storeAction');
                            dispatch('change_Disabled_at_elevatorMotor_Brake_MechanicalRelease_Selection_basedOnChangesTo_Selected_at_elevatorMotor_Brake_Selection_storeAction');
                            dispatch('change_Disabled_at_elevatorMotor_Brake_ReleaseMonitoring_Selection_basedOnChangesTo_Selected_at_elevatorMotor_Brake_Selection_storeAction');
                            await dispatch('insert_databaseRecord_at_elevatorMotor_Brakes_storeAction', state.elevatorMotor_Brake_Data.elevatorMotor_Brake_Selection[firstIndex_whichMeetCondition].elevatorMotor_Brake)
                        }
                        else {
                            await dispatch('change_Selected_atFirstElementOf_elevatorMotor_Brake_Selection_toTrue_storeAction')
                        }
                    }
                }
            }
            else {
                if ((rootState.user.userPreferences.hasOwnProperty('elevatorMotor_Brake_MechanicalRelease') == true) && (rootState.user.userPreferences.elevatorMotor_Brake_MechanicalRelease != null)) {
                    if ((rootState.user.userPreferences.elevatorMotor_Brake_MechanicalRelease == 'hand release') || (rootState.user.userPreferences.elevatorMotor_Brake_MechanicalRelease == 'bowdenwire hand release')) {
                        await dispatch('change_Selected_atFirstElementOf_elevatorMotor_Brake_Selection_toTrue_storeAction')
                    }
                    else if (rootState.user.userPreferences.elevatorMotor_Brake_MechanicalRelease == 'without') {
                        await dispatch('checkIf_userPreferences_elevatorMotor_Brake_NominalVoltage_and_elevatorMotor_Brake_ReleaseMonitoring_Set_while_userPreference_elevatorMotor_Brake_MechanicalRelease_Set_storeAction')
                    }
                    else {
                        await dispatch('change_Selected_atFirstElementOf_elevatorMotor_Brake_Selection_toTrue_storeAction')
                    }
                }
                else {
                    await dispatch('change_Selected_atFirstElementOf_elevatorMotor_Brake_Selection_toTrue_storeAction')
                }
            }
        }
        else {
            if ((rootState.user.userPreferences.hasOwnProperty('elevatorMotor_Brake_MechanicalRelease') == true) && (rootState.user.userPreferences.elevatorMotor_Brake_MechanicalRelease != null)) {
                if (rootState.user.userPreferences.elevatorMotor_Brake_MechanicalRelease == 'without') {
                    await dispatch('checkIf_userPreferences_elevatorMotor_Brake_NominalVoltage_and_elevatorMotor_Brake_ReleaseMonitoring_Set_while_userPreference_elevatorMotor_Brake_MechanicalRelease_Set_storeAction')
                }
            }
            else {
                await dispatch('checkIf_userPreferences_elevatorMotor_Brake_NominalVoltage_and_elevatorMotor_Brake_ReleaseMonitoring_Set_while_userPreference_elevatorMotor_Brake_MechanicalRelease_notSet_storeAction')
            }
        }
    },

    async checkIf_userPreferences_elevatorMotor_Brake_NominalVoltage_and_elevatorMotor_Brake_ReleaseMonitoring_Set_while_userPreference_elevatorMotor_Brake_MechanicalRelease_Set_storeAction({ rootState, dispatch }) {
        if ((rootState.user.userPreferences.hasOwnProperty('elevatorMotor_Brake_NominalVoltage') == false) && (rootState.user.userPreferences.hasOwnProperty('elevatorMotor_Brake_ReleaseMonitoring') == false)) {
            await dispatch('checkIf_userPreference_elevatorMotor_Brake_MechanicalRelease_Possible_storeAction');
        }
        else if ((rootState.user.userPreferences.hasOwnProperty('elevatorMotor_Brake_NominalVoltage') == false) && (rootState.user.userPreferences.hasOwnProperty('elevatorMotor_Brake_ReleaseMonitoring') == true) && (rootState.user.userPreferences.elevatorMotor_Brake_ReleaseMonitoring != null)) {
            await dispatch('checkIf_userPreferences_elevatorMotor_Brake_MechanicalReleaseAndReleaseMonitoring_Possible_storeAction');
        }
        else if ((rootState.user.userPreferences.hasOwnProperty('elevatorMotor_Brake_NominalVoltage') == true) && (rootState.user.userPreferences.elevatorMotor_Brake_NominalVoltage > 0) && (rootState.user.userPreferences.hasOwnProperty('elevatorMotor_Brake_ReleaseMonitoring') == false)) {
            await dispatch('checkIf_userPreferences_elevatorMotor_Brake_NominalVoltageAndMechanicalRelease_Possible_storeAction');
        }
        else if ((rootState.user.userPreferences.hasOwnProperty('elevatorMotor_Brake_NominalVoltage') == true) && (rootState.user.userPreferences.elevatorMotor_Brake_NominalVoltage > 0) && (rootState.user.userPreferences.hasOwnProperty('elevatorMotor_Brake_ReleaseMonitoring') == true) && (rootState.user.userPreferences.elevatorMotor_Brake_ReleaseMonitoring != null)) {
            await dispatch('checkIf_userPreferences_elevatorMotor_Brake_NominalVoltageAndMechanicalReleaseAndReleaseMonitoring_Possible_storeAction');
        }
    },
    async checkIf_userPreferences_elevatorMotor_Brake_NominalVoltage_and_elevatorMotor_Brake_ReleaseMonitoring_Set_while_userPreference_elevatorMotor_Brake_MechanicalRelease_notSet_storeAction({ rootState, dispatch }) {
        if ((rootState.user.userPreferences.hasOwnProperty('elevatorMotor_Brake_NominalVoltage') == false) && (rootState.user.userPreferences.hasOwnProperty('elevatorMotor_Brake_ReleaseMonitoring') == false)) {
            await dispatch('change_Selected_atFirstElementOf_elevatorMotor_Brake_Selection_toTrue_storeAction')
        }
        else if ((rootState.user.userPreferences.hasOwnProperty('elevatorMotor_Brake_NominalVoltage') == false) && (rootState.user.userPreferences.hasOwnProperty('elevatorMotor_Brake_ReleaseMonitoring') == true) && (rootState.user.userPreferences.elevatorMotor_Brake_ReleaseMonitoring != null)) {
            await dispatch('checkIf_userPreference_elevatorMotor_Brake_ReleaseMonitoring_Possible_storeAction');
        }
        else if ((rootState.user.userPreferences.hasOwnProperty('elevatorMotor_Brake_NominalVoltage') == true) && (rootState.user.userPreferences.elevatorMotor_Brake_NominalVoltage > 0) && (rootState.user.userPreferences.hasOwnProperty('elevatorMotor_Brake_ReleaseMonitoring') == false)) {
            await dispatch('checkIf_userPreference_elevatorMotor_Brake_NominalVoltage_Possible_storeAction');
        }
        else if ((rootState.user.userPreferences.hasOwnProperty('elevatorMotor_Brake_NominalVoltage') == true) && (rootState.user.userPreferences.elevatorMotor_Brake_NominalVoltage > 0) && (rootState.user.userPreferences.hasOwnProperty('elevatorMotor_Brake_ReleaseMonitoring') == true) && (rootState.user.userPreferences.elevatorMotor_Brake_ReleaseMonitoring != null)) {
            await dispatch('checkIf_userPreferences_elevatorMotor_Brake_NominalVoltageAndReleaseMonitoring_Possible_storeAction');
        }
    },

    async checkIf_userPreference_elevatorMotor_Brake_NominalVoltage_Possible_storeAction({ rootState, commit, dispatch }) {
        const firstIndex_whichMeetCondition = state.elevatorMotor_Brake_Data.elevatorMotor_Brake_Selection.findIndex(i => i.elevatorMotor_Brake.nominalVoltage == rootState.user.userPreferences.elevatorMotor_Brake_NominalVoltage);
        if (firstIndex_whichMeetCondition > -1) {
            commit('change_userPreference_affected_selected_elevatorMotor_Brake_NominalVoltage_storeMutation', true);
            commit('change_userPreference_affected_selected_elevatorMotor_Brake_MechanicalRelease_storeMutation', false);
            commit('change_userPreference_affected_selected_elevatorMotor_Brake_ReleaseMonitoring_storeMutation', false);
            commit('change_userPreferences_affected_selected_elevatorMotor_Brake_storeMutation', false);
            dispatch('change_Selected_at_elevatorMotor_Brake_Selection_storeAction', firstIndex_whichMeetCondition);
            dispatch('change_Selected_at_elevatorMotor_Brake_Voltage_Selection_basedOnChangesTo_Selected_at_elevatorMotor_Brake_Selection_storeAction');
            dispatch('change_Selected_at_elevatorMotor_Brake_MechanicalRelease_Selection_basedOnChangesTo_Selected_at_elevatorMotor_Brake_Selection_storeAction');
            dispatch('change_Selected_at_elevatorMotor_Brake_ReleaseMonitoring_Selection_basedOnChangesTo_Selected_at_elevatorMotor_Brake_Selection_storeAction');
            dispatch('change_Disabled_at_elevatorMotor_Brake_MechanicalRelease_Selection_basedOnChangesTo_Selected_at_elevatorMotor_Brake_Selection_storeAction');
            dispatch('change_Disabled_at_elevatorMotor_Brake_ReleaseMonitoring_Selection_basedOnChangesTo_Selected_at_elevatorMotor_Brake_Selection_storeAction');
            await dispatch('insert_databaseRecord_at_elevatorMotor_Brakes_storeAction', state.elevatorMotor_Brake_Data.elevatorMotor_Brake_Selection[firstIndex_whichMeetCondition].elevatorMotor_Brake)
        }
        else {
            await dispatch('change_Selected_atFirstElementOf_elevatorMotor_Brake_Selection_toTrue_storeAction')
        }
    },
    async checkIf_userPreference_elevatorMotor_Brake_MechanicalRelease_Possible_storeAction({ rootState, commit, dispatch }) {
        const firstIndex_whichMeetCondition = state.elevatorMotor_Brake_Data.elevatorMotor_Brake_Selection.findIndex(i => i.elevatorMotor_Brake.mechanicalRelease == rootState.user.userPreferences.elevatorMotor_Brake_MechanicalRelease);
        if (firstIndex_whichMeetCondition > -1) {
            commit('change_userPreference_affected_selected_elevatorMotor_Brake_NominalVoltage_storeMutation', false);
            commit('change_userPreference_affected_selected_elevatorMotor_Brake_MechanicalRelease_storeMutation', true);
            commit('change_userPreference_affected_selected_elevatorMotor_Brake_ReleaseMonitoring_storeMutation', false);
            commit('change_userPreferences_affected_selected_elevatorMotor_Brake_storeMutation', false);
            dispatch('change_Selected_at_elevatorMotor_Brake_Selection_storeAction', firstIndex_whichMeetCondition);
            dispatch('change_Selected_at_elevatorMotor_Brake_Voltage_Selection_basedOnChangesTo_Selected_at_elevatorMotor_Brake_Selection_storeAction');
            dispatch('change_Selected_at_elevatorMotor_Brake_MechanicalRelease_Selection_basedOnChangesTo_Selected_at_elevatorMotor_Brake_Selection_storeAction');
            dispatch('change_Selected_at_elevatorMotor_Brake_ReleaseMonitoring_Selection_basedOnChangesTo_Selected_at_elevatorMotor_Brake_Selection_storeAction');
            dispatch('change_Disabled_at_elevatorMotor_Brake_MechanicalRelease_Selection_basedOnChangesTo_Selected_at_elevatorMotor_Brake_Selection_storeAction');
            dispatch('change_Disabled_at_elevatorMotor_Brake_ReleaseMonitoring_Selection_basedOnChangesTo_Selected_at_elevatorMotor_Brake_Selection_storeAction');
            await dispatch('insert_databaseRecord_at_elevatorMotor_Brakes_storeAction', state.elevatorMotor_Brake_Data.elevatorMotor_Brake_Selection[firstIndex_whichMeetCondition].elevatorMotor_Brake)

        }
        else {
            await dispatch('change_Selected_atFirstElementOf_elevatorMotor_Brake_Selection_toTrue_storeAction')
        }
    },
    async checkIf_userPreference_elevatorMotor_Brake_ReleaseMonitoring_Possible_storeAction({ rootState, commit, dispatch }) {
        const firstIndex_whichMeetCondition = state.elevatorMotor_Brake_Data.elevatorMotor_Brake_Selection.findIndex(i => i.elevatorMotor_Brake.releaseMonitoring == rootState.user.userPreferences.elevatorMotor_Brake_ReleaseMonitoring);
        if (firstIndex_whichMeetCondition > -1) {
            commit('change_userPreference_affected_selected_elevatorMotor_Brake_NominalVoltage_storeMutation', false);
            commit('change_userPreference_affected_selected_elevatorMotor_Brake_MechanicalRelease_storeMutation', false);
            commit('change_userPreference_affected_selected_elevatorMotor_Brake_ReleaseMonitoring_storeMutation', true);
            commit('change_userPreferences_affected_selected_elevatorMotor_Brake_storeMutation', false);
            dispatch('change_Selected_at_elevatorMotor_Brake_Selection_storeAction', firstIndex_whichMeetCondition);
            dispatch('change_Selected_at_elevatorMotor_Brake_Voltage_Selection_basedOnChangesTo_Selected_at_elevatorMotor_Brake_Selection_storeAction');
            dispatch('change_Selected_at_elevatorMotor_Brake_MechanicalRelease_Selection_basedOnChangesTo_Selected_at_elevatorMotor_Brake_Selection_storeAction');
            dispatch('change_Selected_at_elevatorMotor_Brake_ReleaseMonitoring_Selection_basedOnChangesTo_Selected_at_elevatorMotor_Brake_Selection_storeAction');
            dispatch('change_Disabled_at_elevatorMotor_Brake_MechanicalRelease_Selection_basedOnChangesTo_Selected_at_elevatorMotor_Brake_Selection_storeAction');
            dispatch('change_Disabled_at_elevatorMotor_Brake_ReleaseMonitoring_Selection_basedOnChangesTo_Selected_at_elevatorMotor_Brake_Selection_storeAction');
            await dispatch('insert_databaseRecord_at_elevatorMotor_Brakes_storeAction', state.elevatorMotor_Brake_Data.elevatorMotor_Brake_Selection[firstIndex_whichMeetCondition].elevatorMotor_Brake)

        }
        else {
            await dispatch('change_Selected_atFirstElementOf_elevatorMotor_Brake_Selection_toTrue_storeAction')
        }
    },
    async checkIf_userPreferences_elevatorMotor_Brake_NominalVoltageAndMechanicalRelease_Possible_storeAction({ rootState, commit, dispatch }) {
        const firstIndex_whichMeetConditions = state.elevatorMotor_Brake_Data.elevatorMotor_Brake_Selection.findIndex(i => ((i.elevatorMotor_Brake.nominalVoltage == rootState.user.userPreferences.elevatorMotor_Brake_NominalVoltage) && (i.elevatorMotor_Brake.mechanicalRelease == rootState.user.userPreferences.elevatorMotor_Brake_MechanicalRelease)));
        if (firstIndex_whichMeetConditions > -1) {
            commit('change_userPreference_affected_selected_elevatorMotor_Brake_NominalVoltage_storeMutation', true);
            commit('change_userPreference_affected_selected_elevatorMotor_Brake_MechanicalRelease_storeMutation', true);
            commit('change_userPreference_affected_selected_elevatorMotor_Brake_ReleaseMonitoring_storeMutation', false);
            commit('change_userPreferences_affected_selected_elevatorMotor_Brake_storeMutation', false);
            dispatch('change_Selected_at_elevatorMotor_Brake_Selection_storeAction', firstIndex_whichMeetConditions);
            dispatch('change_Selected_at_elevatorMotor_Brake_Voltage_Selection_basedOnChangesTo_Selected_at_elevatorMotor_Brake_Selection_storeAction');
            dispatch('change_Selected_at_elevatorMotor_Brake_MechanicalRelease_Selection_basedOnChangesTo_Selected_at_elevatorMotor_Brake_Selection_storeAction');
            dispatch('change_Selected_at_elevatorMotor_Brake_ReleaseMonitoring_Selection_basedOnChangesTo_Selected_at_elevatorMotor_Brake_Selection_storeAction');
            dispatch('change_Disabled_at_elevatorMotor_Brake_MechanicalRelease_Selection_basedOnChangesTo_Selected_at_elevatorMotor_Brake_Selection_storeAction');
            dispatch('change_Disabled_at_elevatorMotor_Brake_ReleaseMonitoring_Selection_basedOnChangesTo_Selected_at_elevatorMotor_Brake_Selection_storeAction');
            await dispatch('insert_databaseRecord_at_elevatorMotor_Brakes_storeAction', state.elevatorMotor_Brake_Data.elevatorMotor_Brake_Selection[firstIndex_whichMeetConditions].elevatorMotor_Brake)
        }
        else {
            await dispatch('change_Selected_atFirstElementOf_elevatorMotor_Brake_Selection_toTrue_storeAction')
        }
    },
    async checkIf_userPreferences_elevatorMotor_Brake_NominalVoltageAndReleaseMonitoring_Possible_storeAction({ rootState, commit, dispatch }) {
        const firstIndex_whichMeetConditions = state.elevatorMotor_Brake_Data.elevatorMotor_Brake_Selection.findIndex(i => ((i.elevatorMotor_Brake.nominalVoltage == rootState.user.userPreferences.elevatorMotor_Brake_NominalVoltage) && (i.elevatorMotor_Brake.releaseMonitoring == rootState.user.userPreferences.elevatorMotor_Brake_ReleaseMonitoring)));
        if (firstIndex_whichMeetConditions > -1) {
            commit('change_userPreference_affected_selected_elevatorMotor_Brake_NominalVoltage_storeMutation', true);
            commit('change_userPreference_affected_selected_elevatorMotor_Brake_MechanicalRelease_storeMutation', false);
            commit('change_userPreference_affected_selected_elevatorMotor_Brake_ReleaseMonitoring_storeMutation', true);
            commit('change_userPreferences_affected_selected_elevatorMotor_Brake_storeMutation', false);
            dispatch('change_Selected_at_elevatorMotor_Brake_Selection_storeAction', firstIndex_whichMeetConditions);
            dispatch('change_Selected_at_elevatorMotor_Brake_Voltage_Selection_basedOnChangesTo_Selected_at_elevatorMotor_Brake_Selection_storeAction');
            dispatch('change_Selected_at_elevatorMotor_Brake_MechanicalRelease_Selection_basedOnChangesTo_Selected_at_elevatorMotor_Brake_Selection_storeAction');
            dispatch('change_Selected_at_elevatorMotor_Brake_ReleaseMonitoring_Selection_basedOnChangesTo_Selected_at_elevatorMotor_Brake_Selection_storeAction');
            dispatch('change_Disabled_at_elevatorMotor_Brake_MechanicalRelease_Selection_basedOnChangesTo_Selected_at_elevatorMotor_Brake_Selection_storeAction');
            dispatch('change_Disabled_at_elevatorMotor_Brake_ReleaseMonitoring_Selection_basedOnChangesTo_Selected_at_elevatorMotor_Brake_Selection_storeAction');
            await dispatch('insert_databaseRecord_at_elevatorMotor_Brakes_storeAction', state.elevatorMotor_Brake_Data.elevatorMotor_Brake_Selection[firstIndex_whichMeetCondition].elevatorMotor_Brake)
        }
        else {
            await dispatch('change_Selected_atFirstElementOf_elevatorMotor_Brake_Selection_toTrue_storeAction')
        }
    },
    async checkIf_userPreferences_elevatorMotor_Brake_MechanicalReleaseAndReleaseMonitoring_Possible_storeAction({ rootState, commit, dispatch }) {
        const firstIndex_whichMeetConditions = state.elevatorMotor_Brake_Data.elevatorMotor_Brake_Selection.findIndex(i => ((i.elevatorMotor_Brake.mechanicalRelease == rootState.user.userPreferences.elevatorMotor_Brake_MechanicalRelease) && (i.elevatorMotor_Brake.releaseMonitoring == rootState.user.userPreferences.elevatorMotor_Brake_ReleaseMonitoring)));
        if (firstIndex_whichMeetConditions > -1) {
            commit('change_userPreference_affected_selected_elevatorMotor_Brake_NominalVoltage_storeMutation', false);
            commit('change_userPreference_affected_selected_elevatorMotor_Brake_MechanicalRelease_storeMutation', true);
            commit('change_userPreference_affected_selected_elevatorMotor_Brake_ReleaseMonitoring_storeMutation', true);
            commit('change_userPreferences_affected_selected_elevatorMotor_Brake_storeMutation', false);
            dispatch('change_Selected_at_elevatorMotor_Brake_Selection_storeAction', firstIndex_whichMeetConditions);
            dispatch('change_Selected_at_elevatorMotor_Brake_Voltage_Selection_basedOnChangesTo_Selected_at_elevatorMotor_Brake_Selection_storeAction');
            dispatch('change_Selected_at_elevatorMotor_Brake_MechanicalRelease_Selection_basedOnChangesTo_Selected_at_elevatorMotor_Brake_Selection_storeAction');
            dispatch('change_Selected_at_elevatorMotor_Brake_ReleaseMonitoring_Selection_basedOnChangesTo_Selected_at_elevatorMotor_Brake_Selection_storeAction');
            dispatch('change_Disabled_at_elevatorMotor_Brake_MechanicalRelease_Selection_basedOnChangesTo_Selected_at_elevatorMotor_Brake_Selection_storeAction');
            dispatch('change_Disabled_at_elevatorMotor_Brake_ReleaseMonitoring_Selection_basedOnChangesTo_Selected_at_elevatorMotor_Brake_Selection_storeAction');
            await dispatch('insert_databaseRecord_at_elevatorMotor_Brakes_storeAction', state.elevatorMotor_Brake_Data.elevatorMotor_Brake_Selection[firstIndex_whichMeetConditions].elevatorMotor_Brake)
        }
        else {
            await dispatch('change_Selected_atFirstElementOf_elevatorMotor_Brake_Selection_toTrue_storeAction')
        }
    },
    async checkIf_userPreferences_elevatorMotor_Brake_NominalVoltageAndMechanicalReleaseAndReleaseMonitoring_Possible_storeAction({ rootState, commit, dispatch }) {
        const firstIndex_whichMeetConditions = state.elevatorMotor_Brake_Data.elevatorMotor_Brake_Selection.findIndex(i => ((i.elevatorMotor_Brake.nominalVoltage == rootState.user.userPreferences.elevatorMotor_Brake_NominalVoltage) && (i.elevatorMotor_Brake.mechanicalRelease == rootState.user.userPreferences.elevatorMotor_Brake_MechanicalRelease) && (i.elevatorMotor_Brake.releaseMonitoring == rootState.user.userPreferences.elevatorMotor_Brake_ReleaseMonitoring)));
        if (firstIndex_whichMeetConditions > -1) {
            commit('change_userPreference_affected_selected_elevatorMotor_Brake_NominalVoltage_storeMutation', true);
            commit('change_userPreference_affected_selected_elevatorMotor_Brake_MechanicalRelease_storeMutation', true);
            commit('change_userPreference_affected_selected_elevatorMotor_Brake_ReleaseMonitoring_storeMutation', true);
            commit('change_userPreferences_affected_selected_elevatorMotor_Brake_storeMutation', true);
            commit('change_elevatorMotor_Brake_Selections_Shown_storeMutation', false)

            dispatch('change_Selected_at_elevatorMotor_Brake_Selection_storeAction', firstIndex_whichMeetConditions);
            dispatch('change_Selected_at_elevatorMotor_Brake_Voltage_Selection_basedOnChangesTo_Selected_at_elevatorMotor_Brake_Selection_storeAction');
            dispatch('change_Selected_at_elevatorMotor_Brake_MechanicalRelease_Selection_basedOnChangesTo_Selected_at_elevatorMotor_Brake_Selection_storeAction');
            dispatch('change_Selected_at_elevatorMotor_Brake_ReleaseMonitoring_Selection_basedOnChangesTo_Selected_at_elevatorMotor_Brake_Selection_storeAction');
            dispatch('change_Disabled_at_elevatorMotor_Brake_MechanicalRelease_Selection_basedOnChangesTo_Selected_at_elevatorMotor_Brake_Selection_storeAction');
            dispatch('change_Disabled_at_elevatorMotor_Brake_ReleaseMonitoring_Selection_basedOnChangesTo_Selected_at_elevatorMotor_Brake_Selection_storeAction');
            await dispatch('insert_databaseRecord_at_elevatorMotor_Brakes_storeAction', state.elevatorMotor_Brake_Data.elevatorMotor_Brake_Selection[firstIndex_whichMeetConditions].elevatorMotor_Brake)
        }
        else {
            await dispatch('change_Selected_atFirstElementOf_elevatorMotor_Brake_Selection_toTrue_storeAction')
        }
    },

    async change_Selected_atFirstElementOf_elevatorMotor_Brake_Selection_toTrue_storeAction({ commit, dispatch }) {
        commit('change_userPreference_affected_selected_elevatorMotor_Brake_NominalVoltage_storeMutation', false);
        commit('change_userPreference_affected_selected_elevatorMotor_Brake_MechanicalRelease_storeMutation', false);
        commit('change_userPreference_affected_selected_elevatorMotor_Brake_ReleaseMonitoring_storeMutation', false);
        commit('change_userPreferences_affected_selected_elevatorMotor_Brake_storeMutation', false);
        dispatch('change_Selected_at_elevatorMotor_Brake_Selection_storeAction', 0);
        dispatch('change_Selected_at_elevatorMotor_Brake_Voltage_Selection_basedOnChangesTo_Selected_at_elevatorMotor_Brake_Selection_storeAction');
        dispatch('change_Selected_at_elevatorMotor_Brake_MechanicalRelease_Selection_basedOnChangesTo_Selected_at_elevatorMotor_Brake_Selection_storeAction');
        dispatch('change_Selected_at_elevatorMotor_Brake_ReleaseMonitoring_Selection_basedOnChangesTo_Selected_at_elevatorMotor_Brake_Selection_storeAction');
        dispatch('change_Disabled_at_elevatorMotor_Brake_MechanicalRelease_Selection_basedOnChangesTo_Selected_at_elevatorMotor_Brake_Selection_storeAction');
        dispatch('change_Disabled_at_elevatorMotor_Brake_ReleaseMonitoring_Selection_basedOnChangesTo_Selected_at_elevatorMotor_Brake_Selection_storeAction');

        await dispatch('insert_databaseRecord_at_elevatorMotor_Brakes_storeAction', state.elevatorMotor_Brake_Data.elevatorMotor_Brake_Selection[0].elevatorMotor_Brake)

    },

    async checkIf_changesAt_elevatorMotor_Brake_changes_configurationFlow_storeAction({ rootState, dispatch }, value) {
        dispatch('change_Selected_at_elevatorMotor_Brake_Selection_storeAction', value)
        dispatch('change_Selected_at_elevatorMotor_Brake_Voltage_Selection_basedOnChangesTo_Selected_at_elevatorMotor_Brake_Selection_storeAction');
        dispatch('change_Selected_at_elevatorMotor_Brake_MechanicalRelease_Selection_basedOnChangesTo_Selected_at_elevatorMotor_Brake_Selection_storeAction');
        dispatch('change_Selected_at_elevatorMotor_Brake_ReleaseMonitoring_Selection_basedOnChangesTo_Selected_at_elevatorMotor_Brake_Selection_storeAction');
        dispatch('change_Disabled_at_elevatorMotor_Brake_MechanicalRelease_Selection_basedOnChangesTo_Selected_at_elevatorMotor_Brake_Selection_storeAction');
        dispatch('change_Disabled_at_elevatorMotor_Brake_ReleaseMonitoring_Selection_basedOnChangesTo_Selected_at_elevatorMotor_Brake_Selection_storeAction');

        if (rootState.configurations.configurationSteps.elevatorMotor_Brake.storedInDatabase == true) {
            await this.dispatch('configurations/selectAndInsert_or_updateAndOrDelete_databaseRecords_storeAction', { changesAt: this._vm.$configurationSteps.elevatorMotor_Brake, elevatorMotor_Brake: state.elevatorMotor_Brake_Data.elevatorMotor_Brake_Selection[value].elevatorMotor_Brake });
            this.dispatch('configurations/check_toDos_at_driveTechnologies_storeAction')
        }
        else {
            dispatch('insert_databaseRecord_at_elevatorMotor_Brakes_storeAction', state.elevatorMotor_Brake_Data.elevatorMotor_Brake_Selection[value].elevatorMotor_Brake)
        }
    },
    change_Selected_at_elevatorMotor_Brake_Selection_storeAction({ commit }, value) {
        commit('change_Selected_at_elevatorMotor_Brake_Selection_storeMutation', value);

        const firstIndexWhere_Selected_at_elevatorMotor_Brake_Selection_isTrue = state.elevatorMotor_Brake_Data.elevatorMotor_Brake_Selection.findIndex(i => i.selected == true);
        if (firstIndexWhere_Selected_at_elevatorMotor_Brake_Selection_isTrue > -1 && state.elevatorMotor_Brake_Data != null && state.elevatorMotor_Brake_Data.hasOwnProperty('elevatorMotor_Brake_Connection_Selection') && state.elevatorMotor_Brake_Data.elevatorMotor_Brake_Connection_Selection != null) {
            const firstIndexWhere_Selected_at_elevatorMotor_BrakeConnection_Selection_isTrue = state.elevatorMotor_Brake_Data.elevatorMotor_Brake_Connection_Selection.findIndex(i => i.selected == true);
            if (firstIndexWhere_Selected_at_elevatorMotor_BrakeConnection_Selection_isTrue > -1) {
                const brakeConnection = {
                    index: firstIndexWhere_Selected_at_elevatorMotor_Brake_Selection_isTrue,
                    brakeConnectionBlockNumber: state.elevatorMotor_Brake_Data.elevatorMotor_Brake_Connection_Selection[firstIndexWhere_Selected_at_elevatorMotor_BrakeConnection_Selection_isTrue].blockNumber,
                    brakeConnectionTypeDesignation: state.elevatorMotor_Brake_Data.elevatorMotor_Brake_Connection_Selection[firstIndexWhere_Selected_at_elevatorMotor_BrakeConnection_Selection_isTrue].text,
                }
                commit('change_Selected_at_elevatorMotor_Brake_Selection_BrakeConnection_storeMutation', brakeConnection);
            }
        }
        if (firstIndexWhere_Selected_at_elevatorMotor_Brake_Selection_isTrue > -1 && state.elevatorMotor_Brake_Data != null && state.elevatorMotor_Brake_Data.hasOwnProperty('elevatorMotor_Brake_Bowdenwire_Selection') && state.elevatorMotor_Brake_Data.elevatorMotor_Brake_Bowdenwire_Selection != null) {
            const firstIndexWhere_Selected_at_elevatorMotor_Bowdenwire_Selection_isTrue = state.elevatorMotor_Brake_Data.elevatorMotor_Brake_Bowdenwire_Selection.findIndex(i => i.selected == true);
            if (firstIndexWhere_Selected_at_elevatorMotor_Bowdenwire_Selection_isTrue > -1) {
                const bowdenwire = {
                    index: firstIndexWhere_Selected_at_elevatorMotor_Brake_Selection_isTrue,
                    bowdenwireBlockNumber: state.elevatorMotor_Brake_Data.elevatorMotor_Brake_Bowdenwire_Selection[firstIndexWhere_Selected_at_elevatorMotor_Bowdenwire_Selection_isTrue].blockNumber,
                    bowdenwireDeliver: state.elevatorMotor_Brake_Data.elevatorMotor_Brake_Bowdenwire_Selection[firstIndexWhere_Selected_at_elevatorMotor_Bowdenwire_Selection_isTrue].deliver.text,
                    bowdenwireLength: state.elevatorMotor_Brake_Data.elevatorMotor_Brake_Bowdenwire_Selection[firstIndexWhere_Selected_at_elevatorMotor_Bowdenwire_Selection_isTrue].length,
                }
                commit('change_Selected_at_elevatorMotor_Brake_Selection_Bowdenwire_storeMutation', bowdenwire);
            }
        }
    },

    change_Selected_at_elevatorMotor_Brake_Selection_basedOnChangesTo_elevatorMotor_Brake_Voltage_storeAction({ commit, dispatch }, value) {
        var firstIndexWhere_Selected_at_elevatorMotor_Brake_Selection_hasToBeChangedToTrue = undefined;
        const firstIndexWhere_Selected_at_elevatorMotor_Brake_Selection_isTrue = state.elevatorMotor_Brake_Data.elevatorMotor_Brake_Selection.findIndex(i => i.selected == true);

        if ((state.elevatorMotor_Brake_MechanicalRelease_ChangedByUser == false) && (state.elevatorMotor_Brake_ReleaseMonitoring_ChangedByUser == false)) {
            firstIndexWhere_Selected_at_elevatorMotor_Brake_Selection_hasToBeChangedToTrue = state.elevatorMotor_Brake_Data.elevatorMotor_Brake_Selection.findIndex(i => i.elevatorMotor_Brake.nominalVoltage == value);
        }
        else if ((state.elevatorMotor_Brake_MechanicalRelease_ChangedByUser == true) && (state.elevatorMotor_Brake_ReleaseMonitoring_ChangedByUser == false)) {
            firstIndexWhere_Selected_at_elevatorMotor_Brake_Selection_hasToBeChangedToTrue = state.elevatorMotor_Brake_Data.elevatorMotor_Brake_Selection.findIndex(i => ((i.elevatorMotor_Brake.nominalVoltage == value) && (i.elevatorMotor_Brake.mechanicalRelease == state.elevatorMotor_Brake_Data.elevatorMotor_Brake_Selection[firstIndexWhere_Selected_at_elevatorMotor_Brake_Selection_isTrue].elevatorMotor_Brake.mechanicalRelease)))
            if (firstIndexWhere_Selected_at_elevatorMotor_Brake_Selection_hasToBeChangedToTrue == -1) {
                firstIndexWhere_Selected_at_elevatorMotor_Brake_Selection_hasToBeChangedToTrue = state.elevatorMotor_Brake_Data.elevatorMotor_Brake_Selection.findIndex(i => i.elevatorMotor_Brake.nominalVoltage == value);
            }
        }
        else if ((state.elevatorMotor_Brake_MechanicalRelease_ChangedByUser == false) && (state.elevatorMotor_Brake_ReleaseMonitoring_ChangedByUser == true)) {
            firstIndexWhere_Selected_at_elevatorMotor_Brake_Selection_hasToBeChangedToTrue = state.elevatorMotor_Brake_Data.elevatorMotor_Brake_Selection.findIndex(i => ((i.elevatorMotor_Brake.nominalVoltage == value) && (i.elevatorMotor_Brake.releaseMonitoring == state.elevatorMotor_Brake_Data.elevatorMotor_Brake_Selection[firstIndexWhere_Selected_at_elevatorMotor_Brake_Selection_isTrue].elevatorMotor_Brake.releaseMonitoring)))
            if (firstIndexWhere_Selected_at_elevatorMotor_Brake_Selection_hasToBeChangedToTrue == -1) {
                firstIndexWhere_Selected_at_elevatorMotor_Brake_Selection_hasToBeChangedToTrue = state.elevatorMotor_Brake_Data.elevatorMotor_Brake_Selection.findIndex(i => i.elevatorMotor_Brake.nominalVoltage == value);
            }
        }
        else if ((state.elevatorMotor_Brake_MechanicalRelease_ChangedByUser == true) && (state.elevatorMotor_Brake_ReleaseMonitoring_ChangedByUser == true)) {
            firstIndexWhere_Selected_at_elevatorMotor_Brake_Selection_hasToBeChangedToTrue = state.elevatorMotor_Brake_Data.elevatorMotor_Brake_Selection.findIndex(i => ((i.elevatorMotor_Brake.nominalVoltage == value) && (i.elevatorMotor_Brake.mechanicalRelease == state.elevatorMotor_Brake_Data.elevatorMotor_Brake_Selection[firstIndexWhere_Selected_at_elevatorMotor_Brake_Selection_isTrue].elevatorMotor_Brake.mechanicalRelease) && (i.elevatorMotor_Brake.releaseMonitoring == state.elevatorMotor_Brake_Data.elevatorMotor_Brake_Selection[firstIndexWhere_Selected_at_elevatorMotor_Brake_Selection_isTrue].elevatorMotor_Brake.releaseMonitoring)))
            if (firstIndexWhere_Selected_at_elevatorMotor_Brake_Selection_hasToBeChangedToTrue == -1) {
                firstIndexWhere_Selected_at_elevatorMotor_Brake_Selection_hasToBeChangedToTrue = state.elevatorMotor_Brake_Data.elevatorMotor_Brake_Selection.findIndex(i => i.elevatorMotor_Brake.nominalVoltage == value);
            }
        }

        if (firstIndexWhere_Selected_at_elevatorMotor_Brake_Selection_hasToBeChangedToTrue > -1) {
            commit('change_userPreference_affected_selected_elevatorMotor_Brake_NominalVoltage_storeMutation', false);
            commit('change_userPreferences_affected_selected_elevatorMotor_Brake_storeMutation', false);
            dispatch('checkIf_changesAt_elevatorMotor_Brake_changes_configurationFlow_storeAction', firstIndexWhere_Selected_at_elevatorMotor_Brake_Selection_hasToBeChangedToTrue);
        }
    },
    change_Selected_at_elevatorMotor_Brake_Selection_basedOnChangesTo_elevatorMotor_Brake_MechanicalRelease_storeAction({ commit, dispatch }, value) {
        var firstIndexWhere_Selected_at_elevatorMotor_Brake_Selection_hasToBeChangedToTrue = undefined;
        const firstIndexWhere_Selected_at_elevatorMotor_Brake_Selection_isTrue = state.elevatorMotor_Brake_Data.elevatorMotor_Brake_Selection.findIndex(i => i.selected == true);
        if (state.elevatorMotor_Brake_ReleaseMonitoring_ChangedByUser == false) {
            firstIndexWhere_Selected_at_elevatorMotor_Brake_Selection_hasToBeChangedToTrue = state.elevatorMotor_Brake_Data.elevatorMotor_Brake_Selection.findIndex(i => ((i.elevatorMotor_Brake.nominalVoltage == state.elevatorMotor_Brake_Data.elevatorMotor_Brake_Selection[firstIndexWhere_Selected_at_elevatorMotor_Brake_Selection_isTrue].elevatorMotor_Brake.nominalVoltage) && (i.elevatorMotor_Brake.mechanicalRelease == value)));
        }
        else {
            firstIndexWhere_Selected_at_elevatorMotor_Brake_Selection_hasToBeChangedToTrue = state.elevatorMotor_Brake_Data.elevatorMotor_Brake_Selection.findIndex(i => ((i.elevatorMotor_Brake.nominalVoltage == state.elevatorMotor_Brake_Data.elevatorMotor_Brake_Selection[firstIndexWhere_Selected_at_elevatorMotor_Brake_Selection_isTrue].elevatorMotor_Brake.nominalVoltage) && (i.elevatorMotor_Brake.mechanicalRelease == value) && (i.elevatorMotor_Brake.releaseMonitoring == state.elevatorMotor_Brake_Data.elevatorMotor_Brake_Selection[firstIndexWhere_Selected_at_elevatorMotor_Brake_Selection_isTrue].elevatorMotor_Brake.releaseMonitoring)));
            if (firstIndexWhere_Selected_at_elevatorMotor_Brake_Selection_hasToBeChangedToTrue == -1) {
                firstIndexWhere_Selected_at_elevatorMotor_Brake_Selection_hasToBeChangedToTrue = state.elevatorMotor_Brake_Data.elevatorMotor_Brake_Selection.findIndex(i => ((i.elevatorMotor_Brake.nominalVoltage == state.elevatorMotor_Brake_Data.elevatorMotor_Brake_Selection[firstIndexWhere_Selected_at_elevatorMotor_Brake_Selection_isTrue].elevatorMotor_Brake.nominalVoltage) && (i.elevatorMotor_Brake.mechanicalRelease == value)));
            }
        }

        if (firstIndexWhere_Selected_at_elevatorMotor_Brake_Selection_hasToBeChangedToTrue > -1) {
            commit('change_userPreference_affected_selected_elevatorMotor_Brake_MechanicalRelease_storeMutation', false);
            commit('change_userPreferences_affected_selected_elevatorMotor_Brake_storeMutation', false);
            commit('change_elevatorMotor_Brake_MechanicalRelease_ChangedByUser_storeMutation', true);
            dispatch('checkIf_changesAt_elevatorMotor_Brake_changes_configurationFlow_storeAction', firstIndexWhere_Selected_at_elevatorMotor_Brake_Selection_hasToBeChangedToTrue);
        }
    },
    change_Selected_at_elevatorMotor_Brake_Selection_basedOnChangesTo_elevatorMotor_Brake_ReleaseMonitoring_storeAction({ commit, dispatch }, value) {
        var firstIndexWhere_Selected_at_elevatorMotor_Brake_Selection_hasToBeChangedToTrue = undefined;
        const firstIndexWhere_Selected_at_elevatorMotor_Brake_Selection_isTrue = state.elevatorMotor_Brake_Data.elevatorMotor_Brake_Selection.findIndex(i => i.selected == true);

        firstIndexWhere_Selected_at_elevatorMotor_Brake_Selection_hasToBeChangedToTrue = state.elevatorMotor_Brake_Data.elevatorMotor_Brake_Selection.findIndex(i => ((i.elevatorMotor_Brake.nominalVoltage == state.elevatorMotor_Brake_Data.elevatorMotor_Brake_Selection[firstIndexWhere_Selected_at_elevatorMotor_Brake_Selection_isTrue].elevatorMotor_Brake.nominalVoltage) && (i.elevatorMotor_Brake.mechanicalRelease == state.elevatorMotor_Brake_Data.elevatorMotor_Brake_Selection[firstIndexWhere_Selected_at_elevatorMotor_Brake_Selection_isTrue].elevatorMotor_Brake.mechanicalRelease) && (i.elevatorMotor_Brake.releaseMonitoring == value)));

        if (firstIndexWhere_Selected_at_elevatorMotor_Brake_Selection_hasToBeChangedToTrue > -1) {
            commit('change_userPreference_affected_selected_elevatorMotor_Brake_ReleaseMonitoring_storeMutation', false);
            commit('change_userPreferences_affected_selected_elevatorMotor_Brake_storeMutation', false);
            commit('change_elevatorMotor_Brake_ReleaseMonitoring_ChangedByUser_storeMutation', true);
            dispatch('checkIf_changesAt_elevatorMotor_Brake_changes_configurationFlow_storeAction', firstIndexWhere_Selected_at_elevatorMotor_Brake_Selection_hasToBeChangedToTrue);
        }
    },
    change_Selected_at_elevatorMotor_Brake_Selection_basedOnChangesTo_elevatorMotor_Brake_Connection_storeAction({ commit, dispatch }, value) {
        const firstIndexWhere_Selected_at_elevatorMotor_Brake_Selection_isTrue = state.elevatorMotor_Brake_Data.elevatorMotor_Brake_Selection.findIndex(i => i.selected == true);

        commit('change_Selected_BlockNumber_at_elevatorMotor_Brake_Connection_Selection_storeMutation', value)

        if (firstIndexWhere_Selected_at_elevatorMotor_Brake_Selection_isTrue > -1) {
            const brakeConnection = {
                index: firstIndexWhere_Selected_at_elevatorMotor_Brake_Selection_isTrue,
                brakeConnectionBlockNumber: value.blockNumber,
                brakeConnectionTypeDesignation: value.text,
            }
            commit('change_Selected_at_elevatorMotor_Brake_Selection_BrakeConnection_storeMutation', brakeConnection);
            dispatch('checkIf_changesAt_elevatorMotor_Brake_changes_configurationFlow_storeAction', firstIndexWhere_Selected_at_elevatorMotor_Brake_Selection_isTrue);
        }
    },

    async insert_databaseRecord_at_elevatorMotor_Brakes_storeAction({ rootState }, value) {
        const data = {
            configurationOverviewsId: rootState.configurations.configurationOverviewsId,
            configurationSteps: rootState.configurations.configurationSteps,
            elevatorMotor_Brake: value,
        }

        await axios({
            method: 'post',
            url: '/api/driveTechnologies/insert_databaseRecord_at_elevatorMotor_Brakes',
            data: {
                data: data,
            }
        })
            .then((response) => {
                this.dispatch('statusHandling/change_dataAndStatus_storeAction', response.data);

                if (response.data.status.statusCode.startsWith('2')) {
                    this.dispatch('configurations/change_configurationSteps_storeAction', response.data.data.configurationSteps);
                }
            })
            .catch(() => { })
    },

    // elevatorMotor_Brake_Voltage
    change_Selected_at_elevatorMotor_Brake_Voltage_Selection_basedOnChangesTo_Selected_at_elevatorMotor_Brake_Selection_storeAction({ dispatch }) {
        const firstIndexWhere_Selected_at_elevatorMotor_Brake_Selection_isTrue = state.elevatorMotor_Brake_Data.elevatorMotor_Brake_Selection.findIndex(i => i.selected == true);

        if (firstIndexWhere_Selected_at_elevatorMotor_Brake_Selection_isTrue > -1) {
            const firstIndexWhere_Selected_at_elevatorMotor_Brake_Voltage_Selection_mustBeChangedToTrue = state.elevatorMotor_Brake_Data.elevatorMotor_Brake_Voltage_Selection.findIndex(i => i.nominalVoltage == state.elevatorMotor_Brake_Data.elevatorMotor_Brake_Selection[firstIndexWhere_Selected_at_elevatorMotor_Brake_Selection_isTrue].elevatorMotor_Brake.nominalVoltage);
            if (firstIndexWhere_Selected_at_elevatorMotor_Brake_Voltage_Selection_mustBeChangedToTrue > -1) {
                dispatch('change_Selected_atElementOf_elevatorMotor_Brake_Voltage_Selection_storeAction', firstIndexWhere_Selected_at_elevatorMotor_Brake_Voltage_Selection_mustBeChangedToTrue);
            }
            else {
                // error
            }
        }
        else {
            // error
        }
    },
    change_Selected_atElementOf_elevatorMotor_Brake_Voltage_Selection_storeAction({ commit }, value) {
        commit('change_Selected_at_elevatorMotor_Brake_Voltage_Selection_storeMutation', value);
    },

    // elevatorMotor_Brake_MechanicalRelease
    change_Selected_at_elevatorMotor_Brake_MechanicalRelease_Selection_basedOnChangesTo_Selected_at_elevatorMotor_Brake_Selection_storeAction({ dispatch }) {
        const firstIndexWhere_Selected_at_elevatorMotor_Brake_Selection_isTrue = state.elevatorMotor_Brake_Data.elevatorMotor_Brake_Selection.findIndex(i => i.selected == true);

        if (firstIndexWhere_Selected_at_elevatorMotor_Brake_Selection_isTrue > -1) {
            const firstIndexWhere_Selected_at_elevatorMotor_Brake_MechanicalRelease_Selection_mustBeChangedToTrue = state.elevatorMotor_Brake_Data.elevatorMotor_Brake_MechanicalRelease_Selection.findIndex(i => i.text == state.elevatorMotor_Brake_Data.elevatorMotor_Brake_Selection[firstIndexWhere_Selected_at_elevatorMotor_Brake_Selection_isTrue].elevatorMotor_Brake.mechanicalRelease);
            if (firstIndexWhere_Selected_at_elevatorMotor_Brake_MechanicalRelease_Selection_mustBeChangedToTrue > -1) {
                dispatch('change_Selected_atElementOf_elevatorMotor_Brake_MechanicalRelease_Selection_storeAction', firstIndexWhere_Selected_at_elevatorMotor_Brake_MechanicalRelease_Selection_mustBeChangedToTrue);
            }
            else {
                // error
            }
        }
        else {
            // error
        }
    },
    change_Selected_atElementOf_elevatorMotor_Brake_MechanicalRelease_Selection_storeAction({ commit, dispatch }, value) {
        commit('change_Selected_at_elevatorMotor_Brake_MechanicalRelease_Selection_storeMutation', value);

        if (state.elevatorMotor_Brake_Data.hasOwnProperty('elevatorMotor_Brake_DeliverBowdenwire_Selection') && state.elevatorMotor_Brake_Data.elevatorMotor_Brake_DeliverBowdenwire_Selection != null && state.elevatorMotor_Brake_Data.elevatorMotor_Brake_DeliverBowdenwire_Selection.length > 0) {
            const firstIndex_whichMeetConditions = state.elevatorMotor_Brake_Data.elevatorMotor_Brake_MechanicalRelease_Selection.findIndex(i => i.selected == true);
            if (firstIndex_whichMeetConditions > -1) {
                var firstIndex_WichtMeetConditions_Bowdenwire = state.elevatorMotor_Brake_Data.elevatorMotor_Brake_DeliverBowdenwire_Selection.findIndex(i => i.selected == true);
                if (firstIndex_WichtMeetConditions_Bowdenwire == -1 || state.elevatorMotor_Brake_Data.elevatorMotor_Brake_DeliverBowdenwire_Selection[firstIndex_WichtMeetConditions_Bowdenwire].mechanicalRelease != state.elevatorMotor_Brake_Data.elevatorMotor_Brake_MechanicalRelease_Selection[firstIndex_whichMeetConditions].text) {
                    firstIndex_WichtMeetConditions_Bowdenwire = state.elevatorMotor_Brake_Data.elevatorMotor_Brake_DeliverBowdenwire_Selection.findIndex(i => i.mechanicalRelease == state.elevatorMotor_Brake_Data.elevatorMotor_Brake_MechanicalRelease_Selection[firstIndex_whichMeetConditions].text);
                    dispatch('change_Selected_at_elevatorMotor_Brake_DeliverBowdenwire_Selection_storeAction', firstIndex_WichtMeetConditions_Bowdenwire);
                }
            }
        }


    },
    change_Disabled_at_elevatorMotor_Brake_MechanicalRelease_Selection_basedOnChangesTo_Selected_at_elevatorMotor_Brake_Selection_storeAction({ commit }) {
        var helpArray_elevatorMotor_Brake_MechanicalRelease_Selection = [];

        const firstIndexWhere_Selected_at_elevatorMotor_Brake_Selection_isTrue = state.elevatorMotor_Brake_Data.elevatorMotor_Brake_Selection.findIndex(i => i.selected == true);
        if (firstIndexWhere_Selected_at_elevatorMotor_Brake_Selection_isTrue > -1) {
            state.elevatorMotor_Brake_Data.elevatorMotor_Brake_Selection.forEach(i => {
                if (i.elevatorMotor_Brake.nominalVoltage == state.elevatorMotor_Brake_Data.elevatorMotor_Brake_Selection[firstIndexWhere_Selected_at_elevatorMotor_Brake_Selection_isTrue].elevatorMotor_Brake.nominalVoltage) {
                    var helpArray_containsCurrentItemOf_elevatorMotor_Brake_MechanicalRelease_Selection = false;
                    helpArray_elevatorMotor_Brake_MechanicalRelease_Selection.forEach(ii => {
                        if (i.elevatorMotor_Brake.mechanicalRelease == ii.text) {
                            helpArray_containsCurrentItemOf_elevatorMotor_Brake_MechanicalRelease_Selection = true;
                        }
                    })
                    if (!helpArray_containsCurrentItemOf_elevatorMotor_Brake_MechanicalRelease_Selection) {
                        helpArray_elevatorMotor_Brake_MechanicalRelease_Selection.push(i.elevatorMotor_Brake.mechanicalRelease)
                    }
                }
            })
        }
        else {
            // error
        }

        if (helpArray_elevatorMotor_Brake_MechanicalRelease_Selection.length > 0) {
            commit('change_Disabled_at_elevatorMotor_Brake_MechanicalRelease_Selection_storeMutation', helpArray_elevatorMotor_Brake_MechanicalRelease_Selection)
        }
    },


    // elevatorMotor_Brake_ReleaseMonitoring
    change_Selected_at_elevatorMotor_Brake_ReleaseMonitoring_Selection_basedOnChangesTo_Selected_at_elevatorMotor_Brake_Selection_storeAction({ dispatch }) {
        const firstIndexWhere_Selected_at_elevatorMotor_Brake_Selection_isTrue = state.elevatorMotor_Brake_Data.elevatorMotor_Brake_Selection.findIndex(i => i.selected == true);

        if (firstIndexWhere_Selected_at_elevatorMotor_Brake_Selection_isTrue > -1) {
            const firstIndexWhere_Selected_at_elevatorMotor_Brake_ReleaseMonitoring_Selection_mustBeChangedToTrue = state.elevatorMotor_Brake_Data.elevatorMotor_Brake_ReleaseMonitoring_Selection.findIndex(i => i.text == state.elevatorMotor_Brake_Data.elevatorMotor_Brake_Selection[firstIndexWhere_Selected_at_elevatorMotor_Brake_Selection_isTrue].elevatorMotor_Brake.releaseMonitoring);
            if (firstIndexWhere_Selected_at_elevatorMotor_Brake_ReleaseMonitoring_Selection_mustBeChangedToTrue > -1) {
                dispatch('change_Selected_atElementOf_elevatorMotor_Brake_ReleaseMonitoring_Selection_storeAction', firstIndexWhere_Selected_at_elevatorMotor_Brake_ReleaseMonitoring_Selection_mustBeChangedToTrue);
            }
            else {
                // error
            }
        }
        else {
            // error
        }
    },
    change_Selected_atElementOf_elevatorMotor_Brake_ReleaseMonitoring_Selection_storeAction({ commit }, value) {
        commit('change_Selected_at_elevatorMotor_Brake_ReleaseMonitoring_Selection_storeMutation', value);
    },
    change_Disabled_at_elevatorMotor_Brake_ReleaseMonitoring_Selection_basedOnChangesTo_Selected_at_elevatorMotor_Brake_Selection_storeAction({ commit }) {
        var helpArray_elevatorMotor_Brake_ReleaseMonitoring_Selection = [];

        const firstIndexWhere_Selected_at_elevatorMotor_Brake_Selection_isTrue = state.elevatorMotor_Brake_Data.elevatorMotor_Brake_Selection.findIndex(i => i.selected == true);
        if (firstIndexWhere_Selected_at_elevatorMotor_Brake_Selection_isTrue > -1) {
            state.elevatorMotor_Brake_Data.elevatorMotor_Brake_Selection.forEach(i => {
                var helpArray_containsCurrentItemOf_elevatorMotor_Brake_ReleaseMonitoring_Selection = false;
                if ((i.elevatorMotor_Brake.nominalVoltage == state.elevatorMotor_Brake_Data.elevatorMotor_Brake_Selection[firstIndexWhere_Selected_at_elevatorMotor_Brake_Selection_isTrue].elevatorMotor_Brake.nominalVoltage) && (i.elevatorMotor_Brake.withMechanicalRelease) && (i.elevatorMotor_Brake.mechanicalRelease == state.elevatorMotor_Brake_Data.elevatorMotor_Brake_Selection[firstIndexWhere_Selected_at_elevatorMotor_Brake_Selection_isTrue].elevatorMotor_Brake.mechanicalRelease)) {
                    helpArray_elevatorMotor_Brake_ReleaseMonitoring_Selection.forEach(ii => {
                        if (ii.text == i.elevatorMotor_Brake.releaseMonitoring) {
                            helpArray_containsCurrentItemOf_elevatorMotor_Brake_ReleaseMonitoring_Selection = true;
                        }
                    })
                    if (!helpArray_containsCurrentItemOf_elevatorMotor_Brake_ReleaseMonitoring_Selection) {
                        helpArray_elevatorMotor_Brake_ReleaseMonitoring_Selection.push(i.elevatorMotor_Brake.releaseMonitoring)
                    }
                }
                else if ((i.elevatorMotor_Brake.nominalVoltage == state.elevatorMotor_Brake_Data.elevatorMotor_Brake_Selection[firstIndexWhere_Selected_at_elevatorMotor_Brake_Selection_isTrue].elevatorMotor_Brake.nominalVoltage) && (!i.elevatorMotor_Brake.withMechanicalRelease) && (state.elevatorMotor_Brake_Data.elevatorMotor_Brake_Selection[firstIndexWhere_Selected_at_elevatorMotor_Brake_Selection_isTrue].elevatorMotor_Brake.mechanicalRelease == 'without')) {
                    helpArray_elevatorMotor_Brake_ReleaseMonitoring_Selection.forEach(ii => {
                        if (ii.text == i.elevatorMotor_Brake.releaseMonitoring) {
                            helpArray_containsCurrentItemOf_elevatorMotor_Brake_ReleaseMonitoring_Selection = true;
                        }
                    })
                    if (!helpArray_containsCurrentItemOf_elevatorMotor_Brake_ReleaseMonitoring_Selection) {
                        helpArray_elevatorMotor_Brake_ReleaseMonitoring_Selection.push(i.elevatorMotor_Brake.releaseMonitoring)
                    }
                }
            })
        }
        else {
            // error
        }

        if (helpArray_elevatorMotor_Brake_ReleaseMonitoring_Selection.length > 0) {
            commit('change_Disabled_at_elevatorMotor_Brake_ReleaseMonitoring_Selection_storeMutation', helpArray_elevatorMotor_Brake_ReleaseMonitoring_Selection)
        }
    },

    // elevatorMotor_Brake_Connection
    change_Selected_atElementOf_elevatorMotor_Brake_Connection_Selection_storeAction({ commit }, value) {
        commit('change_Selected_at_elevatorMotor_Brake_Connection_Selection_storeMutation', value);
    },

    // elevatorMotor_Brake_Bowdenwire
    change_Selected_at_elevatorMotor_Brake_Selection_basedOnChangesTo_elevatorMotor_Brake_DeliverBowdenwire_storeAction({ commit, dispatch }, value) {

        var firstIndex_whichMeetConditions = state.elevatorMotor_Brake_Data.elevatorMotor_Brake_DeliverBowdenwire_Selection.findIndex(i => i.selected == true);
        if (value != null && (firstIndex_whichMeetConditions == -1 || value.deliver.text != state.elevatorMotor_Brake_Data.elevatorMotor_Brake_DeliverBowdenwire_Selection[firstIndex_whichMeetConditions].deliver.text)) {
            var firstIndex_whichMeetConditions_Bowdewire = state.elevatorMotor_Brake_Data.elevatorMotor_Brake_DeliverBowdenwire_Selection.findIndex(i => i.deliver.text == value.deliver.text && i.mechanicalRelease == value.mechanicalRelease);
            dispatch('change_Selected_at_elevatorMotor_Brake_DeliverBowdenwire_Selection_storeAction', firstIndex_whichMeetConditions_Bowdewire)

            const firstIndexWhere_Selected_at_elevatorMotor_Brake_Selection_isTrue = state.elevatorMotor_Brake_Data.elevatorMotor_Brake_Selection.findIndex(i => i.selected == true);
            if (firstIndexWhere_Selected_at_elevatorMotor_Brake_Selection_isTrue > -1) {
                dispatch('checkIf_changesAt_elevatorMotor_Brake_changes_configurationFlow_storeAction', firstIndexWhere_Selected_at_elevatorMotor_Brake_Selection_isTrue);

            }
        }
    },
    change_Selected_at_elevatorMotor_Brake_DeliverBowdenwire_Selection_storeAction({ commit, dispatch }, value) {
        commit('change_Selected_at_elevatorMotor_Brake_DeliverBowdenwire_Selection_storeMutation', value);

        const firstIndex_whichMeetConditions = state.elevatorMotor_Brake_Data.elevatorMotor_Brake_DeliverBowdenwire_Selection.findIndex(i => i.selected == true);
        if (firstIndex_whichMeetConditions > -1) {
            const deliverBowdenwire_Selection = state.elevatorMotor_Brake_Data.elevatorMotor_Brake_DeliverBowdenwire_Selection[firstIndex_whichMeetConditions]
            var firstIndex_WichtMeetConditions_Bowdenwire = state.elevatorMotor_Brake_Data.elevatorMotor_Brake_Bowdenwire_Selection.findIndex(i => i.selected == true);
            if (firstIndex_WichtMeetConditions_Bowdenwire == -1 ||
                (state.elevatorMotor_Brake_Data.elevatorMotor_Brake_Bowdenwire_Selection[firstIndex_WichtMeetConditions_Bowdenwire].mechanicalRelease != deliverBowdenwire_Selection.mechanicalRelease ||
                    state.elevatorMotor_Brake_Data.elevatorMotor_Brake_Bowdenwire_Selection[firstIndex_WichtMeetConditions_Bowdenwire].deliver.text != deliverBowdenwire_Selection.deliver.text)) {
                firstIndex_WichtMeetConditions_Bowdenwire = state.elevatorMotor_Brake_Data.elevatorMotor_Brake_Bowdenwire_Selection.findIndex(i => i.mechanicalRelease == deliverBowdenwire_Selection.mechanicalRelease && i.deliver.text == deliverBowdenwire_Selection.deliver.text);
                dispatch('change_Selected_at_elevatorMotor_Brake_Bowdenwire_Selection_storeAction', firstIndex_WichtMeetConditions_Bowdenwire);
            }
        }
        else {
            dispatch('change_Selected_at_elevatorMotor_Brake_Bowdenwire_Selection_storeAction', -1);
        }
    },

    change_Selected_at_elevatorMotor_Brake_Bowdenwire_Selection_storeAction({ commit, dispatch }, value) {
        commit('change_Selected_at_elevatorMotor_Brake_Bowdenwire_Selection_storeMutation', value);

        const firstIndexWhere_Selected_at_elevatorMotor_Brake_Selection_isTrue = state.elevatorMotor_Brake_Data.elevatorMotor_Brake_Selection.findIndex(i => i.selected == true);
        if (firstIndexWhere_Selected_at_elevatorMotor_Brake_Selection_isTrue > -1 && value > -1) {
            const bowdenwire = {
                index: firstIndexWhere_Selected_at_elevatorMotor_Brake_Selection_isTrue,
                bowdenwireBlockNumber: state.elevatorMotor_Brake_Data.elevatorMotor_Brake_Bowdenwire_Selection[value].blockNumber,
                bowdenwireDeliver: state.elevatorMotor_Brake_Data.elevatorMotor_Brake_Bowdenwire_Selection[value].deliver.text,
                bowdenwireLength: state.elevatorMotor_Brake_Data.elevatorMotor_Brake_Bowdenwire_Selection[value].length,
            }
            commit('change_Selected_at_elevatorMotor_Brake_Selection_Bowdenwire_storeMutation', bowdenwire);
        }
    },

    change_Selected_at_elevatorMotor_Brake_Selection_basedOnChangesTo_elevatorMotor_Brake_Bowdenwire_storeAction({ commit, dispatch }, value) {
        var firstIndex_whichMeetConditions = state.elevatorMotor_Brake_Data.elevatorMotor_Brake_Bowdenwire_Selection.findIndex(i => i.selected == true);
        if (value != null && (firstIndex_whichMeetConditions == -1 ||
            value.deliver.text != state.elevatorMotor_Brake_Data.elevatorMotor_Brake_Bowdenwire_Selection[firstIndex_whichMeetConditions].deliver.text ||
            value.mechanicalRelease != state.elevatorMotor_Brake_Data.elevatorMotor_Brake_Bowdenwire_Selection[firstIndex_whichMeetConditions].mechanicalRelease ||
            value.length != state.elevatorMotor_Brake_Data.elevatorMotor_Brake_Bowdenwire_Selection[firstIndex_whichMeetConditions].length)) {
            var firstIndex_WichtMeetConditions_Bowdenwire = state.elevatorMotor_Brake_Data.elevatorMotor_Brake_Bowdenwire_Selection.findIndex(i => i.length == value.length && i.deliver.text == value.deliver.text && i.mechanicalRelease == value.mechanicalRelease);
            dispatch('change_Selected_at_elevatorMotor_Brake_Bowdenwire_Selection_storeAction', firstIndex_WichtMeetConditions_Bowdenwire);

            const firstIndexWhere_Selected_at_elevatorMotor_Brake_Selection_isTrue = state.elevatorMotor_Brake_Data.elevatorMotor_Brake_Selection.findIndex(i => i.selected == true);
            if (firstIndexWhere_Selected_at_elevatorMotor_Brake_Selection_isTrue > -1) {
                dispatch('checkIf_changesAt_elevatorMotor_Brake_changes_configurationFlow_storeAction', firstIndexWhere_Selected_at_elevatorMotor_Brake_Selection_isTrue);

            }
        }
    },

    change_Selected_atElementOf_elevatorMotor_Brake_Bowdenwire_Selection_storeAction({ commit, dispatch }, value) {
        if (value > -1) {
            var firstIndex_whichMeetConditions = state.elevatorMotor_Brake_Data.elevatorMotor_Brake_DeliverBowdenwire_Selection.findIndex(i => i.deliver.text == state.elevatorMotor_Brake_Data.elevatorMotor_Brake_Bowdenwire_Selection[value].deliver.text && i.mechanicalRelease == state.elevatorMotor_Brake_Data.elevatorMotor_Brake_Bowdenwire_Selection[value].mechanicalRelease);
            if (firstIndex_whichMeetConditions > -1) {
                commit('change_Selected_at_elevatorMotor_Brake_DeliverBowdenwire_Selection_storeMutation', firstIndex_whichMeetConditions);
            }
            dispatch('change_Selected_at_elevatorMotor_Brake_Bowdenwire_Selection_storeAction', value);
        }
    },

    // elevatorMotor_Encoder
    change_elevatorMotor_Encoder_toDefault_storeAction({ commit }) {
        commit('change_elevatorMotor_Encoder_Data_storeMutation', {});
        commit('change_elevatorMotor_Encoder_Selection_Shown_storeMutation', true)
    },

    async get_elevatorMotor_Encoder_Informations_storeAction({ dispatch, rootState }) {
        const data = {
            configurationOverviewsId: rootState.configurations.configurationOverviewsId,
            configurationSteps: rootState.configurations.configurationSteps,
        }

        await axios({
            method: 'post',
            url: '/api/driveTechnologies/get_elevatorMotor_Encoder_Informations',
            data: {
                data: data,
            }
        })
            .then((response) => {
                this.dispatch('statusHandling/change_dataAndStatus_storeAction', response.data);

                if ((response.data.status.statusCode.substring(0, 1) == '2') || (response.data.status.statusCode.substring(0, 2) == '74') || (response.data.status.statusCode.substring(0, 2) == '75')) {
                    dispatch('toDos_afterResponseOf_get_elevatorMotor_Encoder_Informations_storeAction', response.data.data)
                }
            })
            .catch(() => { })
    },
    toDos_afterResponseOf_get_elevatorMotor_Encoder_Informations_storeAction({ rootState, dispatch }, value) {
        dispatch('change_elevatorMotor_Encoder_Data_storeAction', value.elevatorMotor_Encoder_Data);

        var configurationSteps = Object.assign({}, rootState.configurations.configurationSteps);
        var configurationSteps_elevatorMotor_Encoder = Object.assign({}, rootState.configurations.configurationSteps.elevatorMotor_Encoder);
        configurationSteps_elevatorMotor_Encoder.loaded = true;
        configurationSteps.elevatorMotor_Encoder = configurationSteps_elevatorMotor_Encoder;
        this.dispatch('configurations/change_configurationSteps_storeAction', configurationSteps)
    },

    change_elevatorMotor_Encoder_Data_storeAction({ commit }, value) {
        commit('change_elevatorMotor_Encoder_Data_storeMutation', value);
    },
    change_elevatorMotor_Encoder_Selection_Shown_storeAction({ commit }, value) {
        commit('change_elevatorMotor_Encoder_Selection_Shown_storeMutation', value)
    },

    async checkIf_userPreference_elevatorMotor_Encoder_SetAndPossible_storeAction({ commit, dispatch, rootState }) {
        if (rootState.user.userPreferences_Loaded == false) {
            await this.dispatch('user/get_userPreference_Informations_storeAction')
        }

        if ((rootState.user.userPreferences.hasOwnProperty('elevatorMotor_Encoder') == true) && (rootState.user.userPreferences.elevatorMotor_Encoder != null)) {
            const firstIndex_whichMeetCondition = state.elevatorMotor_Encoder_Data.elevatorMotor_Encoder_Selection.findIndex(i => i.elevatorMotor_Encoder.typeDesignation == rootState.user.userPreferences.elevatorMotor_Encoder);
            if (firstIndex_whichMeetCondition > -1) {
                commit('change_userPreference_affected_selected_elevatorMotor_Encoder_storeMutation', true);
                commit('change_elevatorMotor_Encoder_Selection_Shown_storeMutation', false)
                dispatch('change_Selected_at_elevatorMotor_Encoder_Selection_storeAction', firstIndex_whichMeetCondition);
                await dispatch('insert_databaseRecord_at_elevatorMotor_Encoders_storeAction', state.elevatorMotor_Encoder_Data.elevatorMotor_Encoder_Selection[firstIndex_whichMeetCondition].elevatorMotor_Encoder)
            }
            else {
                dispatch('change_Selected_at_elevatorMotor_Encoder_Selection_storeAction', 0);
                await dispatch('insert_databaseRecord_at_elevatorMotor_Encoders_storeAction', state.elevatorMotor_Encoder_Data.elevatorMotor_Encoder_Selection[0].elevatorMotor_Encoder)
            }
        }
        else {
            dispatch('change_Selected_at_elevatorMotor_Encoder_Selection_storeAction', 0);
            await dispatch('insert_databaseRecord_at_elevatorMotor_Encoders_storeAction', state.elevatorMotor_Encoder_Data.elevatorMotor_Encoder_Selection[0].elevatorMotor_Encoder)
        }
    },
    change_userPreference_affected_selected_elevatorMotor_Encoder_storeAction({ commit }, value) {
        commit('change_userPreference_affected_selected_elevatorMotor_Encoder_storeMutation', value);
    },

    async checkIf_changesAt_elevatorMotor_Encoder_changes_configurationFlow_storeAction({ rootState, commit, dispatch }, value) {
        commit('change_userPreference_affected_selected_elevatorMotor_Encoder_storeMutation', false);
        dispatch('change_Selected_at_elevatorMotor_Encoder_Selection_storeAction', value)

        if (rootState.configurations.configurationSteps.elevatorMotor_Encoder.storedInDatabase == true) {
            await this.dispatch('configurations/selectAndInsert_or_updateAndOrDelete_databaseRecords_storeAction', { changesAt: this._vm.$configurationSteps.elevatorMotor_Encoder, elevatorMotor_Encoder: state.elevatorMotor_Encoder_Data.elevatorMotor_Encoder_Selection[value].elevatorMotor_Encoder });

            if (router.history.current.params.slug == 'controlTechnologies') {
                this.dispatch('configurations/check_toDos_at_controlTechnologies_storeAction')
            }
        }
        else {
            dispatch('insert_databaseRecord_at_elevatorMotor_Encoders_storeAction', state.elevatorMotor_Encoder_Data.elevatorMotor_Encoder_Selection[value].elevatorMotor_Encoder)
        }
    },
    change_Selected_at_elevatorMotor_Encoder_Selection_storeAction({ commit }, value) {
        commit('change_Selected_at_elevatorMotor_Encoder_Selection_storeMutation', value);
    },

    async insert_databaseRecord_at_elevatorMotor_Encoders_storeAction({ rootState }, value) {
        const data = {
            configurationOverviewsId: rootState.configurations.configurationOverviewsId,
            configurationSteps: rootState.configurations.configurationSteps,
            elevatorMotor_Encoder: value,
        }

        await axios({
            method: 'post',
            url: '/api/driveTechnologies/insert_databaseRecord_at_elevatorMotor_Encoders',
            data: {
                data: data,
            }
        })
            .then((response) => {
                this.dispatch('statusHandling/change_dataAndStatus_storeAction', response.data);

                if (response.data.status.statusCode.startsWith('2')) {
                    this.dispatch('configurations/change_configurationSteps_storeAction', response.data.data.configurationSteps);
                }
            })
            .catch(() => { })
    },
    // frame
    async get_frame_Informations_storeAction({ dispatch, rootState }, value) {
        const data = {
            configurationOverviewsId: rootState.configurations.configurationOverviewsId,
            configurationSteps: rootState.configurations.configurationSteps,
            elevatorMotor_TractionSheave: value.elevatorMotor_TractionSheave,
            elevatorMotor_Machine: value.elevatorMotor_Machine,

        }

        await axios({
            method: 'post',
            url: '/api/frames/get_frame_Informations',
            data: {
                data: data,
            }
        })
            .then((response) => {
                this.dispatch('statusHandling/change_dataAndStatus_storeAction', response.data);
                if ((response.data.status.statusCode.substring(0, 1) == '2') || (response.data.status.statusCode.substring(0, 2) == '74') || (response.data.status.statusCode.substring(0, 2) == '75')) {
                    dispatch('toDos_afterResponseOf_get_frame_Informations_storeAction', response.data.data)
                }
            })
            .catch(() => { })
    },

    toDos_afterResponseOf_get_frame_Informations_storeAction({ dispatch }, value) {
        dispatch('change_frame_Data_storeAction', value.frame_Data);

        value.configurationSteps.frame.loaded = true;
        this.dispatch('configurations/change_configurationSteps_storeAction', value.configurationSteps);
    },

    change_frame_Data_storeAction({ commit }, value) {
        commit('change_frame_Data_storeMutation', value)
    },

    async checkIf_changesAt_frame_changes_configurationFlow_storeAction({ rootState, commit, dispatch }, value) {
        const firstIndex_whichMeetCondition = state.frame_Data.frame_Selection.findIndex(i => i.frame.blockNumber == value.frame.blockNumber);
        if (firstIndex_whichMeetCondition > -1) {
            const firstIndexWhere_Selected_atElementOf_frame_Selection_isTrue = state.frame_Data.frame_Selection.findIndex(i => i.selected == true);
            //if (firstIndexWhere_Selected_atElementOf_frame_Selection_isTrue == firstIndex_whichMeetCondition) {
            //    commit('change_Selected_atEveryElementOf_frame_Selection_toFalse_storeMutation')
            //    commit('change_verticalDistanceOfTheAxlesFromTractionSheaveAndDeflectionPulley_storeMutation', { value: undefined, invalid: undefined });
            //    commit('change_horizontalDistanceOfTheRopeHoles_storeMutation', { value: undefined, invalid: undefined });
            //    commit('change_deflectionPulleyDiameter_storeMutation', { value: undefined, invalid: undefined });
            //}
            //else {
            dispatch('change_Selected_atElementOf_frame_Selection_storeAction', firstIndex_whichMeetCondition)
            dispatch('change_frame_horizontalDistance_Minimum_storeAction', state.frame_Data.frame_Selection[firstIndex_whichMeetCondition].frame.horizontalDistanceMinimum);
            dispatch('change_frame_horizontalDistance_Maximum_storeAction', state.frame_Data.frame_Selection[firstIndex_whichMeetCondition].frame.horizontalDistanceMaximum);

            dispatch('change_deflectionPulleyDiameter_storeAction', state.frame_Data.frame_Selection[firstIndex_whichMeetCondition].frame.pulleyDiameter);
            const localDecimal = { value: state.frame_Data.frame_Selection[firstIndex_whichMeetCondition].frame.verticalDistance, country: rootState.configurations.language, name: 'verticalDistanceOfTheAxlesFromTractionSheaveAndDeflectionPulley' }
            dispatch('set_decimalValue', localDecimal);
            if (firstIndexWhere_Selected_atElementOf_frame_Selection_isTrue != firstIndex_whichMeetCondition) {
                if (state.frame_Data.frame_Selection[firstIndex_whichMeetCondition].frame.horizontalDistanceMaximum != null && state.frame_Data.frame_Selection[firstIndex_whichMeetCondition].frame.horizontalDistanceMaximum == 0) {
                    const localDecimal2 = { value: 0, country: rootState.configurations.language, name: 'horizontalDistanceOfTheRopeHoles' }
                    dispatch('set_decimalValue', localDecimal2);
                }
                else {
                    dispatch('change_horizontalDistanceOfTheRopeHoles2_storeAction', undefined);
                }
            }

            //}

            //await this.dispatch('configurations/selectAndInsert_or_updateAndOrDelete_databaseRecords_storeAction', { changesAt: this._vm.$configurationSteps.frame, frame: value.frame });

            //this.dispatch('configurations/check_toDos_at_frames_storeAction')
        }
        else {
            // error
        }
    },


    change_Selected_atElementOf_frame_Selection_storeAction({ commit }, value) {
        commit('change_Selected_at_frame_Selection_storeMutation', value)
    },

    change_frame_horizontalDistance_Minimum_storeAction({ commit }, value) {
        commit('change_frame_horizontalDistance_Minimum_storeMutation', value);
    },
    change_frame_horizontalDistance_Maximum_storeAction({ commit }, value) {
        commit('change_frame_horizontalDistance_Maximum_storeMutation', value);
    },
    async check_frame_numberOfPulleys_storeAction({ commit,rootState }, value) 
    {
        const data = {
            configurationOverviewsId: rootState.configurations.configurationOverviewsId,
            frame: value,
        }

        await axios({
            method: 'post',
            url: '/api/driveTechnologies/check_frame_numberOfPulleys',
            data: {
                data: data,
            }
        })
            .then((response) => {
                this.dispatch('statusHandling/change_dataAndStatus_storeAction', response.data);
                commit('change_frameInformation_storeMutation',response.data);
            })
            .catch(() => { })

    },
    change_wrappingAngleFrameCalculation_Modal_Shown_storeAction({ commit }, value) {
        commit('change_frameInformation_storeMutation', null);
        commit('change_wrappingAngleFrameCalculation_Modal_Shown_storeMutation', value)
    },
    async checkIf_wrappingAngle_withFrame_hasToBeCalculated_before_tractionCheck_storeAction({ dispatch }, value) {
        await dispatch('checkIf_wrappingAngle_withFrame_storeAction', value)
        await dispatch('change_elevatorMotor_Machine_forWhich_wrappingAngleCalculation_and_tractionCheck_isMade_storeAction', value);
        await dispatch('checkIf_tractionCheck_Inputs_ValueListsAndSelectionsAndPermissibleValueRanges_Loaded_storeAction')
    },
    async checkIf_wrappingAngle_withFrame_storeAction({ rootState, commit, dispatch }, value) {
        if (value.frame_Available != null && value.frame_Available == true) {
            await dispatch('get_frame_Informations_storeAction', value);
            if (value.frame != null) {
                const firstIndex_whichMeetCondition = state.frame_Data.frame_Selection.findIndex(i => i.frame.blockNumber == value.frame.frame.blockNumber);
                if (firstIndex_whichMeetCondition > -1) {
                    dispatch('change_Selected_atElementOf_frame_Selection_storeAction', firstIndex_whichMeetCondition)
                }
                dispatch('change_wrappingAngleFrameCalculation_Modal_Shown_storeAction', true)
                if (value.frame.frame != null && value.frame.frame.blockNumber != null) {
                    dispatch('checkIf_changesAt_frame_changes_configurationFlow_storeAction', value.frame);
                }
            }
            else {
                dispatch('change_wrappingAngleFrameCalculation_Modal_Shown_storeAction', null)
            }
        }
        else {
            dispatch('change_wrappingAngleFrameCalculation_Modal_Shown_storeAction', null)
        }
    },
}

const mutations = {
    // elevatorMotor_Machine
    change_elevatorMotor_Machine_Data_storeMutation(state, payload) {
        state.elevatorMotor_Machine_Data = Object.assign({}, payload);
    },
    change_Selected_at_elevatorMotor_Machine_Selection_storeMutation(state, payload) {
        for (let i = 0; i < state.elevatorMotor_Machine_Data.elevatorMotor_Machine_Selection.length; i++) {
            state.elevatorMotor_Machine_Data.elevatorMotor_Machine_Selection[i].selected = false;
            if (i == payload) {
                state.elevatorMotor_Machine_Data.elevatorMotor_Machine_Selection[i].selected = true;
            }
        }
    },
    change_Selected_atEveryElementOf_elevatorMotor_Machine_Selection_toFalse_storeMutation(state) {
        state.elevatorMotor_Machine_Data.elevatorMotor_Machine_Selection.forEach(i => { i.selected = false })
    },
    change_Selected_atEveryElementOf_elevatorMotor_Machine_Selection_frameAvailable_toFalse_storeMutation(state, payload) {
        for (let i = 0; i < state.elevatorMotor_Machine_Data.elevatorMotor_Machine_Selection.length; i++) {
            state.elevatorMotor_Machine_Data.elevatorMotor_Machine_Selection[i].frame_Available = false;
        }
    },
    change_elevatorMotor_Machine_Selection_Shown_storeMutation(state, payload) {
        state.elevatorMotor_Machine_Selection_Shown = payload;
    },

    change_elevatorMotor_Machine_Selection_lastIndex_storeMutation(state, payload) {
        state.elevatorMotor_Machine_Selection_lastIndex = payload;
    },
    change_elevatorMotor_Machine_Selection_Filtered_storeMutation(state, payload) {
        state.elevatorMotor_Machine_Selection_Filtered = payload;
    },
    change_filtered_elevatorMotor_Machine_Selection_storeMutation(state, payload) {
        state.filtered_elevatorMotor_Machine_Selection = payload;
    },

    change_elevatorMotor_Machine_forWhich_wrappingAngleCalculation_and_tractionCheck_isMade_storeMutation(state, payload) {
        state.elevatorMotor_Machine_forWhich_wrappingAngleCalculation_and_tractionCheck_isMade = payload;
    },

    // elevatorMotor_Machine filter
    change_elevatorMotor_Machine_Filter_Shown_storeMutation(state, payload) {
        state.elevatorMotor_Machine_Filter_Shown = payload;
    },
    change_elevatorMotor_Machine_FilterCriterion_elevatorMotor_Machine_Series_Selection_storeMutation(state, payload) {
        state.elevatorMotor_Machine_FilterCriterion_elevatorMotor_Machine_Series_Selection = payload;
    },
    change_elevatorMotor_Machine_FilterCriterion_tractionSheaveDiameter_storeMutation(state, payload) {
        state.elevatorMotor_Machine_FilterCriterion_tractionSheaveDiameter_Selection = payload;
    },
    change_Selected_atElementOf_elevatorMotor_Machine_FilterCriterion_elevatorMotor_Machine_Series_Selection_storeMutation(state, payload) {
        state.elevatorMotor_Machine_FilterCriterion_elevatorMotor_Machine_Series_Selection.forEach(i => {
            if (i.text == payload) {
                if (i.selected == true) {
                    i.selected = false;
                }
                else {
                    i.selected = true;
                }
            }
        });
    },
    change_Selected_atEveryElementOf_elevatorMotor_Machine_FilterCriterion_elevatorMotor_Machine_Series_Selection_toFalse_storeMutation(state) {
        state.elevatorMotor_Machine_FilterCriterion_elevatorMotor_Machine_Series_Selection.forEach(i => {
            i.selected = false;
        });
    },

    change_elevatorMotor_Machine_FilterCriterion_lowestNominalRotationSpeedPerMachineTractionSheaveCombination_storeMutation(state, payload) {
        state.elevatorMotor_Machine_FilterCriterion_lowestNominalRotationSpeedPerMachineTractionSheaveCombination = payload;
    },
    change_elevatorMotor_Machine_FilterCriterion_elevatorMotor_Machine_MechanicalUtilization_Maximum_storeMutation(state, payload) {
        state.elevatorMotor_Machine_FilterCriterion_elevatorMotor_Machine_MechanicalUtilization_Maximum = payload;
    },
    change_elevatorMotor_Machine_FilterCriterion_elevatorMotor_Machine_ElectricalUtilization_Maximum_storeMutation(state, payload) {
        state.elevatorMotor_Machine_FilterCriterion_elevatorMotor_Machine_ElectricalUtilization_Maximum = payload;
    },
    change_elevatorMotor_Machine_FilterCriterion_elevatorMotor_TractionSheave_Diameter_Minimum_storeMutation(state, payload) {
        state.elevatorMotor_Machine_FilterCriterion_elevatorMotor_TractionSheave_Diameter_Minimum = payload;
    },
    change_elevatorMotor_Machine_FilterCriterion_elevatorMotor_TractionSheave_Diameter_Maximum_storeMutation(state, payload) {
        state.elevatorMotor_Machine_FilterCriterion_elevatorMotor_TractionSheave_Diameter_Maximum = payload;
    },
    change_elevatorMotor_Machine_FilterCriterion_elevatorMotor_TemperatureMonitoringByPT100_storeMutation(state, payload) {
        state.elevatorMotor_Machine_FilterCriterion_elevatorMotor_TemperatureMonitoringByPT100 = payload;
    },
    change_elevatorMotor_Machine_FilterCriterion_elevatorMotor_ForcedVentilation_storeMutation(state, payload) {
        state.elevatorMotor_Machine_FilterCriterion_elevatorMotor_ForcedVentilation = payload;
    },
    change_elevatorMotor_Machine_FilterCriterion_elevatorMotor_Brake_MechanicalRelease_storeMutation(state, payload) {
        state.elevatorMotor_Machine_FilterCriterion_elevatorMotor_Brake_MechanicalRelease = payload;
    },
    change_elevatorMotor_Machine_FilterCriterion_elevatorMotor_SafetyBrake_storeMutation(state, payload) {
        state.elevatorMotor_Machine_FilterCriterion_elevatorMotor_SafetyBrake = payload;
    },
    change_elevatorMotor_Machine_FilterCriterion_elevatorMotor_AutomationProject_storeMutation(state, payload) {
        state.elevatorMotor_Machine_FilterCriterion_elevatorMotor_AutomationProject = payload;
    },
    change_elevatorMotor_Machine_FilterCriterion_elevatorMotor_ZAframe_storeMutation(state, payload) {
        state.elevatorMotor_Machine_FilterCriterion_elevatorMotor_ZAframe = payload;
    },
    add_elementAt_arrayOf_active_elevatorMotor_Machine_FilterCriteria_storeMutation(state, payload) {
        state.arrayOf_active_elevatorMotor_Machine_FilterCriteria.push(payload);
    },
    change_elementAt_arrayOf_active_elevatorMotor_Machine_FilterCriteria_storeMutation(state, payload) {
        state.arrayOf_active_elevatorMotor_Machine_FilterCriteria[payload.index].data = payload.data;
    },
    remove_elementAt_arrayOf_active_elevatorMotor_Machine_FilterCriteria_storeMutation(state, payload) {
        state.arrayOf_active_elevatorMotor_Machine_FilterCriteria.splice(payload, 1);
    },
    remove_allElementsAt_arrayOf_active_elevatorMotor_Machine_FilterCriteria_storeMutation(state) {
        state.arrayOf_active_elevatorMotor_Machine_FilterCriteria = [];
    },


    // wrappingAngleCalculation
    change_wrappingAngleCalculation_Modal_Shown_storeMutation(state, payload) {
        state.wrappingAngleCalculation_Modal_Shown = payload;
    },
    change_loader_forLoadingAllContent_at_wrappingAngleCalculation_Modal_Shown_storeMutation(state, payload) {
        state.loader_forLoadingAllContent_at_wrappingAngleCalculation_Modal_Shown = payload;
    },
    change_loader_forLoadingResults_at_wrappingAngleCalculation_Modal_Shown_storeMutation(state, payload) {
        state.loader_forLoadingResults_at_wrappingAngleCalculation_Modal_Shown = payload;
    },
    abort_httpRequests_get_wrappingAngleCalculation_Informations_storeMutation(state) {
        state.cancellationInformationsAboutHttpRequests_get_wrappingAngleCalculation_Informations.abort();
    },
    change_cancellationInformationsAboutHttpRequests_get_wrappingAngleCalculation_Informations_toNewAbortController_storeMutation(state) {
        state.cancellationInformationsAboutHttpRequests_get_wrappingAngleCalculation_Informations = new AbortController();
    },
    abort_httpRequests_check_wrappingAngleCalculation_Data_byCalculationDll_storeMutation(state) {
        state.cancellationInformationsAboutHttpRequests_check_wrappingAngleCalculation_Data_byCalculationDll.abort();
    },
    change_cancellationInformationsAboutHttpRequests_check_wrappingAngleCalculation_Data_byCalculationDll_toNewAbortController_storeMutation(state) {
        state.cancellationInformationsAboutHttpRequests_check_wrappingAngleCalculation_Data_byCalculationDll = new AbortController();
    },

    change_verticalDistanceOfTheAxlesFromTractionSheaveAndDeflectionPulley_storeMutation(state, payload) {
        state.verticalDistanceOfTheAxlesFromTractionSheaveAndDeflectionPulley = payload;
    },
    change_horizontalDistanceOfTheRopeHoles_storeMutation(state, payload) {
        state.horizontalDistanceOfTheRopeHoles = payload;
    },
    change_deflectionPulleyDiameter_storeMutation(state, payload) {
        state.deflectionPulleyDiameter = payload;
    },
    change_wrappingAngleCalculation_Inputs_Invalid_storeMutation(state, payload) {
        state.wrappingAngleCalculation_Inputs_Invalid = payload;
    },
    change_wrappingAngle_Calculated_storeMutation(state, payload) {
        state.wrappingAngle_Calculated = payload;
    },
    change_calculated_wrappingAngle_storeMutation(state, payload) {
        state.calculated_wrappingAngle = payload;
    },
    change_entered_wrappingAngle_storeMutation(state, payload) {
        state.entered_wrappingAngle = payload;
    },

    change_wrappingAngle_Property_of_suspensionMeans_Object_atItemOf_elevatorMotor_Machine_Selection_EnteredNotCalculated_storeMutation(state, payload) {
        state.wrappingAngle_Property_of_suspensionMeans_Object_atItemOf_elevatorMotor_Machine_Selection_EnteredNotCalculated = payload;
    },

    change_tractionCheck_Modal_Shown_storeMutation(state, payload) {
        state.tractionCheck_Modal_Shown = payload;
    },
    change_loader_forLoadingAllContent_at_tractionCheck_Modal_Shown_storeMutation(state, payload) {
        state.loader_forLoadingAllContent_at_tractionCheck_Modal_Shown = payload;
    },
    change_loader_forLoadingResults_at_tractionCheck_Modal_Shown_storeMutation(state, payload) {
        state.loader_forLoadingResults_at_tractionCheck_Modal_Shown = payload;
    },
    abort_httpRequests_get_tractionCheck_Informations_storeMutation(state) {
        state.cancellationInformationsAboutHttpRequests_get_tractionCheck_Informations.abort();
    },
    change_cancellationInformationsAboutHttpRequests_get_tractionCheck_Informations_toNewAbortController_storeMutation(state) {
        state.cancellationInformationsAboutHttpRequests_get_tractionCheck_Informations = new AbortController();
    },

    change_tractionCheck_Inputs_ValueListsAndSelectionsAndPermissibleValueRanges_storeMutation(state, payload) {
        state.tractionCheck_Inputs_ValueListsAndSelectionsAndPermissibleValueRanges = Object.assign({}, payload);
    },
    change_tractionCheck_Inputs_ValueListsAndSelectionsAndPermissibleValueRanges_Loaded_storeMutation(state, payload) {
        state.tractionCheck_Inputs_ValueListsAndSelectionsAndPermissibleValueRanges_Loaded = payload;
    },
    change_tractionCheck_UndercutWidthDisabled_storeMutation(state, payload) {
        state.undercutWidth_Disabled = payload;
    },
    change_tractionCheck_UndercutWidthInput_storeMutation(state, payload) {
        state.undercutWidth_Input = payload;
    },
    change_minimumCarWeight_storeMutation(state, payload) {
        state.minimumCarWeight = payload;
    },
    change_maximumCarWeight_storeMutation(state, payload) {
        state.maximumCarWeight = payload;
    },
    change_ropePulleyDiameter_storeMutation(state, payload) {
        state.ropePulleyDiameter = payload;
    },
    change_numberOfRopePulleysWithUniformBending_storeMutation(state, payload) {
        state.numberOfRopePulleysWithUniformBending = payload;
    },
    change_numberOfRopePulleysWithReverseBending_storeMutation(state, payload) {
        state.numberOfRopePulleysWithReverseBending = payload;
    },
    change_sheaveHardened_storeMutation(state, payload) {
        state.sheaveHardened = payload;
    },
    change_sheaveProfile_storeMutation(state, payload) {
        state.sheaveProfile = payload;
    },
    change_sheaveProfileAndSheaveHardenedTextKey_storeMutation(state, payload) {
        state.sheaveProfileAndSheaveHardenedTextKey = payload;
    },
    change_undercutAngle_Disabled_storeMutation(state, payload) {
        state.undercutAngle_Disabled = payload;
    },
    change_undercutAngle_storeMutation(state, payload) {
        state.undercutAngle = payload;
    },
    change_vgrooveAngle_Disabled_storeMutation(state, payload) {
        state.vgrooveAngle_Disabled = payload;
    },
    change_vgrooveAngle_storeMutation(state, payload) {
        state.vgrooveAngle = payload;
    },
    change_minimumValues_Disabled_storeMutation(state, payload) {
        state.minimumValues_Disabled = payload;
    },
    change_calculatedWithMinimumValues_storeMutation(state, payload) {
        state.calculatedWithMinimumValues = payload;
    },
    change_shortenedBufferStroke_Disabled_storeMutation(state, payload) {
        state.shortenedBufferStroke_Disabled = payload;
    },
    change_calculatedWithShortenedBufferStroke_storeMutation(state, payload) {
        state.calculatedWithShortenedBufferStroke = payload;
    },
    change_tractionCheck_Inputs_Invalid_storeMutation(state, payload) {
        state.tractionCheck_Inputs_Invalid = payload;
    },

    change_tractionCheck_storeMutation(state, payload) {
        state.tractionCheck = Object.assign({}, payload);
    },

    change_tractionCheck_Data_at_elevatorMotor_TractionSheave_Object_at_ElementOf_elevatorMotor_Machine_Selection_storeMutation(state, payload) {
        state.elevatorMotor_Machine_Data.elevatorMotor_Machine_Selection[payload.index].elevatorMotor_TractionSheave.sheaveProfile = payload.elevatorMotor_TractionSheave.sheaveProfile;
        state.elevatorMotor_Machine_Data.elevatorMotor_Machine_Selection[payload.index].elevatorMotor_TractionSheave.sheaveHardened = payload.elevatorMotor_TractionSheave.sheaveHardened;
        state.elevatorMotor_Machine_Data.elevatorMotor_Machine_Selection[payload.index].elevatorMotor_TractionSheave.grooveDiameter = payload.elevatorMotor_TractionSheave.grooveDiameter;
        state.elevatorMotor_Machine_Data.elevatorMotor_Machine_Selection[payload.index].elevatorMotor_TractionSheave.undercutAngleDisabled = payload.elevatorMotor_TractionSheave.undercutAngleDisabled;
        state.elevatorMotor_Machine_Data.elevatorMotor_Machine_Selection[payload.index].elevatorMotor_TractionSheave.undercutAngle = payload.elevatorMotor_TractionSheave.undercutAngle;
        state.elevatorMotor_Machine_Data.elevatorMotor_Machine_Selection[payload.index].elevatorMotor_TractionSheave.vgrooveAngleDisabled = payload.elevatorMotor_TractionSheave.vgrooveAngleDisabled;
        state.elevatorMotor_Machine_Data.elevatorMotor_Machine_Selection[payload.index].elevatorMotor_TractionSheave.vgrooveAngle = payload.elevatorMotor_TractionSheave.vgrooveAngle;
        state.elevatorMotor_Machine_Data.elevatorMotor_Machine_Selection[payload.index].elevatorMotor_TractionSheave.undercutWidth = payload.elevatorMotor_TractionSheave.undercutWidth;
        state.elevatorMotor_Machine_Data.elevatorMotor_Machine_Selection[payload.index].elevatorMotor_TractionSheave.grooveAngle = payload.elevatorMotor_TractionSheave.grooveAngle;
    },
    change_tractionCheck_Data_at_elevatorMotor_TractionSheave_Object_at_ElementOf_elevatorMotor_Machine_Selection_toNull_storeMutation(state, payload) {
        state.elevatorMotor_Machine_Data.elevatorMotor_Machine_Selection[payload].elevatorMotor_TractionSheave.sheaveProfile = null;
        state.elevatorMotor_Machine_Data.elevatorMotor_Machine_Selection[payload].elevatorMotor_TractionSheave.sheaveHardened = null;
        state.elevatorMotor_Machine_Data.elevatorMotor_Machine_Selection[payload].elevatorMotor_TractionSheave.grooveDiameter = null;
        state.elevatorMotor_Machine_Data.elevatorMotor_Machine_Selection[payload].elevatorMotor_TractionSheave.undercutAngleDisabled = null;
        state.elevatorMotor_Machine_Data.elevatorMotor_Machine_Selection[payload].elevatorMotor_TractionSheave.undercutAngle = null;
        state.elevatorMotor_Machine_Data.elevatorMotor_Machine_Selection[payload].elevatorMotor_TractionSheave.vgrooveAngleDisabled = null;
        state.elevatorMotor_Machine_Data.elevatorMotor_Machine_Selection[payload].elevatorMotor_TractionSheave.vgrooveAngle = null;
        state.elevatorMotor_Machine_Data.elevatorMotor_Machine_Selection[payload].elevatorMotor_TractionSheave.undercutWidth = null;
        state.elevatorMotor_Machine_Data.elevatorMotor_Machine_Selection[payload].elevatorMotor_TractionSheave.grooveAngle = null;
    },
    change_axleLoad_at_ElementOf_elevatorMotor_Machine_Selection_storeMutation(state, payload) {
        state.elevatorMotor_Machine_Data.elevatorMotor_Machine_Selection[payload.index].elevatorMotor_Machine.axleLoad = payload.axleLoad;
    },
    change_permissibleDutyCycle_at_ElementOf_elevatorMotor_Machine_Selection_storeMutation(state, payload) {
        state.elevatorMotor_Machine_Data.elevatorMotor_Machine_Selection[payload.index].elevatorMotor_Machine.permissibleDutyCycle = payload.permissibleDutyCycle;
    },
    change_frame_Data_at_ElementOf_elevatorMotor_Machine_Selection_storeMutation(state, payload) {
        state.elevatorMotor_Machine_Data.elevatorMotor_Machine_Selection[payload.index].frame = payload.frame;
    },
    change_frame_Data_at_ElementOf_elevatorMotor_Machine_Selection_toNull_storeMutation(state, payload) {
        //state.elevatorMotor_Machine_Data.elevatorMotor_Machine_Selection[payload.index].frame.id = null;
        //state.elevatorMotor_Machine_Data.elevatorMotor_Machine_Selection[payload.index].frame.blockNumber = null;
        //state.elevatorMotor_Machine_Data.elevatorMotor_Machine_Selection[payload.index].frame.typeDesignation = null;
        //state.elevatorMotor_Machine_Data.elevatorMotor_Machine_Selection[payload.index].frame.type = null;
        //state.elevatorMotor_Machine_Data.elevatorMotor_Machine_Selection[payload.index].frame.axleLoad = null;
        //state.elevatorMotor_Machine_Data.elevatorMotor_Machine_Selection[payload.index].frame.horizontalDistance_Maximum = null;
        //state.elevatorMotor_Machine_Data.elevatorMotor_Machine_Selection[payload.index].frame.horizontalDistance_Minimum = null;
        //state.elevatorMotor_Machine_Data.elevatorMotor_Machine_Selection[payload.index].frame.verticalDistance = null;
        //state.elevatorMotor_Machine_Data.elevatorMotor_Machine_Selection[payload.index].frame.pulleyDiameter = null;
        //state.elevatorMotor_Machine_Data.elevatorMotor_Machine_Selection[payload.index].frame.positionPulley = null;
        //state.elevatorMotor_Machine_Data.elevatorMotor_Machine_Selection[payload.index].frame.dimensionSheet = null;
        if (payload.index != null && state.elevatorMotor_Machine_Data.elevatorMotor_Machine_Selection[payload.index] != null) {
            state.elevatorMotor_Machine_Data.elevatorMotor_Machine_Selection[payload.index].frame = null;
        }
    },
    add_elevatorSystem_Object_at_ElementOf_elevatorMotor_Machine_Selection_storeMutation(state, payload) {
        state.elevatorMotor_Machine_Data.elevatorMotor_Machine_Selection[payload.index] = Object.assign(state.elevatorMotor_Machine_Data.elevatorMotor_Machine_Selection[payload.index], { elevatorSystem: payload.elevatorSystem });
    },
    change_elevatorSystem_Object_at_ElementOf_elevatorMotor_Machine_Selection_storeMutation(state, payload) {
        state.elevatorMotor_Machine_Data.elevatorMotor_Machine_Selection[payload.index].elevatorSystem = payload.elevatorSystem;
    },
    delete_elevatorSystem_Object_at_ElementOf_elevatorMotor_Machine_Selection_storeMutation(state, payload) {
        delete state.elevatorMotor_Machine_Data.elevatorMotor_Machine_Selection[payload].elevatorSystem;
    },

    add_suspensionMeans_Object_at_ElementOf_elevatorMotor_Machine_Selection_storeMutation(state, payload) {
        state.elevatorMotor_Machine_Data.elevatorMotor_Machine_Selection[payload.index] = Object.assign(state.elevatorMotor_Machine_Data.elevatorMotor_Machine_Selection[payload.index], { suspensionMeans: payload.suspensionMeans });
    },
    change_suspensionMeans_Object_at_ElementOf_elevatorMotor_Machine_Selection_storeMutation(state, payload) {
        state.elevatorMotor_Machine_Data.elevatorMotor_Machine_Selection[payload.index].suspensionMeans = payload.suspensionMeans;
    },
    delete_suspensionMeans_Object_at_ElementOf_elevatorMotor_Machine_Selection_storeMutation(state, payload) {
        delete state.elevatorMotor_Machine_Data.elevatorMotor_Machine_Selection[payload].suspensionMeans;
    },

    add_tractionCheck_Object_at_ElementOf_elevatorMotor_Machine_Selection_storeMutation(state, payload) {
        state.elevatorMotor_Machine_Data.elevatorMotor_Machine_Selection[payload.index] = Object.assign(state.elevatorMotor_Machine_Data.elevatorMotor_Machine_Selection[payload.index], { tractionCheck: payload.tractionCheck });
        state.elevatorMotor_Machine_Data.elevatorMotor_Machine_Selection[payload.index].tractionCheck_Executed = true;
        state.elevatorMotor_Machine_Data.elevatorMotor_Machine_Selection[payload.index].tractionCheck_Passed = payload.tractionCheck.tractionCheckPassedInTotal;
    },
    change_tractionCheck_Object_at_ElementOf_elevatorMotor_Machine_Selection_storeMutation(state, payload) {
        state.elevatorMotor_Machine_Data.elevatorMotor_Machine_Selection[payload.index].tractionCheck = payload.tractionCheck;
        state.elevatorMotor_Machine_Data.elevatorMotor_Machine_Selection[payload.index].tractionCheck_Executed = true;
        state.elevatorMotor_Machine_Data.elevatorMotor_Machine_Selection[payload.index].tractionCheck_Passed = payload.tractionCheck.tractionCheckPassedInTotal;
    },
    delete_tractionCheck_Object_at_ElementOf_elevatorMotor_Machine_Selection_storeMutation(state, payload) {
        delete state.elevatorMotor_Machine_Data.elevatorMotor_Machine_Selection[payload].tractionCheck;
        state.elevatorMotor_Machine_Data.elevatorMotor_Machine_Selection[payload].tractionCheck_Executed = false;
        state.elevatorMotor_Machine_Data.elevatorMotor_Machine_Selection[payload].tractionCheck_Passed = false;
    },
    delete_frame_Data_at_ElementOf_elevatorMotor_Machine_Selection_storeMutation(state, payload) {
        delete state.elevatorMotor_Machine_Data.elevatorMotor_Machine_Selection[payload].frame;
    },

    add_frame_Object_at_ElementOf_elevatorMotor_Machine_Selection_storeMutation(state, payload) {
        state.elevatorMotor_Machine_Data.elevatorMotor_Machine_Selection[payload.index] = Object.assign(state.elevatorMotor_Machine_Data.elevatorMotor_Machine_Selection[payload.index], { frameSelection: payload.frame_Selection });
        state.elevatorMotor_Machine_Data.elevatorMotor_Machine_Selection[payload.index].frameSelction_Executed = true;
    },


    // elevatorMotor_Machine_TractionSheaveSide
    change_elevatorMotor_Machine_TractionSheaveSide_Data_storeMutation(state, payload) {
        state.elevatorMotor_Machine_TractionSheaveSide_Data = Object.assign({}, payload);
    },
    change_Selected_at_elevatorMotor_Machine_TractionSheaveSide_Selection_storeMutation(state, payload) {
        for (let i = 0; i < state.elevatorMotor_Machine_TractionSheaveSide_Data.elevatorMotor_Machine_TractionSheaveSide_Selection.length; i++) {
            state.elevatorMotor_Machine_TractionSheaveSide_Data.elevatorMotor_Machine_TractionSheaveSide_Selection[i].selected = false;
            if (i == payload) {
                state.elevatorMotor_Machine_TractionSheaveSide_Data.elevatorMotor_Machine_TractionSheaveSide_Selection[i].selected = true;
            }
        }
    },

    // elevatorMotor_Machine_InstallationPosition
    change_elevatorMotor_Machine_InstallationPosition_Data_storeMutation(state, payload) {
        state.elevatorMotor_Machine_InstallationPosition_Data = Object.assign({}, payload);
    },
    change_Selected_at_elevatorMotor_Machine_InstallationPosition_Selection_storeMutation(state, payload) {
        for (let i = 0; i < state.elevatorMotor_Machine_InstallationPosition_Data.elevatorMotor_Machine_InstallationPosition_Selection.length; i++) {
            state.elevatorMotor_Machine_InstallationPosition_Data.elevatorMotor_Machine_InstallationPosition_Selection[i].selected = false;
            if (i == payload) {
                state.elevatorMotor_Machine_InstallationPosition_Data.elevatorMotor_Machine_InstallationPosition_Selection[i].selected = true;
            }
        }
    },


    // elevatorMotor_TemperatureMonitoring
    change_elevatorMotor_TemperatureMonitoring_Data_storeMutation(state, payload) {
        state.elevatorMotor_TemperatureMonitoring_Data = Object.assign({}, payload);
    },
    change_Selected_at_elevatorMotor_TemperatureMonitoring_Selection_storeMutation(state, payload) {
        for (let i = 0; i < state.elevatorMotor_TemperatureMonitoring_Data.elevatorMotor_TemperatureMonitoring_Selection.length; i++) {
            state.elevatorMotor_TemperatureMonitoring_Data.elevatorMotor_TemperatureMonitoring_Selection[i].selected = false;
            if (i == payload) {
                state.elevatorMotor_TemperatureMonitoring_Data.elevatorMotor_TemperatureMonitoring_Selection[i].selected = true;
            }
        }
    },
    change_elevatorMotor_TemperatureMonitoring_Selection_Shown_storeMutation(state, payload) {
        state.elevatorMotor_TemperatureMonitoring_Selection_Shown = payload;
    },


    // elevatorMotor_ForcedVentilation
    change_elevatorMotor_PermissibleDutyCyleWithoutForcedVentilation_storeMutation(state, payload) {
        state.permissibleDutyCycleWithoutForcedVentilation = payload;
    },
    change_elevatorMotor_PermissibleDutyCyleWithForcedVentilation_storeMutation(state, payload) {
        state.permissibleDutyCycleWithForcedVentilation = payload;
    },
    change_elevatorMotor_ForcedVentilation_storeMutation(state, payload) {
        state.elevatorMotor_ForcedVentilation = Object.assign({}, payload);
    },
    change_Selected_at_elevatorMotor_ForcedVentilation_storeMutation(state, payload) {
        state.elevatorMotor_ForcedVentilation.selected = payload;
    },
    change_elevatorMotor_ForcedVentilation_Options_Shown_storeMutation(state, payload) {
        state.elevatorMotor_ForcedVentilation_Options_Shown = payload;
    },


    // elevatorMotor_Brake
    change_elevatorMotor_Brake_Data_storeMutation(state, payload) {
        state.elevatorMotor_Brake_Data = Object.assign({}, payload);
    },
    change_Selected_at_elevatorMotor_Brake_Selection_storeMutation(state, payload) {
        for (let i = 0; i < state.elevatorMotor_Brake_Data.elevatorMotor_Brake_Selection.length; i++) {
            state.elevatorMotor_Brake_Data.elevatorMotor_Brake_Selection[i].selected = false;
            if (i == payload) {
                state.elevatorMotor_Brake_Data.elevatorMotor_Brake_Selection[i].selected = true;
            }
        }
    },
    change_Selected_at_elevatorMotor_Brake_Selection_BrakeConnection_storeMutation(state, payload) {
        state.elevatorMotor_Brake_Data.elevatorMotor_Brake_Selection[payload.index].elevatorMotor_Brake.brakeConnectionTypeDesignation = payload.brakeConnectionTypeDesignation;
        state.elevatorMotor_Brake_Data.elevatorMotor_Brake_Selection[payload.index].elevatorMotor_Brake.brakeConnectionBlockNumber = payload.brakeConnectionBlockNumber;
    },
    change_Selected_at_elevatorMotor_Brake_Selection_Bowdenwire_storeMutation(state, payload) {
        state.elevatorMotor_Brake_Data.elevatorMotor_Brake_Selection[payload.index].elevatorMotor_Brake.bowdenwireDeliver = payload.bowdenwireDeliver;
        state.elevatorMotor_Brake_Data.elevatorMotor_Brake_Selection[payload.index].elevatorMotor_Brake.bowdenwireBlockNumber = payload.bowdenwireBlockNumber;
        state.elevatorMotor_Brake_Data.elevatorMotor_Brake_Selection[payload.index].elevatorMotor_Brake.bowdenwireLength = payload.bowdenwireLength;
    },
    change_elevatorMotor_Brake_Selections_Shown_storeMutation(state, payload) {
        state.elevatorMotor_Brake_Selections_Shown = payload;
    },

    change_loader_forLoadingData_at_elevatorMotor_Brake_Shown_storeMutation(state, payload) {
        state.loader_forLoadingData_at_elevatorMotor_Brake_Shown = payload;
    },
    abort_httpRequests_get_elevatorMotor_Brake_Informations_storeMutation(state) {
        state.cancellationInformationsAboutHttpRequests_get_elevatorMotor_Brake_Informations.abort();
    },
    change_cancellationInformationsAboutHttpRequests_get_elevatorMotor_Brake_Informations_toNewAbortController_storeMutation(state) {
        state.cancellationInformationsAboutHttpRequests_get_elevatorMotor_Brake_Informations = new AbortController();
    },

    // elevatorMotor_Brake_Voltage
    change_Selected_at_elevatorMotor_Brake_Voltage_Selection_storeMutation(state, payload) {
        for (let i = 0; i < state.elevatorMotor_Brake_Data.elevatorMotor_Brake_Voltage_Selection.length; i++) {
            state.elevatorMotor_Brake_Data.elevatorMotor_Brake_Voltage_Selection[i].selected = false;
            if (i == payload) {
                state.elevatorMotor_Brake_Data.elevatorMotor_Brake_Voltage_Selection[i].selected = true;
            }
        }
    },

    // elevatorMotor_Brake_MechanicalRelease
    change_Selected_at_elevatorMotor_Brake_MechanicalRelease_Selection_storeMutation(state, payload) {
        for (let i = 0; i < state.elevatorMotor_Brake_Data.elevatorMotor_Brake_MechanicalRelease_Selection.length; i++) {
            state.elevatorMotor_Brake_Data.elevatorMotor_Brake_MechanicalRelease_Selection[i].selected = false;
            if (i == payload) {
                state.elevatorMotor_Brake_Data.elevatorMotor_Brake_MechanicalRelease_Selection[i].selected = true;
            }
        }
    },
    change_Disabled_at_elevatorMotor_Brake_MechanicalRelease_Selection_storeMutation(state, payload) {
        state.elevatorMotor_Brake_Data.elevatorMotor_Brake_MechanicalRelease_Selection.forEach(i => {
            const item_whichMustNotBeDisabled = payload.some(ii => ii == i.text);
            i.disabled = true;
            if (item_whichMustNotBeDisabled) {
                i.disabled = false;
            }
        })
    },
    change_elevatorMotor_Brake_MechanicalRelease_ChangedByUser_storeMutation(state, payload) {
        state.elevatorMotor_Brake_MechanicalRelease_ChangedByUser = payload;
    },

    // elevatorMotor_Brake_ReleaseMonitoring
    change_Selected_at_elevatorMotor_Brake_ReleaseMonitoring_Selection_storeMutation(state, payload) {
        for (let i = 0; i < state.elevatorMotor_Brake_Data.elevatorMotor_Brake_ReleaseMonitoring_Selection.length; i++) {
            state.elevatorMotor_Brake_Data.elevatorMotor_Brake_ReleaseMonitoring_Selection[i].selected = false;
            if (i == payload) {
                state.elevatorMotor_Brake_Data.elevatorMotor_Brake_ReleaseMonitoring_Selection[i].selected = true;
            }
        }
    },
    change_Disabled_at_elevatorMotor_Brake_ReleaseMonitoring_Selection_storeMutation(state, payload) {
        state.elevatorMotor_Brake_Data.elevatorMotor_Brake_ReleaseMonitoring_Selection.forEach(i => {
            const item_whichMustNotBeDisabled = payload.some(ii => ii == i.text);
            i.disabled = true;
            if (item_whichMustNotBeDisabled) {
                i.disabled = false;
            }
        })
    },
    change_elevatorMotor_Brake_ReleaseMonitoring_ChangedByUser_storeMutation(state, payload) {
        state.elevatorMotor_Brake_ReleaseMonitoring_ChangedByUser = payload;
    },

    // elevatorMotor_Brake_Connection
    change_Selected_at_elevatorMotor_Brake_Connection_Selection_storeMutation(state, payload) {
        for (let i = 0; i < state.elevatorMotor_Brake_Data.elevatorMotor_Brake_Connection_Selection.length; i++) {
            state.elevatorMotor_Brake_Data.elevatorMotor_Brake_Connection_Selection[i].selected = false;
            if (i == payload) {
                state.elevatorMotor_Brake_Data.elevatorMotor_Brake_Connection_Selection[i].selected = true;
            }
        }
    },
    change_Selected_BlockNumber_at_elevatorMotor_Brake_Connection_Selection_storeMutation(state, payload) {
        for (let i = 0; i < state.elevatorMotor_Brake_Data.elevatorMotor_Brake_Connection_Selection.length; i++) {
            state.elevatorMotor_Brake_Data.elevatorMotor_Brake_Connection_Selection[i].selected = false;
            if (state.elevatorMotor_Brake_Data.elevatorMotor_Brake_Connection_Selection[i].blockNumber == payload.blockNumber) {
                state.elevatorMotor_Brake_Data.elevatorMotor_Brake_Connection_Selection[i].selected = true;
            }
        }
    },

    // elevatorMotor_Brake_Bowdenwire
    change_Selected_at_elevatorMotor_Brake_DeliverBowdenwire_Selection_storeMutation(state, payload) {
        for (let i = 0; i < state.elevatorMotor_Brake_Data.elevatorMotor_Brake_DeliverBowdenwire_Selection.length; i++) {
            state.elevatorMotor_Brake_Data.elevatorMotor_Brake_DeliverBowdenwire_Selection[i].selected = false;
            if (i == payload) {
                state.elevatorMotor_Brake_Data.elevatorMotor_Brake_DeliverBowdenwire_Selection[i].selected = true;
            }
        }
    },
    change_Selected_at_elevatorMotor_Brake_Bowdenwire_Selection_storeMutation(state, payload) {
        for (let i = 0; i < state.elevatorMotor_Brake_Data.elevatorMotor_Brake_Bowdenwire_Selection.length; i++) {
            state.elevatorMotor_Brake_Data.elevatorMotor_Brake_Bowdenwire_Selection[i].selected = false;
            if (i == payload) {
                state.elevatorMotor_Brake_Data.elevatorMotor_Brake_Bowdenwire_Selection[i].selected = true;
            }
        }
    },


    // elevatorMotor_Encoder
    change_elevatorMotor_Encoder_Data_storeMutation(state, payload) {
        state.elevatorMotor_Encoder_Data = Object.assign({}, payload);
    },
    change_elevatorMotor_Encoder_Selection_Shown_storeMutation(state, payload) {
        state.elevatorMotor_Encoder_Selection_Shown = payload;
    },
    change_Selected_at_elevatorMotor_Encoder_Selection_storeMutation(state, payload) {
        for (let i = 0; i < state.elevatorMotor_Encoder_Data.elevatorMotor_Encoder_Selection.length; i++) {
            state.elevatorMotor_Encoder_Data.elevatorMotor_Encoder_Selection[i].selected = false;
            if (i == payload) {
                state.elevatorMotor_Encoder_Data.elevatorMotor_Encoder_Selection[i].selected = true;
            }
        }
    },

    // userPreference
    //change_userPreferences_affected_elevatorMotor_Machine_FilterCriteria_storeMutation(state, payload) {
    //    state.userPreferences_affected_elevatorMotor_Machine_FilterCriteria = payload;
    //},
    change_userPreference_affected_selected_elevatorMotor_TemperatureMonitoring_storeMutation(state, payload) {
        state.userPreference_affected_selected_elevatorMotor_TemperatureMonitoring = payload;
    },
    change_userPreference_affected_elevatorMotor_ForcedVentilation_Selected_storeMutation(state, payload) {
        state.userPreference_affected_elevatorMotor_ForcedVentilation_Selected = payload;
    },
    change_userPreference_affected_selected_elevatorMotor_Brake_NominalVoltage_storeMutation(state, payload) {
        state.userPreference_affected_selected_elevatorMotor_Brake_NominalVoltage = payload;
    },
    change_userPreference_affected_selected_elevatorMotor_Brake_MechanicalRelease_storeMutation(state, payload) {
        state.userPreference_affected_selected_elevatorMotor_Brake_MechanicalRelease = payload;
    },
    change_userPreference_affected_selected_elevatorMotor_Brake_ReleaseMonitoring_storeMutation(state, payload) {
        state.userPreference_affected_selected_elevatorMotor_Brake_ReleaseMonitoring = payload;
    },
    change_userPreferences_affected_selected_elevatorMotor_Brake_storeMutation(state, payload) {
        state.userPreferences_affected_selected_elevatorMotor_Brake = payload;
    },
    change_userPreference_affected_selected_elevatorMotor_Encoder_storeMutation(state, payload) {
        state.userPreference_affected_selected_elevatorMotor_Encoder = payload;
    },

    // frame
    change_frame_Data_storeMutation(state, payload) {
        state.frame_Data = Object.assign({}, payload);
    },
    change_frameInformation_storeMutation(state, payload) {
        state.frameInformation = payload;
    },
    change_Selected_at_frame_Selection_storeMutation(state, payload) {
        for (let i = 0; i < state.frame_Data.frame_Selection.length; i++) {
            state.frame_Data.frame_Selection[i].selected = false;
            if (i == payload) {
                state.frame_Data.frame_Selection[i].selected = true;
            }
        }
    },
    change_Selected_atEveryElementOf_frame_Selection_toFalse_storeMutation(state) {
        if (Array.isArray(state.frame_Data.frame_Selection)) {
            state.frame_Data.frame_Selection.forEach(i => {
                i.selected = false;
            })
        }
    },

    change_frame_horizontalDistance_Minimum_storeMutation(state, payload) {
        state.horizontalDistance_Minimum = payload;
    },
    change_frame_horizontalDistance_Maximum_storeMutation(state, payload) {
        state.horizontalDistance_Maximum = payload;
    },
    change_wrappingAngleFrameCalculation_Modal_Shown_storeMutation(state, payload) {
        state.wrappingAngleFrameCalculation_Modal_Shown = payload;
    },
}

export const driveTechnologies = {
    namespaced: true,
    components: {
        axios
    },
    state,
    actions,
    mutations
}