import axios from 'axios';
import router from "@/router";

let state = {
    summary: {},
    ZLM: null,
}

const actions = {
    change_ZLM_storeAction({ commit }, value) {
        commit('change_ZLM_storeMutation', value);

        if (state.ZLM != null) {
            sessionStorage.setItem('zlm', state.ZLM);
        }
        else {
            if (sessionStorage.getItem('zlm')) {
                sessionStorage.removeItem('zlm')
            }
            this.dispatch('inputsManual/change_configurationStepsManualId_storeAction', null);
        }

    },
    check_ZLM_storeAction({ commit }) {
        if (sessionStorage.getItem('zlm')) {
            commit('change_ZLM_storeMutation', sessionStorage.getItem('zlm'));
        }
    },
    async check_ZLM_Exist_storeAction({ dispatch }, value) {
        this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: true, reasonWhy_loaderModal_Shown: 'data_BeingLoaded' });
        const zlm = value;
        await axios({
            method: 'post',
            url: '/api/inputsManual/check_ZLM_Exist/' + zlm,
        })
            .then((response) => {
                var integerZLMNumber = parseInt(response.data);
                if (!isNaN(integerZLMNumber) ) {
                    dispatch('change_ZLM_storeAction', integerZLMNumber);
                    this.dispatch('inputsManual/change_manualMotorToDefault_storeAction');
                }
                else {
                    dispatch('change_ZLM_storeAction', null);
                    this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
                }
            })
            .catch(() => {
            })
            .then(() => {
                //this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
            })
    },
}


const mutations = {


    change_ZLM_storeMutation(state, payload) {
        state.ZLM = payload;
    },


}
export const manualHome = {
    namespaced: true,
    components: {
        axios,
    },
    state,
    actions,
    mutations
}