import Vue from 'vue'
import App from './App'
import router from './router'
import axios from 'axios'
import { store } from './store';
import CoreuiVue from '@coreui/vue'
import { BootstrapVue, } from 'bootstrap-vue'
import Vuex from 'vuex'
// internationalization
import i18next from 'i18next';
import VueI18Next from '@panter/vue-i18next';
import Backend from 'i18next-http-backend';
import dataValidation from './plugins/dataValidation';

Vue.use(CoreuiVue)
Vue.use(BootstrapVue);
Vue.use(Vuex)
Vue.use(VueI18Next);
Vue.use(dataValidation)

i18next
    .use(Backend)
    .init({
        lng: 'de', // language
        fallbackLng: 'de',
        debug: false,
        backend: {
            loadPath: (process.env.BASE_URL == '/' ? '' : process.env.BASE_URL) + '/locales/{{lng}}.json',
        }
    });
const i18n = new VueI18Next(i18next);


Vue.directive('click-outsideOfModal', {
    bind(el, binding, vnode) {
        var event = function (event) {
            //if (event.target.className !== 'myButton' && ((el == event.target) && el.contains(event.target))) {
            if ((event.target.className == 'my_modal_dialog') || (event.target.className == 'my_modal show')) {
                vnode.context[binding.expression](event);
            }
        };
        document.body.addEventListener('click', event)
    },
    unbind() {
        document.body.removeEventListener('click', event)
    },
});

Vue.prototype.$configurationSteps = {
    inputs: 'inputs',
    wrappingAngleCalculation: 'wrappingAngleCalculation',
    tractionCheck: 'tractionCheck',
    elevatorMotor_Machine: 'elevatorMotor_Machine',
    elevatorMotor_Machine_TractionSheaveSide: 'elevatorMotor_Machine_TractionSheaveSide',
    elevatorMotor_Machine_InstallationPosition: 'elevatorMotor_Machine_InstallationPosition',
    elevatorMotor_TemperatureMonitoring: 'elevatorMotor_TemperatureMonitoring',
    elevatorMotor_ForcedVentilation: 'elevatorMotor_ForcedVentilation',
    elevatorMotor_Brake: 'elevatorMotor_Brake',
    elevatorMotor_Encoder: 'elevatorMotor_Encoder',
    frameDeliverOptions: 'frameDeliverOptions',
    frame: 'frames',
    pulley: 'pulleys',
    inverter: 'inverter',
    unintendedCarMovementCheck: 'unintendedCarMovementCheck',
    brakeResistor: 'brakeResistor',
    powerRecuperationUnit: 'powerRecuperationUnit',
    conversionOfRegenerativeEnergy: 'conversionOfRegenerativeEnergy',
    brakeControl: 'brakeControl',
    energyEfficiencyRating: 'energyEfficiencyRating',
    cables: 'cables',
    summary: 'summary',
}

Vue.prototype.$closingOptions = {
    close: 'close',
    continue: 'continue',
    retry: 'retry',
    newConfiguration: 'newConfiguration',
    logout: 'logout',
    to_startPage: 'to_startPage',
    to_inputs: 'to_inputs',
    to_elevatorMotor_Machine: 'to_elevatorMotor_Machine',
    to_tractionChecks: 'to_tractionChecks',
    to_elevatorMotor_TemperatureMonitoring: 'to_elevatorMotor_TemperatureMonitoring',
    to_elevatorMotor_ForcedVentilation: 'to_elevatorMotor_ForcedVentilation',
    to_elevatorMotor_Brake: 'to_elevatorMotor_Brake',
    to_elevatorMotor_Encoder: 'to_elevatorMotor_Encoder',
    to_inverter: 'to_inverter',
    to_conversionOfRegenerativeEnergy: 'to_conversionOfRegenerativeEnergy',
    to_brakeControl: 'to_brakeControl',
    to_summary: 'to_summary',
    to_input_summaryOK: 'to_input_summaryOK',
    back_to_input: 'back_to_input',
}

Vue.prototype.$imageFilePath ='/img/ProductImages'

Vue.prototype.$status_for_statusCode_7600000 = {
    status: {
        statusCode: '7600000',
        statusInContextOf: '',
        statusInformations: [{ textKey: 3248, textValues: null }],
        statusModalClosingOptions: [{ displayedDesignation: { textKey: 3242 }, closingOption: Vue.prototype.$closingOptions.logout }],
        valueDivergences: null,
    },
}

Vue.prototype.$status_for_statusCode_7200000 = {
    status: {
        statusCode: '7200000',
        statusInContextOf: '',
        statusInformations: [{ textKey: 3310, textValues: null }],
        statusModalClosingOptions: [{ displayedDesignation: { textKey: 383 }, closingOption: Vue.prototype.$closingOptions.close }],
        valueDivergences: null,
    },
}





var app = new Vue({
    el: '#app',

    // internationalization
    i18n,

    router,
    store,
    template: '<App/>',
    components: {
        App
    },

})


axios.interceptors.request.use(async function (config) {
    config.baseURL = process.env.BASE_URL;

    const user = await store.dispatch("account/getUser");
    if (user) {
        const access_token = user.access_token;
        if (access_token) {
            config.headers.Authorization = `Bearer ${access_token}`;
        }
    }

    config.timeout = 120000;

    return config
},
    function (error) {
        return Promise.reject(error);
    });

axios.interceptors.response.use(
    function (response) {
        return response;
    },
    function (error) {
        if (store.state.account.status.loggedIn == true) {
            var statusInContextOf = error.config.url.split('/')[3]
            if (error.response != null) {
                if (error.response.status === 401) {
                    store.dispatch('statusHandling/change_dataAndStatus_storeAction', { status: { statusCode: error.response.status.toString(), statusInContextOf: statusInContextOf, statusInformations: [{ textKey: 3269, textValues: null }], statusModalClosingOptions: [{ displayedDesignation: { textKey: 3242 }, closingOption: Vue.prototype.$closingOptions.logout }], valueDivergences: null, } })
                }
                else {
                    store.dispatch('statusHandling/change_dataAndStatus_storeAction', { status: { statusCode: error.response.status.toString(), statusInContextOf: statusInContextOf, statusInformations: [{ textKey: 3248, textValues: null }], statusModalClosingOptions: [{ displayedDesignation: { textKey: 3313 }, closingOption: Vue.prototype.$closingOptions.to_startPage }], valueDivergences: null, } })
                }
            }

            // timeout
            if (error.code == 'ECONNABORTED') {
                store.dispatch('statusHandling/change_dataAndStatus_storeAction', { status: { statusCode: '7600001', statusInContextOf: statusInContextOf, statusInformations: [{ textKey: 3248, textValues: null }], statusModalClosingOptions: [{ displayedDesignation: { textKey: 3313 }, closingOption: Vue.prototype.$closingOptions.to_startPage }], valueDivergences: null, } })
            }

            return Promise.reject(error);
        }
    });


