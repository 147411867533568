import Vue from 'vue'
import Router from 'vue-router'
import { store } from '@/store';

Vue.use(Router)

var router = new Router({
    mode: 'history', // https://router.vuejs.org/api/#mode
    base: process.env.BASE_URL,
    scrollBehavior: () => ({ y: 0 }),
    routes: configRoutes()
})

function configRoutes() {
    return [
        {
            path: '/',
            redirect: '/home',
            name: 'container',
            component: () => import(/* webpackChunkName: "container" */ '@/containers/TheContainer'),
            meta: { requiresAuth: true },
            children: [
                {
                    path: '/',
                    name: 'home',
                    component: () => import(/* webpackChunkName: "home" */ '@/views/Home'),
                },
                {
                    path: '/SEARCH',
                    name: 'Search',
                    component: () => import('@/views/Home'),
                    props: (route) => ({
                        zaliftidFromUrl: route.query.ID,
                        salesorderFromUrl: route.query.SALESORDER,
                        posFromUrl: route.query.POS
                    })
                },
                {
                    path: 'configurationStep/:slug',
                    name: 'configuration',
                    component: () => import(/* webpackChunkName: "inputs" */ '@/views/configuration/Configuration'),
                    children: [
                        {
                            path: '/inputs',
                            name: 'inputs',
                            component: () => import(/* webpackChunkName: "inputs" */ '@/views/configuration/configurationSteps/Inputs'),
                        },
                        {
                            path: '/driveTechnologies',
                            name: 'driveTechnologies',
                            component: () => import(/* webpackChunkName: "driveTechnologies" */ '@/views/configuration/configurationSteps/DriveTechnologies'),
                        },
                        {
                            path: '/controlTechnologies',
                            name: 'controlTechnologies',
                            component: () => import(/* webpackChunkName: "controlTechnologies" */ '@/views/configuration/configurationSteps/ControlTechnologies'),
                        },
                        {
                            path: '/frames',
                            name: 'frames',
                            component: () => import(/* webpackChunkName: "frames" */ '@/views/configuration/configurationSteps/Frames'),
                        },
                        {
                            path: '/summary',
                            name: 'summary',
                            component: () => import(/* webpackChunkName: "summary" */ '@/views/configuration/configurationSteps/Summary'),
                        }
                    ]
                },
                {
                    path: 'manualStep/:slug',
                    name: 'configuration',
                    component: () => import(/* webpackChunkName: "inputs" */ '@/views/configuration/Configuration'),
                    children: [
                        {
                            path: '/FI',
                            name: 'FI',
                            component: () => import(/* webpackChunkName: "inputs" */ '@/views/configuration/manualSteps/FI'),
                        },
                        {
                            path: '/ManualHome',
                            name: 'ManualHome',
                            component: () => import(/* webpackChunkName: "inputs" */ '@/views/configuration/manualSteps/ManualHome'),
                        },
                        {
                            path: '/EVAC',
                            name: 'EVAC',
                            component: () => import(/* webpackChunkName: "inputs" */ '@/views/configuration/manualSteps/EVAC'),
                        },
                        {
                            path: '/inputsManual',
                            name: 'inputsManual',
                            component: () => import(/* webpackChunkName: "inputs" */ '@/views/configuration/manualSteps/InputsManual'),
                        },
                        {
                            path: '/driveTechnologiesManual',
                            name: 'driveTechnologiesManual',
                            component: () => import(/* webpackChunkName: "inputs" */ '@/views/configuration/manualSteps/DriveTechnologiesManual'),
                        },
                        {
                            path: '/cables',
                            name: 'cables',
                            component: () => import(/* webpackChunkName: "cables" */ '@/views/configuration/manualSteps/Cables'),
                        },
                        {
                            path: '/suppliesMotorManual',
                            name: 'suppliesMotorManual',
                            component: () => import(/* webpackChunkName: "inputs" */ '@/views/configuration/manualSteps/SuppliesMotorManual'),
                        },
                        {
                            path: '/packagingMotorManual',
                            name: 'packagingMotorManual',
                            component: () => import(/* webpackChunkName: "inputs" */ '@/views/configuration/manualSteps/PackagingMotorManual'),
                        },
                        {
                            path: '/psgMenu',
                            name: 'psgMenu',
                            component: () => import(/* webpackChunkName: "inputs" */ '@/views/configuration/manualSteps/PsgMenu'),
                        },
                        {
                            path: '/ZArec',
                            name: 'ZArec',
                            component: () => import(/* webpackChunkName: "inputs" */ '@/views/configuration/manualSteps/ZArec'),
                        },
                        //{
                        //    path: '/RLD',
                        //    name: 'RLD',
                        //    component: () => import(/* webpackChunkName: "inputs" */ '@/views/configuration/manualSteps/RLD'),
                        //},

                    ]
                },
            ]
        },
        {
            path: '/callback',
            name: 'callback',
            component: () => import(/* webpackChunkName: "callback" */ '@/views/Callback')
        },
        {
            path: '/notFound',
            alias: '*',
            name: 'notFound',
            component: () => import(/* webpackChunkName: "404" */ '@/views/NotFound')
        },
    ]
}

router.beforeEach((to, from, next) => {
    if (store.state.account.status.loggedIn == true) {
        if (to.params.slug == 'driveTechnologies') {
            if (store.state.configurations.navigation.inputs == 'passed') {
                next();
            }
            else {
                if ((from.params.slug == 'inputs') && (store.state.configurations.configurationSteps.inputs.loaded == true) && (store.state.configurations.configurationSteps.inputs.storedInDatabase == false)) {
                    store.dispatch('inputs/checkIf_inputs_areValid_storeAction')
                }
                else {
                    store.dispatch('statusHandling/change_dataAndStatus_storeAction', { status: { statusCode: '7300000', statusInContextOf: '', statusInformations: [{ textKey: 3311, textValues: null }], statusModalClosingOptions: [{ displayedDesignation: { textKey: 383 }, closingOption: 'close' }], valueDivergences: null } })
                }
            }
        }
        else if (to.params.slug == 'controlTechnologies') {
            if (store.state.configurations.navigation.driveTechnologies == 'passed') {
                next();
            }
            else {
                store.dispatch('statusHandling/change_dataAndStatus_storeAction', { status: { statusCode: '7300000', statusInContextOf: '', statusInformations: [{ textKey: 3311, textValues: null }], statusModalClosingOptions: [{ displayedDesignation: { textKey: 383 }, closingOption: 'close' }], valueDivergences: null } })
            }
        }
        else if (to.params.slug == 'frames') {
            if (store.state.configurations.navigation.driveTechnologies == 'passed') {
                next();
            }
            else {
                store.dispatch('statusHandling/change_dataAndStatus_storeAction', { status: { statusCode: '7300000', statusInContextOf: '', statusInformations: [{ textKey: 3311, textValues: null }], statusModalClosingOptions: [{ displayedDesignation: { textKey: 383 }, closingOption: 'close' }], valueDivergences: null } })
            }
        }
        else if (to.params.slug == 'summary') {
            if (store.state.configurations.navigation.controlTechnologies == 'passed') {
                next();
            }
            else {
                store.dispatch('statusHandling/change_dataAndStatus_storeAction', { status: { statusCode: '7300000', statusInContextOf: '', statusInformations: [{ textKey: 3311, textValues: null }], statusModalClosingOptions: [{ displayedDesignation: { textKey: 383 }, closingOption: 'close' }], valueDivergences: null } })
            }
        }
        else {
            next()
        }
    }
    else if (to.matched.some(record => record.meta.requiresAuth)) {
        //authentication is required. Trigger the sign in process, including the return URI
        store.dispatch('account/authenticate', to.fullPath).then(() => {
            next();
        });
    }
    else {
        next();
    }
});

export default router;

