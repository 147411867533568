import Oidc from 'oidc-client';
import { Log } from 'oidc-client';

var url = "https://localhost:5001";
var client_id = "zalift-localhost";
var response_type = "id_token token";
var authority = 'https://vwebdev01.za.ziehl-abegg.de/AuthServer/';
var scope = 'openid profile zalift.api';
var loadUserInfo = true;


switch (window.location.hostname) {
    case "vwebdev01.za.ziehl-abegg.de":
        url = "https://vwebdev01.za.ziehl-abegg.de/ZAlift_development"
        break;

    case "app-zalift-frontend-dev-westeu-001.azurewebsites.net":
        authority = "https://test-login.ziehl-abegg.com/test-login.ziehl-abegg.com/B2C_1A_SIGNUP_SIGNIN/v2.0/.well-known/openid-configuration";
        url = "https://app-zalift-frontend-dev-westeu-001.azurewebsites.net";
        client_id = "e80a8522-41e3-48c4-a81c-57072b2af770";
        //response_type = "id_token";
        scope = 'profile https://zab2ctest.onmicrosoft.com/zalift.api/access';
        loadUserInfo = false;
        break;
    case "dev-zalift.ziehl-abegg.com":
        authority = "https://test-login.ziehl-abegg.com/test-login.ziehl-abegg.com/b2c_1a_signup_signin/v2.0/.well-known/openid-configuration";
        url = "https://dev-zalift.ziehl-abegg.com";
        client_id = "e80a8522-41e3-48c4-a81c-57072b2af770";
        //response_type = "id_token";
        scope = 'openid https://zab2ctest.onmicrosoft.com/zalift.api/access';
        loadUserInfo = false;
        break;
    case "beta-zalift.ziehl-abegg.com":
        authority = "https://test-login.ziehl-abegg.com/test-login.ziehl-abegg.com/b2c_1a_signup_signin/v2.0/.well-known/openid-configuration";
        url = "https://beta-zalift.ziehl-abegg.com";
        client_id = "e80a8522-41e3-48c4-a81c-57072b2af770";
        //response_type = "id_token";
        scope = 'openid https://zab2ctest.onmicrosoft.com/zalift.api/access';
        loadUserInfo = false;
        break;
    case "sap-zalift.ziehl-abegg.com":
        authority = "https://test-login.ziehl-abegg.com/test-login.ziehl-abegg.com/b2c_1a_signup_signin/v2.0/.well-known/openid-configuration";
        url = "https://sap-zalift.ziehl-abegg.com";
        client_id = "e80a8522-41e3-48c4-a81c-57072b2af770";
        //response_type = "id_token";
        scope = 'openid https://zab2ctest.onmicrosoft.com/zalift.api/access';
        loadUserInfo = false;
        break;
    case "zalift.ziehl-abegg.com":
        authority = "https://login.ziehl-abegg.com/login.ziehl-abegg.com/b2c_1a_signup_signin/v2.0/.well-known/openid-configuration";
        url = "https://zalift.ziehl-abegg.com";
        client_id = "08e6c251-6e3d-4365-9c5b-83490f41a282";
        //response_type = "id_token";
        scope = 'openid https://zab2c.onmicrosoft.com/zalift.api/access';
        loadUserInfo = false;
        break;
    case "localhost":
        authority = "https://test-login.ziehl-abegg.com/test-login.ziehl-abegg.com/b2c_1a_signup_signin/v2.0/.well-known/openid-configuration";
        url = "https://localhost:5001";
        client_id = "e80a8522-41e3-48c4-a81c-57072b2af770";
        scope = 'openid profile https://zab2ctest.onmicrosoft.com/zalift.api/access';
        loadUserInfo = false;
        break;
}

Log.logger = console;
Log.level = Log.DEBUG;

// https://github.com/IdentityModel/oidc-client-js/wiki
var userManager = new Oidc.UserManager({
    // required settings
    authority: authority, // the URL of the OIDC/OAuth2 provider | data type: string
    client_id: client_id, // your client application's identifier as registered with the OIDC/OAuth2 provider | data type: string
    redirect_uri: url + '/callback', // the redirect URI of your client application to receive a response from the OIDC/OAuth2 provider
    response_type: response_type, // the type of response desired from the OIDC/OAuth2 provider | data type: string, default: id_token 
    scope: scope, // the scope being requested from the OIDC/OAuth2 provider | data type: string, default: openid

    // optional settings
    userStore: new Oidc.WebStorageStateStore({ store: localStorage }), // storage object used to persist User for currently authenticated user
    automaticSilentRenew: true, // flag to indicate if there should be an automatic attempt to renew the access token prior to its expiration. The attempt is made as a result of the accessTokenExpiring event being raised | data type: boolean, default: false 
    accessTokenExpiringNotificationTime: 10, // the number of seconds before an access token is to expire to raise the accessTokenExpiring event | data type: number, default: 60
    post_logout_redirect_uri: url, // the OIDC/OAuth2 post-logout redirect URI | data type: string
    silent_redirect_uri: url + '/static/silent-renew.html', // the URL for the page containing the code handling the silent renew
    clockSkew: 25000,
    loadUserInfo: loadUserInfo,
});

export default userManager;