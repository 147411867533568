import axios from 'axios';
import router from '../router';

let state = {
    selectedMotorColor: undefined,
    selectedLogo: undefined,
    selectedCableGland: undefined,
    selectedCustomPart1: undefined,
    selectedCustomPart2: undefined,
    selectedCustomPart3: undefined,
    selectedLanguage1: undefined,
    selectedLanguage2: undefined,
    selectedQuantity1: undefined,
    selectedQuantity2: undefined,
    motorColorSelection: [],
    motorLogoSelection: [],
    motorOperatingInstructionSelection: [],
    deliverOperatingInstructionSelection: [],
    cableGlandSelection: [],
    quantityOperatingInstructions: 0,
    customPart_Modal_Shown: false,
    customPartNumber: undefined,
    customPartSelection: [],
    ratingPlateLayoutLanguage: undefined,
    massbl: undefined,
}

const actions = {

    change_suppliesMotorManual_toDefault_storeAction({ commit, dispatch }) {
        commit('change_selectedMotorColor_storeMutation', null);
        commit('change_selectedLogo_storeMutation', null);
        commit('change_selectedCableGland_storeMutation', null);
        commit('change_selectedCustomPart1_storeMutation', null);
        commit('change_selectedCustomPart2_storeMutation', null);
        commit('change_selectedCustomPart3_storeMutation', null);
        commit('change_selectedLanguage1_storeMutation', null);
        commit('change_selectedLanguage2_storeMutation', null);
        commit('change_selectedQuantity1_storeMutation', null);
        commit('change_selectedQuantity2_storeMutation', null);
        commit('change_motorColorSelection_storeMutation', []);
        commit('change_motorLogoSelection_storeMutation', []);
        commit('change_motorOperatingInstructionSelection_storeMutation', []);
        commit('change_deliverOperatingInstructionSelection_storeMutation', []);
        commit('change_cableGlandSelection_storeMutation', []);
        commit('change_quantityOperatingInstructions_storeMutation', 0);
        commit('change_customPart_Modal_Shown_storeMutation', false);
        commit('change_customPartNumber_storeMutation', null);
        commit('change_customPartSelection_storeMutation', []);
        commit('change_ratingPlateLayoutLanguage_storeMutation', null);
        commit('change_massbl_storeMutation', null);

    },


    async get_suppliesInformations_storeAction({ rootState, commit, dispatch }) {
        this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: true, reasonWhy_loaderModal_Shown: 'data_BeingLoaded' });
        const configurationStepsManualId = rootState.inputsManual.configurationStepsManualId;

        await axios({
            method: 'post',
            url: '/api/suppliesMotorManual/get_suppliesInformations/' + configurationStepsManualId,
        })
            .then((response) => {
                this.dispatch('statusHandling/change_dataAndStatus_storeAction', response.data);

                if ((response.data.status.statusCode.substring(0, 1) == '2') || (response.data.status.statusCode.substring(0, 2) == '74') || (response.data.status.statusCode.substring(0, 2) == '75')) {
                    commit('change_motorColorSelection_storeMutation', response.data.data.motorColorSelection);
                    commit('change_motorLogoSelection_storeMutation', response.data.data.motorLogoSelection);
                    commit('change_motorOperatingInstructionSelection_storeMutation', response.data.data.motorOperatingInstructionSelection);
                    commit('change_cableGlandSelection_storeMutation', response.data.data.cableGlandSelection);
                    commit('change_ratingPlateLayoutLanguage_storeMutation', response.data.data.ratingPlateLayoutLanguage);
                    commit('change_massbl_storeMutation', response.data.data.massbl);
                    commit('change_deliverOperatingInstructionSelection_storeMutation', response.data.data.deliverOperatingInstructionSelection);

                    const firstIndex_whichMeetConditions_Color = state.motorColorSelection.findIndex(i => (i.selected == true));
                    if (firstIndex_whichMeetConditions_Color > -1) {
                        dispatch('change_Selected_motorColor_storeAction', state.motorColorSelection[firstIndex_whichMeetConditions_Color]);
                    }
                    const firstIndex_whichMeetConditions_Logo = state.motorLogoSelection.findIndex(i => (i.selected == true));
                    if (firstIndex_whichMeetConditions_Logo > -1) {
                        dispatch('change_Selected_motorLogo_storeAction', state.motorLogoSelection[firstIndex_whichMeetConditions_Logo]);
                    }
                    dispatch('change_quantityOperatingInstructions_storeAction');

                    const firstIndex_whichMeetConditions_CableGland = state.cableGlandSelection.findIndex(i => (i.selected == true));
                    if (firstIndex_whichMeetConditions_CableGland > -1) {
                        dispatch('change_Selected_cableGland_storeAction', state.cableGlandSelection[firstIndex_whichMeetConditions_CableGland]);
                    }

                    commit('change_customPartSelection_storeMutation', response.data.data.customPartSelection);
                    if (state.customPartSelection != null && state.customPartSelection.length > 0 && response.data.data.selectedCustomPart1 != null) {
                        const firstIndex_whichMeetConditions_selectedCutomPart1 = state.customPartSelection.findIndex(i => (i.blockNumber == response.data.data.selectedCustomPart1));
                        if (firstIndex_whichMeetConditions_selectedCutomPart1 > -1) {
                            commit('change_selectedCustomPart1_storeMutation', state.customPartSelection[firstIndex_whichMeetConditions_selectedCutomPart1])
                            commit('change_customPartSelected_storeMutation', response.data.data.selectedCustomPart1)
                        }
                    }
                    if (state.customPartSelection != null && state.customPartSelection.length > 0 && response.data.data.selectedCustomPart2 != null) {
                        const firstIndex_whichMeetConditions_selectedCutomPart2 = state.customPartSelection.findIndex(i => (i.blockNumber == response.data.data.selectedCustomPart2));
                        if (firstIndex_whichMeetConditions_selectedCutomPart2 > -1) {
                            commit('change_selectedCustomPart2_storeMutation', state.customPartSelection[firstIndex_whichMeetConditions_selectedCutomPart2])
                            commit('change_customPartSelected_storeMutation', response.data.data.selectedCustomPart2)
                        }
                    }
                    if (state.customPartSelection != null && state.customPartSelection.length > 0 && response.data.data.selectedCustomPart3 != null) {
                        const firstIndex_whichMeetConditions_selectedCutomPart3 = state.customPartSelection.findIndex(i => (i.blockNumber == response.data.data.selectedCustomPart3));
                        if (firstIndex_whichMeetConditions_selectedCutomPart3 > -1) {
                            commit('change_selectedCustomPart3_storeMutation', state.customPartSelection[firstIndex_whichMeetConditions_selectedCutomPart3])
                            commit('change_customPartSelected_storeMutation', response.data.data.selectedCustomPart3)
                        }
                    }

                    if (state.ratingPlateLayoutLanguage != null && state.ratingPlateLayoutLanguage.hasOwnProperty("quantity1")) {
                        const firstIndex_whichMeetConditions_RatingPlateLanugageQuantity1 = state.ratingPlateLayoutLanguage.quantity1.findIndex(i => (i.selected == true));
                        if (firstIndex_whichMeetConditions_RatingPlateLanugageQuantity1 > -1) {
                            dispatch("change_Selected_quantity1_storeAction", state.ratingPlateLayoutLanguage.quantity1[firstIndex_whichMeetConditions_RatingPlateLanugageQuantity1]);
                        }
                    }
                    if (state.ratingPlateLayoutLanguage != null && state.ratingPlateLayoutLanguage.hasOwnProperty("quantity2")) {
                        const firstIndex_whichMeetConditions_RatingPlateLanugageQuantity2 = state.ratingPlateLayoutLanguage.quantity2.findIndex(i => (i.selected == true));
                        if (firstIndex_whichMeetConditions_RatingPlateLanugageQuantity2 > -1) {
                            dispatch("change_Selected_quantity2_storeAction", state.ratingPlateLayoutLanguage.quantity2[firstIndex_whichMeetConditions_RatingPlateLanugageQuantity2]);
                        }
                    }

                }
            })
            .catch(() => { })
            .then(() => {
                this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
            })
    },

    change_Selected_motorColor_storeAction({ commit, dispatch }, value) {
        if (value == null) {
            commit('change_motorColorSelected_storeMutation', null);
        }
        else {
            commit('change_motorColorSelected_storeMutation', value.text.text);
        }

        const firstIndex_whichMeetConditions = state.motorColorSelection.findIndex(i => (i.selected == true));
        if (firstIndex_whichMeetConditions > -1) {
            commit('change_selectedMotorColor_storeMutation', state.motorColorSelection[firstIndex_whichMeetConditions])
        }
        else {
            commit('change_selectedMotorColor_storeMutation', null);
        }
    },

    change_Selected_motorLogo_storeAction({ commit, dispatch }, value) {
        if (value == null) {
            commit('change_motorLogoSelected_storeMutation', null);
        }
        else {
            commit('change_motorLogoSelected_storeMutation', value.text.text);
        }

        const firstIndex_whichMeetConditions = state.motorLogoSelection.findIndex(i => (i.selected == true));
        if (firstIndex_whichMeetConditions > -1) {
            commit('change_selectedLogo_storeMutation', state.motorLogoSelection[firstIndex_whichMeetConditions])
        }
        else {
            commit('change_selectedLogo_storeMutation', null);
        }
    },

    change_Selected_operatingInstruction_storeAction({ commit, dispatch }, value) {
        if (value == null) {
            commit('change_operatingInstructionSelected_storeMutation', null);
        }
        else {
            commit('change_operatingInstructionSelected_storeMutation', value.text.text);
        }

        dispatch('change_quantityOperatingInstructions_storeAction');
    },

    change_Selected_deliverOperatingInstruction_storeAction({ commit, dispatch }, value) {
        if (value == null) {
            commit('change_deliverOperatingInstructionSelected_storeMutation', null);
        }
        else {
            commit('change_deliverOperatingInstructionSelected_storeMutation', value.text.text);
        }
    },

    change_quantityOperatingInstructions_storeAction({ commit }) {
        var number = state.motorOperatingInstructionSelection.filter(i => i.selected == true).length;
        if (number == null) {
            number = 0;
        }
        commit('change_quantityOperatingInstructions_storeMutation', number)
    },

    change_Selected_cableGland_storeAction({ commit, dispatch }, value) {
        if (value == null) {
            commit('change_cableGlandSelected_storeMutation', null);
        }
        else {
            commit('change_cableGlandSelected_storeMutation', value.text.text);
        }

        const firstIndex_whichMeetConditions = state.cableGlandSelection.findIndex(i => (i.selected == true));
        if (firstIndex_whichMeetConditions > -1) {
            commit('change_selectedCableGland_storeMutation', state.cableGlandSelection[firstIndex_whichMeetConditions])
        }
        else {
            commit('change_selectedCableGland_storeMutation', null);
        }
    },

    async change_customPart_Modal_Shown_storeAction({ commit, dispatch }, value) {
        commit('change_customPart_Modal_Shown_storeMutation', value)

        if (value == true) {
            //    commit('change_customPart_Modal_Shown_storeMutation', value)
        }
        else if (value == false) {
            //if (state.elevatorMotor_Machine_Selection_Shown == true) {
            //    await dispatch('checkIf_changesAt_tractionCheck_changes_configurationFlow_storeAction')
            //}
            //else {
            //    dispatch('change_tractionCheck_toDefault_storeAction')
            //}
        }
    },
    set_customPartModal_Shown_storeAction({ commit, dispatch }, value) {
        commit('change_customPart_Modal_Shown_storeMutation', true);
        commit('change_customPartNumber_storeMutation', value);

        if (state.customPartNumber == 1 && state.selectedCustomPart1 != null && state.selectedCustomPart1.hasOwnProperty('blockNumber') && state.selectedCustomPart1.blockNumber != null) {
            commit('change_customPartSelected_storeMutation', state.selectedCustomPart1.blockNumber)
        }
        else if (state.customPartNumber == 2 && state.selectedCustomPart2 != null && state.selectedCustomPart2.hasOwnProperty('blockNumber') && state.selectedCustomPart2.blockNumber != null) {
            commit('change_customPartSelected_storeMutation', state.selectedCustomPart2.blockNumber)
        }
        else if (state.customPartNumber == 3 && state.selectedCustomPart3 != null && state.selectedCustomPart3.hasOwnProperty('blockNumber') && state.selectedCustomPart3.blockNumber != null) {
            commit('change_customPartSelected_storeMutation', state.selectedCustomPart3.blockNumber)
        }
        else {
            commit('change_customPartSelected_storeMutation', state.customPartSelection[0].blockNumber)
        }
    },
    change_customPartModal_Shown_storeAction({ commit, dispatch }, value) {
        if (value == 1) {
            const firstIndex_whichMeetCondition = state.customPartSelection.findIndex(i => (i.selected == true));
            if (firstIndex_whichMeetCondition > -1) {
                if (state.customPartNumber == 1) {
                    commit('change_selectedCustomPart1_storeMutation', state.customPartSelection[firstIndex_whichMeetCondition])
                }
                else if (state.customPartNumber == 2) {
                    commit('change_selectedCustomPart2_storeMutation', state.customPartSelection[firstIndex_whichMeetCondition])
                }
                else if (state.customPartNumber == 3) {
                    commit('change_selectedCustomPart3_storeMutation', state.customPartSelection[firstIndex_whichMeetCondition])
                }
            }
        }
        commit('change_customPartNumber_storeMutation', null);
        commit('change_customPartSelected_storeMutation', null);
        commit('change_customPart_Modal_Shown_storeMutation', false);
    },
    change_Selected_customPart_storeAction({ commit, dispatch }, value) {
        if (value == null) {
            commit('change_customPartSelected_storeMutation', null);
        }
        else {
            commit('change_customPartSelected_storeMutation', value.blockNumber);
        }
    },
    change_Selected_language1_storeAction({ commit, dispatch }, value) {
        if (value == null) {
            commit('change_language1Selected_storeMutation', null);
        }
        else {
            commit('change_language1Selected_storeMutation', value.text.text);
        }

        const firstIndex_whichMeetConditions = state.ratingPlateLayoutLanguage.language1.findIndex(i => (i.selected == true));
        if (firstIndex_whichMeetConditions > -1) {
            commit('change_selectedLanguage1_storeMutation', state.ratingPlateLayoutLanguage.language1[firstIndex_whichMeetConditions])
        }
        else {
            commit('change_selectedLanguage1_storeMutation', null);
        }
    },
    change_Selected_language2_storeAction({ commit, dispatch }, value) {
        if (value == null) {
            commit('change_language2Selected_storeMutation', null);
        }
        else {
            commit('change_language2Selected_storeMutation', value.text.text);
        }

        const firstIndex_whichMeetConditions = state.ratingPlateLayoutLanguage.language2.findIndex(i => (i.selected == true));
        if (firstIndex_whichMeetConditions > -1) {
            commit('change_selectedLanguage2_storeMutation', state.ratingPlateLayoutLanguage.language2[firstIndex_whichMeetConditions])
        }
        else {
            commit('change_selectedLanguage2_storeMutation', null);
        }
    },
    change_Selected_quantity1_storeAction({ commit, dispatch }, value) {
        if (value == null) {
            commit('change_quantity1Selected_storeMutation', null);
        }
        else {
            commit('change_quantity1Selected_storeMutation', value.value);
        }

        const firstIndex_whichMeetConditions = state.ratingPlateLayoutLanguage.quantity1.findIndex(i => (i.selected == true));
        if (firstIndex_whichMeetConditions > -1) {
            commit('change_selectedQuantity1_storeMutation', state.ratingPlateLayoutLanguage.quantity1[firstIndex_whichMeetConditions])
        }
        else {
            commit('change_selectedQuantity1_storeMutation', null);
        }
    },
    change_Selected_quantity2_storeAction({ commit, dispatch }, value) {
        if (value == null) {
            commit('change_quantity2Selected_storeMutation', null);
        }
        else {
            commit('change_quantity2Selected_storeMutation', value.value);
        }

        const firstIndex_whichMeetConditions = state.ratingPlateLayoutLanguage.quantity2.findIndex(i => (i.selected == true));
        if (firstIndex_whichMeetConditions > -1) {
            commit('change_selectedQuantity2_storeMutation', state.ratingPlateLayoutLanguage.quantity2[firstIndex_whichMeetConditions])
        }
        else {
            commit('change_selectedQuantity2_storeMutation', null);
        }
    },
    async insertOrUpdate_suppliesMotorManual_storeAction({ rootState, dispatch }) {
        this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: true, reasonWhy_loaderModal_Shown: 'data_BeingLoaded' });

        this.dispatch('packagingMotorManual/change_packagingMotor_toDefault_storeAction');
        const configurationStepsManualId = rootState.inputsManual.configurationStepsManualId;

        var suppliesMotorManual = {};
        if (state.selectedMotorColor != null) {
            suppliesMotorManual.mN_FARBE = state.selectedMotorColor.blockNumberColor;
            suppliesMotorManual.fARBE = state.selectedMotorColor.text.text;
            suppliesMotorManual.mFARBE = state.selectedMotorColor.quantityColor;
            suppliesMotorManual.mHAERT = state.selectedMotorColor.quantityHardener;
            suppliesMotorManual.tNHAERT = state.selectedMotorColor.blockNumberHardener;
        }
        if (state.selectedLogo != null) {
            suppliesMotorManual.mN_LOGO = state.selectedLogo.blockNumber;
            suppliesMotorManual.lLOGO = state.selectedLogo.text.code;
        }
        var motorOperatingInstructions = state.motorOperatingInstructionSelection.filter(function (x) { return x.selected == true });

        var firstIndex_whichMeetCondition_deliverOperatingInstruction = -1;
        if (state.deliverOperatingInstructionSelection != null) {
            firstIndex_whichMeetCondition_deliverOperatingInstruction = state.deliverOperatingInstructionSelection.findIndex(i => (i.selected == true));
        }
        if (firstIndex_whichMeetCondition_deliverOperatingInstruction > -1) {
            if (state.deliverOperatingInstructionSelection[firstIndex_whichMeetCondition_deliverOperatingInstruction].text.code == 1) {
                suppliesMotorManual.lBAL = 0;

                if (Array.isArray(motorOperatingInstructions) == true && motorOperatingInstructions.length > 0) {
                    suppliesMotorManual.mN_BAL1 = motorOperatingInstructions[0].blockNumber;
                    suppliesMotorManual.bALSP1 = motorOperatingInstructions[0].text.text;
                    suppliesMotorManual.bALME1 = motorOperatingInstructions[0].quantity;
                    suppliesMotorManual.lBAL = 1;
                }
                if (Array.isArray(motorOperatingInstructions) == true && motorOperatingInstructions.length > 1) {
                    suppliesMotorManual.mN_BAL2 = motorOperatingInstructions[1].blockNumber;
                    suppliesMotorManual.bALSP2 = motorOperatingInstructions[1].text.text;
                    suppliesMotorManual.bALME2 = motorOperatingInstructions[1].quantity;
                }
                if (Array.isArray(motorOperatingInstructions) == true && motorOperatingInstructions.length > 2) {
                    suppliesMotorManual.mN_BAL3 = motorOperatingInstructions[2].blockNumber;
                    suppliesMotorManual.bALSP3 = motorOperatingInstructions[2].text.text;
                    suppliesMotorManual.bALME3 = motorOperatingInstructions[2].quantity;
                }

            }
            else {
                suppliesMotorManual.lBAL = 0;
            }
        }
        else {
            suppliesMotorManual.lBAL = 0;

            if (Array.isArray(motorOperatingInstructions) == true && motorOperatingInstructions.length > 0) {
                suppliesMotorManual.mN_BAL1 = motorOperatingInstructions[0].blockNumber;
                suppliesMotorManual.bALSP1 = motorOperatingInstructions[0].text.text;
                suppliesMotorManual.bALME1 = motorOperatingInstructions[0].quantity;
                suppliesMotorManual.lBAL = 1;
            }
            if (Array.isArray(motorOperatingInstructions) == true && motorOperatingInstructions.length > 1) {
                suppliesMotorManual.mN_BAL2 = motorOperatingInstructions[1].blockNumber;
                suppliesMotorManual.bALSP2 = motorOperatingInstructions[1].text.text;
                suppliesMotorManual.bALME2 = motorOperatingInstructions[1].quantity;
            }
            if (Array.isArray(motorOperatingInstructions) == true && motorOperatingInstructions.length > 2) {
                suppliesMotorManual.mN_BAL3 = motorOperatingInstructions[2].blockNumber;
                suppliesMotorManual.bALSP3 = motorOperatingInstructions[2].text.text;
                suppliesMotorManual.bALME3 = motorOperatingInstructions[2].quantity;
            }

        }

        if (state.selectedCableGland != null) {
            suppliesMotorManual.tNKVML = state.selectedCableGland.blockNumber;
            suppliesMotorManual.cableGlandSelected = state.selectedCableGland.text.text;
        }
        if (state.selectedCustomPart1 != null) {
            suppliesMotorManual.mN_KDMAT1 = state.selectedCustomPart1.blockNumber;
            suppliesMotorManual.customPart1 = state.selectedCustomPart1.text.text;
        }
        if (state.selectedCustomPart2 != null) {
            suppliesMotorManual.mN_KDMAT2 = state.selectedCustomPart2.blockNumber;
            suppliesMotorManual.customPart2 = state.selectedCustomPart2.text.text;
        }
        if (state.selectedCustomPart3 != null) {
            suppliesMotorManual.mN_KDMAT3 = state.selectedCustomPart3.blockNumber;
            suppliesMotorManual.customPart3 = state.selectedCustomPart3.text.text;
        }

        const firstIndex_whichMeetCondition_language1 = state.ratingPlateLayoutLanguage.language1.findIndex(i => (i.selected == true));
        const firstIndex_whichMeetCondition_language2 = state.ratingPlateLayoutLanguage.language2.findIndex(i => (i.selected == true));
        const firstIndex_whichMeetCondition_quantity1 = state.ratingPlateLayoutLanguage.quantity1.findIndex(i => (i.selected == true));
        const firstIndex_whichMeetCondition_quantity2 = state.ratingPlateLayoutLanguage.quantity2.findIndex(i => (i.selected == true));

        if (firstIndex_whichMeetCondition_language1 > -1) {
            suppliesMotorManual.lLab01 = state.ratingPlateLayoutLanguage.language1[firstIndex_whichMeetCondition_language1].text.text;
        }
        if (firstIndex_whichMeetCondition_language2 > -1) {
            suppliesMotorManual.lLab02 = state.ratingPlateLayoutLanguage.language2[firstIndex_whichMeetCondition_language2].text.text;
        }
        if (firstIndex_whichMeetCondition_quantity1 > -1) {
            suppliesMotorManual.nL01 = state.ratingPlateLayoutLanguage.quantity1[firstIndex_whichMeetCondition_quantity1].value;
        }
        if (firstIndex_whichMeetCondition_quantity2 > -1) {
            suppliesMotorManual.nL02 = state.ratingPlateLayoutLanguage.quantity1[firstIndex_whichMeetCondition_quantity2].value;
        }

        suppliesMotorManual.kulogo = state.ratingPlateLayoutLanguage.kulogo;
        suppliesMotorManual.x_LSD = state.ratingPlateLayoutLanguage.x_LSD;
        suppliesMotorManual.nL03 = state.ratingPlateLayoutLanguage.nL03;
        suppliesMotorManual.aL01 = state.ratingPlateLayoutLanguage.aL01;


        const data = {
            configurationStepsManualId: configurationStepsManualId,
            suppliesMotorManual: suppliesMotorManual,
            iNOK: state.ratingPlateLayoutLanguage.inok,
        }
        await axios({
            method: 'post',
            url: '/api/configurations/insertOrUpdate_suppliesMotorManual',
            data: {
                data: data,
            }
        })
            .then((response) => {
                this.dispatch('statusHandling/change_dataAndStatus_storeAction', response.data);

                if (rootState.configurations.plusManual == false) {
                    if (response.data.status.statusCode.substring(0, 1) == '2') {
                        if (response.data.data.changedDataset == true) {
                            this.dispatch('packagingMotorManual/change_packagingMotor_toDefault_storeAction');

                            var navigationManual = Object.assign({}, rootState.configurations.navigationManual)
                            navigationManual.suppliesMotorManual = 'passed';
                            navigationManual.packagingMotorManual = null;
                            this.commit('configurations/change_navigationManual_storeMutation', navigationManual);
                        }
                        router.push({ params: { slug: 'packagingMotorManual' } });
                    }
                    else {
                        var navigationManual = Object.assign({}, rootState.configurations.navigationManual)
                        navigationManual.suppliesMotorManual = null;
                        navigationManual.packagingMotorManual = null;
                        this.commit('configurations/change_navigationManual_storeMutation', navigationManual);
                    }
                }

            })
            .catch(() => { })
            .then(() => {
                this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });

                if (rootState.configurations.plusManual == true) {
                    this.dispatch('configurations/change_manualConfiguration_storeAction', false);
                    router.push('../manualStep/ManualHome');
                }

            })
    },
    async delete_configuration_suppliesMotorManual_storeAction({ rootState, commit, dispatch }) {
        this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: true, reasonWhy_loaderModal_Shown: 'data_BeingLoaded' });

        const configurationStepsManualId = rootState.inputsManual.configurationStepsManualId;

        const data = {
            configurationStepsManualId: configurationStepsManualId,
        }

        await axios({
            method: 'post',
            url: '/api/configurations/delete_configuration_suppliesMotorManual',
            data: {
                data: data,
            }
        })
            .then((response) => {
                this.dispatch('statusHandling/change_dataAndStatus_storeAction', response.data);
                if (response.data.status.statusCode.substring(0, 1) == '2') {
                    this.dispatch('packagingMotorManual/change_packagingMotor_toDefault_storeAction');

                    var navigationManual = Object.assign({}, rootState.configurations.navigationManual)
                    navigationManual.suppliesMotorManual = null;
                    navigationManual.packagingMotorManual = null;
                    this.commit('configurations/change_navigationManual_storeMutation', navigationManual);
                }

            })
            .catch(() => { })
            .then(() => {
                this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
            })

    },

    checkIf_changesAt_configurationFlow_storeAction({ rootState, dispatch }) {
        if (rootState.configurations.navigationManual.suppliesMotorManual == "passed") {
            dispatch('delete_configuration_suppliesMotorManual_storeAction');
        }
    },


    checkIf_suppliesMotorManual_areValid_storeAction({ rootState, dispatch }) {
        var numberOf_inputs_whichAreInvalid = 0;

        if (state.selectedMotorColor == null || state.selectedMotorColor.blockNumberColor == null) {
            numberOf_inputs_whichAreInvalid = numberOf_inputs_whichAreInvalid + 1;
        }
        if (state.selectedLogo == null || state.selectedLogo.text == null || state.selectedLogo.text.code == null) {
            numberOf_inputs_whichAreInvalid = numberOf_inputs_whichAreInvalid + 1;
        }
        var motorOperatingInstructions = state.motorOperatingInstructionSelection.filter(function (x) { return x.selected == true });
        if (motorOperatingInstructions == null || motorOperatingInstructions.length == 0) {
            numberOf_inputs_whichAreInvalid = numberOf_inputs_whichAreInvalid + 1;
        }

        const firstIndex_whichMeetCondition_language1 = state.ratingPlateLayoutLanguage.language1.findIndex(i => (i.selected == true));
        const firstIndex_whichMeetCondition_quantity1 = state.ratingPlateLayoutLanguage.quantity1.findIndex(i => (i.selected == true));

        if (firstIndex_whichMeetCondition_language1 == -1) {
            numberOf_inputs_whichAreInvalid = numberOf_inputs_whichAreInvalid + 1;
        }
        if (firstIndex_whichMeetCondition_quantity1 == -1) {
            numberOf_inputs_whichAreInvalid = numberOf_inputs_whichAreInvalid + 1;
        }

        if (state.deliverOperatingInstructionSelection != null) {
            const firstIndex_whichMeetCondition_deliverOperatingInstruction = state.deliverOperatingInstructionSelection.findIndex(i => (i.selected == true));
            if (firstIndex_whichMeetCondition_deliverOperatingInstruction == -1) {
                numberOf_inputs_whichAreInvalid = numberOf_inputs_whichAreInvalid + 1;
            }
        }
        else {
            numberOf_inputs_whichAreInvalid = numberOf_inputs_whichAreInvalid + 1;
        }

        if (numberOf_inputs_whichAreInvalid > 0) {
            this.dispatch('statusHandling/change_dataAndStatus_storeAction', { status: { statusCode: '7200001', statusInContextOf: 'checkIf_inputs_areValid_storeAction', statusInformations: [{ textKey: 1446 }, { textKey: 1449 }], statusModalClosingOptions: [{ displayedDesignation: { textKey: 383 }, closingOption: 'close' }] } })
        }
        else if (numberOf_inputs_whichAreInvalid == 0) {
            dispatch('insertOrUpdate_suppliesMotorManual_storeAction');
        }

    },

}

const mutations = {
    change_motorColorSelection_storeMutation(state, payload) {
        state.motorColorSelection = payload;
    },
    change_motorLogoSelection_storeMutation(state, payload) {
        state.motorLogoSelection = payload;
    },
    change_motorOperatingInstructionSelection_storeMutation(state, payload) {
        state.motorOperatingInstructionSelection = payload;
    },
    change_deliverOperatingInstructionSelection_storeMutation(state, payload) {
        state.deliverOperatingInstructionSelection = payload;
    },

    change_cableGlandSelection_storeMutation(state, payload) {
        state.cableGlandSelection = payload;
    },
    change_ratingPlateLayoutLanguage_storeMutation(state, payload) {
        state.ratingPlateLayoutLanguage = payload;
    },
    change_massbl_storeMutation(state, payload) {
        state.massbl = payload;
    },
    change_motorColorSelected_storeMutation(state, payload) {
        for (let i = 0; i < state.motorColorSelection.length; i++) {
            state.motorColorSelection[i].selected = false;
            if (state.motorColorSelection[i].text.text == payload) {
                state.motorColorSelection[i].selected = true;
            }
        }
    },
    change_motorLogoSelected_storeMutation(state, payload) {
        for (let i = 0; i < state.motorLogoSelection.length; i++) {
            state.motorLogoSelection[i].selected = false;
            if (state.motorLogoSelection[i].text.text == payload) {
                state.motorLogoSelection[i].selected = true;
            }
        }
    },
    change_operatingInstructionSelected_storeMutation(state, payload) {
        for (let i = 0; i < state.motorOperatingInstructionSelection.length; i++) {
            if (payload == null) {
                state.motorOperatingInstructionSelection[i].selected = false;
            }
            if (state.motorOperatingInstructionSelection[i].text.text == payload) {
                if (state.motorOperatingInstructionSelection[i].selected == true) {
                    state.motorOperatingInstructionSelection[i].selected = false;
                }
                else {
                    state.motorOperatingInstructionSelection[i].selected = true;
                }
            }
        }
    },
    change_deliverOperatingInstructionSelected_storeMutation(state, payload) {
        for (let i = 0; i < state.deliverOperatingInstructionSelection.length; i++) {
            state.deliverOperatingInstructionSelection[i].selected = false;
            if (state.deliverOperatingInstructionSelection[i].text.text == payload) {
                state.deliverOperatingInstructionSelection[i].selected = true;
            }
        }
    },

    change_cableGlandSelected_storeMutation(state, payload) {
        for (let i = 0; i < state.cableGlandSelection.length; i++) {
            state.cableGlandSelection[i].selected = false;
            if (state.cableGlandSelection[i].text.text == payload) {
                state.cableGlandSelection[i].selected = true;
            }
        }
    },
    change_customPartSelected_storeMutation(state, payload) {
        for (let i = 0; i < state.customPartSelection.length; i++) {
            state.customPartSelection[i].selected = false;
            if (state.customPartSelection[i].blockNumber == payload) {
                state.customPartSelection[i].selected = true;
            }
        }
    },
    change_language1Selected_storeMutation(state, payload) {
        for (let i = 0; i < state.ratingPlateLayoutLanguage.language1.length; i++) {
            state.ratingPlateLayoutLanguage.language1[i].selected = false;
            if (state.ratingPlateLayoutLanguage.language1[i].text.text == payload) {
                state.ratingPlateLayoutLanguage.language1[i].selected = true;
            }
        }
    },
    change_language2Selected_storeMutation(state, payload) {
        for (let i = 0; i < state.ratingPlateLayoutLanguage.language2.length; i++) {
            state.ratingPlateLayoutLanguage.language2[i].selected = false;
            if (state.ratingPlateLayoutLanguage.language2[i].text.text == payload) {
                state.ratingPlateLayoutLanguage.language2[i].selected = true;
            }
        }
    },

    change_quantity1Selected_storeMutation(state, payload) {
        for (let i = 0; i < state.ratingPlateLayoutLanguage.quantity1.length; i++) {
            state.ratingPlateLayoutLanguage.quantity1[i].selected = false;
            if (state.ratingPlateLayoutLanguage.quantity1[i].value == payload) {
                state.ratingPlateLayoutLanguage.quantity1[i].selected = true;
            }
        }
    },
    change_quantity2Selected_storeMutation(state, payload) {
        for (let i = 0; i < state.ratingPlateLayoutLanguage.quantity2.length; i++) {
            state.ratingPlateLayoutLanguage.quantity2[i].selected = false;
            if (state.ratingPlateLayoutLanguage.quantity2[i].value == payload) {
                state.ratingPlateLayoutLanguage.quantity2[i].selected = true;
            }
        }
    },
    change_selectedMotorColor_storeMutation(state, payload) {
        state.selectedMotorColor = payload;
    },
    change_selectedLogo_storeMutation(state, payload) {
        state.selectedLogo = payload;
    },
    change_quantityOperatingInstructions_storeMutation(state, payload) {
        state.quantityOperatingInstructions = payload;
    },
    change_selectedCableGland_storeMutation(state, payload) {
        state.selectedCableGland = payload;
    },
    change_customPart_Modal_Shown_storeMutation(state, payload) {
        state.customPart_Modal_Shown = payload;
    },
    change_customPartNumber_storeMutation(state, payload) {
        state.customPartNumber = payload;
    },
    change_customPartSelection_storeMutation(state, payload) {
        state.customPartSelection = payload;
    },
    change_selectedCustomPart1_storeMutation(state, payload) {
        state.selectedCustomPart1 = payload;
    },
    change_selectedCustomPart2_storeMutation(state, payload) {
        state.selectedCustomPart2 = payload;
    },
    change_selectedCustomPart3_storeMutation(state, payload) {
        state.selectedCustomPart3 = payload;
    },
    change_selectedLanguage1_storeMutation(state, payload) {
        state.selectedLanguage1 = payload;
    },
    change_selectedLanguage2_storeMutation(state, payload) {
        state.selectedLanguage2 = payload;
    },
    change_selectedQuantity1_storeMutation(state, payload) {
        state.selectedQuantity1 = payload;
    },
    change_selectedQuantity2_storeMutation(state, payload) {
        state.selectedQuantity2 = payload;
    },
}

export const suppliesMotorManual = {
    namespaced: true,
    components: {
        axios,
    },
    state,
    actions,
    mutations
}