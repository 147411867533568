import Vue from 'vue';
import Vuex from 'vuex';

import { account } from './account.module';
import { statusHandling } from './statusHandling.module';
import { configurations } from './configurations.module';
import { inputs } from './inputs.module';
import { driveTechnologies } from './driveTechnologies.module';
import { controlTechnologies } from './controlTechnologies.module';
import { frames } from './frames.module';
import { cables } from './cables.module';
import { summary } from './summary.module';
import { user } from './user.module';
import { container } from './container.module';
import { FI } from './FI.module';
import { EVAC } from './EVAC.module';
import {inputsManual } from './inputsManual.module'
import { ZArec } from './ZArec.module';
/*import { RLD } from './RLD.module';*/
import { driveTechnologiesManual } from './driveTechnologiesManual.module'
import { suppliesMotorManual } from './suppliesMotorManual.module'
import { packagingMotorManual } from './packagingMotorManual.module'
import { psgMenu } from './psgMenu.module'
import { manualHome } from './manualHome.module';

Vue.use(Vuex);

export const store = new Vuex.Store({
    modules: {
        account,
        statusHandling,
        configurations,
        inputs,
        driveTechnologies,
        controlTechnologies,
        frames,
        cables,
        summary,
        user,
        container,
        FI,
        EVAC,
        ZArec,
       /* RLD,*/
        inputsManual,
        driveTechnologiesManual,
        suppliesMotorManual,
        packagingMotorManual,
        psgMenu,
        manualHome
    }
});

