import axios from 'axios';
import router from '../router';

let state = {
    // elevatorSystem
    nominalLoad_ValueList: [],
    nominalLoad: { value: undefined, invalid: undefined },

    nominalSpeed_ValueList: [],
    nominalSpeed: { value: undefined, invalid: undefined },

    travelDistance_ValueList: [],
    travelDistance: { value: undefined, invalid: undefined },

    carWeight_ValueList: [],
    carWeight: { value: undefined, invalid: undefined },

    counterweightUnit_Options: [{ text: '%', value: true }, { text: 'kg', value: false }],
    counterweightInPercent: true,
    counterweightInPercent_ValueList: [],
    counterweight: { value: undefined, invalid: undefined },
    counterweightDisplay: '?',

    mainsVoltageAndMainsFrequency_Selection: [],
    mainsVoltage: undefined,
    mainsFrequency: undefined,
    mainsVoltageAndMainsFrequency: { value: undefined, invalid: undefined },

    usageCategoryAndAverageTravelTimeAndDutyCycle_Selection: [],
    usageCategory: undefined,
    averageTravelTime: undefined,
    dutyCycle: undefined,
    usageCategoryAndAverageTravelTimeAndDutyCycle: { value: undefined, invalid: undefined },

    startsPerHour_Selection: [],
    startsPerHour: { value: undefined, invalid: undefined },


    // construction
    locationOfMachine_Selection: [],
    locationOfMachine_VerticalPositionOfMachineAndDetailedPositionOfMachine_Selection: [],
    locationOfMachine_Selection_filteredBy_selected_verticalPositionOfMachineAndDetailedPositionOfMachine: [],

    designType: 'standard',

    suspensionRatio1_ValueList: [],
    suspensionRatio1: { value: undefined, invalid: undefined },

    numberOfPulleysOnCarSide_ValueList: [],
    numberOfPulleysOnCarSide: { value: undefined, invalid: undefined },

    numberOfPulleysOnCounterweightSide_ValueList: [],
    numberOfPulleysOnCounterweightSide: { value: undefined, invalid: undefined },

    calculatedWithDoublewrap: false,
    wrappingAngle_Calculated: false,
    wrappingAngle_ValueList: [],
    wrappingAngle: { value: undefined, invalid: undefined },
    accept_sFrameList: [],


    // suspensionMeans
    suspensionMeans_Selection: [],
    typeOfSuspensionMeans: 'rope',
    ropeManufacturerAndType_Selection: [],
    ropeManufacturer: undefined,
    ropeType: undefined,
    beltManufacturerAndType_Selection: [],
    beltManufacturer: undefined,
    beltType: undefined,
    suspensionMeansManufacturerAndType: { value: undefined, invalid: undefined },

    ropeDiameter_ValueList: [],
    ropeDiameter: { value: undefined, invalid: undefined },

    cordDiameter_ValueList: [],
    cordDiameter: { value: undefined, invalid: undefined },

    individualSuspensionMeans: false,
    individualSuspensionMeans_ropeDiameter: { value: undefined, invalid: undefined },
    individualSuspensionMeans_cordDiameter: { value: undefined, invalid: undefined },
    individualSuspensionMeans_breakingForce: { value: undefined, invalid: undefined },
    individualSuspensionMeans_weightPerMeter: { value: undefined, invalid: undefined },

    numberOfSuspensionMeans_ValueList: [],
    numberOfSuspensionMeans: { value: undefined, invalid: undefined },

    weight: { value: undefined, canBeCalculated: false, calculatedSuccessfully: undefined },
    loader_forWeightCalculation_Shown: false,

    safetyFactor: { value: undefined, canBeCalculated: false, calculatedSuccessfully: undefined },
    loader_forSafetyFactorCalculation_Shown: false,

    cableWeightPerMeter_ValueList: [],
    cableWeightPerMeter: { value: undefined, invalid: undefined },

    useOfRopeTensionWeight: false,
    ropeTensionWeight_ValueList: [],
    ropeTensionWeight: { value: undefined, invalid: undefined },

    useOfCompensationRope: false,
    compensationRopeUnit_Options: [{ text: '%', value: true }, { text: 'kg', value: false }],
    compensationRopeInPercent: true,
    compensationRopeInPercent_ValueList: [],
    compensationRope: { value: undefined, invalid: undefined },

    dFactor: { value: undefined, invalid: undefined },
    inputdFactor_Disabled: undefined,

    // elevatorMotor_TractionSheave
    grooveDistance_ValueList: [],
    useOfStandardGrooveDistance: true,
    grooveDistance: { value: undefined, invalid: undefined },

    useStandardNumberOfGrooves: true,
    numberOfGrooves: { value: undefined, invalid: undefined },


    // additional components
    useFrame: false,
    accept_useFrame: false,

    // configuration
    checkedAtLeastOnce: false,

    // tooltip
    tooltip_ropeTensionWeight: { value: false, textKey: undefined, textValues: undefined },
    tooltip_compensationRope: { value: false, textKey: undefined, textValues: undefined },
    tooltip_grooveDistance: { value: false, textKey: undefined, textValues: undefined },
}

const actions = {
    change_inputs_toDefault_storeAction({ commit, dispatch }) {

        // elevatorSystem
        commit('change_nominalLoad_storeMutation', { value: null, invalid: null });
        commit('change_nominalSpeed_storeMutation', { value: null, invalid: null });
        commit('change_travelDistance_storeMutation', { value: null, invalid: null });
        commit('change_carWeight_storeMutation', { value: null, invalid: null });

        commit('change_counterweightInPercent_storeMutation', true);
        commit('change_counterweight_storeMutation', { value: null, invalid: null });

        commit('change_mainsVoltage_storeMutation', null);
        commit('change_mainsFrequency_storeMutation', null);
        commit('change_mainsVoltageAndMainsFrequency_storeMutation', { value: null, invalid: null });

        commit('change_usageCategory_storeMutation', null);
        commit('change_averageTravelTime_storeMutation', null);
        commit('change_dutyCycle_storeMutation', null);
        commit('change_usageCategoryAndAverageTravelTimeAndDutyCycle_storeMutation', { value: null, invalid: null });


        // construction
        dispatch('change_locationOfMachine_toDefault_storeAction')

        commit('change_designType_storeMutation', 'standard');

        commit('change_suspensionRatio1_storeMutation', { value: null, invalid: null });
        commit('change_numberOfPulleysOnCarSide_storeMutation', { value: null, invalid: null });
        commit('change_numberOfPulleysOnCounterweightSide_storeMutation', { value: null, invalid: null });

        commit('change_calculatedWithDoublewrap_storeMutation', false);
        commit('change_wrappingAngle_Calculated_storeMutation', false);
        commit('change_wrappingAngle_storeMutation', { value: null, invalid: null });
        commit('change_useFrame_storeMutation', false);


        // suspensionMeans
        commit('change_typeOfSuspensionMeans_storeMutation', 'rope');

        commit('change_ropeManufacturer_storeMutation', null);
        commit('change_ropeType_storeMutation', null);
        dispatch('change_tooltip_grooveDistance_storeAction');
        commit('change_beltManufacturer_storeMutation', null);
        commit('change_beltType_storeMutation', null);
        commit('change_suspensionMeansManufacturerAndType_storeMutation', { value: null, invalid: null });

        commit('change_ropeDiameter_storeMutation', { value: null, invalid: null });
        commit('change_cordDiameter_storeMutation', { value: null, invalid: null });

        commit('change_Selected_at_suspensionMeans_Selection_storeMutation', null);

        commit('change_individualSuspensionMeans_storeMutation', false);
        commit('change_individualSuspensionMeans_ropeDiameter_storeMutation', { value: null, invalid: null });
        commit('change_individualSuspensionMeans_cordDiameter_storeMutation', { value: null, invalid: null });
        commit('change_individualSuspensionMeans_breakingForce_storeMutation', { value: null, invalid: null });
        commit('change_individualSuspensionMeans_weightPerMeter_storeMutation', { value: null, invalid: null });

        commit('change_numberOfSuspensionMeans_storeMutation', { value: null, invalid: null });

        commit('change_weight_storeMutation', { value: null, canBeCalculated: false, calculatedSuccessfully: null });
        commit('change_loader_forWeightCalculation_Shown_storeMutation', false);

        commit('change_safetyFactor_storeMutation', { value: null, canBeCalculated: false, calculatedSuccessfully: null });
        commit('change_loader_forSafetyFactorCalculation_Shown_storeMutation', false);

        commit('change_cableWeightPerMeter_storeMutation', { value: null, invalid: null });

        commit('change_useOfRopeTensionWeight_storeMutation', false);
        commit('change_ropeTensionWeight_storeMutation', { value: null, invalid: null });

        commit('change_useOfCompensationRope_storeMutation', false);
        commit('change_compensationRopeInPercent_storeMutation', true);
        commit('change_compensationRope_storeMutation', { value: null, invalid: null });
        commit('change_dFactor_storeMutation', { value: null, invalid: null });



        // elevatorMotor_TractionSheave
        commit('change_useOfStandardGrooveDistance_storeMutation', true);
        commit('change_grooveDistance_storeMutation', { value: null, invalid: null });

        commit('change_useStandardNumberOfGrooves_storeMutation', true)
        commit('change_numberOfGrooves_storeMutation', { value: null, invalid: null });

        commit('change_checkedAtLeastOnce_storeMutation', false);

    },


    set_decimalValue({ dispatch }, value) {
        const valueDecimalCriteria = this._vm.$localDecimal;
        valueDecimalCriteria.value = value.value;
        if (value.country == 'de') {
            valueDecimalCriteria.country = 'de-DE';
        }
        else {
            valueDecimalCriteria.country = 'en-US';
        }

        const decimalValue = this._vm.$set_decimalValueToLocaleString(valueDecimalCriteria);

        if (value.name == 'nominalSpeed') {
            dispatch('checkIf_nominalSpeed_isValid_storeAction', decimalValue);
            dispatch('change_tooltip_ropeTensionWeight_storeAction');
            dispatch('change_tooltip_compensationRope_storeAction');
        }
        else if (value.name == 'travelDistance') {
            dispatch('checkIf_travelDistance_isValid_storeAction', decimalValue);
        }
        else if (value.name == 'wrappingAngle') {
            dispatch('checkIf_wrappingAngle_isValid_storeAction', decimalValue);
        }
        else if (value.name == 'ropeDiameter') {
            dispatch('checkIf_ropeDiameter_isValid_storeAction', decimalValue);
            dispatch('change_tooltip_grooveDistance_storeAction');
        }
        else if (value.name == 'cordDiameter') {
            dispatch('checkIf_cordDiameter_isValid_storeAction', decimalValue);
        }
        else if (value.name == 'cableWeightPerMeter') {
            dispatch('checkIf_cableWeightPerMeter_isValid_storeAction', decimalValue);
        }
        else if (value.name == 'ropeTensionWeight') {
            dispatch('checkIf_ropeTensionWeight_isValid_storeAction', decimalValue);
            dispatch('change_tooltip_ropeTensionWeight_storeAction');
            dispatch('change_tooltip_compensationRope_storeAction');
        }
        else if (value.name == 'compensationRope') {
            dispatch('checkIf_compensationRope_isValid_storeAction', decimalValue);
            dispatch('change_tooltip_compensationRope_storeAction');
        }
        else if (value.name == 'grooveDistance') {
            dispatch('checkIf_grooveDistance_isValid_storeAction', decimalValue);
            dispatch('change_tooltip_grooveDistance_storeAction');
        }
        else if (value.name == 'individualSuspensionMeans_breakingForce') {
            dispatch('checkIf_individualSuspensionMeans_breakingForce_isValid_storeAction', decimalValue);
        }
        else if (value.name == 'individualSuspensionMeans_weightPerMeter') {
            dispatch('checkIf_individualSuspensionMeans_weightPerMeter_isValid_storeAction', decimalValue);
        }
        else if (value.name == 'individualSuspensionMeans_ropeDiameter') {
            dispatch('checkIf_individualSuspensionMeans_ropeDiameter_isValid_storeAction', decimalValue);
        }
        else if (value.name == 'dFactor') {
            dispatch('checkIf_dFactor_isValid_storeAction', decimalValue);
        }

    },

    // inputs
    async get_elevatorSystem_construction_suspensionMeans_elevatorMotor_TractionSheave_ValueListsAndSelections_Informations_storeAction({ rootState, commit }) {
        const data = {
            configurationSteps: rootState.configurations.configurationSteps,
        };

        await axios({
            method: 'post',
            url: '/api/inputs/get_elevatorSystem_construction_suspensionMeans_elevatorMotor_TractionSheave_ValueListsAndSelections_Informations',
            data: {
                data: data,
            }
        })
            .then((response) => {
                this.dispatch('statusHandling/change_dataAndStatus_storeAction', response.data)
                if (response.data.status.statusCode.substring(0, 1) == '2') {
                    var mainsVoltageAndMainsFrequency_Selection = [];
                    response.data.data.mainsVoltageAndMainsFrequency_Selection.forEach(i => {
                        var mainsVoltageAndMainsFrequency = i.mainsVoltage + ' V' + ' | ' + i.mainsFrequency + ' Hz';
                        mainsVoltageAndMainsFrequency_Selection.push(mainsVoltageAndMainsFrequency)
                    })

                    var usageCategoryAndAverageTravelTimeAndDutyCycle_Selection = [];
                    response.data.data.usageCategoryAndAverageTravelTimeAndDutyCycle_Selection.forEach(i => {
                        var usageCategoryAndAverageTravelTimeAndDutyCycle = i.usageCategory + ' | ' + i.averageTravelTime + ' h' + ' | ' + i.dutyCycle + ' %';
                        usageCategoryAndAverageTravelTimeAndDutyCycle_Selection.push(usageCategoryAndAverageTravelTimeAndDutyCycle)
                    })

                    response.data.data.mainsVoltageAndMainsFrequency_Selection = mainsVoltageAndMainsFrequency_Selection;
                    response.data.data.usageCategoryAndAverageTravelTimeAndDutyCycle_Selection = usageCategoryAndAverageTravelTimeAndDutyCycle_Selection;

                    commit('change_elevatorSystemAndConstructionAndSuspensionMeansAndElevatorMotor_TractionSheave_ValueListsAndSelections_storeMutation', response.data.data)

                    var configurationSteps = Object.assign({}, rootState.configurations.configurationSteps);
                    var configurationSteps_inputs = Object.assign({}, rootState.configurations.configurationSteps.inputs);
                    configurationSteps_inputs.loaded = true;
                    configurationSteps.inputs = configurationSteps_inputs
                    this.dispatch('configurations/change_configurationSteps_storeAction', configurationSteps)

                }
            })
            .catch(() => { })
    },
    set_inputs_storeAction({ commit, dispatch }, value) {
        // elevatorSystem
        commit('change_nominalLoad_storeMutation', { value: value.elevatorSystem.nominalLoad, invalid: false })
        commit('change_nominalSpeed_storeMutation', { value: value.elevatorSystem.nominalSpeed, invalid: false })
        commit('change_travelDistance_storeMutation', { value: value.elevatorSystem.travelDistance, invalid: false })
        commit('change_carWeight_storeMutation', { value: value.elevatorSystem.carWeight, invalid: false })
        commit('change_counterweightInPercent_storeMutation', value.elevatorSystem.counterweightInPercent)
        commit('change_counterweight_storeMutation', { value: value.elevatorSystem.counterweight, invalid: false })
        commit('change_mainsVoltage_storeMutation', value.elevatorSystem.mainsVoltage);
        commit('change_mainsFrequency_storeMutation', value.elevatorSystem.mainsFrequency);
        commit('change_usageCategory_storeMutation', value.elevatorSystem.usageCategory);
        commit('change_averageTravelTime_storeMutation', value.elevatorSystem.averageTravelTime);
        commit('change_dutyCycle_storeMutation', value.elevatorSystem.dutyCycle);
        commit('change_startsPerHour_storeMutation', {value: value.elevatorSystem.startsPerHour, invalid: false})

        dispatch('checkIf_counterweightDisplay_canBeCalculated_storeAction');

        // construction
        const firstIndex_whichMeetConditions = state.locationOfMachine_Selection.findIndex(i => ((i.verticalPositionOfMachine.text == value.construction.verticalPositionOfMachine) && (i.detailedPositionOfMachine.text == value.construction.detailedPositionOfMachine) && (i.footDirectionOfMachine == value.construction.footDirectionOfMachine) && (i.useOfPulleySheave == value.construction.useOfPulleySheave)))
        if (firstIndex_whichMeetConditions > -1) {
            commit('change_Selected_at_locationOfMachine_Selection_storeMutation', firstIndex_whichMeetConditions);
            dispatch('filter_locationOfMachine_Selection_by_selected_verticalPositionOfMachineAndDetailedPositionOfMachine_storeAction');
        }
        else {
            // error
        }

        commit('change_designType_storeMutation', value.construction.designType);
        commit('change_suspensionRatio1_storeMutation', { value: value.construction.suspensionRatio1, invalid: false });
        commit('change_numberOfPulleysOnCarSide_storeMutation', { value: value.construction.numberOfPulleysOnCarSide, invalid: false });
        commit('change_numberOfPulleysOnCounterweightSide_storeMutation', { value: value.construction.numberOfPulleysOnCounterweightSide, invalid: false });
        commit('change_calculatedWithDoublewrap_storeMutation', value.suspensionMeans.calculatedWithDoublewrap);
        commit('change_wrappingAngle_Calculated_storeMutation', value.suspensionMeans.wrappingAngleCalculated);
        commit('change_wrappingAngle_storeMutation', { value: value.suspensionMeans.wrappingAngle, invalid: false });
        commit('change_useFrame_storeMutation', value.construction.useFrame);

        // suspensionMeans
        commit('change_individualSuspensionMeans_storeMutation', value.suspensionMeans.individualSuspensionMeans);

        if (state.individualSuspensionMeans == false) {
            const firstIndex_whichMeetCondition = state.suspensionMeans_Selection.findIndex(i => i.suspensionMeans.blockNumber == value.suspensionMeans.blockNumber);
            if (firstIndex_whichMeetCondition > -1) {
                commit('change_Selected_at_suspensionMeans_Selection_storeMutation', firstIndex_whichMeetCondition);

                if (state.suspensionMeans_Selection[firstIndex_whichMeetCondition].suspensionMeans.typeOfSuspensionMeans.text == 'rope') {
                    commit('change_typeOfSuspensionMeans_storeMutation', 'rope');
                    commit('change_ropeManufacturer_storeMutation', state.suspensionMeans_Selection[firstIndex_whichMeetCondition].suspensionMeans.manufacturer);
                    commit('change_ropeType_storeMutation', state.suspensionMeans_Selection[firstIndex_whichMeetCondition].suspensionMeans.type);
                    commit('change_ropeDiameter_storeMutation', { value: state.suspensionMeans_Selection[firstIndex_whichMeetCondition].suspensionMeans.ropeDiameter, invalid: false });
                    dispatch('change_tooltip_grooveDistance_storeAction');
                }
                else if (state.suspensionMeans_Selection[firstIndex_whichMeetCondition].suspensionMeans.typeOfSuspensionMeans.text == 'belt') {
                    commit('change_typeOfSuspensionMeans_storeMutation', 'belt');
                    commit('change_beltManufacturer_storeMutation', state.suspensionMeans_Selection[firstIndex_whichMeetCondition].suspensionMeans.manufacturer);
                    commit('change_beltType_storeMutation', state.suspensionMeans_Selection[firstIndex_whichMeetCondition].suspensionMeans.type);
                    commit('change_cordDiameter_storeMutation', { value: state.suspensionMeans_Selection[firstIndex_whichMeetCondition].suspensionMeans.cordDiameter, invalid: false });
                }
                else {
                    // error
                }
            }
            else {
                // error
            }
        }
        else if (state.individualSuspensionMeans == true) {
            commit('change_individualSuspensionMeans_breakingForce_storeMutation', { value: value.suspensionMeans.breakingForce, invalid: false });
            commit('change_individualSuspensionMeans_weightPerMeter_storeMutation', { value: value.suspensionMeans.weightPerMeter, invalid: false });
            commit('change_typeOfSuspensionMeans_storeMutation', value.suspensionMeans.typeOfSuspensionMeans);
            if (state.typeOfSuspensionMeans == 'rope') {
                commit('change_individualSuspensionMeans_ropeDiameter_storeMutation', { value: value.suspensionMeans.ropeDiameter, invalid: false });
            }
            else if (state.typeOfSuspensionMeans == 'belt') {
                commit('change_individualSuspensionMeans_cordDiameter_storeMutation', { value: value.suspensionMeans.cordDiameter, invalid: false });
            }
            else {
                // error
            }

        }
        else {
            // error
        }

        commit('change_numberOfSuspensionMeans_storeMutation', { value: value.suspensionMeans.numberOfSuspensionMeans, invalid: false });

        commit('change_weight_storeMutation', { value: value.suspensionMeans.weight, canBeCalculated: true, calculatedSuccessfully: true });
        commit('change_safetyFactor_storeMutation', { value: value.suspensionMeans.safetyFactor, canBeCalculated: true, calculatedSuccessfully: true });

        commit('change_cableWeightPerMeter_storeMutation', { value: value.suspensionMeans.cableWeightPerMeter, invalid: false });

        commit('change_useOfRopeTensionWeight_storeMutation', value.suspensionMeans.useOfRopeTensionWeight);
        commit('change_ropeTensionWeight_storeMutation', { value: value.suspensionMeans.ropeTensionWeight, invalid: false });

        commit('change_useOfCompensationRope_storeMutation', value.suspensionMeans.useOfCompensationRope);
        commit('change_compensationRopeInPercent_storeMutation', value.suspensionMeans.compensationRopeInPercent);
        commit('change_compensationRope_storeMutation', { value: value.suspensionMeans.compensationRope, invalid: false });
        commit('change_dFactor_storeMutation', { value: value.suspensionMeans.dFactor, invalid: false });

        // elevatorMotor_TractionSheave

        commit('change_useOfStandardGrooveDistance_storeMutation', value.elevatorMotor_TractionSheave.useOfStandardGrooveDistance);

        if (value.elevatorMotor_TractionSheave.useOfStandardGrooveDistance == false) {
            commit('change_grooveDistance_storeMutation', { value: value.elevatorMotor_TractionSheave.grooveDistance, invalid: false });
        }

        commit('change_useStandardNumberOfGrooves_storeMutation', value.elevatorMotor_TractionSheave.useStandardNumberOfGrooves);

        if (value.elevatorMotor_TractionSheave.useStandardNumberOfGrooves != null && value.elevatorMotor_TractionSheave.useStandardNumberOfGrooves == false) {
            commit('change_numberOfGrooves_storeMutation', { value: value.elevatorMotor_TractionSheave.numberOfGrooves, invalid: false });
        }
    },
    set_defaultValues_at_inputs_storeAction({ dispatch }) {
        // elevatorSystem
        if (state.counterweightInPercent == true) {
            dispatch('checkIf_counterweight_isValid_storeAction', 50)
        }
        if (state.mainsVoltageAndMainsFrequency_Selection != null) {
            dispatch('checkIf_mainsVoltageAndMainsFrequency_isValid_storeAction', state.mainsVoltageAndMainsFrequency_Selection[0]);
        }
        if (state.usageCategoryAndAverageTravelTimeAndDutyCycle_Selection != null) {
            dispatch('checkIf_usageCategoryAndAverageTravelTimeAndDutyCycle_isValid_storeAction', state.usageCategoryAndAverageTravelTimeAndDutyCycle_Selection[4]);
        }
        dispatch('checkIf_startsPerHour_isValid_storeAction', 180);

        // construction
        dispatch('checkIf_wrappingAngle_isValid_storeAction', 150);
        dispatch('checkIf_cableWeightPerMeter_isValid_storeAction', 1);
        dispatch('checkIf_dFactor_isValid_storeAction', 1);
    },

    checkIf_changesAt_inputs_changes_configurationFlow_storeAction({ rootState }) {
        if (rootState.configurations.configurationSteps.inputs.storedInDatabase == true) {
            var configurationSteps = Object.assign({}, rootState.configurations.configurationSteps);
            var configurationSteps_inputs = Object.assign({}, rootState.configurations.configurationSteps.inputs);
            configurationSteps_inputs.storedInDatabase = false;
            configurationSteps.inputs = configurationSteps_inputs
            this.dispatch('configurations/change_configurationSteps_storeAction', configurationSteps)

            this.dispatch('configurations/selectAndInsert_or_updateAndOrDelete_databaseRecords_storeAction', { changesAt: this._vm.$configurationSteps.inputs });
        }
    },

    checkIf_inputs_areValid_storeAction({ commit, dispatch }) {
        commit('change_checkedAtLeastOnce_storeMutation', true);

        var numberOf_inputs_whichAreInvalid = 0;

        // elevatorSystem
        if (state.nominalLoad.value == null) {
            dispatch('checkIf_nominalLoad_isValid_storeAction', null);
        }
        if (state.nominalLoad.invalid == true) {
            numberOf_inputs_whichAreInvalid = numberOf_inputs_whichAreInvalid + 1;
        }

        if (state.nominalSpeed.value == null) {
            dispatch('checkIf_nominalSpeed_isValid_storeAction', null);
        }
        if (state.nominalSpeed.invalid == true) {
            numberOf_inputs_whichAreInvalid = numberOf_inputs_whichAreInvalid + 1;
        }

        if (state.travelDistance.value == null) {
            dispatch('checkIf_travelDistance_isValid_storeAction', null);
        }
        if (state.travelDistance.invalid == true) {
            numberOf_inputs_whichAreInvalid = numberOf_inputs_whichAreInvalid + 1;
        }

        if (state.carWeight.value == null) {
            dispatch('checkIf_carWeight_isValid_storeAction', null);
        }
        if (state.carWeight.invalid == true) {
            numberOf_inputs_whichAreInvalid = numberOf_inputs_whichAreInvalid + 1;
        }

        if (state.counterweight.value == null) {
            dispatch('checkIf_counterweight_isValid_storeAction', null);
        }
        if (state.counterweight.invalid == true) {
            numberOf_inputs_whichAreInvalid = numberOf_inputs_whichAreInvalid + 1;
        }

        if ((state.mainsVoltage == null) || (state.mainsFrequency == null)) {
            dispatch('checkIf_mainsVoltageAndMainsFrequency_isValid_storeAction', null);
        }
        if (state.mainsVoltageAndMainsFrequency.invalid == true) {
            numberOf_inputs_whichAreInvalid = numberOf_inputs_whichAreInvalid + 1;
        }

        if ((state.usageCategory == null) || (state.averageTravelTime == null) || (state.dutyCycle == null)) {
            dispatch('checkIf_usageCategoryAndAverageTravelTimeAndDutyCycle_isValid_storeAction', null);
        }
        if (state.usageCategoryAndAverageTravelTimeAndDutyCycle.invalid == true) {
            numberOf_inputs_whichAreInvalid = numberOf_inputs_whichAreInvalid + 1;
        }

        if (state.startsPerHour.value == null) {
            dispatch('checkIf_startsPerHour_isValid_storeAction', null);
        }
        if (state.startsPerHour.invalid == true) {
            numberOf_inputs_whichAreInvalid = numberOf_inputs_whichAreInvalid + 1;
        }

        // construction
        if (state.suspensionRatio1.value == null) {
            dispatch('checkIf_suspensionRatio1_isValid_storeAction', null);
        }
        if (state.suspensionRatio1.invalid == true) {
            numberOf_inputs_whichAreInvalid = numberOf_inputs_whichAreInvalid + 1;
        }

        if (state.numberOfPulleysOnCarSide.value == null) {
            dispatch('checkIf_numberOfPulleysOnCarSide_isValid_storeAction', null);
        }
        if (state.numberOfPulleysOnCarSide.invalid == true) {
            numberOf_inputs_whichAreInvalid = numberOf_inputs_whichAreInvalid + 1;
        }

        if (state.numberOfPulleysOnCounterweightSide.value == null) {
            dispatch('checkIf_numberOfPulleysOnCounterweightSide_isValid_storeAction', null);
        }
        if (state.numberOfPulleysOnCounterweightSide.invalid == true) {
            numberOf_inputs_whichAreInvalid = numberOf_inputs_whichAreInvalid + 1;
        }

        if ((state.calculatedWithDoublewrap == false) && (state.wrappingAngle_Calculated == false) && (state.wrappingAngle.value == null)) {
            dispatch('checkIf_wrappingAngle_isValid_storeAction', null);
        }
        if ((state.calculatedWithDoublewrap == false) && (state.wrappingAngle_Calculated == false) && (state.wrappingAngle.invalid == true)) {
            numberOf_inputs_whichAreInvalid = numberOf_inputs_whichAreInvalid + 1;
        }


        // suspensionMeans
        if ((state.individualSuspensionMeans == false) && (((state.typeOfSuspensionMeans == 'rope') && ((state.ropeManufacturer == null) || (state.ropeType == null))) || ((state.typeOfSuspensionMeans == 'belt') && ((state.beltManufacturer == null) || (state.beltType == null))))) {
            dispatch('checkIf_suspensionMeansManufacturerAndType_isValid_storeAction', null);
        }
        if ((state.individualSuspensionMeans == false) && (state.suspensionMeansManufacturerAndType.invalid == true)) {
            numberOf_inputs_whichAreInvalid = numberOf_inputs_whichAreInvalid + 1;
        }

        if ((state.individualSuspensionMeans == false) && (state.typeOfSuspensionMeans == 'rope') && (state.ropeDiameter.value == null)) {
            dispatch('checkIf_ropeDiameter_isValid_storeAction', null);
        }
        if ((state.individualSuspensionMeans == false) && (state.typeOfSuspensionMeans == 'rope') && (state.ropeDiameter.invalid == true)) {
            numberOf_inputs_whichAreInvalid = numberOf_inputs_whichAreInvalid + 1;
        }

        if ((state.individualSuspensionMeans == false) && (state.typeOfSuspensionMeans == 'belt') && (state.cordDiameter.value == null)) {
            dispatch('checkIf_cordDiameter_isValid_storeAction', null);
        }
        if ((state.individualSuspensionMeans == false) && (state.typeOfSuspensionMeans == 'belt') && (state.cordDiameter.invalid == true)) {
            numberOf_inputs_whichAreInvalid = numberOf_inputs_whichAreInvalid + 1;
        }

        if ((state.individualSuspensionMeans == true) && (state.individualSuspensionMeans_breakingForce.value == null)) {
            dispatch('checkIf_individualSuspensionMeans_breakingForce_isValid_storeAction', null);
        }
        if ((state.individualSuspensionMeans == true) && (state.individualSuspensionMeans_breakingForce.invalid == true)) {
            numberOf_inputs_whichAreInvalid = numberOf_inputs_whichAreInvalid + 1;
        }

        if ((state.individualSuspensionMeans == true) && (state.individualSuspensionMeans_weightPerMeter.value == null)) {
            dispatch('checkIf_individualSuspensionMeans_weightPerMeter_isValid_storeAction', null);
        }
        if ((state.individualSuspensionMeans == true) && (state.individualSuspensionMeans_weightPerMeter.invalid == true)) {
            numberOf_inputs_whichAreInvalid = numberOf_inputs_whichAreInvalid + 1;
        }

        if ((state.individualSuspensionMeans == true) && (state.typeOfSuspensionMeans == 'rope') && (state.individualSuspensionMeans_ropeDiameter.value == null)) {
            dispatch('checkIf_individualSuspensionMeans_ropeDiameter_isValid_storeAction', null);
        }
        if ((state.individualSuspensionMeans == true) && (state.typeOfSuspensionMeans == 'rope') && (state.individualSuspensionMeans_ropeDiameter.invalid == true)) {
            numberOf_inputs_whichAreInvalid = numberOf_inputs_whichAreInvalid + 1;
        }

        if ((state.individualSuspensionMeans == true) && (state.typeOfSuspensionMeans == 'belt') && (state.individualSuspensionMeans_cordDiameter.value == null)) {
            dispatch('checkIf_individualSuspensionMeans_cordDiameter_isValid_storeAction', null);
        }
        if ((state.individualSuspensionMeans == true) && (state.typeOfSuspensionMeans == 'belt') && (state.individualSuspensionMeans_cordDiameter.invalid == true)) {
            numberOf_inputs_whichAreInvalid = numberOf_inputs_whichAreInvalid + 1;
        }


        if (state.numberOfSuspensionMeans.value == null) {
            dispatch('checkIf_numberOfSuspensionMeans_isValid_storeAction', null);
        }
        if (state.numberOfSuspensionMeans.invalid == true) {
            numberOf_inputs_whichAreInvalid = numberOf_inputs_whichAreInvalid + 1;
        }

        if (state.cableWeightPerMeter.value == null) {
            dispatch('checkIf_cableWeightPerMeter_isValid_storeAction', null);
        }
        if (state.cableWeightPerMeter.invalid == true) {
            numberOf_inputs_whichAreInvalid = numberOf_inputs_whichAreInvalid + 1;
        }

        if ((state.useOfRopeTensionWeight == true) && (state.ropeTensionWeight.value == null)) {
            dispatch('checkIf_ropeTensionWeight_isValid_storeAction', null);
        }
        if ((state.useOfRopeTensionWeight == true) && (state.ropeTensionWeight.invalid == true)) {
            numberOf_inputs_whichAreInvalid = numberOf_inputs_whichAreInvalid + 1;
        }

        if ((state.useOfCompensationRope == true) && (state.compensationRope.value == null)) {
            dispatch('checkIf_compensationRope_isValid_storeAction', null);
        }
        if ((state.useOfCompensationRope == true) && (state.compensationRope.invalid == true)) {
            numberOf_inputs_whichAreInvalid = numberOf_inputs_whichAreInvalid + 1;
        }

        if (state.dFactor == null) {
            dispatch('checkIf_dFactor_isValid_storeAction', null);
        }

        // elevatorMotor_TractionSheave
        if ((state.useOfStandardGrooveDistance == false) && (state.grooveDistance.value == null)) {
            dispatch('checkIf_grooveDistance_isValid_storeAction', null);
        }
        if ((state.useOfStandardGrooveDistance == false) && (state.grooveDistance.invalid == true)) {
            numberOf_inputs_whichAreInvalid = numberOf_inputs_whichAreInvalid + 1;
        }

        if ((state.useStandardNumberOfGrooves == false) && (state.numberOfGrooves.value == null)) {
            dispatch('checkIf_numberOfGrooves_isValid_storeAction', null);
        }
        if ((state.useStandardNumberOfGrooves == false) && (state.numberOfGrooves.invalid == true)) {
            numberOf_inputs_whichAreInvalid = numberOf_inputs_whichAreInvalid + 1;
        }



        if (numberOf_inputs_whichAreInvalid > 0) {
            this.dispatch('statusHandling/change_dataAndStatus_storeAction', { status: { statusCode: '7200001', statusInContextOf: 'checkIf_inputs_areValid_storeAction', statusInformations: [{ textKey: 1446 }, { textKey: 1449 }], statusModalClosingOptions: [{ displayedDesignation: { textKey: 383 }, closingOption: 'close' }] } })
        }
        else if (numberOf_inputs_whichAreInvalid == 0) {
            dispatch('check_inputs_byCalculationDll_storeAction');
        }
    },
    check_inputs_byCalculationDll_storeAction({ rootState, dispatch }) {
        var blockNumber = null;
        var manufacturer = null;
        var type = null;
        var ropeDiameter = null;
        var cordDiameter = null;
        var beltWidth = null;
        var breakingForce = null;
        var weightPerMeter = null;
        var ropeDiameter2 = null;
        var certificateDescription = null;
        var compensationRopeInPercent = state.compensationRopeInPercent;
        var wrappingAngle = null;
        var ropeTensionWeight = null;
        var compensationRope = null;
        var grooveDistance = null;

        if (state.useOfCompensationRope == false) {
            compensationRopeInPercent = null;
        }

        const selected_locationOfMachine = state.locationOfMachine_Selection.find(i => i.selected == true);

        if (state.individualSuspensionMeans == false) {
            const selected_suspensionMeans = state.suspensionMeans_Selection.find(i => i.selected == true);
            blockNumber = selected_suspensionMeans.suspensionMeans.blockNumber;
            manufacturer = selected_suspensionMeans.suspensionMeans.manufacturer;
            type = selected_suspensionMeans.suspensionMeans.type;
            ropeDiameter = selected_suspensionMeans.suspensionMeans.ropeDiameter;
            ropeDiameter2 = selected_suspensionMeans.suspensionMeans.ropeDiameter2;
            cordDiameter = selected_suspensionMeans.suspensionMeans.cordDiameter;
            beltWidth = selected_suspensionMeans.suspensionMeans.beltWidth;
            certificateDescription = selected_suspensionMeans.suspensionMeans.certificateDescription;
            breakingForce = selected_suspensionMeans.suspensionMeans.breakingForce;
            weightPerMeter = selected_suspensionMeans.suspensionMeans.weightPerMeter;
        }
        else if (state.individualSuspensionMeans == true) {
            blockNumber = null;
            manufacturer = null;
            type = null;
            if (state.typeOfSuspensionMeans == 'rope') {
                ropeDiameter = state.individualSuspensionMeans_ropeDiameter.value;
                cordDiameter = null;
            }
            else if (state.typeOfSuspensionMeans == 'belt') {
                ropeDiameter = null;
                cordDiameter = state.individualSuspensionMeans_cordDiameter.value;
            }
            breakingForce = state.individualSuspensionMeans_breakingForce.value;
            weightPerMeter = state.individualSuspensionMeans_weightPerMeter.value;
        }

        if (state.wrappingAngle_Calculated == false) {
            wrappingAngle = state.wrappingAngle.value;
        }
        if (state.useOfCompensationRope == true) {
            compensationRope = state.compensationRope.value;
        }
        if (state.useOfRopeTensionWeight == true) {
            ropeTensionWeight = state.ropeTensionWeight.value;
        }
        if (state.useOfStandardGrooveDistance == false) {
            grooveDistance = state.grooveDistance.value;
        }



        const elevatorSystem = {
            nominalLoad: state.nominalLoad.value,
            nominalSpeed: state.nominalSpeed.value,
            travelDistance: state.travelDistance.value,
            carWeight: state.carWeight.value,
            counterweightInPercent: state.counterweightInPercent,
            counterweight: state.counterweight.value,
            mainsVoltage: state.mainsVoltage,
            mainsFrequency: state.mainsFrequency,
            usageCategory: state.usageCategory,
            averageTravelTime: state.averageTravelTime,
            dutyCycle: state.dutyCycle,
            startsPerHour: state.startsPerHour.value,
        }

        const construction = {
            verticalPositionOfMachine: selected_locationOfMachine.verticalPositionOfMachine.text,
            detailedPositionOfMachine: selected_locationOfMachine.detailedPositionOfMachine.text,
            footDirectionOfMachine: selected_locationOfMachine.footDirectionOfMachine,
            useOfPulleySheave: selected_locationOfMachine.useOfPulleySheave,
            designType: state.designType,
            suspensionRatio1: state.suspensionRatio1.value,
            numberOfPulleysOnCarSide: state.numberOfPulleysOnCarSide.value,
            numberOfPulleysOnCounterweightSide: state.numberOfPulleysOnCounterweightSide.value,
            useFrame: state.useFrame,
        }

        const suspensionMeans = {
            blockNumber: blockNumber,
            typeOfSuspensionMeans: state.typeOfSuspensionMeans,
            manufacturer: manufacturer,
            type: type,
            ropeDiameter: ropeDiameter,
            ropeDiameter2: ropeDiameter2,
            cordDiameter: cordDiameter,
            beltWidth: beltWidth,
            individualSuspensionMeans: state.individualSuspensionMeans,
            breakingForce: breakingForce,
            weightPerMeter: weightPerMeter,
            weight: state.weight.value,
            safetyFactor: state.safetyFactor.value,
            certificateDescription: certificateDescription,
            numberOfSuspensionMeans: state.numberOfSuspensionMeans.value,
            cableWeightPerMeter: state.cableWeightPerMeter.value,
            useOfRopeTensionWeight: state.useOfRopeTensionWeight,
            ropeTensionWeight: ropeTensionWeight,
            useOfCompensationRope: state.useOfCompensationRope,
            compensationRopeInPercent: compensationRopeInPercent,
            compensationRope: compensationRope,
            calculatedWithDoublewrap: state.calculatedWithDoublewrap,
            wrappingAngleCalculated: state.wrappingAngle_Calculated,
            wrappingAngle: wrappingAngle,
            dFactor: state.dFactor.value,
        }
        const elevatorMotor_TractionSheave = {
            useOfStandardGrooveDistance: state.useOfStandardGrooveDistance,
            grooveDistance: grooveDistance,
            useStandardNumberOfGrooves: state.useStandardNumberOfGrooves,
            numberOfGrooves: state.numberOfGrooves.value,
        }

        const data = {
            elevatorSystem: elevatorSystem,
            construction: construction,
            suspensionMeans: suspensionMeans,
            elevatorMotor_TractionSheave: elevatorMotor_TractionSheave,
        }
        axios({
            method: 'post',
            url: '/api/inputs/check_inputs_byCalculationDll',
            data: {
                data: data,
                status: rootState.statusHandling.status,
            }
        })
            .then((response) => {
                this.dispatch('statusHandling/change_dataAndStatus_storeAction', response.data)

                if ((response.data.status.statusCode.substring(0, 1) == '2') || (response.data.status.statusCode.substring(0, 2) == '74') || (response.data.status.statusCode.substring(0, 2) == '75')) {
                    dispatch('insert_databaseRecords_at_elevatorSystems_and_constructions_and_suspensionMeans_and_elevatorMotor_TractionSheaves_storeAction', response.data.data);
                }
            })
            .catch(() => { })
    },

    insert_databaseRecords_at_elevatorSystems_and_constructions_and_suspensionMeans_and_elevatorMotor_TractionSheaves_storeAction({ rootState }, value) {
        var configurationSteps_Inputs_StoredInDatabase = false;
        if (rootState.configurations.configurationSteps.inputs.storedInDatabase == true) {
            configurationSteps_Inputs_StoredInDatabase = true;
        }

        value.configurationSteps = rootState.configurations.configurationSteps;
        value.inputdFactor_Disabled = state.inputdFactor_Disabled;

        axios({
            method: 'post',
            url: '/api/inputs/insert_databaseRecords_at_elevatorSystems_and_constructions_and_suspensionMeans_and_elevatorMotor_TractionSheaves',
            data: {
                data: value,
            }
        })
            .then(async (response) => {
                this.dispatch('statusHandling/change_dataAndStatus_storeAction', response.data);

                if (response.data.status.statusCode.substring(0, 1) == '2') {
                    this.dispatch('configurations/set_configurationOverviewsId_at_sessionStorage_storeAction', response.data.data.configurationOverviewsId)
                    this.dispatch('configurations/change_configurationSteps_storeAction', response.data.data.configurationSteps);

                    if ((rootState.configurations.note.invalid == false) && (configurationSteps_Inputs_StoredInDatabase == false) && (response.data.data.configurationSteps.inputs.storedInDatabase == true)) {
                        await this.dispatch('configurations/update_databaseRecord_at_configurationOverviews_bySetting_Note_storeAction', rootState.configurations.note.value)
                        if ((rootState.statusHandling.status.statusInContextOf == 'update_databaseRecord_at_configurationOverviews_bySetting_Note') && (rootState.statusHandling.status.statusCode.startsWith('2'))) {
                            router.push({ params: { slug: 'driveTechnologies' } })
                        }
                    }
                    else {
                        router.push({ params: { slug: 'driveTechnologies' } })
                    }

                }
            })
            .catch(() => { })
    },


    // elevatorSystem
    checkIf_nominalLoad_isValid_storeAction({ dispatch }, value) {
        const valueAndValueValidationCriteria = this._vm.$transmit_valueAndValueValidationCriteria;
        valueAndValueValidationCriteria.value = value;
        valueAndValueValidationCriteria.dataType = this._vm.$dataTypes.integer;
        valueAndValueValidationCriteria.permissibleNumberOfDecimalPlaces = null;
        valueAndValueValidationCriteria.permissibleValueRange.minimum.value = 0;
        valueAndValueValidationCriteria.permissibleValueRange.minimum.operator = this._vm.$operators.greaterThan;
        valueAndValueValidationCriteria.permissibleValueRange.maximum.value = 20000;
        valueAndValueValidationCriteria.permissibleValueRange.maximum.operator = this._vm.$operators.lessThan;
        const nominalLoad_ValidationResult = this._vm.$checkIf_value_isValid(valueAndValueValidationCriteria);

        dispatch('change_nominalLoad_storeAction', nominalLoad_ValidationResult)
    },
    change_nominalLoad_storeAction({ commit, dispatch }, nominalLoad_ValidationResult) {
        commit('change_nominalLoad_storeMutation', nominalLoad_ValidationResult);
        dispatch('checkIf_suspensionMeans_SafetyFactor_canBeCalculated_storeAction');
        dispatch('checkIf_changesAt_inputs_changes_configurationFlow_storeAction');
        dispatch('checkIf_counterweightDisplay_canBeCalculated_storeAction');
    },

    checkIf_nominalSpeed_isValid_storeAction({ dispatch }, value) {
        const valueAndValueValidationCriteria = this._vm.$transmit_valueAndValueValidationCriteria;
        valueAndValueValidationCriteria.value = value;
        valueAndValueValidationCriteria.dataType = this._vm.$dataTypes.decimal;
        valueAndValueValidationCriteria.permissibleNumberOfDecimalPlaces = 2;
        valueAndValueValidationCriteria.permissibleValueRange.minimum.value = 0;
        valueAndValueValidationCriteria.permissibleValueRange.minimum.operator = this._vm.$operators.greaterThan;
        valueAndValueValidationCriteria.permissibleValueRange.maximum.value = 3.5;
        valueAndValueValidationCriteria.permissibleValueRange.maximum.operator = this._vm.$operators.lessThanOrEqualTo;
        const nominalSpeed_ValidationResult = this._vm.$checkIf_value_isValid(valueAndValueValidationCriteria);
        dispatch('change_nominalSpeed_storeAction', nominalSpeed_ValidationResult);
    },
    change_nominalSpeed_storeAction({ commit, dispatch }, nominalSpeed_ValidationResult) {
        commit('change_nominalSpeed_storeMutation', nominalSpeed_ValidationResult);
        dispatch('checkIf_changesAt_inputs_changes_configurationFlow_storeAction')
        if (state.usageCategoryAndAverageTravelTimeAndDutyCycle_Selection != null && state.nominalSpeed != null && state.nominalSpeed.value != null && state.travelDistance != null && state.travelDistance.value != null && state.usageCategoryAndAverageTravelTimeAndDutyCycle != null && state.usageCategoryAndAverageTravelTimeAndDutyCycle.value != null) {
            dispatch('get_usageCategoryAndAverageTravelTimeAndDutyCycle_storeAction');
        }
    },
    change_nominalSpeed_ValueList_storeAction({ commit }, nominalSpeedValueList) {
        commit('change_nominalSpeed_ValueList_storeMutation', nominalSpeedValueList);
    },

    checkIf_travelDistance_isValid_storeAction({ dispatch }, value) {
        const valueAndValueValidationCriteria = this._vm.$transmit_valueAndValueValidationCriteria;
        valueAndValueValidationCriteria.value = value;
        valueAndValueValidationCriteria.dataType = this._vm.$dataTypes.decimal;
        valueAndValueValidationCriteria.permissibleNumberOfDecimalPlaces = 2;
        valueAndValueValidationCriteria.permissibleValueRange.minimum.value = 0;
        valueAndValueValidationCriteria.permissibleValueRange.minimum.operator = this._vm.$operators.greaterThan;
        valueAndValueValidationCriteria.permissibleValueRange.maximum.value = null;
        valueAndValueValidationCriteria.permissibleValueRange.maximum.operator = null;
        const travelDistance_ValidationResult = this._vm.$checkIf_value_isValid(valueAndValueValidationCriteria);

        dispatch('change_travelDistance_storeAction', travelDistance_ValidationResult);
    },
    change_travelDistance_storeAction({ commit, dispatch }, travelDistance_ValidationResult) {
        commit('change_travelDistance_storeMutation', travelDistance_ValidationResult);
        dispatch('checkIf_suspensionMeans_Weight_canBeCalculated_storeAction');
        dispatch('checkIf_suspensionMeans_SafetyFactor_canBeCalculated_storeAction');
        dispatch('checkIf_changesAt_inputs_changes_configurationFlow_storeAction')
        if (state.usageCategoryAndAverageTravelTimeAndDutyCycle_Selection != null && state.nominalSpeed != null && state.nominalSpeed.value != null && state.travelDistance != null && state.travelDistance.value != null && state.usageCategoryAndAverageTravelTimeAndDutyCycle != null && state.usageCategoryAndAverageTravelTimeAndDutyCycle.value != null) {
            dispatch('get_usageCategoryAndAverageTravelTimeAndDutyCycle_storeAction');
        }
    },

    change_travelDistance_ValueList_storeAction({ commit }, valueList) {
        commit('change_travelDistance_ValueList_storeMutation', valueList);
    },

    checkIf_carWeight_isValid_storeAction({ dispatch }, value) {
        const valueAndValueValidationCriteria = this._vm.$transmit_valueAndValueValidationCriteria;
        valueAndValueValidationCriteria.value = value;
        valueAndValueValidationCriteria.dataType = this._vm.$dataTypes.integer;
        valueAndValueValidationCriteria.permissibleNumberOfDecimalPlaces = null;
        valueAndValueValidationCriteria.permissibleValueRange.minimum.value = 0;
        valueAndValueValidationCriteria.permissibleValueRange.minimum.operator = this._vm.$operators.greaterThan;
        valueAndValueValidationCriteria.permissibleValueRange.maximum.value = 20000;
        valueAndValueValidationCriteria.permissibleValueRange.maximum.operator = this._vm.$operators.lessThan;
        const carWeight_ValidationResult = this._vm.$checkIf_value_isValid(valueAndValueValidationCriteria);

        dispatch('change_carWeight_storeAction', carWeight_ValidationResult);
    },
    change_carWeight_storeAction({ commit, dispatch }, carWeight_ValidationResult) {
        commit('change_carWeight_storeMutation', carWeight_ValidationResult);
        dispatch('checkIf_suspensionMeans_SafetyFactor_canBeCalculated_storeAction');
        dispatch('checkIf_changesAt_inputs_changes_configurationFlow_storeAction');
        dispatch('checkIf_counterweightDisplay_canBeCalculated_storeAction');
    },

    change_counterweightInPercent_storeAction({ rootState, commit, dispatch }, value) {
        commit('change_counterweightInPercent_storeMutation', value)

        if ((state.checkedAtLeastOnce == true) || (rootState.configurations.configurationSteps.inputs.storedInDatabase == true)) {
            dispatch('checkIf_counterweight_isValid_storeAction', null)
        }
        else {
            dispatch('change_counterweight_storeAction', { value: null, invalid: null })
        }
        dispatch('checkIf_counterweightDisplay_canBeCalculated_storeAction');
    },
    checkIf_counterweight_isValid_storeAction({ dispatch }, value) {
        const valueAndValueValidationCriteria = this._vm.$transmit_valueAndValueValidationCriteria;
        valueAndValueValidationCriteria.value = value;
        valueAndValueValidationCriteria.dataType = this._vm.$dataTypes.integer;
        valueAndValueValidationCriteria.permissibleNumberOfDecimalPlaces = null;
        valueAndValueValidationCriteria.permissibleValueRange.minimum.value = 0;
        valueAndValueValidationCriteria.permissibleValueRange.minimum.operator = this._vm.$operators.greaterThan;
        valueAndValueValidationCriteria.permissibleValueRange.maximum.value = null;
        valueAndValueValidationCriteria.permissibleValueRange.maximum.operator = null;
        const counterweight_ValidationResult = this._vm.$checkIf_value_isValid(valueAndValueValidationCriteria);

        dispatch('change_counterweight_storeAction', counterweight_ValidationResult);
    },
    change_counterweight_storeAction({ commit, dispatch }, counterweight_ValidationResult) {
        commit('change_counterweight_storeMutation', counterweight_ValidationResult);
        dispatch('checkIf_changesAt_inputs_changes_configurationFlow_storeAction')
        dispatch('checkIf_counterweightDisplay_canBeCalculated_storeAction');
    },

    checkIf_counterweightDisplay_canBeCalculated_storeAction({ commit, dispatch }) {
        if ((state.nominalLoad.invalid == false) && (state.nominalLoad.value != null) && (state.carWeight.invalid == false) && (state.carWeight.value != null) && (state.counterweight.invalid == false) && (state.counterweight.value != null) && (state.counterweightInPercent == true)) {
            dispatch('get_calculated_counterweightDisplay_storeAction');
        }
        else {
            commit('change_counterweightDisplay_storeMutation', '?');
        }
    },

    get_calculated_counterweightDisplay_storeAction({ commit }) {
        const data = {
            nominalLoad: state.nominalLoad.value,
            carWeight: state.carWeight.value,
            counterweight: state.counterweight.value,
            counterweightInPercent: state.counterweightInPercent,
        }

        axios({
            method: 'post',
            url: '/api/inputs/get_calculated_counterweightDisplay_Informations',
            data: {
                data: data,
            }
        })
            .then((response) => {
                this.dispatch('statusHandling/change_dataAndStatus_storeAction', response.data)

                if ((response.data.status.statusCode.substring(0, 1) == '2') || (response.data.status.statusCode.substring(0, 2) == '74') || (response.data.status.statusCode.substring(0, 2) == '75')) {
                    commit('change_counterweightDisplay_storeMutation', response.data.counterweight);
                }
                else {
                    commit('change_counterweightDisplay_storeMutation', '?');
                }
            })
            .then(() => {
            })
    },

    checkIf_mainsVoltageAndMainsFrequency_isValid_storeAction({ dispatch }, value) {
        const valueAndValueValidationCriteria = this._vm.$transmit_valueAndValueValidationCriteria;
        valueAndValueValidationCriteria.value = value;
        valueAndValueValidationCriteria.dataType = this._vm.$dataTypes.string;
        valueAndValueValidationCriteria.permissibleNumberOfDecimalPlaces = null;
        valueAndValueValidationCriteria.permissibleValueRange.minimum.value = null;
        valueAndValueValidationCriteria.permissibleValueRange.minimum.operator = null;
        valueAndValueValidationCriteria.permissibleValueRange.maximum.value = null;
        valueAndValueValidationCriteria.permissibleValueRange.maximum.operator = null;
        const mainsVoltageAndMainsFrequency_ValidationResult = this._vm.$checkIf_value_isValid(valueAndValueValidationCriteria);

        dispatch('change_mainsVoltageAndMainsFrequency_storeAction', mainsVoltageAndMainsFrequency_ValidationResult);
    },
    change_mainsVoltageAndMainsFrequency_storeAction({ commit, dispatch }, mainsVoltageAndMainsFrequency_ValidationResult) {
        if (mainsVoltageAndMainsFrequency_ValidationResult.value != null) {
            commit('change_mainsVoltage_storeMutation', Number(mainsVoltageAndMainsFrequency_ValidationResult.value.split(' | ')[0].split(' ')[0]));
            commit('change_mainsFrequency_storeMutation', Number(mainsVoltageAndMainsFrequency_ValidationResult.value.split(' | ')[1].split(' ')[0]));
        }
        else {
            commit('change_mainsVoltage_storeMutation', null);
            commit('change_mainsFrequency_storeMutation', null);
        }

        commit('change_mainsVoltageAndMainsFrequency_storeMutation', mainsVoltageAndMainsFrequency_ValidationResult);
        dispatch('checkIf_changesAt_inputs_changes_configurationFlow_storeAction')
    },

    checkIf_usageCategoryAndAverageTravelTimeAndDutyCycle_isValid_storeAction({ dispatch }, value) {
        const valueAndValueValidationCriteria = this._vm.$transmit_valueAndValueValidationCriteria;
        valueAndValueValidationCriteria.value = value;
        valueAndValueValidationCriteria.dataType = this._vm.$dataTypes.string;
        valueAndValueValidationCriteria.permissibleNumberOfDecimalPlaces = null;
        valueAndValueValidationCriteria.permissibleValueRange.minimum.value = null;
        valueAndValueValidationCriteria.permissibleValueRange.minimum.operator = null;
        valueAndValueValidationCriteria.permissibleValueRange.maximum.value = null;
        valueAndValueValidationCriteria.permissibleValueRange.maximum.operator = null;
        const usageCategoryAndAverageTravelTimeAndDutyCycle_ValidationResult = this._vm.$checkIf_value_isValid(valueAndValueValidationCriteria);

        dispatch('change_usageCategoryAndAverageTravelTimeAndDutyCycle_storeAction', usageCategoryAndAverageTravelTimeAndDutyCycle_ValidationResult);
    },
    change_usageCategoryAndAverageTravelTimeAndDutyCycle_storeAction({ commit, dispatch }, usageCategoryAndAverageTravelTimeAndDutyCycle_ValidationResult) {
        if (usageCategoryAndAverageTravelTimeAndDutyCycle_ValidationResult.value != null) {
            commit('change_usageCategory_storeMutation', Number(usageCategoryAndAverageTravelTimeAndDutyCycle_ValidationResult.value.split(' | ')[0]));
            commit('change_averageTravelTime_storeMutation', Number(usageCategoryAndAverageTravelTimeAndDutyCycle_ValidationResult.value.split(' | ')[1].split(' ')[0]));
            commit('change_dutyCycle_storeMutation', Number(usageCategoryAndAverageTravelTimeAndDutyCycle_ValidationResult.value.split(' | ')[2].split(' ')[0]));
        }
        else {
            commit('change_usageCategory_storeMutation', null);
            commit('change_averageTravelTime_storeMutation', null);
            commit('change_dutyCycle_storeMutation', null);
        }

        commit('change_usageCategoryAndAverageTravelTimeAndDutyCycle_storeMutation', usageCategoryAndAverageTravelTimeAndDutyCycle_ValidationResult);
        dispatch('checkIf_changesAt_inputs_changes_configurationFlow_storeAction')
    },

    get_usageCategoryAndAverageTravelTimeAndDutyCycle_storeAction({ dispatch }) {
        var startsPerHour = null
        if (state.startsPerHour != null && state.startsPerHour.hasOwnProperty('value')) {
            startsPerHour = state.startsPerHour.value;
        }
        const data = {
            nominalSpeed: state.nominalSpeed.value,
            travelDistance: state.travelDistance.value,
            usageCategoryAndAverageTravelTimeAndDutyCycle: state.usageCategoryAndAverageTravelTimeAndDutyCycle.value,
            startsPerHour: startsPerHour,
        }

        axios({
            method: 'post',
            url: '/api/inputs/get_usageCategoryAndAverageTravelTimeAndDutyCycle',
            data: {
                data: data,
            }
        })
            .then((response) => {
                this.dispatch('statusHandling/change_dataAndStatus_storeAction', response.data)
                if (response.data.usageCategoryAndAverageTravelTimeAndDutyCycle != null && response.data.status.statusCode.substring(0, 1) == '2') {
                    dispatch('checkIf_usageCategoryAndAverageTravelTimeAndDutyCycle_isValid_storeAction', response.data.usageCategoryAndAverageTravelTimeAndDutyCycle);
                }
            })
    },

    get_usageCategoryAndAverageTravelTimeAndDutyCycle_StartsPerHour_storeAction({ dispatch }, value) {
        var startsPerHour = null
        if (state.startsPerHour != null && state.startsPerHour.hasOwnProperty('value')) {
            startsPerHour = state.startsPerHour.value;
        }
        const data = {
            usageCategoryAndAverageTravelTimeAndDutyCycle: value,
            startsPerHour: startsPerHour,
        }

        axios({
            method: 'post',
            url: '/api/inputs/get_usageCategoryAndAverageTravelTimeAndDutyCycle_StartsPerHour',
            data: {
                data: data,
            }
        })
            .then((response) => {
                this.dispatch('statusHandling/change_dataAndStatus_storeAction', response.data)
                if (response.data.usageCategoryAndAverageTravelTimeAndDutyCycle != null && response.data.status.statusCode.substring(0, 1) == '2') {
                    dispatch('checkIf_usageCategoryAndAverageTravelTimeAndDutyCycle_isValid_storeAction', response.data.usageCategoryAndAverageTravelTimeAndDutyCycle);
                }
            })
    },

    checkIf_startsPerHour_isValid_storeAction({ dispatch }, value) {
        const valueAndValueValidationCriteria = this._vm.$transmit_valueAndValueValidationCriteria;
        valueAndValueValidationCriteria.value = value;
        valueAndValueValidationCriteria.dataType = this._vm.$dataTypes.integer;
        valueAndValueValidationCriteria.permissibleNumberOfDecimalPlaces = null;
        valueAndValueValidationCriteria.permissibleValueRange.minimum.value = null;
        valueAndValueValidationCriteria.permissibleValueRange.minimum.operator = null;
        valueAndValueValidationCriteria.permissibleValueRange.maximum.value = null;
        valueAndValueValidationCriteria.permissibleValueRange.maximum.operator = null;
        const startsPerHour_ValidationResult = this._vm.$checkIf_value_isValid(valueAndValueValidationCriteria);

        dispatch('change_startsPerHour_storeAction', startsPerHour_ValidationResult);
    },

    change_startsPerHour_storeAction({ commit, dispatch }, startsPerHour_ValidationResult) {
        commit('change_startsPerHour_storeMutation', startsPerHour_ValidationResult);
        dispatch('checkIf_changesAt_inputs_changes_configurationFlow_storeAction')
        if (state.usageCategoryAndAverageTravelTimeAndDutyCycle_Selection != null && state.nominalSpeed != null && state.nominalSpeed.value != null && state.travelDistance != null && state.travelDistance.value != null && state.usageCategoryAndAverageTravelTimeAndDutyCycle != null && state.usageCategoryAndAverageTravelTimeAndDutyCycle.value != null) {
            dispatch('get_usageCategoryAndAverageTravelTimeAndDutyCycle_StartsPerHour_storeAction', state.usageCategoryAndAverageTravelTimeAndDutyCycle.value);
        }

    },

    get_startsPerHour_storeAction({ dispatch }) {
        const data = {
            startsPerHour: state.startsPerHour.value,
            usageCategoryAndAverageTravelTimeAndDutyCycle: state.usageCategoryAndAverageTravelTimeAndDutyCycle.value,
        }

        axios({
            method: 'post',
            url: '/api/inputs/get_startsPerHour',
            data: {
                data: data,
            }
        })
            .then((response) => {
                this.dispatch('statusHandling/change_dataAndStatus_storeAction', response.data)
                if (response.data.usageCategoryAndAverageTravelTimeAndDutyCycle != null && response.data.status.statusCode.substring(0, 1) == '2') {
                    dispatch('checkIf_startsPerHour_isValid_storeAction', response.data.usageCategoryAndAverageTravelTimeAndDutyCycle);
                }
            })
    },


    // construction
    change_locationOfMachine_toDefault_storeAction({ dispatch }) {
        const firstIndex_whichMeetConditions = state.locationOfMachine_Selection.findIndex(i => i.preselected == true)
        if (firstIndex_whichMeetConditions > -1) {
            dispatch('change_Selected_at_locationOfMachine_Selection_storeAction', firstIndex_whichMeetConditions)
        }
        else {
            if (state.locationOfMachine_Selection.length > 0) {
                dispatch('change_Selected_at_locationOfMachine_Selection_storeAction', 0)
            }
            else {
                // error
            }
        }
        dispatch('filter_locationOfMachine_Selection_by_selected_verticalPositionOfMachineAndDetailedPositionOfMachine_storeAction')

    },

    change_Selected_at_locationOfMachine_Selection_storeAction({ commit,dispatch }, value) {
        commit('change_Selected_at_locationOfMachine_Selection_storeMutation', value)
    },
    change_locationOfMachine_verticalPositionOfMachineAndDetailedPositionOfMachine_storeAction({ commit, dispatch }, value) {
        const firstIndex_whichMeetConditions = state.locationOfMachine_Selection.findIndex(i => ((i.verticalPositionOfMachine.text == value.verticalPositionOfMachine.text) && (i.detailedPositionOfMachine.text == value.detailedPositionOfMachine.text) && (i.preselected == true)))
        if (firstIndex_whichMeetConditions > -1) {
            commit('change_Selected_at_locationOfMachine_Selection_storeMutation', firstIndex_whichMeetConditions);
            dispatch('filter_locationOfMachine_Selection_by_selected_verticalPositionOfMachineAndDetailedPositionOfMachine_storeAction');
            dispatch('checkIf_changesAt_inputs_changes_configurationFlow_storeAction');
        }
        else {
            // error
        }
    },
    filter_locationOfMachine_Selection_by_selected_verticalPositionOfMachineAndDetailedPositionOfMachine_storeAction({ commit }) {
        var helpArray_locationOfMachine_Selection_filteredBy_selected_verticalPositionOfMachineAndDetailedPositionOfMachine = [];
        const firstIndex_whichMeetConditions = state.locationOfMachine_Selection.findIndex(i => i.selected == true)

        if (firstIndex_whichMeetConditions > -1) {
            state.locationOfMachine_Selection.forEach(i => {
                if ((i.verticalPositionOfMachine.text == state.locationOfMachine_Selection[firstIndex_whichMeetConditions].verticalPositionOfMachine.text) && (i.detailedPositionOfMachine.text == state.locationOfMachine_Selection[firstIndex_whichMeetConditions].detailedPositionOfMachine.text)) {
                    helpArray_locationOfMachine_Selection_filteredBy_selected_verticalPositionOfMachineAndDetailedPositionOfMachine.push(i);
                }
            })
            commit('change_locationOfMachine_Selection_filteredBy_selected_verticalPositionOfMachineAndDetailedPositionOfMachine_storeMutation', helpArray_locationOfMachine_Selection_filteredBy_selected_verticalPositionOfMachineAndDetailedPositionOfMachine)
        }
        else {
            // error
        }
    },
    change_locationOfMachine_footDirectionOfMachineAndUseOfPulleySheave_storeAction({ commit, dispatch }, value) {
        const firstIndex_whichMeetConditions = state.locationOfMachine_Selection.findIndex(i => ((i.verticalPositionOfMachine.text == value.verticalPositionOfMachine.text) && (i.detailedPositionOfMachine.text == value.detailedPositionOfMachine.text) && (i.footDirectionOfMachine == value.footDirectionOfMachine) && (i.useOfPulleySheave == value.useOfPulleySheave)))
        if (firstIndex_whichMeetConditions > -1) {
            commit('change_Selected_at_locationOfMachine_Selection_storeMutation', firstIndex_whichMeetConditions);
            dispatch('checkIf_changesAt_inputs_changes_configurationFlow_storeAction');
        }
        else {
            // error
        }
    },

    change_designType_storeAction({ commit, dispatch }, value) {
        commit('change_designType_storeMutation', value);
        dispatch('checkIf_changesAt_inputs_changes_configurationFlow_storeAction')
    },

    checkIf_suspensionRatio1_isValid_storeAction({ dispatch }, value) {
        const valueAndValueValidationCriteria = this._vm.$transmit_valueAndValueValidationCriteria;
        valueAndValueValidationCriteria.value = value;
        valueAndValueValidationCriteria.dataType = this._vm.$dataTypes.integer;
        valueAndValueValidationCriteria.permissibleNumberOfDecimalPlaces = null;
        valueAndValueValidationCriteria.permissibleValueRange.minimum.value = 0;
        valueAndValueValidationCriteria.permissibleValueRange.minimum.operator = this._vm.$operators.greaterThan;
        valueAndValueValidationCriteria.permissibleValueRange.maximum.value = null;
        valueAndValueValidationCriteria.permissibleValueRange.maximum.operator = null;
        const suspensionRatio1_ValidationResult = this._vm.$checkIf_value_isValid(valueAndValueValidationCriteria);

        dispatch('change_suspensionRatio1_storeAction', suspensionRatio1_ValidationResult);
    },
    change_suspensionRatio1_storeAction({ commit, dispatch }, suspensionRatio1_ValidationResult) {
        commit('change_suspensionRatio1_storeMutation', suspensionRatio1_ValidationResult);
        dispatch('checkIf_suspensionMeans_SafetyFactor_canBeCalculated_storeAction');
        dispatch('checkIf_changesAt_inputs_changes_configurationFlow_storeAction')
    },

    checkIf_numberOfPulleysOnCarSide_isValid_storeAction({ dispatch }, value) {
        const valueAndValueValidationCriteria = this._vm.$transmit_valueAndValueValidationCriteria;
        valueAndValueValidationCriteria.value = value;
        valueAndValueValidationCriteria.dataType = this._vm.$dataTypes.integer;
        valueAndValueValidationCriteria.permissibleNumberOfDecimalPlaces = null;
        valueAndValueValidationCriteria.permissibleValueRange.minimum.value = 0;
        valueAndValueValidationCriteria.permissibleValueRange.minimum.operator = this._vm.$operators.greaterThanOrEqualTo;
        valueAndValueValidationCriteria.permissibleValueRange.maximum.value = null;
        valueAndValueValidationCriteria.permissibleValueRange.maximum.operator = null;
        const numberOfPulleysOnCarSide_ValidationResult = this._vm.$checkIf_value_isValid(valueAndValueValidationCriteria);

        dispatch('change_numberOfPulleysOnCarSide_storeAction', numberOfPulleysOnCarSide_ValidationResult);
    },
    change_numberOfPulleysOnCarSide_storeAction({ commit, dispatch }, numberOfPulleysOnCarSide_ValidationResult) {
        commit('change_numberOfPulleysOnCarSide_storeMutation', numberOfPulleysOnCarSide_ValidationResult);
        dispatch('checkIf_changesAt_inputs_changes_configurationFlow_storeAction')
    },

    checkIf_numberOfPulleysOnCounterweightSide_isValid_storeAction({ dispatch }, value) {
        const valueAndValueValidationCriteria = this._vm.$transmit_valueAndValueValidationCriteria;
        valueAndValueValidationCriteria.value = value;
        valueAndValueValidationCriteria.dataType = this._vm.$dataTypes.integer;
        valueAndValueValidationCriteria.permissibleNumberOfDecimalPlaces = null;
        valueAndValueValidationCriteria.permissibleValueRange.minimum.value = 0;
        valueAndValueValidationCriteria.permissibleValueRange.minimum.operator = this._vm.$operators.greaterThanOrEqualTo;
        valueAndValueValidationCriteria.permissibleValueRange.maximum.value = null;
        valueAndValueValidationCriteria.permissibleValueRange.maximum.operator = null;
        const numberOfPulleysOnCounterweightSide_ValidationResult = this._vm.$checkIf_value_isValid(valueAndValueValidationCriteria);

        dispatch('change_numberOfPulleysOnCounterweightSide_storeAction', numberOfPulleysOnCounterweightSide_ValidationResult);
    },
    change_numberOfPulleysOnCounterweightSide_storeAction({ commit, dispatch }, numberOfPulleysOnCounterweightSide_ValidationResult) {
        commit('change_numberOfPulleysOnCounterweightSide_storeMutation', numberOfPulleysOnCounterweightSide_ValidationResult);
        dispatch('checkIf_changesAt_inputs_changes_configurationFlow_storeAction')
    },

    change_calculatedWithDoublewrap_storeAction({ commit, dispatch }, value) {
        commit('change_calculatedWithDoublewrap_storeMutation', value);
        dispatch('checkIf_changesAt_inputs_changes_configurationFlow_storeAction')
    },
    change_wrappingAngle_Calculated_storeAction({ rootState, commit, dispatch }, value) {
        commit('change_wrappingAngle_Calculated_storeMutation', value);

        if (value == false && state.useFrame == true) {
            commit('change_useFrame_storeMutation', false);
        }

        if ((value == false) && ((state.checkedAtLeastOnce == true) || (rootState.configurations.configurationSteps.inputs.storedInDatabase == true))) {
            dispatch('checkIf_wrappingAngle_isValid_storeAction', state.wrappingAngle.value);
        }
        else {
            dispatch('checkIf_changesAt_inputs_changes_configurationFlow_storeAction')
        }
    },
    checkIf_wrappingAngle_isValid_storeAction({ dispatch }, value) {
        const valueAndValueValidationCriteria = this._vm.$transmit_valueAndValueValidationCriteria;
        valueAndValueValidationCriteria.value = value;
        valueAndValueValidationCriteria.dataType = this._vm.$dataTypes.decimal;
        valueAndValueValidationCriteria.permissibleNumberOfDecimalPlaces = 2;
        valueAndValueValidationCriteria.permissibleValueRange.minimum.value = 0;
        valueAndValueValidationCriteria.permissibleValueRange.minimum.operator = this._vm.$operators.greaterThan;
        valueAndValueValidationCriteria.permissibleValueRange.maximum.value = null;
        valueAndValueValidationCriteria.permissibleValueRange.maximum.operator = null;
        const wrappingAngle_ValidationResult = this._vm.$checkIf_value_isValid(valueAndValueValidationCriteria);

        dispatch('change_wrappingAngle_storeAction', wrappingAngle_ValidationResult);
    },
    change_wrappingAngle_storeAction({ commit, dispatch }, wrappingAngle_ValidationResult) {
        commit('change_wrappingAngle_storeMutation', wrappingAngle_ValidationResult);
        dispatch('checkIf_changesAt_inputs_changes_configurationFlow_storeAction')
    },
    change_wrappingAngle_Data_storeAction({ commit }, value) {
        commit('change_wrappingAngle_Calculated_storeMutation', value.wrappingAngle_Calculated);
        commit('change_wrappingAngle_storeMutation', { value: value.wrappingAngle, invalid: false });
    },


    // suspensionMeans
    change_suspensionMeans_Selection_storeAction({ commit }, value) {
        commit('change_suspensionMeans_Selection_storeMutation', value)
    },
    change_typeOfSuspensionMeans_storeAction({ rootState, commit, dispatch }, value) {
        commit('change_typeOfSuspensionMeans_storeMutation', value);

        if (state.individualSuspensionMeans == false) {
            var suspensionMeans_Selection_filteredBy_typeOfSuspensionMeans = state.suspensionMeans_Selection.filter(i => {
                if (i.suspensionMeans.typeOfSuspensionMeans.text == value) {
                    return true
                }
                return false
            })

            if (suspensionMeans_Selection_filteredBy_typeOfSuspensionMeans.length == 1) {
                if (value == 'rope') {
                    commit('change_ropeManufacturer_storeMutation', suspensionMeans_Selection_filteredBy_typeOfSuspensionMeans[0].suspensionMeans.manufacturer);
                    commit('change_ropeType_storeMutation', suspensionMeans_Selection_filteredBy_typeOfSuspensionMeans[0].suspensionMeans.type);
                    commit('change_suspensionMeansManufacturerAndType_storeMutation', { value: suspensionMeans_Selection_filteredBy_typeOfSuspensionMeans[0].suspensionMeans.manufacturer + ' | ' + suspensionMeans_Selection_filteredBy_typeOfSuspensionMeans[0].suspensionMeans.type, invalid: false });
                    commit('change_ropeDiameter_storeMutation', { value: suspensionMeans_Selection_filteredBy_typeOfSuspensionMeans[0].suspensionMeans.ropeDiameter, invalid: false });
                    dispatch('change_tooltip_grooveDistance_storeAction');
                }
                else if (value == 'belt') {
                    commit('change_beltManufacturer_storeMutation', suspensionMeans_Selection_filteredBy_typeOfSuspensionMeans[0].suspensionMeans.manufacturer);
                    commit('change_beltType_storeMutation', suspensionMeans_Selection_filteredBy_typeOfSuspensionMeans[0].suspensionMeans.type);
                    commit('change_suspensionMeansManufacturerAndType_storeMutation', { value: suspensionMeans_Selection_filteredBy_typeOfSuspensionMeans[0].suspensionMeans.manufacturer + ' | ' + suspensionMeans_Selection_filteredBy_typeOfSuspensionMeans[0].suspensionMeans.type, invalid: false });
                    commit('change_cordDiameter_storeMutation', { value: suspensionMeans_Selection_filteredBy_typeOfSuspensionMeans[0].suspensionMeans.cordDiameter, invalid: false });
                }
                else {
                    //error
                }
            }

            if ((state.checkedAtLeastOnce == true) || (rootState.configurations.configurationSteps.inputs.storedInDatabase == true)) {
                if (value == 'rope') {
                    commit('change_ropeDiameter_storeMutation', { value: state.ropeDiameter.value, invalid: null });

                    if ((state.ropeManufacturer != null) && (state.ropeType != null)) {
                        commit('change_suspensionMeansManufacturerAndType_storeMutation', { value: (state.ropeManufacturer + ' | ' + state.ropeType), invalid: null })
                    }
                    else {
                        commit('change_suspensionMeansManufacturerAndType_storeMutation', { value: null, invalid: null })
                    }
                }
                else if (value == 'belt') {
                    commit('change_cordDiameter_storeMutation', { value: state.cordDiameter.value, invalid: null })

                    if ((state.beltManufacturer != null) && (state.beltType != null)) {
                        commit('change_suspensionMeansManufacturerAndType_storeMutation', { value: (state.ropeManufacturer + ' | ' + state.ropeType), invalid: null })
                    }
                    else {
                        commit('change_suspensionMeansManufacturerAndType_storeMutation', { value: null, invalid: null })
                    }
                }
                else {
                    // error
                }
            }
            else {
                if (value == 'rope') {
                    if (state.ropeDiameter.value != null) {
                        commit('change_ropeDiameter_storeMutation', { value: state.ropeDiameter.value, invalid: null });
                    }
                    else {
                        commit('change_ropeDiameter_storeMutation', { value: null, invalid: null })
                    }

                    if ((state.ropeManufacturer != null) && (state.ropeType != null)) {
                        commit('change_suspensionMeansManufacturerAndType_storeMutation', { value: (state.ropeManufacturer + ' | ' + state.ropeType), invalid: null })
                    }
                    else {
                        commit('change_suspensionMeansManufacturerAndType_storeMutation', { value: null, invalid: null })
                    }
                }
                else if (value == 'belt') {
                    if (state.cordDiameter.value != null) {
                        commit('change_cordDiameter_storeMutation', { value: state.cordDiameter.value, invalid: null })
                    }
                    else {
                        commit('change_cordDiameter_storeMutation', { value: null, invalid: null })
                    }

                    if ((state.beltManufacturer != null) && (state.beltType != null)) {
                        commit('change_suspensionMeansManufacturerAndType_storeMutation', { value: (state.beltManufacturer + ' | ' + state.beltType), invalid: null })
                    }
                    else {
                        commit('change_suspensionMeansManufacturerAndType_storeMutation', { value: null, invalid: null })
                    }
                }
                else {
                    // error
                }
            }
            dispatch('checkIf_suspensionMeans_Data_Entered_storeAction');
        }
        else if (state.individualSuspensionMeans == true) {
            if ((state.checkedAtLeastOnce == true) || (rootState.configurations.configurationSteps.inputs.storedInDatabase == true)) {
                if (value == 'rope') {
                    dispatch('checkIf_individualSuspensionMeans_ropeDiameter_isValid_storeAction', state.individualSuspensionMeans_ropeDiameter.value);
                }
                else if (value == 'belt') {
                    dispatch('checkIf_individualSuspensionMeans_cordDiameter_isValid_storeAction', state.individualSuspensionMeans_cordDiameter.value);
                }
                else {
                    // error
                }
            }
            else {
                if (value == 'rope') {
                    if (state.individualSuspensionMeans_ropeDiameter.value != null) {
                        dispatch('checkIf_individualSuspensionMeans_ropeDiameter_isValid_storeAction', state.individualSuspensionMeans_ropeDiameter.value);
                    }
                    else {
                        dispatch('change_individualSuspensionMeans_ropeDiameter_storeAction', { value: null, invalid: null })
                    }
                }
                else if (value == 'belt') {
                    if (state.individualSuspensionMeans_cordDiameter.value != null) {
                        dispatch('checkIf_individualSuspensionMeans_cordDiameter_isValid_storeAction', state.individualSuspensionMeans_cordDiameter.value);
                    }
                    else {
                        dispatch('change_individualSuspensionMeans_cordDiameter_storeAction', { value: null, invalid: null })
                    }
                }
                else {
                    // error
                }
            }
        }
    },

    checkIf_suspensionMeansManufacturerAndType_isValid_storeAction({ dispatch }, value) {
        const valueAndValueValidationCriteria = this._vm.$transmit_valueAndValueValidationCriteria;
        valueAndValueValidationCriteria.value = value;
        valueAndValueValidationCriteria.dataType = this._vm.$dataTypes.string;
        valueAndValueValidationCriteria.permissibleNumberOfDecimalPlaces = null;
        valueAndValueValidationCriteria.permissibleValueRange.minimum.value = null;
        valueAndValueValidationCriteria.permissibleValueRange.minimum.operator = null;
        valueAndValueValidationCriteria.permissibleValueRange.maximum.value = null;
        valueAndValueValidationCriteria.permissibleValueRange.maximum.operator = null;
        const suspensionMeansManufacturerAndType_ValidationResult = this._vm.$checkIf_value_isValid(valueAndValueValidationCriteria);

        dispatch('change_suspensionMeansManufacturerAndType_storeAction', suspensionMeansManufacturerAndType_ValidationResult);
    },
    change_suspensionMeansManufacturerAndType_storeAction({ commit, dispatch }, suspensionMeansManufacturerAndType_ValidationResult) {
        commit('change_suspensionMeansManufacturerAndType_storeMutation', suspensionMeansManufacturerAndType_ValidationResult);

        if (suspensionMeansManufacturerAndType_ValidationResult.value != null) {
            const manufacturer = suspensionMeansManufacturerAndType_ValidationResult.value.split(' | ')[0];
            const type = suspensionMeansManufacturerAndType_ValidationResult.value.split(' | ')[1];

            const suspensionMeans_Selection_filteredBy_manufacturerAndType = state.suspensionMeans_Selection.filter(i => {
                if ((i.suspensionMeans.manufacturer == manufacturer) && (i.suspensionMeans.type == type)) {
                    return true;
                }
                return false;
            })

            if (state.typeOfSuspensionMeans == 'rope') {
                commit('change_ropeManufacturer_storeMutation', manufacturer);
                commit('change_ropeType_storeMutation', type);
                if (suspensionMeans_Selection_filteredBy_manufacturerAndType.length == 1) {
                    commit('change_ropeDiameter_storeMutation', { value: suspensionMeans_Selection_filteredBy_manufacturerAndType[0].suspensionMeans.ropeDiameter, invalid: false });
                }
                dispatch('change_tooltip_grooveDistance_storeAction');
            }
            else if (state.typeOfSuspensionMeans == 'belt') {
                commit('change_beltManufacturer_storeMutation', manufacturer);
                commit('change_beltType_storeMutation', type);
                if (suspensionMeans_Selection_filteredBy_manufacturerAndType.length == 1) {
                    commit('change_cordDiameter_storeMutation', { value: suspensionMeans_Selection_filteredBy_manufacturerAndType[0].suspensionMeans.cordDiameter, invalid: false });
                }
            }
            else {
                // error
            }
        }
        else {
            if (state.typeOfSuspensionMeans == 'rope') {
                commit('change_ropeManufacturer_storeMutation', null);
                commit('change_ropeType_storeMutation', null);
                dispatch('change_tooltip_grooveDistance_storeAction');
            }
            else if (state.typeOfSuspensionMeans == 'belt') {
                commit('change_beltManufacturer_storeMutation', null);
                commit('change_beltType_storeMutation', null);
            }
            else {
                // error
            }
        }

        dispatch('checkIf_suspensionMeans_Data_Entered_storeAction');
    },

    checkIf_ropeDiameter_isValid_storeAction({ dispatch }, value) {
        const valueAndValueValidationCriteria = this._vm.$transmit_valueAndValueValidationCriteria;
        valueAndValueValidationCriteria.value = value;
        valueAndValueValidationCriteria.dataType = this._vm.$dataTypes.string;
        valueAndValueValidationCriteria.permissibleNumberOfDecimalPlaces = null;
        valueAndValueValidationCriteria.permissibleValueRange.minimum.value = null;
        valueAndValueValidationCriteria.permissibleValueRange.minimum.operator = null;
        valueAndValueValidationCriteria.permissibleValueRange.maximum.value = null;
        valueAndValueValidationCriteria.permissibleValueRange.maximum.operator = null;
        const ropeDiameter_ValidationResult = this._vm.$checkIf_value_isValid(valueAndValueValidationCriteria);

        dispatch('change_ropeDiameter_storeAction', ropeDiameter_ValidationResult)
    },
    change_ropeDiameter_storeAction({ commit, dispatch }, ropeDiameter_ValidationResult) {
        commit('change_ropeDiameter_storeMutation', ropeDiameter_ValidationResult)

        if (ropeDiameter_ValidationResult.value != null) {
            const suspensionMeans_Selection_filteredBy_ropeDiameter = state.suspensionMeans_Selection.filter(i => {
                if ((i.suspensionMeans.typeOfSuspensionMeans.text == 'rope') && (i.suspensionMeans.ropeDiameter == ropeDiameter_ValidationResult.value)) {
                    return true;
                }
                return false;
            })

            if (suspensionMeans_Selection_filteredBy_ropeDiameter.length == 1) {
                commit('change_ropeManufacturer_storeMutation', suspensionMeans_Selection_filteredBy_ropeDiameter[0].suspensionMeans.manufacturer);
                commit('change_ropeType_storeMutation', suspensionMeans_Selection_filteredBy_ropeDiameter[0].suspensionMeans.type);
                commit('change_suspensionMeansManufacturerAndType_storeMutation', { value: suspensionMeans_Selection_filteredBy_ropeDiameter[0].suspensionMeans.manufacturer + ' | ' + suspensionMeans_Selection_filteredBy_ropeDiameter[0].suspensionMeans.type, invalid: false });
                dispatch('change_tooltip_grooveDistance_storeAction');
            }
        }

        dispatch('checkIf_suspensionMeans_Data_Entered_storeAction');
    },

    change_ropeDiameter_ValueList_storeAction({ commit }, valueList) {
        commit('change_ropeDiameter_ValueList_storeMutation', valueList);
    },

    checkIf_cordDiameter_isValid_storeAction({ dispatch }, value) {
        const valueAndValueValidationCriteria = this._vm.$transmit_valueAndValueValidationCriteria;
        valueAndValueValidationCriteria.value = value;
        valueAndValueValidationCriteria.dataType = this._vm.$dataTypes.string;
        valueAndValueValidationCriteria.permissibleNumberOfDecimalPlaces = null;
        valueAndValueValidationCriteria.permissibleValueRange.minimum.value = null;
        valueAndValueValidationCriteria.permissibleValueRange.minimum.operator = null;
        valueAndValueValidationCriteria.permissibleValueRange.maximum.value = null;
        valueAndValueValidationCriteria.permissibleValueRange.maximum.operator = null;
        const cordDiameter_ValidationResult = this._vm.$checkIf_value_isValid(valueAndValueValidationCriteria);

        dispatch('change_cordDiameter_storeAction', cordDiameter_ValidationResult)
    },
    change_cordDiameter_storeAction({ commit, dispatch }, cordDiameter_ValidationResult) {
        commit('change_cordDiameter_storeMutation', cordDiameter_ValidationResult)

        if (cordDiameter_ValidationResult.value != null) {
            const suspensionMeans_Selection_filteredBy_cordDiameter = state.suspensionMeans_Selection.filter(i => {
                if ((i.suspensionMeans.typeOfSuspensionMeans.text == 'belt') && (i.suspensionMeans.cordDiameter == cordDiameter_ValidationResult.value)) {
                    return true;
                }
                return false;
            })

            if (suspensionMeans_Selection_filteredBy_cordDiameter.length == 1) {
                commit('change_beltManufacturer_storeMutation', suspensionMeans_Selection_filteredBy_cordDiameter[0].suspensionMeans.manufacturer);
                commit('change_beltType_storeMutation', suspensionMeans_Selection_filteredBy_cordDiameter[0].suspensionMeans.type);
                commit('change_suspensionMeansManufacturerAndType_storeMutation', { value: suspensionMeans_Selection_filteredBy_cordDiameter[0].suspensionMeans.manufacturer + ' | ' + suspensionMeans_Selection_filteredBy_cordDiameter[0].suspensionMeans.type, invalid: false });
            }
        }

        dispatch('checkIf_suspensionMeans_Data_Entered_storeAction');
    },

    change_cordDiameter_ValueList_storeAction({ commit }, valueList) {
        commit('change_cordDiameter_ValueList_storeMutation', valueList);
    },

    change_Selected_at_supensionMeans_Selection_storeAction({ commit, dispatch }, value) {
        const firstIndex_whichMeetCondition = state.suspensionMeans_Selection.findIndex(i => i.suspensionMeans.blockNumber == value.blockNumber);
        if (firstIndex_whichMeetCondition > -1) {
            commit('change_Selected_at_suspensionMeans_Selection_storeMutation', firstIndex_whichMeetCondition);

            if (state.suspensionMeans_Selection[firstIndex_whichMeetCondition].suspensionMeans.typeOfSuspensionMeans.text == 'rope') {
                commit('change_typeOfSuspensionMeans_storeMutation', 'rope');
                commit('change_ropeManufacturer_storeMutation', state.suspensionMeans_Selection[firstIndex_whichMeetCondition].suspensionMeans.manufacturer);
                commit('change_ropeType_storeMutation', state.suspensionMeans_Selection[firstIndex_whichMeetCondition].suspensionMeans.type);
                commit('change_suspensionMeansManufacturerAndType_storeMutation', { value: state.suspensionMeans_Selection[firstIndex_whichMeetCondition].suspensionMeans.manufacturer + state.suspensionMeans_Selection[firstIndex_whichMeetCondition].suspensionMeans.type, invalid: false })
                commit('change_ropeDiameter_storeMutation', { value: state.suspensionMeans_Selection[firstIndex_whichMeetCondition].suspensionMeans.ropeDiameter, invalid: false });
                dispatch('change_tooltip_grooveDistance_storeAction');
            }
            else if (state.suspensionMeans_Selection[firstIndex_whichMeetCondition].suspensionMeans.typeOfSuspensionMeans.text == 'belt') {
                commit('change_typeOfSuspensionMeans_storeMutation', 'belt');
                commit('change_beltManufacturer_storeMutation', state.suspensionMeans_Selection[firstIndex_whichMeetCondition].suspensionMeans.manufacturer);
                commit('change_beltType_storeMutation', state.suspensionMeans_Selection[firstIndex_whichMeetCondition].suspensionMeans.type);
                commit('change_suspensionMeansManufacturerAndType_storeMutation', { value: state.suspensionMeans_Selection[firstIndex_whichMeetCondition].suspensionMeans.manufacturer + state.suspensionMeans_Selection[firstIndex_whichMeetCondition].suspensionMeans.type, invalid: false })
                commit('change_cordDiameter_storeMutation', { value: state.suspensionMeans_Selection[firstIndex_whichMeetCondition].suspensionMeans.cordDiameter, invalid: false });
            }
            else {
                // error
            }
            commit('change_individualSuspensionMeans_storeMutation', false)

            dispatch('checkIf_suspensionMeans_Weight_canBeCalculated_storeAction');
            dispatch('checkIf_suspensionMeans_SafetyFactor_canBeCalculated_storeAction');
            dispatch('checkIf_changesAt_inputs_changes_configurationFlow_storeAction');
        }
        else {
            // error
        }
    },
    change_Selected_atEveryElement_supensionMeans_Selection_toFalse_storeAction({ commit, dispatch }) {
        commit('change_ropeManufacturer_storeMutation', null);
        commit('change_ropeType_storeMutation', null);
        commit('change_ropeDiameter_storeMutation', { value: null, invalid: null });
        commit('change_beltManufacturer_storeMutation', null);
        commit('change_beltType_storeMutation', null);
        commit('change_cordDiameter_storeMutation', { value: null, invalid: null });

        dispatch('change_DisabledToFalse_at_ropeDiameter_ValueList_storeAction');
        dispatch('change_DisabledToFalse_at_cordDiameter_ValueList_storeAction');
        dispatch('change_tooltip_grooveDistance_storeAction');
        dispatch('checkIf_suspensionMeans_Data_Entered_storeAction');
    },
    change_DisabledToFalse_at_ropeDiameter_ValueList_storeAction({ commit }) {
        state.ropeDiameter_ValueList.forEach(i => {
            if (i.disabled == true) {
                i.disabled = false;
            }
        })
    },
    change_DisabledToFalse_at_cordDiameter_ValueList_storeAction({ commit }) {
        state.cordDiameter_ValueList.forEach(i => {
            if (i.disabled == true) {
                i.disabled = false;
            }
        })
    },

    checkIf_suspensionMeans_Data_Entered_storeAction({ commit, dispatch }) {
        if ((state.typeOfSuspensionMeans == 'rope') && (state.ropeManufacturer != null) && (state.ropeType != null) && (state.ropeDiameter.value != null)) {
            const firstIndex_whichMeetConditions = state.suspensionMeans_Selection.findIndex(i => ((i.suspensionMeans.typeOfSuspensionMeans.text == state.typeOfSuspensionMeans) && (i.suspensionMeans.manufacturer == state.ropeManufacturer) && (i.suspensionMeans.type == state.ropeType) && (i.suspensionMeans.ropeDiameter == state.ropeDiameter.value)));
            if (firstIndex_whichMeetConditions > -1) {
                commit('change_Selected_at_suspensionMeans_Selection_storeMutation', firstIndex_whichMeetConditions);
            }
            else {
                commit('change_Selected_at_suspensionMeans_Selection_storeMutation', null);
            }
        }
        else if ((state.typeOfSuspensionMeans == 'belt') && (state.beltManufacturer != null) && (state.beltType != null) && (state.cordDiameter.value != null)) {
            const firstIndex_whichMeetConditions = state.suspensionMeans_Selection.findIndex(i => ((i.suspensionMeans.typeOfSuspensionMeans.text == state.typeOfSuspensionMeans) && (i.suspensionMeans.manufacturer == state.beltManufacturer) && (i.suspensionMeans.type == state.beltType) && (i.suspensionMeans.cordDiameter == state.cordDiameter.value)));
            if (firstIndex_whichMeetConditions > -1) {
                commit('change_Selected_at_suspensionMeans_Selection_storeMutation', firstIndex_whichMeetConditions);
            }
            else {
                commit('change_Selected_at_suspensionMeans_Selection_storeMutation', null);
            }
        }
        else {
            commit('change_Selected_at_suspensionMeans_Selection_storeMutation', null);
        }

        dispatch('checkIf_suspensionMeans_Weight_canBeCalculated_storeAction');
        dispatch('checkIf_suspensionMeans_SafetyFactor_canBeCalculated_storeAction');
        dispatch('checkIf_changesAt_inputs_changes_configurationFlow_storeAction');
    },

    change_individualSuspensionMeans_storeAction({ rootState, commit, dispatch }, value) {
        commit('change_individualSuspensionMeans_storeMutation', value);

        if (value == true) {
            commit('change_Selected_at_suspensionMeans_Selection_storeMutation', null);
            commit('change_suspensionMeansManufacturerAndType_storeMutation', { value: null, invalid: null });

            if ((state.checkedAtLeastOnce == true) || (rootState.configurations.configurationSteps.inputs.storedInDatabase == true)) {

                if (state.typeOfSuspensionMeans == 'rope') {
                    commit('change_individualSuspensionMeans_ropeDiameter_storeMutation', { value: state.individualSuspensionMeans_ropeDiameter.value, invalid: null });
                }
                else if (state.typeOfSuspensionMeans == 'belt') {
                    commit('change_individualSuspensionMeans_cordDiameter_storeMutation', { value: state.individualSuspensionMeans_cordDiameter.value, invalid: null });
                }
                else {
                    // error
                }

                commit('change_individualSuspensionMeans_breakingForce_storeMutation', { value: state.individualSuspensionMeans_breakingForce.value, invalid: null });
                commit('change_individualSuspensionMeans_weightPerMeter_storeMutation', { value: state.individualSuspensionMeans_weightPerMeter.value, invalid: null });
            }

            dispatch('checkIf_changesAt_inputs_changes_configurationFlow_storeAction');
            dispatch('checkIf_suspensionMeans_Weight_canBeCalculated_storeAction');
            dispatch('checkIf_suspensionMeans_SafetyFactor_canBeCalculated_storeAction');
        }
        else if (value == false) {
            if ((state.checkedAtLeastOnce == true) || (rootState.configurations.configurationSteps.inputs.storedInDatabase == true)) {
                if (state.typeOfSuspensionMeans == 'rope') {
                    if ((state.ropeManufacturer != null) && (state.ropeType != null)) {
                        commit('change_suspensionMeansManufacturerAndType_storeMutation', { value: (state.ropeManufacturer + ' | ' + state.ropeType), invalid: null })
                    }
                    else {
                        commit('change_suspensionMeansManufacturerAndType_storeMutation', { value: null, invalid: null })
                    }

                    commit('change_ropeDiameter_storeMutation', { value: state.ropeDiameter.value, invalid: null });
                }
                else if (state.typeOfSuspensionMeans == 'belt') {
                    if ((state.beltManufacturer != null) && (state.beltType != null)) {
                        commit('change_suspensionMeansManufacturerAndType_storeMutation', { value: (state.ropeManufacturer + ' | ' + state.ropeType), invalid: null })
                    }
                    else {
                        commit('change_suspensionMeansManufacturerAndType_storeMutation', { value: null, invalid: null })
                    }

                    commit('change_cordDiameter_storeMutation', { value: state.cordDiameter.value, invalid: null })
                }
                else {
                    // error
                }
            }

            dispatch('checkIf_suspensionMeans_Data_Entered_storeAction');
        }
    },

    checkIf_individualSuspensionMeans_ropeDiameter_isValid_storeAction({ dispatch }, value) {
        const valueAndValueValidationCriteria = this._vm.$transmit_valueAndValueValidationCriteria;
        valueAndValueValidationCriteria.value = value;
        valueAndValueValidationCriteria.dataType = this._vm.$dataTypes.decimal;
        valueAndValueValidationCriteria.permissibleNumberOfDecimalPlaces = 2;
        valueAndValueValidationCriteria.permissibleValueRange.minimum.value = 0;
        valueAndValueValidationCriteria.permissibleValueRange.minimum.operator = this._vm.$operators.greaterThan;
        valueAndValueValidationCriteria.permissibleValueRange.maximum.value = null;
        valueAndValueValidationCriteria.permissibleValueRange.maximum.operator = null;
        const individualSuspensionMeans_ropeDiameter_ValidationResult = this._vm.$checkIf_value_isValid(valueAndValueValidationCriteria);

        dispatch('change_individualSuspensionMeans_ropeDiameter_storeAction', individualSuspensionMeans_ropeDiameter_ValidationResult);
    },
    change_individualSuspensionMeans_ropeDiameter_storeAction({ commit, dispatch }, individualSuspensionMeans_ropeDiameter_ValidationResult) {
        commit('change_individualSuspensionMeans_ropeDiameter_storeMutation', individualSuspensionMeans_ropeDiameter_ValidationResult);
        dispatch('checkIf_changesAt_inputs_changes_configurationFlow_storeAction');
    },

    checkIf_individualSuspensionMeans_cordDiameter_isValid_storeAction({ dispatch }, value) {
        const valueAndValueValidationCriteria = this._vm.$transmit_valueAndValueValidationCriteria;
        valueAndValueValidationCriteria.value = value;
        valueAndValueValidationCriteria.dataType = this._vm.$dataTypes.decimal;
        valueAndValueValidationCriteria.permissibleNumberOfDecimalPlaces = 2;
        valueAndValueValidationCriteria.permissibleValueRange.minimum.value = 0;
        valueAndValueValidationCriteria.permissibleValueRange.minimum.operator = this._vm.$operators.greaterThan;
        valueAndValueValidationCriteria.permissibleValueRange.maximum.value = null;
        valueAndValueValidationCriteria.permissibleValueRange.maximum.operator = null;
        const individualSuspensionMeans_cordDiameter_ValidationResult = this._vm.$checkIf_value_isValid(valueAndValueValidationCriteria);

        dispatch('change_individualSuspensionMeans_cordDiameter_storeAction', individualSuspensionMeans_cordDiameter_ValidationResult);
    },
    change_individualSuspensionMeans_cordDiameter_storeAction({ commit }, individualSuspensionMeans_cordDiameter_ValidationResult) {
        commit('change_individualSuspensionMeans_cordDiameter_storeMutation', individualSuspensionMeans_cordDiameter_ValidationResult);
        dispatch('checkIf_changesAt_inputs_changes_configurationFlow_storeAction');
    },

    checkIf_individualSuspensionMeans_breakingForce_isValid_storeAction({ dispatch }, value) {
        const valueAndValueValidationCriteria = this._vm.$transmit_valueAndValueValidationCriteria;
        valueAndValueValidationCriteria.value = value;
        valueAndValueValidationCriteria.dataType = this._vm.$dataTypes.decimal;
        valueAndValueValidationCriteria.permissibleNumberOfDecimalPlaces = 2;
        valueAndValueValidationCriteria.permissibleValueRange.minimum.value = 0;
        valueAndValueValidationCriteria.permissibleValueRange.minimum.operator = this._vm.$operators.greaterThan;
        valueAndValueValidationCriteria.permissibleValueRange.maximum.value = null;
        valueAndValueValidationCriteria.permissibleValueRange.maximum.operator = null;
        const individualSuspensionMeans_breakingForce_ValidationResult = this._vm.$checkIf_value_isValid(valueAndValueValidationCriteria);

        dispatch('change_individualSuspensionMeans_breakingForce_storeAction', individualSuspensionMeans_breakingForce_ValidationResult);
    },
    change_individualSuspensionMeans_breakingForce_storeAction({ commit, dispatch }, individualSuspensionMeans_breakingForce_ValidationResult) {
        commit('change_individualSuspensionMeans_breakingForce_storeMutation', individualSuspensionMeans_breakingForce_ValidationResult);
        dispatch('checkIf_suspensionMeans_SafetyFactor_canBeCalculated_storeAction');
        dispatch('checkIf_changesAt_inputs_changes_configurationFlow_storeAction');
    },

    checkIf_individualSuspensionMeans_weightPerMeter_isValid_storeAction({ dispatch }, value) {
        const valueAndValueValidationCriteria = this._vm.$transmit_valueAndValueValidationCriteria;
        valueAndValueValidationCriteria.value = value;
        valueAndValueValidationCriteria.dataType = this._vm.$dataTypes.decimal;
        valueAndValueValidationCriteria.permissibleNumberOfDecimalPlaces = 3;
        valueAndValueValidationCriteria.permissibleValueRange.minimum.value = 0;
        valueAndValueValidationCriteria.permissibleValueRange.minimum.operator = this._vm.$operators.greaterThan;
        valueAndValueValidationCriteria.permissibleValueRange.maximum.value = null;
        valueAndValueValidationCriteria.permissibleValueRange.maximum.operator = null;
        const individualSuspensionMeans_weightPerMeter_ValidationResult = this._vm.$checkIf_value_isValid(valueAndValueValidationCriteria);

        dispatch('change_individualSuspensionMeans_weightPerMeter_storeAction', individualSuspensionMeans_weightPerMeter_ValidationResult);
    },
    change_individualSuspensionMeans_weightPerMeter_storeAction({ commit, dispatch }, individualSuspensionMeans_weightPerMeter_ValidationResult) {
        commit('change_individualSuspensionMeans_weightPerMeter_storeMutation', individualSuspensionMeans_weightPerMeter_ValidationResult);
        dispatch('checkIf_suspensionMeans_Weight_canBeCalculated_storeAction');
        dispatch('checkIf_suspensionMeans_SafetyFactor_canBeCalculated_storeAction');
        dispatch('checkIf_changesAt_inputs_changes_configurationFlow_storeAction');
    },

    checkIf_numberOfSuspensionMeans_isValid_storeAction({ dispatch }, value) {
        const valueAndValueValidationCriteria = this._vm.$transmit_valueAndValueValidationCriteria;
        valueAndValueValidationCriteria.value = value;
        valueAndValueValidationCriteria.dataType = this._vm.$dataTypes.integer;
        valueAndValueValidationCriteria.permissibleValueRange.minimum.value = 0;
        valueAndValueValidationCriteria.permissibleValueRange.minimum.operator = this._vm.$operators.greaterThan;
        valueAndValueValidationCriteria.permissibleValueRange.maximum.value = null;
        valueAndValueValidationCriteria.permissibleValueRange.maximum.operator = null;
        valueAndValueValidationCriteria.permissibleNumberOfDecimalPlaces = null;
        const numberOfSuspensionMeans_ValidationResult = this._vm.$checkIf_value_isValid(valueAndValueValidationCriteria);

        dispatch('change_numberOfSuspensionMeans_storeAction', numberOfSuspensionMeans_ValidationResult);

        if (state.numberOfGrooves.value != null) {
            dispatch('checkIf_numberOfGrooves_isValid_storeAction', state.numberOfGrooves.value);
        }
    },
    change_numberOfSuspensionMeans_storeAction({ commit, dispatch }, numberOfSuspensionMeans_ValidationResult) {
        commit('change_numberOfSuspensionMeans_storeMutation', numberOfSuspensionMeans_ValidationResult);
        dispatch('checkIf_suspensionMeans_Weight_canBeCalculated_storeAction');
        dispatch('checkIf_suspensionMeans_SafetyFactor_canBeCalculated_storeAction');
        dispatch('checkIf_changesAt_inputs_changes_configurationFlow_storeAction');
    },

    checkIf_cableWeightPerMeter_isValid_storeAction({ dispatch }, value) {
        const valueAndValueValidationCriteria = this._vm.$transmit_valueAndValueValidationCriteria;
        valueAndValueValidationCriteria.value = value;
        valueAndValueValidationCriteria.dataType = this._vm.$dataTypes.decimal;
        valueAndValueValidationCriteria.permissibleNumberOfDecimalPlaces = 2;
        valueAndValueValidationCriteria.permissibleValueRange.minimum.value = 0.5;
        valueAndValueValidationCriteria.permissibleValueRange.minimum.operator = this._vm.$operators.greaterThanOrEqualTo;
        valueAndValueValidationCriteria.permissibleValueRange.maximum.value = null;
        valueAndValueValidationCriteria.permissibleValueRange.maximum.operator = null;
        const cableWeightPerMeter_ValidationResult = this._vm.$checkIf_value_isValid(valueAndValueValidationCriteria);

        dispatch('change_cableWeightPerMeter_storeAction', cableWeightPerMeter_ValidationResult);
    },
    change_cableWeightPerMeter_storeAction({ commit, dispatch }, cableWeightPerMeter_ValidationResult) {
        commit('change_cableWeightPerMeter_storeMutation', cableWeightPerMeter_ValidationResult);
        dispatch('checkIf_suspensionMeans_SafetyFactor_canBeCalculated_storeAction');
        dispatch('checkIf_changesAt_inputs_changes_configurationFlow_storeAction');
    },

    change_useOfRopeTensionWeight_storeAction({ rootState, commit, dispatch }, value) {
        commit('change_useOfRopeTensionWeight_storeMutation', value)

        if ((value == true) && ((state.checkedAtLeastOnce == true) || (rootState.configurations.configurationSteps.inputs.storedInDatabase == true))) {
            dispatch('checkIf_ropeTensionWeight_isValid_storeAction', state.ropeTensionWeight.value)
        }
        else {
            dispatch('checkIf_changesAt_inputs_changes_configurationFlow_storeAction');
        }

        dispatch('checkIf_suspensionMeans_SafetyFactor_canBeCalculated_storeAction');
    },
    checkIf_ropeTensionWeight_isValid_storeAction({ dispatch }, value) {
        const valueAndValueValidationCriteria = this._vm.$transmit_valueAndValueValidationCriteria;
        valueAndValueValidationCriteria.value = value;
        valueAndValueValidationCriteria.dataType = this._vm.$dataTypes.decimal;
        valueAndValueValidationCriteria.permissibleNumberOfDecimalPlaces = 2;
        valueAndValueValidationCriteria.permissibleValueRange.minimum.value = 0;
        valueAndValueValidationCriteria.permissibleValueRange.minimum.operator = this._vm.$operators.greaterThan;
        valueAndValueValidationCriteria.permissibleValueRange.maximum.value = null;
        valueAndValueValidationCriteria.permissibleValueRange.maximum.operator = null;
        const ropeTensionWeight_ValidationResult = this._vm.$checkIf_value_isValid(valueAndValueValidationCriteria);

        dispatch('change_ropeTensionWeight_storeAction', ropeTensionWeight_ValidationResult);
    },
    change_ropeTensionWeight_storeAction({ commit, dispatch }, ropeTensionWeight_ValidationResult) {
        commit('change_ropeTensionWeight_storeMutation', ropeTensionWeight_ValidationResult);
        dispatch('checkIf_suspensionMeans_SafetyFactor_canBeCalculated_storeAction');
        dispatch('checkIf_changesAt_inputs_changes_configurationFlow_storeAction');
    },

    change_useOfCompensationRope_storeAction({ rootState, commit, dispatch }, value) {
        commit('change_useOfCompensationRope_storeMutation', value);

        if ((value == true) && ((state.checkedAtLeastOnce == true) || (rootState.configurations.configurationSteps.inputs.storedInDatabase == true))) {
            dispatch('checkIf_compensationRope_isValid_storeAction', state.compensationRope.value)
        }
        else {
            dispatch('checkIf_changesAt_inputs_changes_configurationFlow_storeAction');
        }

        dispatch('checkIf_suspensionMeans_SafetyFactor_canBeCalculated_storeAction');

        if (state.useOfCompensationRope == true && state.compensationRopeInPercent == null) {
            commit('change_compensationRopeInPercent_storeMutation', true);
        }
    },
    change_compensationRopeInPercent_storeAction({ commit, dispatch }, value) {
        commit('change_compensationRopeInPercent_storeMutation', value);
        dispatch('checkIf_suspensionMeans_SafetyFactor_canBeCalculated_storeAction');
        dispatch('checkIf_changesAt_inputs_changes_configurationFlow_storeAction');
    },
    checkIf_compensationRope_isValid_storeAction({ dispatch }, value) {
        const valueAndValueValidationCriteria = this._vm.$transmit_valueAndValueValidationCriteria;
        valueAndValueValidationCriteria.value = value;
        valueAndValueValidationCriteria.dataType = this._vm.$dataTypes.decimal;
        valueAndValueValidationCriteria.permissibleNumberOfDecimalPlaces = 2;
        valueAndValueValidationCriteria.permissibleValueRange.minimum.value = 0;
        valueAndValueValidationCriteria.permissibleValueRange.minimum.operator = this._vm.$operators.greaterThan;
        valueAndValueValidationCriteria.permissibleValueRange.maximum.value = null;
        valueAndValueValidationCriteria.permissibleValueRange.maximum.operator = null;
        const compensationRope_ValidationResult = this._vm.$checkIf_value_isValid(valueAndValueValidationCriteria);

        dispatch('change_compensationRope_storeAction', compensationRope_ValidationResult);
    },
    change_compensationRope_storeAction({ commit, dispatch }, compensationRope_ValidationResult) {
        commit('change_compensationRope_storeMutation', compensationRope_ValidationResult);
        dispatch('checkIf_suspensionMeans_SafetyFactor_canBeCalculated_storeAction');
        dispatch('checkIf_changesAt_inputs_changes_configurationFlow_storeAction');
    },
    checkIf_dFactor_isValid_storeAction({ dispatch }, value) {

        const valueAndValueValidationCriteria = this._vm.$transmit_valueAndValueValidationCriteria;
        valueAndValueValidationCriteria.value = value;
        valueAndValueValidationCriteria.dataType = this._vm.$dataTypes.decimal;
        valueAndValueValidationCriteria.permissibleNumberOfDecimalPlaces = 2;
        valueAndValueValidationCriteria.permissibleValueRange.minimum.value = 1;
        valueAndValueValidationCriteria.permissibleValueRange.minimum.operator = this._vm.$operators.greaterThanOrEqualTo;
        valueAndValueValidationCriteria.permissibleValueRange.maximum.value = 100;
        valueAndValueValidationCriteria.permissibleValueRange.maximum.operator = this._vm.$operators.lessThan;
        const muefaktor_ValidationResult = this._vm.$checkIf_value_isValid(valueAndValueValidationCriteria);

        dispatch('change_dFactor_storeAction', muefaktor_ValidationResult);
    },
    change_dFactor_storeAction({ commit, dispatch }, value) {
        commit('change_dFactor_storeMutation', value);
        dispatch('checkIf_changesAt_inputs_changes_configurationFlow_storeAction');
    },

    checkIf_suspensionMeans_Weight_canBeCalculated_storeAction({ commit, dispatch }) {
        if (state.individualSuspensionMeans == false) {
            const firstIndex_whichMeetCondition = state.suspensionMeans_Selection.findIndex(i => (i.selected == true));
            if ((state.travelDistance.invalid == false) && (state.travelDistance.value != null) && (firstIndex_whichMeetCondition > -1) && (state.suspensionMeans_Selection[firstIndex_whichMeetCondition].suspensionMeans.weightPerMeter != null) && (state.numberOfSuspensionMeans.value != null)) {
                commit('change_weight_storeMutation', { value: null, canBeCalculated: true, calculatedSuccessfully: null })
                dispatch('get_calculated_suspensionMeans_Weight_storeAction', state.suspensionMeans_Selection[firstIndex_whichMeetCondition].suspensionMeans.weightPerMeter);
            }
            else {
                commit('change_weight_storeMutation', { value: null, canBeCalculated: false, calculatedSuccessfully: null })
            }
        }
        else if (state.individualSuspensionMeans == true) {
            if ((state.travelDistance.invalid == false) && (state.travelDistance.value != null) && (state.individualSuspensionMeans_weightPerMeter.invalid == false) && (state.individualSuspensionMeans_weightPerMeter.value != null) && (state.numberOfSuspensionMeans.value != null)) {
                commit('change_weight_storeMutation', { value: null, canBeCalculated: true, calculatedSuccessfully: null })
                dispatch('get_calculated_suspensionMeans_Weight_storeAction', state.individualSuspensionMeans_weightPerMeter.value);
            }
            else {
                commit('change_weight_storeMutation', { value: null, canBeCalculated: false, calculatedSuccessfully: null })
            }
        }
        else {
            // error
        }
    },
    get_calculated_suspensionMeans_Weight_storeAction({ commit }, value) {
        const data = {
            travelDistance: state.travelDistance.value,
            weightPerMeter: value,
            numberOfSuspensionMeans: state.numberOfSuspensionMeans.value,
        }

        commit('change_loader_forWeightCalculation_Shown_storeMutation', true)

        axios({
            method: 'post',
            url: '/api/inputs/get_calculated_suspensionMeans_Weight_Informations',
            data: {
                data: data,
            }
        })
            .then((response) => {
                this.dispatch('statusHandling/change_dataAndStatus_storeAction', response.data)

                if ((response.data.status.statusCode.substring(0, 1) == '2') || (response.data.status.statusCode.substring(0, 2) == '74') || (response.data.status.statusCode.substring(0, 2) == '75')) {
                    commit('change_weight_storeMutation', { value: response.data.data.suspensionMeans.weight, canBeCalculated: true, calculatedSuccessfully: true })
                }
                else {
                    commit('change_weight_storeMutation', { value: null, canBeCalculated: true, calculatedSuccessfully: false })
                }
            })
            .then(() => {
                commit('change_loader_forWeightCalculation_Shown_storeMutation', false)
            })
    },
    change_weight_storeAction({ commit }, value) {
        commit('change_weight_storeMutation', value)
    },

    checkIf_suspensionMeans_SafetyFactor_canBeCalculated_storeAction({ commit, dispatch }) {
        if (state.individualSuspensionMeans == false) {
            const firstIndex_whichMeetCondition = state.suspensionMeans_Selection.findIndex(i => (i.selected == true));
            if ((state.nominalLoad.invalid == false) && (state.nominalLoad.value != null) && (state.travelDistance.invalid == false) && (state.travelDistance.value != null) && (state.carWeight.invalid == false) && (state.carWeight.value != null) && (state.suspensionRatio1.invalid == false) && (state.suspensionRatio1.value != null) && (firstIndex_whichMeetCondition > -1) && (state.suspensionMeans_Selection[firstIndex_whichMeetCondition].suspensionMeans.breakingForce != null) && (state.suspensionMeans_Selection[firstIndex_whichMeetCondition].suspensionMeans.weightPerMeter != null) && (state.numberOfSuspensionMeans.value != null) && (state.cableWeightPerMeter.invalid == false) && (state.cableWeightPerMeter.value != null) && ((state.useOfRopeTensionWeight == false) || ((state.useOfRopeTensionWeight == true) && (state.ropeTensionWeight.invalid == false) && (state.ropeTensionWeight.value != null))) && ((state.useOfCompensationRope == false) || ((state.useOfCompensationRope == true) && (state.compensationRope.invalid == false) && (state.compensationRope.value != null)))) {
                commit('change_safetyFactor_storeMutation', { value: null, canBeCalculated: true, calculatedSuccessfully: null });
                dispatch('get_calculated_suspensionMeans_SafetyFactor_storeAction', { breakingForce: state.suspensionMeans_Selection[firstIndex_whichMeetCondition].suspensionMeans.breakingForce, weightPerMeter: state.suspensionMeans_Selection[firstIndex_whichMeetCondition].suspensionMeans.weightPerMeter });
            }
            else {
                commit('change_safetyFactor_storeMutation', { value: null, canBeCalculated: false, calculatedSuccessfully: null });
            }
        }
        else if (state.individualSuspensionMeans == true) {
            if ((state.nominalLoad.invalid == false) && (state.nominalLoad.value != null) && (state.travelDistance.invalid == false) && (state.travelDistance.value != null) && (state.carWeight.invalid == false) && (state.carWeight.value != null) && (state.suspensionRatio1.invalid == false) && (state.suspensionRatio1.value != null) && (state.individualSuspensionMeans_breakingForce.invalid == false) && (state.individualSuspensionMeans_breakingForce.value != null) && (state.individualSuspensionMeans_weightPerMeter.invalid == false) && (state.individualSuspensionMeans_weightPerMeter.value != null) && (state.numberOfSuspensionMeans.value != null) && (state.cableWeightPerMeter.invalid == false) && (state.cableWeightPerMeter.value != null) && ((state.useOfRopeTensionWeight == false) || ((state.useOfRopeTensionWeight == true) && (state.ropeTensionWeight.invalid == false) && (state.ropeTensionWeight.value != null))) && ((state.useOfCompensationRope == false) || ((state.useOfCompensationRope == true) && (state.compensationRope.invalid == false) && (state.compensationRope.value != null)))) {
                commit('change_safetyFactor_storeMutation', { value: null, canBeCalculated: true, calculatedSuccessfully: null });
                dispatch('get_calculated_suspensionMeans_SafetyFactor_storeAction', { breakingForce: state.individualSuspensionMeans_breakingForce.value, weightPerMeter: state.individualSuspensionMeans_weightPerMeter.value });
            }
            else {
                commit('change_safetyFactor_storeMutation', { value: null, canBeCalculated: false, calculatedSuccessfully: null });
            }
        }
        else {
            // error
        }
    },
    get_calculated_suspensionMeans_SafetyFactor_storeAction({ commit }, value) {
        const data = {
            nominalLoad: state.nominalLoad.value,
            travelDistance: state.travelDistance.value,
            carWeight: state.carWeight.value,
            suspensionRatio1: state.suspensionRatio1.value,
            breakingForce: value.breakingForce,
            weightPerMeter: value.weightPerMeter,
            numberOfSuspensionMeans: state.numberOfSuspensionMeans.value,
            cableWeightPerMeter: state.cableWeightPerMeter.value,
            ropeTensionWeight: state.ropeTensionWeight.value,
            compensationRope: state.compensationRope.value,
            compensationRopeInPercent: state.compensationRopeInPercent,
        }

        commit('change_loader_forSafetyFactorCalculation_Shown_storeMutation', true)

        axios({
            method: 'post',
            url: '/api/inputs/get_calculated_suspensionMeans_SafetyFactor_Informations',
            data: {
                data: data,
            }
        })
            .then((response) => {
                this.dispatch('statusHandling/change_dataAndStatus_storeAction', response.data)

                if ((response.data.status.statusCode.substring(0, 1) == '2') || (response.data.status.statusCode.substring(0, 2) == '74') || (response.data.status.statusCode.substring(0, 2) == '75')) {
                    commit('change_safetyFactor_storeMutation', { value: response.data.data.suspensionMeans.safetyFactor, canBeCalculated: true, calculatedSuccessfully: true });
                }
                else {
                    commit('change_safetyFactor_storeMutation', { value: null, canBeCalculated: true, calculatedSuccessfully: false });
                }
            })
            .then(() => {
                commit('change_loader_forSafetyFactorCalculation_Shown_storeMutation', false)
            })
    },
    change_safetyFactor_storeAction({ commit }, value) {
        commit('change_safetyFactor_storeMutation', value)
    },

    change_Disabled_at_suspensionMeansManufacturerAndType_Selection_storeAction({ commit }) {
        if (state.typeOfSuspensionMeans == 'rope') {
            if (state.ropeDiameter.value != null) {
                const suspensionMeans_Selection_filteredBy_ropeDiameter = state.suspensionMeans_Selection.filter(i => {
                    if (i.suspensionMeans.ropeDiameter == state.ropeDiameter.value) {
                        return true;
                    }
                    return false;
                })
                commit('change_Disabled_at_ropeManufacturerAndType_Selection_storeMutation', suspensionMeans_Selection_filteredBy_ropeDiameter)
            }
            else if (state.ropeDiameter.value == null) {
                commit('change_Disabled_at_ropeManufacturerAndType_Selection_storeMutation', [])
            }
        }
        else if (state.typeOfSuspensionMeans == 'belt') {
            if (state.cordDiameter.value != null) {
                const suspensionMeans_Selection_filteredBy_cordDiameter = state.suspensionMeans_Selection.filter(i => {
                    if (i.suspensionMeans.cordDiameter == state.cordDiameter.value) {
                        return true;
                    }
                    return false;
                })
                commit('change_Disabled_at_beltManufacturerAndType_Selection_storeMutation', suspensionMeans_Selection_filteredBy_cordDiameter)
            }
            else if (state.cordDiameter.value == null) {
                commit('change_Disabled_at_beltManufacturerAndType_Selection_storeMutation', [])
            }
        }
    },
    change_Disabled_at_ropeDiameter_ValueList_storeAction({ commit }) {
        if ((state.ropeManufacturer != null) && (state.ropeType != null)) {
            const suspensionMeans_Selection_filteredBy_ropeManufacturerAndType = state.suspensionMeans_Selection.filter(i => {
                if ((i.suspensionMeans.manufacturer == state.ropeManufacturer) && (i.suspensionMeans.type == state.ropeType)) {
                    return true;
                }
                return false;
            })
            commit('change_Disabled_at_ropeDiameter_ValueList_storeMutation', suspensionMeans_Selection_filteredBy_ropeManufacturerAndType)
        }
    },
    change_Disabled_at_cordDiameter_ValueList_storeAction({ commit }) {
        if ((state.beltManufacturer != null) && (state.beltType != null)) {
            const suspensionMeans_Selection_filteredBy_beltManufacturerAndType = state.suspensionMeans_Selection.filter(i => {
                if ((i.suspensionMeans.manufacturer == state.beltManufacturer) && (i.suspensionMeans.type == state.beltType)) {
                    return true;
                }
                return false;
            })
            commit('change_Disabled_at_cordDiameter_ValueList_storeMutation', suspensionMeans_Selection_filteredBy_beltManufacturerAndType)
        }
    },


    // elevatorMotor_TractionSheave
    change_useOfStandardGrooveDistance_storeAction({ rootState, commit, dispatch }, value) {
        commit('change_useOfStandardGrooveDistance_storeMutation', value);

        if ((value == true) && ((state.checkedAtLeastOnce == true) || (rootState.configurations.configurationSteps.inputs.storedInDatabase == true))) {
            dispatch('checkIf_grooveDistance_isValid_storeAction', state.grooveDistance.value)
        }
        else {
            dispatch('checkIf_changesAt_inputs_changes_configurationFlow_storeAction');
        }
    },
    checkIf_grooveDistance_isValid_storeAction({ dispatch }, value) {
        const valueAndValueValidationCriteria = this._vm.$transmit_valueAndValueValidationCriteria;
        valueAndValueValidationCriteria.value = value;
        valueAndValueValidationCriteria.dataType = this._vm.$dataTypes.integer;
        valueAndValueValidationCriteria.permissibleValueRange.minimum.value = 0;
        valueAndValueValidationCriteria.permissibleValueRange.minimum.operator = this._vm.$operators.greaterThan;
        valueAndValueValidationCriteria.permissibleValueRange.maximum.value = null;
        valueAndValueValidationCriteria.permissibleValueRange.maximum.operator = this._vm.$operators.lessThan;
        valueAndValueValidationCriteria.permissibleNumberOfDecimalPlaces = null;
        const grooveDistance_ValidationResult = this._vm.$checkIf_value_isValid(valueAndValueValidationCriteria);

        dispatch('change_grooveDistance_storeAction', grooveDistance_ValidationResult);
    },
    change_grooveDistance_storeAction({ commit, dispatch }, grooveDistance_ValidationResult) {
        commit('change_grooveDistance_storeMutation', grooveDistance_ValidationResult);
        dispatch('checkIf_changesAt_inputs_changes_configurationFlow_storeAction')
    },
    change_useStandardNumberOfGrooves_storeAction({ rootState, commit, dispatch }, value) {
        commit('change_useStandardNumberOfGrooves_storeMutation', value);

        if ((value == true) && ((state.checkedAtLeastOnce == true) || (rootState.configurations.configurationSteps.inputs.storedInDatabase == true))) {
            dispatch('checkIf_numberOfGrooves_isValid_storeAction', state.numberOfGrooves.value)
        }
        else {
            dispatch('checkIf_changesAt_inputs_changes_configurationFlow_storeAction');
        }
    },
    checkIf_numberOfGrooves_isValid_storeAction({ commit, dispatch }, value) {
        var numberOfRopes = 0;
        if (state.numberOfSuspensionMeans != null && state.numberOfSuspensionMeans.invalid != null && state.numberOfSuspensionMeans.invalid == false && state.numberOfSuspensionMeans.value != null) {
            var numberOfRopes = state.numberOfSuspensionMeans.value;
        }
        const valueAndValueValidationCriteria = this._vm.$transmit_valueAndValueValidationCriteria;
        valueAndValueValidationCriteria.value = value;
        valueAndValueValidationCriteria.dataType = this._vm.$dataTypes.integer;
        valueAndValueValidationCriteria.permissibleValueRange.minimum.value = parseInt(numberOfRopes);
        valueAndValueValidationCriteria.permissibleValueRange.minimum.operator = this._vm.$operators.greaterThanOrEqualTo;
        if (state.typeOfSuspensionMeans == null || state.typeOfSuspensionMeans != 'belt') {
            valueAndValueValidationCriteria.permissibleValueRange.maximum.value = null;
            valueAndValueValidationCriteria.permissibleValueRange.maximum.operator = this._vm.$operators.lessThan;
        }
        else {
            valueAndValueValidationCriteria.permissibleValueRange.maximum.value = parseInt(numberOfRopes);
            valueAndValueValidationCriteria.permissibleValueRange.maximum.operator = this._vm.$operators.lessThanOrEqualTo;
        }
        valueAndValueValidationCriteria.permissibleNumberOfDecimalPlaces = null;
        const numberOfGrooves_ValidationResult = this._vm.$checkIf_value_isValid(valueAndValueValidationCriteria);
        dispatch('change_numberOfGrooves_storeAction', numberOfGrooves_ValidationResult);
    },

    change_numberOfGrooves_storeAction({ commit, dispatch }, numberOfGrooves_ValidationResult) {
        commit('change_numberOfGrooves_storeMutation', numberOfGrooves_ValidationResult);
        dispatch('checkIf_changesAt_inputs_changes_configurationFlow_storeAction')
    },

    async change_tooltip_ropeTensionWeight_storeAction({ commit }) {
        if (state.nominalSpeed != null && state.nominalSpeed.invalid == false && state.nominalSpeed.value > 3.5 && (state.useOfRopeTensionWeight == false || state.ropeTensionWeight == null || state.ropeTensionWeight.value == null || state.ropeTensionWeight.value <= 0)) {
            const data = {
                nominalSpeed: state.nominalSpeed.value,
                useOfRopeTensionWeight: state.useOfRopeTensionWeight,
                ropeTensionWeight: state.ropeTensionWeight.value,
            }
            axios({
                method: 'post',
                url: '/api/inputs/check_inputs_tooltip_ropeTensionWeight',
                data: {
                    data: data,
                }
            })
                .then((response) => {
                    commit('change_tooltip_ropeTensionWeight_storeMutation', response.data.data);
                })

        }
        else {
            commit('change_tooltip_ropeTensionWeight_storeMutation', { value: false, textKey: undefined, textValues: undefined });
        }
    },
    async change_tooltip_compensationRope_storeAction({ commit }) {
        var tooltip = false;
        if (state.nominalSpeed != null && state.nominalSpeed.invalid == false && isNaN(state.nominalSpeed.value) == false && state.nominalSpeed.value > 3 && (state.useOfCompensationRope == false || state.compensationRope == null || state.compensationRope.value == null || state.compensationRope.value <= 0)) {
            tooltip = true;
        }
        else if (state.useOfRopeTensionWeight != null && state.useOfRopeTensionWeight == true && state.ropeTensionWeight != null && state.ropeTensionWeight.value != null && state.ropeTensionWeight.value > 0 && (state.useOfCompensationRope == null || state.useOfCompensationRope == false || state.compensationRope.value == null || state.compensationRope.value <= 0)) {
            tooltip = true
        }

        if (tooltip == true) {
            const data = {
                nominalSpeed: state.nominalSpeed.value,
                useOfRopeTensionWeight: state.useOfRopeTensionWeight,
                ropeTensionWeight: state.ropeTensionWeight.value,
                useOfCompensationRope: state.useOfCompensationRope,
                compensationRope: state.compensationRope.value,
            }
            axios({
                method: 'post',
                url: '/api/inputs/check_inputs_tooltip_compensationRope',
                data: {
                    data: data,
                }
            })
                .then((response) => {
                    commit('change_tooltip_compensationRope_storeMutation', response.data.data);
                })
        }
        else {
            commit('change_tooltip_compensationRope_storeMutation', { value: false, textKey: undefined, textValues: undefined });
        }
    },
    async change_tooltip_grooveDistance_storeAction({ commit }) {
        if (state.typeOfSuspensionMeans == 'rope' && state.ropeDiameter != null && state.ropeDiameter.value != null && state.ropeDiameter.value > 0 && state.useOfStandardGrooveDistance == false && state.grooveDistance != null && state.grooveDistance.value != null) {
            const data = {
                useOfStandardGrooveDistance: state.useOfStandardGrooveDistance,
                grooveDistance: state.grooveDistance.value,
                calculatedWithDoublewrap: state.calculatedWithDoublewrap,
                ropeDiameter: state.ropeDiameter.value,
                ropeManufacturer: state.ropeManufacturer,
                ropeType: state.ropeType,
            }
            axios({
                method: 'post',
                url: '/api/inputs/check_inputs_tooltip_grooveDistance',
                data: {
                    data: data,
                }
            })
                .then((response) => {
                    commit('change_tooltip_grooveDistance_storeMutation', response.data.data);
                })
        }
        else {
            commit('change_tooltip_grooveDistance_storeMutation', { value: false, textKey: undefined, textValues: undefined });
        }
    },

    // additional components
    change_useFrame_storeAction({ commit, dispatch }, value) {
        commit('change_useFrame_storeMutation', value);
        if (value == true) {
            commit('change_wrappingAngle_Calculated_storeMutation', value);
        }
        dispatch('checkIf_changesAt_inputs_changes_configurationFlow_storeAction');
    },
    change_accept_useFrame_storeAction({ commit, dispatch }, value) {
        commit('change_accept_useFrame_storeMutation', value);
    },

    todos_input_IfConfigurationNotValid_storeAction({ dispatch, rootState }) {
        if (rootState.configurations.configuration_Modifiable != null && rootState.configurations.configuration_Modifiable == false && rootState.configurations.configurationNotValid != null && rootState.configurations.configurationNotValid == true) {
            dispatch('checkIf_changesAt_inputs_changes_configurationFlow_storeAction');
        }
    },
}

const mutations = {
    change_dFactor_storeMutation(state, payload) {
        state.dFactor = payload;
    },

    change_elevatorSystemAndConstructionAndSuspensionMeansAndElevatorMotor_TractionSheave_ValueListsAndSelections_storeMutation(state, payload) {
        // elevatorSystem
        state.nominalLoad_ValueList = payload.nominalLoad_ValueList;
        state.nominalSpeed_ValueList = payload.nominalSpeed_ValueList;
        state.travelDistance_ValueList = payload.travelDistance_ValueList;
        state.carWeight_ValueList = payload.carWeight_ValueList;
        state.counterweightInPercent_ValueList = payload.counterweightInPercent_ValueList;
        state.mainsVoltageAndMainsFrequency_Selection = payload.mainsVoltageAndMainsFrequency_Selection;
        state.usageCategoryAndAverageTravelTimeAndDutyCycle_Selection = payload.usageCategoryAndAverageTravelTimeAndDutyCycle_Selection;
        state.startsPerHour_Selection = payload.startsPerHour_ValueList;

        // construction
        state.locationOfMachine_Selection = payload.locationOfMachine_Selection;
        state.locationOfMachine_VerticalPositionOfMachineAndDetailedPositionOfMachine_Selection = payload.locationOfMachine_VerticalPositionOfMachineAndDetailedPositionOfMachine_Selection;
        state.suspensionRatio1_ValueList = payload.suspensionRatio1_ValueList;
        state.numberOfPulleysOnCarSide_ValueList = payload.numberOfPulleysOnCarSide_ValueList;
        state.numberOfPulleysOnCounterweightSide_ValueList = payload.numberOfPulleysOnCounterweightSide_ValueList;
        state.wrappingAngle_ValueList = payload.wrappingAngle_ValueList;
        state.accept_sFrameList = payload.accept_sFrameList;

        // suspensionMeans
        state.suspensionMeans_Selection = payload.suspensionMeans_Selection;
        state.ropeManufacturerAndType_Selection = payload.ropeManufacturerAndType_Selection;
        state.beltManufacturerAndType_Selection = payload.beltManufacturerAndType_Selection;
        state.ropeDiameter_ValueList = payload.ropeDiameter_ValueList;
        state.cordDiameter_ValueList = payload.cordDiameter_ValueList;
        state.numberOfSuspensionMeans_ValueList = payload.numberOfSuspensionMeans_ValueList;
        state.cableWeightPerMeter_ValueList = payload.cableWeightPerMeter_ValueList;
        state.ropeTensionWeight_ValueList = payload.ropeTensionWeight_ValueList;
        state.compensationRopeInPercent_ValueList = payload.compensationRopeInPercent_ValueList;

        // elevatorMotor_TractionSheave
        state.grooveDistance_ValueList = payload.grooveDistance_ValueList;
        state.inputdFactor_Disabled = payload.inputdFactorDisabled;
    },

    // elevatorSystem
    change_nominalLoad_storeMutation(state, payload) {
        state.nominalLoad = payload;
    },
    change_nominalSpeed_storeMutation(state, payload) {
        state.nominalSpeed = payload;
    },
    change_nominalSpeed_ValueList_storeMutation(state, payload) {
        state.nominalSpeed_ValueList = payload;
    },
    change_travelDistance_storeMutation(state, payload) {
        state.travelDistance = payload;
    },
    change_travelDistance_ValueList_storeMutation(state, payload) {
        state.travelDistance_ValueList = payload;
    },
    change_carWeight_storeMutation(state, payload) {
        state.carWeight = payload;
    },

    change_counterweightInPercent_storeMutation(state, payload) {
        state.counterweightInPercent = payload;
    },
    change_counterweight_storeMutation(state, payload) {
        state.counterweight = payload;
    },

    change_counterweightDisplay_storeMutation(state, payload) {
        state.counterweightDisplay = payload;
    },

    change_mainsVoltage_storeMutation(state, payload) {
        state.mainsVoltage = payload;
    },
    change_mainsFrequency_storeMutation(state, payload) {
        state.mainsFrequency = payload;
    },
    change_mainsVoltageAndMainsFrequency_storeMutation(state, payload) {
        state.mainsVoltageAndMainsFrequency = payload;
    },

    change_usageCategory_storeMutation(state, payload) {
        state.usageCategory = payload;
    },
    change_averageTravelTime_storeMutation(state, payload) {
        state.averageTravelTime = payload;
    },
    change_dutyCycle_storeMutation(state, payload) {
        state.dutyCycle = payload;
    },
    change_usageCategoryAndAverageTravelTimeAndDutyCycle_storeMutation(state, payload) {
        state.usageCategoryAndAverageTravelTimeAndDutyCycle = payload;
    },

    change_startsPerHour_storeMutation(state, payload) {
        state.startsPerHour= payload;
    },

    // construction
    change_Selected_at_locationOfMachine_Selection_storeMutation(state, payload) {
        for (let i = 0; i < state.locationOfMachine_Selection.length; i++) {
            state.locationOfMachine_Selection[i].selected = false;
            if (i == payload) {
                state.locationOfMachine_Selection[i].selected = true;
            }
        }
    },
    change_locationOfMachine_Selection_filteredBy_selected_verticalPositionOfMachineAndDetailedPositionOfMachine_storeMutation(state, payload) {
        state.locationOfMachine_Selection_filteredBy_selected_verticalPositionOfMachineAndDetailedPositionOfMachine = payload;
    },

    change_designType_storeMutation(state, payload) {
        state.designType = payload;
    },
    change_suspensionRatio1_storeMutation(state, payload) {
        state.suspensionRatio1 = payload;
    },
    change_numberOfPulleysOnCarSide_storeMutation(state, payload) {
        state.numberOfPulleysOnCarSide = payload;
    },
    change_numberOfPulleysOnCounterweightSide_storeMutation(state, payload) {
        state.numberOfPulleysOnCounterweightSide = payload;
    },

    change_calculatedWithDoublewrap_storeMutation(state, payload) {
        state.calculatedWithDoublewrap = payload;
    },
    change_wrappingAngle_Calculated_storeMutation(state, payload) {
        state.wrappingAngle_Calculated = payload;
    },
    change_wrappingAngle_storeMutation(state, payload) {
        state.wrappingAngle = payload;
    },


    // suspensionMeans
    change_suspensionMeans_Selection_storeMutation(state, payload) {
        state.suspensionMeans_Selection = payload;
    },
    change_typeOfSuspensionMeans_storeMutation(state, payload) {
        state.typeOfSuspensionMeans = payload;
    },

    change_ropeManufacturer_storeMutation(state, payload) {
        state.ropeManufacturer = payload;
    },
    change_ropeType_storeMutation(state, payload) {
        state.ropeType = payload;
    },
    change_beltManufacturer_storeMutation(state, payload) {
        state.beltManufacturer = payload;
    },
    change_beltType_storeMutation(state, payload) {
        state.beltType = payload;
    },
    change_suspensionMeansManufacturerAndType_storeMutation(state, payload) {
        state.suspensionMeansManufacturerAndType = payload;
    },

    change_ropeDiameter_storeMutation(state, payload) {
        state.ropeDiameter = payload;
    },
    change_ropeDiameter_ValueList_storeMutation(state, payload) {
        state.ropeDiameter_ValueList = payload;
    },
    change_cordDiameter_storeMutation(state, payload) {
        state.cordDiameter = payload;
    },
    change_cordDiameter_ValueList_storeMutation(state, payload) {
        state.cordDiameter_ValueList = payload;
    },
    change_individualSuspensionMeans_storeMutation(state, payload) {
        state.individualSuspensionMeans = payload;
    },
    change_individualSuspensionMeans_ropeDiameter_storeMutation(state, payload) {
        state.individualSuspensionMeans_ropeDiameter = payload;
    },
    change_individualSuspensionMeans_cordDiameter_storeMutation(state, payload) {
        state.individualSuspensionMeans_cordDiameter = payload;
    },
    change_individualSuspensionMeans_breakingForce_storeMutation(state, payload) {
        state.individualSuspensionMeans_breakingForce = payload;
    },
    change_individualSuspensionMeans_weightPerMeter_storeMutation(state, payload) {
        state.individualSuspensionMeans_weightPerMeter = payload;
    },

    change_numberOfSuspensionMeans_storeMutation(state, payload) {
        state.numberOfSuspensionMeans = payload;
    },

    change_weight_storeMutation(state, payload) {
        state.weight = payload;
    },
    change_weight_canBeCalculated_storeMutation(state, payload) {
        state.weight_canBeCalculated = payload;
    },
    change_loader_forWeightCalculation_Shown_storeMutation(state, payload) {
        state.loader_forWeightCalculation_Shown = payload;
    },

    change_safetyFactor_storeMutation(state, payload) {
        state.safetyFactor = payload;
    },
    change_safetyFactor_canBeCalculated_storeMutation(state, payload) {
        state.safetyFactor_canBeCalculated = payload;
    },
    change_loader_forSafetyFactorCalculation_Shown_storeMutation(state, payload) {
        state.loader_forSafetyFactorCalculation_Shown = payload;
    },

    change_cableWeightPerMeter_storeMutation(state, payload) {
        state.cableWeightPerMeter = payload;
    },

    change_useOfRopeTensionWeight_storeMutation(state, payload) {
        state.useOfRopeTensionWeight = payload;
    },
    change_ropeTensionWeight_storeMutation(state, payload) {
        state.ropeTensionWeight = payload;
    },

    change_useOfCompensationRope_storeMutation(state, payload) {
        state.useOfCompensationRope = payload;
    },
    change_compensationRopeInPercent_storeMutation(state, payload) {
        state.compensationRopeInPercent = payload;
    },
    change_compensationRope_storeMutation(state, payload) {
        state.compensationRope = payload;
    },

    change_Selected_at_suspensionMeans_Selection_storeMutation(state, payload) {
        for (let i = 0; i < state.suspensionMeans_Selection.length; i++) {
            state.suspensionMeans_Selection[i].selected = false;
            if (i == payload) {
                state.suspensionMeans_Selection[i].selected = true;
            }
        }
    },
    change_Disabled_at_ropeManufacturerAndType_Selection_storeMutation(state, payload) {
        if (payload.length > 0) {
            state.ropeManufacturerAndType_Selection.forEach(i => {
                i.disabled = true;
                payload.forEach(ii => {
                    if ((ii.suspensionMeans.manufacturer == i.manufacturerAndType.split(' | ')[0]) && (ii.suspensionMeans.type == i.manufacturerAndType.split(' | ')[1])) {
                        i.disabled = false;
                    }
                })
            })
        }
        else if (payload.length == 0) {
            state.ropeManufacturerAndType_Selection.forEach(i => {
                i.disabled = false;
            })
        }
    },
    change_Disabled_at_beltManufacturerAndType_Selection_storeMutation(state, payload) {
        if (payload.length > 0) {
            state.beltManufacturerAndType_Selection.forEach(i => {
                i.disabled = true;
                payload.forEach(ii => {
                    if ((ii.suspensionMeans.manufacturer == i.manufacturerAndType.split(' | ')[0]) && (ii.suspensionMeans.type == i.manufacturerAndType.split(' | ')[1])) {
                        i.disabled = false;
                    }
                })
            })
        }
        else if (payload.length == 0) {
            state.beltManufacturerAndType_Selection.forEach(i => {
                i.disabled = false;
            })
        }
    },
    change_Disabled_at_ropeDiameter_ValueList_storeMutation(state, payload) {
        if (payload.length > 0) {
            state.ropeDiameter_ValueList.forEach(i => {
                i.disabled = true;
                payload.forEach(ii => {
                    if (ii.suspensionMeans.ropeDiameter == i.ropeDiameter) {
                        i.disabled = false;
                    }
                })
            })
        }
    },
    change_Disabled_at_cordDiameter_ValueList_storeMutation(state, payload) {
        if (payload.length > 0) {
            state.cordDiameter_ValueList.forEach(i => {
                i.disabled = true;
                payload.forEach(ii => {
                    if (ii.suspensionMeans.cordDiameter == i.cordDiameter) {
                        i.disabled = false;
                    }
                })
            })
        }
    },

    change_inputdFactor_Disabled_storeMutation(state, payload) {
        state.inputdFactor_Disabled = payload;
    },

    // elevatorMotor_TractionSheave
    change_useOfStandardGrooveDistance_storeMutation(state, payload) {
        state.useOfStandardGrooveDistance = payload;
    },
    change_grooveDistance_storeMutation(state, payload) {
        state.grooveDistance = payload;
    },

    change_checkedAtLeastOnce_storeMutation(state, payload) {
        state.checkedAtLeastOnce = payload;
    },
    change_useStandardNumberOfGrooves_storeMutation(state, payload) {
        state.useStandardNumberOfGrooves = payload;
    },
    change_numberOfGrooves_storeMutation(state, payload) {
        state.numberOfGrooves = payload;
    },
    change_tooltip_ropeTensionWeight_storeMutation(state, payload) {
        state.tooltip_ropeTensionWeight = payload;
    },
    change_tooltip_compensationRope_storeMutation(state, payload) {
        state.tooltip_compensationRope = payload;
    },
    change_tooltip_grooveDistance_storeMutation(state, payload) {
        state.tooltip_grooveDistance = payload;
    },

    // additional components
    change_useFrame_storeMutation(state, payload) {
        state.useFrame = payload;
    },
    change_accept_useFrame_storeMutation(state, payload) {
        state.accept_useFrame = payload;
    },
}

export const inputs = {
    namespaced: true,
    components: {
        axios,
    },
    state,
    actions,
    mutations
}